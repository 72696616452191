import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Register = () => {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [rno, setRno] = useState("");
  const [userType, setuserType] = useState("")
  const [password, setpassword] = useState("")
  const [cpassword, setcpassword] = useState("")
  const [city, setcity] = useState("");
  const [School, setschool] = useState("");
  const [profile, setprofile] = useState("")
  const [branch, setbranch] = useState({})
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState("");
  const [pincode, setpincode] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [learnType, setlearnType] = useState("");
  const [fatheName,setfatheName]=useState("");

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!username) return alert("Please enter your name");
      if (!fatheName) return alert("Please enter your father name");
      if (!email) return alert("Please enter your email");
      if (!mobile) return alert("Please enter your mobile");
      if (!userType) return alert("Please select user type");
      if (!city) return alert("Please select city");
     
      if (!password) return alert("Please enter your password");
      if (!cpassword) return alert("Please enter confirm password");
      if (password !== cpassword) return alert("Confirm password did not match")

      const config = {
        url: "/customer/register-customer",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          name: username, mobile: mobile, email: email, Password: password, refNumber: rno, profile: profile, city: city, area: School, gender: gender, dob: dob, pincode: pincode, Address: address1, Address1: address2, userType: userType, learnType: learnType,  fatheName:fatheName,
        }
      }

      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully register");
        return window.location.assign("/studentlogin")
      }

      //  else if (userType == "Staff") {
      //   const config = {
      //     url: "/staff/register-Satff",
      //     method: "post",
      //     baseURL: "https://jaidrivingschool.in/api",
      //     headers: { "content-type": "multipart/form-data" },
      //     data: {
      //       name: username, mobile: mobile, email: email, Password: password, refNumber: rno, profile: profile, city: city, branchId: branch?._id, branchName: branch?.branchName ? `${branch?.branchName},${branch?.area}` : "Main Branch",
      //     }
      //   }
      //   let res = await axios(config);
      //   if (res.status == 200) {
      //     alert("Successfully register");
      //     return window.location.assign("/stafflogin")
      //   }
      // }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  };
  const [allCity, setAllCity] = useState([])
  const getAllFormdata = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getAllCity");
      if (res.status == 200) {
        setAllCity(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [allBranch, setAllBranc] = useState([]);
  const getAllBranch = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getAllBranch");
      if (res.status == 200) {
        setAllBranc(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllFormdata();
    // getAllBranch();
  }, [])


  return (
    <div className="registration-container">
      <Container>
        <div
          style={{
            backgroundImage: "url('../images/register-right-bg.jpg')",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            marginBottom: '20px',
            boxShadow: "0 8px 32px 0 rgba(19, 19, 20, 0.37)",
            borderRadius: '50px'
          }}>
          <h2 className="text-center">Registration Form</h2>

          <h2
            style={{
              fontFamily: 'math',
              marginLeft: '40px',
              fontSize: '40px',
              position: 'absolute'
            }}
          >Let's Drive <br /> With Us!</h2>

          <form >
            {/* <h2>Registration Form</h2> */}
            <div className="d-flex gap-2" >
              <div className="d-flex gap-2 ">
                <input type="checkbox" checked={learnType=="I Want to Learn Driving"} value="I Want to Learn Driving" onChange={(e) => setlearnType(e.target.value)} style={{ width: '12px' }} />
                <label className="mt-2 form-check-label" for="flexCheckDefault" style={{ fontSize: '14px' }}>
                  I Want to Learn Driving
                </label>
              </div>

              <div className="d-flex gap-2 ">
                <input type="checkbox" checked={learnType=="I Want to Take Services"} value="I Want to Take Services" onChange={(e) => setlearnType(e.target.value)} style={{ width: '12px' }} />
                <label className="mt-2 form-check-label" for="flexCheckDefault" style={{ fontSize: '14px' }}>
                  I Want to Take Services
                </label>
              </div>
            </div>
            <label htmlFor="username">Name</label>
            <input
              type="text"
              id="Rname"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              placeholder="Enter Name"
            />
            <br />
            <label htmlFor="username">Sonof</label>
            <input
              type="text"
              id="Rname"
              value={fatheName}
              onChange={(e) => setfatheName(e.target.value)}
              required
              placeholder="Enter Father/Spouse Name"
            />
            <br />
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="Remail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter Email"
            />
            <br />
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              id="Rmobile"
              maxLength="10"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
              placeholder="Enter Mobile No"
            />
            <br />

            <label htmlFor="mobile">Gender</label>
            <select id="Rutype" onChange={(e) => setgender(e.target.value)}>
              <option value="">Select Gender</option>
              <br />
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <br />

            <label htmlFor="rno" >DOB</label>
            <input
              onChange={(e) => setdob(e.target.value)}
              style={{ marginLeft: '130px' }}
              type="date"
              // value={rno}
              required
            />
            <br />

            <label htmlFor="rno" >Referral No</label>
            <input
              type="number"
              id="Rrno"
              value={rno}
              onChange={(e) => setRno(e.target.value)}
              required
              placeholder="Enter Referral No"
            />
            <br />
            <label htmlFor="">User Type</label>
            <select name="" id="" style={{ marginLeft: "88px" }} onChange={(e) => setuserType(e.target.value)}>
              <option value="">----Select User Type-----</option>
              <option value="Student">Student</option>
              <option value="Customer">Customer</option>
            </select>

            {/* <select onChange={(e)=>setuserType(e.target.value)} id="Rutype">
          <option value="">User Type</option>
          <br />
          <option value="Staff">Trainer</option>
          <option value="Student">Student/Customer</option>
        </select> */}
            <br />
            <label htmlFor="">City</label>
            <select name="" id="Rcity" onChange={(e) => setcity(e.target.value)}>
              <option value="">Select City</option>
              <br />
              {allCity?.map((ele) => {
                return (
                  <option value={ele?.city}>{ele?.city}</option>
                )
              })}
            </select>



            <br />

            <label htmlFor="rno" >Pincode</label>
            <input
              onChange={(e) => setpincode(e.target.value)}
              style={{ marginLeft: '105px' }}
              type="text"
              required
              placeholder="Enter Pincode"
            />
            <br />

            <label htmlFor="">Address 1</label>
            <input
              onChange={(e) => setaddress1(e.target.value)}
              type="text"
              style={{ marginLeft: '93px' }}
              required
              placeholder="Address"
            />

            <br />

            <label htmlFor="">Address 2 <br /> (Optional)</label>
            <input
              onChange={(e) => setaddress2(e.target.value)}
              type="text"
              style={{ marginLeft: '90px' }}
              required
              placeholder="Address"
            />
            <br />

            <label htmlFor="upload1">Your Profile</label>
            <input type="file" id="Rrno" name="file" accept="image/*" onChange={(e) => setprofile(e.target.files[0])}>
            </input>
            <br />

            <div style={{ position: 'relative' }}>
              <label htmlFor="password">Password</label>
              <input
                type={passwordVisible ? "text" : "password"}
                id="Rpassword"
                placeholder="Enter Password"
                onChange={(e) => setpassword(e.target.value)}
              // required
              />{" "}
              {passwordVisible ? (<AiFillEyeInvisible
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  position: 'absolute',
                  color: '#000',
                  bottom: '23%',
                  right: '19%',
                  cursor: 'pointer'
                }} />) : (<AiFillEye
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  style={{
                    position: 'absolute',
                    color: '#000',
                    bottom: '23%',
                    right: '19%',
                    cursor: 'pointer'
                  }} />)}

            </div>

            {/* <br /> */}
            <div style={{ position: 'relative' }}>
              <label htmlFor="password">Confirm Pwd</label>
              <input
                type={passwordVisible1 ? "text" : "password"}
                id="Rpassword1"
                placeholder="Re-enter Password"
                onChange={(e) => setcpassword(e.target.value)}
              // required
              />{" "}
              {passwordVisible1 ? (<AiFillEyeInvisible
                onClick={() => setPasswordVisible1(!passwordVisible1)}
                style={{
                  position: 'absolute',
                  color: '#000',
                  bottom: '23%',
                  right: '19%',
                  cursor: 'pointer'
                }} />) : (<AiFillEye
                  onClick={() => setPasswordVisible1(!passwordVisible1)}
                  style={{
                    position: 'absolute',
                    color: '#000',
                    bottom: '23%',
                    right: '19%',
                    cursor: 'pointer'
                  }} />)}

            </div>
            <br />

            <a

              className=" btn btn-warning"
              type="submit"
              id="Rbutton"
              onClick={(e) => handleSubmit(e)}
              style={{ marginLeft: "194px", marginTop: "20px", marginBottom: "10px" }}
            >
              Register
            </a>
            <p>
              Already have an account?
              <a href="/studentlogin" style={{ color: "blue", textDecoration: "underline" }}>
                Login
              </a>
            </p>
            {/* <p>
              Staff Already have an account?
              <a href="/stafflogin" style={{ color: "blue", textDecoration: "underline" }}>
                Login
              </a>
            </p> */}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Register;
