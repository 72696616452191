import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Sidebrr from './Sidebrr'
import Siide from './Siide'

const SideStudent = () => {
    const [sidebar,setSidebar]=useState(false)
    const togglesidebar=()=>{
        setSidebar(!sidebar)
    }
  return (
    <div>
        <Sidebar opensidebar={togglesidebar}/>
        <Siide  sidebar={sidebar} closesidebar={togglesidebar}/>
        <Sidebrr sidebar={sidebar}/>
    </div>
  )
}

export default SideStudent