import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import axios from "axios";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";
const Courses = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [allBranch, setAllBranc] = useState([]);
  const getAllBranch = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getAllBranch");
      if (res.status == 200) {
        setAllBranc(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [allCity, setAllCity] = useState([])
  const getAllcity = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getAllCity");
      if (res.status == 200) {
        setAllCity(res.data.success)
        getAllBranch()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/course/getAllcourse"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCourse();
    getAllBranch();
    getAllcity();
  }, []);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allCourse.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));

  const [name, setname] = useState("")
  const [mobile, setmobile] = useState("")
  const [email, setemail] = useState("")
  const [gender, setgender] = useState("")
  const [dob, setdob] = useState("")
  const [state, setstate] = useState("")
  const [city, setcity] = useState("")
  const [branch, setbranch] = useState("")
  const [address, setaddress] = useState("")
  const [pincode, setpincode] = useState("");
  const [photo, setphoto] = useState("");
  const [idproof, setidproof] = useState("");
  const [medicleProof, setmedicleProof] = useState("");

  useEffect(() => {
    if (user) {
      setname(user?.name);
      setmobile(user?.mobile);
      setemail(user?.email);
      setgender(user?.gender);
      setdob(user?.dob);
      setstate(user?.state);
      setcity(user?.city);
      setaddress(user?.Address);
      setpincode(user?.pincode);
      setphoto(user?.profile);
      setidproof(user?.addhar);
      setmedicleProof(user?.medicalproof);
    }
  }, []);
  const [courseData, setCourseData] = useState({})
  const updateUser = async () => {
    try {
      let obj = { userId: user?._id, name: name, mobile: mobile, email: email, city: city, area: branch, courseData: courseData, passportcopy: photo, addhar: idproof, medicalproof: medicleProof, dob: dob, Address: address, gender: gender, state: state, pincode: pincode }
      if (!name) return alert("Please fill the blank");
      if (!email) return alert("Please fill the blank");
      if (!mobile) return alert("Please fill the blank");
      if (!gender | !dob | !city | !branch | !address | !pincode | !photo | !idproof) return alert("Please complete the form")
      const config = {
        url: "/customer/updateUser",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj
      }
      let res = await axios(config);
      if (res.status == 200) {
        await sessionStorage.setItem("user", JSON.stringify(res.data.success))
        navigate("/timslot", { state: { data1: courseData, branch: branch } })
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  return (
    <div>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "130px",
            background: "black",
            overflow: " hidden",
            background: " #C33764",
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Courses</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "white" }}>&raquo;</span>
            </li>
            <li style={{ color: "white", fontSize: "18px" }}> Courses</li>
          </ul>
        </div>
      </div>
      {allCourse
        ?.slice(pagesVisited, pagesVisited + usersPerPage)
        ?.map((item) => {
          return (
            <div className="container">
              <div className=" rw1 row me-0">
                <div className="bg-48 col-lg-4 col-md-2 pt-5">
                  <img
                    src={`https://jaidrivingschool.in/Course/${item?.courseImage}`}
                    alt=""
                    style={{ width: "100%" }}
                    height={300}
                  />
                </div>
                <div className="col-lg-8 col-md-10 pt-5">
                  <h3>{item?.courseName} </h3>
                  <p>{item?.courseDuration} Days</p>
                  <div className="rw row me-0" style={{ marginTop: "-10px" }}>
                    {item?.coursePoint ? (<div className=" col-md-12 ">
                      <p> {parse(item?.coursePoint)}</p>
                    </div>) : ("")}




                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <h4>&#8377;{item?.price}</h4>
                    </div>
                    <div className="col-md-3">
                      <a onClick={() => navigate("/courseviewmore", { state: item })} className="btn btn-warning ">
                        Explore Now
                      </a>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-warning cbmd_09 "
                        onClick={() => {
                          setCourseData(item)
                          handleShow()
                        }}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill the Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-12">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Full name"
                  aria-label="Full name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-6">
                <label htmlFor="">Email ID</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  class="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  className="vi_0"
                />
              </div>
              <div class="col-md-6">
                <label htmlFor="">Mobile</label>
                <input
                  type="number" value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                  class="form-control"
                  placeholder="Mobile"
                  aria-label="Mobile"
                  className="vi_0"
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-6">
                <label htmlFor="">Gender</label>
                <select
                  class="form-select "
                  aria-label=".form-select-lg example"
                  className="vi_0"
                  onChange={(e) => setgender(e.target.value)}
                >
                  <option >{gender ? (gender) : ("Select gender")} </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div class="col-md-6">
                <label htmlFor="">DOB</label>
                <input
                  type="date"
                  value={dob}
                  onChange={(e) => setdob(e.target.value)}
                  class="form-control"
                  placeholder="Last name"
                  aria-label="Last name"
                  className="vi_0"
                />
              </div>
            </div>
          </div>

          <div className="do-sear mt-2">
            <div class="row me-0">

              <div class="col-md-6">
                <label for="inputState" className="form-label">
                  City
                </label>
                <select id="inputState" className=" vi_0" onChange={(e) => setcity(e.target.value)}>
                  <option selected>{user?.city ? (city) : ("Choose...")}</option>
                  {allCity?.map((item) => {
                    return (
                      <option>{item?.city}</option>
                    )
                  })}

                </select>
              </div>
              <div class="col-md-6">
                <label for="inputState" class="form-label ms-2">
                  Branch
                </label>
                <select className="vi_0" onChange={(e) => setbranch(JSON.parse(e.target.value))}>
                  <option >Choose...</option>
                  <option value={JSON.stringify({ branchName: "Main Branch" })}>Main Branch</option>
                  {allBranch?.filter((ele) => ele?.city == city)?.map((item) => {
                    return (
                      <option value={JSON.stringify(item)}>{item.branchName},{item?.area}</option>
                    )
                  })}

                </select>
              </div>
            </div>
          </div>

          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-6">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Address
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  placeholder="Please enter your address"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  className="vi_0"
                ></textarea>
              </div>
              <div class="col-md-6">
                <label for="inputZip" class="form-label">
                  Zip
                </label>
                <input type="text" placeholder="Please enter zip" value={pincode} onChange={(e) => setpincode(e.target.value)} className="form-control vi_0" id="inputZip" />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div style={{ display: "flex" }}>
                <div class=" col-md-6">
                  <label for="formFile" class="form-label" htmlFor="upload1">
                    Passport Size Photo
                  </label>
                  <input
                    class="form-control formselect vi_0"
                    type="file"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => setphoto(e.target.files[0])}
                  />
                </div>
                <div class=" col-md-6">
                  <label for="formFile" class="form-label" htmlFor="upload2">
                    Addhar
                  </label>
                  <input
                    class="form-control formselect vi_0"
                    type="file"
                    id="upload2"
                    onChange={(e) => setidproof(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row me-0">
            <div class="col-md-5">
              <label for="formFile" class="form-label" htmlFor="upload3">
                Medical Certificate
              </label>
              <input class="form-control" type="file" id="upload3"  onChange={(e)=>setmedicleProof(e.target.files[0])}/>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => updateUser()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Courses;
