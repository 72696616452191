import React, { useEffect, useState } from "react";
// import Sidebar from "./sidebar/Sidebar";
import Side from "./sidebar/Side";
import axios from "axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaEye } from "react-icons/fa";

const Profile = () => {

  let trainer=JSON.parse(sessionStorage.getItem("staff"));
let token=sessionStorage.getItem("token");
const [AllTrainerBook,setAlltrainer]=useState([]);
const [nochangedata,setnochangedata]=useState([])
  const getbookDetailsByTrainerId=async()=>{
    try {
      const config = {
        url: "/admin/getbookDetailsByTrainerId/"+trainer?._id+"/"+trainer?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`,"content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
        setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    if(trainer){
      getbookDetailsByTrainerId()
    }
  },[]);

  const makeAcceptAndReject=async(data,status)=>{
    try {
      const config = {
        url: "/admin/MakeAcceptAndReject",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`,"content-type": "application/json" },
        data:{
          authId:trainer?._id,
          bookId:data?._id,trainerId:trainer?._id,trinerStatus:status,trinerName:trainer?.name,trainerMobile:trainer?.mobile
        }
      };
      let res=await axios(config)
      if(res.status==200){
        alert(res.data?.success);
        getbookDetailsByTrainerId();
      }

    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  }
  const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 5;
 const pagesVisited = pageNumber * usersPerPage;
 const pageCount = Math.ceil(AllTrainerBook?.filter((ele)=>ele?.trinerStatus !=="Rejected")?.length / usersPerPage);
 const changePage = ({ selected }) => {
   setPageNumber(selected);
 };
 const [search, setSearch] = useState("");
 const handleFilter = (e) => {
   if (e.target.value != "") {
     setSearch(e.target.value);
     const filterTable = nochangedata.filter((o) =>
       Object.keys(o).some((k) =>
         String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
       )
     );
     setAlltrainer([...filterTable]);
   } else {
     setSearch(e.target.value);
     setAlltrainer([...nochangedata]);
   }
 };
 const [showAt,setshowAt]=useState(false);
 const [courseD,setcourseD]=useState({});
 const [AllAttandance,setAllAttandance]=useState([]);
 const getAttedance=async(id)=>{
   try {
     let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassBycustomerId/"+id);
     if(res.status==200){
       setAllAttandance(res.data.success);
     }
   } catch (error) {
     console.log(error);
   }
 }
  return (
    <div>
      
      <div className="mt-3" >
        <Side />
      </div>
      <div className="container">
        <div className="row ">
         <div className="cs">
         <div className=" mt-3 ">
            <h4>Assigned Student List</h4>
            <div className="row">
            <div className="col-3">
            <input type="text" placeholder="Search...." className="vi_0" style={{marginLeft:"-20px"}} onChange={(e)=>handleFilter(e)}/>
          </div>
            </div>
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th> Student Name</th>
                  <th>Course Details</th>
                  <th>Scheduled Time</th>
                  <th>Car Model</th>
                  <th>No. of Course Days</th>
                  <th>Start date of course</th>
                  <th>End Date of Course</th>
                  <th>Status</th>
                  <th>Trainer Status </th>
                  <th>View Classes</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllTrainerBook?.filter((ele)=>ele?.trinerStatus !=="Rejected")?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return (
                     <tr>
                  <td>{i+1}</td>
                  <td>{item?.customerName}</td>
                  <td>{item?.courseTitle}</td>
                  <td>{item?.courseTiming}</td>
                  <td>{item?.vechicleId?.Brand}</td>
                  <td>{item?.courseDuration}</td>
                  <td>{moment(item?.courseStartDate)?.format("DD/MM/YYYY")}</td>
                  <td>{moment(item?.courseEndDate)?.format("DD/MM/YYYY")}</td>
                  <td>
                     {item?.status=="Pending" ? (<span style={{color:"blue"}}>{item?.status}</span>):(<span style={{color:"green"}}>{item?.status=="Assigned" ? (<span style={{color:"Red"}}>{item?.status}</span>):(<span>{item?.status}</span>)}</span>)}
                    </td>

                    <td>
                     {item?.trinerStatus=="Pending" ? (<span style={{color:"blue"}}>{item?.trinerStatus}</span>):(<span style={{color:"green"}} >{item?.trinerStatus=="Assigned" ? (<span style={{color:"Red"}}>{item?.trinerStatus} {" "} </span>):(<span>{item?.trinerStatus} {" "}</span>)}</span>)}
                    </td>
                    <td><FaEye style={{color:"blue",fontSize:"25px",cursor:"pointer"}} onClick={()=>{
                                      setcourseD(item)
                                      getAttedance(item?.customerId?._id)
                                      setshowAt(true)
                                    }}/></td>
                    <td style={{display:"flex",gap:"5px"}}>
                    {item?.trinerStatus=="Assigned"? (<>
                      <button type="button" class="btn btn-success" onClick={()=>makeAcceptAndReject(item,"Accepted")}>Accept</button>
                  <button type="button" class="btn btn-danger" onClick={()=>makeAcceptAndReject(item,"Rejected")}>Reject</button>
                    </>):<></> }
                    
                     </td>
                </tr>
                  )
                })}
               
              
               
              </tbody>
            </table>
            
              <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
          </div>
         </div>
        </div>
      </div>
      
      <Modal
            show={showAt}
            onHide={()=>setshowAt(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Classes And Attanence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="scrolll_bar mb-4">
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        {/* <th>S.No</th> */}
                        {/* <th>Id</th> */}
                        <th>Day</th>
                        <th>Topic Name</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Intial Reading</th>
                        <th>End Reading</th>
                        {/* <th>Trainer Name</th> */}
                        <th>Remark</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseD?.courseTopic?.map((item, i) => {
                        return (
                          <tr>
                            {/* <td>{i + 1}</td> */}
                        
                            <td>{item?.days}</td>
                            <td>{item?.topicName}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectdate}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectTime}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.endtime}</td>

                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.pickupAddress}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.dropAddress}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.remarks}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)?.length==0 ?(<span style={{color:"blue"}}>Pending</span>):(<span style={{color:"green"}}>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.status}</span>)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setshowAt(false)}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={handleSubmit}>
                Submit{" "}
              </Button> */}
            </Modal.Footer>
          </Modal>
    </div>
  );
};

export default Profile;
