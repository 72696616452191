import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
const AdminCount = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleClose3 = () => setShow3(false);
  const handleShow1 = () => setShow1(true);
  const handleShow3 = () => setShow3(true);
  const [count, setcount] = useState("");
  const [text, settext] = useState("");
  const [allcount, setallCount] = useState([]);

  const getallcount = async () => {
    try {
      let res = await axios("https://jaidrivingschool.in/api/admin/getcounthome");
      if (res.status == 200) {
        setallCount(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  // const [text,settext]=useState("");
  // const [title,settitle]=useState("");
  const AddCount = async () => {
    try {
      if (!count) return alert("Please enter count");
      if (!text) return alert("Please enter tittle")
      const config = {
        url: "/admin/AddCounthome",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          authId: admin?._id,
          count: count,
          text: text
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added")
        getallcount();
        handleClose();
        setcount("");
        settext("");
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }

  useEffect(() => {
    getallcount()
  }, [])
  const [editdata, seteditdata] = useState({});
  const UpdateCounts = async () => {
    try {
      const config = {
        url: "/admin/updatecountHome",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          authId: admin?._id,
          count: count,
          text: text,
          id: editdata?._id
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully updated")
        getallcount();
        handleClose3();
        setcount("");
        settext("");
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const [deleteId, setdeleteId] = useState("");
  const deleteCount = async () => {
    try {
      const config = {
        url: "/admin/deleteCount/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted")
        getallcount();
        handleClose1()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4" >
            <button onClick={handleShow}>Add Counts</button>

          </div>
        </div>

        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Counts</th>
                  <th>Title</th>

                  {/* <th>View</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allcount?.map((ele, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{ele?.count}</td>
                      {/* style={{ overflow: "hidden", overflowY: "scroll" }} */}
                      <td >
                        {ele?.text}
                      </td>


                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={() => {
                          seteditdata(ele)
                          handleShow3()
                        }} />{" "}
                        <Button type="button" variant="danger" onClick={() => {
                          setdeleteId(ele?._id);
                          handleShow1();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "20" }} />
                          </span>
                        </Button>

                      </td>

                      {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
              <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                    </tr>
                  )
                })}

              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Counts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Counts</label>
              <input type="text" placeholder="Enter count" className="vi_0" onChange={(e) => setcount(e.target.value)} />
            </div>

            <div className="do-sear mt-2">
              <label>Title</label>
              <input type="text" placeholder="Enter Title" className="vi_0" onChange={(e) => settext(e.target.value)} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AddCount}>Submit</Button>
          </Modal.Footer>
        </Modal>
        {/* Edit Model */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Counts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Counts</label>
              <input type="text" placeholder={editdata?.count} className="vi_0" onChange={(e) => setcount(e.target.value)} />
            </div>

            <div className="do-sear mt-2">
              <label>Title</label>
              <input type="text" placeholder={editdata?.text} className="vi_0" onChange={(e) => settext(e.target.value)} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
            <Button variant="primary" onClick={UpdateCounts}>Submit</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ?? </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteCount}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminCount;
