import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminLogo = () => {
  const handleChange = (e, editor) => {
    const data = editor.getData();
    sethomeContact(data);
  };
  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    sethomeEmai(data);
  };
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    sethomeLocation(data);
  };
  const handleChange3 = (e, editor) => {
    const data = editor.getData();
    sethomeWhatsApp(data);
  };
  const handleChange4 = (e, editor) => {
    const data = editor.getData();
    setaddress(data);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [AllHomeLogo, setAllHomeLogo] = useState([]);
  const getHomeLogo = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getHomeLogo");
      if (res.status == 200) {
        setAllHomeLogo(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHomeLogo();
  }, []);
  const [contactNumber, setcontactNumber] = useState("");
  const [homeContact, sethomeContact] = useState("");
  const [homeEmai, sethomeEmai] = useState("");
  const [homeLocation, sethomeLocation] = useState("");
  const [homeWhatsApp, sethomeWhatsApp] = useState("");
  const [Image, setImage] = useState("");
  const [address, setaddress] = useState("");
const [contactEmail,setcontactEmail]=useState("");
const [contactWhatsApp,setcontactWhatsApp]=useState("")


  const AddHomeLogo = async () => {
    try {
      const config = {
        url: "/admin/AddHomeLogo",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
            homeContact: homeContact,
            homeEmai: homeEmai,
            homeLocation: homeLocation,
            address: address,
            homeWhatsApp: homeWhatsApp,
          image: Image,
          contactNumber: contactNumber,
          contactEmail:contactEmail,
          contactWhatsApp:contactWhatsApp,
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success)
        getHomeLogo();
        handleClose();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [deleteData,setdeleteData]=useState({});
  const [show4, setshow4] = useState(false);

  const handleClose4 = () => setshow4(false);
  const handleshow4 = () => setshow4(true);

  const deleteDataAboutus=async()=>{
    try {
      const config = {
        url: "/admin/updateHomeLogous",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: deleteData
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully deleted");
        handleClose4();
        getHomeLogo()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="add-gr">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                <h3>Home </h3>
                </div>
                <div className="col-md-4">
                      <div className="ad-b mt-4 mb-4" style={{ float: "right" }}>
            <button style={{ borderRadius:"10px"}} onClick={handleShow}>+ Home Page</button>
            <button style={{ borderRadius:"10px"}} onClick={handleShow3} >+ Contact Page</button>
          </div>
                    </div>
            </div>
        
        </div>

        <div className="container">
          <h4 className="text-center"> Home Page</h4>
          <div className="mo-gra mt-5 scrolll_bar">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Content</th>
                  <th colSpan={3}></th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {AllHomeLogo?.map((ele) => {
                return (
                  <tbody>
                    {ele?.image ? (   <tr height={60}>
                      <th width={150}>Logo Image</th>
                      <td colSpan={3} width={600}>
                        {ele?.image ? (<img
                          src={`https://jaidrivingschool.in/HomeLogo/${ele?.image}`}
                          alt=""
                          width={100}
                          height={50}
                        />) : (<></>)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({image:""})
                          handleshow4();
                        }} >
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                  
                    </tr>):(<></>)}
                 
                    {ele?.homeContact ? ( <tr height={60}>
                      <th width={150}>Contact Number</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.homeContact}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({homeContact:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                    
                    {ele?.homeEmai ? ( <tr height={60}>
                      <th width={150}>Email</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.homeEmai}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={handleShow} />
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({homeEmai:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                   
                    {ele?.homeWhatsApp ? ( <tr height={60}>
                      <th width={150}>WhatsApp Number</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.homeWhatsApp}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({homeWhatsApp:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                   
                    {ele?.homeLocation ? (    <tr height={60}>
                      <th width={150}>Location</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.homeLocation}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({homeLocation:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                
                  </tbody>
                )
              })}

            </Table>
          </div>
        </div>
        <div className="container">
          <h4 className="text-center">Contact Us Page</h4>
          <div className="mo-gra mt-5 scrolll_bar">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Content</th>
                  <th colSpan={3}></th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {AllHomeLogo?.map((ele) => {
                return (
                  <tbody>
                    {ele?.address ? ( <tr height={60}>
                      <th width={150}> Address</th>
                      <td colSpan={3} width={600}>
                        {ele?.address ? (<span>{ele?.address}</span>) : (<></>)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={handleShow3}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({address:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                   
                    {ele?.contactNumber ? (   <tr height={60}>
                      <th width={150}>Contact Number</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.contactNumber}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow3}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({contactNumber:""})
                          handleshow4();
                        }} >
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                 
                    {ele?.contactEmail ? (   <tr height={60}>
                      <th width={150}>WhatsApp Number</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.contactEmail}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow3}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({contactEmail:""})
                          handleshow4();
                        }} >
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                    {ele?.contactWhatsApp ? (   <tr height={60}>
                      <th width={150}>Email</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.contactWhatsApp}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow3}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({contactWhatsApp:""})
                          handleshow4();
                        }} >
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                  </tbody>
                )
              })}

            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Home Page</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label htmlFor="upload1">Home Logo Image</label>
              <input type="file" name="file" id="upload1" className="vi_0" onChange={(e) => setImage(e.target.files[0])} />
            </div>
            <div className="do-sear mt-2">
              <label>Contact Number</label>
              <input type="text" maxLength={10}  placeholder="Enter Contact Number" value={homeContact} id="upload1" className="vi_0" onChange={(e) => sethomeContact(e.target.value)} />
            </div>
            <div className="do-sear mt-2">
              <label>Email</label>
              <input type="email"  placeholder="Enter Email" value={homeEmai} id="upload1" className="vi_0" onChange={(e) => sethomeEmai(e.target.value)} />
            </div>
            <div className="do-sear mt-2">
              <label>WhatsApp Number</label>
              <input type="text" maxLength={10}  placeholder="Enter whats app number" value={homeWhatsApp} id="upload1" className="vi_0" onChange={(e) => sethomeWhatsApp(e.target.value)} />

            </div>
            <div className="do-sear mt-2">
              <label>Home Location</label>
              <input type="text" maxLength={10}  placeholder="Enter home location" value={homeLocation} id="upload1" className="vi_0" onChange={(e) => sethomeLocation(e.target.value)} />

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AddHomeLogo}>Submit</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title> Contact us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label htmlFor="upload2">Contact Number</label>
              <input type="text" className="vi_0" value={contactNumber} placeholder="Enter Contact Number" onChange={(e) => setcontactNumber(e.target.value)} />
            </div>
            <div className="do-sear mt-2">
              <label>address</label>
              <input type="text" className="vi_0" value={address} placeholder="Enter address" onChange={(e) => setaddress(e.target.value)} />
            </div>
            <div className="do-sear mt-2">
              <label>Email</label>
              <input type="email" className="vi_0" value={contactEmail} placeholder="Enter Email" onChange={(e) => setcontactEmail(e.target.value)} />
            </div>
            <div className="do-sear mt-2">
              <label>Whats App Number</label>
              <input type="text" className="vi_0" value={contactWhatsApp} placeholder="Enter WhatsApp Number" onChange={(e) => setcontactWhatsApp(e.target.value)} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
            <Button variant="primary" onClick={AddHomeLogo}>Submit</Button>
          </Modal.Footer>
        </Modal>


        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title> Are you sure delete ?</Modal.Title>
          </Modal.Header>
         
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteDataAboutus}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminLogo;
