import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";
import useRazorpay from "react-razorpay";
import Table from "react-bootstrap/Table";
const Instructors = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const {state}=useLocation()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleshow1 = () => setShow1(true);
  const navigate = useNavigate();
  const [date,setdate]=useState("");
  const [time,settime]=useState("");


const [AllAditional, setAllAditional] = useState([]);
const getAllAditional = async () => {
  try {
    const res = await axios.get(
      "https://jaidrivingschool.in/api/admin/getAllAdditional"
    );
    if (res.status == 200) {
      setAllAditional(res.data.success);
    }
  } catch (error) {
    console.log(error);
  }
};
  useEffect(()=>{
    getAllAditional()
    if(state){
      setdate(state?.date)
      settime(state?.time)
    }
  },[])
  const Razorpay = useRazorpay();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllAditional.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


let [add,setadd]=useState(0)

const [additionalCharge,setadditionalcharge]=useState([]);
const checkHand=(data)=>{
  
  let am=additionalCharge.filter((item)=>item?._id==data?._id)
  if(am.length!=0){
    let ab=(additionalCharge.filter((item)=>item?._id!==data?._id))
    setadditionalcharge(ab)
    setadd(ab.reduce((a,i)=>a+Number(i.price),0))
  }else{
    additionalCharge.push(data);
    setadd(additionalCharge.reduce((a,i)=>a+Number(i.price),0))
  }
 
}
// console.log("additional charge",additionalCharge);

const car=state?.car
const course=state?.course
const [payId,setpayid]=useState("");
const [PayMethod,setpayMethod]=useState("");


 

let PayAmount=(Number(course?.price)+Number(car.price)+Number(add))
const gst=(PayAmount*(car.Tax/100))
const user=JSON.parse(sessionStorage.getItem("user"))
const [traner,settrainer]=useState("")
let total=(PayAmount+gst)
let paymentStatus=""
let enddate=new Date(date);

const addedDate=enddate.setDate(enddate.getDate()+course?.courseDuration)

const token=sessionStorage.getItem("token");
const addAcountHistory=async(bookId)=>{
  try {
    const config={
      url: "/admin/addAccount",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
      headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"},
      data:{
        authId:user?._id,
        customeId:user?.customerId,bookingId:bookId,paymentId:payId,amount:total,status:"Cr"
      }
    }
    await axios(config);
  } catch (error) {
    console.log(error);
  }
}
const BookCourse=async()=>{
  try {
    
    if(!date) return alert("Please select date");
  
    if(!time) return alert("Please select time");

    let status="Pending"
    let pay=0
    if(payId){
      pay=total
      paymentStatus="Full Payment"
    }
    if(traner){
      status="Assigned"
    }
    const config={
      url: "/admin/createBooking",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
      headers: { "content-type": "application/json"},
      data:{
      gst:gst,paymentStatus:paymentStatus,vechicleId:car?._id,customerId:user?._id,dob:user?.dob,trinerStatus:status,number:user?.mobile,email:user?.email,customerName:user?.name,trainerId:traner?._id,trinerName:traner?.name,trainerMobile:traner?.mobile,courseId:course?._id,courseTitle:course?.courseName,coursePrice:course?.price,courseStartDate:date,courseEndDate:enddate,courseTiming:time,schoolBranch:user?.area,city:user?.city,totalAmount:total,paymentMethod:PayMethod,vechicleType:car?.vehicleType,payId:payId,payAmount:pay,Address:user?.Address,
      courseDuration:course?.courseDuration,courseTopic:course?.courseTopic,additional:additionalCharge,branchId:state.branch?._id,branchName:state?.branch?.branchName
      }
    }
    let res=await axios(config);
    if(res.status==200){
      if(payId){
        addAcountHistory(res.data.bookId)
      }
      alert("Successfully booked");
      window.location.assign("/scourse")
    }

  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}


const posttransaction = async () => {
  try {
    if(!PayMethod) return alert("Please select payment method")
    if (PayMethod=="Cash") {
      return BookCourse();
    }
    const config = {
      data: {
        key: "rzp_test_FAe0X6xLYXaXHe",
        amount: total* 100,
        currency: "INR",
        name: "Jai Driving School",
        description: "Order Amount",
        image: "../images/logo.png.jpg",
        customerId: user?._id,
        handler: function (response) {
          alert(response.razorpay_payment_id);
          setpayid(response.razorpay_payment_id);
        },
        prefill: {
          name: user?.name,
          email:user?.email,
          contact: user?.mobile,
        },
      },
    };
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Fail to load");
      return;
    }
    const paymentObject = new Razorpay(config.data);
    paymentObject.open();
  } catch (error) {
    console.log(error);
  }
};

useEffect(()=>{
  if(payId){
    BookCourse()
  }
},[payId])

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col">
            <h4>Select Additional Service</h4>
          </div>
          {/* <div className="container wertyuiop_67">
          <div className="row">
          <div class="col-md-4 ">
              <label for="disabledSelect" class="form-label">
                Selected Date - {date}
              </label>
              <input
                type="date"
                id="disabledTextInput"
                onChange={(e)=>setdate(e.target.value)}
                class="form-control"
                style={{ width: "270px" }}
              />
             
            </div>
            <div className="col-md-3">
            <label for="disabledSelect" class="form-label">
                Select Your Time
              </label>
              <Form.Select
                aria-label="Default select example"
                style={{ width: "250px" }}
                onChange={(e)=>settime(e.target.value)}
              >
                <option>{time}</option>
                {alltimeSlot?.map((ele)=>{
                  return (<option value={ele?.AvailableTime}>{ele?.AvailableTime}</option>)
                })}
              </Form.Select>
            </div>
            <div className="col-md-5">
              <button
                className="btn btn-warning"
                onClick={instructur}
                style={{ float: "right", marginTop: "30px" }}
              >
                Update
              </button>
            </div>
          </div>
        </div> */}
        </div>
      </div>
      <br/>
      <div className="container-fluid xcvbnm_012">
        <div  style={{margin:"0% 20% 0% 20%"}}>
      
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Service </th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                {AllAditional?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((ele, i) => {
                  return (
                    <tr>
                      <td>      <input type="checkbox"   style={{width:"20px",height:"20px",background:"red"}} onChange={()=>checkHand(ele)}/></td>
                      <td>{ele?.text}</td>
                      <td>₹ {ele?.price}</td>
                    </tr>
                  );
                })}
                <td></td>
                   <td></td>
                   <td style={{padding:"5px",fontWeight:"bold"}}>Total Price :-{total?.toFixed(2)}</td>
               
                </tbody>
              </Table>
   
             
        </div>
 
        {/* <div className="row">
          {AllTrainer ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item)=>{
            return (
                <div className="col-md-3">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={`https://jaidrivingschool.in/Staff/${item?.profile}`} height={200} />
              <Card.Body>
                <Card.Title>{item?.name}</Card.Title>
                <Card.Text>
                  Location- {item?.city} <br />
                  Time- {item?.availableSlot?.map((ele)=>{
                    return (<span>{ele?.times}{" ,"}</span>)
                  })} <br />
               
                  Ratings -
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={()=>{
                    settrainer(item)
                    handleshow1()}}
                  style={{
                    color: "black",
                  }}
                >
                  Book
                </Button>
              </Card.Body>
            </Card>
          </div>
            )
          })}
        
     
        </div> */}

      </div>
<div className="row">
  <div className="col-md-4">
  <button
        type="submit"
        onClick={()=>navigate(-1)}
        class="btn btn-warning instructors_btn"
        style={{
          width: "7rem",
          float: "left",
          marginBottom: "20px",
          marginLeft: "50px",
        }}
      >
        Back
      </button>
  </div>
  <div className="col-md-4">
  <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
  </div>
  <div className="col-md-4">
    {additionalCharge?.length==0 ? (  <button
        type="submit"
        onClick={handleshow1}
        class="btn btn-warning instructors_btn"
        style={{
          width: "7rem",
          float: "right",
          marginBottom: "20px",
          marginRight: "50px",
        }}
      >
        Skip And Book
      </button>):(  <button
        type="submit"
        onClick={handleshow1}
        class="btn btn-warning instructors_btn"
        style={{
          width: "7rem",
          float: "right",
          marginBottom: "20px",
          marginRight: "50px",
        }}
      >
       Book Now
      </button>)}
  </div>
</div>
     
      {/* <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill the Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-10">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Full name"
                  aria-label="Full name"
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-5">
                <label htmlFor="">Email ID</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  aria-label="Email"
                />
              </div>
              <div class="col-md-5">
                <label htmlFor="">Mobile</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Mobile"
                  aria-label="Mobile"
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-5">
                <label htmlFor="">Gender</label>
                <select
                  class="form-select "
                  aria-label=".form-select-lg example"
                >
                  <option selected></option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Others</option>
                </select>
              </div>

              <div class="col-md-5">
                <label htmlFor="">DOB</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Last name"
                  aria-label="Last name"
                />
              </div>
            </div>
          </div>

          <div className="do-sear mt-2">
            <div class="row me-0">
              <div className="" style={{ display: "flex" }}>
                <div class="col-md-5">
                  <label for="inputState" class="form-label">
                    City
                  </label>
                  <select id="inputState" class="form-select formselect">
                    <option selected>Choose...</option>
                    <option>...</option>
                  </select>
                </div>
                <div class="col-md-5">
                  <label for="inputState" class="form-label ms-2">
                    State
                  </label>
                  <select id="inputState" class="form-select ms-2 formselect">
                    <option selected>Choose...</option>
                    <option>...</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-5">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Address
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                ></textarea>
              </div>
              <div class="col-md-5">
                <label for="inputZip" class="form-label">
                  Zip
                </label>
                <input type="text" class="form-control" id="inputZip" />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div style={{ display: "flex" }}>
                <div class=" col-md-5">
                  <label for="formFile" class="form-label">
                    Photo
                  </label>
                  <input
                    class="form-control formselect"
                    type="file"
                    id="formFile"
                  />
                </div>
                <div class=" col-md-5">
                  <label for="formFile" class="form-label">
                    ID Proof
                  </label>
                  <input
                    class="form-control formselect"
                    type="file"
                    id="formFile"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row me-0">
            <div class="col-md-5">
              <label for="formFile" class="form-label">
                Medical Certificate
              </label>
              <input class="form-control" type="file" id="formFile" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleshow1}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Payments Modes</h4>
          <Form>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Online"
                  name="group1"
                  type={type}
                  onClick={()=>setpayMethod("Online")}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="Cash"
                  onClick={()=>setpayMethod("Cash")}
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <div>
          <h5 style={{float:"right"}}className="mb-3" >Total Amount:- ₹ {total?.toFixed(2)}</h5></div>
          <div>
          <Button variant="secondary" onClick={handleClose1}  >
            Close
          </Button>
          <Button variant="primary" style={{marginLeft:"10px"}} onClick={posttransaction}>Book Now</Button></div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Instructors;
