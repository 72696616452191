import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import exportFromJSON from "export-from-json";
import moment from "moment";
import ReactPaginate from "react-paginate";

const VendorDlApplication = () => {
  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [makeActive, setMakeActive] = useState(true);
  const [ADLApplication, setADLApplication] = useState(true);
  const [DLApplication, SetDLApplication] = useState(false);
  const [Reslot, setReslot] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const [data, setdata] = useState([]);
  const [noschangedata, setnochangedata] = useState([]);

  const [fileName, setfileName] = useState("DL Application");
  const exportType = "xls";
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
    } else {
      alert("Enter file name to export");
    }
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      console.log(
        "amit check it",
        moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
      );
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setAllrtoDl(abc);
    setdata(abc);
    setfileName(
      `DL Application_${moment(fromd).format("DD/MM/YYYY")} to ${moment(
        tod
      ).format("DD/MM/YYYY")}`
    );
  };
  const [Name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [Id, setId] = useState("");
  const [branch, setbranch] = useState("");
  const [applicationId, setapplicationId] = useState("");
  const [applicationDate, setapplicationDate] = useState("");
  const [Attachment, setAttachment] = useState("");
  const [SlotDate, setSlotDate] = useState("");
  const [SlotTime, setSlotTime] = useState("");
  const [DLNumber, setDLNumber] = useState("");
  const [ValidFrom, setValidFrom] = useState("");
  const [ValidTill, setValidTill] = useState("");
  const [Document, setDocument] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [totalAmount, settotalAmount] = useState("");
  const [payAmount, setpayAmount] = useState("");
  const [branchId, setbranchId] = useState("");
  const [RTOName, setRTOName] = useState("");
  const [allRtoDl, setAllrtoDl] = useState([]);
  const [LLList, setLLList] = useState([]);
  const getAllRtoDL = async () => {
    try {
     let ab =admin?.branchId ? admin?.branchId: admin?._id
      let res = await axios(
        "https://jaidrivingschool.in/api/admin/getAllRTODLByBranch/"+ab
      );
      if (res.status == 200) {
        setAllrtoDl(res.data.success);
        setdata(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBr = (abc) => {
    if (abc !== "") {
      if (abc !== "Main Branch") {
        abc = JSON.parse(abc);
        setbranch(`${abc?.branchName},${abc?.area}`);
        setbranchId(abc?._id);
      } else {
        setbranch("Main Branch");
        setbranchId("");
      }
    }
  };
  const handleUser = (abc) => {
    if (abc !== "") {
      abc = JSON.parse(abc);
      setuserId(abc?._id);
      setName(abc?.name);
      setId(abc?.customerId);
    }
  };
  //post method Add Application form
  const AddRTODL = async () => {
    try {
        let ab =admin?.branchId ? admin?.branchId: admin?._id
      if(!Name||!applicationId||!applicationDate||!Attachment) return alert("Please fill the form")
      const config = {
        url: "/admin/addRTODrivingL",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          Name: Name,
          Id: Id,
          branchId: ab,
          userId: userId,
          branch: `${admin?.branchName},${admin?.area}`,
          applicationId: applicationId,
          applicationDate: applicationDate,
          RTOName: RTOName,
          Attachment: Attachment,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added");
        getAllRtoDL();
        handleClose3();
        setName("");
        setId("");
        setbranch("");
        setapplicationId("");
        setapplicationDate("");
        setAttachment("");
        setRTOName("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  //post method Add DL Number
  const [AddDl, setAddDl] = useState({});
  const AddRTODLList = async () => {
    if(!DLNumber||!ValidFrom||!ValidTill||!totalAmount||!Document) return alert("Please fill the form")
    try {
      // if (!city) return alert("Please enter city");

      const config = {
        url: "/admin/makeAddDrivingLNumberrtoll",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          DLNumber: DLNumber,
          ValidFrom: ValidFrom,
          ValidTill: ValidTill,
          totalAmount: totalAmount,
          Document: Document,
          Remarks: Remarks,
          id: AddDl?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added");
        getAllRtoDL();
        handleClose();
        setDLNumber("");
        setValidFrom("");
        setValidTill("");
        setDocument("");
        setRemarks("");
        settotalAmount("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const handleRTO = (abc) => {
    if (abc !== "") {
      abc = JSON.parse(abc);
      setRTOName(abc?.rtoName);

    }
  }
  const [RTOReslot, setRTOReslot] = useState({});
  const AddRTOReslot = async () => {
    try {
      // if (!city) return alert("Please enter city");
      if(!SlotDate||!SlotTime) return alert("Please fill the form")
      const config = {
        url: "/admin/makeUpdeteResoleDrivingLNumberrtoll",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          SlotDate: SlotDate,
          SlotTime: SlotTime,
          Remarks: Remarks,
          id: RTOReslot?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added");
        getAllRtoDL();
        handleClose1();
        setSlotDate("");
        setSlotTime("");
        setRemarks("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  // edit method for ll list
  const [show2, setShow2] = useState(false);
  const handleShow2 = (data) => {
    setShow2(true);
    setName(data?.Name);
    setbranch(data?.branch);
    setapplicationId(data?.applicationId);
    setId(data?.Id);
    setDLNumber(data?.DLNumber);
    setValidFrom(data?.ValidFrom);
    setValidTill(data?.ValidTill);
    settotalAmount(data?.totalAmount);
    setRemarks(data?.Remarks);
    setAttachment(data?.Attachment);
  };
  const handleClose2 = () => setShow2(false);
  const [edit, setedit] = useState({});
  const editRTODL = async () => {
    try {
      const config = {
        url: "/admin/updateRTODrivingL",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",

        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          Name: Name,
          Id: Id,
          branchId: branchId,
          userId: userId,
          branch: branch,
          applicationId: applicationId,
          DLNumber: DLNumber,
          ValidFrom: ValidFrom,
          ValidTill: ValidTill,
          Document: Document,
          Attachment: Attachment,
          totalAmount: totalAmount,
          Remarks: Remarks,
          id: edit?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        handleClose2();
        getAllRtoDL();
        setName("");
        setId("");
        setbranch("");
        setapplicationId("");
        setapplicationDate("");
        setDLNumber("");
        setValidFrom("");
        setValidTill("");
        setDocument("");
        setRemarks("");
        settotalAmount("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //branch
  const [allBranch, setallbranch] = useState([]);

  const getAllBranches = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranchByAdmin/" +
          admin?._id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        setallbranch(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [show4, setshow4] = useState(false);
  const handleShow4 = () => setshow4(true);
  const handleClose4 = () => setshow4(false);
  const [deleteId, setdeleteId] = useState("");
  const deleteRto = async () => {
    try {
      const config = {
        url: "/admin/deleteRTODrivingL/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose4();
        getAllRtoDL();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllCustomer, setAllCustomer] = useState([]);
  // const [nochangedata, setnochangedata] = useState([])
  const getCustomer = async () => {
    try {
      const config = {
        url: "/Customer/getAllCustomer",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
        // setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allRTO, setAllRTO] = useState([]);
  const getRTO = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllRTOByAdmin/" + admin?._id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      }
      );
      if (res.status == 200) {
        setAllRTO(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCustomer();
    getAllBranches();
    getAllRtoDL();
    getRTO();
  }, []);
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = noschangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllrtoDl([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllrtoDl([...noschangedata]);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allRtoDl.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      <div className="add-gr">
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-9  ">
              <span
                style={{ cursor: "pointer", fontSize: "20px" }}
                onClick={() => {
                  setADLApplication(true);
                  SetDLApplication(false);
                  setReslot(false);
                }}
              >
                DL Application List
              </span>
            </div>

            <div className="col-md-3 ad-b">
              <button onClick={handleShow3} style={{ float: "right" }}>
                {" "}
                Add Application{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row ">
            <div className="col-md-3 mt-4">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ height: "45px" }}
                  onChange={handleFilter}
                />
              </InputGroup>
            </div>
            <div className=" col-md-2 ">
              <label>From</label>
              <br />
              <input
                type="date"
                className="vi_0"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className=" col-md-2">
              <label>To</label>
              <br />
              <input
                type="date"
                className="vi_0"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2 mt-4 ">
              <button className="btn btn-success" onClick={searchDate}>
                Submit
              </button>
            </div>
            <div className="col-md-2 mt-4 ad-b">
              <button onClick={ExportToExcel}>Export Excel</button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ad-b">
              <div style={{ float: "right" }}>
                <button
                  onClick={() => {
                    SetDLApplication(true);
                    setADLApplication(false);
                    setReslot(false);
                  }}
                >
                  D L List
                </button>
                &nbsp;
                <button
                  onClick={() => {
                    setADLApplication(false);
                    SetDLApplication(false);
                    setReslot(true);
                  }}
                >
                  Reslot List
                </button>
              </div>
            </div>
          </div>
        </div>

        {ADLApplication ? (
          <>
            <div className="container">
              <div className="scroLl_bar mo-gra mt-5">
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th> Name</th>
                      <th>Id</th>
                      <th>Branch</th>
                      <th>RTO Name</th>
                      <th>Application No</th>
                      <th>Application Date</th>
                      <th>Application Document</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {allRtoDl?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((ele, i) => {
                    return (
                      <tbody>
                        <tr style={{ width: "200px" }}>
                          <td>{i + 1}</td>
                          <td>{ele?.Name}</td>
                          <td>{ele?.Id}</td>
                          <td>{ele?.branch}</td>
                          <td>{ele?.RTOName}</td>
                          <td>{ele?.applicationId}</td>
                          <td>{ele?.applicationDate}</td>
                          <td>
                            <img
                              src={`https://jaidrivingschool.in/RTO/${ele?.Attachment}`}
                              alt="img"
                              style={{ width: "75px", height: "75px" }}
                            />
                          </td>
                          <td>{ele?.status}</td>
                          <td>
                            {ele?.status == "Passed" ? (
                              ""
                            ) : (
                              <div className="ad-b">
                                <button
                                  onClick={() => {
                                    handleShow();
                                    setAddDl(ele);
                                  }}
                                >
                                  Add DL
                                </button>
                                <button
                                  onClick={() => {
                                    handleShow1();
                                    setRTOReslot(ele);
                                  }}
                                >
                                  Reslot
                                </button>
                              </div>
                            )}
                          </td>
                          <td style={{display:"flex",gap:"5px"}}>
                          <img
                                    src="./images/editing.png"
                                    alt=""
                                    width={30}
                                    onClick={() => {
                                      handleShow2(ele);
                                      setedit(ele);
                                    }}
                                  />{" "}
                            <Button
                              type="button"
                              variant="danger"
                              onClick={() => {
                                setdeleteId(ele?._id);
                                handleShow4();
                              }}
                            >
                              <span>
                                <RiDeleteBin6Line style={{ fontSize: "20" }} />
                              </span>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
                <br/>
                        <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              </div>
            </div>
          </>
        ) : (
          <>
            {DLApplication ? (
              <>
                <div className="container">
                  <div className="scroLl_bar mo-gra mt-5">
                    <Table
                      striped
                      bordered
                      hover
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          <th>S No.</th>
                          <th> Name</th>
                          <th>Id</th>
                          <th>Branch</th>
                          <th>RTO Name</th>
                          <th>Application No</th>
                          <th>D L Number</th>
                          <th>Valid From</th>
                          <th>Valid TiDL</th>

                          <th>Paymemt</th>
                          <th>Application Attachments</th>
                          <th>Action</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      {allRtoDl
                        ?.filter((item) => item?.status == "Passed")?.slice(pagesVisited, pagesVisited + usersPerPage)
                        ?.map((ele, i) => {
                          return (
                            <tbody>
                              <tr style={{ width: "200px" }}>
                                <td>{i + 1}</td>
                                <td>{ele?.Name}</td>
                                <td>{ele?.Id}</td>
                                <td>{ele?.branch}</td>
                                <td>{ele?.RTOName}</td>
                                <td>{ele?.applicationId}</td>
                                <td>{ele?.DLNumber}</td>
                                <td>{ele?.ValidFrom}</td>
                                <td>{ele?.ValidTill}</td>

                                <td>{ele?.totalAmount}</td>
                                <td>
                                  <img
                                    src={`https://jaidrivingschool.in/RTO/${ele?.Document}`}
                                    alt="img"
                                    style={{ height: "75px", width: "75px" }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src="./images/editing.png"
                                    alt=""
                                    width={30}
                                    onClick={() => {
                                      handleShow2(ele);
                                      setedit(ele);
                                    }}
                                  />{" "}
                                </td>
                                <td>{ele?.Remarks}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </Table>
                    <br/>
                        <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
                  </div>
                </div>
              </>
            ) : (
              <>
                {Reslot ? (
                  <>
                    <div className="container">
                      <div className="scrolll_bar mo-gra mt-5">
                        <Table
                          striped
                          bordered
                          hover
                          style={{ textAlign: "center" }}
                        >
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th> Name</th>
                              <th>Id</th>
                              <th>Branch</th>
                          <th>RTO Name</th>
                              <th>Application No</th>
                              <th>Slot Date</th>
                              <th>Slot Time</th>
                              <th>Payment Status</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          {allRtoDl
                            ?.filter((item) => item?.status == "Reslot")?.slice(pagesVisited, pagesVisited + usersPerPage)
                            ?.map((ele, i) => {
                              return (
                                <tbody>
                                  <tr style={{ width: "200px" }}>
                                    <td>{i + 1}</td>
                                    <td>{ele?.Name}</td>
                                    <td>{ele?.Id}</td>
                                    <td>{ele?.branch}</td>
                                <td>{ele?.RTOName}</td>
                                    <td>{ele?.applicationId}</td>
                                    <td>{ele?.SlotDate}</td>
                                    <td>{ele?.SlotTime}</td>
                                    <td>Paid/Pay later</td>
                                    <td>{ele?.Remarks}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                        </Table>
                        <br/>
                        <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add D L Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="do-sear mt-2">
            <label htmlFor="">Select Branch</label>
            <br />

            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => handleBr(e.target.value)}
            >
              <option value="">----Select Branch----</option>
              <option value="Main Branch">Main Branch</option>
              {allBranch?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>
                    {item?.branchName},{item?.area}
                  </option>
                );
              })}
            </select>
          </div> */}

          <div className="do-sear mt-2">
            <label>Customer/Student Name</label>
            <br />
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => {
                handleUser(e.target.value);
              }}
            >
              <option value="">----Select Student----</option>
              {AllCustomer?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>{item?.customerId}-{item?.name}</option>
                );
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Id</label>
            <input
              type="text"
              placeholder="Enter Id "
              className="vi_0"
              value={Id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear">
            <label htmlFor="">RTO Name</label>
            <select name="" id="" className="vi_0" onChange={(e) => { handleRTO(e.target.value) }}>
              <option value="">---Select RTO Name----</option>
              {allRTO?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>{item?.rtoName}</option>
                )
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Application No</label>
            <input
              type="text"
              placeholder="Enter Application No"
              className="vi_0"
              onChange={(e) => {
                setapplicationId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Application Date</label>
            <input
              type="date"
              placeholder=" "
              className="vi_0"
              onChange={(e) => {
                setapplicationDate(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Attachment</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              onChange={(e) => setAttachment(e.target.files[0])}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Slot Date</label>
            <input
              type="date"
              placeholder="Enter Area/City "
              className="vi_0"
              value="Slot Date"
              onChange={(e) => {
                setSlotDate(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Slot Time</label>
            <input
              type="time"
              placeholder="Enter Area/City "
              className="vi_0"
              onChange={(e) => {
                setSlotTime(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={AddRTODL}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add D L </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Enter D L Number</label>
            <br />

            <input
              type="text"
              placeholder=" Enter D L Number"
              className="vi_0"
              onChange={(e) => setDLNumber(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Valid From</label>
            <input
              type="date"
              placeholder="Enter Start Date "
              className="vi_0"
              onChange={(e) => {
                setValidFrom(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Valid Till</label>
            <input
              type="date"
              placeholder="Enter End Date "
              className="vi_0"
              onChange={(e) => {
                setValidTill(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Payment</label>
            <input
              type="text"
              placeholder="Enter Amount "
              className="vi_0"
              onChange={(e) => {
                settotalAmount(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Document</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              onChange={(e) => {
                setDocument(e.target.files[0]);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              placeholder="Enter Remarks "
              className="vi_0"
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddRTODLList}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Slot </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Slot Date</label>
            <br />

            <input
              type="date"
              placeholder=" Enter Number"
              className="vi_0"
              onChange={(e) => {
                setSlotDate(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Slot Time</label>
            <input
              type="date"
              placeholder="Enter Slot Time "
              className="vi_0"
              onChange={(e) => {
                setSlotTime(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              placeholder="Enter "
              className="vi_0"
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={AddRTOReslot}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update D L </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="do-sear mt-2">
            <label htmlFor="">Select Branch</label>
            <br />

            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => handleBr(e.target.value)}
            >
              <option value="">----Select Branch----</option>
              <option value="Main Branch">Main Branch</option>
              {allBranch?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>
                    {item?.branchName},{item?.area}
                  </option>
                );
              })}
            </select>
          </div> */}

          <div className="do-sear mt-2">
            <label>Customer/Student Name</label>
            <br />
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => {
                handleUser(e.target.value);
              }}
            >
              <option value="">----Select Student----</option>
              {AllCustomer?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>{item?.customerId}-{item?.name}</option>
                );
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Id</label>
            <input
              type="text"
              placeholder="Enter Id "
              className="vi_0"
              value={Id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Application No</label>
            <input
              type="text"
              placeholder="Enter Application No"
              className="vi_0"
              value={applicationId}
              onChange={(e) => {
                setapplicationId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label htmlFor="">Enter D L Number</label>
            <br />

            <input
              type="text"
              placeholder=" Enter L L Number"
              className="vi_0"
              value={DLNumber}
              onChange={(e) => {
                setDLNumber(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Valid From</label>
            <input
              type="date"
              placeholder="Enter Start Date "
              className="vi_0"
              value={ValidFrom}
              onChange={(e) => {
                setValidFrom(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Valid Till</label>
            <input
              type="date"
              placeholder="Enter End Date "
              className="vi_0"
              value={ValidTill}
              onChange={(e) => {
                setValidTill(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label htmlFor="upload1">Attachment</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              name="file"
              id="upload1"
              onChange={(e) => {
                setAttachment(e.target.files[0]);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label htmlFor="upload2">Document</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              name="file"
              id="upload2"
              onChange={(e) => {
                setDocument(e.target.files[0]);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              placeholder="Enter "
              className="vi_0"
              value={Remarks}
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              editRTODL();
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteRto}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorDlApplication;
