import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import exportFromJSON from "export-from-json";
import moment from "moment";
import {FaRegEdit} from "react-icons/fa"
import { Autocomplete, TextField } from "@mui/material";
import ReactPaginate from "react-paginate";

const VendorOtherService = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => {
    setShow2(true);
  };
  const handleClose2 = () => setShow2(false);
  const [show4, setshow4] = useState(false);
  const handleShow4 = () => setshow4(true);
  const handleClose4 = () => setshow4(false);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [Addotherservice, setAddotherservice] = useState(false);
  const [ServiceList, setServiceList] = useState(false);
  const [MainPage, setMainPage] = useState(true);
  const [Name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [Id, setId] = useState("");
  const [branch, setbranch] = useState("");
  const [applicationId, setapplicationId] = useState("");
  const [applicationDate, setapplicationDate] = useState("");
  const [Attachment, setAttachment] = useState("");
  const [SlotDate, setSlotDate] = useState("");
  const [SlotTime, setSlotTime] = useState("");
  const [LLNumber, setLLNumber] = useState("");
  const [ValidFrom, setValidFrom] = useState("");
  const [ValidTill, setValidTill] = useState("");
  const [Document, setDocument] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [branchId, setbranchId] = useState("");
  const [serviceName, setserviceName] = useState("");

  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");

  const [allBranch, setallbranch] = useState([]);
  const getAllBranches = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranchByAdmin/" +
          admin?._id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        setallbranch(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllCustomer, setAllCustomer] = useState([]);
  // const [nochangedata, setnochangedata] = useState([])
  const getCustomer = async () => {
    try {
      const config = {
        url: "/Customer/getAllCustomer",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllSerType, setAllSetType] = useState([]);
  const getServiceType = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getServiceType"
      );
      if (res.status == 200) {
        setAllSetType(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [data, setdata] = useState([]);
  const [AllOtherService,setAllOtherService]=useState([]);
const [nochangedata,setnochangedata]=useState([]);
const getAllOtherService=async()=>{
    let ab =admin?.branchId ? admin?.branchId: admin?._id
  try {
    let res=await axios.get("https://jaidrivingschool.in/api/admin/getAllotherServiceByBranch/"+ab);
    if(res.status==200){
      setAllOtherService(res.data.success);
      setnochangedata(res.data.success);
      setdata(res.data.success);
    }
  } catch (error) {
    console.log(error);
  }
}
  useEffect(() => {
    // getAllBranches();
    getCustomer();
    getServiceType();
    getAllOtherService()
  }, []);

  const top100Films = AllCustomer.map((item) => ({
    label: `${item?.customerId}-${item?.name}`,
    year: JSON.stringify(item),
  }));
  const [selectedValue, setSelectedValue] = useState(null);
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);

    if (newValue) {
      setName(JSON.parse(newValue.year)?.name);
      setId(JSON.parse(newValue.year)?.customerId);
      setuserId(JSON.parse(newValue.year)?._id);
    } else {
      setName("");
      setId("");
      setuserId("");
    }
  };

  const addServiceType = async () => {
    if (!serviceName) return alert("Please enter service name");
    // if(!Remarks) return alert("Please enter service name")
    let am = AllSerType?.filter(
      (ele) => ele?.serviceT?.toUpperCase() == serviceName?.toUpperCase()
    );
    if (am?.length !== 0) return alert(`${serviceName} already exits`);
    try {
      const config = {
        url: "/admin/addServiceType",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          serviceT: serviceName,
          remarks: Remarks,
          authId: admin?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        handleClose();
        setServiceList(true);
        getServiceType();
        setRemarks("");
        setserviceName("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [editServiceT, seteditServiceT] = useState({});
  const updateSeT = async () => {
    if (serviceName) {
      let am = AllSerType?.filter(
        (ele) => ele?.serviceT?.toUpperCase() == serviceName?.toUpperCase()
      );
      if (am?.length !== 0) return alert(`${serviceName} already exits`);
    }
    try {
      const config = {
        url: "/admin/ServiceTypeupdate",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          serviceT: serviceName,
          remarks: Remarks,
          authId: admin?._id,
          id: editServiceT?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose2();
        setAddotherservice(false);
        setMainPage(false);
        setServiceList(true);
        getServiceType();
        setRemarks("");
        setserviceName("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteSeT, setdeleteSeT] = useState("");
const DeleteSET=async()=>{
    try {
        const config = {
            url: "/admin/deleteServiceType/"+deleteSeT,
            method: "delete",
            baseURL: "https://jaidrivingschool.in/api",
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
            data:{
                authId:admin?._id
            }
          };
          let res=await axios(config);
          if(res.status==200){
            alert("Successfully deleted");
            handleClose4()
            getServiceType()
          }
    } catch (error) {
        console.log(error);
    }
}

const handleBr = (abc) => {
  if (abc !== "") {
    if (abc !== "Main Branch") {
      abc = JSON.parse(abc);
      setbranch(`${abc?.branchName},${abc?.area}`);
      setbranchId(abc?._id);
    } else {
      setbranch("Main Branch");
      setbranchId("");
    }
  }
};

const AddOtherservice=async()=>{
  try {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
    if(!Name) return alert("Please select user");
    // if(!branch) return alert("Please select branch");
    if(!serviceName) return alert("Please select service");
    if(!applicationId) return alert("Please enter application id");
    if(!applicationDate) return alert("Please select application date");
    
    const config = {
      url: "/admin/addOtherServices",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: {
        Name: Name,
        userId:userId,
        Id:Id,
        applicationId:applicationId,
        Remarks: Remarks,
        authId: admin?._id,
        applicationDate:applicationDate,
        branchId:ab,
        ServiceType:serviceName,
        Attachment:Attachment,
        branch:`${admin?.branchName},${admin?.area}`
      },
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully added");
      handleClose3()
      getAllOtherService()
      setAddotherservice(false);
      setMainPage(true);
      setServiceList(false);
      setSelectedValue("");
      setbranch("");
      setuserId("");
      setId("");
      setRemarks("");
      setName("");
      setapplicationId("");
      setapplicationId("");
      setserviceName("");
      setAttachment("");
    }
  } catch (error) {
    console.log(error);
  }
}

const [statusShow,setstatusShow]=useState(false);
const [statusA,setstatusA]=useState("");
const [changeId,setchangeId]=useState({})
const makeCompleteAndReject=async()=>{
  try {
    if(statusA=="Rejected"){
      if(!Remarks)return alert("Please enter remarks")
    }
    const config = {
      url: "/admin/MakeCompleteOrRejectOtherService",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        status:statusA,
        Remarks: Remarks,
        authId: admin?._id,
       id:changeId?._id
      },
    };
    let res=await axios(config);
    if(res.status==200){
      alert(res.data.success);
      setstatusShow(false);
      getAllOtherService()
      setRemarks("")
    }
  } catch (error) {
    console.log(error);
  }
}

const otheDeleteData=async()=>{
  try {
    const config = {
      url: "/admin/deleteOtherServices/"+deleteSeT+"/"+admin?._id,
      method: "delete",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
     
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
     handleClose4()
      getAllOtherService()
     
    }
  } catch (error) {
    console.log(error);
  }
}

const [othEShow,setEothShow]=useState(false);

const updateOtherService=async()=>{
  try {
    const config = {
      url: "/admin/updateOtherServices",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: {
        Name: Name,
        userId:userId,
        Id:Id,
        applicationId:applicationId,
        Remarks: Remarks,
        authId: admin?._id,
        applicationDate:applicationDate,
        // branchId:branchId,
        ServiceType:serviceName,
        Attachment:Attachment,
        // branch:branch,
        id:changeId?._id
      },
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Updated");
      setEothShow(false)
      getAllOtherService()
      setAddotherservice(false);
      setMainPage(true);
      setServiceList(false);
      setSelectedValue("");
      setbranch("");
      setuserId("");
      setId("");
      setRemarks("");
      setName("");
      setapplicationId("");
      setapplicationId("");
      setserviceName("");
      setAttachment("");
    }
  } catch (error) {
    console.log(error);
  }
}


const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(AllOtherService.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};


const handleFilter = (e) => {
  if (e.target.value != "") {
 
    const filterTable = nochangedata.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setAllOtherService([...filterTable]);
  } else {

    setAllOtherService([...nochangedata]);
  }
};


// const [noschangedata, setnochangedata] = useState([]);

const [fileName, setfileName] = useState("Other Service Application");
const exportType = "xls";
const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
};
const [fromd, setfromd] = useState("");
const [tod, settod] = useState("");
const searchDate = () => {
  if (!fromd) return alert("Please select from date");
  if (!tod) return alert("Please select to date");

  let std = fromd?.split("-");
  let entd = tod?.split("-");
  let startD = parseInt(std?.join(""));
  let endD = parseInt(entd?.join(""));
  let abc = nochangedata?.filter((ele) => {
    
    let abd = (ele?.applicationDate)?.split("-");
    let amd = parseInt(abd?.join(""));
    return startD <= amd && endD >= amd;
  });
  setAllOtherService(abc);
  setdata(abc);
  setfileName(
    `Other Service Application_${moment(fromd).format("DD/MM/YYYY")} to ${moment(
      tod
    ).format("DD/MM/YYYY")}`
  );
};
  return (
    <>
      <div className="add-gr">
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-9  ">
              <span
                style={{ cursor: "pointer", fontSize: "20px" }}
                onClick={() => {
                  setAddotherservice(false);
                  setServiceList(false);
                  setMainPage(true);
                }}
              >
                Other Services
              </span>
            </div>

            <div className="col-md-3 ad-b">
              <button onClick={()=>{
                 setId("");
                 setRemarks("");
                 setName("");
                 setapplicationId("");
                 setapplicationId("");
                handleShow3()}} style={{ float: "right" }}>
                {" "}
                Add Application{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row ">
            <div className="col-md-3 mt-4">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  onChange={handleFilter}
                  aria-describedby="basic-addon1"
                  style={{ height: "45px" }}
                />
              </InputGroup>
            </div>
            <div className=" col-md-2 ">
              <label>From</label>
              <br />
              <input type="date" className="vi_0" value={fromd} onChange={(e)=>setfromd(e.target.value)} />
            </div>
            <div className=" col-md-2">
              <label>To</label>
              <br />
              <input type="date" className="vi_0" value={tod} onChange={(e)=>settod(e.target.value)} />
            </div>
            <div className="col-md-2 mt-4 ">
              <button className="btn btn-success" onClick={searchDate}>Submit</button>
            </div>
            <div className="col-md-2 mt-4 ad-b">
              <button onClick={ExportToExcel}>Export To Excel</button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ad-b">
              <div style={{ float: "right" }}>
                <button
                  onClick={() => {
                    handleShow();
                  }}
                  style={{ backgroundColor: "#FCCA0B" }}
                >
                  + Service
                </button>
                &nbsp;
                {ServiceList ? (   <button
                  onClick={() => {
                    setAddotherservice(false);
                    setMainPage(true);
                    setServiceList(false);
                  }}
                >
                  Other Service List
                </button>):(   <button
                  onClick={() => {
                    setAddotherservice(false);
                    setMainPage(false);
                    setServiceList(true);
                  }}
                >
                  Service List
                </button>)}
             
              </div>
            </div>
          </div>
        </div>

        {MainPage ? (
          <>
            <div className="container">
              <div className="scroLl_bar mo-gra mt-5">
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th>Id</th>
                      <th> Name</th>
                  
                      <th>Service</th>
                      <th>Branch</th>
                      <th>Application No</th>
                      <th>Application Date</th>
                      <th>Application Document</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {AllOtherService  ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                      return(
                            <tr style={{ width: "200px" }}>
                      <td>{i+1}.</td>
                      <td>{item?.Id}</td>
                      <td>{item?.Name}</td>
                      
                      <td>{item?.ServiceType}</td>
                      <td>{item?.branch}</td>
                      <td>{item?.applicationId}</td>
                      <td>{item?.applicationDate}</td>
                      <td>
                        <a href={`https://jaidrivingschool.in/RTO/${item?.Attachment}`} target="_blank">
                        <img
                          src={`https://jaidrivingschool.in/RTO/${item?.Attachment}`}
                          alt="img"
                          style={{ width: "75px", height: "75px" }}
                        /></a>
                      </td>
                      <td>{item?.status=="Completed" ? (<span style={{color:"green"}}>{item?.status}{" "}{item?.Remarks}</span>):(<span>{item?.status=="Rejected" ? (<span style={{color:"red"}}>{item?.status}{" "}{item?.Remarks}</span>):(<span style={{color:"blue"}}>{item?.status}{" "}{item?.Remarks}</span>)}</span>)} </td>
                     
                      <td style={{display:"flex",gap:"3px"}}>
                        {item?.status!=="Completed" ? (<div style={{display:"flex",gap:"3px"}}><button type="button" class="btn btn-success" onClick={()=>{
                          setstatusA("Completed");
                          setchangeId(item);
                          setstatusShow(true)
                        }}>Complete</button><button type="button" class="btn btn-danger" onClick={()=>{
                          setstatusA("Rejected");
                          setchangeId(item);
                          setstatusShow(true)
                        }}>Reject</button></div>):(<></>)}
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => {
                            setdeleteSeT(item?._id)
                            handleShow4();
                          }}
                        >
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "20" }} />
                          </span>
                        </Button>
                        <FaRegEdit style={{color:"blue",fontSize:"45px",cursor:"pointer"}} onClick={()=>{
                           setId(item?.Id);
                           setchangeId(item);
                           setRemarks(item?.Remarks);
                           setapplicationId(item?.applicationId);
                           setapplicationDate(item?.applicationDate);
                           setRemarks(item?.Remarks);
                           setEothShow(true)
                        }}/>
                      </td>
                    </tr>
                      )
                    })}
                
                  </tbody>
                </Table>
                <br />
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </div>
          </>
        ) : (
          <>
                    <div className="container">
                      <div className="scrolll_bar mo-gra mt-5">
                        <Table
                          striped
                          bordered
                          hover
                          style={{ textAlign: "center" }}
                        >
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th> Services Name</th>

                              <th>Remarks</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {AllSerType?.map((item, i) => {
                              return (
                                <tr style={{ width: "200px" }}>
                                  <td>{i + 1}.</td>
                                  <td>{item?.serviceT}</td>
                                  <td>{item?.remarks}</td>

                                  <td>
                                    <img
                                      src="./images/editing.png"
                                      alt=""
                                      width={30}
                                      onClick={() => {
                                        seteditServiceT(item);
                                        handleShow2();
                                      }}
                                    />{" "}
                                    <Button
                                      type="button"
                                      variant="danger"
                                      onClick={() => {
                                        setdeleteSeT(item?._id);
                                        handleShow4();
                                      }}
                                    >
                                      <span>
                                        <RiDeleteBin6Line
                                          style={{ fontSize: "20" }}
                                        />
                                      </span>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
        )}
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Customer/Student Name</label>
            <br />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 460 }}
              value={selectedValue} // This sets the selected value
              onChange={handleAutocompleteChange} // Handle change event
              renderInput={(params) => (
                <TextField {...params} label="Customer/Student" />
              )}
            />
          </div>
          {/* <div className="do-sear mt-2">
            <label htmlFor="">Select Branch</label>
            <br />

            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) =>handleBr(e.target.value)}
            >
              <option value="">----Select Branch----</option>
              <option value={JSON.stringify({ branchName: "Main Branch" })}>Main Branch</option>
              {allBranch?.map((item) => {
                return (
                  <option
                    value={JSON.stringify(item)}
                  >{`${item?.branchName},${item?.area}`}</option>
                );
              })}
            </select>
          </div> */}

          <div className="do-sear mt-2">
            <label>Id</label>
            <input
              type="text"
              placeholder="Enter Id "
              value={Id}
              onChange={(e) => setId(e.target.value)}
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Service</label>
            <select name="" id="" className="vi_0" onChange={(e)=>setserviceName(e.target.value)}>
              <option value="">----Select Service---</option>
              {AllSerType?.map((item)=>{
                return (<option value={item?.serviceT}>{item?.serviceT}</option>)
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Application No</label>
            <input
              type="text"
              placeholder="Enter Application No"
              className="vi_0"
              value={applicationId}
              onChange={(e) => setapplicationId(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Application Date</label>
            <input
              type="date"
              placeholder=" "
              className="vi_0"
              value={applicationDate}
              onChange={(e) => setapplicationDate(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label htmlFor="upload1">Attachment</label>
            <input
              type="file"
              name="file"
              id="upload1"
              onChange={(e) => setAttachment(e.target.files[0])}
              placeholder="Enter Email Id "
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              value={Remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Enter Remarks "
              className="vi_0"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={AddOtherservice}>Submit</Button>
        </Modal.Footer>
      </Modal>

{/* update OtherService */}
<Modal
        show={othEShow}
        onHide={()=>setEothShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Customer/Student Name</label>
            <br />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 460 }}
              value={selectedValue} // This sets the selected value
              onChange={handleAutocompleteChange} // Handle change event
              renderInput={(params) => (
                <TextField {...params} label={`${changeId?.Id}-${changeId?.Name}`} />
              )}
            />
          </div>
          {/* <div className="do-sear mt-2">
            <label htmlFor="">Select Branch</label>
            <br />

            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) =>handleBr(e.target.value)}
            >
              <option value="">{changeId?.branch ?(changeId?.branch):("----Select Branch----")}</option>
              <option value={JSON.stringify({ branchName: "Main Branch" })}>Main Branch</option>
              {allBranch?.map((item) => {
                return (
                  <option
                    value={JSON.stringify(item)}
                  >{`${item?.branchName},${item?.area}`}</option>
                );
              })}
            </select>
          </div> */}

          <div className="do-sear mt-2">
            <label>Id</label>
            <input
              type="text"
              placeholder="Enter Id "
              value={Id}
              onChange={(e) => setId(e.target.value)}
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Service</label>
            <select name="" id="" className="vi_0" onChange={(e)=>setserviceName(e.target.value)}>
              <option value="">{changeId?.ServiceType ?(changeId?.ServiceType):("----Select Service---")}</option>
              {AllSerType?.map((item)=>{
                return (<option value={item?.serviceT}>{item?.serviceT}</option>)
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Application No</label>
            <input
              type="text"
              placeholder="Enter Application No"
              className="vi_0"
              value={applicationId}
              onChange={(e) => setapplicationId(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Application Date</label>
            <input
              type="date"
              placeholder=" "
              className="vi_0"
              value={applicationDate}
              onChange={(e) => setapplicationDate(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label htmlFor="upload1">Attachment</label>
            <input
              type="file"
              name="file"
              id="upload1"
              onChange={(e) => setAttachment(e.target.files[0])}
              placeholder="Enter Email Id "
              className="vi_0"
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              value={Remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Enter Remarks "
              className="vi_0"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setEothShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={updateOtherService}>Submit</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Service </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Enter Service Name</label>
            <br />

            <input
              type="text"
              placeholder=" Enter Service Name"
              className="vi_0"
              value={serviceName}
              onChange={(e) => setserviceName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              value={Remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Enter Remarks "
              className="vi_0"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addServiceType}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update service </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Enter Service Name</label>
            <br />

            <input
              type="text"
              placeholder={editServiceT?.serviceT}
              className="vi_0"
              value={serviceName}
              onChange={(e) => setserviceName(e.target.value)}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              value={Remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder={editServiceT?.remarks}
              className="vi_0"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={updateSeT}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          {ServiceList ? ( <Button variant="success" onClick={DeleteSET}>Ok</Button>):( <Button variant="success" onClick={otheDeleteData}>Ok</Button>)}
         
        </Modal.Footer>
      </Modal>

      <Modal
            show={statusShow}
            onHide={()=>setstatusShow(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Application Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    placeholder="Enter your remarks"
                    className="vi_0"
                    value={Remarks}
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>
              </div>
            
            </Modal.Body>
            <Modal.Footer>
            
              <Button variant="secondary" onClick={()=>setstatusShow(false)}>
                Close
              </Button>
                   <Button variant="success" onClick={makeCompleteAndReject}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
    </>
  );
};

export default VendorOtherService;
