import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const AdminSafetyRules = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [category,setcategory]=useState("");
const [discrioption,setdiscription]=useState("");
  const handleShow1 = (data) => {
    setcategory(data?.category);
    setdiscription(data?.discription);
    setShow1(true)};
  const handleClose1 = () => setShow1(false);
  const admin=JSON.parse(sessionStorage.getItem("admin"));
  const token=sessionStorage.getItem("token");
  const [AllSafetyRule,setAllgraph]=useState([]);
  const getAllSafetyRules=async()=>{
    try {
      const res=await axios.get("https://jaidrivingschool.in/api/admin/getSAFETYRULE");
      if(res.status==200){
        setAllgraph(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getAllSafetyRules()
  },[])
const [image,setimage]=useState("");


const AddSafetyRule=async()=>{
  try {
    if(!image) return alert("Please select image")
    if(!category) return alert("Please enter title")
    if(!discrioption) return alert("Please enter description")
    const config={
      url: "/admin/addSAFETYRULE",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
       data: {authId:admin?._id,category:category,discription:discrioption,Image:image}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully added");
      handleClose();
      getAllSafetyRules();
      setimage("");
      setcategory("");
      setdiscription("")
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
const handleChange1 = (e, editor) => {
  const data = editor.getData();
  setdiscription(data);
};
const [viewImge,setViewImage]=useState("")
const [edit,setedit]=useState({})
const editGraph=async()=>{
  try {
    const config={
      url: "/admin/SAFETYRULEupdate",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
       data: {authId:admin?._id,category:category,discription:discrioption,id:edit?._id,Image:image}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Updated");
      handleClose1();
      getAllSafetyRules();
      setimage("");
      setcategory("");
      setdiscription("")
    }
  } catch (error) {
    console.log(error);
  }
}
const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 5;
 const pagesVisited = pageNumber * usersPerPage;
 const pageCount = Math.ceil(AllSafetyRule.length / usersPerPage);
 const changePage = ({ selected }) => {
   setPageNumber(selected);
 };

const [show4,setshow4]=useState(false);
const handleShow4=()=>setshow4(true);
const handleClose4=()=>setshow4(false);
const [deleteId,setdeleteId]=useState("");
const deleteGraph=async()=>{
  try {
    const config={
      url: "/admin/deleteSAFETYRULE/"+deleteId+"/"+admin?._id,
      method: "delete",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
      //  data: {authId:admin?._id,titele:title,link:link,image:image,id:edit?._id}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
      handleClose4();
      getAllSafetyRules();
    
    }
  } catch (error) {
    console.log(error);
  }
}
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
          <div className="col-md-6">
              <div className="ad-b mt-4">
                <button>Safety Rules Details</button>
               
              </div>
            </div>
            <div className="col-md-6">
              <div className="ad-b mt-4">
                <button style={{borderRadius:"10px",float:"right"}} onClick={()=>{
                   setcategory("");
                   setdiscription("")
                  handleShow()}}>+Add</button>
               
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                
                  <th>Image </th>
                  <th>Title</th>
                  <th>Description</th>
                
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {AllSafetyRule?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return (<tr>
                    <td>{i+1}</td>
                    <td><img src={`https://jaidrivingschool.in/SafetyRule/${item?.Image}`} style={{width:"100px",height:"100px"}}/></td>
                    <td>{item?.category}</td>
                    <td>{item?.discription ? (parse(item?.discription)):""}</td>
                   
                    <td>
                      <img src="./images/editing.png" alt="" width={30} onClick={()=>{
                        setedit(item)
                        handleShow1(item)
                      }} />
                    </td>
                    <td>
                    <Button type="button" variant="danger" onClick={()=>{
                                setdeleteId(item?._id);
                                handleShow4();
                              }}>
                                <span>
                                  <RiDeleteBin6Line style={{fontSize:"25"}} />
                                </span>
                              </Button>
                    </td>
                    {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
            <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                  </tr>)
                })}
                
              </tbody>
            </Table>
            <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Safety Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="do-sear mt-2">
            <label htmlFor="upload1">Image</label>
            <input type="file" name="file" id="upload1" accept="image/*" onChange={(e)=>setimage(e.target.files[0])} className="vi_0" placeholder="Please enter question" />
          </div>
          <div className="do-sear mt-2">
            <label>Title</label>
            <input type="text" onChange={(e)=>setcategory(e.target.value)} className="vi_0" placeholder="Please enter title" />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
                  editor={ClassicEditor}
                  data={discrioption}
                  onChange={handleChange1}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddSafetyRule}>Submit</Button>
        </Modal.Footer>
      </Modal>
     
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <h4>Text: Banner 1</h4> */}
            <img src={viewImge} alt="" width="100%"/>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          {/* <Button variant="primary">Submit</Button> */}
        </Modal.Footer>
      </Modal>
      {/* edit Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Updated Safety Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="do-sear mt-2">
            <label htmlFor="upload1">Image</label>
            <input type="file" name="file" id="upload1" accept="image/*" onChange={(e)=>setimage(e.target.files[0])} className="vi_0" placeholder="Please enter question" />
          </div>
          <div className="do-sear mt-2">
            <label>Title</label>
            <input type="text" value={category} onChange={(e)=>setcategory(e.target.value)} className="vi_0" placeholder="Please enter title" />
          </div>
          <div className="do-sear mt-2">
            <label>Description</label>
            <CKEditor
                  editor={ClassicEditor}
                  data={discrioption}
                  onChange={handleChange1}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editGraph}>Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* deleteMode */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>
      
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteGraph}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminSafetyRules;
