import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
const VendorAddVechile = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");
  const [nochangedata, setnochangedata] = useState([]);
  const [allVehicle, setallVehicle] = useState([]);
  const getAllVehicle = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllVehicleByBranchId/" +
         ab +
          "/" +
          admin?._id,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (res.status == 200) {
        setallVehicle(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallVehicle([...filterTable]);
    } else {
      setSearch(e.target.value);
      setallVehicle([...nochangedata]);
    }
  };

  const [vehicleType, setvehicleType] = useState("");
  const [Brand, setBrand] = useState("");
  const [year, setyear] = useState("");
  const [Tax, setTax] = useState("");
  const [RRCValidity, setRRCValidity] = useState("");
  const [Insurance, setInsurance] = useState("");
  const [vehicleNumber, setvehicleNumber] = useState("");
  const [price, setprice] = useState("");
  const [Emission, setEmission] = useState("");
  const [color, setcolor] = useState("");
  const [RRCValidityDate, setRRCValidityDate] = useState("");
  const [InsuranceDate, setInsuranceDate] = useState("");
  const [EmissionDate, setEmissionDate] = useState("");
  const [vehicleImage, setvehicleImage] = useState("");
  const [discription, setdiscription] = useState("");
  const [branch, setbranch] = useState(admin);

  const addVehicleDetails = async () => {
    try {
      if (!vehicleNumber) return alert("Please enter vehicle number");
      if (!vehicleType) return alert("Please enter vehicle class");
      if (!Brand) return alert("Please enter vehicle brand/year");
      if (!price) return alert("Please enter vehicle base price");
      // if(!vehicleImage) return alert("Please select vehicle image")
      if (!RRCValidity) return alert("Please select RC document");
      if (!RRCValidityDate) return alert("Please select RC Validity date");
      if (!Insurance) return alert("Please select insurance");
      if (!InsuranceDate) return alert("Please select  insurance vlidity date");
      if (!Emission) return alert("Please select emission");
      if (!EmissionDate) return alert("Please select emission date");
      // if (!discription) return alert("Please enter discrption");
      // if(!vehicleNumber) return alert("Please enter vehicle number")
      // if(!vehicleNumber) return alert("Please enter vehicle number")
      // if(!vehicleNumber) return alert("Please enter vehicle number")

      const config = {
        url: "/admin/addVehicle",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          vehicleType: vehicleType,
          Brand: Brand,
          Tax: Tax,
          RRCValidity: RRCValidity,
          Insurance: Insurance,
          vehicleNumber: vehicleNumber,
          Emission: Emission,
          RRCValidityDate: RRCValidityDate,
          price: price,
          InsuranceDate: InsuranceDate,
          EmissionDate: EmissionDate,
          vehicleImage: vehicleImage,
          discription: discription,
          branchId:admin?.branchId ? admin?.branchId: admin?._id,
          branchName: branch?.branchName
            ? `${branch?.branchName},${branch?.area}`
            : "Main Branch",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        getAllVehicle();
        handleClose();
        setdiscription("");
        setEmissionDate("");
        setInsuranceDate("");
        setInsurance("");
        setRRCValidityDate("");
        setRRCValidity("");
        setvehicleImage("");
        setTax("");
        setprice("");

        setBrand("");
        setvehicleType("");
        setvehicleNumber("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllVehicle();
  }, []);
  const [show1, setshow1] = useState(false);
  const handleClose1 = () => setshow1(false);
  const handleShow1 = () => setshow1(true);
  const [deleteId, setdeleteId] = useState("");
  const deleteVehicle = async () => {
    try {
      const config = {
        url: "/admin/DeleteVehicleById/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        getAllVehicle();
        handleClose1();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [show3, setshow3] = useState(false);
  const handleShow3 = () => setshow3(true);
  const handleClose3 = () => setshow3(false);
  const [editdata, seteditdata] = useState({});
  const updateVehicle = async () => {
    try {
      const config = {
        url: "/admin/updateVehicle",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          vehicleType: vehicleType,
          Brand: Brand,
          Tax: Tax,
          RRCValidity: RRCValidity,
          Insurance: Insurance,
          vehicleNumber: vehicleNumber,
          Emission: Emission,
          RRCValidityDate: RRCValidityDate,
          price: price,
          InsuranceDate: InsuranceDate,
          EmissionDate: EmissionDate,
          vehicleImage: vehicleImage,
          discription: discription,
          vehicleId: editdata?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        getAllVehicle();
        handleClose3();
        setdiscription("");
        setEmissionDate("");
        setInsuranceDate("");
        setInsurance("");
        setRRCValidityDate("");
        setRRCValidity("");
        setvehicleImage("");
        setTax("");
        setprice("");
        setBrand("");
        setvehicleType("");
        setvehicleNumber("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allVehicle.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [allVehType, setallVehType] = useState([]);
  const getAllVehicleType = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllVehicleType"
      );
      if (res.status == 200) {
        setallVehType(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicleType();
  }, []);

  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button> Vehicles </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button
                    style={{ borderRadius: "10px", float: "right" }}
                    onClick={handleShow}
                  >
                    + Add{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
              <div className="scrolll_bar">
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Vehicle No.</th>
                      <th> Vehicle Type</th>

                      <th>Vehicle Image</th>

                      <th>Brand/Year</th>
                      <th>Base Price</th>
                      <th>Tax</th>
                      <th>RRC Validity</th>
                      <th>Insurance</th>
                      <th>Emission</th>
                      {/* <th>Description</th> */}
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVehicle
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{ele?.vehicleNumber}</td>
                            <td>{ele?.vehicleType}</td>

                            <td>
                              {ele?.vehicleImage ? (
                                <img
                                  src={`https://jaidrivingschool.in/Vehicle/${ele?.vehicleImage}`}
                                  alt=""
                                  style={{
                                    height: "63px",
                                    width: "93px",
                                  }}
                                />
                              ) : (
                                <img
                                  src="../images/dummy.jpg"
                                  alt=""
                                  style={{
                                    height: "70px",
                                    width: "93px",
                                  }}
                                />
                              )}
                            </td>
                            <td>{ele?.Brand}</td>
                            <td>₹ {ele?.price} </td>
                            <td>
                              {ele?.Tax}
                              {"%"}
                            </td>
                            <td> <a href={`https://jaidrivingschool.in/Vehicle/${ele?.RRCValidity}`} target="_blank">
                              <img
                                src={`https://jaidrivingschool.in/Vehicle/${ele?.RRCValidity}`}
                                alt=""
                                width={50}
                              /></a>
                              <br />
                              {ele?.RRCValidityDate}
                            </td>
                            <td><a href={`https://jaidrivingschool.in/Vehicle/${ele?.Insurance}`} target="_blank">
                              <img
                                src={`https://jaidrivingschool.in/Vehicle/${ele?.Insurance}`}
                                alt=""
                                width={50}
                              /></a>
                              <br />
                              {ele?.InsuranceDate}
                            </td>
                            <td><a href={`https://jaidrivingschool.in/Vehicle/${ele?.Emission}`} target="_blank">
                              <img
                                src={`https://jaidrivingschool.in/Vehicle/${ele?.Emission}`}
                                alt=""
                                width={50}
                              /></a>
                              <br />
                              {ele?.EmissionDate}
                            </td>
                            {/* <td style={{ overflow: "hidden", overflowY: "scroll",height:"50px" }}>{ele?.discription}</td> */}
                            <td>
                              <img
                                src="./images/editing.png"
                                alt=""
                                width={30}
                                onClick={() => {
                                  seteditdata(ele);
                                  handleShow3();
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setdeleteId(ele?._id);
                                  handleShow1();
                                }}
                              >
                                <span>
                                  <RiDeleteBin6Line
                                    style={{ fontSize: "20" }}
                                  />
                                </span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div><br/>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Vehicle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
              <div className="do-sear mt-2">
                <label> Select Branch</label>
                <select className="vi_0" onChange={(e) => setbranch(JSON.parse(e.target.value))}>
                    <option value={JSON.stringify({})}>Main Branch</option>
                    {allBranches?.map((item)=>{
                      return (<option value={JSON.stringify(item)}>{item?.branchName},{item?.area}</option>)
                    })}
                </select>
              </div>
            </div> */}
            <div className="row">
              <div className="do-sear mt-2">
                <label>Vehicle No.</label>
                <input
                  type="text"
                  placeholder="Enter Vehicle No."
                  className="vi_0"
                  onChange={(e) => setvehicleNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Vehicle Type</label>
                <select
                  className="vi_0"
                  onChange={(e) => setvehicleType(e.target.value)}
                >
                  <option>Select</option>
                  {allVehType?.map((item) => {
                    return (
                      <option value={item?.vehicleType}>
                        {item?.vehicleType}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Brand/Year</label>
                <input
                  type="text"
                  placeholder="Enter Brand/Year"
                  className="vi_0"
                  onChange={(e) => setBrand(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Base Price</label>
                <input
                  type="number"
                  min="0"
                  placeholder="Enter base price"
                  className="vi_0"
                  onChange={(e) => setprice(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Tax</label>
                <input
                  type="number"
                  min="0"
                  placeholder="Enter tax"
                  className="vi_0"
                  onChange={(e) => setTax(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Vehicle Image</label>
                <input
                  type="file"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setvehicleImage(e.target.files[0])}
                  placeholder="Enter tax"
                  className="vi_0"
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload2">RRC Validity</label>
                <input
                  type="file"
                  className="vi_0"
                  id="upload2"
                  name="file"
                  onChange={(e) => setRRCValidity(e.target.files[0])}
                />
                <input
                  type="date"
                  placeholder="Enter validit date"
                  className="vi_0"
                  onChange={(e) => setRRCValidityDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload3">Insurance</label>
                <input
                  type="file"
                  className="vi_0"
                  id="upload3"
                  name="file"
                  onChange={(e) => setInsurance(e.target.files[0])}
                />
                <input
                  type="date"
                  placeholder="Enter Course Details"
                  className="vi_0"
                  onChange={(e) => setInsuranceDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload4">Emission</label>
                <input
                  type="file"
                  className="vi_0"
                  id="upload4"
                  name="file"
                  onChange={(e) => setEmission(e.target.files[0])}
                />
                <input
                  type="date"
                  placeholder="Enter Course Details"
                  className="vi_0"
                  onChange={(e) => setEmissionDate(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  className="vi_0"
                  onChange={(e) => setdiscription(e.target.value)}
                  placeholder="Enter Text Here"
                ></textarea>
              </div>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={addVehicleDetails}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Edit Model */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Vehicle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
              <div className="do-sear mt-2">
                <label> Select Branch</label>
                <select className="vi_0" onChange={(e) => setbranch(JSON.parse(e.target.value))}>
                    <option value={JSON.stringify({})}>{editdata?.branchName ? (editdata?.branchName):("Main Branch")} </option>
                    {allBranches?.map((item)=>{
                      return (<option value={JSON.stringify(item)}>{item?.branchName},{item?.area}</option>)
                    })}
                </select>
              </div>
            </div> */}
            <div className="row">
              <div className="do-sear mt-2">
                <label>Vehicle No.</label>
                <input
                  type="text"
                  placeholder={editdata?.vehicleNumber}
                  className="vi_0"
                  onChange={(e) => setvehicleNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Vehicle Type</label>
                <select
                  className="vi_0"
                  onChange={(e) => setvehicleType(e.target.value)}
                >
                  <option>
                    {editdata?.vehicleType ? editdata?.vehicleType : "Select"}
                  </option>
                  {allVehType?.map((item) => {
                    return (
                      <option value={item?.vehicleType}>
                        {item?.vehicleType}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Brand/Year</label>
                <input
                  type="text"
                  placeholder={editdata?.Brand}
                  className="vi_0"
                  onChange={(e) => setBrand(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Base Price</label>
                <input
                  type="number"
                  min="0"
                  placeholder={editdata?.price}
                  className="vi_0"
                  onChange={(e) => setprice(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Tax</label>
                <input
                  type="number"
                  min="0"
                  placeholder={editdata?.Tax}
                  className="vi_0"
                  onChange={(e) => setTax(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Vehicle Image</label>
                <input
                  type="file"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setvehicleImage(e.target.files[0])}
                  placeholder="Enter tax"
                  className="vi_0"
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload2">RRC Validity</label>
                <input
                  type="file"
                  className="vi_0"
                  id="upload2"
                  name="file"
                  onChange={(e) => setRRCValidity(e.target.files[0])}
                />
                <input
                  type="date"
                  placeholder="Enter validit date"
                  className="vi_0"
                  onChange={(e) => setRRCValidityDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload3">Insurance</label>
                <input
                  type="file"
                  className="vi_0"
                  id="upload3"
                  name="file"
                  onChange={(e) => setInsurance(e.target.files[0])}
                />
                <input
                  type="date"
                  placeholder="Enter Course Details"
                  className="vi_0"
                  onChange={(e) => setInsuranceDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload4">Emission</label>
                <input
                  type="file"
                  className="vi_0"
                  id="upload4"
                  name="file"
                  onChange={(e) => setEmission(e.target.files[0])}
                />
                <input
                  type="date"
                  placeholder="Enter Course Details"
                  className="vi_0"
                  onChange={(e) => setEmissionDate(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  className="vi_0"
                  onChange={(e) => setdiscription(e.target.value)}
                  placeholder={editdata?.discription}
                ></textarea>
              </div>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
            <Button variant="primary" onClick={updateVehicle}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete Model */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ?? </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteVehicle}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};
export default VendorAddVechile;
