import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';

const AdminFeatures = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleClose3=()=> setShow3(false)
  const handleShow1 = () => setShow1(true);
  const handleShow3 = () => setShow3(true);
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4" style={{ float: "right" }}>
            <button onClick={handleShow}>Add Text</button>
            <button onClick={handleShow1}>Add Image</button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Tittle</th>
                <th>Description</th>
                <th>View</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <img
                    src="./images/3.jpg"
                    alt=""
                    style={{ width: "75px", height: "75px" }}
                  />
                </td>
                <td>Car Driving</td>
                <td style={{ overflow: "hidden", overflowY: "scroll" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Explicabo consequuntur quo earum natus delectus facilis maxime
                  laboriosam debitis velit asperiores.
                </td>
                <td>
                   
                    <img src="./images/eye.png" width={30} alt="" onClick={handleShow3} style={{cursor:"pointer"}} />
                  
                </td>
                <td>
                  <div className="ad-b mt-4">
                    <button>Delete</button>
                    <button>Edit</button>
                  </div>
                </td>

                {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
          <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      {/* Add Model */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Tittle</label>
            <input type="text" placeholder="Enter Tittle" className="vi_0" />
          </div>

          <div className="do-sear mt-2">
            <label>Description</label>
            <textarea
              name=""
              id=""
              cols="30  "
              rows="5"
              placeholder="Enter Your Text Here "
              className="vi_0"
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Image</label>
            <input type="file" className="vi_0" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas show={show3} onHide={handleClose3}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AdminFeatures;
