import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Side from './sidebar/Side'
import axios from 'axios'
import moment from 'moment'

const Attendence = () => {
    const navigate = useNavigate()

    const user=JSON.parse(sessionStorage.getItem("staff"));
    const token=sessionStorage.getItem("token")

  
    //   const [image, setImage] = useState("");
    const [tableData, setTableData] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);

    const [booked,setbooked]=useState({});
    const [topic,settopic]=useState("");
    const [day,setday]=useState("");
    const [time,settime]=useState("");
    const [mobile,setmobile]=useState("");
    const [date,setdate]=useState("");
    const [student,setstudent]=useState("");
    let trainer=JSON.parse(sessionStorage.getItem("staff"));

    const [AllTrainerBook,setAlltrainer]=useState([]);
    const [nochangedata,setnochangedata]=useState([])
      const getbookDetailsByTrainerId=async()=>{
        try {
          const config = {
            url: "/admin/getbookDetailsByTrainerId/"+trainer?._id+"/"+trainer?._id,
            method: "get",
            baseURL: "https://jaidrivingschool.in/api",
            headers: { Authorization: `Bearer ${token}`,"content-type": "application/json" },
          };
          let res = await axios(config);
          if (res.status == 200) {
            setAlltrainer(res.data.success);
            setnochangedata(res.data.success)
          }
        } catch (error) {
          console.log(error);
        }
      }
      useEffect(()=>{
        if(trainer){
          getbookDetailsByTrainerId()
        }
      },[]);
    //   const handleImageChange = (event) => {
    //     setImage(URL.createObjectURL(event.target.files[0]));
    //   };



 

    const handleEdit = (index) => {
        
    };

    const getAttendanceByTrainerId=async()=>{
        try {
            let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassByTrainerId/"+user?._id);
            if(res.status==200){
                setTableData(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    }
useEffect(()=>{
    if(user){
        getAttendanceByTrainerId()
    }
},[])
console.log("class",tableData)
    return (
        <div>
            <Side />
            <div className='container-fluid '>
                <div className='row '>

                    <div className='col-md-7 mt-5'>
                        
                        
                    </div>
                </div>

            </div>
            <div className="container">
                <div className="row">
                    <div className="update">
                        <div>
                        <button type='submit' onClick={() => navigate("/addattendence")} className='btn btn-success'>Mark Absent</button>
                        <h1 className="">Updated Attendence Table</h1>
                        </div>
                        <div className="cs">
                            <table className="table table-bordered table-hover">
                                <thead  style={{backgroundColor:"#9d041b"}}>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Topic</th>
                                        <th>StartTime</th>
                                        <th>EndTime</th>
                                        <th>Student</th>
                                        <th>Day</th>
                                        <th>Date</th>
                                        <th>Remark</th>
                                        <th>Status</th>
                                      
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((data, index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{data?.classTopic}</td>
                                            <td>{moment(data?.picupTime,["hh:mm"]).format('hh:mm a')}</td>
                                            <td>{moment(data.endtime,["hh:mm"]).format('hh:mm a')}</td>
                                            <td>{data.StudentName}</td>
                                            <td>{data.classday} day</td>
                                            <td>{moment(data.selectdate).format("DD/MM/YYYY")}</td>
                                            <td>{data.remarks}</td>

                                            <td>
                                               {data?.status=="Complete" ? (<span style={{color:"green"}}>Present</span>):(<span>{data?.studentStatus}</span>)}
                                            </td>
                                            {/* <td>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => handleEdit(index)}
                                                >
                                                    Edit
                                                </button>
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Attendence