import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const ContactEnquireNow = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div class="container-fluid">
                <div className='row'>
                    <div className="col-md-6 bg-11">
                        <h4>Enquiry Form</h4>
                        <form action="" className="form1">
                            <label htmlFor="">Name</label>
                            <input type="text" placeholder="Enter your name" />
                            <br />
                            <label htmlFor="">E-mail ID</label>
                            <input type="email" placeholder="Enter your e-mail" />
                            <br />
                            <label htmlFor="">Mobile no</label>
                            <input type="number" placeholder="Enter your number" /><br />
                            <textarea name="" id="" cols="40" placeholder="Message....."></textarea>
                            <a href="" className="btn btn-warning mt-1">
                                Send Request
                            </a>
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactEnquireNow