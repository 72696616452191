import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import moment from "moment";
const AdminEnqire = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const admin=JSON.parse(sessionStorage.getItem("admin"));
  const token=sessionStorage.getItem("token");
  const [AllGraph,setAllgraph]=useState([]);
  const getAllgraph=async()=>{
    try {
        const config={
            url: "/admin/GetAllEnquire/"+admin?._id,
            method: "get",
            baseURL: "https://jaidrivingschool.in/api",
            headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"},
    
          }
      const res=await axios(config);
      if(res.status==200){
        setAllgraph(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getAllgraph()
  },[])

  // console.log("Enquires",AllGraph)
  const [subject,setsubject]=useState("");
  const [username, setusername] = useState("");
  const [useMobile, setuseMobile] = useState("");
  const [useEmail, setuseEmail] = useState("");
  const [comments, setcomments] = useState("");
  const CallNow = async () => {
    if (!username) return alert("Please enter your name");
    if (!useMobile) return alert("Please enter your mobile number");
    if (!useEmail) return alert("Please enter your email");
    if(!subject) return alert("Please write subject")
    if (!comments) return alert("Please enter your comments");
   
    try {
      const config = {
        url: "/admin/postEnquire",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          name: username,
          email: useEmail,
          mobile: useMobile,
          EnquireType: subject,
          comments: comments,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Added");
       
        handleClose();
        getAllgraph();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };



const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 15;
 const pagesVisited = pageNumber * usersPerPage;
 const pageCount = Math.ceil(AllGraph.length / usersPerPage);
 const changePage = ({ selected }) => {
   setPageNumber(selected);
 };

const [show4,setshow4]=useState(false);
const handleShow4=()=>setshow4(true);
const handleClose4=()=>setshow4(false);
const [deleteId,setdeleteId]=useState("");
const deleteGraph=async()=>{
  try {
    const config={
      url: "/admin/deleteEnquire/"+deleteId+"/"+admin?._id,
      method: "delete",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"},
      //  data: {authId:admin?._id,titele:title,link:link,day:day,id:edit?._id}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
      handleClose4();
      getAllgraph();
    
    }
  } catch (error) {

    console.log(error);
  }
}
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
          <div className="col-md-6">
              <div className="ad-b mt-4">
                <button>All Enquires</button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ad-b mt-4">
                <button
                  style={{ borderRadius: "10px", float: "right" }}
                  onClick={()=>{
                    setusername("");
                    setuseMobile("");
                    setuseEmail("");
                    setsubject("");
                    setcomments("");
                    handleShow()}}
                >
                  +Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
              <th>Date</th>
                  <th>Name</th>
                  
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Branch Name</th>
                  <th>Subject</th>
                  <th>Comments</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {AllGraph?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return (<tr>
                    <td>{i+1}</td>
                  <td>{moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.mobile}</td>
                    <td>{item?.branchId ? (item?.branchId?.branchName):"Main Branch"}</td>
                    <td>{item?.EnquireType}</td>
                    <td>{item?.comments}</td>
                
                    <td>
                    <Button type="button" variant="danger" onClick={()=>{
                                setdeleteId(item?._id);
                                handleShow4();
                              }}>
                                <span>
                                  <RiDeleteBin6Line style={{fontSize:"25"}} />
                                </span>
                              </Button>
                    </td>
                
                  </tr>)
                })}
                
              </tbody>
            </Table>
            <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
          </div>
        </div>
      </div>
 
     
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="do-sear mt-2">
            <label htmlFor="upload1">day</label>
            <input type="File" placeholder="Enter Userid" className="vi_0"  id="upload1" accept="day/*" onChange={(e)=>setday(e.target.files[0])}/>
          </div> */}

          <label htmlFor="">Name</label>
          <input
            type="text"
            className="vi_0"
            placeholder="Enter your name"
            value={username}
            onChange={(e) => setusername(e.target.value)}
          />
          <br />
          <label htmlFor="">Mobile no</label>
          <input
            type="number"
            className="vi_0"
            placeholder="Enter your number"
            value={useMobile}
            onChange={(e) => setuseMobile(e.target.value)}
          />
          <br />
          <label htmlFor="">E-mail ID</label>
          <input
            type="email"
            className="vi_0"
            placeholder="Enter your e-mail"
            value={useEmail}
            onChange={(e) => setuseEmail(e.target.value)}
          />
          <br />
          <label htmlFor="">Subject</label>
          <input
            type="email"
            className="vi_0"
            placeholder="Enter your subject"
            value={subject}
            onChange={(e) => setsubject(e.target.value)}
          />
          <br />
          <label htmlFor="">Message</label>
          <textarea
            name=""
            id=""
            cols="40"
            value={comments}
            className="vi_0"
            onChange={(e) => setcomments(e.target.value)}
            placeholder="Message....."
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={CallNow}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    
    
      {/* deleteMode */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>
      
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteGraph}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminEnqire;
