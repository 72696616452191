import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Side1 from "./sidebar1/Side1";

const StudentProfile = () => {
  const navigate = useNavigate()
  const user = JSON.parse(sessionStorage.getItem("user"))
  const token = sessionStorage.getItem("token")
  if (!user) {
    alert("Please login");
    window.location.assign("/")
  } else
    return (
      <div>
        <Side1 />

        <div className="container">
          <div className="ad-b mt-4 mb-3">
            {/* <button >Profile</button> */}
          </div>
          <div className="main-body">
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      {user?.profile ? (<img
                        src={`https://jaidrivingschool.in/Customer/${user?.profile}`}
                        alt="Admin"
                        className="rounded-circle"
                        width="150"
                        height="150px"
                      />) : (<img
                        src="./images/person1.jpg"
                        alt="Admin"
                        className="rounded-circle"
                        width="150"
                        height="150px"
                      />)}
                      <div className="mt-3">
                        <h4></h4>
                        <p className="text-secondary mb-1">{user?.name}</p>
                        <p className="text-muted font-size-sm">
                          {user?.city}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3 bnml_0">
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Full Name</label>
                          <p>
                            {user?.name}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Email</label>
                          <p>
                            {user?.email}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Mobile Number</label>
                          <p>
                            {user?.mobile}

                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Gender</label>
                          <p>
                            {user?.gender}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Referral no</label>
                          <p>
                            {user?.ToRefer}
                          </p>
                        </div>
                      </div> */}

                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">DOB</label>
                          <p>
                            {user?.dob}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">City</label>
                          <p>
                            {user?.city}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Pincode</label>
                          <p>
                            {user?.pincode}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Address</label>
                          <p>
                            {user?.Address}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-md-4 " id="studentprofile" style={{marginLeft:"-228px"}}>
                        <div className="do-sear mb-2" >
                          <label className="mb-0">User Type</label>
                          <p>
                            Student
                          </p>
                        
                        </div>
                      </div> */}
                      {/* <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Upload Photo</label>
                          <input
                            type="file"
                            placeholder=""
                            className="vi_0"
                          />
                        </div>
                      </div> */}
                      <div className="">
                        <div className="do-sear mb-2">
                          <button type='submit' className='btn btn-success mb-3' onClick={() => navigate("/updateprofile")}>Edit Profile</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    )
}

export default StudentProfile