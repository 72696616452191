import React from 'react'
import { useNavigate } from 'react-router-dom'

const OnlinePayment = () => {
  const navigate=useNavigate()
  return (
    <div>
      <div class="container-fluid">
        <div className='row'>
          <div className='col-md-4 enqlabel'>
            <form action="" className='enqform'>
             <h4>Payments Modes</h4>
             <h6>Select the type of payment:-</h6>
             <select name="" id="" style={{width:"12rem",marginLeft:"10px"}}>
              <option value="">Pay on cash</option>
              <option value="">Online payment</option>
             </select><br />
             <button type="submit" onClick={()=>navigate("/opayment")} class="btn btn-warning mt-5 ms-4">Submit</button>
            </form>
          </div>
        </div>

      </div>
    </div>
  )
}

export default OnlinePayment