import axios from "axios";
import React, { useEffect, useState } from "react";
import { json, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import ReactElasticCarousel from "react-elastic-carousel";
import Coursesss from "./Cousesss";
import { BiUserCircle } from "react-icons/bi";
import { Try } from "@mui/icons-material";
import parse from "html-react-parser";

const BlogViewMore = () => {
  const { id } = useParams();
  const [blog, setblog] = useState({});
  const getBlogById = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getBlogById/" + id
      );
      if (res.status == 200) {
        setblog(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allReviews, setallReviews] = useState([]);
  const getAllReviews = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getReview"
      );
      if (res.status == 200) {
        setallReviews(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 4 },
  ];
  useEffect(() => {
    if (id) {
      getBlogById();
    }
    getAllReviews();
  }, [id]);
  const uaer = JSON.parse(sessionStorage.getItem("user"));
  const trainer = JSON.parse(sessionStorage.getItem("staff"));

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [comment, setcomment] = useState("");
  useEffect(() => {
    if (uaer) {
      setname(uaer?.name);
      setemail(uaer?.email);
    } else if (trainer) {
      setname(trainer?.name);
      setemail(trainer?.email);
    }
  }, []);

  const makeComment = async (e) => {

    try {
      if (!name) return alert("Please enter your name");
      if (!email) return alert("Please enter your email");
      if (!comment) return alert("Please enter your comment");
      const config = {
        url: "AddCommentBlog",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api/admin/",
        headers: { "content-type": "application/json" },
        data: {
          id: blog?._id,
          name: name,
          email: email,
          comment: comment,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully commented");
        setcomment("")
        getBlogById();
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row rww">
          <h3 style={{ color: "rgb(157 4 27)" }}>{blog?.category}</h3>
          <h4>{blog?.title}</h4>
          {/* <p>{blog?.blog1}</p> */}

          <div className="col-md-6">
            <img
              src={`https://jaidrivingschool.in/Blog/${blog?.image}`}
              alt=""
              style={{ width: "100%" }}
              height={400}
            />
          </div>
          <div className="col-md-6">
            <p>{blog?.blog1 ? (parse(blog?.blog1)) : ""}</p>
          </div>
          <div className="col-md-6 mt-3">
            <p>{blog?.blog2 ? (parse(blog?.blog2)) : ""}</p>

          </div>
          <div className="col-md-6">
            <img
              src={`https://jaidrivingschool.in/Blog/${blog?.image1}`}
              alt=""

              height={400}
              style={{ float: "right", width: "100%" }}
            />
          </div>
          <div className="col-md-12">
            <p>{blog?.blog3 ? (parse(blog?.blog3)) : ""}</p>

          </div>

          <img src="./images/yellow.png" alt="" height={2} width={1110} />

          {/*     
                    <div class="card mb-3 mt-3" style={{ maxWidth: "540px" }}>
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src="/images/comment1.jpeg" class="img-fluid rounded-start" alt="..." />
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h6 class="card-title">By Johnson on may 27th</h6>
                                    <h6 class="card-text">Bacon ipsum dolor sit amet nulla ham qui sint exercitation eiusmod commodo, chuck duis velit.</h6>
                                    <h6>⭐⭐⭐⭐⭐</h6>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="card mb-3 mt-3" style={{maxWidth:"540px"}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src="/images/comment1.jpeg" class="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h6 class="card-title">By Johnson on may 27th</h6>
                                    <h6 class="card-text">Bacon ipsum dolor sit amet nulla ham qui sint exercitation eiusmod commodo, chuck duis velit.</h6>
                                    <h6>⭐⭐⭐⭐⭐</h6>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div class="card mb-3 mt-3" style={{maxWidth:"540px"}}>
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src="/images/comment1.jpeg" class="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h6 class="card-title">By Johnson on may 27th</h6>
                                    <h6 class="card-text">Bacon ipsum dolor sit amet nulla ham qui sint exercitation eiusmod commodo, chuck duis velit.</h6>
                                    <h6>⭐⭐⭐⭐⭐</h6>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div> */}
        </div>
        <div className="container ">
          <h2 className="text-center mt-3" style={{ color: "#001A33" }}>
            REVIEWS
          </h2>
          <ReactElasticCarousel breakPoints={breakPoints} enableAutoPlay>
            {allReviews?.map((item) => {
              return (
                <div className="text-center mt-5">
                  <img
                    src={`https://jaidrivingschool.in/review/${item?.logo}`}
                    alt=""
                    height={150}
                    width={130}
                    style={{ borderRadius: "100%" }}
                  />
                  <p
                    className="text-justify text-center mt-2"
                    style={{ color: "#5881A9" }}
                  >
                    {item?.text}
                  </p>
                  <h5 className="text-center">{item?.writer}</h5>
                  <div className="text-center">
                    <StarRatings
                      rating={item?.ratting}
                      starDimension="25px"
                      starSpacing="5px"
                      starRatedColor="#fcca0b"
                    />
                  </div>
                </div>
              );
            })}
          </ReactElasticCarousel>
        </div>
        <br />
        <div className="row ">
          <div className="col-md-6" style={{ color: "rgb(157 4 27)" }}>
            <form className="comment">
              <h4>Leave A Comment</h4>
              <h6 className="mt-2">Name</h6>
              <input
                type="text"
                placeholder="enter your name"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
              <h6 className="mt-2">E-mail</h6>
              <input
                type="email"
                className="mb-2"
                placeholder="enter your email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              <h6>Comment</h6>
              <textarea
                name=""
                id=""
                cols="30"
                onChange={(e) => setcomment(e.target.value)}
                placeholder="Write your comments"
                rows="4"
                style={{ border: "1px solid black" }}
              ></textarea>
              <br />
              <button
                type="button"
                onClick={(e) => makeComment(e)}
                style={{ marginLeft: "100px" }}
                class="btn btn-warning mt-3 mb-3"
              >
                Post Comment
              </button>
            </form>
          </div>
          <div className="col-md-6">
            <h4>Comments</h4>
            {blog?.comments?.map((ele) => {
              return (
                <div className="d-flex mb-2" style={{ gap: "10px" }}>
                  <BiUserCircle style={{ fontSize: "45px", color: "red" }} />
                  <div>
                    <p>{ele?.name}</p>
                    <p>{ele?.comment}.</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogViewMore;
