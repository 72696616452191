import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";

import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import exportFromJSON from "export-from-json";

const VendorRtoWebSite = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const admin = JSON.parse(sessionStorage.getItem("branch"));
    const token = sessionStorage.getItem("token");

    const [branchName, setbranchName] = useState("");
    const [userType, setuserType] = useState("");
    const [RTOName, setRTOName] = useState("");
    const [Id, setId] = useState("");
    const [RTOApLLDlNo, setRTOApLLDlNo] = useState("");
    const [Name, setName] = useState("");
    const [applicationDate, setapplicationDate] = useState("");
    const [RTONo, setRTONo] = useState("");
    const [ServicesRequested, setServicesRequested] = useState("");
    const [LLDateExpire, setLLDateExpire] = useState("");
    const [ClassOfVehicle, setClassOfVehicle] = useState("");
    const [RTOOffice, setRTOOffice] = useState("");
    const [ChallanNo, setChallanNo] = useState("");
    const [PaymentMethod, setPaymentMethod] = useState("");
    const [Remarks, setRemarks] = useState("");
    const [totalAmount, settotalAmount] = useState("");
    const [DateofPayment, setDateofPayment] = useState("");

    const addRtoWebsitePayment = async () => {
        try {
            let ab =admin?.branchId ? admin?.branchId: admin?._id
            // if (!city) return alert("Please Enter City");
            // if (!Adress) return alert("Please Enter Address");
            // if (!pincode || pincode.length > 6 || pincode.length < 6)
            //     return alert("Please Enter Correct Pincode");
            // if (!RTOName) return alert("Please Enter RTO Name");
            // if (!rtoEmail) return alert("Please Enter email");
            // if (!rtoCode) return alert("Please Enter RTO Code");
            if(!RTOName||!RTOApLLDlNo||!Name) return alert("Please fill the form")
            const config = {
                url: "/admin/addRTOWebsitePayment",
                method: "post",
                baseURL: "https://jaidrivingschool.in/api",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },

                data: {
                    authId: admin?._id,
                    branchName: `${admin?.branchName},${admin?.area}`,
                    branchId:ab,
                    userType: userType,
                    RTOName: RTOName,
                    Id: Id,
                    RTOApLLDlNo: RTOApLLDlNo,
                    Name: Name,
                    applicationDate: applicationDate,
                    RTONo: RTONo,
                    ServicesRequested: ServicesRequested,
                    LLDateExpire: LLDateExpire,
                    ClassOfVehicle: ClassOfVehicle,
                    RTOOffice: RTOOffice,
                    ChallanNo: ChallanNo,
                    PaymentMethod: PaymentMethod,
                    Remarks: Remarks,
                    totalAmount: totalAmount,
                    DateofPayment: DateofPayment,

                },
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert(res.data.success);
                getRTOWebsitepayment();
                handleClose()


            }
        } catch (error) {
            alert(error.response.data.error)
            console.log(error);
        }
    };
    const [nochangedata, setnochangedata] = useState([])
    const [search, setSearch] = useState("");
    const handleFilter = (e) => {
        if (e.target.value != "") {
            setSearch(e.target.value);
            const filterTable = nochangedata.filter((o) =>
                Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
                )
            );
            setRTOWebsitepayment([...filterTable]);
        } else {
            setSearch(e.target.value);
            setRTOWebsitepayment([...nochangedata]);
        }
    };
    const [data, setdata] = useState([]);
    const [RTOWebsitepayment, setRTOWebsitepayment] = useState([]);
    const getRTOWebsitepayment = async () => {
        let ab =admin?.branchId ? admin?.branchId: admin?._id
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAllRTOWebsitesPayByBranch/"+ab
            );
            if (res.status == 200) {
                setRTOWebsitepayment(res.data.success);
                setnochangedata(res.data.success);
                setdata(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [fileName, setfileName] = useState("RTO-WebsitepaymentApplication");
  const exportType = "xls";
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });

     } else {
      alert("Enter file name to export");
    }
  };
    const [show4, setshow4] = useState(false);
    const handleShow4 = () => setshow4(true);
    const handleClose4 = () => setshow4(false);
    const [deleteId, setdeleteId] = useState("");
    const deleteRto = async () => {
        try {
            const config = {
                url: "/admin/deleteRTOWebsitePayment/" + deleteId + "/" + admin?._id,
                method: "delete",
                baseURL: "https://jaidrivingschool.in/api",

                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
                //  data: {authId:admin?._id,titele:title,link:link,day:day,id:edit?._id}
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert("Successfully deleted");
                handleClose4();
                getRTOWebsitepayment();
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getRTOWebsitepayment();
    }, []);

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(RTOWebsitepayment.length / usersPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };
const [edit,setedit]=useState({});
const [editShow,setEditShow]=useState(false);
const hndleEs=(data)=>{
   
    setuserType(data?.userType);
    setId(data?.Id);
    setRTOName(data?.RTOName);
    setRTOApLLDlNo(data?.RTOApLLDlNo);
    setName(data?.Name);
    setRTONo(data?.RTONo);
    setServicesRequested(data?.ServicesRequested);
    setLLDateExpire(data?.LLDateExpire);
    setClassOfVehicle(data?.ClassOfVehicle);
    setRTOOffice(data?.RTOOffice);
    setChallanNo(data?.ChallanNo);
    setPaymentMethod(data?.PaymentMethod);
    setDateofPayment(data?.DateofPayment);
    settotalAmount(data?.totalAmount);
    setRemarks(data?.Remarks);
    setEditShow(true)}

    const updateRTOWebsitePayment=async()=>{
        try {
            const config = {
                url: "/admin/updateRTOWebsitePayment",
                method: "put",
                baseURL: "https://jaidrivingschool.in/api",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },

                data: {
                    id:edit?._id,
                    authId: admin?._id,
                 
                    userType: userType,
                    RTOName: RTOName,
                    Id: Id,
                    RTOApLLDlNo: RTOApLLDlNo,
                    Name: Name,
                    applicationDate: applicationDate,
                    RTONo: RTONo,
                    ServicesRequested: ServicesRequested,
                    LLDateExpire: LLDateExpire,
                    ClassOfVehicle: ClassOfVehicle,
                    RTOOffice: RTOOffice,
                    ChallanNo: ChallanNo,
                    PaymentMethod: PaymentMethod,
                    Remarks: Remarks,
                    totalAmount: totalAmount,
                    DateofPayment: DateofPayment,

                },
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert("Successfully updated");
                getRTOWebsitepayment();
                setEditShow(false)
            }
        } catch (error) {
            alert(error.response.data.error)
            console.log(error);
        }
    }
    const [allRTO, setAllRTO] = useState([]);
    const getRTO = async () => {
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAllRTOByAdmin/" + admin?._id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
            }
            );
            if (res.status == 200) {
                setAllRTO(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(()=>{
        getRTO();
    },[])
    const handleRTO = (abc) => {
        if (abc !== "") {
            abc = JSON.parse(abc);
            setRTOName(abc?.rtoName);

        }
    }
    return (
        <>
            <div className="add-gr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ad-b mt-4">
                                <button
                                    style={{ borderRadius: "10px", float: "right" }}
                                    onClick={()=>{
                                        setbranchName("");
                                        setuserType("");
                                        setId("");
                                        setRTOName("");
                                        setRTOApLLDlNo("");
                                        setName("");
                                        setRTONo("");
                                        setServicesRequested("");
                                        setLLDateExpire("");
                                        setClassOfVehicle("");
                                        setRTOOffice("");
                                        setChallanNo("");
                                        setPaymentMethod("");
                                        setDateofPayment("");
                                        settotalAmount("");
                                        setRemarks("");
                                        handleShow()}}
                                >
                                    +Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                    <AiOutlineSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={handleFilter}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-6 ad-b">
                            <button style={{ float: "right" }} onClick={ExportToExcel}>
                                Export Excel
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="mo-gra mt-5 scrolll_bar">
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th>S.No</th>

                                    <th>Date </th>
                                    <th>Branch  Name</th>
                                    <th>Student/Customer</th>
                                    <th>Jai-Application Number/Customer Number</th>
                                    <th>RTO Name </th>
                                    <th>(RTO) Application No LL/DL/Others</th>
                                    <th>Name</th>

                                    <th>RTO Application No</th>
                                    <th>Services Requested (LL/DD/RC)</th>
                                    <th>Learning Licence No. and Date of Expiry</th>

                                    <th>Class Of Vehicle</th>
                                    <th>RTO Office</th>
                                    <th>Challan No</th>
                                    <th>Payment Method</th>
                                    <th>Date of Payment</th>
                                    <th>Amount</th>
                                    <th>Remarks</th>
                                    <th>Delete</th>

                                </tr>
                            </thead>
                            {RTOWebsitepayment?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                                return (
                                    <tbody>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{moment(item?.createdAt).format("MMM Do YY")}</td>

                                            <td> {item?.branchName}</td>
                                            <td>{item?.userType}</td>
                                            <td>{item?.Id}</td>
                                            <td>{item?.RTOName}</td>
                                            <td>{item?.RTOApLLDlNo}</td>
                                            <td>{item?.Name}</td>

                                            <td>{item?.RTONo}</td>
                                            <td>{item?.ServicesRequested}</td>
                                            <td>{item?.LLDateExpire}</td>

                                            <td>{item?.ClassOfVehicle}</td>
                                            <td>{item?.RTOOffice}</td>
                                            <td>{item?.ChallanNo}</td>
                                            <td>{item?.PaymentMethod}</td>

                                            <td>{item?.DateofPayment}</td>
                                            <td> {item?.totalAmount}</td>
                                            <td> {item?.Remarks}</td>
                                            <td style={{display:"flex",gap:"5px"}}> <img
                                src="./images/editing.png"
                                alt=""
                                width={30}
                                onClick={() => {
                                    hndleEs(item);
                                  setedit(item);
                                }}
                              />{" "}<Button type="button" variant="danger" onClick={() => {
                                                setdeleteId(item?._id);
                                                handleShow4();
                                            }}>
                                                <span>
                                                    <RiDeleteBin6Line
                                                        style={{ fontSize: "20" }}

                                                    />
                                                </span>
                                            </Button></td>





                                        </tr>
                                    </tbody>
                                )
                            })}

                        </Table>
                        <br/>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add RTO WebSite Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
{/* 
                    <div className="do-sear mt-2">
                        <label>Branch Name </label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Branch Name "
                            onChange={(e) => { setbranchName(e.target.value) }}

                        />

                    </div> */}
                    <div className="do-sear mt-2">
                        <label>Jai-Application Number/Customer Number
                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Jai-Application Number/Customer Number "
                            onChange={(e) => { setId(e.target.value) }}
                        />

                    </div>

                    <div className="do-sear mt-2">
                        <label>RTO Name</label>
                        <select name="" id="" className="vi_0" onChange={(e) => { handleRTO(e.target.value) }}>
                            <option value="">----Select RTO Name----</option>
                            {allRTO?.map((item) => {
                                return (
                                    <option value={JSON.stringify(item)}>{item?.rtoName}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="do-sear mt-2">
                        <label>(RTO) Application No LL/DL/Others</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter (RTO) Application No LL/DL/Others"
                            onChange={(e) => { setRTOApLLDlNo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Name</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Name"
                            onChange={(e) => { setName(e.target.value) }}

                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>Student / Customer</label>
                        <select name="" id="" className="vi_0" onChange={(e) => { setuserType(e.target.value) }}>
                            <option value="">---select---</option>
                            <option value="Student">Student</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    <div className="do-sear mt-2">
                        <label>RTO Application No</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter RTO Application No"
                            onChange={(e) => { setRTONo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Services Requested (LL/DL/RC)
                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Services Requested (LL/DL/RC)"
                            onChange={(e) => { setServicesRequested(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Learning Licence No. and Date of Expiry</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Learning Licence No. and Date of Expiry"
                            onChange={(e) => { setLLDateExpire(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Class Of Vehicle</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Class Of Vehicle"
                            onChange={(e) => { setClassOfVehicle(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>RTO Office</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter RTO Office"
                            onChange={(e) => setRTOOffice(e.target.value)}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Challan No

                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Challan No"
                            onChange={(e) => { setChallanNo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Payment Method


                        </label>
                        <select name="" id="" className="vi_0" onChange={(e) => { setPaymentMethod(e.target.value) }}>
                            <option value="">--select--</option>
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                        </select>
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Date of Payment



                        </label>
                        <input type="date" name="" id="" className="vi_0" onChange={(e) => { setDateofPayment(e.target.value) }} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Amount
                        </label>
                        <input type="number" name="" id="" placeholder="Enter Amount" className="vi_0" onChange={(e) => { settotalAmount(e.target.value) }} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Remarks </label>
                        <textarea
                            rows={3}
                            cols={10}
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Remarks "
                            onChange={(e) => { setRemarks(e.target.value) }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addRtoWebsitePayment}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={show4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete??</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose4}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={deleteRto}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Model */}
                <Modal
                show={editShow}
                onHide={()=>setEditShow(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update RTO WebSite Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <div className="do-sear mt-2">
                        <label>Branch Name </label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Branch Name "
                            value={branchName}
                            onChange={(e) => { setbranchName(e.target.value) }}

                        />

                    </div> */}
                    <div className="do-sear mt-2">
                        <label>Jai-Application Number/Customer Number
                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            value={Id}
                            placeholder="Please enter Jai-Application Number/Customer Number "
                            onChange={(e) => { setId(e.target.value) }}
                        />

                    </div>

                    <div className="do-sear mt-2">
                        <label>RTO Name</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={RTOName}
                            placeholder="Please enter RTO Name "
                            onChange={(e) => { setRTOName(e.target.value) }}
                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>(RTO) Application No LL/DL/Others</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={RTOApLLDlNo}
                            placeholder="Please enter (RTO) Application No LL/DL/Others"
                            onChange={(e) => { setRTOApLLDlNo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Name</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Name"
                            value={Name}
                            onChange={(e) => { setName(e.target.value) }}

                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>Student / Customer</label>
                        <select name="" id="" className="vi_0" onChange={(e) => { setuserType(e.target.value) }}>
                            <option value="">---select---</option>
                            <option value="Student">Student</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    <div className="do-sear mt-2">
                        <label>RTO Application No</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={RTONo}
                            placeholder="Please enter RTO Application No"
                            onChange={(e) => { setRTONo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Services Requested (LL/DL/RC)
                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            value={ServicesRequested}
                            placeholder="Please enter Services Requested (LL/DL/RC)"
                            onChange={(e) => { setServicesRequested(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Learning Licence No. and Date of Expiry</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={LLDateExpire}
                            placeholder="Please enter Learning Licence No. and Date of Expiry"
                            onChange={(e) => { setLLDateExpire(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Class Of Vehicle</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={ClassOfVehicle}
                            placeholder="Please enter Class Of Vehicle"
                            onChange={(e) => { setClassOfVehicle(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>RTO Office</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter RTO Office"
                            value={RTOOffice}
                            onChange={(e) => setRTOOffice(e.target.value)}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Challan No

                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Challan No"
                            value={ChallanNo}
                            onChange={(e) => { setChallanNo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Payment Method


                        </label>
                        <select name="" id="" className="vi_0" onChange={(e) => { setPaymentMethod(e.target.value) }}>
                            <option value="">--select--</option>
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                        </select>
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Date of Payment



                        </label>
                        <input type="date" name="" id="" className="vi_0" value={DateofPayment} onChange={(e) => { setDateofPayment(e.target.value) }} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Amount
                        </label>
                        <input type="number" name="" id="" placeholder="Enter Amount" className="vi_0" value={totalAmount} onChange={(e) => { settotalAmount(e.target.value) }} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Remarks </label>
                        <textarea
                            rows={3}
                            cols={10}
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Remarks "
                            value={Remarks}
                            onChange={(e) => { setRemarks(e.target.value) }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setEditShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateRTOWebsitePayment}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default VendorRtoWebSite