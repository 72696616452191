import React from "react";
import { useNavigate } from "react-router-dom";

const BookNow = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <div className="bg_image"> */}
        {/* <img src="/images/teaching.jpg" alt="" width="100%" height="500px" /> */}
        <div className="form2">
        <h4 style={{  fontWeight: "bold",  }}>
            Personal Details
          </h4>

          {/* <form 
            action=""
            // style={{ marginLeft: "450px", width: "442px" }}
          > */}
        
            <label for="exampleInputEmail1">Name</label>
            <input type="text" placeholder="enter your name" />
            <br />
            <label for="exampleInputEmail1">E-mail ID</label>
            <input type="email" placeholder="enter your e-mail" />
            <br />
            <label for="exampleInputEmail1">Mobile no</label>
            <input type="number" placeholder="enter your number" />
            <br />
            <label for="exampleInputEmail1">Date Of Birth</label>
            <input
              type="date"
              // class="form-control"
              placeholder="enter your dob"
            />
            <br />
            <label for="exampleInputEmail1" >
              Joining Date
            </label>
            <input
              type="date"
              // class="form-control"
              placeholder="enter your joining date"
              id="bg-34"
            />
            <br />
           
           
          {/* </form> */}
          </div>
          <button
              type="submit"
              onClick={() => navigate("/continue")}
              class="btn btn-warning"
              style={{ width: "9rem",float:"right", marginTop:"20px", marginBottom:"20px", marginRight:"50px" }}
            >
              Save&Continue
            </button>
       
      {/* </div> */}
    </div>
  );
};

export default BookNow;
