import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import moment from "moment";
const AdminLeave = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  const [AllGraph, setAllgraph] = useState([]);
  const getAllgraph = async () => {
    try {
      const config = {
        url: "/admin/getStaffleave",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      const res = await axios(config);
      if (res.status == 200) {
        setAllgraph(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllgraph();
  }, []);
  const [day, setday] = useState("");
  const [title, settitle] = useState("");
  const [link, setlink] = useState("");

  const AddGraph = async () => {
    try {
      if (!day) return alert("Please select day");
      let am = AllGraph?.filter((ele) => ele?.day == day);
      if (am.length != 0) return alert(`${day} already exits`);
      if (!title) return alert("Please enter time");
      const config = {
        url: "/admin/addOpening",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",

        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: { authId: admin?._id, time: title, day: day },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        handleClose();
        getAllgraph();
        setday("");
        settitle("");
        setlink("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [viewImge, setViewday] = useState("");
  const [edit, setedit] = useState({});
  const editGraph = async () => {
    try {
      const config = {
        url: "/admin/OpeningUpdate",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",

        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: { authId: admin?._id, time: title, day: day, id: edit?._id },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        handleClose1();
        getAllgraph();
        setday("");
        settitle("");
        setlink("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllGraph.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [show4, setshow4] = useState(false);
  const handleShow4 = () => setshow4(true);
  const handleClose4 = () => setshow4(false);
  const [deleteId, setdeleteId] = useState("");
  const deleteGraph = async () => {
    try {
      const config = {
        url: "/admin/deleteStaffLeave/" + deleteId,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",

        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        //  data: {authId:admin?._id,titele:title,link:link,day:day,id:edit?._id}
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose4();
        getAllgraph();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const makeApprovedHoldLeaves=async(id,status,comment)=>{
    try {
        const config = {
          url: "/admin/makeChangeStaffLeave",
          method: "put",
          baseURL: "https://jaidrivingschool.in/api",
  
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
           data: {id,status,comment}
        };
        let res = await axios(config);
        if (res.status == 200) {
          alert(`Successfully ${status}`);
          handleClose4();
          getAllgraph();
        }
      } catch (error) {
        console.log(error);
      }
  }
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ad-b mt-4">
                <button>All Apply Leaves</button>
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className="ad-b mt-4">
                <button style={{borderRadius:"10px",float:"right"}} onClick={handleShow}>+Add</button>
               
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>

                  <th>Name</th>

                  <th>Email</th>
                  <th>Mobile</th>
                  <th>User Type</th>
                  <th>No of Days</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Reason</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllGraph?.slice(
                  pagesVisited,
                  pagesVisited + usersPerPage
                )?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                 
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.mobile}</td>
                      <td>{item?.userType}</td>
                      <td>{item?.days} days</td>
                      <td>{moment(item?.Fromdate).format("DD/MM/YYYY")}</td>
                      <td>{moment(item?.Todate).format("DD/MM/YYYY")}</td>
                      <td>
                        <p
                          style={{
                            overflow: "hidden",
                            overflowY: "scroll",
                            height: "100px",
                            width: "250px",
                          }}
                        >
                          {" "}
                          {item?.Reason}
                        </p>
                      </td>
                      <td>
                        {item?.status == "Approved" ? (
                          <span style={{ color: "green" }}>{item?.status}</span>
                        ) : (
                          <span style={{ color: "red" }}>{item?.status}</span>
                        )}
                      </td>

                      <td style={{ display: "flex", gap: "4px" }}>
                        {item?.status == "Approved" ? (
                          <></>
                        ) : (
                          <div style={{ display: "flex", gap: "4px" }}>
                            <button type="button" class="btn btn-success" onClick={()=>makeApprovedHoldLeaves(item?._id,"Approved","")}>
                              Approved
                            </button>
                            <button type="button" class="btn btn-danger" onClick={()=>makeApprovedHoldLeaves(item?._id,"Hold","")}>
                              Hold
                            </button>
                          </div>
                        )}

                        <Button type="button" variant="danger" onClick={()=>{
                                setdeleteId(item?._id);
                                handleShow4();
                              }}>
                                <span>
                                  <RiDeleteBin6Line style={{fontSize:"25"}} />
                                </span>
                              </Button>
                      </td>
                 
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Time Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="do-sear mt-2">
            <label htmlFor="upload1">day</label>
            <input type="File" placeholder="Enter Userid" className="vi_0"  id="upload1" accept="day/*" onChange={(e)=>setday(e.target.files[0])}/>
          </div> */}
          <div className="do-sear mt-2">
            <label>Days</label>
            <select onChange={(e) => setday(e.target.value)} className="vi_0">
              <option>Select Days</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Opening Time</label>
            <input
              type="text"
              onChange={(e) => settitle(e.target.value)}
              className="vi_0"
              placeholder="Eg:- 06:00 am to 09:00 pm"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddGraph}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <h4>Text: Banner 1</h4> */}
            <img src={viewImge} alt="" width="100%" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          {/* <Button variant="primary">Submit</Button> */}
        </Modal.Footer>
      </Modal>
      {/* edit Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Updated Time Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label>Days</label>
            <select onChange={(e) => setday(e.target.value)} className="vi_0">
              <option>{edit?.day}</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Time Slot</label>
            <input
              type="text"
              onChange={(e) => settitle(e.target.value)}
              className="vi_0"
              placeholder={edit?.time}
            />
          </div>
          {/* <div className="do-sear mt-2">
            <label>Url</label>
            <input type="text" onChange={(e)=>setlink(e.target.value)} className="vi_0" placeholder={edit?.link} />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editGraph}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* deleteMode */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteGraph}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminLeave;
