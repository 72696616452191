import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
const Branches = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleClose3 = () => setShow3(false);
  const handleShow1 = () => setShow1(true);

  const [city, setcity] = useState("");
  const [text, settext] = useState("");
  const [allcity, setallCity] = useState([]);

  const [makeActive, setMakeActive] = useState(true)

  const getAllCity = async () => {
    try {
      let res = await axios("https://jaidrivingschool.in/api/admin/getAllCity");
      if (res.status == 200) {
        setallCity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  // const [text,settext]=useState("");
  // const [title,settitle]=useState("");
  const AddCity = async () => {
    try {
      if (!city) return alert("Please enter city");

      const config = {
        url: "/admin/addCity",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          authId: admin?._id,
          city: city,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added");
        getAllCity();
        handleClose();
        setcity("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [editdata, seteditdata] = useState({});

  const [deleteId, setdeleteId] = useState("");
  const deleteCount = async () => {
    try {
      const config = {
        url: "/admin/deleteBranch/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        getAllBranches();
        handleClose1();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteCityshow, setdelecity] = useState(false);
  const deletClose = () => setdelecity(false);
  const handleDeletecity = () => setdelecity(true);

  const deleteCity = async () => {
    try {
      const config = {
        url: "/admin/deleteCity/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        getAllCity();
        deletClose();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [allBranch, setallbranch] = useState([]);

  const getAllBranches = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranchByAdmin/"+admin?._id,{ headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },}
      );
      if (res.status == 200) {
        setallbranch(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [BranchShow, setBrancShow] = useState(false);
  const hancdleBranch = () => setBrancShow(false);
  const showBranch = () => setBrancShow(true);
  const [area, setarea] = useState("");
  const [pincode, setpincode] = useState("");
  const [latitude, setlatitue] = useState("");
  const [Longitude, setlongitude] = useState("");
  const [image, setimage] = useState("");
  const [address, setaddress] = useState("");
  const [branchName, setbranchName] = useState("");
  const [branchHeadName, setbranchHeadName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [description, setdescription] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");

  const addBraches = async () => {
    try {
      if (!password) return alert("Please enter password");
      if (!cpassword) return alert("Please enter confirm password");
      if (password !== cpassword) return alert("Confirm password dose not match")
      // if (!area) return alert("Please enter branch addess");
      // if (!pincode) return alert("Please enter pincode");
      // if (!latitude) return alert("Please enter latitude");
      // if (!Longitude) return alert("Please enter Longitude");
      const config = {
        url: "/admin/addBranches",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          city: city,
          area: area,
          pincode: pincode,
          locationLink: latitude,
          branchHeadrName: branchHeadName,
          branchNumber: mobile,
          branchEmail: email,
          branchName: branchName,
          Adress: address,
          image: image,
          description: description,
          password: password
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        getAllBranches();
        hancdleBranch();
        setpassword("");
        setcpassword("");
        setbranchHeadName("");
        setmobile("");
        setemail("");
        setbranchName("");
        setaddress("");
        setimage("");
        setcity("");
        setarea("");
        setpincode("");
        setlatitue("");
        setlongitude("");
        setdescription("")
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const UpdateCounts = async () => {
    try {
      if (password || cpassword) {
        if (!cpassword) return alert("Please enter confirm password");
        if (password !== cpassword) return alert("Confirm password dose not match")
      }

      const config = {
        url: "/admin/updateBranch",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          city: city,
          area: area,
          pincode: pincode,
          locationLink: latitude,

          id: editdata?._id,
          branchHeadrName: branchHeadName,
          branchNumber: mobile,
          branchEmail: email,
          branchName: branchName,
          Adress: address,
          image: image,
          description: description,
          password: password
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        getAllBranches();
        handleClose3();
        setbranchHeadName("");
        setpassword("");
        setcpassword("");
        setmobile("");
        setemail("");
        setbranchName("");
        setaddress("");
        setimage("");
        setcity("");
        setarea("");
        setpincode("");
        setlatitue("");
        setlongitude("");
        setdescription("")
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const handleShow3 = (data) => {
    setbranchHeadName(data?.branchHeadrName);
    setmobile(data?.branchNumber);
    // setemail(data?.branchEmail);
    setbranchName(data?.branchName);
    setaddress(data?.Adress);
    setcity(data?.city);
    setarea(data?.area);
    setpincode(data?.pincode);
    setlatitue(data?.locationLink);

    setdescription(data?.description)
    setShow3(true)
  };
  useEffect(() => {
    getAllCity();
    getAllBranches();
  }, []);
  const [listcity, setlistcity] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allBranch.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [pageNumber1, setPageNumber1] = useState(0);

  const pagesVisited1 = pageNumber1 * usersPerPage;
  const pageCount1 = Math.ceil(allcity.length / usersPerPage);
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };

const makeActiveAndDeactive=async(id,isBlock)=>{
  try {
    const config={
      url: "/admin/makeActiveAndDeactive",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data:{
        authId:admin?._id,
        id:id,
        isBlock:isBlock
      }
    }
    let res=await axios(config)
    if(res.status==200){
      alert(res.data.success);
      getAllBranches();
    }
  } catch (error) {
    console.log(error);
  }
}

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            <button onClick={handleShow}>+ City</button>{" "}
            <button onClick={() => {
              setbranchHeadName("");
              setmobile("");
              setemail("");
              setbranchName("");
              setaddress("");
              setimage("");
              setcity("");
              setarea("");
              setpincode("");
              setlatitue("");
              setlongitude("");
              setdescription("")
              showBranch()
            }}>+ Branch</button>{" "}
            <button onClick={() => setlistcity(!listcity)}>
              {" "}
              {listcity == false ? "City list" : "Branch list"}
            </button>
          </div>
        </div>

        <div className="container">
          {listcity ? (
            <div className="mo-gra mt-5">
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>City</th>
                  </tr>
                </thead>
                <tbody>
                  {allcity
                    ?.slice(pagesVisited1, pagesVisited1 + usersPerPage)
                    ?.map((ele, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{ele?.city}</td>
                          {/* style={{ overflow: "hidden", overflowY: "scroll" }} */}

                          <td>
                            <Button
                              type="button"
                              variant="danger"
                              onClick={() => {
                                setdeleteId(ele?._id);
                                handleDeletecity();
                              }}
                            >
                              <span>
                                <RiDeleteBin6Line style={{ fontSize: "20" }} />
                              </span>
                            </Button>
                          </td>


                          {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
              <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount1}
                onPageChange={changePage1}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          ) : (
            <div className="mo-gra mt-5">
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Branch Name</th>
                    <th>Branch Head Name</th>
                    <th>Mobile No</th>
                    <th>Email</th>
                    <th>Area</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Pincode</th>
                    <th>Location Link</th>
                    {/* <th>Longitude</th> */}
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allBranch
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((ele, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td><img src={`https://jaidrivingschool.in/Branch/${ele?.image}`} width={100} height={100} /></td>
                          <td>{ele?.branchName}</td>
                          <td>{ele?.branchHeadrName}</td>
                          <td>{ele?.branchNumber}</td>
                          <td>{ele?.branchEmail}</td>
                          <td>{ele?.area}</td>
                          {/* style={{ overflow: "hidden", overflowY: "scroll" }} */}
                          <td><p style={{ overflow: "hidden", overflowY: "scroll", height: "100px", width: "200px" }}>{ele?.Adress}</p></td>

                          <td>{ele?.city}</td>
                          <td>{ele?.pincode}</td>
                          <td> <a href={ele?.locationLink} target="_blank"> <p style={{ overflow: "hidden", overflowY: "scroll", height: "100px", width: "200px" }}>{ele?.locationLink}</p></a></td>
                          {/* <td>{ele?.longtitute}</td> */}
                          <td><p style={{ overflow: "hidden", overflowY: "scroll", height: "100px", width: "200px" }}>{ele?.description}</p></td>
                          <td>{ele?.isBlock==false ? (<span style={{color:"green"}}>Activated</span>):(<span style={{color:"red"}}>Deactivated</span>)}</td>
                          <td style={{ display: "flex", gap: "3px" }}>
                            <img
                              src="./images/editing.png"
                              alt=""
                              width={30}
                              onClick={() => {
                                seteditdata(ele);
                                handleShow3(ele);
                              }}
                            />{" "}
                            <Button
                              type="button"
                              variant="danger"
                              onClick={() => {
                                setdeleteId(ele?._id);
                                handleShow1();
                              }}
                            >
                              <span>
                                <RiDeleteBin6Line style={{ fontSize: "20" }} />
                              </span>
                            </Button>
                          </td>
                          <td>{ele?.isBlock==false ? (<Button variant="danger" type="button" onClick={() => makeActiveAndDeactive(ele?._id,true)}> Deactivate </Button>) : (<Button type="button" variant="success" onClick={() => makeActiveAndDeactive(ele?._id,false)}> Activate </Button>)}</td>
                          {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
              <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          )}
        </div>

        {/* Add Model */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add City</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>City Name</label>
              <input
                type="text"
                placeholder="Enter city name"
                className="vi_0"
                onChange={(e) => setcity(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AddCity}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Edit Model */}
        <Modal
          show={BranchShow}
          onHide={hancdleBranch}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label htmlFor="upload1">Branch Image</label>
              <input
                type="file"
                name="file"
                id="upload1"
                accept="image/*"
                placeholder="Enter branch address"
                className="vi_0"
                onChange={(e) => setimage(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Name</label>
              <input
                type="text"
                value={branchName}
                placeholder="Enter branch name"
                className="vi_0"
                onChange={(e) => setbranchName(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Head Name</label>
              <input
                type="text"
                value={branchHeadName}
                placeholder="Enter branch head name"
                className="vi_0"
                onChange={(e) => setbranchHeadName(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label> Mobile No</label>
              <input
                type="text"
                value={mobile}
                maxLength={10}
                placeholder="Enter branch mobile number"
                className="vi_0"
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Email</label>
              <input
                type="email"
                value={email}
                placeholder="Enter branch email id"
                className="vi_0"
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Area</label>
              <input
                type="text"
                value={area}
                placeholder="Eg:- Whitefeild Bengalore"
                className="vi_0"
                onChange={(e) => setarea(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Address</label>
              <input
                type="text"

                placeholder="Enter full address"
                className="vi_0"
                value={address}
                onChange={(e) => setaddress(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>City</label>
              <select className="vi_0" onChange={(e) => setcity(e.target.value)}>
                <option>Select city</option>
                {allcity?.map((ele) => {
                  return <option value={ele?.city}>{ele?.city}</option>;
                })}
              </select>
              {/* <input type="text" placeholder="Enter city name" className="vi_0" onChange={(e)=>setcity(e.target.value)} /> */}
            </div>
            <div className="do-sear mt-2">
              <label>Pincode</label>
              <input
                type="number"
                value={pincode}
                placeholder="Enter pincode"
                className="vi_0"
                onChange={(e) => setpincode(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>location Link</label>
              <input
                type="text"
                placeholder="Enter locationLink"
                className="vi_0"
                value={latitude}
                onChange={(e) => setlatitue(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter password"
                className="vi_0"

                onChange={(e) => setpassword(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Confirm Password</label>
              <input
                type="text"
                placeholder="Enter confirm password"
                className="vi_0"

                onChange={(e) => setcpassword(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Description</label>
              <textarea
                type="text"
                placeholder="Please enter Description"
                cols={3}
                className="vi_0"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hancdleBranch}>
              Close
            </Button>
            <Button variant="primary" onClick={addBraches}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label htmlFor="upload1">Branch Image</label>
              <input
                type="file"
                name="file"
                id="upload1"
                accept="image/*"
                placeholder="Enter branch address"
                className="vi_0"
                onChange={(e) => setimage(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Name</label>
              <input
                type="text"
                value={branchName}
                placeholder="Enter branch name"
                className="vi_0"
                onChange={(e) => setbranchName(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Head Name</label>
              <input
                type="text"
                value={branchHeadName}
                placeholder="Enter branch head name"
                className="vi_0"
                onChange={(e) => setbranchHeadName(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label> Mobile No</label>
              <input
                type="text"
                value={mobile}
                maxLength={10}
                placeholder="Enter branch mobile number"
                className="vi_0"
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Email</label>
              <input
                type="email"
                value={email}
                placeholder={editdata?.branchEmail}
                className="vi_0"
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Area</label>
              <input
                type="text"
                value={area}
                placeholder="Eg:- Whitefeild Bengalore"
                className="vi_0"
                onChange={(e) => setarea(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Branch Address</label>
              <input
                type="text"

                placeholder="Enter full address"
                className="vi_0"
                value={address}
                onChange={(e) => setaddress(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>City</label>
              <select className="vi_0" onChange={(e) => setcity(e.target.value)}>
                <option>{editdata?.city ? (editdata?.city) : ("Select city")}</option>
                {allcity?.map((ele) => {
                  return <option value={ele?.city}>{ele?.city}</option>;
                })}
              </select>
              {/* <input type="text" placeholder="Enter city name" className="vi_0" onChange={(e)=>setcity(e.target.value)} /> */}
            </div>
            <div className="do-sear mt-2">
              <label>Pincode</label>
              <input
                type="number"
                value={pincode}
                placeholder="Enter pincode"
                className="vi_0"
                onChange={(e) => setpincode(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>location Link</label>
              <input
                type="text"
                placeholder="Enter locationLink"
                className="vi_0"
                value={latitude}
                onChange={(e) => setlatitue(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter password"
                className="vi_0"

                onChange={(e) => setpassword(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Confirm Password</label>
              <input
                type="text"
                placeholder="Enter confirm password"
                className="vi_0"

                onChange={(e) => setcpassword(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Description</label>
              <textarea
                type="text"
                placeholder="Please enter Description"
                cols={3}
                className="vi_0"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
            <Button variant="primary" onClick={UpdateCounts}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ?? </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteCount}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        {/* city delete model */}
        <Modal
          show={deleteCityshow}
          onHide={deletClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete city ??</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" onClick={deletClose}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteCity}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Branches;
