import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import axios from "axios";
import moment from "moment/moment";
// import Input from "react-bootstrap/Input";

const TimSlot = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [AllCars, setAllCars] = useState([])
  const GetAllCars = async () => {
    try {
      const res = await axios.get("https://jaidrivingschool.in/api/admin/getAllVehicle");
      if (res.status == 200) {
        setAllCars(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    GetAllCars()
  }, [])
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
const [minDate,setmindate]=useState("")
 
  useEffect(()=>{
    const currentDate = new Date(Date.now());
        currentDate.setDate(currentDate.getDate() + 1);
        const formattedNewDate = currentDate.toISOString().split('T')[0];
        setdate(formattedNewDate)
        setmindate(formattedNewDate)
  },[]);
  const instructur = (item) => {
    if (!date) return alert("Please select date");
  
    if (!time) return alert("Please select time")
    return navigate("/instructors", { state: { course: state?.data1, car: item, date: date, time: time, branch: state?.branch } })
  }
  const [alltimeSlot, setalltimeSlot] = useState([])
  const getAlltimeSlote = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getslotTime");
      if (res.status == 200) {
        setalltimeSlot(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAlltimeSlote()
  }, [])
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <div className="container wertyuiop_67">
          <div className="row">
            <div class="col-md-4 ">
              <label for="disabledSelect" class="form-label">
                Course  Start Date
              </label>
              <input
                type="date"
                id="disabledTextInput"
                value={date}
                onChange={(e) => setdate(e.target.value)}
                class="form-control"
                style={{ width: "270px" }}
                min={minDate}
              />
            </div>
            <div className="col-md-3">
              <label for="disabledSelect" class="form-label">
                Select Your Time
              </label>
              <Form.Select
                aria-label="Default select example"
                style={{ width: "250px" }}
                onChange={(e) => settime(e.target.value)}
              >
                <option>---Select Your Slot---</option>
                {alltimeSlot?.filter((item) => item?.branchId == state?.branch?._id)?.map((ele) => {
                  return (<option value={ele?.AvailableTime}>{ele?.AvailableTime}</option>)
                })}
              </Form.Select>
            </div>
            <div className="col-md-5">

            </div>
          </div>
        </div>
      </div>
      {AllCars?.filter((item) => state?.branch?._id ? item?.branchId == state?.branch?._id : !item?.branchId)?.map((item) => {
        return (
          <div className="container  wertyuiop_67">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-8">
                <div className="row" style={{ alignItems: "center" }}>
                  <div className="col-md-1">
                    {/* <input type="radio" name="car" /> */}
                  </div>
                  <div className="col-md-4">
                    <img src={`https://jaidrivingschool.in/Vehicle/${item?.vehicleImage}`} alt="" width={250} />
                  </div>
                  <div className="col-md-4 hjerwe" >
                    <h5>{item?.Brand}</h5>
                    <ul>
                      <li>Vehicle No. - {item?.vehicleNumber}</li>
                      <li>Class Of Vehicle:{item?.vehicleType}</li>
                      <li>Price:{"₹ "} {item?.price?.toFixed(2)}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <button
                  className="btn btn-warning"
                  onClick={() => instructur(item)}
                  style={{ float: "right", marginTop: "30px" }}
                >
                  Continue
                </button>
              </div>
            </div>


          </div>)
      })}
      <button
        type="submit"
        onClick={() => navigate(-1)}
        class="btn btn-warning instructors_btn"
        style={{
          width: "7rem",
          float: "left",
          marginBottom: "20px",
          marginLeft: "50px",
        }}
      >
        Back
      </button>
    </>
  );
};

export default TimSlot;
