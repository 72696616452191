import React from 'react'
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const AdminnForm14 = () => {
    const {state}=useLocation();
    const item=state?.item;
    const createPDF = async () => {
        // setRotate(360);
    
        // dynamic image is also adding in the PDF
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.getElementById("pdf"), {
            useCORS: true,
        });
        console.log("data", data);
        const img = data.toDataURL("image/png");
        console.log("img", img);
        const imgProperties = pdf.getImageProperties(img);
        console.log("imgProperties", imgProperties);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        console.log("pdfWidth", pdfWidth);
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        console.log("pdfHeight", pdfHeight);
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("Form14.pdf");
    };
    return (
        <div>

            <Container>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-warning" style={{ float: "right" }} onClick={createPDF}>
                            Download Form 14{" "}
                            <i
                                class="fa-solid fa-file-arrow-down "
                                style={{ marginLeft: "10px" }}
                            ></i>
                        </button>
                    </div>
                </div>
                <div className='form-14 mb-4' id='pdf'>
                    <div className="d-flex justify-content-evenly">
                        <div className='text-center'>
                            <h2 className='form-label'>FORM 14</h2>
                            {/* [See Rules 14(e), 17(1)(b)27(d)] */}
                            <p>Register Showing The Enrollment Of Trainee(s) <br /> In The Driving School Establishment</p>
                        </div>
                        <div>
                            <img src={`https://jaidrivingschool.in/RTO/${item?.TraineePhoto}`} alt="" style={{ width: '150px', height: '150px' }} />
                        </div>
                    </div>

                    <hr />

                    <div className='d-flex gap-2'>
                        <p className='text-start form-label'>NO : </p>
                        <p className='text-underline' style={{ width: '100px' }}>{item?.serNou}</p>
                    </div>

                    <br />

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Register for the year : </p>
                        <p className='text-underline' style={{ width: '575px' }} >{item?.regiYear}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Name of the trainee : </p>
                        <p className='text-underline' style={{ width: '580px' }} >{item?.Name}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Son / Wife / Daughter of : </p>
                        <p className='text-underline' style={{ width: '542px' }} >{item?.garduianName}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Address : </p>
                        <p className='text-underline' style={{ width: '666px' }} >{item?.OfficialAddress}</p>
                    </div>

                    <div className='d-flex gap-2 mb-3'>
                        <p className='form-label'>(a) Permanent Address : </p>
                        <p className='text-underline' style={{ width: '555px' }} >{item?.PermanentAddress}</p>
                    </div>
                    <div className='d-flex gap-2 mb-3'>
                        <p className='form-label'>(b) Temporary Address : </p>
                        <p className='text-underline' style={{ width: '555px' }} >{item?.TemporaryAddress}</p>
                    </div>



                    <div className='d-flex gap-2'>
                        <p className='form-label'> Date of Birth : </p>
                        <p className='text-underline' style={{ width: '630px' }} >{item?.DateofBirth}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Class of vehicle for which training imparted : </p>
                        <p className='text-underline' style={{ width: '400px' }} >{item?.ClassofVehicle}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Date of Enrollment : </p>
                        <p className='text-underline' style={{ width: '585px' }} >{item?.DateofEnrollment}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Learner's licence number and date of expiry : </p>
                        <p className='text-underline' style={{ width: '400px' }} >{item?.LLNDateExpire}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Date of complition of the Course : </p>
                        <p className='text-underline' style={{ width: '480px' }} >{item?.DateofComplition}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Driving licence number and date of issue and the licencing authority wich isued the licence  : </p>
                        <p className='text-underline' style={{ width: '500px' }} >{item?.aboutDL}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Remarks : </p>
                        <p className='text-underline' style={{ width: '662px' }} >{item?.Remarks}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Signature of the licence holder / insturctor : </p>
                        <p className='text-underline' style={{ width: '500px' }} ></p>
                    </div>

                    <hr />

                    <div className='text-center'>
                        <h2 className='text-uppercase'>jai motor driving school </h2>
                        <p style={{ fontSize: '14px', margin: '0px' }}>site No. 185/2B, "Chennakeshava Commercial complex", Shop No,5 & 6, Thindlu Main Road <br /> Oppt to Shirihari Service Station, Virupakshapura, Vidhyaranayapura Post, Banglore-560097.</p>
                        <p className='fw-bold' style={{ fontSize: '14px' }}> ph : 080-49917114 | Mob : 9845091018 | 8618360152 </p>
                    </div>

                </div>


            </Container>

        </div>
    )
}
