import React, { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Carousel from "react-multi-carousel";

const Contact = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const user = JSON.parse(sessionStorage.getItem("user"))
  const trainer = JSON.parse(sessionStorage.getItem("staff"))
  const [username, setusername] = useState("");
  const [useMobile, setuseMobile] = useState("");
  const [useEmail, setuseEmail] = useState("");
  const [comments, setcomments] = useState("");

  const [AllHomeLogo, setAllHomeLogo] = useState([]);
  const getHomeLogo = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getHomeLogo");
      if (res.status == 200) {
        setAllHomeLogo(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHomeLogo()
    if (user) {
      setusername(user?.name);
      setuseMobile(user?.mobile);
      setuseEmail(user?.email)
    }
    if (trainer) {
      setusername(trainer?.name);
      setuseMobile(trainer?.mobile);
      setuseEmail(trainer?.email)
    }
  }, []);
  const CallNow = async () => {
    if (!username) return alert("Please enter your name");
    if (!useMobile) return alert("Please enter your mobile number");
    if (!useEmail) return alert("Please enter your email");
    if (!comments) return alert("Please enter your comments");

    try {
      const config = {
        url: "/admin/postEnquire",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: { name: username, email: useEmail, mobile: useMobile, EnquireType: `Contact Us`, comments: comments }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Team will be connect as soon");
        window.location.assign("/");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [allBranch, setallbranch] = useState([]);

  const getAllBranches = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setallbranch(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBranches();
  }, []);
  useEffect(() => {
    getAllBranches();
  }, []);

  // const user=JSON.parse(sessionStorage.getItem("user"));

  const [name, setname] = useState("")
  const [mobile, setmobile] = useState("")
  const [email, setemail] = useState("")

  const [branch, setbranch] = useState({})
  const [address, setaddress] = useState("")

  useEffect(() => {
    if (user) {
      setname(user?.name);
      setmobile(user?.mobile);
      setemail(user?.email);
    }
  }, []);
  const Addenquire = async () => {
    try {
      if (!name) return alert("Please enter your name");
      if (!mobile) return alert("Please enter mobile number");
      if (!email) return alert("Please enter your email");
      if (!address) return alert("Please enter your comment");
      const config = {
        url: "/admin/postEnquire",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: { name: name, email: email, EnquireType: `About branch ${branch?.branchName} ${branch?.area}`, comments: address, mobile: mobile, branchId: branch?._id }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Team will be connect as soon");
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "130px",
            background: "black",
            overflow: " hidden",
            background: " #C33764",
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Contact</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "white" }}>&raquo;</span>
            </li>
            <li style={{ color: "white", fontSize: "18px" }}> Contact</li>
          </ul>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 bg-9">
          {AllHomeLogo?.map((ele)=>{
            return (
             <div className="col-md-6  bg-10">
            <h4>
              <LocationOnIcon />
              Address
            </h4>
            <p>
             {ele?.address}
            </p>
            <h4>
              <LocalPhoneIcon />
              Contact no
            </h4>
            <p>+91 {ele?.contactNumber}</p>
            <h4>
              <WhatsAppIcon />
              Whatsapp no
            </h4>
            <p>+91 {ele?.contactWhatsApp}</p>
            <h4>
              <EmailIcon />
              E-mail
            </h4>
            <p>{ele?.contactEmail}</p>
          </div> 
            )
          })}
          
          <div className="col-md-6 bg-11">
            <form action="" className="form1">
              <h4>Enquiry Form</h4>
              <label htmlFor="">Name</label>
              <input type="text" placeholder="Enter your name" value={username} onChange={(e) => setusername(e.target.value)} />
              <br />
              <label htmlFor="">Mobile no</label>
              <input type="number" placeholder="Enter your number" value={useMobile} onChange={(e) => setuseMobile(e.target.value)} />
              <br />
              <label htmlFor="">E-mail ID</label>
              <input type="email" placeholder="Enter your e-mail" value={useEmail} onChange={(e) => setuseEmail(e.target.value)} />
              <br />

              <textarea
                name=""
                id=""
                cols="40"
                value={comments}
                onChange={(e) => setcomments(e.target.value)}
                placeholder="Message....."
              ></textarea>
              <p className="btn btn-warning mt-1" onClick={CallNow}>
                Send Request
              </p>
              <br />
            </form>
          </div>
        </div>


        <div className="container bnmjhi_0">
          <h5 className="bg-16">
            <span style={{ fontSize: "23px" }}>Our Branches</span>
          </h5>
          <div className="row qazxs_09">
            <Carousel responsive={responsive}>
              {allBranch?.map((item) => {
                return (
                  <div className="bg-15">
                    <div class="card fghit_0" style={{ width: "100%" }}>
                      <img src={`https://jaidrivingschool.in/Branch/${item?.image}`} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">
                          <LocationOnIcon />  <a href={item?.locationLink} target="_blank" style={{ color: "black" }}> {item?.area}</a>
                        </h5>
                        <p class="card-text">
                          {item?.Adress}, {item?.city} - {item?.pincode}{" "}
                          <br /> <PhoneIcon />
                          +91-{item?.branchNumber} <br />
                          <EmailOutlinedIcon />
                          {item?.branchEmail}
                        </p>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <button className="btn btn-warning" >
                            <a href={`/SingleBranch/${item?._id}`} style={{ color: "white" }}>
                              View Now</a>
                          </button>
                          <button className="btn btn-warning" >
                            <a style={{ color: "white" }} onClick={() => {
                              setbranch(item);
                              handleShow1();
                            }}>
                              Enquire Now</a>
                          </button></div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Carousel>

          </div>
        </div>
      </div>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="container">
            <div className="row">
              <div className="col-md-12">
                <div className="do-sear mt-2">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="vi_0"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label htmlFor="">E-mail ID</label>
                  <input
                    type="email"
                    placeholder="Enter your e-mail"
                    className="vi_0"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="do-sear mt-2">
                  <label htmlFor="">Mobile no</label>
                  <input
                    type="number"
                    placeholder="Enter your number"
                    className="vi_0"
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="do-sear mt-2">
                  <textarea
                    name=""
                    id=""
                    cols="40"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                    placeholder="Message....."
                    className="vi_0"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={Addenquire}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;
