import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { RiDeleteBin6Line } from 'react-icons/ri';
import axios from 'axios';
import ReactPaginate from 'react-paginate';



const AdminCoursetopics = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");

    const [AllTopic,setAllTopic]=useState([]);

    const getAllTopics=async()=>{
        try {
            let res=await axios.get("https://jaidrivingschool.in/api/admin/getCORSETOPIC");
            if(res.status==200){
                setAllTopic(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [topic,settopic]=useState("");

    useEffect(()=>{
        getAllTopics()
    },[])

const AddTopic=async()=>{
    try {
        if(!topic) return alert("Please enter course topic");
        let am=AllTopic.filter((ele)=>ele?.topic==topic);
        if(am?.length!==0) return alert(`${topic} Already exits`)
         const config = {
        url: "/admin/addCORSETOPIC",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
      topic:topic
        },
      };
      let res = await axios(config);
      if(res.status==200){
        alert("Successfully Added");
        handleClose();
          getAllTopics();
        settopic("");
      
      }
    } catch (error) {
        console.log(error);
    }
}

const [edit,setedit]=useState({});

const updateTupic=async()=>{
    try {
        if(!topic) return alert("Please enter course topic");
        let am=AllTopic.filter((ele)=>ele?.topic==topic);
        if(am?.length!==0) return alert(`${topic} Already exits`)
         const config = {
        url: "/admin/CORSETOPICupdate",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
            id:edit?._id,
          authId: admin?._id,
      topic:topic
        },
      };
      let res = await axios(config);
      if(res.status==200){
        alert("Successfully Updated");
        handleClose1();
          getAllTopics();
        settopic("");
      
      }
    } catch (error) {
        console.log(error);
    }
}

const [deleteId,setdeleteId]=useState("");

const deleteTotpic=async ()=>{
    try {
         const config = {
        url: "/admin/deleteCORSETOPIC/"+deleteId,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
       
          authId: admin?._id,
 
        },
      };
      let res = await axios(config);
      if(res.status==200){
        alert("Successfully Deleted");
        handleClose2();
          getAllTopics();    
      }
    } catch (error) {
        console.log(error);
    }
}
const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 10;
const pagesVisited = pageNumber * usersPerPage;
const pageCount = Math.ceil(AllTopic.length / usersPerPage);
const changePage = ({ selected }) => {
  setPageNumber(selected);
};
    return (
        <>
            <div className="add-gr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <span style={{ float: "right" }}>
                                <div className="ad-b mt-4">
                                    <button style={{ borderRadius: "10px", float: "right" }} onClick={() => {
                                        handleShow()
                                    }}>+ Add </button>
                                </div>
                            </span>
                        </div>
                    </div>
                    <di className="row">
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Topics</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AllTopic ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                                    return (<tr>
                                    <td>{i+1}.</td>

                                    <td>{item?.topic}</td>
                                    <td>
                                        <img src="./images/editing.png" alt="" width={30} onClick={() =>{
                                            setedit(item)
                                            handleShow1()}} />
                                    </td>
                                    <td>
                                        <Button
                                            type="button"
                                            variant="danger"
                                            onClick={() =>{
                                                setdeleteId(item?._id)
                                                 handleShow2()}}
                                        >
                                            <span>
                                                <RiDeleteBin6Line style={{ fontSize: "20" }} />
                                            </span>
                                        </Button>
                                    </td>
                                </tr>)
                                })}
                                

                            </tbody>
                        </Table>
                        <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
                    </di>
                </div>

            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Course Topics</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <div className='row'>
                        <div className="col-md-12">
                            <label>Course Topic</label>
                            <input
                                type="text"
                                placeholder="Enter Course Topic"
                                className="vi_0"
                                value={topic}
                                onChange={(e)=>settopic(e.target.value)}
                            />
                        </div>


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={AddTopic}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={show1}
                onHide={handleClose1}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Course Topics</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                   
                <div className='row'>
                        <div className="col-md-12">
                            <label>Course Topic</label>
                            <input
                                type="text"
                                placeholder={edit?.topic}
                                className="vi_0"
                                value={topic}
                                onChange={(e)=>settopic(e.target.value)}
                            />
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateTupic} >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* delete Model */}
            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete ??</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleClose2()}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={deleteTotpic} >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal >
        </>


    )
}

export default AdminCoursetopics