import React from "react";

const Sidebrr = ({ sidebar1 }) => {
  return (
    <div className={sidebar1 ? "sidebar1 sidebar1--open" : "sidebar1"}>
      <li>
        <a href="/sprofile">Profile</a>{" "}
      </li>{" "}
      <li>
        <a href="/scourse">Course</a>{" "}
      </li>{" "}
      <li>
        <a href="/Customer-One-Day-Course">One Day Course</a>{" "}
      </li>{" "}
      <li>
        <a href="/sattendence">Attendence</a>{" "}
      </li>{" "}
      <li>
        <a href="/scertificate">Certificate</a>{" "}
      </li>{" "}
      <li>
        <a href="/sservice">Service Status</a>{" "}
      </li>{" "}
      <li>
        <a href="/spay">Pay Receipt</a>{" "}
      </li>{" "}
      <li>
        <a href="/studentidcard">ID Card</a>{" "}
      </li>{" "}
      <li>
        <a href="/customer-wallet">Wallet</a>{" "}
      </li>{" "}

    </div>
  );
};

export default Sidebrr;
