import React, { useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineMail, AiOutlineSearch } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { BsCalendarDate, BsFillTelephoneFill } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios, { all } from "axios";
import moment from "moment";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { logDOM } from "@testing-library/react";
import StarRatings from "react-star-ratings";
import { FaEye } from "react-icons/fa";

const VendorTrainerProfile = () => {
  const [profile, setProfile] = useState(true);
  const [course, setCourse] = useState(false);
  const [service, setService] = useState(false);
  const [form5, setform5] = useState(false);
  const [form15, setForm15] = useState(false);
  const [form14, setForm14] = useState(false);
  const [payment, setPayment] = useState(false);
  const [rating,setrating]=useState(false);

  const { state } = useLocation();
  // console.log(state);

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getBookDeatailsByCustomerId = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getbookDetailsByTrainerId/" +
        state?._id+"/"+admin?._id,{   headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },}
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllServices, setServices] = useState([]);
const [nochanged,setnochanged]=useState([])
  const getAllService = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getbookDetailsByTrainerId/" +
          state?._id+"/"+admin?._id,{   headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },},{
            data:{authId:admin?._id}
          }
      );
      if (res.status == 200) {
        setServices(res.data.success);
        setnochanged(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [rate,setrate]=useState(0);
  const [AllRating,setAllRating]=useState([]);
  const getAllRating=async()=>{
    try {
      let res=await axios.get("https://jaidrivingschool.in/api/user/GetRatingByTrainer/" +
      state?._id);
      if(res.status==200){
        setAllRating(res.data.success)
        setrate(res.data.rate);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (state) {
      getBookDeatailsByCustomerId();
    //   getAllService();
      getAllRating();
    }
  }, []);

  const [data,setdata]=useState([])
  const [fileName, setfileName] = useState("Execl");
  const exportType = "xls";
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });

      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallCourse([...filterTable]);
    } else {
      setSearch(e.target.value);
      setallCourse([...nochangedata]);
    }
  };

  const TrainerHandle = (e) => {
    if (e.target.value != "") {
    
      const filterTable = nochanged.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setServices([...filterTable]);
    } else {
    
        setServices([...nochanged]);
    }
  }
// const item=state

const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.getElementById("pdf"), {
        useCORS: true,
    });
    console.log("data", data);
    const img = data.toDataURL("image/png");
    console.log("img", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("imgProperties", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("pdfWidth", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("pdfHeight", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Form5.pdf");
};

const [classDate,setclassDate]=useState("");
const [FromHrs,setFromHrs]=useState("");
const [ToHrs,setToHrs]=useState("");
const [TotalHrs,setTotalHrs]=useState("");
const [Classofvehicle,setClassofvehicle]=useState("");
const [Signatureofinsturctor,setSignatureofinsturctor]=useState("");
const [SignatureofTrainer,setSignatureofTrainer]=useState("");
    const admin = JSON.parse(sessionStorage.getItem("branch"));
    const token = sessionStorage.getItem("token");

    const [showAt,setshowAt]=useState(false);
const [courseD,setcourseD]=useState({});
const [AllAttandance,setAllAttandance]=useState([]);
const getAttedance=async()=>{
  try {
    let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassByTrainerId/"+state?._id);
    if(res.status==200){
      setAllAttandance(res.data.success);
    }
  } catch (error) {
    console.log(error);
  }
}

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ad-b">
              <button
                onClick={() => {
                  setProfile(true);
                  setCourse(false);
                  setService(false);
                  setform5(false);
                  setForm15(false);
                  setForm14(false);
                  setrating(false);
                  setPayment(false);
                }}
              >
                Profile
              </button>{" "}
              &nbsp;
              <button
                onClick={() => {
                  setProfile(false);
                  setCourse(true);
                  setService(false);
                  setform5(false);
                  setForm15(false);
                  setForm14(false);
                  setrating(false);
                  setPayment(false);
                  setdata([...allCourse])
                }}
              >
              Assgined  Course
              </button>
              {/* &nbsp;
              <button
                onClick={() => {
                  setProfile(false);
                  setCourse(false);
                  setService(true);
                  setform5(false);
                  setForm15(false);
                  setrating(false);
                  setForm14(false);
                  setPayment(false);
                  setdata([...AllServices])
                }}
              >
                Services
              </button> */}
              &nbsp;
              <button
                onClick={() => {
                  setProfile(false);
                  setCourse(false);
                  setService(false);
                  setform5(false);
                  setrating(true);
                  setForm15(false);
                  setForm14(false);
                  setPayment(false);
                  setdata([...AllServices])
                }}
              >
                Rating
              </button>
              {/* &nbsp;
              <button
                onClick={() => {
                  setProfile(false);
                  setCourse(false);
                  setService(false);
                  setform5(true);
                  setForm15(false);
                  setForm14(false);
                  setPayment(false);
                }}
              >
                Form 5
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setProfile(false);
                  setCourse(false);
                  setService(false);
                  setform5(false);
                  setForm15(true);
                  setForm14(false);
                  setPayment(false);
                }}
              >
                Form 15
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setProfile(false);
                  setCourse(false);
                  setService(false);
                  setform5(false);
                  setForm15(false);
                  setForm14(true);
                  setPayment(false);
                }}
              >
                Form 14
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setProfile(false);
                  setCourse(false);
                  setService(false);
                  setform5(false);
                  setForm15(false);
                  setForm14(false);
                  setPayment(true);
                }}
              >
                Payment Rescipt
              </button> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {profile ? (
              <>
                <div>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src={`https://jaidrivingschool.in/Staff/${state?.profile}`}
                          alt=""
                          style={{
                            width: "200px",
                            height: "200px",
                            borderRadius: "24px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <BiUser
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          />{" "}
                          <span style={{ color: "#9d041b" }}>Name</span>
                          <p className="ms-4">{state?.name}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <AiOutlineHome
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          />{" "}
                          <span style={{ color: "#9d041b" }}>
                            Branch Name
                          </span>
                          <p className="ms-4">{state?.branchName}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <AiOutlineHome
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          />{" "}
                          <span style={{ color: "#9d041b" }}>
                            trainerId
                          </span>
                          <p className="ms-4">{state?.staffId}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <i
                            class="fa-solid fa-venus-mars"
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          ></i>{" "}
                          <span style={{ color: "#9d041b" }}>City</span>
                          <p className="ms-4">{state?.city}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <BsFillTelephoneFill
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          />{" "}
                          <span style={{ color: "#9d041b" }}>
                            Contact Details
                          </span>
                          <p className="ms-4">{state?.mobile}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <AiOutlineMail
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          />{" "}
                          <span style={{ color: "#9d041b" }}>Email</span>
                          <p className="ms-4">{state?.email}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <AiOutlineHome
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          />{" "}
                          <span style={{ color: "#9d041b" }}>Address</span>
                          <p className="ms-4">{state?.Address}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <AiOutlineHome
                            style={{ color: "#9d041b", fontSize: "20px" }}
                          />{" "}
                          <span style={{ color: "#9d041b" }}>Join For</span>
                          <p className="ms-4">Trainer</p>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {course ? (
                  <>
                    <div>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">
                                <AiOutlineSearch />
                              </InputGroup.Text>
                              <Form.Control
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={handleFilter}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-md-6 ad-b">
                            <button style={{ float: "right" }} onClick={ExportToExcel}>
                              Export Excel
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="mo-gra mt-5 scrolll_bar">
                          <Table
                            striped
                            bordered
                            hover
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr>
                                <th>S No.</th>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Branch</th>
                                <th>Course Name</th>
                                <th>Course Starting Date</th>
                                <th>Course Ending Date</th>
                                <th> Days</th>

                                <th>Payment Method</th>
                                <th>Paid Amount</th>

                                <th>Balance Amount</th>
                                <th>Total</th>
                                <th>Class View</th>
                                <th>Payment Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allCourse?.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td> {item?.bookid}</td>
                                    <td>{item?.customerName}</td>
                                    <td>{item?.branchName}</td>
                                    <td>{item?.courseTitle}</td>
                                    <td>{item?.courseStartDate ? (moment(item?.courseStartDate).format("DD/MM/YYYY")):""}</td>
                                    <td>{item?.courseEndDate ? (moment(item?.courseEndDate).format("DD/MM/YYYY")):""}</td>
                                <td>{item?.courseDuration}</td>
                                    <td>{item?.paymentMethod}</td>
                                    <td>₹{item?.payAmount}</td>
                                    <td>₹{Number(item?.totalAmount)-item?.payAmount}</td>
                                    <td>₹{Number(item?.totalAmount)}</td>
                                    <td><FaEye style={{color:"blue",fontSize:"25px",cursor:"pointer"}} onClick={()=>{
                                      getAttedance(item?.customerId?._id)
                                      setcourseD(item)
                                      setshowAt(true)
                                    }}/></td>
                                    <th>{item?.paymentStatus}</th>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {service ? (
                      <>
                        <div>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-6">
                                <InputGroup className="mb-3">
                                  <InputGroup.Text id="basic-addon1">
                                    <AiOutlineSearch />
                                  </InputGroup.Text>
                                  <Form.Control
                                    placeholder="Search"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={TrainerHandle}
                                  />
                                </InputGroup>
                              </div>
                              <div className="col-md-6 ad-b">
                                <button style={{ float: "right" }} onClick={ExportToExcel}>
                                  Export Excel
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="container">
                            <div className="mo-gra mt-5 scrolll_bar">
                              <Table
                                striped
                                bordered
                                hover
                                style={{ textAlign: "center" }}
                              >
                                <thead>
                                  <tr>
                                    <th>S No.</th>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Branch</th>
                                    <th>Services Name</th>
                                    {/* <th>Services Buyed</th> */}

                                    <th>Payment Method</th>
                                    <th>Paid Amount</th>

                                    <th>Balance Amount</th>
                                    <th>Total</th>
                                    <th>Payment Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                    {AllServices?.map((item,i)=>{
                                        return (
                                            <tr>
                                    <td>{i + 1}</td>
                            <td>{item?.bookid}</td>
                            <td>{item?.customerName}</td>

                                    <td>{item?.branchName}</td>
                                    <td>{item?.Servicename}</td>
                                    {/* <td>Learning Licese</td> */}
                                    <td>{item?.paymentMethod}</td>
                                    <td>₹{item?.payAmount}</td>
                                    <td>₹{Number(item?.totalAmount)-item?.payAmount}</td>
                                    <td>₹{item?.totalAmount}</td>
                                    <th>
                              {item?.paymentStatus == "Under Proccess" ? (
                                <span style={{ color: "blue" }}>
                                  {item?.paymentStatus}
                                </span>
                              ) : (
                                <span style={{ color: "green" }}>
                                  {item?.paymentStatus == "Assigned" ? (
                                    <span style={{ color: "Red" }}>
                                      {item?.paymentStatus}
                                    </span>
                                  ) : (
                                    <span>{item?.paymentStatus}</span>
                                  )}
                                </span>
                              )}
                            </th>
                                  </tr>  
                                        )
                                    })}
                                
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                      
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {rating ? (<div>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-6">
                                {/* <InputGroup className="mb-3">
                                  <InputGroup.Text id="basic-addon1">
                                    <AiOutlineSearch />
                                  </InputGroup.Text>
                                  <Form.Control
                                    placeholder="Search"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={TrainerHandle}
                                  />
                                </InputGroup> */}
                              </div>
                              <div className="col-md-6 ad-b">
                                <h3 style={{ float: "right" }} >
                                <StarRatings
        rating={rate}
        starDimension="20px"
        // changeRating={setratting}
        starSpacing="2px"
        starRatedColor="#fcca0b"
      />
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="container">
                            <div className="mo-gra mt-5 scrolll_bar">
                              <Table
                                striped
                                bordered
                                hover
                                style={{ textAlign: "center" }}
                              >
                                <thead>
                                  <tr>
                                    <th>S No.</th>
                                    <th>Trainer Image</th>
                                    <th>Trainer Name</th>
                                    <th>Rating</th>
                                    <th>Remarks</th>
                                   
                                  </tr>
                                </thead>
                                <tbody>
                                    {AllRating?.map((item,i)=>{
                                        return (
                                            <tr>
                                    <td>{i + 1}</td>
                            <td>{item?.bookid}</td>
                            <td>{item?.trainerName}</td>

                                    <td><StarRatings
        rating={Number(item?.ratting)}
        starDimension="20px"
        // changeRating={setratting}
        starSpacing="2px"
        starRatedColor="#fcca0b"
      /></td>
                                    <td>{item?.remark}</td>
                                  

                                  </tr>  
                                        )
                                    })}
                                
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>):<></>}
          </div>
        </div>
       
        <Modal
            show={showAt}
            onHide={()=>setshowAt(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Classes And Attanence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="scrolll_bar mb-4">
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        {/* <th>S.No</th> */}
                        {/* <th>Id</th> */}
                        <th>Day</th>
                        <th>Topic Name</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Intial Reading</th>
                        <th>End Reading</th>
                        {/* <th>Trainer Name</th> */}
                        <th>Remark</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseD?.courseTopic?.map((item, i) => {
                        return (
                          <tr>
                            {/* <td>{i + 1}</td> */}
                        
                            <td>{item?.days}</td>
                            <td>{item?.topicName}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectdate}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectTime}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.endtime}</td>

                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.initalSt}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.endre}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.remarks}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)?.length==0 ?(<span style={{color:"blue"}}>Pending</span>):(<span style={{color:"green"}}>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.status}</span>)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setshowAt(false)}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={handleSubmit}>
                Submit{" "}
              </Button> */}
            </Modal.Footer>
          </Modal>
      </div>
    </>
  );
};

export default VendorTrainerProfile;
