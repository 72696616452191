import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaEdit, FaEye } from "react-icons/fa";
import exportFromJSON from "export-from-json";
import moment from "moment";
import useRazorpay from "react-razorpay";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";


// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
const AdminStudents = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
 const history=useNavigate();
  const handleClose1 = () => setShow1(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const [editShow, seteditShow] = useState(false);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  const [AllBooking, setAllBooking] = useState([]);
  const [data, setdata] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const [alltimeSlot, setalltimeSlot] = useState([]);
  const getAlltimeSlote = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getslotTime"
      );
      if (res.status == 200) {
        setalltimeSlot(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllCustomer, setAllCustomer] = useState([]);
  // const [nochangedata, setnochangedata] = useState([])
  const getCustomer = async () => {
    try {
      const config = {
        url: "/Customer/getAllCustomer",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
        // setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAlltimeSlote();
    getCustomer();
  }, []);
  const getAllBooking = async () => {
    try {
      const config = {
        url: "/admin/getAllBooking/" + admin?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
        setnochangedata(res.data.success);
        setdata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllTrainer, setAlltrainer] = useState([]);
  const [nochangedataT, setnochangedataT] = useState([]);
  const getTrainer = async () => {
    try {
      const config = {
        url: "/staff/getAllSudents",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
        setnochangedataT(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getAllBooking();
      getTrainer();
    }
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllBooking.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [pageNumber1, setPageNumber1] = useState(0);
  const usersPerPage1 = 10;
  const pagesVisited1 = pageNumber1 * usersPerPage1;
  const pageCount1 = Math.ceil(AllTrainer.length / usersPerPage1);
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllBooking([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllBooking([...nochangedata]);
    }
  };

  const TrainerHandle = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedataT.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAlltrainer([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAlltrainer([...nochangedataT]);
    }
  };

  const [slectData, setSelectData] = useState({});

  const BookAssignTrainer = async (data) => {
    try {
      const config = {
        url: "/admin/BookAssignTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          bookId: slectData?._id,
          trainerId: data?._id,
          trinerStatus: "Assigned",
          trinerName: data?.name,
          trainerMobile: data?.mobile,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        handleClose();
        getAllBooking();
        getTrainer();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const cancelAssigneTrainer = async (data) => {
    try {
      const config = {
        url: "/admin/BookAssignTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          bookId: slectData?._id,
          trainerId: slectData?._id,
          trinerStatus: "Pending",
          trinerName: "",
          trainerMobile: "",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully cancel");
        handleClose();
        getAllBooking();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [fileName, setfileName] = useState("CourseBooking");
  const exportType = "xls";
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
    } else {
      alert("Enter file name to export");
    }
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = nochangedata?.filter((ele) => {
    
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setAllBooking(abc);
    setdata(abc);
    setfileName(
      `CourseBooking_${moment(fromd).format("DD/MM/YYYY")} to ${moment(
        tod
      ).format("DD/MM/YYYY")}`
    );
  };


  const [user, setuser] = useState("");

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [branch, setbranch] = useState("");
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [photo, setphoto] = useState("");
  const [idproof, setidproof] = useState("");
  const [medicleProof, setmedicleProof] = useState("");

  useEffect(() => {
    if (user) {
      setname(user?.name);
      setmobile(user?.mobile);
      setemail(user?.email);
      setgender(user?.gender);
      setdob(user?.dob ? user?.dob : "");
      setstate(user?.state);
      setcity(user?.city);
      setaddress(user?.Address);
      setpincode(user?.pincode);
      setphoto(user?.profile);
      setidproof(user?.addhar);
      setmedicleProof(user?.medicalproof);
    }
  }, [user]);
  const [course, setCourseData] = useState({});

  const updateUser = async () => {
    try {
      let ab = "";
      if (!editShow) {
        if (!name) return alert("Please fill the blank");
        if (!email) return alert("Please fill the blank");
        if (!mobile) return alert("Please fill the blank");
        if (
          !user |
          !gender |
          !dob |
          !city |
          !address |
          !pincode |
          !photo |
          !idproof |
          !Object.keys(course).length
        )
          return alert("Please complete the form");
        if (!branch) return alert("Please select branch");

        if (branch?.branchName == "Main Branch") {
          ab = `Main Branch`;
        } else {
          ab = `${branch?.branchName},${branch?.area}`;
        }
      } else {
        if (branch)
          if (branch?.branchName == "Main Branch") {
            ab = `Main Branch`;
          } else {
            ab = `${branch?.branchName},${branch?.area}`;
          }
      }

      let obj = {
        userId: user?._id,
        name: name,
        mobile: mobile,
        email: email,
        city: city,
        area: ab,
        courseData: course,
        passportcopy: photo,
        addhar: idproof,
        medicalproof: medicleProof,
        dob: dob,
        Address: address,
        gender: gender,
        state: state,
        pincode: pincode,
      };

      const config = {
        url: "/customer/updateUser",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status == 200) {
        handleShow5();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [allCity, setAllCity] = useState([]);
  const getAllcity = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllCity"
      );
      if (res.status == 200) {
        setAllCity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allBranch, setAllBranc] = useState([]);
  const getAllBranch = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setAllBranc(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [date, setdate] = useState("");
  const [time, settime] = useState("");

  useEffect(() => {
    const currentDate = new Date(Date.now());
    currentDate.setDate(currentDate.getDate() + 1);
    const formattedNewDate = currentDate.toISOString().split("T")[0];
    setdate(formattedNewDate);
  }, []);

  const [car, setvehicleData] = useState({});
  const instructur = (item) => {
    if (!date) return alert("Please select date");

    if (!time) return alert("Please select time");
    setvehicleData(item);
    handleShow6();
  };

  const [AllCars, setAllCars] = useState([]);
  const GetAllCars = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllVehicle"
      );
      if (res.status == 200) {
        setAllCars(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allCourse, setallCourse] = useState([]);
  // const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/course/getAllcourse"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllAditional, setAllAditional] = useState([]);
  const getAddition = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllAdditional"
      );
      if (res.status == 200) {
        setAllAditional(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetAllCars();
    getAllcity();
    getAllBranch();
    getAllCourse();
  }, []);

  const [editPay,setEditPay]=useState(0)
  let [add, setadd] = useState(0);

  const [branchId, setbranchId] = useState("");

  const [additionalCharge, setadditionalcharge] = useState([]);
  const checkHand = (data) => {
    let am = additionalCharge.filter((item) => item?.text == data?.text);
    if (am.length != 0) {
      let ab = additionalCharge.filter((item) => item?.text !== data?.text);
      setadditionalcharge(ab);
      setadd(ab.reduce((a, i) => a + Number(i.price), 0));
    } else {
      additionalCharge.push(data);
      setadd(additionalCharge.reduce((a, i) => a + Number(i.price), 0));
    }
  };

  const top100Films = AllCustomer.map((item) => ({
    label: `${item?.customerId}-${item?.name}`,
    year: JSON.stringify(item),
  }));
  const top100Films2 = allCourse.map((item) => ({
    label: `${item?.courseName}-${item?.courseDuration} days`,
    year: JSON.stringify(item),
  }));

  const [selectedValue, setSelectedValue] = useState(null);
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);

    if (newValue) {
      setuser(JSON.parse(newValue.year));
    } else {
      setuser("");
    }
  };

  const [selectedValue2, setSelectedValue2] = useState(null);
  const handleAutocompleteChange2 = (event, newValue) => {
    setSelectedValue2(newValue);

    if (newValue) {
      setCourseData(JSON.parse(newValue.year));
    } else {
      setCourseData({});
    }
  };

  let PayAmount =
    Number(course?.price) + (car?.price ? Number(car.price) : 0) + Number(add);
  const gst = car?.Tax ? PayAmount * (Number(car?.Tax) / 100) : 0;

  const [PayMethod, setpayMethod] = useState("");
  let total = PayAmount + gst;

  let enddate = new Date(date);

  const addedDate = enddate.setDate(enddate.getDate() + course?.courseDuration);
  const Razorpay = useRazorpay();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const [payId, setpayid] = useState("");
  const addAcountHistory = async (bookId) => {
    try {
      const config = {
        url: "/admin/addAccount",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          customeId: user?.customerId,
          bookingId: bookId,
          paymentId: payId,
          amount: total,
          status: "Cr",
        },
      };
      await axios(config);
    } catch (error) {
      console.log(error);
    }
  };

  const BookCourse = async () => {
    try {
      if (!date) return alert("Please select date");

      if (!time) return alert("Please select time");
      let ab = "";
      if (branch?.branchName == "Main Branch") {
        ab = `Main Branch`;
      } else {
        ab = `${branch?.branchName},${branch?.area}`;
      }
      let paymentStatus = "Under Proccess";

      let pay = 0;
      if (payId) {
        pay = total;
        paymentStatus = "Full Payment";
      }

      const config = {
        url: "/admin/createBooking",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          gst: gst,
          paymentStatus: paymentStatus,
          vechicleId: car?._id,
          customerId: user?._id,
          dob: dob,
          number: mobile,
          email: email,
          customerName: name,
          courseId: course?._id,
          courseTitle: course?.courseName,
          coursePrice: course?.price,
          courseStartDate: date,
          courseEndDate: enddate,
          courseTiming: time,
          schoolBranch: branch?.branchName,
          city: city,
          totalAmount: total,
          paymentMethod: PayMethod,
          vechicleType: car?.vehicleType,
          payId: payId,
          payAmount: pay,
          Address: address,
          courseDuration: course?.courseDuration,
          courseTopic: course?.courseTopic,
          additional: additionalCharge,
          branchId: branch?._id,
          branchName: ab,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        if (payId) {
          addAcountHistory(res.data.bookId);
        }
        alert("Successfully booked");
        window.location.reload();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const posttransaction = async () => {
    try {
      if (!PayMethod) return alert("Please select payment method");
      if (PayMethod == "Cash") {
        if(editShow){
          return EbookingData()
        }else{
           return BookCourse();
        }
      }
      let amount=0
      if(editShow){
        amount=editPay* 100
      }else{
        amount=total * 100
      }

      const config = {
        data: {
          key: "rzp_test_FAe0X6xLYXaXHe",
          amount: Math.round(amount),
          currency: "INR",
          name: "Jai Driving School",
          description: "Order Amount",
          image: "../images/logo.png.jpg",
          customerId: user?._id,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            name: user?.name,
            email: user?.email,
            contact: user?.mobile,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  

  const [editData, setEditData] = useState({});

  const EbookingData = async () => {


    let ab = "";
    if(branch){
       if (branch?.branchName == "Main Branch") {
      ab = `Main Branch`;
    } else {
      ab = `${branch?.branchName},${branch?.area}`;
    }
    }
   
    let paymentStatus = "";

    let pay = 0;
    if (payId) {
      pay = editData?.payAmount+editPay
      paymentStatus = "Full Payment";
    }

    try {
      const config = {
        url: "/admin/updateBokking",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId:admin?._id,
          id:editData?._id,
          gst: gst,
          paymentStatus: paymentStatus,
          vechicleId: car?._id,
          customerId: user?._id,
          dob: dob,
          number: mobile,
          email:email,
          customerName: name,
          courseId: course?._id,
          courseTitle: course?.courseName,
          coursePrice: course?.price,
          courseStartDate: date,
          courseEndDate: enddate,
          courseTiming: time,
          schoolBranch: branch?.branchName,
          city: city,
          totalAmount: (Number(editData?.coursePrice)+add),
          paymentMethod: PayMethod,
          vechicleType: car?.vehicleType,
          payId: payId,
          payAmount: pay,
          Address: address,
          courseDuration: course?.courseDuration,
          courseTopic: course?.courseTopic,
          additional: additionalCharge,
          branchId: branch?._id,
          branchName: ab,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        if (payId) {
          addAcountHistory(res.data.bookId);
        }
        alert("Successfully updated");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShow1 = () => {
    if(editShow){
      let am=(Number(editData?.coursePrice)+add)-Number(editData?.payAmount)
      if(am>0){
        setEditPay(am);
        return  setShow1(true);
      }else{
        return EbookingData()
      }
    }else{
     return  setShow1(true);
    }
   }

   useEffect(() => {
    if (payId) {
      if(editShow){
      return  EbookingData()
      }else{
        return   BookCourse();
      }
    }
  }, [payId]);


  const [showAt,setshowAt]=useState(false);
  const [courseD,setcourseD]=useState({});
  const [AllAttandance,setAllAttandance]=useState([]);
  const getAttedance=async(id)=>{
    try {
      let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassBycustomerId/"+id);
      if(res.status==200){
        setAllAttandance(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }

 
  const makeGenrateCertificate=async(id)=>{
    try {
      const config={
        url: "/admin/makeisGenrateCertificate",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data:{
          id:id,certificateDate:moment().format("DD/MM/YYYY")
        }
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully genrated Certificate")
        getAllBooking();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const makeCompleteCourse=async(id)=>{
    try {
      const config={
        url: "/admin/MakeCancelConfirmBookingCourses",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data:{
          authId:admin?._id,
          bookId:id,
          status:"Completed"  
        }
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully Completed");
        getAllBooking()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="ad-b mt-4">
                  <button>Course Booking Details</button>
                </div>
              </div>
              <div className="col-md-9">
                <div className="ad-b mt-4">
                  <button
                    style={{ borderRadius: "10px", float: "right" }}
                    onClick={()=>{
                      setadd(0)
                      setadditionalcharge([])
                      settime("")
                      
                      setbranchId("");
                      setuser("");
                      setEditData({});
                      seteditShow(false);
                      handleShow4()}}
                  >
                    +Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px", marginTop: "26px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="">From</label>
                  <input
                    type="date"
                    class="form-control"
                    onChange={(e) => setfromd(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="">To</label>
                  <input
                    type="date"
                    class="form-control"
                    onChange={(e) => settod(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    onClick={searchDate}
                    style={{
                      borderRadius: "3px",
                      color: "white",
                      fontSize: "18px",
                      border: "1px solid",
                      backgroundColor: "#9d041b",
                      padding: "5px",
                      marginTop: "26px",
                      borderRadius: "10px",
                      fontWeight: 500,
                    }}
                  >
                    Search
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    onClick={ExportToExcel}
                    style={{
                      borderRadius: "3px",
                      color: "white",
                      fontSize: "18px",
                      border: "1px solid",
                      backgroundColor: "#9d041b",
                      padding: "5px",
                      marginTop: "26px",
                      borderRadius: "10px",
                      fontWeight: 500,
                      float: "right",
                      marginRight: "10px",
                    }}
                  >
                    Export To Excel
                  </button>
                </div>
              </div>
              <div className="scrolll_bar">
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>BookId</th>
                      <th>Passport Size Image</th>
                      <th>Name</th>
                      <th>Date Of Birth</th>
                      <th>Number</th>
                      <th>Email</th>
                      <th>Aadhar</th>
                      <th>Branch</th>
                      <th>Course</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Class Timing</th>
                      <th>Cource Price</th>
                      <th>Additional</th>
                      <th>Total Price</th>
                      <th>Trainer Name</th>
                      <th>Trainer Mobile</th>
                      <th>Booking Status</th>
                      <th>Booking Date</th>
                      <th>Trainer Status</th>
                      <th>Class View</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllBooking?.slice(
                      pagesVisited,
                      pagesVisited + usersPerPage
                    )?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item?.bookid}</td>
                          <td>
                            {" "}
                            <a
                              href={`https://jaidrivingschool.in/Customer/${item?.customerId?.passportcopy}`}
                              target="_blank"
                            >
                              <img
                                src={`https://jaidrivingschool.in/Customer/${item?.customerId?.passportcopy}`}
                                style={{ width: "100px", height: "100px" }}
                              />
                            </a>
                          </td>
                          <td>{item?.customerName}</td>
                          <td>{item?.customerId?.dob}</td>

                          <td>{item?.number}</td>
                          <td>{item?.email}</td>
                          <td>
                            <a
                              href={`https://jaidrivingschool.in/Customer/${item?.customerId?.addhar}`}
                              target="_blank"
                            >
                              {" "}
                              <img
                                src={`https://jaidrivingschool.in/Customer/${item?.customerId?.addhar}`}
                                style={{ width: "100px", height: "100px" }}
                              />
                            </a>
                          </td>
                          <td>{item?.branchName}</td>
                          <td>{item?.courseTitle}</td>
                          <td>
                            {moment(item?.courseStartDate).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {moment(item?.courseEndDate).format("DD/MM/YYYY")}
                          </td>
                          <td>{item?.courseTiming}</td>
                          <td>{item?.coursePrice}</td>
                          <td>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Service</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.additional.map((ele, i) => {
                                  return (
                                    <tr>
                                      {/* <td>{i + 1}</td> */}
                                      <td>{ele?.text}</td>
                                      <td>₹{ele?.price}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </td>
                          <td>{item?.totalAmount}</td>
                          <td>{item?.trinerName}</td>
                          <td>{item?.trainerMobile}</td>
                          <td>
                            {item?.status == "Pending" ? (
                              <span style={{ color: "blue" }}>
                                {item?.status}
                              </span>
                            ) : (
                              <span style={{ color: "green" }}>
                                {item?.status == "Assigned" ? (
                                  <span style={{ color: "Red" }}>
                                    {item?.status}
                                  </span>
                                ) : (
                                  <span>{item?.status}</span>
                                )}
                              </span>
                            )}
                          </td>
                          <td>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {item?.trinerStatus == "Pending" ? (
                              <span style={{ color: "blue" }}>
                                {item?.trinerStatus}
                              </span>
                            ) : (
                              <span
                                style={{ color: "green" }}
                                onClick={() => {
                                  setSelectData(item);
                                  handleShow3();
                                }}
                              >
                                {item?.trinerStatus == "Assigned" ||
                                item?.trinerStatus == "Rejected" ? (
                                  <span style={{ color: "Red" }}>
                                    {item?.trinerStatus} <FaEye />
                                  </span>
                                ) : (
                                  <span>
                                    {item?.trinerStatus} <FaEye />
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                          <td><FaEye style={{color:"blue",fontSize:"25px",cursor:"pointer"}} onClick={()=>{
                                      getAttedance(item?.customerId?._id)
                                      setcourseD(item)
                                      setshowAt(true)
                                    }}/></td>
                          <td>
                            {" "}
                            <div style={{ display: "flex", gap: "5px" }}>
                              {item?.trinerStatus !== "Accepted" ||
                              item?.trinerStatus !== "Complete" ? (
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  onClick={() => {
                                    setSelectData(item);
                                    handleShow();
                                  }}
                                >
                                  Assgined Trainer
                                </button>
                              ) : (
                                <></>
                              )}{" "}
                              <FaEdit
                                style={{
                                  color: "blue",
                                  fontSize: "45px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setadd(item?.additional?.reduce((a, i) => a + Number(i.price), 0))
                                  setadditionalcharge(item?.additional)
                                  settime(item?.courseTiming)
                                  setdate(item?.courseStartDate)
                                  setbranchId(item?.branchId);
                                  setuser(item?.customerId);
                                  setEditData(item);
                                  seteditShow(true);
                                }}
                              />
                              {item?.status=="Completed"||item?.status=="Cancel"? (<div>{item?.status=="Cancel" ? (<></>):(<>{item?.isGenrateC==true? (<button type="button" class="btn btn-success" onClick={()=>history("/Admin-Certificate",{state:item})}>View Certificate</button>):(<button type="button" class="btn btn-warning" onClick={()=>makeGenrateCertificate(item?._id)}>Generate Certificate</button>)}</>)}</div>):(<button type="button" class="btn btn-success" onClick={()=>makeCompleteCourse(item?._id)}>Complete Course</button>) }

                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <br />
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assgin Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Search Trainer...."
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                  onChange={(e) => TrainerHandle(e)}
                />
              </div>
            </div>
            {AllTrainer?.slice(
              pagesVisited1,
              pagesVisited1 + usersPerPage1
            )?.map((item) => {
              return (
                <div className="">
                  <img
                    src={`https://jaidrivingschool.in/Staff/${item?.profile}`}
                    style={{
                      borderRadius: "100%",
                      height: "40px",
                      width: "40px",
                    }}
                  />{" "}
                  <span>{item?.name}</span> <span>{item?.mobile}</span>{" "}
                  <span style={{ float: "right" }}>
                    {item?.mobile == slectData?.trainerMobile ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={cancelAssigneTrainer}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={() => {
                          BookAssignTrainer(item);
                        }}
                      >
                        Assign Now
                      </button>
                    )}{" "}
                  </span>
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount1}
              onPageChange={changePage1}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show Assgned Trainer Model */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assgined Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row"></div>
            {AllTrainer?.filter((ele) => ele?._id == slectData?.trainerId)?.map(
              (item) => {
                return (
                  <div className="">
                    <img
                      src={`https://jaidrivingschool.in/Staff/${item?.profile}`}
                      style={{
                        borderRadius: "100%",
                        height: "40px",
                        width: "40px",
                      }}
                    />{" "}
                    <span>{item?.name}</span> <span>{item?.mobile}</span>{" "}
                    <span style={{ float: "right" }}>
                      {slectData?.trinerStatus == "Assigned" ? (
                        <span style={{ color: "red" }}>Assigned</span>
                      ) : (
                        <BsFillCheckCircleFill
                          style={{ color: "green", fontSize: "25px" }}
                        />
                      )}{" "}
                    </span>
                  </div>
                );
              }
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Fill The Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-12">
                  <label htmlFor="">Customer/Student</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    sx={{ width: 420 }}
                    value={selectedValue} // This sets the selected value
                    onChange={handleAutocompleteChange} // Handle change event
                    renderInput={(params) => (
                      <TextField {...params} label="Customer/Student" />
                    )}
                  />
            
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-12">
                  <label htmlFor="">Course</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films2}
                    sx={{ width: 420 }}
                    value={selectedValue2} // This sets the selected value
                    onChange={handleAutocompleteChange2} // Handle change event
                    renderInput={(params) => (
                      <TextField {...params} label="Courses" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class="col-md-6">
                  <label htmlFor="">Email ID</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    class="form-control"
                    placeholder="Email"
                    aria-label="Email"
                  />
                </div>
                <div class="col-md-6">
                  <label htmlFor="">Mobile</label>
                  <input
                    type="number"
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    class="form-control"
                    placeholder="Mobile"
                    aria-label="Mobile"
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class="col-md-6">
                  <label htmlFor="">Gender</label>
                  <select
                    class="form-select "
                    aria-label=".form-select-lg example"
                    onChange={(e) => setgender(e.target.value)}
                  >
                    <option>{gender ? gender : "Select gender"} </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div class="col-md-6">
                  <label htmlFor="">DOB</label>
                  <input
                    type="date"
                    value={dob}
                    onChange={(e) => setdob(e.target.value)}
                    class="form-control"
                    placeholder="Last name"
                    aria-label="Last name"
                  />
                </div>
              </div>
            </div>

            <div className="do-sear mt-2">
              <div class="row me-0">
                <div className="" style={{ display: "flex" }}>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label">
                      City
                    </label>
                    <select
                      id="inputState"
                      class="form-select formselect"
                      onChange={(e) => setcity(e.target.value)}
                    >
                      <option selected>
                        {user?.city ? city : "Choose..."}
                      </option>
                      {allCity?.map((item) => {
                        return <option value={item?.city}>{item?.city}</option>;
                      })}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label ms-2">
                      Branch
                    </label>
                    <select
                      class="form-select ms-2 formselect"
                      onChange={(e) => {
                        setbranch(
                          e.target.value ? JSON.parse(e.target.value) : {}
                        );
                        setbranchId(
                          e.target.value ? JSON.parse(e.target.value)?._id : ""
                        );
                      }}
                    >
                      <option value="">Choose...</option>
                      <option
                        value={JSON.stringify({ branchName: "Main Branch" })}
                      >
                        Main Branch
                      </option>
                      {allBranch
                        ?.filter((ele) => ele?.city == city)
                        ?.map((item) => {
                          return (
                            <option value={JSON.stringify(item)}>
                              {item.branchName},{item?.area}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-6">
                  <label for="exampleFormControlTextarea1" class="form-label">
                    Address
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Please enter your address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                  ></textarea>
                </div>
                <div class="col-md-6">
                  <label for="inputZip" class="form-label">
                    Zip
                  </label>
                  <input
                    type="text"
                    placeholder="Please enter zip"
                    value={pincode}
                    onChange={(e) => setpincode(e.target.value)}
                    class="form-control"
                    id="inputZip"
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div style={{ display: "flex" }}>
                  <div class=" col-md-6">
                    <label for="formFile" class="form-label" htmlFor="upload1">
                      Passport Photo
                    </label>
                    <input
                      class="form-control formselect"
                      type="file"
                      id="upload1"
                      accept="image/*"
                      onChange={(e) => setphoto(e.target.files[0])}
                    />
                  </div>
                  <div class=" col-md-6">
                    <label for="formFile" class="form-label" htmlFor="upload2">
                      Addhar
                    </label>
                    <input
                      class="form-control formselect"
                      type="file"
                      id="upload2"
                      onChange={(e) => setidproof(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateUser();
                getAddition();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Additional */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Choose the Car & Select Course date And time
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div class="col-md-4">
                <label for="disabledSelect" class="form-label">
                  Course Start Date
                </label>
                <input
                  type="date"
                  id="disabledTextInput"
                  value={date}
                  onChange={(e) => setdate(e.target.value)}
                  class="form-control"
                  min={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-md-4">
                <label for="disabledSelect" class="form-label">
                  Select Your Time
                </label>
                <Form.Select
                  aria-label="Default select example"
                value={time}
                  onChange={(e) => settime(e.target.value)}
                >
                  <option>{time? (time):"--Select time slot--"}</option>
                  {alltimeSlot
                    ?.filter((item) => item?.branchId == branch?._id)
                    ?.map((ele) => {
                      return (
                        <option value={ele?.AvailableTime}>
                          {ele?.AvailableTime}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
              <div className="col-md-3">
             
                <button
                  className="btn btn-warning"
                  onClick={() => {
                    instructur({});
                  }}
                  style={{ float: "right", marginTop: "5px" }}
                >
                  Skip Continue
                </button>
              </div>
            </div>

            {AllCars?.filter((item) =>
              branchId ? item?.branchId == branchId : !item?.branchId
            )?.map((item) => {
              return (
                <div className="container  ">
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-md-8">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-1">
                        
                        </div>
                        <div>
                          <img
                            src={`https://jaidrivingschool.in/Vehicle/${item?.vehicleImage}`}
                            alt=""
                            width={250}
                          />
                        </div>
                        <div>
                          <h5>{item?.Brand}</h5>
                          <ul>
                            <li>Vehicle No. - {item?.vehicleNumber}</li>
                            <li>Class Of Vehicle:{item?.vehicleType}</li>
                            <li>
                              Price:{"₹ "} {item?.price?.toFixed(2)}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                    {editData?.vechicleId==item?._id ? (<IoCheckmarkDoneCircleSharp style={{color:"green",fontSize:"50px"}}/>):( <button
                        className="btn btn-warning"
                        onClick={() => {
                          instructur(item);
                        }}
                        style={{ float: "right", marginTop: "30px" }}
                      >
                        Continue
                      </button>)}
                     
                    </div>
                  </div>
                </div>
              );
            })}
          </Modal.Body>
        </Modal>
        <Modal
          show={show6}
          onHide={handleClose6}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h4>Select Additional Service</h4>
                </div>
              </div>
            </div>

            <div className="container-fluid xcvbnm_012">
              <div style={{ margin: "0% 20% 0% 20%" }}>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Service </th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllAditional?.map((ele, i) => {
                      return (
                        <tr>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              style={{
                                width: "20px",
                                height: "20px",
                                background: "red",
                              }}
                              onChange={() => checkHand(ele)}
                              checked={additionalCharge?.filter((item)=>item?.text==ele?.text).length}
                            />
                          </td>
                          <td>{ele?.text}</td>
                          <td>₹{ele?.price}</td>
                        </tr>
                      );
                    })}
                    <td></td>
                    <td></td>
                  </tbody>
                </Table>
                <p
                  style={{ padding: "5px", fontWeight: "bold", float: "right" }}
                >
                 Total Price :-  {editData?.coursePrice ? ((Number(editData?.coursePrice)+add)?.toFixed(2)):(total?.toFixed(2))}
    
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-4">
              {additionalCharge?.length == 0 ? (
                <button
                  type="submit"
                  onClick={handleShow1}
                  class="btn btn-warning instructors_btn"
                  style={{
                    width: "7rem",
                    float: "right",
                    marginBottom: "20px",
                    marginRight: "50px",
                  }}
                >
                 {editShow? ("Update Now"):("Skip And Book")}
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={handleShow1}
                  class="btn btn-warning instructors_btn"
                  style={{
                    width: "7rem",
                    float: "right",
                    marginBottom: "20px",
                    marginRight: "50px",
                  }}
                >
                  {editShow? ("Update Now"):("Book Now")}
                </button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        {/* Payment */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Payments Modes</h4>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Online"
                    name="group1"
                    type={type}
                    onClick={() => setpayMethod("Online")}
                    // id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="Cash"
                    onClick={() => setpayMethod("Cash")}
                    name="group1"
                    type={type}
                    // id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h5 style={{ float: "right" }} className="mb-3">
                Total Pay Amount:- ₹ {editShow ? editPay?.toFixed(2): total?.toFixed(2)}
              </h5>
            </div>
            <div>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button
                variant="primary"
                style={{ marginLeft: "10px" }}
                onClick={posttransaction}
              >
                Pay Now
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/*Edit Booking*/}
        <Modal
          show={editShow}
          onHide={() => seteditShow(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-12">
                  <label htmlFor="">Customer/Student</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    sx={{ width: 420 }}
                    value={selectedValue} // This sets the selected value
                    onChange={handleAutocompleteChange} // Handle change event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${user?.customerId}-${user?.name}`}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-12">
                  <label htmlFor="">Course</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films2}
                    sx={{ width: 420 }}
                    value={selectedValue2} // This sets the selected value
                    onChange={handleAutocompleteChange2} // Handle change event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${editData?.courseTitle} ${editData?.courseDuration} days`}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class="col-md-6">
                  <label htmlFor="">Email ID</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    class="form-control"
                    placeholder="Email"
                    aria-label="Email"
                  />
                </div>
                <div class="col-md-6">
                  <label htmlFor="">Mobile</label>
                  <input
                    type="number"
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    class="form-control"
                    placeholder="Mobile"
                    aria-label="Mobile"
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class="col-md-6">
                  <label htmlFor="">Gender</label>
                  <select
                    class="form-select "
                    aria-label=".form-select-lg example"
                    onChange={(e) => setgender(e.target.value)}
                  >
                    <option>{gender ? gender : "Select gender"} </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div class="col-md-6">
                  <label htmlFor="">DOB</label>
                  <input
                    type="date"
                    value={dob}
                    onChange={(e) => setdob(e.target.value)}
                    class="form-control"
                    placeholder="Last name"
                    aria-label="Last name"
                  />
                </div>
              </div>
            </div>

            <div className="do-sear mt-2">
              <div class="row me-0">
                <div className="" style={{ display: "flex" }}>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label">
                      City
                    </label>
                    <select
                      id="inputState"
                      class="form-select formselect"
                      onChange={(e) => setcity(e.target.value)}
                    >
                      <option selected>
                        {user?.city ? city : "Choose..."}
                      </option>
                      {allCity?.map((item) => {
                        return <option value={item?.city}>{item?.city}</option>;
                      })}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label ms-2">
                      Branch
                    </label>
                    <select
                      class="form-select ms-2 formselect"
                      onChange={(e) => {
                        setbranch(
                          e.target.value ? JSON.parse(e.target.value) : {}
                        );
                        setbranchId(
                          e.target.value ? JSON.parse(e.target.value)?._id : ""
                        );
                      }}
                    >
                      <option value="">{editData?.branchName}</option>
                      <option
                        value={JSON.stringify({ branchName: "Main Branch" })}
                      >
                        Main Branch
                      </option>
                      {allBranch
                        ?.filter((ele) => ele?.city == city)
                        ?.map((item) => {
                          return (
                            <option value={JSON.stringify(item)}>
                              {item.branchName},{item?.area}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-6">
                  <label for="exampleFormControlTextarea1" class="form-label">
                    Address
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Please enter your address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                  ></textarea>
                </div>
                <div class="col-md-6">
                  <label for="inputZip" class="form-label">
                    Zip
                  </label>
                  <input
                    type="text"
                    placeholder="Please enter zip"
                    value={pincode}
                    onChange={(e) => setpincode(e.target.value)}
                    class="form-control"
                    id="inputZip"
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div style={{ display: "flex" }}>
                  <div class=" col-md-6">
                    <label for="formFile" class="form-label" htmlFor="upload1">
                      Passport Photo
                    </label>
                    <input
                      class="form-control formselect"
                      type="file"
                      id="upload1"
                      accept="image/*"
                      onChange={(e) => setphoto(e.target.files[0])}
                    />
                  </div>
                  <div class=" col-md-6">
                    <label for="formFile" class="form-label" htmlFor="upload2">
                      Addhar
                    </label>
                    <input
                      class="form-control formselect"
                      type="file"
                      id="upload2"
                      onChange={(e) => setidproof(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => seteditShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateUser();
                getAddition();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>

     
      <Modal
            show={showAt}
            onHide={()=>setshowAt(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Classes And Attanence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="scrolll_bar mb-4">
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        {/* <th>S.No</th> */}
                        {/* <th>Id</th> */}
                        <th>Day</th>
                        <th>Topic Name</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Intial Reading</th>
                        <th>End Reading</th>
                        {/* <th>Trainer Name</th> */}
                        <th>Remark</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseD?.courseTopic?.map((item, i) => {
                        return (
                          <tr>
                            {/* <td>{i + 1}</td> */}
                        
                            <td>{item?.days}</td>
                            <td>{item?.topicName}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectdate}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectTime}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.endtime}</td>

                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.pickupAddress}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.dropAddress}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.remarks}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)?.length==0 ?(<span style={{color:"blue"}}>Pending</span>):(<span style={{color:"green"}}>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.status}</span>)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setshowAt(false)}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={handleSubmit}>
                Submit{" "}
              </Button> */}
            </Modal.Footer>
          </Modal>
    </div>
  );
};

export default AdminStudents;
