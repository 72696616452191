import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import axios from "axios";

const Coursesss = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [allBranch, setAllBranc] = useState([]);
  const getAllBranch = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setAllBranc(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allCity, setAllCity] = useState([]);
  const getAllcity = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllCity"
      );
      if (res.status == 200) {
        setAllCity(res.data.success);
        getAllBranch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/course/getAllcourse"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCourse();
    getAllBranch();
    getAllcity();
  }, []);

  const user = JSON.parse(sessionStorage.getItem("user"));


  const [name,setname]=useState("")
  const [mobile,setmobile]=useState("")
  const [email,setemail]=useState("")
  const [gender,setgender]=useState("")
  const [dob,setdob]=useState("")
  const [state,setstate]=useState("")
  const [city,setcity]=useState("")
  const [branch,setbranch]=useState("")
  const [address,setaddress]=useState("")
  const [pincode,setpincode]=useState("");
  const [photo,setphoto]=useState("");
  const [idproof,setidproof]=useState("");
  const [medicleProof,setmedicleProof]=useState("");
  
  useEffect(() => {
    if(user){
      setname(user?.name);
      setmobile(user?.mobile);
      setemail(user?.email);
      setgender(user?.gender);
      setdob(user?.dob);
      setstate(user?.state);
      setcity(user?.city);
      setaddress(user?.Address);
      setpincode(user?.pincode);
      setphoto(user?.profile);
      setidproof(user?.addhar);
      setmedicleProof(user?.medicalproof);
    }
  }, []);
  const [courseData, setCourseData] = useState({});
  const updateUser=async()=>{
    try {
      let obj={userId:user?._id, name:name, mobile:mobile, email:email ,city:city,courseData:courseData,passportcopy:photo,addhar:idproof,medicalproof:medicleProof,dob:dob,Address:address,gender:gender,area:branch,state:state,pincode:pincode}
      if(!name) return alert("Please fill the blank");
      if(!email) return alert("Please fill the blank");
      if(!mobile) return alert("Please fill the blank");
      if(!gender | !dob| !city | !branch| !address | !pincode | !photo | !idproof ) return alert("Please complete the form")
      const config={
        url: "/customer/updateUser",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data"},
        data:obj
      }
      let res=await axios(config);
      if(res.status==200){
        await sessionStorage.setItem("user",JSON.stringify(res.data.success))
        navigate("/timslot",{state:{data1:courseData,branch:branch}})
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 6;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allCourse.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div>
      {/* <div className="container-fluid mt-4"> */}
      <div
        className="efowfg"
        style={{
          backgroundImage: "url('/images/mehrun.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "535px",
        }}
      >
        {/* <div className="content"> */}
        <div
          className="container text-white"
          style={{ marginLeft: "10px", marginTop: "10px" }}
        >
          <h2>COURSES</h2>
          <div className="row bg-31">
            {allCourse
              ?.slice(pagesVisited, pagesVisited + usersPerPage)
              ?.map((item) => {
                return (
                  <div className="col-4 ps-5">
                    <div className="box">
                      <div className="box-card ">
                        <div
                          class="card bg-26 "
                          style={{ width: "19rem", height: "11rem" }}
                        >
                          <div class="card-header">
                            {" "}
                            <img src="../images/shield.png" alt="" />
                            {item?.courseName}{" "}
                          </div>
                          <ul class="list-group list-group-flush">
                            <li
                              class="list-group-item"
                              style={{ color: "black" }}
                            >
                              {item?.courseDuration} Days ......................................&#x20B9;
                              {item?.price}{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="box-content">
                        <div
                          class="card"
                          id="c-card"
                          style={{ width: "19rem", height: "11rem" }}
                        >
                          <div class="card-body">
                            <h5 class="card-title"> {item?.courseName}</h5>

                            <p class="card-text">{item?.courseDuration} Days</p>
                            <a
                              onClick={() =>
                                navigate("/courseviewmore", { state: item })
                              }
                              class="card-link btn btn-warning  "
                              style={{ color: "black" }}
                            >
                              More Info
                            </a>
                            <button
                              className="btn btn-warning cbmd_09 "
                              onClick={() => {
                                setCourseData(item);
                                handleShow();
                              }}
                            >
                              Book Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="col-4 ps-5">
              <div className="box">
                <div className="box-card ">
                  <div class="card bg-26" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-header">
                      {" "}
                      <img src="../images/shield.png" alt="" />
                      HOW TO PROTECT
                      YOUR CAR AND YOURSELF{" "}
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" style={{ color: "black" }}>
                        Automatic......................................&#x20B9;350{" "}
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="box-content">
                  <div class="card" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">
                        {" "}
                        HOW TO PROTECT YOUR CAR AND YOURSELF
                      </h5>

                      <p class="card-text">30Days</p>
                      <a href="#" class="card-link btn btn-warning" style={{color:"black"}}>
                        More Info
                      </a>
                      <button
                        className="btn btn-warning cbmd_09 "
                        onClick={handleShow}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 ps-5">
              <div className="box">
                <div className="box-card ">
                  <div class="card bg-26" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-header">
                      {" "}
                      <img src="../images/shield.png" alt="" />
                      HOW TO PROTECT
                      YOUR CAR AND YOURSELF{" "}
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" style={{ color: "black" }}>
                        Automatic......................................&#x20B9;350{" "}
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="box-content">
                  <div class="card" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">
                        {" "}
                        HOW TO PROTECT YOUR CAR AND YOURSELF
                      </h5>

                      <p class="card-text">30Days</p>
                      <a href="#" class="card-link btn btn-warning" style={{color:"black"}}>
                        More Info
                      </a>
                      <button
                        className="btn btn-warning cbmd_09 "
                        onClick={handleShow}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="row bg-60">
            <div className="col-4 ps-5">
              <div className="box">
                <div className="box-card ">
                  <div class="card bg-27" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-header">
                      {" "}
                      <img src="../images/shield.png" alt="" />
                      HOW TO PROTECT
                      YOUR CAR AND YOURSELF{" "}
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" style={{ color: "black" }}>
                        Automatic......................................&#x20B9;350{" "}
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="box-content">
                  <div class="card" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">
                        {" "}
                        HOW TO PROTECT YOUR CAR AND YOURSELF
                      </h5>

                      <p class="card-text">30Days</p>
                      <a href="#" class="card-link btn btn-warning" style={{color:"black"}}>
                        More Info
                      </a>
                      <button
                        className="btn btn-warning cbmd_09 "
                        onClick={handleShow}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 ps-5">
              <div className="box">
                <div className="box-card ">
                  <div class="card bg-26" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-header">
                      {" "}
                      <img src="../images/shield.png" alt="" />
                      HOW TO PROTECT
                      YOUR CAR AND YOURSELF{" "}
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" style={{ color: "black" }}>
                        Automatic......................................&#x20B9;350{" "}
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="box-content">
                  <div class="card" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">
                        {" "}
                        HOW TO PROTECT YOUR CAR AND YOURSELF
                      </h5>

                      <p class="card-text">30Days</p>
                      <a href="#" class="card-link btn btn-warning" style={{color:"black"}}>
                        More Info
                      </a>
                      <button
                        className="btn btn-warning cbmd_09 "
                        onClick={handleShow}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 ps-5">
              <div className="box">
                <div className="box-card ">
                  <div class="card bg-26" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-header">
                      {" "}
                      <img src="../images/shield.png" alt="" />
                      HOW TO PROTECT
                      YOUR CAR AND YOURSELF{" "}
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" style={{ color: "black" }}>
                        Automatic......................................&#x20B9;350{" "}
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="box-content">
                  <div class="card" style={{ width: "19rem", height: "11rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">
                        {" "}
                        HOW TO PROTECT YOUR CAR AND YOURSELF
                      </h5>

                      <p class="card-text">30Days</p>
                      <a href="#" class="card-link btn btn-warning" style={{color:"black"}}>
                        More Info
                      </a>
                      <button
                        className="btn btn-warning cbmd_09 "
                        onClick={handleShow}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* </div> */}
      </div>

      {/* </div> */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill the Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-10">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Full name"
                  aria-label="Full name"
                  value={name}
                  onChange={(e)=>setname(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-5">
                <label htmlFor="">Email ID</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e)=>setemail(e.target.value)}
                  class="form-control"
                  placeholder="Email"
                  aria-label="Email"
                />
              </div>
              <div class="col-md-5">
                <label htmlFor="">Mobile</label>
                <input
                  type="number" value={mobile}
                  onChange={(e)=>setmobile(e.target.value)}
                  class="form-control"
                  placeholder="Mobile"
                  aria-label="Mobile"
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-5">
                <label htmlFor="">Gender</label>
                <select
                  class="form-select "
                  aria-label=".form-select-lg example"
                  onChange={(e)=>setgender(e.target.value)}
                >
                  <option >{gender ? (gender):("Select gender")} </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div class="col-md-5">
                <label htmlFor="">DOB</label>
                <input
                  type="date"
                  onChange={(e)=>setdob(e.target.value)}
                  class="form-control"
                  placeholder="Last name"
                  aria-label="Last name"
                />
              </div>
            </div>
          </div>

          <div className="do-sear mt-2">
            <div class="row me-0">
              <div className="" style={{ display: "flex" }}>
                <div class="col-md-5">
                  <label for="inputState" class="form-label">
                    City
                  </label>
                  <select id="inputState" class="form-select formselect" onChange={(e)=>setcity(e.target.value)}>
                    <option selected>{user?.city ? (city): ("Choose...")}</option>
                    {allCity?.map((item)=>{
                      return (
                        <option>{item?.city}</option>
                      )
                    })}
                  
                  </select>
                </div>
                <div class="col-md-5">
                  <label for="inputState" class="form-label ms-2">
                  Branch
                  </label>
                  <select  class="form-select ms-2 formselect" onChange={(e)=> setbranch(JSON.parse(e.target.value))}>
                    <option >Choose...</option>
                    <option value={JSON.stringify({branchName:"Main Branch"})}>Main Branch</option>
                    {allBranch?.filter((ele)=>ele?.city==city)?.map((item)=>{
                      return (
                         <option value={JSON.stringify(item)}>{item.branchName},{item?.area}</option>
                      )
                    })}
                   
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-5">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Address
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  placeholder="Please enter your address"
                  value={address}
                  onChange={(e)=>setaddress(e.target.value)}
                ></textarea>
              </div>
              <div class="col-md-5">
                <label for="inputZip" class="form-label">
                  Zip
                </label>
                <input type="text" placeholder="Please enter zip" value={pincode} onChange={(e)=>setpincode(e.target.value)} class="form-control" id="inputZip" />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div style={{ display: "flex" }}>
                <div class=" col-md-5">
                  <label for="formFile" class="form-label" htmlFor="upload1">
                   Passport Photo
                  </label>
                  <input
                    class="form-control formselect"
                    type="file"
                    id="upload1"
                    accept="image/*"
                    onChange={(e)=>setphoto(e.target.files[0])}
                  />
                </div>
                <div class=" col-md-5">
                  <label for="formFile" class="form-label" htmlFor="upload2">
                     Addhar
                  </label>
                  <input
                    class="form-control formselect"
                    type="file"
                    id="upload2"
                    onChange={(e)=>setidproof(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row me-0">
            <div class="col-md-5">
              <label for="formFile" class="form-label" htmlFor="upload3">
                Medical Certificate
              </label>
              <input class="form-control" type="file" id="upload3"  onChange={(e)=>setmedicleProof(e.target.files[0])}/>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() =>updateUser()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Coursesss;
