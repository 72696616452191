import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { display } from "@mui/system";

const Acc = (props) => {
  const { ques, answ } = props;
  const [state, setState] = useState(true);

  let handleClick = () => {
    setState(!state);
  };
  return (
    <div className="faq">
      <p
        onClick={handleClick}
        style={{
          cursor: "pointer",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        //   backgroundColor: "yellow",
          margin: " 20px auto",
          padding: "10px",
          border: "1px solid #9d041b",
          borderRadius:"10px"
        }}
      >
        {ques}

        <span>
          {state ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </span>
      </p>

      {state ? (
        " "
      ) : (
        <p
          style={{
            cursor: "pointer",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            margin: " 20px auto",
            // marginLeft:"20px"
            // padding: "10px",

          }}
        >
          {answ}
        </p>
      )}
    </div>
  );
};

export default Acc;
