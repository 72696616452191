

export let Menu=[
    {
        images:"./images/8906.jpg",
        category:"Instructor",
        name:"Ram Sharma",
        designation:"Junior Instructor",
        location:"Nagwara",
        imgs:"./images/4stars.png"
    },
    {
        images:"./images/8906.jpg",
        category:"Instructor",
        name:"Ram Sharma",
        designation:"Junior Instructor",
        location:"Nagwara",
        imgs:"./images/4stars.png"
    },
    {
        images:"./images/8906.jpg",
        category:"staff",
        name:"Ram Sharma",
        designation:"Manager",
        location:"Nagwara",
        imgs:"./images/4stars.png"
    },
    {
        images:"./images/8906.jpg",
        category:"Instructor",
        name:"Ram Sharma",
        designation:"Junior Instructor",
        location:"Nagwara",
        imgs:"./images/4stars.png"
    },
    {
        images:"./images/8906.jpg",
        category:"Instructor",
        name:"Ram Sharma",
        designation:"Senior Instructor",
        location:"Nagwara",
        imgs:"./images/4stars.png"
    },
    {
        images:"./images/8906.jpg",
        category:"staff",
        name:"Ram Sharma",
        designation:"Junior Officer",
        location:"Nagwara",
        imgs:"./images/4stars.png"
    },
    {
        images:"./images/8906.jpg",
        category:"staff",
        name:"Ram Sharma",
        designation:"Senior Officer",
        location:"Nagwara",
        imgs:"./images/4stars.png"
    },





]