import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import { BsFillChatDotsFill, BsWhatsapp } from "react-icons/bs";
// import { GrContact } from "react-icons/gr";

function AdminHeader() {
  const logOut = () => {
    sessionStorage.removeItem("admin");
    sessionStorage.removeItem("token");
    window.location.assign("/admin");
  };
  let admin = JSON.parse(sessionStorage.getItem("admin"));
  if (!admin) {
    alert("Please login");
    window.location.assign("/admin");
  } else
    return (
      <>
        <div
          className="header"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          <Navbar expand="lg" style={{ marginLeft: "4rem" }}>
            <Container fluid>
              <Navbar.Brand href="#">
                {admin?.profile ? (
                  <img
                    src={`https://jaidrivingschool.in/admin/${admin?.profile}`}
                    alt="logo"
                    style={{
                      width: "75px",
                      height: "75px",
                      borderRadius: "50%",
                      border: "2px solid black",
                    }}
                  />
                ) : (
                  <BiUserCircle style={{ fontSize: "25px" }} />
                )}

                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  {admin?.name}{" "}
                  {/* <div
                  className="d-block"
                  style={{ fontSize: "14px", fontWeight: "600", color:"white" }}
                >
                  ID:38572147
                </div> */}
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="ms-auto my-2 my-lg-0"
                  // style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  {/* <Nav.Link href="#">
                  <BsFillChatDotsFill style={{ fontSize: "30px", paddingRight: "5px" }} />
                  Contact admin
                </Nav.Link>
                <Nav.Link href="#">
                  <BsWhatsapp style={{ fontSize: "25px", color: "green", paddingRight: "5px" }} />
                  Chat with admin
                </Nav.Link> */}
                  <Nav.Link href="#" onClick={logOut}>
                    <BiLogOutCircle
                      style={{ fontSize: "25px", color: "red" }}
                    />
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </>
    );
}

export default AdminHeader;
