import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { BiUserCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
const VendorBookService = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  const [AllBooking, setAllBooking] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllBooking = async () => {
    try {
      const config = {
        url: "/admin/getAllServiceBooking",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllTrainer, setAlltrainer] = useState([]);
  const [nochangedataT, setnochangedataT] = useState([]);
  const getTrainer = async () => {
    try {
      const config = {
        url: "/staff/getAllSudents",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
        setnochangedataT(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getAllBooking();
    }
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllBooking.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [pageNumber1, setPageNumber1] = useState(0);
  const usersPerPage1 = 10;
  const pagesVisited1 = pageNumber1 * usersPerPage1;
  const pageCount1 = Math.ceil(AllTrainer.length / usersPerPage1);
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllBooking([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllBooking([...nochangedata]);
    }
  };

  const TrainerHandle = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedataT.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAlltrainer([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAlltrainer([...nochangedataT]);
    }
  };

  const [slectData, setSelectData] = useState({});
  console.log("slect DAta", slectData);
  const BookAssignTrainer = async (data) => {
    try {
      const config = {
        url: "/admin/BookAssignTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          bookId: slectData?._id,
          trainerId: data?._id,
          trinerStatus: "Assigned",
          trinerName: data?.name,
          trainerMobile: data?.mobile,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        handleClose();
        getAllBooking();
        getTrainer();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const cancelAssigneTrainer = async (data) => {
    try {
      const config = {
        url: "/admin/BookAssignTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          bookId: slectData?._id,
          trainerId: slectData?._id,
          trinerStatus: "Pending",
          trinerName: "",
          trainerMobile: "",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully cancel");
        handleClose();
        getAllBooking();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="ad-b mt-4">
                  <button> Other Branch Booked Services Details</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>bookId</th>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Email</th>
                    <th>Location</th>
                    <th>Service</th>
                    <th>Service Price</th>
                    <th>Total Price</th>
                    <th>Pay Amount </th>
                    <th>Pay Id</th>
                    <th>Pay method</th>
                    <th>Payment Status</th>
                    <th> Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>JDSB004</td>
                    <td>Vinay</td>

                    <td>8618745879 </td>
                    <td>vinay@gmail.com</td>
                    <td>Vinayaka Layout</td>
                    <td>License Assistance</td>
                    <td>4999</td>
                    <td>4999</td>
                    <td>0</td>
                    <td>---</td>
                    <td>---</td>
                    <td>----</td>
                    <td>InProccess/Proccess</td>

                    <td>
                      <span style={{ display: "flex", gap: "4px" }}>
                        <button type="button" class="btn btn-success">
                          Complete
                        </button>{" "}
                        <button type="button" class="btn btn-danger">
                          Reject
                        </button>
                      </span>

                      <button type="button" class="btn btn-danger">
                        Block
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assgin Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Search Trainer...."
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                  onChange={(e) => TrainerHandle(e)}
                />
              </div>
            </div>
            {AllTrainer?.slice(
              pagesVisited1,
              pagesVisited1 + usersPerPage1
            )?.map((item) => {
              return (
                <div className="">
                  <img
                    src={`https://jaidrivingschool.in/Staff/${item?.profile}`}
                    style={{
                      borderRadius: "100%",
                      height: "40px",
                      width: "40px",
                    }}
                  />{" "}
                  <span>{item?.name}</span> <span>{item?.mobile}</span>{" "}
                  <span style={{ float: "right" }}>
                    {item?.mobile == slectData?.trainerMobile ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={cancelAssigneTrainer}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={() => {
                          BookAssignTrainer(item);
                        }}
                      >
                        Assign Now
                      </button>
                    )}{" "}
                  </span>
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount1}
              onPageChange={changePage1}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show Assgned Trainer Model */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assgined Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Search Trainer...."
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                  onChange={(e) => TrainerHandle(e)}
                />
              </div>
            </div>
            {AllTrainer?.filter((ele) => ele?._id == slectData?.trainerId)?.map(
              (item) => {
                return (
                  <div className="">
                    <img
                      src={`https://jaidrivingschool.in/Staff/${item?.profile}`}
                      style={{
                        borderRadius: "100%",
                        height: "40px",
                        width: "40px",
                      }}
                    />{" "}
                    <span>{item?.name}</span> <span>{item?.mobile}</span>{" "}
                    <span style={{ float: "right" }}>
                      {slectData?.trinerStatus == "Assigned" ? (
                        <span style={{ color: "red" }}>Assigned</span>
                      ) : (
                        <BsFillCheckCircleFill
                          style={{ color: "green", fontSize: "25px" }}
                        />
                      )}{" "}
                    </span>
                  </div>
                );
              }
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default VendorBookService;
