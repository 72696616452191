import React, { useEffect, useState } from 'react'
import Side from "./sidebar/Side";
import axios from 'axios';
import { BsWallet2 } from 'react-icons/bs';
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import useRazorpay from "react-razorpay";
import moment from 'moment';
const TrainerWallet = () => {
const user=JSON.parse(sessionStorage.getItem("staff"));
const token=sessionStorage.getItem("token")
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

const [payId,setpayid]=useState("");
const [amount,setamount]=useState("");

const [wallet,setwallet]=useState({})
const CreateWallet=async()=>{
  try {
    const config = {
      url: "/wallet/createWalletTrainer",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        authId: user?._id,
        trainerId: user?._id,
      },
    };
    let res=await axios(config)
    if(res.status==200){
      setwallet(res.data.success)
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}

const posttransaction = async () => {
  try {
    if(!amount) return alert("Please enter amount");
 
    const config = {
      data: {
        key: "rzp_test_FAe0X6xLYXaXHe",
        amount: amount* 100,
        currency: "INR",
        name: "Jai Driving School",
        description: "Order Amount",
        image: "../images/logo.png.jpg",
        customerId: user?._id,
        handler: function (response) {
          alert(response.razorpay_payment_id);
          setpayid(response.razorpay_payment_id);
        },
        prefill: {
          name: user?.name,
          email:user?.email,
          contact: user?.mobile,
        },
      },
    };
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Fail to load");
      return;
    }
    const paymentObject = new Razorpay(config.data);
    paymentObject.open();
  } catch (error) {
    console.log(error);
  }
};


useEffect(()=>{
  if(user){
    CreateWallet()
  }
},[]);
const addAcountHistory=async()=>{
  try {
    const config={
      url: "/admin/addAccount",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
      headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"},
      data:{
        authId:user?._id,
        customeId:user?.staffId,paymentId:payId,amount:amount,status:"Cr"
      }
    }
    await axios(config);
  } catch (error) {
    console.log(error);
  }
}

useEffect(()=>{
if(payId){
  AddTransaction()
  addAcountHistory()
}
},[payId])

  const Razorpay = useRazorpay();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };



const AddTransaction=async()=>{
  try {
      const config = {
      url: "/wallet/addTransaction",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        authId: user?._id,
        id:wallet?._id,heading:"Added To Wallet",payId:payId,amount:amount,status:"CR"
      },
    };
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully added");
      setpayid("");
      setamount("")
      handleClose();
      CreateWallet();
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}
  return (
    <div>
        <Side />
        <button type="button" style={{color:"white",float:"right",marginTop:"3px"}} class="btn btn-info" onClick={()=>handleShow()}>Add to wallet</button>
        <div className="container mt-5 mb-5">
       
          <div className="row mt-5 mb-5 " style={{backgroundImage:"url(./images/wallet1.png)"}}>
            
            <div className="col-md-6" style={{border:"1px solid white",boxShadow: "0 8px 32px 0 rgba(19, 19, 20, 0.37)",backgroundColor:"white"}}>
              <div className="row">
                <div className="col-md-12 text-center">
                  <img src="./images/wallet.png" alt="" height={30} width={30} /><label htmlFor="">YOUR WALLET </label>
                  <h2  id='wallet'>&#8377;{wallet?.totalAmount?.toFixed(2)}</h2>

                </div>
              </div>
              

            </div>
            <div className='col-md-6' style={{color:"white",textAlign:"center",}}>
            <label htmlFor="" style={{color:"white"}}>YOUR TRANSATION </label>
           <div  style={wallet?.transaction?.length>=6 ? {overflow:"hidden",overflowY:"scroll",height: "400px"}:{}}>
           {wallet?.transaction?.map((item)=>{
              return (
                 <div style={{display:"flex",justifyContent:"space-between",}} >
              <div>
          <BsWallet2 style={{color:"white",fontSize:"25px"}}/> <span >{item?.heading}</span>
          <p >PayId:- {item?.payId}</p>
           </div> 
           <div> <p>{moment(item?.date).format("LLL")} </p></div>
           {item?.status=="CR" ? ( <div style={{margin:"0 0 5px 0",color:"#5dff7d"}}>
            <h5  >+ ₹{item?.amount?.toFixed(2)} {item?.status}</h5>
           </div>):( <div style={{margin:"0 0 5px 0",color:"red"}}>
            <h5  >- ₹{item?.amount?.toFixed(2)} {item?.status}</h5>
           </div>)}
          
           </div>
              )
            })}
           </div>
           
            </div>
          </div>
        </div>
         <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add To Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor=''>Amount</label><br/>
            <input type='number'   className="vi_0" placeholder='Enter amount' min="1" value={amount} onChange={(e)=>setamount(e.target.value)}></input>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={()=>posttransaction()} >
            Add now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default TrainerWallet