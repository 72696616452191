import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import { Menu } from "./Out team/Instructors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const Aboutus = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const [aboutusviewmore, setAboutusviewmore] = useState({})
  const [show7, setShow7] = useState(false);
  const handleShow7 = () => setShow7(true);
  const handleClose7 = () => setShow7(false)
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [tesla, setTesla] = useState(false);
  const [innova, setInnova] = useState(false);
  const [nano, setNano] = useState(false);
  const [items, setItems] = useState(Menu);
  const [allAbout, setAllAbout] = useState([]);
  const getAboutUs = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAbout"
      );
      if (res.status == 200) {
        setAllAbout(res.data.success);
        getTrainer()
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  useEffect(() => {
    getAboutUs();
  }, []);

  const filterItem = (category) => {
    const updateItems = Menu.filter((curElem) => {
      return curElem.category === category;
    });
    setItems(updateItems);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  const handleClick = () => {
    setTesla(!tesla);
  };
  const handleClick1 = () => {
    setInnova(!innova);
  };
  const handleClick2 = () => {
    setNano(!nano);
  };
  const [AllTrainer, setAlltrainer] = useState([]);

  const getTrainer = async () => {
    try {
      const config = {
        url: "/staff/getAllSudents",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        // headers: { Authorization: `Bearer ${token}`,"content-type": "multipart/form-data" },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
        // setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllVehical, setAllVehicle] = useState([]);
  const getAllVehicle = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getAllVehicle")
      if (res.status == 200) {
        setAllVehicle(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [allBranch, setallbranch] = useState([]);

  const getAllBranches = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setallbranch(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [allStaff, setallstaff] = useState([]);
  const getAllSatff = async () => {
    try {
      const config = {
        url: "/admin/staff/getAllStaffs",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
      };
      let res = await axios(config);
      if (res.status == 200) {
        setallstaff(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllSatff()
    getTrainer();
    getAllVehicle();
    getAllBranches();
  }, []);
  useEffect(() => {
    getAllSatff();
    getTrainer();
    getAllVehicle();
    getAllBranches();
  }, []);
  const [instruct, setinstruct] = useState(true);
  const [staff, setstaff] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const [name, setname] = useState("")
  const [mobile, setmobile] = useState("")
  const [email, setemail] = useState("")

  const [branch, setbranch] = useState({})
  const [address, setaddress] = useState("")

  useEffect(() => {
    if (user) {
      setname(user?.name);
      setmobile(user?.mobile);
      setemail(user?.email);
    }
  }, []);
  const Addenquire = async () => {
    try {
      if (!name) return alert("Please enter your name");
      if (!mobile) return alert("Please enter mobile number");
      if (!email) return alert("Please enter your email");
      if (!address) return alert("Please enter your comment");
      const config = {
        url: "/admin/postEnquire",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: { name: name, email: email, EnquireType: `About branch ${branch?.branchName} ${branch?.area}`, comments: address, mobile: mobile, branchId: branch?._id }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Team will be connect as soon");
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "130px",
            background: "black",
            overflow: " hidden",
            background: " #C33764",
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>About Us</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "white" }}>&raquo;</span>
            </li>
            <li style={{ color: "white", fontSize: "18px" }}> About Us</li>
          </ul>
        </div>
      </div>
      {/* <div className="container">
        <div className="row">
          <div className="col-2 mt-2">
            <h5 className="text-center">
              <span style={{ fontSize: "23px" }}>About Us</span>
            </h5>
          </div>
        </div>
      </div> */}
      {allAbout?.map((ele) => {
        return (
          <div className="container-fluid">
            <div className="row pt-5 ">
              <div className="col-md-7 ">
                <p
                  style={{
                    textAlign: "justify",
                    lineHeight: "1.8rem",
                    fontSize: "18px",
                  }}
                >
                  {ele?.Aboutdis.slice(0, 600)}... <br /> <br />
                  <button className="btn btn-warning" style={{ float: "right", cursor: "pointer" }} onClick={() => {
                    setAboutusviewmore(ele);
                    handleShow7()
                  }}>View More</button>
                </p>
              </div>
              <div className="col-md-5  bg-12">
                <img
                  src={`https://jaidrivingschool.in/About/${ele?.image}`}
                  alt="ffgjnhf"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
            </div>
          </div>
        );
      })}

      {/* <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5 className="bg-33">
              <span
                style={{ fontSize: "23px", cursor: "pointer" }}
                onClick={() => setItems(Menu)}
              >
                Our Team
              </span>
            </h5>
          </div>

          <div className="col-md-2 bg-13">
            <a
              className="btn btn-warning"
              onClick={() => filterItem("Instructor")}
              style={{ fontSize: "18px" }}
            >
              Instructors
            </a>
          </div>
          <div className="col-md-2 bg-14">
            <a
              className="btn btn-warning"
              onClick={() => filterItem("staff")}
              style={{ fontSize: "18px" }}
            >
              Staff
            </a>
          </div>
        </div>
        <div className="qwertyu">
          <ReactElasticCarousel breakPoints={breakPoints} enableAutoPlay>
            {items.map((data) => {
              const { images, name, designation, location, imgs } = data;
              return (
                <div className="card" style={{ width: "18rem" }}>
                  <img src={images} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <p className="card-text">
                      Name : {name} <br />
                      Designation: {designation} <br />
                      Location:{location} <br />
                      <img
                        src={imgs}
                        className="card-img-top"
                        alt=""
                        style={{ width: "50%", marginTop: "0px" }}
                      />
                    </p>
                  </div>
                </div>
              );
            })}
          </ReactElasticCarousel>
        </div>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <h4>Our Teams</h4>
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-warning"
              onClick={() => {
                setinstruct(true)
                setstaff(false);
              }}
            >
              Instructors
            </button>
          </div>
          <div className="col-md-2 staff_T">
            <button
              className="btn btn-warning"
              onClick={() => {
                setinstruct(false)
                setstaff(true);
              }}
            >
              Staff
            </button>
          </div>
        </div>
      </div>
      <div className="container pt-4 bnjkhgf_0">
        {instruct ? (
          <Carousel responsive={responsive}>
            {AllTrainer.map((data) => {
              return (
                <div class="item">
                  <div className="card fghit_0 " >

                    <img
                      src={`https://jaidrivingschool.in/Staff/${data.profile}`}
                      className="card-img-top"
                      alt="..."
                      style={{ height: "200px" }}
                    />
                    <div className="card-body">
                      <p className="card-text">
                        Name : {data.name} <br />
                        Designation: Instructor
                        <br />
                        Location: {data.city} <br />
                      </p>
                    </div>

                  </div>

                </div>
              );
            })}


          </Carousel>) : (<></>)}
        {staff ? (<Carousel responsive={responsive}>
          {allStaff.map((data) => {
            return (
              <div class="item">
                <div className="card fghit_0 " >

                  <img
                    src={`https://jaidrivingschool.in/staff/${data.profile}`}
                    className="card-img-top"
                    alt="..."
                    style={{ height: "200px" }}
                  />
                  <div className="card-body">
                    <p className="card-text">
                      Name : {data.name} <br />
                      Designation: Staff
                      <br />
                      Location: {data.city} <br />
                    </p>
                  </div>

                </div>

              </div>
            );
          })}


        </Carousel>) : (<></>)}

      </div>

      <div className="container bnmjhi_0">
        <h5 className="bg-16">
          <span style={{ fontSize: "23px" }}>Our Branches</span>
        </h5>
        <div className="row qazxs_09">
          <Carousel responsive={responsive}>
            {allBranch?.map((item) => {
              return (
                <div className="bg-15">
                  <div class="card fghit_0" style={{ width: "100%" }}>
                    <img src={`https://jaidrivingschool.in/Branch/${item?.image}`} class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">
                        <LocationOnIcon />  <a href={item?.locationLink} target="_blank" style={{ color: "black" }}> {item?.area}</a>
                      </h5>
                      <p class="card-text">
                        {item?.Adress}, {item?.city} - {item?.pincode}{" "}
                        <br /> <PhoneIcon />
                        +91-{item?.branchNumber} <br />
                        <EmailOutlinedIcon />
                        {item?.branchEmail}
                      </p>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <button className="btn btn-warning" >
                          <a href={`/SingleBranch/${item?._id}`} style={{ color: "white" }}>
                            View Now</a>
                        </button>
                        <button className="btn btn-warning" >
                          <a style={{ color: "white" }} onClick={() => {
                            setbranch(item);
                            handleShow1();
                          }}>
                            Enquire Now</a>
                        </button></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>

        </div>
      </div>
      <div className="container bnmjhi_0">
        <h5 className="bg-33">
          <span style={{ fontSize: "23px" }}>Car Lists</span>
        </h5>
        <div className="row qazxs_09">
          <Carousel responsive={responsive}>
            {AllVehical?.map((item) => {
              return (
                <div className=" bg-15">
                  <div class="card fghit_0">
                    <img
                      src={`https://jaidrivingschool.in/Vehicle/${item?.vehicleImage}`}

                      height={200}
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h5 class="card-title">{item?.Brand}</h5>
                      <p class="card-text">
                        Vehicle No: {item?.vehicleNumber} <br /> Class of Vehicle : {item?.vehicleType} <br />
                        <span style={{ float: "right" }}>Price:- ₹ {item?.price?.toFixed(2)}</span>
                      </p>

                      {/* <Button
                  variant="primary"
                  onClick={handleShow}
                  style={{ color: "black" }}
                >
                  View More
                </Button> */}
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>


          {/* <div className="col-md-4 bg-15">
            <div class="card" style={{ width: "100%" }}>
              <img
                src="./images/maruti800.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Maruti 800</h5>
                <p class="card-text">
                  Vehicle No: MP04 <br /> Class of Vehicle : LMV <br /> Brand:
                  Maruti <br /> Year: 2010
                </p>

                <Button
                  variant="primary"
                  onClick={handleShow}
                  style={{ color: "black" }}
                >
                  View More
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-4 bg-15">
            <div class="card" style={{ width: "100%" }}>
              <img
                src="./images/maruti800.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Maruti 800</h5>
                <p class="card-text">
                  Vehicle No: MP04 <br /> Class of Vehicle : LMV <br /> Brand:
                  Maruti <br /> Year: 2010
                </p>

                <Button
                  variant="primary"
                  onClick={handleShow}
                  style={{ color: "black" }}
                >
                  View More
                </Button>
              </div>
            </div>
          </div> */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>About Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* car license &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &#10003;
              <br />
              Car Number&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &#10003;
              <br />
              Verified RTO registration&nbsp; &nbsp; &nbsp; &nbsp; &#10003;
              <br />
              Safety Measures &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &#10003; */}
              <img
                src={`https://jaidrivingschool.in/About/${aboutusviewmore?.image}`}
                alt="ffgjnhf"
                style={{ width: "100%", height: "200px" }}
              />
              <br /> <br />
              <p className="text-justify">{aboutusviewmore?.Aboutdis}</p>

            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={handleClose7}
                style={{ color: "black" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Enquiry Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="do-sear mt-2">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        placeholder="Enter your name"
                        className="vi_0"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="do-sear mt-2">
                      <label htmlFor="">E-mail ID</label>
                      <input
                        type="email"
                        placeholder="Enter your e-mail"
                        className="vi_0"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="do-sear mt-2">
                      <label htmlFor="">Mobile no</label>
                      <input
                        type="number"
                        placeholder="Enter your number"
                        className="vi_0"
                        value={mobile}
                        onChange={(e) => setmobile(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="do-sear mt-2">
                      <textarea
                        name=""
                        id=""
                        cols="40"
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                        placeholder="Message....."
                        className="vi_0"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button variant="primary" onClick={Addenquire}>Submit</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
