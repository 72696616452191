import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Side1 from "./sidebar1/Side1";
import axios from "axios";

const UpdateProfile = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [profile, setProfile] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [pincode, setPincode] = useState("");
  const [Address, setAddress] = useState("");
  const editUser = async (e) => {
    e.preventDefault()
    try {
      const config = {
        url: "/customer/updateUser",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",

        headers: { "content-type": "multipart/form-data" },
        data: {
          name: name,
          email: email,
          mobile: mobile,
          gender: gender,
          dob: dob,
          city: city,
          pincode: pincode,
          Address: Address,
          profile: profile,
          userId: user?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        sessionStorage.setItem("user", JSON.stringify(res.data.success));
        setName("");
        setEmail("");
        setMobile("");
        setGender("");
        setDob("");
        setPincode("");
        setAddress("");
        setProfile("");
        setCity("");
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error)
    }
  };
  useEffect(() => {
    if (user) {
      setName(user?.name);
      // setEmail(user?.email);
      // setMobile(user?.mobile);
      setGender(user?.gender);
      setDob(user?.dob);
      setPincode(user?.pincode);
      setAddress(user?.Address);
      setCity(user?.city);
    }


  }, [])

  return (
    <div>
      <Side1 />
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-md mt-5">
            <div className="bg_profile">
              {/* <img src="/images/bg.png" alt="" style={{width:"100%"}} height={150} /> */}
              <div className="profile"></div>
              <div className="main_employeee">
                <form>
                  <div class="row ">
                    <div className="col-md-6">
                      <label className="content1"> Full Name</label>
                      <br />
                      <input
                        // className="form-control"
                        type="text"
                        name="firstname"
                        id="firstname"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6  me">
                      <label>E-mail ID</label>
                      <br />
                      <input
                        // className="form-control"
                        type="email"
                        name="email"
                        id=""
                        placeholder={user?.email}
                        value={email}

                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6  me">
                      <label>Mobile no</label>
                      <br />
                      <input
                        // className="form-control"
                        type="number"
                        name="mobile"
                        id=""
                        placeholder={user?.mobile}
                        value={mobile}
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 me">
                      <label>Gender</label>
                      <br />
                      <input
                        // className="form-control"
                        type="text"
                        name="gender"
                        id=""
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 me">
                      <label>DOB</label>
                      <br />
                      <input
                        // className="form-control"
                        type="date"
                        name="city"
                        id="DOB"
                        value={dob}
                        onChange={(e) => {
                          setDob(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 me">
                      <label>City</label>
                      <br />
                      <input
                        // className="form-control"
                        type="text"
                        name="city"
                        id=""
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 me">
                      <label>Pincode</label>
                      <br />
                      <input
                        // className="form-control"
                        type="text"
                        name="city"
                        id="pincode"
                        value={pincode}
                        onChange={(e) => {
                          setPincode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 me">
                      <label>Address</label>
                      <br />
                      <input
                        // className="form-control"
                        type="text"
                        name="city"
                        id="address"
                        value={Address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-6 upload">
                      <label>Upload Photo</label>
                      <br />
                      <input
                        type="file"
                        onChange={(e) => {
                          setProfile(e.target.files[0]);
                        }}
                      />
                    </div>

                  </div>


                  <div className="bg-51">
                    <button
                      className="btn btn-warning mt-4 mb-3"
                      type="submit"
                      style={{ marginLeft: "40px", width: "7rem" }}
                      onClick={() => navigate("/sprofile")}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-warning mt-4 mb-3"
                      type="submit"
                      style={{ marginLeft: "40px", width: "7rem" }}
                      onClick={(e) => editUser(e)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;