import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel";


const Reeviews = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const [allReviews, setallReviews] = useState([]);
    const getAllReviews = async () => {
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getReview"
            );
            if (res.status == 200) {
                setallReviews(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getAllReviews();
    }, [])

    return (
        <>
            <div className="text-center mb-3">
                <h1 className="fw-bold">REVIEWS</h1>
                <p style={{ fontSize: '12px' }}>What Our Customer Says</p>
            </div>
            <div className="customer-bg-img mb-5" style={{ backgroundImage: "url('../images/customer-say-bg.jpg')", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '400px' }}>
                <div className="container ">
                    <Carousel responsive={responsive}>
                        {allReviews?.map((item) => {
                            return (
                                <div className="text-center mt-5 reviews_cust" style={{ border: '1px solid #fccc0b', borderRadius: '15px', padding: '25px' }}>
                                    <img
                                        src={`https://jaidrivingschool.in/review/${item?.logo}`}
                                        // src="../images/smile-icon.png"
                                        alt=""
                                        height={70}
                                        width={70}
                                        style={{ borderRadius: "100%" }}
                                    />

                                    <p
                                        className="text-center text-center mt-2"
                                        style={{ color: "rgb(241 243 244)" }}
                                    >
                                        {item?.text}
                                    </p>
                                    <h5 className="text-center text-light">{item?.writer}</h5>
                                    {/* <div className="text-center">
                    <StarRatings
                      rating={item?.ratting}
                      starDimension="25px"
                      starSpacing="5px"
                      starRatedColor="#fcca0b"
                    />
                  </div> */}
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
            </div>
        </>
    )
}

export default Reeviews