import React, { useEffect, useState } from "react";
import { Qa } from "./Qa";
import Acc from "./Acc";
import axios from "axios";

const Faq = () => {
  const [AllFAq,setAllFAq]=useState([]);
  const getFAQ=async()=>{
    try {
      const res=await axios.get("https://jaidrivingschool.in/api/admin/getFAQ");
      if(res.status==200){
        setAllFAq(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getFAQ()
  },[])
  return (
    <div>
      <div className="container mt-5 faq">
        <h3 style={{ marginLeft: "200px" }}>FAQS</h3>
        {AllFAq?.map((data) => {
          const { quation, answer } = data;
          return (
            <>
              <Acc ques={quation} answ={answer} />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
