import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Side from "./sidebar/Side";
import axios from "axios";

const Me = () => {
  const staff = JSON.parse(sessionStorage.getItem("staff"))

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [profile, setProfile] = useState("");
  const [city, setCity] = useState("");
  const [Address, setAddress] = useState("");
  // const [city, setCity] = useState("");
  const editStaff = async (e) => {
    e.preventDefault()
    try {
      const config = {
        url: "/staff/updateStaff",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",

        headers:
          { "content-type": "multipart/form-data" },
        data: {
          name: name,
          email: email,
          mobile: mobile,
          profile: profile,
          city: city,
          Address: Address,
          userId: staff?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        sessionStorage.setItem("staff", JSON.stringify(res.data.success))
        setName("");
        setEmail("");
        setMobile("");
        setCity("");
        setAddress("");
        setProfile("");
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error)
    }
  };
  useEffect(() => {
    if (staff) {
      setName(staff?.name)
      // setEmail(staff?.email)
      // setMobile(staff?.mobile)
      setCity(staff?.city)
      setAddress(staff?.Address)

    }
  }, [])

  const token = sessionStorage.getItem("token")
  const navigate = useNavigate()

  if (!staff) {
    alert("Please login");
    window.location.assign("/")

  } else
    return (
      <div>
        <Side />
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-md mt-5">
              <div className="bg_profile">
                <div className="profile">
                </div>
                <div className="main_employeee">
                  <form >
                    <div class="row ">
                      <div className="col-md-6">
                        <label className="content1"> Full Name:</label>
                        <br />
                        <input className="fullname1"
                          type="text"
                          name="fullname"
                          id="firstname"
                          value={name}
                          onChange={(e) => { setName(e.target.value) }}
                        />
                      </div>
                      <div className="col-md-6  me">
                        <label>E-mail ID</label><br />
                        <input
                          type="email"
                          name="email"
                          id=""
                          placeholder={staff.email}
                          value={email}
                          onChange={(e) => { setEmail(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div className="row">


                      <div className="col-md-6  me">
                        <label>Mobile no</label><br />
                        <input
                          type="number"
                          name="mobile"
                          id=""
                          placeholder={staff?.mobile}
                          // value={staff?.mobile}
                          onChange={(e) => { setMobile(e.target.value) }}
                        />
                      </div>
                      <div className="col-md-6  me">
                        <label>City</label><br />
                        <input
                          type="text"
                          name="city"
                          id=""
                          value={city}
                          onChange={(e) => { setCity(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 me">
                        <label htmlFor="">Address</label>
                        <textarea name="" id="" cols="30" rows="3" value={Address} onChange={(e) => { setAddress(e.target.value) }} ></textarea>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 upload" >
                        <label>Upload Photo</label><br />
                        <input type="file" onChange={(e) => { setProfile(e.target.files[0]) }} />
                      </div>
                    </div>


                    <div className="bg-51">
                      <button
                        className="btn btn-warning mt-4 mb-3"
                        type="submit"
                        style={{ marginLeft: "40px", width: "7rem" }}
                        onClick={() => navigate("/me1")}
                      >
                        Back
                      </button>
                      <button
                        className="btn btn-warning mt-4 mb-3"
                        type="submit"
                        style={{ marginLeft: "40px", width: "7rem" }}
                        onClick={(e) => editStaff(e)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
};

export default Me;
