import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";

import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import exportFromJSON from "export-from-json";
import moment from "moment";


const AdminRtoCFPayment = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const token = sessionStorage.getItem("token");

    const [branchName, setbranchName] = useState("");
    const [userType, setuserType] = useState("");
    const [RTOName, setRTOName] = useState("");
    const [Id, setId] = useState("");
    const [RTOApLLDlNo, setRTOApLLDlNo] = useState("");
    const [Name, setName] = useState("");
    const [applicationDate, setapplicationDate] = useState("");
    const [RTONo, setRTONo] = useState("");
    const [Particular, setParticular] = useState("");
    const [ServicesRequested, setServicesRequested] = useState("");
    const [LLDateExpire, setLLDateExpire] = useState("");
    const [ClassOfVehicle, setClassOfVehicle] = useState("");
    const [DateofComplition, setDateofComplition] = useState("");
    const [DateofPassing, setDateofPassing] = useState("");
    const [AboutDl, setAboutDl] = useState("");
    const [Remarks, setRemarks] = useState("");
    const [totalAmount, settotalAmount] = useState("");
    const [DateofPayment, setDateofPayment] = useState("");
    const [data, setdata] = useState([]);
    const [noschangedata, setnoschangedata] = useState([]);


    const addRtoCFPayment = async () => {
        try {
            if(!branchName||!RTOName||!RTOApLLDlNo||!Name) return alert("Please fill the form")
            const config = {
                url: "/admin/addRtocfpayment",
                method: "post",
                baseURL: "https://jaidrivingschool.in/api",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },

                data: {
                    authId: admin?._id,
                    branchName: branchName,
                    userType: userType,
                    RTOName: RTOName,
                    Id: Id,
                    RTOApLLDlNo: RTOApLLDlNo,
                    Name: Name,
                    applicationDate: applicationDate,
                    RTONo: RTONo,
                    Particular: Particular,
                    ServicesRequested: ServicesRequested,
                    LLDateExpire: LLDateExpire,
                    ClassOfVehicle: ClassOfVehicle,
                    DateofComplition: DateofComplition,
                    DateofPassing: DateofPassing,
                    AboutDl: AboutDl,
                    Remarks: Remarks,
                    totalAmount: totalAmount,
                    DateofPayment: DateofPayment,

                },
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert(res.data.success);
                getRTOCFpayment();
                handleClose()
                setbranchName("");
                setuserType("");
                setId("");
                setRTOName("");
                setRTOApLLDlNo("");
                setName("");
                setRTONo("");
                setServicesRequested("");
                setLLDateExpire("");
                setClassOfVehicle("");
                setParticular("");
                setapplicationDate("");
                setDateofPayment("");
                settotalAmount("");
                setRemarks("");
                setDateofComplition("");
                setDateofPassing("");
                setAboutDl("");
               

            }
        } catch (error) {
            console.log(error);
            alert(error.response.data.error);
        }
    };
    const [RTOCFpayment, setRTOCFpayment] = useState([]);
    // const [nochangedata, setnochangedata] = useState([])
    const [search, setSearch] = useState("");
    const handleFilter = (e) => {
        if (e.target.value != "") {
            setSearch(e.target.value);
            const filterTable = noschangedata.filter((o) =>
                Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
                )
            );
            setRTOCFpayment([...filterTable]);
        } else {
            setSearch(e.target.value);
            setRTOCFpayment([...noschangedata]);
        }
    };


    const getRTOCFpayment = async () => {
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAllRtocfpayment"
            );
            if (res.status == 200) {
                setRTOCFpayment(res.data.success);
                setnoschangedata(res.data.success);
                setdata(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [show4, setshow4] = useState(false);
    const handleShow4 = () => setshow4(true);
    const handleClose4 = () => setshow4(false);
    const [deleteId, setdeleteId] = useState("");
    const deleteRto = async () => {
        try {
            const config = {
                url: "/admin/deleteRtocfpayment/" + deleteId + "/" + admin?._id,
                method: "delete",
                baseURL: "https://jaidrivingschool.in/api",

                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
                //  data: {authId:admin?._id,titele:title,link:link,day:day,id:edit?._id}
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert("Successfully deleted");
                handleClose4();
                getRTOCFpayment();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [fileName, setfileName] = useState("RTO CF Application");
    const exportType = "xls";
    const ExportToExcel = () => {
   
            exportFromJSON({ data, fileName, exportType });

    };
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return alert("Please select from date");
        if (!tod) return alert("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-")
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""))
        let abc = noschangedata?.filter((ele) => {
            console.log("amit check it", moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd
        })
        setRTOCFpayment(abc);
        setdata(abc)
        setfileName(`RTO CF Application_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
    }
    useEffect(() => {
        getRTOCFpayment();
    }, []);
const [showE,setshowE]=useState(false);
const handleEdShow=(data)=>{
    setbranchName(data?.branchName);
    setuserType(data?.userType);
    setId(data?.Id);
    setRTOName(data?.RTOName);
    setRTOApLLDlNo(data?.RTOApLLDlNo);
    setName(data?.Name);
    setRTONo(data?.RTONo);
    setServicesRequested(data?.ServicesRequested);
    setLLDateExpire(data?.LLDateExpire);
    setClassOfVehicle(data?.ClassOfVehicle);
    setParticular(data?.Particular);
    setapplicationDate(data?.applicationDate);
    setDateofPayment(data?.DateofPayment);
    settotalAmount(data?.totalAmount);
    setRemarks(data?.Remarks);
    setDateofComplition(data?.DateofComplition);
    setDateofPassing(data?.DateofPassing);
    setAboutDl(data?.AboutDl);
    setshowE(true);

}

const [edit,setedit]=useState({});
const updatedDataCf=async()=>{
    try {
        // if(!branchName||!RTOName||!RTOApLLDlNo||!Name) return alert("Please fill the form")
        const config = {
            url: "/admin/updateRtocfpayment",
            method: "put",
            baseURL: "https://jaidrivingschool.in/api",
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
            },
            data: {
                id:edit?._id,
                authId: admin?._id,
                branchName: branchName,
                userType: userType,
                RTOName: RTOName,
                Id: Id,
                RTOApLLDlNo: RTOApLLDlNo,
                Name: Name,
                applicationDate: applicationDate,
                RTONo: RTONo,
                Particular: Particular,
                ServicesRequested: ServicesRequested,
                LLDateExpire: LLDateExpire,
                ClassOfVehicle: ClassOfVehicle,
                DateofComplition: DateofComplition,
                DateofPassing: DateofPassing,
                AboutDl: AboutDl,
                Remarks: Remarks,
                totalAmount: totalAmount,
                DateofPayment: DateofPayment,

            },
        };
        let res = await axios(config);
        if (res.status == 200) {
            alert("Successfully updated");
            setshowE(false);
            getRTOCFpayment();
            setbranchName("");
            setuserType("");
            setId("");
            setRTOName("");
            setRTOApLLDlNo("");
            setName("");
            setRTONo("");
            setServicesRequested("");
            setLLDateExpire("");
            setClassOfVehicle("");
            setParticular("");
            setapplicationDate("");
            setDateofPayment("");
            settotalAmount("");
            setRemarks("");
            setDateofComplition("");
            setDateofPassing("");
            setAboutDl("");
        }
    } catch (error) {
        alert(error.response.data.error);
        console.log(error);
    }
}
const [allRTO, setAllRTO] = useState([]);
const getRTO = async () => {
    try {
        let res = await axios.get(
            "https://jaidrivingschool.in/api/admin/getAllRTOByAdmin/" + admin?._id, {
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
            },
        }
        );
        if (res.status == 200) {
            setAllRTO(res.data.success);
        }
    } catch (error) {
        console.log(error);
    }
};
const handleRTO = (abc) => {
    if (abc !== "") {
        abc = JSON.parse(abc);
        setRTOName(abc?.rtoName);

    }
}
useEffect(()=>{
    getRTO()
},[])
    return (
        <>
            <div className="add-gr">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ad-b mt-4">
                                <button
                                    style={{ borderRadius: "10px", float: "right" }}
                                    onClick={()=>{
                                        setbranchName("");
                                        setuserType("");
                                        setId("");
                                        setRTOName("");
                                        setRTOApLLDlNo("");
                                        setName("");
                                        setRTONo("");
                                        setServicesRequested("");
                                        setLLDateExpire("");
                                        setClassOfVehicle("");
                                        setParticular("");
                                        setapplicationDate("");
                                        setDateofPayment("");
                                        settotalAmount("");
                                        setRemarks("");
                                        setDateofComplition("");
                                        setDateofPassing("");
                                        setAboutDl("");
                                        handleShow()}}
                                >
                                    +Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row ">
                        <div className="col-md-3 mt-4">
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                    <AiOutlineSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    style={{ height: "45px" }}
                                    onChange={handleFilter}
                                />
                            </InputGroup>
                        </div>
                        <div className=" col-md-2 ">
                            <label>From</label>
                            <br />
                            <input type="date" className="vi_0" onChange={(e) => setfromd(e.target.value)} />
                        </div>
                        <div className=" col-md-2">
                            <label>To</label>
                            <br />
                            <input type="date" className="vi_0" onChange={(e) => settod(e.target.value)} />
                        </div>
                        <div className="col-md-2 mt-4 ">
                            <button className="btn btn-success" onClick={searchDate}>Submit</button>
                        </div>
                        <div className="col-md-2 mt-4 ad-b">
                            <button onClick={ExportToExcel}>Export Excel</button>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="mo-gra mt-5 scrolll_bar">
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th>S.No</th>

                                    <th>Name </th>
                                    <th>RTO Name</th>
                                    <th>Student/Customer</th>
                                    <th>(RTO) Application No LL/DL/Others</th>
                                    <th>Services Requested (LL/DD/RC) </th>
                                    <th>Particular</th>
                                    <th>Class Of Vehicle</th>
                                    <th> Amount</th>
                                    <th>Learning Licence No. and Date of Expiry</th>
                                    <th>Date of Complition of the course</th>
                                    <th>Date of Passing of the test of competance to drive</th>
                                    <th>Driving Licence number,Date of Issue,licensing authority</th>
                                    <th>Remarks</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            {RTOCFpayment?.map((ele, i) => {
                                return (
                                    <tbody>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{ele?.Name}</td>
                                            <td> {ele?.RTOName}</td>
                                            <td>{ele?.userType}</td>
                                            <td>{ele?.RTOApLLDlNo}</td>
                                            <td>{ele?.ServicesRequested}</td>
                                            <td>{ele?.Particular}</td>
                                            <td>{ele?.ClassOfVehicle}</td>
                                            <td>{ele?.totalAmount}</td>
                                            <td>{ele?.LLDateExpire}</td>
                                            <td>{ele?.DateofComplition}3</td>
                                            <td>{ele?.DateofPassing}</td>
                                            <td>{ele?.AboutDl}</td>
                                            <td> {ele?.Remarks}</td>
                                            <td style={{display:"flex",gap:"5px"}}> <img
                                src="./images/editing.png"
                                alt=""
                                width={30}
                                onClick={() => {
                                    handleEdShow(ele);
                                  setedit(ele);
                                }}
                              />{" "}<Button type="button" variant="danger" onClick={() => {
                                                setdeleteId(ele?._id);
                                                handleShow4();
                                            }}>
                                                <span>
                                                    <RiDeleteBin6Line
                                                        style={{ fontSize: "20" }}

                                                    />
                                                </span>
                                            </Button></td>


                                            {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
                <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                                        </tr>
                                    </tbody>
                                )
                            })}

                        </Table>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add RTO CF Payment </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label>Name</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Name"
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Student / Customer</label>
                        <select name="" id="" className="vi_0" onChange={(e) => { setuserType(e.target.value) }}>
                            <option value="">---select---</option>
                            <option value="Student">Student</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    <div className="do-sear mt-2">
                        <label>RTO Name</label>
                        <select name="" id="" className="vi_0" onChange={(e) => { handleRTO(e.target.value) }}>
                            <option value="">----Select RTO Name----</option>
                            {allRTO?.map((item) => {
                                return (
                                    <option value={JSON.stringify(item)}>{item?.rtoName}</option>
                                )
                            })}
                        </select>
                        {/* <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter RTO Name "
                            onChange={(e) => { setRTOName(e.target.value) }}
                        /> */}
                    </div>

                    <div className="do-sear mt-2">
                        <label>(RTO) Application No LL/DL/Others</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter (RTO) Application No LL/DL/Others"
                            onChange={(e) => { setRTOApLLDlNo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Services Requested (LL/DD/RC)</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Services Requested (LL/DD/RC)"
                            onChange={(e) => { setServicesRequested(e.target.value) }}
                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>Particular</label>
                        <input
                            type="date   "
                            className="vi_0"
                            placeholder="Please enter Particular "
                            onChange={(e) => { setParticular(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Class of Vehicle</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Class of Vehicle"
                            onChange={(e) => { setClassOfVehicle(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Amount</label>
                        <input
                            type="number"
                            className="vi_0"
                            placeholder="Please enter Amount"
                            onChange={(e) => { settotalAmount(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Learning Licence No. and Date of Expiry</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Learning Licence No. and Date of Expiry"
                            onChange={(e) => { setLLDateExpire(e.target.value) }}

                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Complition of the course</label>
                        <input
                            type="date"
                            className="vi_0"
                            placeholder="Please enter Signature / Name of  Trainer"
                            onChange={(e) => { setDateofComplition(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Passing of the test of competance to drive </label>
                        <input
                            type="date"
                            className="vi_0"
                            placeholder="Please enter Signature / Name of  Trainer"
                            onChange={(e) => { setDateofPassing(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Driving Licence number and the Date of Issue and the licensing
                            authority and which issued the licece{" "}
                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Driving Licence number and the Date of Issue and the licensing authority and which issued the liceces"
                            onChange={(e) => { setAboutDl(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Remarks </label>
                        <textarea
                            rows={3}
                            cols={10}
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Remarks "
                            onChange={(e) => { setRemarks(e.target.value) }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addRtoCFPayment}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={show4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete??</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose4}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={deleteRto}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
           {/* update Model */}
            <Modal
                show={showE}
                onHide={()=>setshowE(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update RTO CF Payment </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label>Name</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Name"
                            value={Name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Student / Customer</label>
                        <select name="" id="" className="vi_0" onChange={(e) => { setuserType(e.target.value) }}>
                            <option value={userType}>{userType}</option>
                            <option value="Student">Student</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    <div className="do-sear mt-2">
                        <label>RTO Name</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter RTO Name "
                            value={RTOName}
                            onChange={(e) => { setRTOName(e.target.value) }}
                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>(RTO) Application No LL/DL/Others</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={RTOApLLDlNo}
                            placeholder="Please enter (RTO) Application No LL/DL/Others"
                            onChange={(e) => { setRTOApLLDlNo(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Services Requested (LL/DD/RC)</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={ServicesRequested}
                            placeholder="Please enter Services Requested (LL/DD/RC)"
                            onChange={(e) => { setServicesRequested(e.target.value) }}
                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>Particular</label>
                        <input
                            type="date   "
                            className="vi_0"
                            placeholder="Please enter Particular "
                            value={Particular}
                            onChange={(e) => { setParticular(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Class of Vehicle</label>
                        <input
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Class of Vehicle"
                            value={ClassOfVehicle}
                            onChange={(e) => { setClassOfVehicle(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Amount</label>
                        <input
                            type="number"
                            className="vi_0"
                            placeholder="Please enter Amount"
                            value={totalAmount}
                            onChange={(e) => { settotalAmount(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Learning Licence No. and Date of Expiry</label>
                        <input
                            type="text"
                            className="vi_0"
                            value={LLDateExpire}
                            placeholder="Please enter Learning Licence No. and Date of Expiry"
                            onChange={(e) => { setLLDateExpire(e.target.value) }}

                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Complition of the course</label>
                        <input
                            type="date"
                            className="vi_0"
                            placeholder="Please enter Signature / Name of  Trainer"
                            value={DateofComplition}
                            onChange={(e) => { setDateofComplition(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Passing of the test of competance to drive </label>
                        <input
                            type="date"
                            className="vi_0"
                            placeholder="Please enter Signature / Name of  Trainer"
                            value={DateofPassing}
                            onChange={(e) => { setDateofPassing(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>
                            Driving Licence number and the Date of Issue and the licensing
                            authority and which issued the licece{" "}
                        </label>
                        <input
                            type="text"
                            className="vi_0"
                            value={AboutDl}
                            placeholder="Please enter Driving Licence number and the Date of Issue and the licensing authority and which issued the liceces"
                            onChange={(e) => { setAboutDl(e.target.value) }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Remarks </label>
                        <textarea
                            rows={3}
                            cols={10}
                            type="text"
                            className="vi_0"
                            placeholder="Please enter Remarks "
                            value={Remarks}
                            onChange={(e) => { setRemarks(e.target.value) }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setshowE(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updatedDataCf}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AdminRtoCFPayment;
