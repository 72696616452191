import React from 'react'
import Side from "./sidebar/Side";

const Reviews = () => {
  return (
    <div>
        <Side/>
        <div className="container">
            <div>
                
            </div>
        </div>
    </div>
  )
}

export default Reviews