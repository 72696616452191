import React from "react";

const Blog1 = () => {
  return (
    <div>
      <div className="blog1">
        <div className="container">
          <div className="row">
            <div className="col-md bg-17">
              <ul>
                {" "}
                <h3>CATEGORY</h3>
                <li>
                  <a href="">Learning Licence</a>
                </li>
                <li>
                  <a href="">Permanent Licence</a>
                </li>
                <li>
                  {" "}
                  <a href="">RC Transfer</a>
                </li>
                <li>
                  <a href="">Driving School</a>
                </li>
                <li>
                  <a href="">General Blog</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog1;
