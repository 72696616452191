import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";

function ChangePassword() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="add-gr mt-4">
        <div className="container">

          <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="" style={{ borderRadius: "4px", boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset", padding: "11px" }}>
              <div className="ad-b mt-2">
                <button onClick={handleShow}>Change Password</button>
              </div>
              <div className="">
                <div className="do-sear mt-2">
                  <label>Registerd Email ID</label>
                  <input
                    type="text"
                    placeholder="Enter Registerd Email ID"
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="">
                <div className="do-sear mt-2">
                  <label> Old Password</label>
                  <input
                    type="text"
                    placeholder="Enter Old Password"
                    className="vi_0"
                  />
                </div>
              </div>
              <div className="">
                <div className="do-sear mt-2">
                  <label> New Password</label>
                  <input
                    type="text"
                    placeholder="Enter New Password"
                    className="vi_0"
                  />
                </div>
              </div>

              <Button variant="primary mt-3">Save Changes</Button>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ChangePassword;
