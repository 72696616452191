import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaRegUserCircle } from "react-icons/fa";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AlternateEmail } from "@mui/icons-material";
import { BsFillTelephonePlusFill, BsWhatsapp } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
// import SignUpForm from "./Signup";

const Navbar = () => {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);








  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const staff = JSON.parse(sessionStorage.getItem("staff"));
  const token = sessionStorage.getItem("token");
  const logOut = () => {
    alert("Successfully Logout");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("staff");
    window.location.assign("/");
  };
  const trainer = JSON.parse(sessionStorage.getItem("staff"));
  const [username, setusername] = useState("");
  const [useMobile, setuseMobile] = useState("");
  const [useEmail, setuseEmail] = useState("");
  useEffect(() => {
    if (user) {
      setusername(user?.name);
      setuseMobile(user?.mobile);
      setuseEmail(user?.email);
    }
    if (trainer) {
      setusername(trainer?.name);
      setuseMobile(trainer?.mobile);
      setuseEmail(trainer?.email);
    }
  }, []);
  const CallNow = async () => {
    if (!username) return alert("Please enter your name");
    if (!useMobile) return alert("Please enter your mobile number");
    if (!useEmail) return alert("Please enter your email");

    try {
      const config = {
        url: "/admin/postEnquire",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          name: username,
          email: useEmail,
          mobile: useMobile,
          EnquireType: `Call Me`,
          comments: "User want talk to you",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Team will be connect as soon");
        window.location.assign("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [activeItem, setActiveItem] = useState('Home');
  const [AllHomeLogo, setAllHomeLogo] = useState([]);
  const getHomeLogo = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getHomeLogo");
      if (res.status == 200) {
        setAllHomeLogo(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getHomeLogo()
  },[])
  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  return (
    <div>
      <div
        className="container-fluid "
        style={{ background: " #9d041b" }}
      >
        <div
          style={{ color: "#fff", paddingTop: "3px" }}
        >
          {AllHomeLogo?.map((ele)=>{
            return (
               <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ paddingTop: "3px" }}>
              <p>
                {" "}

                <FaLocationDot /> <a href="" style={{ textDecoration: "none", color: "white" }}>{ele?.homeLocation}</a>
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly", paddingTop: "3px" }} >
              <div className="me-5">
                <p>
                  <BsFillTelephonePlusFill /> + 91 {ele?.homeContact}
                </p>
              </div>
              <div className="me-5">
                <p>
                  <AiFillMail className="me-2" />
                  {ele?.homeEmai}
                </p>
              </div>
              <div className="me-5" >
                <p>
                  <BsWhatsapp /> + 91  {ele?.homeWhatsApp}
                </p>
              </div>
              <div >
                <span >
                  <button
                    type="button"
                    class="btn btn-primary dfghjkl_0"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    style={{
                      color: "black",

                      height: "34px",
                      width: "100px"
                    }}
                  >
                    Call Me
                  </button>
                </span>

              </div>
            </div>

          </div>
            )
          })}
         
        </div>
      </div>
      <div className="container-fluid">
        <div
          class="cgd"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          
          <div className="col-4 mt-4 loca">
           
          </div>
          <div className=" loca col-2 mt-3">
       
          </div>




          {/* Modal callback */}
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">
                    CALL BACK
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div>
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      id="name1"
                      value={username}
                      onChange={(e) => setusername(e.target.value)}
                      required
                      placeholder="Enter Username..."
                    />
                    <br />

                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="number"
                      id="name3"
                      value={useMobile}
                      onChange={(e) => setuseMobile(e.target.value)}
                      required
                      placeholder="Enter Mobile No...."
                    />
                    <br />
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="name2"
                      value={useEmail}
                      onChange={(e) => setuseEmail(e.target.value)}
                      required
                      placeholder="Enter Email..."
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    style={{ color: "black" }}
                    onClick={() => CallNow()}
                  >
                    SEND REQUEST
                  </button>
                </div>
              </div>
            </div>
          </div>

        
        </div>
      </div>
      {/* </div> */}

      <nav class="navbar navbar-expand-lg bg-body-tertiary ">
        <div class="container-fluid">
          {AllHomeLogo?.map((ele)=>{
            return (
              <a class="navbar-brand" href="#">
            <img
                src={`https://jaidrivingschool.in/HomeLogo/${ele?.image}`}
              alt=""
              style={{ height: "62px", width: "140px" }}
              onClick={() => window.location.assign("/")}
            />
          </a> 
            )
          })}
         
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav  mb-2 mb-lg-0">
              <Link
                className="nav-link "
                to={"/"}
              // style={{ marginLeft: "200px" }}
              >
                <li className={`a-ele ${acc ? "active" : "null"}`}
                  onClick={() => {
                    setacc(true);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false)
                    setacc5(false)
                    setacc6(false)
                    setacc7(false)
                    setacc8(false)




                  }} >

                  Home
                </li>
              </Link>
              <Link
                className="nav-link "

                to="/about"
              >
                <li className={`a-ele ${acc1 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(true);
                    setacc2(false)
                    setacc3(false)
                    setacc4(false)
                    setacc5(false)
                    setacc6(false)
                    setacc7(false)
                    setacc8(false)




                  }}>

                  AboutUs
                </li>
              </Link>
              <Link
                className="nav-link"
                to="/courses"
              >
                <li className={`a-ele ${acc2 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(true)
                    setacc3(false)
                    setacc4(false)
                    setacc5(false)
                    setacc6(false)
                    setacc7(false)
                    setacc8(false)




                  }}>

                  Courses
                </li>
              </Link>
              <Link className="nav-link " to="/rto">
                <li className={`a-ele ${acc3 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false)
                    setacc3(true)
                    setacc4(false)
                    setacc5(false)
                    setacc6(false)
                    setacc7(false)
                    setacc8(false)




                  }}>

                  Services
                </li>
              </Link>
              <Link
                className="nav-link "
                aria-current="page"
                to="/gallery"
              >
                <li className={`a-ele ${acc4 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false)
                    setacc3(false)
                    setacc4(true)
                    setacc5(false)
                    setacc6(false)
                    setacc7(false)
                    setacc8(false)




                  }}>

                  Gallery
                </li>
              </Link>
              <Link
                className="nav-link "
                aria-current="page"
                to="/blog"
              >
                <li className={`a-ele ${acc5 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false)
                    setacc3(false)
                    setacc4(false)
                    setacc5(true)
                    setacc6(false)
                    setacc7(false)
                    setacc8(false)



                  }}>

                  Blog
                </li>
              </Link>
              <Link
                className="nav-link "
                aria-current="page"
                to="/traffic"
              >
                <li className={`a-ele ${acc6 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false)
                    setacc3(false)
                    setacc4(false)
                    setacc5(false)
                    setacc6(true)
                    setacc7(false)
                    setacc8(false)


                  }}>

                  SafetyRules
                </li>
              </Link>
              <Link
                className="nav-link "
                to="/contact"
              >
                <li className={`a-ele ${acc7 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false)
                    setacc3(false)
                    setacc4(false)
                    setacc5(false)
                    setacc6(false)
                    setacc7(true)
                    setacc8(false)

                  }}>

                  Contact
                </li>

              </Link>
              <Link className="nav-link " aria-current="page" to="/faq">
                <li className={`a-ele ${acc8 ? "active" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false)
                    setacc3(false)
                    setacc4(false)
                    setacc5(false)
                    setacc6(false)
                    setacc7(false)
                    setacc8(true)
                  }}>

                  FAQ
                </li>
              </Link>

              <div style={{ marginTop: "20px" }} className="button_navbar" >
                <li>

                </li>
                <li>
                  <Button
                    variant="primary"
                    className="ms-3 login_navbar "
                    style={{
                      textAlign: "center", padding: "unset", height: "unset"
                    }}
                  >
                    {token ? (
                      <>
                        {user ? (
                          <li
                            className="nav-item dropdown"
                            style={{ listStyle: "none" }}
                          >
                            <Link
                              className="nav-link  active dropdown-toggle"
                              to="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "5px" }}
                            >
                              {user?.profile ? (
                                <img
                                  src={`https://jaidrivingschool.in/Customer/${user?.profile}`}
                                  style={{
                                    borderRadius: "100%",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              ) : (
                                <FaRegUserCircle
                                  style={{
                                    borderRadius: "100%",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              )}{" "}
                              {user?.name}
                            </Link>
                            <ul className="dropdown-menu" style={{ position: "absolute", top: "100%", left: "unset" }}>
                              <li>
                                <Link className="dropdown-item" to="/sprofile">
                                  Profile{" "}
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" onClick={logOut}>
                                  Logout
                                </Link>
                              </li>
                            </ul>
                          </li>
                        ) : (
                          <li
                            className="nav-item dropdown"
                            style={{ listStyle: "none" }}
                          >
                            <Link
                              className="nav-link  active dropdown-toggle"
                              to="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "5px" }}
                            >
                              {staff?.profile ? (
                                <img
                                  src={`https://jaidrivingschool.in/Staff/${staff?.profile}`}
                                  style={{
                                    borderRadius: "100%",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              ) : (
                                <FaRegUserCircle
                                  style={{
                                    borderRadius: "100%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              )}{" "}
                              {staff?.name}
                            </Link>
                            <ul className="dropdown-menu">
                              <li>
                                <Link className="dropdown-item" to="/me1">
                                  Profile{" "}
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" onClick={logOut}>
                                  Logout
                                </Link>
                              </li>
                            </ul>
                          </li>
                        )}
                      </>
                    ) : (
                      <li
                        className="nav-item dropdown"
                        style={{ listStyle: "none", color: "white " }}
                      >
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ fontFamily: "none", height: "42px", padding: "9px", color: "black", fontWeight: "normal" }}
                        >
                          Login
                        </Link>
                        <ul className="dropdown-menu dropdown_menu" style={{ marginLeft: "-1px" }}>
                          <li>
                            <Link className="dropdown-item" to="/stafflogin">
                              Trainer Login{" "}
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/studentlogin">
                              Student/Customer Login
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/register">
                              Register
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </Button>
                </li>
              </div>

            </ul>
          </div>
        </div>
      </nav >

      {/* <nav className="navbar navbar-expand-lg bg-body-tertiary mb-3" style={{ marginTop: "10px" }}>
        <img
          src="../images/logo.png.jpg"
          alt=""

          style={{ height: "44px", width: "115px" }}
          onClick={() => window.location.assign("/")}
        />
        <div className="container-fluid" >
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navvv">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >


              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to={"/"}
                  style={{ marginLeft: "200px" }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/about"
                  >
                    AboutUs
                  </Link>
                </li>

                <ul className="dropdown-menu">
                <li>
                    <Link className="dropdown-item" to="/ourteams">
                      Our Teams
                    </Link>
                  </li>
                <li>
                    <Link className="dropdown-item" to="/gallery">
                      Gallery
                    </Link>
                  </li>
                </ul>

                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/courses"
                  >
                    Courses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/rto"
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link active dropdown-toggle"
                    to="/rto"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/rto">
                        RTO Services
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/courses"
                >
                  Our Team
                </Link>
              </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/gallery"
                  >
                    Gallery
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/traffic"
                  >
                    SafetyRules
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/faq"
                  >
                    FAQ
                  </Link>
                </li>

                <li className="nav-item">
                <Link  to="/contact" className="nav-link active">Get in Touch</Link>
              </li>
              </ul>

              <button
                type="button"
                class="btn btn-primary dfghjkl_0"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                style={{ color: "white", marginLeft: "-76px", padding: "9px" }}

              >
                CALL ME
              </button>

              <Button variant="primary" className="ms-3 " style={{ textAlign: "center", color: "black" }}>
                {token ? (<>{user ? (<li className="nav-item dropdown" style={{ listStyle: "none" }}>
                  <Link
                    className="nav-link  active dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user?.profile ? (<img src={`https://jaidrivingschool.in/Customer/${user?.profile}`} style={{ borderRadius: "100%", width: "40px", height: "40px" }} />) : (< FaRegUserCircle style={{ borderRadius: "100%", width: "40px", height: "40px" }} />)}{" "}
                    {user?.name}
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/sprofile">
                        Profile{" "}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" onClick={logOut} >
                        Logout
                      </Link>
                    </li>

                  </ul>
                </li>) : (<li className="nav-item dropdown" style={{ listStyle: "none" }}>
                  <Link
                    className="nav-link  active dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {staff?.profile ? (<img src={`https://jaidrivingschool.in/Staff/${staff?.profile}`} style={{ borderRadius: "100%", width: "40px", height: "40px" }} />) : (< FaRegUserCircle style={{ borderRadius: "100%", width: "40px", height: "40px" }} />)}{" "}
                    {staff?.name}
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/me1">
                        Profile{" "}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" onClick={logOut} >
                        Logout
                      </Link>
                    </li>

                  </ul>
                </li>)}</>) : (
                  <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                    <Link
                      className="nav-link  active dropdown-toggle"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      login
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/stafflogin">
                          Trainer Login{" "}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/studentlogin">
                          Student/Customer Login
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/register">
                          Register
                        </Link>
                      </li>
                    </ul>
                  </li>)}

              </Button>


            </div>
          </div>
        </div>
      </nav> */}
    </div >
  );
};

export default Navbar;
