import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { BiUserCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import moment from "moment";
const VendorOtherBookcourses = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");
  const [AllBooking, setAllBooking] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllBooking = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      const config = {
        url: "/admin/getTransferBranchId/" + ab+"/"+admin?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllTrainer, setAlltrainer] = useState([]);
  const [nochangedataT, setnochangedataT] = useState([]);
  const getTrainer = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      const config = {
        url: "/staff/getTrainerByBranchId/"+ab,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
        setnochangedataT(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getAllBooking();
      getTrainer();
    }
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllBooking.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [pageNumber1, setPageNumber1] = useState(0);
  const usersPerPage1 = 10;
  const pagesVisited1 = pageNumber1 * usersPerPage1;
  const pageCount1 = Math.ceil(AllTrainer.length / usersPerPage1);
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllBooking([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllBooking([...nochangedata]);
    }
  };

  const TrainerHandle = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedataT.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAlltrainer([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAlltrainer([...nochangedataT]);
    }
  };

  const [slectData, setSelectData] = useState({});
  console.log("slect DAta", slectData);
  const BookAssignTrainer = async (data) => {
    try {
      const config = {
        url: "/admin/BookAssignTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          bookId: slectData?._id,
          trainerId: data?._id,
          trinerStatus: "Assigned",
          trinerName: data?.name,
          trainerMobile: data?.mobile,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        handleClose();
        getAllBooking();
        getTrainer();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const cancelAssigneTrainer = async (data) => {
    try {
      const config = {
        url: "/admin/BookAssignTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          bookId: slectData?._id,
          trainerId: slectData?._id,
          trinerStatus: "Pending",
          trinerName: "",
          trainerMobile: "",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully cancel");
        handleClose();
        getAllBooking();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [showAt,setshowAt]=useState(false);
  const [courseD,setcourseD]=useState({});
  const [AllAttandance,setAllAttandance]=useState([]);
  const getAttedance=async(id)=>{
    try {
      let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassBycustomerId/"+id);
      if(res.status==200){
        setAllAttandance(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="ad-b mt-4">
                  <button> Other Branch Booked Course Details</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>BookId</th>
                    <th>Passport Image</th>
                    <th>Name</th>
                    <th>Date Of Birth</th>
                    <th>Number</th>
                    <th>Email</th>
                    <th>Other Branch</th>
                    <th>Adhar</th>
                    <th>Course</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Class Timing</th>
                    <th>Cource Price</th>
                    <th>Additional</th>
                    <th>Total Price</th>
                    <th>Trainer Name</th>
                    <th>Trainer Mobile</th>
                    <th>Booking Status</th>
                    <th>Booking Date</th>
                     <th>Trainer Status</th>
                     <th>Class View</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                   {AllBooking?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                    return (
                     <tr>
                    <td>{i+1}</td>
                 
                    <td>
                  {item?.bookid}
                    </td>
                    <td> <a href={`https://jaidrivingschool.in/Customer/${item?.customerId?.passportcopy}`} target="_blank"><img src={`https://jaidrivingschool.in/Customer/${item?.customerId?.passportcopy}`} style={{width:"100px",height:"100px"}} /></a></td>
                    <td>{item?.customerName}</td>
                    <td>{item?.customerId?.dob}</td>
                    
                    <td>
                     {item?.number}
                    </td>
                    <td>{item?.email}</td>
                    <td>{item?.branchName}</td>
                    <td><a href={`https://jaidrivingschool.in/Customer/${item?.customerId?.addhar}`} target="_blank"> <img src={`https://jaidrivingschool.in/Customer/${item?.customerId?.addhar}`} style={{width:"100px",height:"100px"}} /></a></td>
                    <td>{item?.courseTitle}</td>
                    <td>
                    {moment(item?.courseStartDate).format("DD/MM/YYYY")}
                    </td>
                    <td>
                    {moment(item?.courseEndDate).format("DD/MM/YYYY")}
                    </td>
                    <td>{item?.courseTiming}</td>
                  
                    <td>{item?.coursePrice}</td>
                    <td>
                      <Table>
                        <thead><tr>
                          <th>Service</th>
                          <th>Price</th>
                          </tr></thead>
                          <tbody>
                            {item?.additional.map((ele, i) => {
                  return (
                    <tr>
                  
                      <td>{ele?.text}</td>
                      <td>₹{ele?.price}</td>
                    </tr>
                  );
                })}
                          </tbody>
                      </Table>
                    </td>
                    <td>{item?.totalAmount}</td>
                    <td>{item?.trinerName}</td>
                    <td>{item?.trainerMobile}</td>
                    <td>
                     {item?.status=="Pending" ? (<span style={{color:"blue"}}>{item?.status}</span>):(<span style={{color:"green"}}>{item?.status=="Assigned" ? (<span style={{color:"Red"}}>{item?.status}</span>):(<span>{item?.status}</span>)}</span>)}
                    </td>
                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                     {item?.trinerStatus=="Pending" ? (<span style={{color:"blue"}}>{item?.trinerStatus}</span>):(<span style={{color:"green"}} onClick={()=>{
                          setSelectData(item);
                          handleShow3()
                     }}>{item?.trinerStatus=="Assigned"|| item?.trinerStatus=="Rejected" ? (<span style={{color:"Red"}}>{item?.trinerStatus} {" "} <FaEye/></span>):(<span>{item?.trinerStatus} {" "}<FaEye/></span>)}</span>)}
                    </td>
                    <td><FaEye style={{color:"blue",fontSize:"25px",cursor:"pointer"}} onClick={()=>{
                                      setcourseD(item)
                                      getAttedance(item?.customerId?._id)
                                      setshowAt(true)
                                    }}/></td>
                    <td>
                     {item?.trinerStatus!=="Accepted" || item?.trinerStatus!=="Complete" ? (<button type="button" class="btn btn-info" onClick={()=>{
                      setSelectData(item);
                      handleShow()
                     }}>Assgined Trainer</button>):(<></>)}
                    </td>
                    {/* <td>
                     {item?.isBlocked==true ? (<button type="button" class="btn btn-success" onClick={()=>makeBlockUnblockCustomer(item?._id,false)}>Un-Block</button>):(<button type="button" class="btn btn-danger" onClick={()=>makeBlockUnblockCustomer(item?._id,true)}>Block</button>)}{" "}
                    </td> */}
                  </tr>  
                    )
                  })}
                </tbody>
              </Table>
              <br/>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assgin Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Search Trainer...."
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                  onChange={(e) => TrainerHandle(e)}
                />
              </div>
            </div>
            {AllTrainer?.slice(
              pagesVisited1,
              pagesVisited1 + usersPerPage1
            )?.map((item) => {
              return (
                <div className="">
                  <img
                    src={`https://jaidrivingschool.in/Staff/${item?.profile}`}
                    style={{
                      borderRadius: "100%",
                      height: "40px",
                      width: "40px",
                    }}
                  />{" "}
                  <span>{item?.name}</span> <span>{item?.mobile}</span>{" "}
                  <span style={{ float: "right" }}>
                    {item?.mobile == slectData?.trainerMobile ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={cancelAssigneTrainer}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={() => {
                          BookAssignTrainer(item);
                        }}
                      >
                        Assign Now
                      </button>
                    )}{" "}
                  </span>
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount1}
              onPageChange={changePage1}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show Assgned Trainer Model */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assgined Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Search Trainer...."
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                  onChange={(e) => TrainerHandle(e)}
                />
              </div>
            </div> */}
            {AllTrainer?.filter((ele) => ele?._id == slectData?.trainerId)?.map(
              (item) => {
                return (
                  <div className="">
                    <img
                      src={`https://jaidrivingschool.in/Staff/${item?.profile}`}
                      style={{
                        borderRadius: "100%",
                        height: "40px",
                        width: "40px",
                      }}
                    />{" "}
                    <span>{item?.name}</span> <span>{item?.mobile}</span>{" "}
                    <span style={{ float: "right" }}>
                      {slectData?.trinerStatus == "Assigned" ? (
                        <span style={{ color: "red" }}>Assigned</span>
                      ) : (
                        <BsFillCheckCircleFill
                          style={{ color: "green", fontSize: "25px" }}
                        />
                      )}{" "}
                    </span>
                  </div>
                );
              }
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
            show={showAt}
            onHide={()=>setshowAt(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Classes And Attanence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="scrolll_bar mb-4">
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        {/* <th>S.No</th> */}
                        {/* <th>Id</th> */}
                        <th>Day</th>
                        <th>Topic Name</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Intial Reading</th>
                        <th>End Reading</th>
                        {/* <th>Trainer Name</th> */}
                        <th>Remark</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseD?.courseTopic?.map((item, i) => {
                        return (
                          <tr>
                            {/* <td>{i + 1}</td> */}
                        
                            <td>{item?.days}</td>
                            <td>{item?.topicName}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectdate}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.selectTime}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.endtime}</td>

                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.pickupAddress}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.dropAddress}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.remarks}</td>
                            <td>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)?.length==0 ?(<span style={{color:"blue"}}>Pending</span>):(<span style={{color:"green"}}>{AllAttandance?.filter((ele)=>ele?.classTopic==item?.topicName)[0]?.status}</span>)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setshowAt(false)}>
                Close
              </Button>
              {/* <Button variant="primary" onClick={handleSubmit}>
                Submit{" "}
              </Button> */}
            </Modal.Footer>
          </Modal>
      </>

    </div>
  );
};

export default VendorOtherBookcourses;
