import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaBusinessTime, FaClock, FaUsersLine } from "react-icons/fa6";
import { GrDocumentPerformance } from "react-icons/gr";
import {
  MdAccountBalance,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { GiFlatPlatform } from "react-icons/gi";

import axios from "axios";
import { useEffect } from "react";
function VendorSidebar() {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);
  const [acc9, setacc9] = useState(false);
  const [acc10, setacc10] = useState(false);
  const [acc11, setacc11] = useState(false);
  const [acc12, setacc12] = useState(false);
  const [acc13, setacc13] = useState(false);
  const [acc14, setacc14] = useState(false);
  const [acc15, setacc15] = useState(false);
  const [acc16, setacc16] = useState(false);
  const [acc17, setacc17] = useState(false);
  const [gallery, setgallery] = useState(false);
  const [Branch, setBranch] = useState(false);
  const [service, setservice] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [bookS, setbookS] = useState(false);
  const [timeSlot, settimeSlot] = useState(false);
  const [opening, setopening] = useState(false);
  const [Enquires, setEnquires] = useState(false);
  const [ratting, setratting] = useState(false);
  const [Leaves, setLeave] = useState(false);
  const [FAQ, setFAQ] = useState(false);
  const [Expans, setExpans] = useState(false);
  const [from, setform] = useState(false);
  const [RtoApp, setRtoApp] = useState(false);
  const [Rto, setRto] = useState(false);
  const [acc25, setacc25] = useState(false);
  const [acc26, setacc26] = useState(false);
  const [acc27, setacc27] = useState(false);
  const [acc40, setacc40] = useState(false);
  const [acc28, setacc28] = useState(false);
  const [acc29, setacc29] = useState(false);
  const [vendorSl, setvendorSl] = useState(false);

  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const [AllHomeLogo, setAllHomeLogo] = useState([]);
  const getHomeLogo = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getHomeLogo"
      );
      if (res.status == 200) {
        setAllHomeLogo(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHomeLogo();
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="si09">
      {AllHomeLogo?.map((ele) => {
        return (
          <div className="lo-ad">
            <img
              src={`https://jaidrivingschool.in/HomeLogo/${ele?.image}`}
              alt="adminlogo"
              style={{ width: "200px", height: "87px", padding: "0px 11px" }}
            />
          </div>
        );
      })}
      <ul className="">
        <Link to="/Branch-dashboard">
          <li
            className={`a-ele ${acc ? "active-0" : ""}`}
            onClick={() => {
              setacc(true);
              setacc1(false);
              settimeSlot(false);
              setopening(false);
              setEnquires(false);
              setratting(false);
              setLeave(false);
              setacc2(false);
              setacc3(false);
              setFAQ(false);
              setExpans(false);
              setacc4(false);
              setacc5(false);
              setform(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setBranch(false);
              setservice(false);
              setbookS(false);
              setvendorSl(false);
            }}
          >
            <span>
              <LuLayoutDashboard style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Dashboard</span>
          </li>
        </Link>
        {!admin?.branchId ? (
          <Link to="/vendor-subadmin">
            <li
              className={`a-ele ${acc1 ? "active-0" : "null"}`}
              onClick={() => {
                setacc(false);
                setacc1(true);
                setacc2(false);
                settimeSlot(false);
                setopening(false);
                setacc3(false);
                setLeave(false);
                setform(false);
                setbookS(false);
                setacc4(false);
                setExpans(false);
                setFAQ(false);
                setratting(false);
                setvendorSl(false);
                setacc5(false);
                setacc6(false);
                setservice(false);
                setEnquires(false);
                setacc7(false);
                setacc8(false);
                setacc9(false);
                setacc10(false);
                setacc11(false);
                setacc12(false);
                setacc13(false);
                setacc14(false);
                setacc15(false);
                setacc16(false);
                setacc17(false);
                setgallery(false);
                setBranch(false);
              }}
            >
              <span>
                <i class="fa-solid fa-user" style={{ fontSize: "20px" }}></i>
              </span>
              <span className="ms-2">SubAdmin</span>
            </li>
          </Link>
        ) : (
          <>
            {admin?.SubAdmin == true ? (
              <Link to="/vendor-subadmin">
                <li
                  className={`a-ele ${acc1 ? "active-0" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(true);
                    setacc2(false);
                    settimeSlot(false);
                    setopening(false);
                    setacc3(false);
                    setLeave(false);
                    setform(false);
                    setbookS(false);
                    setacc4(false);
                    setExpans(false);
                    setFAQ(false);
                    setratting(false);
                    setvendorSl(false);
                    setacc5(false);
                    setacc6(false);
                    setservice(false);
                    setEnquires(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setgallery(false);
                    setBranch(false);
                  }}
                >
                  <span>
                    <i
                      class="fa-solid fa-user"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </span>
                  <span className="ms-2">SubAdmin</span>
                </li>
              </Link>
            ) : (
              <></>
            )}
          </>
        )}

        {!admin?.branchId ? (
          <Link to="/vendor-opening-hours ">
            <li
              className={`a-ele ${opening ? "active-0" : "null"}`}
              onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setratting(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setEnquires(false);
                setbookS(false);
                setacc8(false);
                setFAQ(false);
                setacc9(false);
                setExpans(false);
                setacc10(false);
                settimeSlot(false);
                setLeave(false);
                setopening(true);
                setservice(false);
                setacc11(false);
                setacc12(false);
                setacc13(false);
                setacc14(false);
                setacc15(false);
                setacc16(false);
                setform(false);
                setgallery(false);
                setvendorSl(false);
                setacc17(false);
                setBranch(false);
              }}
            >
              <span>
                <FaClock style={{ fontSize: "20px" }} />
              </span>
              <span className="ms-2">Opening Hours</span>
            </li>
          </Link>
        ) : (
          <>
            {admin?.webMangement == true ? (
              <Link to="/vendor-opening-hours ">
                <li
                  className={`a-ele ${opening ? "active-0" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setratting(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setEnquires(false);
                    setbookS(false);
                    setacc8(false);
                    setFAQ(false);
                    setacc9(false);
                    setExpans(false);
                    setacc10(false);
                    settimeSlot(false);
                    setLeave(false);
                    setopening(true);
                    setservice(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setform(false);
                    setgallery(false);
                    setvendorSl(false);
                    setacc17(false);
                    setBranch(false);
                  }}
                >
                  <span>
                    <FaClock style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Opening Hours</span>
                </li>
              </Link>
            ) : (
              <></>
            )}
          </>
        )}

        {!admin?.branchId ? (
          <Link to="/branch-time-slot">
            <li
              className={`a-ele ${Branch ? "active-0" : "null"}`}
              onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setExpans(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setratting(false);
                setbookS(false);
                setacc8(false);
                setform(false);
                setacc9(false);
                setFAQ(false);
                setacc10(false);
                settimeSlot(false);
                setopening(false);
                setservice(false);
                setacc11(false);
                setEnquires(false);
                setLeave(false);
                setacc12(false);
                setacc13(false);
                setacc14(false);
                setacc15(false);
                setacc16(false);
                setgallery(false);
                setvendorSl(false);
                setacc17(false);
                setBranch(true);
              }}
            >
              <span>
                <FaBusinessTime style={{ fontSize: "20px" }} />
              </span>
              <span className="ms-2">Available Time</span>
            </li>
          </Link>
        ) : (
          <>
            {admin?.Branch == true ? (
              <Link to="/branch-time-slot">
                <li
                  className={`a-ele ${Branch ? "active-0" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setExpans(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setratting(false);
                    setbookS(false);
                    setacc8(false);
                    setform(false);
                    setacc9(false);
                    setFAQ(false);
                    setacc10(false);
                    settimeSlot(false);
                    setopening(false);
                    setservice(false);
                    setacc11(false);
                    setEnquires(false);
                    setLeave(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setgallery(false);
                    setvendorSl(false);
                    setacc17(false);
                    setBranch(true);
                  }}
                >
                  <span>
                    <FaBusinessTime style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Available Time</span>
                </li>
              </Link>
            ) : (
              <></>
            )}
          </>
        )}

{!admin?.branchId ? (
           <Link to="/vendor-add-vechile">
           <li
             className={`a-ele ${acc4 ? "active-0" : "null"}`}
             onClick={() => {
               setacc(false);
               setacc1(false);
               setacc2(false);
               setacc3(false);
               setacc4(true);
               setacc5(false);
               setservice(false);
               setacc6(false);
               setFAQ(false);
               setacc7(false);
               setExpans(false);
               setacc8(false);
               setLeave(false);
               setEnquires(false);
               setacc9(false);
               settimeSlot(false);
               setopening(false);
               setacc10(false);
               setratting(false);
               setbookS(false);
               setacc11(false);
               setacc12(false);
               setacc13(false);
               setform(false);
               setacc14(false);
               setacc15(false);
               setacc16(false);
               setgallery(false);
               setvendorSl(false);
               setBranch(false);
               setacc17(false);
             }}
           >
             <span>
               <i class="fa-solid fa-car" style={{ fontSize: "20px" }}></i>
             </span>{" "}
             <span className="ms-2">Vehicle</span>
           </li>
         </Link>
        ) : (
          <>
            {admin?.Vehicle == true ? (
              <Link to="/vendor-add-vechile">
              <li
                className={`a-ele ${acc4 ? "active-0" : "null"}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(true);
                  setacc5(false);
                  setservice(false);
                  setacc6(false);
                  setFAQ(false);
                  setacc7(false);
                  setExpans(false);
                  setacc8(false);
                  setLeave(false);
                  setEnquires(false);
                  setacc9(false);
                  settimeSlot(false);
                  setopening(false);
                  setacc10(false);
                  setratting(false);
                  setbookS(false);
                  setacc11(false);
                  setacc12(false);
                  setacc13(false);
                  setform(false);
                  setacc14(false);
                  setacc15(false);
                  setacc16(false);
                  setgallery(false);
                  setvendorSl(false);
                  setBranch(false);
                  setacc17(false);
                }}
              >
                <span>
                  <i class="fa-solid fa-car" style={{ fontSize: "20px" }}></i>
                </span>{" "}
                <span className="ms-2">Vehicle</span>
              </li>
            </Link>
            ) : (
              <></>
            )}
          </>
        )}

      
{!admin?.branchId ? (
        <Link to="/vendor-trainner">
        <li
          className={`a-ele ${acc7 ? "active-0" : "null"}`}
          onClick={() => {
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setEnquires(false);
            setservice(false);
            setacc5(false);
            setbookS(false);
            setLeave(false);
            setform(false);
            setratting(false);
            setFAQ(false);
            setacc6(false);
            setacc7(true);
            setacc8(false);
            setExpans(false);
            setacc9(false);
            settimeSlot(false);
            setopening(false);
            setBranch(false);
            setacc10(false);
            setacc11(false);
            setacc12(false);
            setacc13(false);
            setacc14(false);
            setacc15(false);
            setacc16(false);
            setgallery(false);
            setvendorSl(false);
            setacc17(false);
          }}
        >
          <span>
            <i class="fa-solid fa-user" style={{ fontSize: "18px" }}></i>
          </span>
          <span className="ms-2">Trainer</span>
        </li>
      </Link>
        ) : (
          <>
            {admin?.Courses == true ? (
             <Link to="/vendor-trainner">
             <li
               className={`a-ele ${acc7 ? "active-0" : "null"}`}
               onClick={() => {
                 setacc(false);
                 setacc1(false);
                 setacc2(false);
                 setacc3(false);
                 setacc4(false);
                 setEnquires(false);
                 setservice(false);
                 setacc5(false);
                 setbookS(false);
                 setLeave(false);
                 setform(false);
                 setratting(false);
                 setFAQ(false);
                 setacc6(false);
                 setacc7(true);
                 setacc8(false);
                 setExpans(false);
                 setacc9(false);
                 settimeSlot(false);
                 setopening(false);
                 setBranch(false);
                 setacc10(false);
                 setacc11(false);
                 setacc12(false);
                 setacc13(false);
                 setacc14(false);
                 setacc15(false);
                 setacc16(false);
                 setgallery(false);
                 setvendorSl(false);
                 setacc17(false);
               }}
             >
               <span>
                 <i class="fa-solid fa-user" style={{ fontSize: "18px" }}></i>
               </span>
               <span className="ms-2">Trainer</span>
             </li>
           </Link>
            ) : (
              <></>
            )}
          </>
        )}

{!admin?.branchId ? (
          <Link to="/vendor-staff">
          <li
            className={`a-ele ${acc8 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setbookS(false);
              setFAQ(false);
              setEnquires(false);
              setLeave(false);
              setacc7(false);
              setratting(false);
              setform(false);
              setExpans(false);
              setacc8(true);
              settimeSlot(false);
              setopening(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setservice(false);
              setacc14(false);
              setBranch(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setvendorSl(false);
              setacc17(false);
            }}
          >
            <span>
              <FaUsersLine style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Staff</span>
          </li>
        </Link>
        ) : (
          <>
            {admin?.Discount == true ? (
             <Link to="/vendor-staff">
             <li
               className={`a-ele ${acc8 ? "active-0" : "null"}`}
               onClick={() => {
                 setacc(false);
                 setacc1(false);
                 setacc2(false);
                 setacc3(false);
                 setacc4(false);
                 setacc5(false);
                 setacc6(false);
                 setbookS(false);
                 setFAQ(false);
                 setEnquires(false);
                 setLeave(false);
                 setacc7(false);
                 setratting(false);
                 setform(false);
                 setExpans(false);
                 setacc8(true);
                 settimeSlot(false);
                 setopening(false);
                 setacc9(false);
                 setacc10(false);
                 setacc11(false);
                 setacc12(false);
                 setacc13(false);
                 setservice(false);
                 setacc14(false);
                 setBranch(false);
                 setacc15(false);
                 setacc16(false);
                 setgallery(false);
                 setvendorSl(false);
                 setacc17(false);
               }}
             >
               <span>
                 <FaUsersLine style={{ fontSize: "20px" }} />
               </span>
               <span className="ms-2">Staff</span>
             </li>
           </Link>
            ) : (
              <></>
            )}
          </>
        )}

       
{!admin?.branchId ? (
         <Link to="/vendor-blog">
         <li
           className={`a-ele ${acc9 ? "active-0" : "null"}`}
           onClick={() => {
             setacc(false);
             setacc1(false);
             setacc2(false);
             setBranch(false);
             setLeave(false);
             setacc3(false);
             setacc4(false);
             settimeSlot(false);
             setopening(false);
             setservice(false);
             setExpans(false);
             setacc5(false);
             setFAQ(false);
             setbookS(false);
             setEnquires(false);
             setacc6(false);
             setform(false);
             setratting(false);
             setacc7(false);
             setacc8(false);
             setacc9(true);
             setacc10(false);
             setvendorSl(false);
             setacc11(false);
             setacc12(false);
             setacc13(false);
             setacc14(false);
             setacc15(false);
             setacc16(false);
             setgallery(false);
             setacc17(false);
           }}
         >
           <span>
             <i class="fa-solid fa-blog" style={{ fontSize: "20px" }}></i>
           </span>
           <span className="ms-2">Blog</span>
         </li>
       </Link>
        ) : (
          <>
            {admin?.ServicesA == true ? (
            <Link to="/vendor-blog">
            <li
              className={`a-ele ${acc9 ? "active-0" : "null"}`}
              onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setBranch(false);
                setLeave(false);
                setacc3(false);
                setacc4(false);
                settimeSlot(false);
                setopening(false);
                setservice(false);
                setExpans(false);
                setacc5(false);
                setFAQ(false);
                setbookS(false);
                setEnquires(false);
                setacc6(false);
                setform(false);
                setratting(false);
                setacc7(false);
                setacc8(false);
                setacc9(true);
                setacc10(false);
                setvendorSl(false);
                setacc11(false);
                setacc12(false);
                setacc13(false);
                setacc14(false);
                setacc15(false);
                setacc16(false);
                setgallery(false);
                setacc17(false);
              }}
            >
              <span>
                <i class="fa-solid fa-blog" style={{ fontSize: "20px" }}></i>
              </span>
              <span className="ms-2">Blog</span>
            </li>
          </Link>
            ) : (
              <></>
            )}
          </>
        )}

{!admin?.branchId ? (
           <Link to="/vendor-book-course">
           <li
             className={`a-ele ${service == true ? "active-0" : "null"}`}
             onClick={() => {
               setacc(false);
               setacc1(false);
               setacc2(false);
               setBranch(false);
               setservice(true);
               setacc3(false);
               setacc4(false);
               setbookS(false);
               setratting(false);
               setEnquires(false);
               setExpans(false);
               setLeave(false);
               setacc5(false);
               setacc6(false);
               setFAQ(false);
               setacc7(false);
               settimeSlot(false);
               setopening(false);
               setvendorSl(false);
               setacc8(false);
               setform(false);
               setacc9(false);
               setacc10(false);
               setacc11(false);
               setacc12(false);
               setacc13(false);
               setacc14(false);
               setacc15(false);
               setacc16(false);
               setgallery(false);
               setacc17(false);
             }}
           >
             <span>
               <i class="fa-solid fa-check" style={{ fontSize: "20px" }}></i>
             </span>
             <span className="ms-2"> Booked Course</span>
           </li>
         </Link>
        ) : (
          <>
            {admin?.Additional == true ? (
           <Link to="/vendor-book-course">
           <li
             className={`a-ele ${service == true ? "active-0" : "null"}`}
             onClick={() => {
               setacc(false);
               setacc1(false);
               setacc2(false);
               setBranch(false);
               setservice(true);
               setacc3(false);
               setacc4(false);
               setbookS(false);
               setratting(false);
               setEnquires(false);
               setExpans(false);
               setLeave(false);
               setacc5(false);
               setacc6(false);
               setFAQ(false);
               setacc7(false);
               settimeSlot(false);
               setopening(false);
               setvendorSl(false);
               setacc8(false);
               setform(false);
               setacc9(false);
               setacc10(false);
               setacc11(false);
               setacc12(false);
               setacc13(false);
               setacc14(false);
               setacc15(false);
               setacc16(false);
               setgallery(false);
               setacc17(false);
             }}
           >
             <span>
               <i class="fa-solid fa-check" style={{ fontSize: "20px" }}></i>
             </span>
             <span className="ms-2"> Booked Course</span>
           </li>
         </Link>
            ) : (
              <></>
            )}
          </>
        )}

{!admin?.branchId ? (
         <Link to="/vendor-other-book-course">
         <li
           className={`a-ele ${acc17 ? "active-0" : ""}`}
           onClick={() => {
             setacc(false);
             setacc1(false);
             setacc2(false);
             setacc3(false);
             setacc4(false);
             setacc5(false);
             setservice(false);
             setacc6(false);
             setFAQ(false);
             setacc7(false);
             setEnquires(false);
             setacc8(false);
             settimeSlot(false);
             setLeave(false);
             setopening(false);
             setbookS(false);
             setratting(false);
             setExpans(false);
             setacc9(false);
             setform(false);
             setacc10(false);
             setacc11(false);
             setacc12(false);
             setacc13(false);
             setvendorSl(false);
             setacc14(false);
             setacc15(false);
             setBranch(false);
             setacc16(false);
             setacc17(true);
             setgallery(false);
           }}
         >
           <span>
             <i
               class="fas fa-code-branch ms-1"
               style={{ fontSize: "20px" }}
             ></i>
           </span>
           <span className="ms-1">Requested Branch Booked Course</span>
         </li>
       </Link>
        ) : (
          <>
            {admin?.Customer == true ? (
          <Link to="/vendor-other-book-course">
          <li
            className={`a-ele ${acc17 ? "active-0" : ""}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setservice(false);
              setacc6(false);
              setFAQ(false);
              setacc7(false);
              setEnquires(false);
              setacc8(false);
              settimeSlot(false);
              setLeave(false);
              setopening(false);
              setbookS(false);
              setratting(false);
              setExpans(false);
              setacc9(false);
              setform(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setvendorSl(false);
              setacc14(false);
              setacc15(false);
              setBranch(false);
              setacc16(false);
              setacc17(true);
              setgallery(false);
            }}
          >
            <span>
              <i
                class="fas fa-code-branch ms-1"
                style={{ fontSize: "20px" }}
              ></i>
            </span>
            <span className="ms-1">Requested Branch Booked Course</span>
          </li>
        </Link>
            ) : (
              <></>
            )}
          </>
        )}
     
     {!admin?.branchId ? (
          <Link to="/vendor-book-service">
          <li
            className={`a-ele ${acc11 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setExpans(false);
              setacc3(false);
              setBranch(false);
              setacc4(false);
              setservice(false);
              setacc5(false);
              setLeave(false);
              setbookS(false);
              setratting(false);
              setacc6(false);
              setFAQ(false);
              setEnquires(false);
              setacc7(false);
              setacc8(false);
              setform(false);
              setacc9(false);
              setacc10(false);
              setacc11(true);
              setacc12(false);
              settimeSlot(false);
              setopening(false);
              setacc13(false);
              setacc14(false);
              setBranch(false);
              setacc15(false);
              setacc16(false);
              setvendorSl(false);
              setgallery(false);
              setacc17(false);
            }}
          >
            <span>
              <i class="fa-solid fa-check" style={{ fontSize: "20px" }}></i>
            </span>
            <span className="ms-2">Book Services</span>
          </li>
        </Link>
        ) : (
          <>
            {admin?.Employee == true ? (
           <Link to="/vendor-book-service">
           <li
             className={`a-ele ${acc11 ? "active-0" : "null"}`}
             onClick={() => {
               setacc(false);
               setacc1(false);
               setacc2(false);
               setExpans(false);
               setacc3(false);
               setBranch(false);
               setacc4(false);
               setservice(false);
               setacc5(false);
               setLeave(false);
               setbookS(false);
               setratting(false);
               setacc6(false);
               setFAQ(false);
               setEnquires(false);
               setacc7(false);
               setacc8(false);
               setform(false);
               setacc9(false);
               setacc10(false);
               setacc11(true);
               setacc12(false);
               settimeSlot(false);
               setopening(false);
               setacc13(false);
               setacc14(false);
               setBranch(false);
               setacc15(false);
               setacc16(false);
               setvendorSl(false);
               setgallery(false);
               setacc17(false);
             }}
           >
             <span>
               <i class="fa-solid fa-check" style={{ fontSize: "20px" }}></i>
             </span>
             <span className="ms-2">Book Services</span>
           </li>
         </Link>
            ) : (
              <></>
            )}
          </>
        )}


       
      
    
     

{!admin?.branchId ? (
       <Link to="">
       <li
         className={`a-ele ${acc1 ? "active-0" : "null"}`}
         onClick={() => {
           setRto(!Rto);

           setvendorSl(false);
           setacc(false);
           setacc1(false);
           setacc2(false);
           setacc3(false);
           setacc4(false);
           setacc5(false);
           setacc6(false);
         }}
       >
         <span>
           {/* <FontAwesomeIcon icon={faCar} /> */}
           <i class="fa-solid fa-taxi" style={{ fontSize: "18px" }}></i>
         </span>{" "}
         <span>RTO Management </span>{" "}
         {Rto ? (
           <>
             {" "}
             <span style={{ float: "right" }}>
               <MdOutlineKeyboardArrowLeft />
             </span>
           </>
         ) : (
           <>
             <span style={{ float: "right" }}>
               <MdOutlineKeyboardArrowDown />
             </span>
           </>
         )}
       </li>
     </Link>
        ) : (
          <>
            {admin?.RTO == true ? (
           <Link to="">
           <li
             className={`a-ele ${acc1 ? "active-0" : "null"}`}
             onClick={() => {
               setRto(!Rto);

               setvendorSl(false);
               setacc(false);
               setacc1(false);
               setacc2(false);
               setacc3(false);
               setacc4(false);
               setacc5(false);
               setacc6(false);
             }}
           >
             <span>
               {/* <FontAwesomeIcon icon={faCar} /> */}
               <i class="fa-solid fa-taxi" style={{ fontSize: "18px" }}></i>
             </span>{" "}
             <span>RTO Management </span>{" "}
             {Rto ? (
               <>
                 {" "}
                 <span style={{ float: "right" }}>
                   <MdOutlineKeyboardArrowLeft />
                 </span>
               </>
             ) : (
               <>
                 <span style={{ float: "right" }}>
                   <MdOutlineKeyboardArrowDown />
                 </span>
               </>
             )}
           </li>
         </Link>
            ) : (
              <></>
            )}
          </>
        )}
        {admin ? (
          <Link to="">
            {Rto ? (
              <>
                <div className="webmanagement">
                  <Link to="/branch-Rto-List">
                    <li
                      className={`a-ele ${acc25 ? "active2" : "null"}`}
                      onClick={() => {
                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                        setacc5(false);
                        setacc6(false);
                        setacc7(false);
                        setacc8(false);
                        setacc9(false);
                        setacc10(false);
                        setacc11(false);
                        setacc12(false);
                        setacc13(false);
                        setacc14(false);
                        setacc15(false);
                        setacc16(false);
                        setacc17(false);

                        setacc25(true);
                        setacc26(false);
                        setacc27(false);
                        setacc40(false);
                        setacc28(false);
                        setacc29(false);
                      }}
                    >
                      <span>
                        <i
                          class="fa-solid fa-list-ul"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </span>
                      <span className="ms-2">RTO List</span>
                    </li>
                  </Link>
                  <Link to="">
                    <li
                      className={`a-ele ${acc1 ? "active-0" : "null"}`}
                      onClick={() => {
                        setRtoApp(!RtoApp);

                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                        setacc5(false);
                        setacc6(false);
                      }}
                    >
                      <span>
                        {" "}
                        <i
                          class="fa fa-arrows"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </span>
                      <span className="ms-2">RTO Application </span>{" "}
                      {RtoApp ? (
                        <>
                          {" "}
                          <span style={{ float: "right" }}>
                            <MdOutlineKeyboardArrowLeft />
                          </span>
                        </>
                      ) : (
                        <>
                          <span style={{ float: "right" }}>
                            <MdOutlineKeyboardArrowDown />
                          </span>
                        </>
                      )}
                    </li>
                  </Link>
                  <Link to="">
                    {RtoApp ? (
                      <div className="webmanagement1">
                        <Link to="/branch-LL-Application">
                          <li
                            className={`a-ele ${acc26 ? "active2" : "null"}`}
                            onClick={() => {
                              setacc(false);
                              setacc1(false);
                              setacc2(false);
                              setacc3(false);
                              setacc4(false);
                              setacc5(false);
                              setacc6(false);
                              setacc7(false);
                              setacc8(false);
                              setacc9(false);
                              setacc10(false);
                              setacc11(false);
                              setacc12(false);
                              setacc13(false);
                              setacc14(false);
                              setacc15(false);
                              setacc16(false);
                              setacc17(false);

                              setacc25(false);
                              setacc26(true);
                              setacc27(false);
                              setacc40(false);
                              setacc28(false);
                              setacc29(false);
                            }}
                          >
                            <span>
                              <i
                                class="fa-solid fa-id-card"
                                style={{ fontSize: "18px" }}
                              ></i>
                            </span>
                            <span className="ms-2">L L Application</span>
                          </li>
                        </Link>
                        <Link to="/branch-DL-Application">
                          <li
                            className={`a-ele ${acc27 ? "active2" : "null"}`}
                            onClick={() => {
                              setacc(false);
                              setacc1(false);
                              setacc2(false);
                              setacc3(false);
                              setacc4(false);
                              setacc5(false);
                              setacc6(false);
                              setacc7(false);
                              setacc8(false);
                              setacc9(false);
                              setacc10(false);
                              setacc11(false);
                              setacc12(false);
                              setacc13(false);
                              setacc14(false);
                              setacc15(false);
                              setacc16(false);
                              setacc17(false);

                              setacc25(false);
                              setacc26(false);
                              setacc27(true);
                              setacc40(false);
                              setacc28(false);
                              setacc29(false);
                            }}
                          >
                            <span>
                              <i
                                class="fa-solid fa-id-badge"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </span>
                            <span className="ms-2">D L Application</span>
                          </li>
                        </Link>
                        <Link to="/branch-Other-Service">
                          <li
                            className={`a-ele ${acc40 ? "active2" : "null"}`}
                            onClick={() => {
                              setacc(false);
                              setacc1(false);
                              setacc2(false);
                              setacc3(false);
                              setacc4(false);
                              setacc5(false);
                              setacc6(false);
                              setacc7(false);
                              setacc8(false);
                              setacc9(false);
                              setacc10(false);
                              setacc11(false);
                              setacc12(false);
                              setacc13(false);
                              setacc14(false);
                              setacc15(false);
                              setacc16(false);
                              setacc17(false);

                              setacc25(false);
                              setacc26(false);
                              setacc27(false);
                              setacc40(true);
                              setacc28(false);
                              setacc29(false);
                            }}
                          >
                            <span>
                              <i
                                class="fa-solid fa-id-badge"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </span>
                            <span className="ms-2">Other Applications</span>
                          </li>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </Link>
                  <Link to="/branch-Rto-Websit-Payment">
                    <li
                      className={`a-ele ${acc28 ? "active2" : "null"}`}
                      onClick={() => {
                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                        setacc5(false);
                        setacc6(false);
                        setacc7(false);
                        setacc8(false);
                        setacc9(false);
                        setacc10(false);
                        setacc11(false);
                        setacc12(false);
                        setacc13(false);
                        setacc14(false);
                        setacc15(false);
                        setacc16(false);
                        setacc17(false);

                        setacc25(false);
                        setacc26(false);
                        setacc27(false);
                        setacc40(false);
                        setacc28(true);
                        setacc29(false);
                      }}
                    >
                      <span>
                        <i
                          class="fa-solid fa-money-bill"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </span>
                      <span className="ms-2">RTO Website Payment</span>
                    </li>
                  </Link>
                  <Link to="/branch-Rto-CFPayment">
                    <li
                      className={`a-ele ${acc29 ? "active2" : "null"}`}
                      onClick={() => {
                        setacc(false);
                        setacc1(false);
                        setacc2(false);
                        setacc3(false);
                        setacc4(false);
                        setacc5(false);
                        setacc6(false);
                        setacc7(false);
                        setacc8(false);
                        setacc9(false);
                        setacc10(false);
                        setacc11(false);
                        setacc12(false);
                        setacc13(false);
                        setacc14(false);
                        setacc15(false);
                        setacc16(false);
                        setacc17(false);

                        setacc25(false);
                        setacc26(false);
                        setacc27(false);
                        setacc40(false);
                        setacc28(false);
                        setacc29(true);
                      }}
                    >
                      <span>
                        <i
                          class="fa-solid fa-money-check"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </span>
                      <span className="ms-2">RTO CF Payment</span>
                    </li>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
          </Link>
        ) : (
          <></>
        )}


{!admin?.branchId ? (
      <Link to="/branch-One-Day-Course">
      <li
        className={`a-ele ${ratting ? "active2-0" : "null"}`}
        onClick={() => {
          setacc(false);
          setacc1(false);
          setacc2(false);
          setEnquires(false);
          setform(false);
          setbookS(false);
          setvendorSl(false);
          setacc3(false);
          setratting(true);
          setacc4(false);
          setacc5(false);
          setacc6(false);
          setExpans(false);
          setacc7(false);
          setFAQ(false);
          setBranch(false);
          setacc8(false);
          setacc9(false);
          setacc10(false);
          settimeSlot(false);
          setopening(false);
          setLeave(false);
          setservice(false);
          setacc11(false);
          setacc12(false);
          setacc13(false);
          setacc14(false);
          setacc15(false);
          setacc16(false);
          setgallery(false);
          setacc17(false);
        }}
      >
        <span>
          <LuLayoutDashboard style={{ fontSize: "20px" }} />
        </span>
        <span className="ms-2">One Day Course </span>
      </li>
    </Link>
        ) : (
          <>
            {admin?.Blog == true ? (
          <Link to="/branch-One-Day-Course">
          <li
            className={`a-ele ${ratting ? "active2-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setEnquires(false);
              setform(false);
              setbookS(false);
              setvendorSl(false);
              setacc3(false);
              setratting(true);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setExpans(false);
              setacc7(false);
              setFAQ(false);
              setBranch(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              settimeSlot(false);
              setopening(false);
              setLeave(false);
              setservice(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
            }}
          >
            <span>
              <LuLayoutDashboard style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">One Day Course </span>
          </li>
        </Link>
            ) : (
              <></>
            )}
          </>
        )}

      
       {!admin?.branchId ? (
       <Link to="">
       <li
         className={`a-ele ${acc1 ? "active-0" : "null"}`}
         onClick={() => {
           setform(!from);
           setRto(false)
           setvendorSl(false);
           setacc(false);
           setacc1(false);
           setacc2(false);
           setacc3(false);
           setacc4(false);
           setacc5(false);
           setacc6(false);
         }}
       >
         <span>
           {/* <FontAwesomeIcon icon={faCar} /> */}
           <i class="fa-solid fa-file" style={{ fontSize: "18px" }}></i>
         </span>{" "}
         <span>Form Management </span>{" "}
         {from ? (
           <>
             {" "}
             <span style={{ float: "right" }}>
               <MdOutlineKeyboardArrowLeft />
             </span>
           </>
         ) : (
           <>
             <span style={{ float: "right" }}>
               <MdOutlineKeyboardArrowDown />
             </span>
           </>
         )}
       </li>
     </Link>
        ) : (
          <>
            {admin?.FormMangament == true ? (
           <Link to="">
           <li
             className={`a-ele ${acc1 ? "active-0" : "null"}`}
             onClick={() => {
              setform(!from);
              setRto(false)
               setvendorSl(false);
               setacc(false);
               setacc1(false);
               setacc2(false);
               setacc3(false);
               setacc4(false);
               setacc5(false);
               setacc6(false);
             }}
           >
             <span>
               {/* <FontAwesomeIcon icon={faCar} /> */}
               <i class="fa-solid fa-file" style={{ fontSize: "18px" }}></i>
             </span>{" "}
             <span>Form Management </span>{" "}
             {from ? (
               <>
                 {" "}
                 <span style={{ float: "right" }}>
                   <MdOutlineKeyboardArrowLeft />
                 </span>
               </>
             ) : (
               <>
                 <span style={{ float: "right" }}>
                   <MdOutlineKeyboardArrowDown />
                 </span>
               </>
             )}
           </li>
         </Link>
            ) : (
              <></>
            )}
          </>
        )}
        <Link to="">
          {from ? (  <div className="webmanagement">
              <Link to="/branch-form5">
          <li
            className={`a-ele ${acc12 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setBranch(false);
              setacc4(false);
              setratting(false);
              setacc5(false);
              setacc6(false);
           
              setacc7(false);
              setacc8(false);
              setEnquires(false);
              setExpans(false);
              setacc9(false);
              setacc10(false);
              setservice(false);
              setvendorSl(false);
              setacc11(false);
              setFAQ(false);
              setacc12(true);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setLeave(false);
              setacc16(false);
              setgallery(false);
              settimeSlot(false);
              setopening(false);
              setacc17(false);
              setbookS(false);
            }}
          >
            <GrDocumentPerformance style={{ color: "#89131a" }} /> Form 5
          </li>
        </Link>
        <Link to="/branch-form14">
          <li
            className={`a-ele ${acc13 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setBranch(false);
              setacc4(false);
              setratting(false);
              setacc5(false);
              setacc6(false);
          
              setacc7(false);
              setacc8(false);
              setEnquires(false);
              setExpans(false);
              setacc9(false);
              setacc10(false);
              setservice(false);
              setacc11(false);
              setFAQ(false);
              setacc12(false);
              setacc13(true);
              setacc14(false);
              setacc15(false);
              setvendorSl(false);
              setLeave(false);
              setacc16(false);
              setgallery(false);
              settimeSlot(false);
              setopening(false);
              setacc17(false);
              setbookS(false);
            }}
          >
            <GrDocumentPerformance style={{ color: "#89131a" }} /> Form 14
          </li>
        </Link>
        <Link to="/branch-form15">
          {" "}
          <li
            className={`a-ele ${acc15 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
            
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setExpans(false);
              setacc14(false);
              setacc15(true);
              setacc16(false);
            }}
          >
            {" "}
            <GrDocumentPerformance style={{ color: "#89131a" }} /> Form 15
          </li>
        </Link>
          </div>):(<></>)}
        </Link>
      

        {!admin?.branchId ? (
         <Link to="/vendor-enquire">
         <li
           className={`a-ele ${acc5 ? "active-0" : "null"}`}
           onClick={() => {
             setacc(false);
             setacc1(false);
             setacc2(false);
             setacc3(false);
             setacc4(false);
             setEnquires(false);
             setbookS(false);
             setLeave(false);
             setacc5(true);
             setBranch(false);
             settimeSlot(false);
             setopening(false);
             setform(false);
             setratting(false);
             setacc6(false);
             setservice(false);
             setvendorSl(false);
             setExpans(false);
             setacc7(false);
             setFAQ(false);
             setacc8(false);
             setacc9(false);
             setacc10(false);
             setacc11(false);
             setacc12(false);
             setacc13(false);
             setacc14(false);
             setacc15(false);
             setacc16(false);
             setgallery(false);
             setacc17(false);
           }}
         >
           <span>
             <i
               class="fa fa-question-circle"
               aria-hidden="true"
               style={{ fontSize: "18px" }}
             ></i>
           </span>
           <span className="ms-2">Enquire</span>
         </li>
       </Link>
        ) : (
          <>
            {admin?.Enquiry == true ? (
           <Link to="/vendor-enquire">
           <li
             className={`a-ele ${acc5 ? "active-0" : "null"}`}
             onClick={() => {
               setacc(false);
               setacc1(false);
               setacc2(false);
               setacc3(false);
               setacc4(false);
               setEnquires(false);
               setbookS(false);
               setLeave(false);
               setacc5(true);
               setBranch(false);
               settimeSlot(false);
               setopening(false);
               setform(false);
               setratting(false);
               setacc6(false);
               setservice(false);
               setvendorSl(false);
               setExpans(false);
               setacc7(false);
               setFAQ(false);
               setacc8(false);
               setacc9(false);
               setacc10(false);
               setacc11(false);
               setacc12(false);
               setacc13(false);
               setacc14(false);
               setacc15(false);
               setacc16(false);
               setgallery(false);
               setacc17(false);
             }}
           >
             <span>
               <i
                 class="fa fa-question-circle"
                 aria-hidden="true"
                 style={{ fontSize: "18px" }}
               ></i>
             </span>
             <span className="ms-2">Enquire</span>
           </li>
         </Link>
            ) : (
              <></>
            )}
          </>
        )}

{!admin?.branchId ? (
       <Link to="/vendor-expanse">
       {" "}
       <li
         className={`a-ele ${Expans ? "active-0" : "null"}`}
         onClick={() => {
           setacc(false);
           setacc1(false);
           setacc2(false);
           setacc3(false);
           setacc4(false);
           setacc5(false);
           setFAQ(false);
           setacc6(false);
           setacc7(false);
           setservice(false);
           setExpans(true);
           setacc8(false);
           setratting(false);
           setvendorSl(false);
           settimeSlot(false);
           setopening(false);
           setEnquires(false);
           setacc9(false);
           setacc10(false);
           setacc11(false);
           setBranch(false);
           setacc12(false);
           setLeave(false);
           setform(false);
           setacc13(false);
           setbookS(false);
           setacc14(false);
           setacc15(false);
           setacc16(false);
           setgallery(false);
           setacc17(false);
         }}
       >
         <GiFlatPlatform /> Expanse
       </li>
     </Link>
        ) : (
          <>
            {admin?.expense == true ? (
           <Link to="/vendor-expanse">
           {" "}
           <li
             className={`a-ele ${Expans ? "active-0" : "null"}`}
             onClick={() => {
               setacc(false);
               setacc1(false);
               setacc2(false);
               setacc3(false);
               setacc4(false);
               setacc5(false);
               setFAQ(false);
               setacc6(false);
               setacc7(false);
               setservice(false);
               setExpans(true);
               setacc8(false);
               setratting(false);
               setvendorSl(false);
               settimeSlot(false);
               setopening(false);
               setEnquires(false);
               setacc9(false);
               setacc10(false);
               setacc11(false);
               setBranch(false);
               setacc12(false);
               setLeave(false);
               setform(false);
               setacc13(false);
               setbookS(false);
               setacc14(false);
               setacc15(false);
               setacc16(false);
               setgallery(false);
               setacc17(false);
             }}
           >
             <GiFlatPlatform /> Expanse
           </li>
         </Link>
            ) : (
              <></>
            )}
          </>
        )}

{!admin?.branchId ? (
         <Link to="/branch-genrate-recipt">
         {" "}
         <li
           className={`a-ele ${vendorSl ? "active-0" : "null"}`}
           onClick={() => {
             setacc(false);
             setacc1(false);
             setacc2(false);
             setacc3(false);
             setacc4(false);
             setacc5(false);
             setFAQ(false);
             setacc6(false);
             setacc7(false);
             setservice(false);
             setExpans(false);
             setacc8(false);
             setratting(false);
             setvendorSl(true);
             settimeSlot(false);
             setopening(false);
             setEnquires(false);
             setacc9(false);
             setacc10(false);
             setacc11(false);
             setBranch(false);
             setacc12(false);
             setLeave(false);
             setform(false);
             setacc13(false);
             setbookS(false);
             setacc14(false);
             setacc15(false);
             setacc16(false);
             setgallery(false);
             setacc17(false);
           }}
         >
           {" "}
           <span>
             <LuLayoutDashboard style={{ fontSize: "20px" }} />
           </span>{" "}
           Generate Recipt
         </li>
       </Link>
        ) : (
          <>
            {admin?.GenerateRecipt == true ? (
        <Link to="/branch-genrate-recipt">
        {" "}
        <li
          className={`a-ele ${vendorSl ? "active-0" : "null"}`}
          onClick={() => {
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setFAQ(false);
            setacc6(false);
            setacc7(false);
            setservice(false);
            setExpans(false);
            setacc8(false);
            setratting(false);
            setvendorSl(true);
            settimeSlot(false);
            setopening(false);
            setEnquires(false);
            setacc9(false);
            setacc10(false);
            setacc11(false);
            setBranch(false);
            setacc12(false);
            setLeave(false);
            setform(false);
            setacc13(false);
            setbookS(false);
            setacc14(false);
            setacc15(false);
            setacc16(false);
            setgallery(false);
            setacc17(false);
          }}
        >
          {" "}
          <span>
            <LuLayoutDashboard style={{ fontSize: "20px" }} />
          </span>{" "}
          Generate Recipt
        </li>
      </Link>
            ) : (
              <></>
            )}
          </>
        )}
     
     {!admin?.branchId ? (
         <Link to="/branch-account-history">
         <li
           className={`a-ele ${acc14 ? "active-0" : "null"}`}
           onClick={() => {
             setacc(false);
             setacc1(false);
             setacc2(false);
             setacc3(false);
             setacc4(false);
             setacc5(false);
             setacc6(false);
             setacc7(false);
             setacc8(false);
             setExpans(false);
             setform(false);
             setbookS(false);
             setEnquires(false);
             setacc9(false);
             setacc10(false);
             setvendorSl(false);
             setacc11(false);
             setratting(false);
             setFAQ(false);
             setacc12(false);
             setservice(false);
             setBranch(false);
             setacc13(false);
             settimeSlot(false);
             setopening(false);
             setacc14(true);
             setacc15(false);
             setLeave(false);
             setacc16(false);
             setgallery(false);
             setacc17(false);
           }}
         >
           <MdAccountBalance /> Account History
         </li>
       </Link>
        ) : (
          <>
            {admin?.account == true ? (
        <Link to="/branch-account-history">
        <li
          className={`a-ele ${acc14 ? "active-0" : "null"}`}
          onClick={() => {
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setacc6(false);
            setacc7(false);
            setacc8(false);
            setExpans(false);
            setform(false);
            setbookS(false);
            setEnquires(false);
            setacc9(false);
            setacc10(false);
            setvendorSl(false);
            setacc11(false);
            setratting(false);
            setFAQ(false);
            setacc12(false);
            setservice(false);
            setBranch(false);
            setacc13(false);
            settimeSlot(false);
            setopening(false);
            setacc14(true);
            setacc15(false);
            setLeave(false);
            setacc16(false);
            setgallery(false);
            setacc17(false);
          }}
        >
          <MdAccountBalance /> Account History
        </li>
      </Link>
            ) : (
              <></>
            )}
          </>
        )}
       
      </ul>
    </div>
  );
}

export default VendorSidebar;
