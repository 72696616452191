import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { BiEdit, BiUserCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";

const VendorStaff = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [reason, setreason] = useState("");

  const [show1, setShow1] = useState(false);
  const [holdid, setholdid] = useState("");
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");
  const [AllTrainer, setAlltrainer] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getTrainer = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      const config = {
        url: "/admin/staff/getAllStaffByBranchId/"+ab,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getTrainer();
    }
  }, []);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllTrainer.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAlltrainer([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAlltrainer([...nochangedata]);
    }
  };

  const makeBlockUnblockCustomer = async (id, block) => {
    try {
      const config = {
        url: "/admin/staff/makeBlockUnblockStaff",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          id: id,
          isBlocked: block,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        getTrainer();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const makeApprovedAndHold = async (id, status, reason) => {
    try {
      if (status == "Hold") {
        if (!reason) return alert("Please enter reason");
      }
      const config = {
        url: "/admin/staff/ApprovedAndHoldStaff",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          id: id,
          status: status,
          reason: reason,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        setholdid("");
        getTrainer();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [allBranches, setallBranches] = useState([]);
  const getAllBranch = async () => {
    try {
      let res = await axios(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setallBranches(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allcity, setallCity] = useState([]);

  const getAllCity = async () => {
    try {
      let res = await axios("https://jaidrivingschool.in/api/admin/getAllCity");
      if (res.status == 200) {
        setallCity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBranch();
    getAllCity();
  }, []);
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [Password, setPassword] = useState("");
  const [cPassword, setcPassword] = useState("");
  const [profile, setprofile] = useState("");
  const [city, setcity] = useState("");
  const [area, setarea] = useState("");
  const [address,setaddress]=useState("");
  const [availableSlot, setavailableSlot] = useState([]);
  const [time, settime] = useState("");
  const [branch, setbranch] = useState(admin);
  const addtime = () => {
    if (!time) return alert("Please select time");
    availableSlot.push({ times: time });
    settime("");
    return alert("success");
  };

  const AddTrainer = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      // if(!Password) return alert("Please enter password");
      // if(!cPassword) return alert("Please enter confirm password");
      // if(Password!==cPassword) return alert("Confirm password dose not match");

      const config = {
        url: "/admin/staff/Add-Staff",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          name: name,
          mobile: mobile,
          email: email,
          Password: Password,
          profile: profile,
          city: city,
          Address: area,
          branchId: ab,
          branchName:  `${admin?.branchName},${admin?.area}`,
           
          availableSlot: availableSlot,
          addedBy: "Branch",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        getTrainer();
        handleClose();
        setname("");
        setemail("");
        setmobile("");
        setprofile("");
        setPassword("");
        setcity("");
        setarea("");
        setavailableSlot([]);
     
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [editdata, seteditdata] = useState({});
  const [editShow, seteditShow] = useState(false);
  const showEdit = (data) => {
    seteditShow(true);
  };
  const closeEdit = () => seteditShow(false);

  const updateTrainer = async () => {
    try {


      const config = {
        url: "/admin/staff/updateStaff",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          userId: editdata?._id,
          name: name,
          mobile: mobile,
          email: email,
          Password: Password,
          profile: profile,
          city: city,
          Address: area,
        
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        getTrainer();
        closeEdit();
        setname("");
        setemail("");
        setmobile("");
        setprofile("");
        setPassword("");
        setcity("");
        setarea("");
        setavailableSlot([]);
     
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

 
    return (
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button>Staff Details</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button
                    style={{ borderRadius: "10px", float: "right" }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>StaffId</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Email</th>
                    <th>City</th>
                    {/* <th>Branch</th> */}
                    <th>Address</th>
                    <th>Block Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {AllTrainer?.slice(
                    pagesVisited,
                    pagesVisited + usersPerPage
                  )?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.StaffId}</td>
                        <td>
                          {item?.profile ? (
                            <img
                              src={`https://jaidrivingschool.in/staff/${item?.profile}`}
                              alt=""
                              width={60}
                            />
                          ) : (
                            <BiUserCircle
                              style={{
                                color: "rgb(252 202 11)",
                                fontSize: "40px",
                              }}
                            />
                          )}
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>{item?.city}</td>
                        {/* <td>{item?.branchName}</td> */}
                        <td>{item?.Address}</td>
                        <td>
                          {item?.isBlocked == true ? (
                            <span style={{ color: "red" }}>Blocked</span>
                          ) : (
                            <span style={{ color: "green" }}>Un-Blocked</span>
                          )}
                        </td>
                        {/* <td>
                   {item?.status!=="Approved" ? (<span style={{color:"red"}}>{item?.status} {item?.reason}</span>):(<span style={{color:"green"}}>{item?.status}</span>)}
                  </td> */}
                        <td style={{ display: "flex", gap: "3px" }}>
                          <BiEdit
                            style={{
                              color: "blue",
                              fontSize: "36px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              seteditdata(item);
                              showEdit();
                            }}
                          />
                          {item?.isBlocked == true ? (
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() =>
                                makeBlockUnblockCustomer(item?._id, false)
                              }
                            >
                              Un-Block
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() =>
                                makeBlockUnblockCustomer(item?._id, true)
                              }
                            >
                              Block
                            </button>
                          )}{" "}
                         
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="File"
                placeholder="Enter Userid"
                className="vi_0"
                onChange={(e) => setprofile(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Name</label>
              <input
                type="text"
                placeholder="Enter Name"
                className="vi_0"
                onChange={(e) => setname(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Mobile</label>
              <input
                type="text"
                maxLength={10}
                placeholder="Enter mobile number"
                className="vi_0"
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter email id"
                className="vi_0"
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>City</label>
              <select
                className="vi_0"
                onChange={(e) => setcity(e.target.value)}
              >
                <option value="">Select</option>
                {allcity?.map((item) => {
                  return <option value={item?.city}>{item?.city}</option>;
                })}
              </select>
            </div>
         
            <div className="do-sear mt-2">
              <label>Address</label>
              <input
                type="text"
                placeholder="Enter address"
                className="vi_0"
                value={area}
                onChange={(e) => setarea(e.target.value)}
              />
            </div>
            {/* <div className="do-sear mt-2">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter password"
                className="vi_0"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Confirm Password</label>
              <input
                type="text"
                placeholder="Enter confirm password"
                className="vi_0"
                onChange={(e) => setcPassword(e.target.value)}
              />
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AddTrainer}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={editShow}
          onHide={closeEdit}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Image</label>
              <input
                type="File"
                placeholder="Enter Userid"
                className="vi_0"
                onChange={(e) => setprofile(e.target.files[0])}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Name</label>
              <input
                type="text"
                placeholder={editdata?.name}
                className="vi_0"
                onChange={(e) => setname(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Mobile</label>
              <input
                type="text"
                maxLength={10}
                placeholder={editdata?.mobile}
                className="vi_0"
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Email</label>
              <input
                type="email"
                placeholder={editdata?.email}
                className="vi_0"
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>City</label>
              <select
                className="vi_0"
                onChange={(e) => setcity(e.target.value)}
              >
                <option value="">
                  {editdata?.city ? editdata?.city : "Select"}
                </option>
                {allcity?.map((item) => {
                  return <option value={item?.city}>{item?.city}</option>;
                })}
              </select>
            </div>
        
            <div className="do-sear mt-2">
              <label>Address</label>
              <input
                type="text"
                placeholder={editdata?.Address}
                className="vi_0"
                onChange={(e) => setarea(e.target.value)}
              />
            </div>
            {/* <div className="do-sear mt-2">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter password"
                className="vi_0"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Confirm Password</label>
              <input
                type="text"
                placeholder="Enter confirm password"
                className="vi_0"
                onChange={(e) => setcPassword(e.target.value)}
              />
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEdit}>
              Close
            </Button>
            <Button variant="primary" onClick={updateTrainer}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Hold Reasion */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hold Trainer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Reason</label>
            <input
              type="text"
              placeholder="Please enter reason"
              className="vi_0"
              onChange={(e) => setreason(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => makeApprovedAndHold(holdid, "Hold", reason)}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
};

export default VendorStaff;
