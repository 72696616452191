import React from "react";
import Side from "./sidebar/Side";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const StaffIdCard = () => {
    const createPDF = async () => {
        // setRotate(360);

        // dynamic image is also adding in the PDF
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.getElementById("pdf"), {
            useCORS: true,
        });
        console.log("data", data);
        const img = data.toDataURL("image/png");
        console.log("img", img);
        const imgProperties = pdf.getImageProperties(img);
        console.log("imgProperties", imgProperties);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        console.log("pdfWidth", pdfWidth);
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        console.log("pdfHeight", pdfHeight);
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("InstructorId_Card.pdf");
    };
    const navigate = useNavigate();
    const staff = JSON.parse(sessionStorage.getItem("staff"));

    if (!staff) {
        alert("Please login");
        window.location.assign("/");
    }
    let data = new Date(staff?.updatedAt);
    function addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }

    let adedDate = addOneYear(data)
    return (
        <div>
            <Side />
            <div className="row">
                <div className="col-md-12">
                    <button className="btn btn-warning" style={{ float: "right" }} onClick={createPDF}>
                        Download{" "}
                        <i
                            class="fa-solid fa-file-arrow-down "
                            style={{ marginLeft: "10px" }}
                        ></i>
                    </button>
                </div>
            </div>
            <div className="container p-2">
                <div
                    style={{
                        height: "500px",
                        width: "100%",
                    }}
                    id="pdf"
                >
                    <div className="container p-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div
                                    style={{
                                        boxShadow: " 0px 8px 32px 0px rgba(19, 19, 20, 0.37)",
                                        background: "#f5f6fa",
                                        backdropFilter: "blur(4px)",
                                        height: "100%",
                                        width: "400px",
                                        float: "right",
                                    }}
                                >
                                    {" "}
                                    <div
                                        style={{
                                            // height: "165px",
                                            // width: "300px",
                                            margin: "auto",
                                        }}
                                    >
                                        {" "}
                                        <img
                                            src="/images/logo1.png.png"
                                            alt=""
                                            style={{
                                                height: "122px",
                                                marginLeft: "30%",
                                                marginTop: "10px",
                                            }}

                                        />
                                    </div>{" "}
                                    <div>
                                        {staff?.profile ? (
                                            <img
                                                src={`https://jaidrivingschool.in/Staff/${staff?.profile}`}
                                                alt=""
                                                style={{
                                                    border: "5px solid rgb(157, 4, 27)",
                                                    width: "150px",
                                                    marginLeft: "30%",
                                                    padding: "2px",
                                                    marginTop: "10px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src="./images/person1.jpg"
                                                alt="Admin"
                                                className="rounded-circle"
                                                width="150"
                                                height="150px"
                                            />
                                        )}

                                    </div>
                                    <div className="text-center">
                                        <h3>{staff?.name}</h3>
                                        <p>Instructor</p>{" "}
                                    </div>
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            color: " rgb(157, 4, 27)",
                                            // paddingLeft: "50px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {" "}
                                        www.jaidrivingschool.com
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div
                                    style={{
                                        boxShadow: " 0px 8px 32px 0px rgba(19, 19, 20, 0.37)",
                                        background: "#f5f6fa",
                                        backdropFilter: "blur(4px)",
                                        height: "100%",
                                        width: "400px",
                                    }}
                                >
                                    <div>
                                        <h3
                                            style={{
                                                color: "rgb(157, 4, 27)",
                                                fontSize: "19px",
                                                textAlign: "center",
                                                paddingTop: "10px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {" "}
                                            {staff?.branchName}
                                        </h3>
                                    </div>
                                    <div className="ps-4 text-center">
                                        <p style={{ fontSize: "13px" }}>
                                            {/* <span>{staff?.branchName}</span> <br /> */}
                                            <span>{staff?.Address}</span>
                                        </p>
                                    </div>
                                    <div className="container ">
                                        <div className="row">
                                            <div className="col-md-12 text-justify">
                                                <span style={{ color: "rgb(157, 4, 27)" }}>Id: </span> <span>{staff?.staffId}</span> <br />
                                                <span style={{ color: "rgb(157, 4, 27)" }}>Mob:</span> <span>{staff?.mobile}</span> <br />
                                                <span style={{ color: "rgb(157, 4, 27)" }}>
                                                    Dob:
                                                </span>{" "}
                                                <span>{moment(staff?.dob).format("DD/MM/YYYY")}</span>{" "}
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            color: "rgb(157, 4, 27)",
                                        }}
                                    >
                                        {" "}
                                        <div
                                            style={{
                                                marginTop: "40px",
                                                width: "100%",
                                            }}
                                        >
                                            <span
                                                style={{ display: "flex", justifyContent: "center" }}
                                            >
                                                Authorised Signatory
                                            </span>
                                            <p style={{ paddingTop: "20px" }}>
                                                <span> Note:</span> <br />
                                                <div className="container">
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div>
                                                            <span className="dnjjj_0">
                                                                <ul>
                                                                    <li>This Card is not transferable</li>
                                                                    <li>
                                                                        This card does not have any monitory value
                                                                        and valued only for identification purpose
                                                                        only
                                                                    </li>
                                                                    <li>
                                                                        If found please returns to companies address{" "}
                                                                    </li>
                                                                </ul>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div

                                                            >
                                                                <p className="block" style={{ textAlign: "center", fontSize: "10px" }}><span>Expiry Date Of ID Card</span> <br /> {moment(adedDate).format("DD/MM/YYYY")}</p>


                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffIdCard;
