import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import exportFromJSON from "export-from-json";
import moment from "moment";
import ReactPaginate from "react-paginate";

const AdminLLAplication = () => {
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [makeActive, setMakeActive] = useState(true);
  const [AllApplication, setAllApplication] = useState(true);
  const [LLApplication, SetLLApplication] = useState(false);
  const [Reslot, setReslot] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const [data, setdata] = useState([]);
  const [noschangedata, setnochangedata] = useState([]);

  const [fileName, setfileName] = useState("LL Application");
  const exportType = "xls";
  const ExportToExcel = () => {
      exportFromJSON({ data, fileName, exportType });
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      console.log(
        "amit check it",
        moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
      );
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setAllrtoll(abc);
    setdata(abc);
    setfileName(
      `LL Application_${moment(fromd).format("DD/MM/YYYY")} to ${moment(
        tod
      ).format("DD/MM/YYYY")}`
    );
  };
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = noschangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllrtoll([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllrtoll([...noschangedata]);
    }
  };
  const [Name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [Id, setId] = useState("");
  const [branch, setbranch] = useState("");
  const [applicationId, setapplicationId] = useState("");
  const [applicationDate, setapplicationDate] = useState("");
  const [Attachment, setAttachment] = useState("");
  const [SlotDate, setSlotDate] = useState("");
  const [SlotTime, setSlotTime] = useState("");
  const [LLNumber, setLLNumber] = useState("");
  const [ValidFrom, setValidFrom] = useState("");
  const [ValidTill, setValidTill] = useState("");
  const [Document, setDocument] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [branchId, setbranchId] = useState("");
  const [RTOName, setRTOName] = useState("");


  const [allRtoll, setAllrtoll] = useState([]);
  const [LLList, setLLList] = useState([]);
  const getAllRto = async () => {
    try {
      let res = await axios(
        "https://jaidrivingschool.in/api/admin/getAllRTOLL"
      );
      if (res.status == 200) {
        setAllrtoll(res.data.success);
        setdata(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  //post method Add Application form

  const handleBr = (abc) => {
    if (abc !== "") {
      if (abc !== "Main Branch") {
        abc = JSON.parse(abc);
        setbranch(`${abc?.branchName},${abc?.area}`);
        setbranchId(abc?._id);
      } else {
        setbranch("Main Branch");
        setbranchId("");
      }
    }
  };
  const handleUser = (abc) => {
    if (abc !== "") {
      abc = JSON.parse(abc);
      setuserId(abc?._id);
      setName(abc?.name);
      setId(abc.customerId);
    }
  };
  const handleRTO = (abc) => {
    if (abc !== "") {
      abc = JSON.parse(abc);
      setRTOName(abc?.rtoName);

    }
  }
  const AddRTOLL = async () => {
    try {
      if (!Name || !branch || !applicationId || !applicationDate || !Attachment)
        return alert("Please fill the form");

      const config = {
        url: "/admin/addRTOLL",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          Name: Name,
          Id: Id,
          branchId: branchId,
          userId: userId,
          branch: branch,
          applicationId: applicationId,
          applicationDate: applicationDate,
          Attachment: Attachment,
          SlotDate: SlotDate,
          SlotTime: SlotTime,
          Remarks: Remarks,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added");
        getAllRto();
        handleClose3();
        setName("");
        setId("");
        setbranch("");
        setapplicationId("");
        setapplicationDate("");
        setAttachment("");
        setSlotDate("");
        setSlotTime("");
        setRemarks("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  //post method Add LL Number
  const [Addll, setAddll] = useState({});
  const AddRTOLLList = async () => {
    try {
      // if (!city) return alert("Please enter city");
      if (!LLNumber || !ValidFrom || !ValidTill || !Document)
        return alert("Please fill the form");

      const config = {
        url: "/admin/makeAddLLNumberrtoll",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          LLNumber: LLNumber,

          ValidFrom: ValidFrom,
          ValidTill: ValidTill,
          Document: Document,
          Remarks: Remarks,
          id: Addll?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added");
        getAllRto();
        handleClose();
        setLLNumber("");
        setValidFrom("");
        setValidTill("");
        setDocument("");
        setRemarks("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [RTOReslot, setRTOReslot] = useState({});
  const AddRTOReslot = async () => {
    try {
      // if (!city) return alert("Please enter city");
      if (!SlotDate || !SlotTime) return alert("Please fill the form");

      const config = {
        url: "/admin/makeUpdeteResoleLLNumberrtoll",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          SlotDate: SlotDate,
          SlotTime: SlotTime,
          Remarks: Remarks,
          id: RTOReslot?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfuully added");
        getAllRto();
        handleClose1();
        setSlotDate("");
        setSlotTime("");
        setRemarks("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  // edit method for ll list
  const [show2, setShow2] = useState(false);
  const handleShow2 = (data) => {
    setShow2(true);
    setName(data?.Name);
    setbranch(data?.branch);
    setapplicationId(data?.applicationId);
    setId(data?.Id);
    setLLNumber(data?.LLNumber);
    setValidFrom(data?.ValidFrom);
    setValidTill(data?.ValidTill);
    setRemarks(data?.Remarks);
    setAttachment(data?.Attachment);
  };
  const handleClose2 = () => setShow2(false);
  const [edit, setedit] = useState({});
  const editRTO = async () => {
    try {
      const config = {
        url: "/admin/updateRTOLL",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",

        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          Name: Name,
          Id: Id,
          branchId: branchId,
          userId: userId,
          branch: branch,
          applicationId: applicationId,
          LLNumber: LLNumber,
          ValidFrom: ValidFrom,
          ValidTill: ValidTill,
          Document: Document,
          Attachment: Attachment,
          Remarks: Remarks,
          id: edit?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Updated");
        handleClose2();
        getAllRto();
        setName("");
        setId("");
        setbranch("");
        setapplicationId("");
        setapplicationDate("");
        setLLNumber("");
        setValidFrom("");
        setValidTill("");
        setDocument("");
        setRemarks("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [allBranch, setallbranch] = useState([]);

  const getAllBranches = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranchByAdmin/" +
          admin?._id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        setallbranch(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [show4, setshow4] = useState(false);
  const handleShow4 = () => setshow4(true);
  const handleClose4 = () => setshow4(false);
  const [deleteId, setdeleteId] = useState("");
  const deleteRto = async () => {
    try {
      const config = {
        url: "/admin/deleteRTOLL/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose4();
        getAllRto();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllCustomer, setAllCustomer] = useState([]);
  // const [nochangedata, setnochangedata] = useState([])
  const getCustomer = async () => {
    try {
      const config = {
        url: "/Customer/getAllCustomer",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
        // setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allRTO, setAllRTO] = useState([]);
  const getRTO = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllRTOByAdmin/" + admin?._id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      }
      );
      if (res.status == 200) {
        setAllRTO(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBranches();
    getAllRto();
    getCustomer();
    getRTO();
  }, []);
  console.log(allBranch);
  console.log(allRtoll);
  console.log(allRTO);


  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allRtoll.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      <div className="add-gr">
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-9  ">
              <span
                style={{ cursor: "pointer", fontSize: "20px" }}
                onClick={() => {
                  setAllApplication(true);
                  SetLLApplication(false);
                  setReslot(false);
                }}
              >
                LL Application List
              </span>
            </div>

            <div className="col-md-3 ad-b">
              <button onClick={handleShow3} style={{ float: "right" }}>
                {" "}
                Add Application{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row ">
            <div className="col-md-3 mt-4">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ height: "45px" }}
                  onChange={handleFilter}
                />
              </InputGroup>
            </div>
            <div className=" col-md-2 ">
              <label>From</label>
              <br />
              <input
                type="date"
                className="vi_0"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className=" col-md-2">
              <label>To</label>
              <br />
              <input
                type="date"
                className="vi_0"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2 mt-4 ">
              <button className="btn btn-success" onClick={searchDate}>
                Submit
              </button>
            </div>
            <div className="col-md-2 mt-4 ad-b">
              <button onClick={ExportToExcel}>Export Excel</button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ad-b">
              <div style={{ float: "right" }}>
                <button
                  onClick={() => {
                    SetLLApplication(true);
                    setAllApplication(false);
                    setReslot(false);
                  }}
                >
                  L L List
                </button>
                &nbsp;
                <button
                  onClick={() => {
                    setAllApplication(false);
                    SetLLApplication(false);
                    setReslot(true);
                  }}
                >
                  Reslot List
                </button>
              </div>
            </div>
          </div>
        </div>

        {AllApplication ? (
          <>
            <div className="container">
              <div className="scrolll_bar mo-gra mt-5">
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th> Name</th>
                      <th>Id</th>
                      <th>Branch</th>
                      <th>RTO Name</th>
                      <th>Application No</th>
                      <th>Application Date</th>
                      <th>Application Attachment</th>
                      <th>Status</th>
                      <th>Action</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  {allRtoll
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((ele, i) => {
                      return (
                        <tbody>
                          <tr style={{ width: "200px" }}>
                            <td>{i + 1}</td>
                            <td>{ele?.Name}</td>
                            <td>{ele?.Id}</td>
                            <td>{ele?.branch}</td>
                          <td>{ele?.RTOName}</td>
                            <td>{ele?.applicationId}</td>
                            <td>{ele?.applicationDate}</td>
                            <td>
                              <img
                                src={`https://jaidrivingschool.in/RTO/${ele?.Attachment}`}
                                alt="img"
                                style={{ width: "75px", height: "75px" }}
                              />
                            </td>
                            <td>{ele?.status}</td>
                            <td>
                              {ele?.status == "Passed" ? (
                                ""
                              ) : (
                                <div className="ad-b">
                                  <button
                                    onClick={() => {
                                      setAddll(ele);
                                      handleShow();
                                    }}
                                  >
                                    Add LL
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleShow1();
                                      setRTOReslot(ele);
                                    }}
                                  >
                                    Reslot
                                  </button>
                                </div>
                              )}
                            </td>
                            <td style={{ display: "flex", gap: "5px" }}>
                              <img
                                src="./images/editing.png"
                                alt=""
                                width={30}
                                onClick={() => {
                                  handleShow2(ele);
                                  setedit(ele);
                                }}
                              />{" "}
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setdeleteId(ele?._id);
                                  handleShow4();
                                }}
                              >
                                <span>
                                  <RiDeleteBin6Line
                                    style={{ fontSize: "20" }}
                                  />
                                </span>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </Table>
                <br />
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {LLApplication ? (
              <>
                <div className="container">
                  <div className="scrolll_bar mo-gra mt-5">
                    <Table
                      striped
                      bordered
                      hover
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          <th>S No.</th>
                          <th> Name</th>
                          <th>Id</th>
                          <th>Branch</th>
                          <th>RTO Name</th>
                          <th>Application No</th>
                          <th>L L Number</th>
                          <th>Application Document</th>
                          <th>Valid From</th>
                          <th>Valid Till</th>
                          <th>Action</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      {allRtoll
                        ?.filter((item) => item?.status == "Passed")
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        ?.map((ele, i) => {
                          return (
                            <tbody>
                              <tr style={{ width: "200px" }}>
                                <td>{i + 1}</td>
                                <td>{ele?.Name}</td>
                                <td>{ele?.Id}</td>
                                <td>{ele?.branch}</td>
                                <td>{ele?.RTOName}</td>
                                <td>{ele?.applicationId}</td>
                                <td>{ele?.LLNumber}</td>

                                <td>
                                  <img
                                    src={`https://jaidrivingschool.in/RTO/${ele?.Document}`}
                                    alt="img"
                                    style={{ height: "75px", width: "75px" }}
                                  />
                                </td>
                                <td>{ele?.ValidFrom}</td>
                                <td>{ele?.ValidTill}</td>
                                <td>
                                  <img
                                    src="./images/editing.png"
                                    alt=""
                                    width={30}
                                    onClick={() => {
                                      handleShow2(ele);
                                      setedit(ele);
                                    }}
                                  />{" "}
                                </td>
                                <td>{ele?.Remarks}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </Table>
                    <br />
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {Reslot ? (
                  <>
                    <div className="container">
                      <div className="scrolll_bar mo-gra mt-5">
                        <Table
                          striped
                          bordered
                          hover
                          style={{ textAlign: "center" }}
                        >
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th> Name</th>
                              <th>Id</th>
                              <th>Branch</th>
                              <th>RTO Name</th>
                              <th>Application No</th>
                              <th>Slot Date</th>
                              <th>Slot Time</th>
                              {/* <th>Application Document</th> */}
                              {/* <th>Action</th> */}
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          {allRtoll
                            ?.filter((item) => item?.status == "Reslot")
                            ?.slice(pagesVisited, pagesVisited + usersPerPage)
                            ?.map((ele, i) => {
                              return (
                                <tbody>
                                  <tr style={{ width: "200px" }}>
                                    <td>{i + 1}</td>
                                    <td>{ele?.Name}</td>
                                    <td>{ele?.Id}</td>
                                    <td>{ele?.branch}</td>
                                    <td>{ele?.RTOName}</td>
                                    <td>{ele?.applicationId}</td>
                                    <td>{ele?.SlotDate}</td>
                                    <td>{ele?.SlotTime}</td>

                                    <td>{ele?.Remarks}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                        </Table>
                        <br />
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add L L Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Select Branch</label>
            <br />

            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => handleBr(e.target.value)}
            >
              <option value="">----Select Branch----</option>
              <option value="Main Branch">Main Branch</option>
              {allBranch?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>
                    {item?.branchName},{item?.area}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="do-sear mt-2">
            <label>Customer/Student Name</label>
            <br />
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => {
                handleUser(e.target.value);
              }}
            >
              <option value="">----Select Student----</option>
              {AllCustomer?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>{item?.name}</option>
                );
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Id</label>
            <input
              type="text"
              placeholder="Enter Id "
              className="vi_0"
              value={Id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear">
            <label htmlFor="">RTO Name</label>
            <select name="" id="" className="vi_0" onChange={(e) => { handleRTO(e.target.value) }}>
              <option value="">---Select RTO Name----</option>
              {allRTO?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>{item?.rtoName}</option>
                )
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Application No</label>
            <input
              type="text"
              placeholder="Enter Application No"
              className="vi_0"
              onChange={(e) => {
                setapplicationId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Application Date</label>
            <input
              type="date"
              placeholder=" "
              className="vi_0"
              onChange={(e) => {
                setapplicationDate(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Attachment</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              onChange={(e) => {
                setAttachment(e.target.files[0]);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Slot Date</label>
            <input
              type="date"
              placeholder="Enter Area/City "
              className="vi_0"
              value={SlotDate}
              onChange={(e) => {
                setSlotDate(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Slot Time</label>
            <input
              type="time"
              placeholder="Enter Area/City "
              className="vi_0"
              value={SlotTime}
              onChange={(e) => {
                setSlotTime(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              placeholder="Enter Remarks "
              className="vi_0"
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary" onClick={AddRTOLL}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add L L </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Enter L L Number</label>
            <br />

            <input
              type="text"
              placeholder=" Enter L L Number"
              className="vi_0"
              onChange={(e) => {
                setLLNumber(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Valid From</label>
            <input
              type="date"
              placeholder="Enter Start Date "
              className="vi_0"
              onChange={(e) => {
                setValidFrom(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Valid Till</label>
            <input
              type="date"
              placeholder="Enter End Date "
              className="vi_0"
              onChange={(e) => {
                setValidTill(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Document</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              onChange={(e) => {
                setDocument(e.target.files[0]);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              placeholder="Enter "
              className="vi_0"
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              AddRTOLLList();
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Slot </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Slot Date</label>
            <br />

            <input
              type="date"
              placeholder=" Enter L L Number"
              className="vi_0"
              onChange={(e) => setSlotDate(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Slot Time</label>
            <input
              type="time"
              placeholder="Enter Slot Time "
              className="vi_0"
              onChange={(e) => setSlotTime(e.target.value)}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              placeholder="Enter "
              className="vi_0"
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={AddRTOReslot}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update L L </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="">Select Branch</label>
            <br />

            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => handleBr(e.target.value)}
            >
              <option value="">----Select Branch----</option>
              <option value="Main Branch">Main Branch</option>
              {allBranch?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>
                    {item?.branchName},{item?.area}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="do-sear mt-2">
            <label>Customer/Student Name</label>
            <br />
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => {
                handleUser(e.target.value);
              }}
            >
              <option value="">----Select Student----</option>
              {AllCustomer?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>{item?.name}</option>
                );
              })}
            </select>
          </div>
          <div className="do-sear mt-2">
            <label>Id</label>
            <input
              type="text"
              placeholder="Enter Id "
              className="vi_0"
              value={Id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Application No</label>
            <input
              type="text"
              placeholder="Enter Application No"
              className="vi_0"
              value={applicationId}
              onChange={(e) => {
                setapplicationId(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label htmlFor="">Enter L L Number</label>
            <br />

            <input
              type="text"
              placeholder=" Enter L L Number"
              className="vi_0"
              value={LLNumber}
              onChange={(e) => {
                setLLNumber(e.target.value);
              }}
            />
          </div>

          <div className="do-sear mt-2">
            <label>Valid From</label>
            <input
              type="date"
              placeholder="Enter Start Date "
              className="vi_0"
              value={ValidFrom}
              onChange={(e) => {
                setValidFrom(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Valid Till</label>
            <input
              type="date"
              placeholder="Enter End Date "
              className="vi_0"
              value={ValidTill}
              onChange={(e) => {
                setValidTill(e.target.value);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label htmlFor="upload1">Attachment</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              name="file"
              id="upload1"
              onChange={(e) => {
                setAttachment(e.target.files[0]);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label htmlFor="upload2">Document</label>
            <input
              type="file"
              placeholder="Enter Email Id "
              className="vi_0"
              name="file"
              id="upload2"
              onChange={(e) => {
                setDocument(e.target.files[0]);
              }}
            />
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <textarea
              type=""
              name=""
              rows={3}
              cols={10}
              value={Remarks}
              placeholder="Enter "
              className="vi_0"
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              editRTO();
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteRto}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminLLAplication;
