import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { BiTimeFive } from 'react-icons/bi';
import { Container } from 'react-bootstrap';
import Carousel from 'react-grid-carousel'
import axios from 'axios';
import parse from "html-react-parser";

export default function Packages() {

    const [allService, setAllService] = React.useState([]);
    const getAllService = async () => {
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAllService"
            );
            if (res.status === 200) {
                setAllService(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getAllService();
    }, []);
    console.log("service", allService)
    return (
        <div>

            <Container className='mb-5'>

                <div className="text-center mb-3">
                    <h1 className="fw-bold">OUR SERVICES</h1>
                    <p style={{ fontSize: '12px' }}>Below you will find our three most popular Services</p>
                </div>

                {/* <Box
                    sx={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
                        gap: 2,
                    }}
                > */}



                <Carousel cols={3} rows={1} gap={10} loop>
                    {allService?.map((item) => {
                        return (
                            <Carousel.Item>
                                <Card size="md" variant={item?.popularService == false ? "outlined" : "solid"} color={item?.popularService == false ? "" : "#000"} invertedColors sx={item.popularService == false ? "" : { bgcolor: '#fccc0b' }} className="card_courses" >
                                    <Chip size="sm" variant="outlined" color="neutral">
                                        {item?.serviceName}
                                    </Chip>
                                    <Divider inset="none" />
                                    <List size="sm" sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr', mx: 'calc(-1 * var(--ListItem-paddingX))'
                                    }}>
                                        {item?.servicePoint?.map((ele) => {
                                            return (
                                                <ListItem>
                                                    <ListItemDecorator>
                                                        <Check />
                                                    </ListItemDecorator>
                                                    {ele?.text}
                                                </ListItem>

                                            )
                                        })}


                                    </List>
                                    <Divider inset="none" />
                                    <CardActions>
                                        <Typography level="title-lg" sx={{ mr: 'auto' }}>
                                            &#8377;{item?.servicePrice?.toFixed(2)}
                                            {/* <Typography fontSize="sm" textColor="text.tertiary">
                                        / month
                                    </Typography> */}
                                        </Typography>
                                        <a href="/rto">  <Button
                                            variant="soft"
                                            color="neutral"
                                            endDecorator={<KeyboardArrowRight />}
                                        >
                                            Know More
                                        </Button>
                                        </a>
                                    </CardActions>
                                </Card>
                            </Carousel.Item>
                        )
                    })}


                </Carousel>

                {/* </Box> */}
            </Container>
        </div>
    );
}