import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";

const VendorRtoList = () => {
    const admin = JSON.parse(sessionStorage.getItem("branch"));
    const token = sessionStorage.getItem("token");
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (data) => {
        setShow1(true);
        setCity(data?.city);
        setAdress(data?.Adress);
        setpincode(data?.pincode);
        setrtoName(data?.rtoName);
        setrtoEmail(data?.rtoEmail);
        setrtoCode(data?.rtoCode);
        setrtoNumber(data?.rtoNumber);
        setstate(data?.state);
    }
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const [makeActive, setMakeActive] = useState(true);

    const [city, setCity] = useState("");
    const [Adress, setAdress] = useState("");
    const [pincode, setpincode] = useState("");
    const [rtoName, setrtoName] = useState("");
    const [rtoEmail, setrtoEmail] = useState("");
    const [rtoCode, setrtoCode] = useState("");
    const [rtoNumber, setrtoNumber] = useState("");
    const [state, setstate] = useState("");
 const mobileNumberRegex = /^[0-9]{10}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const addRtoList = async () => {
        try {
            if (!city) return alert("Please Enter City");
            if (!Adress) return alert("Please Enter Address");
            if (!pincode || pincode.length > 6 || pincode.length < 6)
                return alert("Please Enter Correct Pincode");
            if (!rtoName) return alert("Please Enter RTO Name");
            if (!rtoEmail) return alert("Please Enter email");
            if(!emailRegex.test(rtoEmail)) return alert("Invalid email")
             if (!rtoNumber) return alert("Please Enter Contact number");
            if(!mobileNumberRegex.test(rtoNumber)) return alert("Invalid contact number")
            if (!rtoCode) return alert("Please Enter RTO Code");
            let ab =admin?.branchId ? admin?.branchId: admin?._id
            const config = {
                url: "/admin/addRto",
                method: "post",
                baseURL: "https://jaidrivingschool.in/api",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },

                data: {
                    authId: admin?._id,
                    city: city,
                    Adress: Adress,
                    pincode: pincode,
                    rtoName: rtoName,
                    rtoEmail: rtoEmail,
                    rtoCode: rtoCode,
                    rtoNumber: rtoNumber,
                    state: state,
                    branchId:ab,branchName:admin?.branchName
                },
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert(res.data.success);
                getRTO()
                handleClose3();
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [allRTO, setAllRTO] = useState([]);
    const[nochangedata,setnochangedata]=useState([])
    const getRTO = async () => {
        try {

            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAllRTO", {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
            }
            );
            if (res.status == 200) {
                setAllRTO(res.data.success);
                setnochangedata(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };

     const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allRTO.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllRTO([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllRTO([...nochangedata]);
    }
  };
    const [edit, setedit] = useState({});
    const editRTO = async () => {
        if(rtoEmail){
            if(!emailRegex.test(rtoEmail)) return alert("Invalid email")
        }
        if(rtoNumber){
             if(!mobileNumberRegex.test(rtoNumber)) return alert("Invalid contact number")
        }
        try {
            const config = {
                url: "/admin/updateRTO",
                method: "put",
                baseURL: "https://jaidrivingschool.in/api",

                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
                data: {
                    authId: admin?._id,
                    city: city,
                    Adress: Adress,
                    pincode: pincode,
                    rtoName: rtoName,
                    rtoEmail: rtoEmail,
                    rtoCode: rtoCode,
                    rtoNumber: rtoNumber,
                    state: state,
                    id: edit?._id,
                },
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert("Successfully Updated");
                handleClose1();
                getRTO();
                setCity("");
                setAdress("");
                setpincode("");
                setrtoName("");
                setrtoEmail("");
                setrtoCode("");
                setrtoNumber("");

                setstate("");
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [show4, setshow4] = useState(false);
    const handleShow4 = () => setshow4(true);
    const handleClose4 = () => setshow4(false);
    const [deleteId, setdeleteId] = useState("");
    const deleteRto = async () => {
        try {
            const config = {
                url: "/admin/deleteRTO/" + deleteId + "/" + admin?._id,
                method: "delete",
                baseURL: "https://jaidrivingschool.in/api",

                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
                //  data: {authId:admin?._id,titele:title,link:link,day:day,id:edit?._id}
            };
            let res = await axios(config);
            if (res.status == 200) {
                alert("Successfully deleted");
                handleClose4();
                getRTO();
            }
        } catch (error) {
            console.log(error);
        }
    };
    const makeActiveAndDeactive = async (id, isActive) => {
        try {
            const config = {
                url: "/admin/makeActiveAndDeactiveRTO",
                method: "put",
                baseURL: "https://jaidrivingschool.in/api",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
                data: {
                    authId: admin?._id,
                    id: id,
                    isActive: isActive
                }
            }
            let res = await axios(config)
            if (res.status == 200) {
                alert(res.data.success);
                getRTO();
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getRTO();
    }, []);


    return (
        <>
            <div className="add-gr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ad-b">
                            {/* <button onClick={handleShow3} style={{ float: "right" }}>
                                {" "}
                                Add RTO List
                            </button> */}
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="scrolll_bar mo-gra mt-5">
                          <div className="row">
              <div className="col-3">
                <input
                  type="text"
                  placeholder="Search...."
                  onChange={handleFilter}
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                />
              </div>
            </div>
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Date</th>
                                    <th>RTO Name</th>
                                    <th>RTO Code</th>
                                    <th>Email Id</th>
                                    <th>Contact No.</th>
                                    <th>State</th>
                                    <th>Area/City</th>
                                    <th>Address</th>
                                    <th>PinCode</th>
                                    {/* <th>Action</th> */}
                                    {/* <th>Status</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            {allRTO?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((ele, i) => {
                                return (
                                    <tbody>
                                        <tr style={{ width: "200px" }}>
                                            <td>{i + 1}</td>
                                            <td>{moment(ele.createdAt).format("MMM Do YY")}</td>
                                            <td>{ele?.rtoName}</td>
                                            <td>{ele?.rtoCode}</td>
                                            <td>{ele?.rtoEmail}</td>
                                            <td>{ele?.rtoNumber}</td>
                                            <td>{ele?.state}</td>
                                            <td>{ele?.city}</td>
                                            <td>{ele?.Adress}</td>
                                            <td>{ele?.pincode}</td>
                                            {/* <td style={{ display: "flex" }}>
                                                <img
                                                    src="./images/editing.png"
                                                    alt=""
                                                    width={30}
                                                    onClick={() => {
                                                        setedit(ele);
                                                        handleShow1(ele);
                                                    }}
                                                />{" "}
                                                <Button type="button" variant="danger" onClick={() => {
                                                    setdeleteId(ele?._id);
                                                    handleShow4();
                                                }}>
                                                    <span>
                                                        <RiDeleteBin6Line
                                                            style={{ fontSize: "20" }}

                                                        />
                                                    </span>
                                                </Button>
                                            </td>
                                            <td>{ele?.isActive == false ? (<Button variant="danger" type="button" onClick={() => makeActiveAndDeactive(ele?._id, true)}> Deactivate </Button>) : (<Button type="button" variant="success" onClick={() => makeActiveAndDeactive(ele?._id, false)}> Activate </Button>)}</td> */}

                                        </tr>
                                    </tbody>
                                );
                            })}
                        </Table>
                        <br/>
                         <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
                    </div>
                </div>
            </div>
            <Modal
                show={show3}
                onHide={handleClose3}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add RTO List </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label>RTO Name</label>
                        <input
                            type="text"
                            placeholder="Enter RTO Name "
                            className="vi_0"
                            onChange={(e) => {
                                setrtoName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Code</label>
                        <input
                            type="text"
                            placeholder="Enter Code "
                            className="vi_0"
                            onChange={(e) => {
                                setrtoCode(e.target.value);
                            }}
                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>Email Id</label>
                        <input
                            type="email"
                            placeholder="Enter Email Id "
                            className="vi_0"
                            onChange={(e) => {
                                setrtoEmail(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Contact No</label>
                        <input
                            type="number"
                            placeholder="Enter Contact No"
                            className="vi_0"
                            onChange={(e) => {
                                setrtoNumber(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>State</label>
                        <input
                            type="text"
                            placeholder="Enter State "
                            className="vi_0"
                            onChange={(e) => {
                                setstate(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Area/City</label>
                        <input
                            type="text"
                            placeholder="Enter Area/City "
                            className="vi_0"
                            onChange={(e) => {
                                setCity(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Address</label>
                        <textarea
                            name=""
                            rows={3}
                            cols={10}
                            placeholder="Enter Address "
                            className="vi_0"
                            onChange={(e) => {
                                setAdress(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Pincode</label>
                        <input
                            type="number"
                            placeholder="Enter Pincode "
                            className="vi_0"
                            onChange={(e) => {
                                setpincode(e.target.value);
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addRtoList}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={show1}
                onHide={handleClose1}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update RTO List </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label>RTO Name</label>
                        <input
                            type="text"
                            placeholder="Enter RTO Name "
                            className="vi_0"
                            value={rtoName}
                            onChange={(e) => {
                                setrtoName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Code</label>
                        <input
                            type="text"
                            placeholder="Enter Code "
                            value={rtoCode}
                            className="vi_0"
                            onChange={(e) => {
                                setrtoCode(e.target.value);
                            }}
                        />
                    </div>

                    <div className="do-sear mt-2">
                        <label>Email Id</label>
                        <input
                            type="email"
                            placeholder="Enter Email Id "
                            value={rtoEmail}
                            className="vi_0"
                            onChange={(e) => {
                                setrtoEmail(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Contact No</label>
                        <input
                            type="number"
                            placeholder="Enter Contact No"
                            value={rtoNumber}
                            className="vi_0"
                            onChange={(e) => {
                                setrtoNumber(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>State</label>
                        <input
                            type="text"
                            placeholder="Enter State "
                            value={state}
                            className="vi_0"
                            onChange={(e) => {
                                setstate(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Area/City</label>
                        <input
                            type="text"
                            placeholder="Enter Area/City "
                            value={city}
                            className="vi_0"
                            onChange={(e) => {
                                setCity(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Address</label>
                        <textarea
                            name=""
                            rows={3}
                            cols={10}
                            placeholder="Enter Address "
                            value={Adress}
                            className="vi_0"
                            onChange={(e) => {
                                setAdress(e.target.value);
                            }}
                        />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Pincode</label>
                        <input
                            type="number"
                            placeholder="Enter Pincode "
                            value={pincode}
                            className="vi_0"
                            onChange={(e) => {
                                setpincode(e.target.value);
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={editRTO}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={show4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete??</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose4}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={deleteRto}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default VendorRtoList;
