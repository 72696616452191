import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Side from "./sidebar/Side";

const Me1 = () => {
  const navigate = useNavigate()
  const staff = JSON.parse(sessionStorage.getItem("staff"))
  const token = sessionStorage.getItem("token")
  if (!staff) {
    alert("Please login");
    window.location.assign("/")
  } else
    return (
      <div>
        <Side />

        <div className="container">
          <div className="ad-b mt-4 mb-3">
            {/* <button >Profile</button> */}
          </div>
          <div className="main-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      {staff?.profile ? (<img
                        src={`https://jaidrivingschool.in/Staff/${staff?.profile}`}
                        alt="Admin"
                        className="rounded-circle"
                        width="150"
                        height="150px"
                      />) : (<img
                        src="./images/person1.jpg"
                        alt="Admin"
                        className="rounded-circle"
                        width="150"
                        height="150px"
                      />)}

                      <div className="mt-3">
                        <h4></h4>
                        <p className="text-secondary mb-1">{staff?.name}</p>
                        <p className="text-muted font-size-sm">
                          {staff?.city}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Full Name</label>
                          <p>{staff?.name}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Email</label>
                          <p>{staff?.email}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Mobile Number</label>
                          <p>{staff?.mobile}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Instructor's ID</label>
                          <p>{staff?.staffId}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Designation</label>
                          <p>Staff</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Branch Name</label>
                          <p>{staff?.branchName}</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">City</label>
                          <p>{staff?.city}</p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Address</label>
                          <p>{staff?.Address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <button type='submit' className='btn btn-success mb-3' onClick={() => navigate("/me")}>Edit Profile</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    )
}

export default Me1