import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LogChoose = () => {
  const [tesla, setTesla] = useState(false);
  const [maruti, setMaruti] = useState(false);
  const [nano, setNano] = useState(false);
  const [innova, setInnova] = useState(false);
  let tesla1 = (
    <p>
      <div class="card" style={{ width: "18rem" }}>
        <div class="card-body">
          <h5 class="card-title">Tesla Model X</h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">Eco Friendl</h6>
          <p class="card-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
            cumque obcaecati. Odit?
          </p>
        </div>
      </div>
    </p>
  );
  let maruthi1 = (
    <p>
      <div class="card" style={{ width: "18rem" }}>
        <div class="card-body">
          <h5 class="card-title">Maruthi Model X</h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">Eco Friendl</h6>
          <p class="card-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
            cumque obcaecati. Odit?
          </p>
        </div>
      </div>
    </p>
  );
  let nano1 = (
    <p>
      <div class="card" style={{ width: "18rem" }}>
        <div class="card-body">
          <h5 class="card-title">Nano Model X</h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">Eco Friendl</h6>
          <p class="card-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
            cumque obcaecati. Odit?
          </p>
        </div>
      </div>
    </p>
  );
  let innova1 = (
    <p>
      <div class="card" style={{ width: "18rem" }}>
        <div class="card-body">
          <h5 class="card-title">Innova Model X</h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">Eco Friendl</h6>
          <p class="card-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
            cumque obcaecati. Odit?
          </p>
        </div>
      </div>
    </p>
  );
  const handleClick = () => {
    setTesla(!tesla);
  };
  const handleClick1 = () => {
    setMaruti(!maruti);
  };
  const handleClick2 = () => {
    setNano(!nano);
  };
  const handleClick3 = () => {
    setInnova(!innova);
  };
  const navigate = useNavigate();
  return (
    <div>
      <div className="logchoosecar"> 
        <h1  className="text-center">Choose Car Model</h1>
       
        <div
          className="row mt-3 logchoosecar1"
          style={{
            border: "1px solid black",
            padding: "20px",
            width: "80%",
            margin: "auto",
          }}
        >
          <div className="col-md-3 mb-3">
            <img src="/images/tesla.jpeg" alt="" style={{width:"100%"}} />
          </div>
          <div className="col-md-5 ms-5   ">
            <h4>Tesla </h4>
            <h6>Brand : Tesla</h6>
            <h6>Type : Petrol</h6>
            <h6>Color : white</h6>
            {tesla ? tesla1 : ""}
            <button onClick={handleClick} className="btn btn-warning">
              {tesla ? "view less" : "view more"}
            </button>
          </div>
          <div className="col-md-2 mt-5">
            <input type="radio" name="car" />
          </div>
        </div>
        <div
          className="row mt-3 logchoosecar1"
          style={{
            border: "1px solid black",
            padding: "20px",
            width: "80%",
            margin: "auto",
          }}
        >
          <div className="col-md-3 mb-3">
            <img src="/images/tesla.jpeg" alt="" style={{width:"100%"}}/>
          </div>
          <div className="col-md-5 ms-5   ">
            <h4>Tesla </h4>
            <h6>Brand : Tesla</h6>
            <h6>Type : Petrol</h6>
            <h6>Color : white</h6>
            {maruti ? maruthi1 : ""}
            <button onClick={handleClick1} className="btn btn-warning">
              {maruti ? "view less" : "view more"}
            </button>
          </div>
          <div className="col-md-2 mt-5">
            <input type="radio" name="car" />
          </div>
        </div>
        <div
          className="row mt-3 logchoosecar1"
          style={{
            border: "1px solid black",
            padding: "20px",
            width: "80%",
            margin: "auto",
          }}
        >
          <div className="col-md-3 mb-3">
            <img src="/images/tesla.jpeg" alt="" style={{width:"100%"}} />
          </div>
          <div className="col-md-5 ms-5   ">
            <h4>Tesla </h4>
            <h6>Brand : Tesla</h6>
            <h6>Type : Petrol</h6>
            <h6>Color : white</h6>
            {nano ? nano1 : ""}
            <button onClick={handleClick2} className="btn btn-warning">
              {nano ? "view less" : "view more"}
            </button>
          </div>
          <div className="col-md-2 mt-5">
            <input type="radio" name="car" />
          </div>
        </div>
        <div
          className="row mt-3 logchoosecar1"
          style={{
            border: "1px solid black",
            padding: "20px",
            width: "80%",
            margin: "auto",
          }}
        >
          <div className="col-md-3 mb-3">
            <img src="/images/tesla.jpeg" alt="" style={{width:"100%"}} />
          </div>
          <div className="col-md-5 ms-5   ">
            <h4>Tesla </h4>
            <h6>Brand : Tesla</h6>
            <h6>Type : Petrol</h6>
            <h6>Color : white</h6>
            {innova ? innova1 : ""}
            <button onClick={handleClick3} className="btn btn-warning">
              {innova ? "view less" : "view more"}
            </button>
          </div>
          <div className="col-md-2 mt-5">
            <input type="radio" name="car" />
          </div>
        </div>
      </div>
      <button
        type="submit"
        onClick={() => navigate("/loginstructors")}
        class="btn btn-warning mt-3 loginstructorsbtn1"
        style={{ width: "7rem", float: "left", marginBottom: "20px", marginLeft: "50px" }}
      >
        previous
      </button>
      <button
        type="submit"
        onClick={() => navigate("/loginstructors")}
        class="btn btn-warning mt-3 loginstructorsbtn2"
        style={{
          float: "right",
          width: "8rem",
          // height: "50px",
          // marginTop: "60px",
          marginRight: "50px"
        }}
      >
        Save&Continue
      </button>
    </div>
  );
};

export default LogChoose;
