import React, { useEffect, useState } from 'react'
import { json, useNavigate } from 'react-router-dom'
import Side1 from "./sidebar1/Side1";
import axios from 'axios';
import moment from 'moment';


const StudentAttendence = () => {
    const [formData, setFormData] = useState({
        starttime: "",
        endtime: "",
        Instructor: "",
        days: "",
        remarks: "",
        status: "",

    });
    const user=JSON.parse(sessionStorage.getItem("user"))
    const [tableData, setTableData] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const getAttendanceByCustomerId=async()=>{
        try {
            let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassBycustomerId/"+user?._id);
            if(res.status==200){
                setTableData(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    }
useEffect(()=>{
    if(user){
        getAttendanceByCustomerId()
    }
},[])
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (editingIndex === null) {
            // Add new data to table
            setTableData([...tableData, formData]);
        } else {
            // Update existing data in table
            const newData = [...tableData];
            newData[editingIndex] = formData;
            setTableData(newData);
            setEditingIndex(null);
        }
        setFormData({
            starttime: "",
            endtime: "",
            Instructor: "",
            days: "",
            remarks: "",
            status: "",
        });
    };

    
  return (
    <div>
        <Side1 />
        <div className="container">
                <div className="row">
                    <div className="update">
                    <div className="cs">
                            <table className="table table-bordered table-hover">
                                <thead  style={{backgroundColor:"#9d041b"}}>
                                    <tr>
                                    <th>S.No</th>
                                        <th>Topic</th>
                                        <th>StartTime</th>
                                        <th>EndTime</th>
                                        <th>Tainer Name</th>
                                        <th>Day</th>
                                        <th>Date</th>
                                        <th>Remark</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((data, index) => (
                                        <tr key={index}>
                                                                  <td>{index+1}</td>
                                            <td>{data?.classTopic}</td>
                                            <td>{moment(data?.picupTime,["hh:mm"]).format('hh:mm a')}</td>
                                            <td>{moment(data.endtime,["hh:mm"]).format('hh:mm a')}</td>
                                            <td>{data.trainerId?.name}</td>
                                            <td>{data.classday} day</td>
                                            <td>{moment(data.selectdate).format("DD/MM/YYYY")}</td>
                                            <td>{data.remarks}</td>

                                            <td>
                                               {data?.status=="Complete" ? (<span style={{color:"green"}}>Present</span>):(<span>{data?.studentStatus}</span>)}
                                            </td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default StudentAttendence