import React from 'react'
import "../caranimation.css";
import { Container } from 'react-bootstrap';

export const Caranimation = () => {
    return (
        <div>
            <Container fluid className='mb-5'>
                <div>
                    <div className="row">
                        <div className="col-lg-6 text-center " style={{ backgroundImage: "url('../images/bg-road.jpg')" }}>
                            <p className='text-light caranimatin-left-head mt-3'>96% of Our Students Pass <br /> the Driving Test on</p>
                            <h4 className='fw-bold text-light mb-4 fs-1'>Their First Try</h4>
                           <a href="/register"> <button className="courses-btn" >BECOME MEMBER</button></a>
                        </div>

                        <div className="col-lg-6"
                            style={{
                                backgroundColor: "rgb(157 4 27)",
                                backgroundImage: "url('../Image/texture.png')",
                                backgroundRepeat: "repeat",
                                backgroundSize: " cover",
                                // padding: " 260px 0px 160px",
                                height: '300px',
                                position: " relative",
                                zIndex: "1",
                            }}
                        >

                            <div
                                id="contact"
                                className="based "
                                style={{
                                    backgroundImage: "url('../images/city-skiline.png')",
                                    backgroundSize: "cover",
                                    backgroundPosition: " bottom center",
                                    backgroundRepeat: " no-repeat",
                                    width: " 100%",
                                    height: " 100%",
                                    position: " absolute",
                                    left: " 0",
                                    bottom: " 0px",
                                    zIndex: "-1",
                                    overflow: "hidden",
                                }}
                            >
                                <div className="running-taxi">
                                    <div
                                        className="taxi"
                                        style={{ backgroundImage: "url('../images/carbg-1.png')" }}
                                    />
                                    <div
                                        className="taxi-2"
                                        style={{ backgroundImage: "url('../images/carbg-1.png')" }}
                                    />
                                    <div className="taxi-3" />
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )
}
