import React, { useEffect, useState } from "react";
import Side1 from "./sidebar1/Side1";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const IdCard = () => {
    const createPDF = async () => {
        // setRotate(360);

        // dynamic image is also adding in the PDF
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.getElementById("pdf"), {
            useCORS: true,
        });
    
        const img = data.toDataURL("image/png");
    
        const imgProperties = pdf.getImageProperties(img);
     
        const pdfWidth = pdf.internal.pageSize.getWidth();
      
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
     
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("StudentId_Card.pdf");
    };
    const navigate = useNavigate()
    const user = JSON.parse(sessionStorage.getItem("user"))
    const token = sessionStorage.getItem("token")
    const admin = JSON.parse(sessionStorage.getItem("admin"));

    if (!user) {
        alert("Please login");
        window.location.assign("/")
    }
    const [allBranch, setallbranch] = useState([]);

    const getAllBranches = async () => {
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAllBranchByAdmin/" + admin?._id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                },
            }
            );
            if (res.status == 200) {
                setallbranch(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {

        getAllBranches();
    }, []);
    console.log(allBranch)

    let data = new Date(user?.updatedAt);
    function addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }

    let adedDate = addOneYear(data)

    return (
        <div>
            <Side1 />

            <div className="container p-2">
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-warning" style={{ float: "right" }} onClick={createPDF}>
                            Download{" "}
                            <i
                                class="fa-solid fa-file-arrow-down "
                                style={{ marginLeft: "10px" }}
                            ></i>
                        </button>
                    </div>
                </div>
                <div
                    style={{
                        boxShadow: " 0px 8px 32px 0px rgba(19, 19, 20, 0.37)",
                        background: "#f5f6fa",
                        backdropFilter: "blur(4px)",
                        height: "550px",
                        width: "100%",
                        marginBottom: "20px"
                    }}
                    id="pdf"
                    className="id_background"
                >
                    <div className="container p-4" >
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <div
                                    style={{
                                        boxShadow: " 0px 8px 32px 0px rgba(19, 19, 20, 0.37)",
                                        background: "#f5f6fa",
                                        backdropFilter: "blur(4px)",
                                        height: "100%",
                                        width: "400px",
                                        float: "right",
                                    }}
                                    className="id_card_front"
                                >

                                    {" "}
                                    <div
                                        style={{
                                            // height: "165px",
                                            // width: "300px",
                                            margin: "auto",

                                        }}
                                    >
                                        {" "}
                                        <img
                                            src="/images/logo1.png.png"
                                            alt=""
                                            style={{ height: "122px", marginLeft: "30%", marginTop: "10px" }}
                                        />
                                    </div>{" "}
                                    <div>
                                        {user?.profile ? (<img
                                            src={`https://jaidrivingschool.in/Customer/${user?.profile}`}
                                            alt=""
                                            style={{
                                                border: "5px solid rgb(157, 4, 27)",
                                                width: "150px",
                                                marginLeft: "30%",
                                                padding: "2px",
                                                marginTop: "10px",
                                                height: "150px"
                                            }}
                                        />) : (<img
                                            src="./images/person1.jpg"
                                            alt="Admin"
                                            className="rounded-circle"
                                            width="150"
                                            height="150px"
                                        />)}

                                    </div>
                                    <div className="text-center">
                                        <h3>{user?.name}</h3>
                                        <p>{user?.userType}</p>{" "}
                                    </div>
                                    <div
                                        style={{

                                            fontWeight: "bold",
                                            color: " rgb(157, 4, 27)",
                                            // paddingLeft: "50px",
                                            textAlign: "center"

                                        }}
                                    >
                                        {" "}
                                        www.jaidrivingschool.com
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div
                                    style={{

                                        boxShadow: " 0px 8px 32px 0px rgba(19, 19, 20, 0.37)",
                                        background: "#f5f6fa",
                                        backdropFilter: "blur(4px)",
                                        height: "100%",
                                        width: "400px",
                                    }}
                                    className="id_card_front"
                                >

                                    <div>
                                        <h3
                                            style={{
                                                color: "rgb(157, 4, 27)",
                                                fontSize: "19px",
                                                textAlign: "center",
                                                paddingTop: "10px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {" "}
                                            JAI MOTORS DRIVING SCHOOL
                                        </h3>
                                    </div>
                                    <div className="ps-4 text-center">
                                        <p> <p style={{ fontSize: "10px" }}>
                                            Site No. 185/2B, <br />
                                            <span>
                                                Chennakeshava Commercial Complex,
                                                Shop No. 5 & 6, <br /> Thindu Main Road, Opp to Sirhari
                                                Service Station, <br /> Virupakshaoura, Vidhyaranyapura
                                                Post, <br /> Bangalore-560097, Ph: 080 49917114 <br />
                                                Email: jaidrivingschool@gmail.com
                                            </span>
                                        </p></p>

                                    </div>
                                    <div className="container ">
                                        <div className="row">
                                            <div className="col-md-12 text-justify">
                                                <span style={{ color: "rgb(157, 4, 27)" }}>Id: </span> <span>{user?.customerId}</span> <br />
                                                <span style={{ color: "rgb(157, 4, 27)" }}>Mob:</span> <span>{user?.mobile}</span> <br />
                                                <span style={{ color: "rgb(157, 4, 27)" }}>Dob:</span> <span>{moment(user?.dob).format("DD/MM/YYYY")}</span> <br />
                                                {/* <span>Blood G:</span> <span>A+</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{

                                            fontWeight: "bold",
                                            color: "rgb(157, 4, 27)",




                                        }}
                                    >

                                        {" "}
                                        <div
                                            style={{
                                                marginTop: "40px",
                                                width: "100%",
                                            }}
                                        >
                                            <span style={{ display: "flex", justifyContent: "center" }}>Authorised Signatory</span>
                                            <p>
                                                <span> Note:</span> <br />
                                                <div className="container">
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div>
                                                            <span className="dnjjj_0">
                                                                <ul>
                                                                    <li>This Card is not transferable</li>
                                                                    <li>
                                                                        This card does not have any monitory value
                                                                        and valued only for identification purpose
                                                                        only
                                                                    </li>
                                                                    <li>
                                                                        If found please returns to companies address{" "}
                                                                    </li>
                                                                </ul>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div

                                                            >
                                                                <p className="block" style={{ textAlign: "center", fontSize: "10px" }}><span>Expiry Date Of ID Card</span> <br /> {moment(adedDate).format("DD/MM/YYYY")}</p>


                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default IdCard;
