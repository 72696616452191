import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';

import Side1 from "./sidebar1/Side1";
import axios from 'axios';
import { Button, Modal, Table } from "react-bootstrap";
import ReactPaginate from 'react-paginate';

const StudentCertificate = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const user = JSON.parse(sessionStorage.getItem("user"));
    const token = sessionStorage.getItem("token");
    const [allCourse, setallCourse] = useState([]);
    const [nochangedata, setnochangedata] = useState([]);
    const getBookDeatailsByCustomerId = async () => {
      try {
        let res = await axios.get(
          "https://jaidrivingschool.in/api/admin/getBookDeatailsByCustomerId/" +
          user?._id
        );
        if (res.status == 200) {
          setallCourse(res.data.success?.filter((item)=>item?.isGenrateC==true));
          setnochangedata(res.data.success?.filter((item)=>item?.isGenrateC==true));
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(()=>{
        if(user){
            getBookDeatailsByCustomerId()
        }
    },[])

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(allCourse.length / usersPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const [search, setSearch] = useState("");
    const handleFilter = (e) => {
      if (e.target.value != "") {
        setSearch(e.target.value);
        const filterTable = nochangedata.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
        setallCourse([...filterTable]);
      } else {
        setSearch(e.target.value);
        setallCourse([...nochangedata]);
      }
    };
  return (
    <div>
        <Side1 />
        <div className="add-gr">
        <div className="container">
          <div className="row">
     
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-3">
              <input
                type="text"
                placeholder="Search...."
                className="vi_0"
                style={{ margin: "10px" }}
                onChange={(e) => handleFilter(e)}
              />
            </div>
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Course Name</th>
                  <th>Branch Name</th>
                  <th> Status</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allCourse?.slice(
                  pagesVisited,
                  pagesVisited + usersPerPage
                )?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>

                     
                      <td>{item?.courseTitle}</td>
                      <td>{item?.branchName}</td>

                     
                    
                   

                      <td>
                            {item?.status == "Pending" ? (
                              <span style={{ color: "blue" }}>
                                {item?.status}
                              </span>
                            ) : (
                              <span style={{ color: "green" }}>
                                {item?.status == "Assigned" ? (
                                  <span style={{ color: "Red" }}>
                                    {item?.status}
                                  </span>
                                ) : (
                                  <span>{item?.status}</span>
                                )}
                              </span>
                            )}
                          </td>
                      <td>
                    
                       
                            <button
                              type="button"
                              class="btn btn-success"
                           onClick={()=>navigate("/Admin-Certificate",{state:item})}
                            >
                              View Certificate
                            </button>
                           
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <h5>Please share your experience with us</h5>
                            <div className="col-md-8">
                               <label htmlFor="">rate our website,course and experience</label><br/>
                                Rating  :   ⭐⭐⭐⭐⭐

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>navigate("/scertiprint")} style={{ color: "black" }}>
                        submit
                    </Button>

                </Modal.Footer>
            </Modal>
    </div>
  )
}

export default StudentCertificate