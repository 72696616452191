import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import {RxCrossCircled} from "react-icons/rx"
import axios from "axios";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
const AdminCourse = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllService"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllServiceType,setAllServiceType]=useState([]);
  const getAllServiceType=async()=>{
    try {
      const res=await axios.get("https://jaidrivingschool.in/api/admin/getServiceCategory12");
      if(res.status==200){
        setAllServiceType(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [courseName, setcourseName] = useState("");
  const [courseDuration, setcourseDuration] = useState("");
  const [coursePoint, setcoursePoint] = useState([]);
  const [price, setprice] = useState("");
  const [courseImage, setcourseImage] = useState("");
  const [Description, setDescription] = useState("");
  const [PracticalSessions, setPracticalSessions] = useState("");
  const [TheorySessions, setTheorySessions] = useState("");
  const [PracticalExam, setPracticalExam] = useState("");
  const [TheoryExam, setTheoryExam] = useState("");
  const [SimulatorSessions, setSimulatorSessions] = useState("");
  const [aboutCourse, setAboutCourse] = useState("");
  const addDataAbout = () => {
    if (!aboutCourse) return alert("Please select documents type");
    coursePoint.push({ text: aboutCourse });
    setAboutCourse("");
  };

  const [Demo,setDemo]=useState("");
  const AdminService = async () => {
    try {
      if (!courseImage) return alert("Please select image");
      if (!courseName) return alert("Please enter tittle");
      if (!courseDuration) return alert("Please enter service type");
      if (coursePoint?.length == 0)
        return alert("Please add your documents require one by one");
      if (!price) return alert("Please enter Service amount");
      if (!Description) return alert("Please enter Description!");
      // if (!PracticalSessions) return alert("Please enter practical session");
      // if (!TheorySessions) return alert("Please enter theory session");
      // if(!courseImage) return alert("Please select image")
      // if(!courseImage) return alert("Please select image")

      const config = {
        url: "/admin/AddService",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          serviceName: courseName,
          serviceType: courseDuration,
          servicePoint: coursePoint,
          servicePrice: price,
          // serviceRequire:PracticalSessions,
          servicePicture: courseImage,
          serviceDiscrioption: Description,
          serviceStDate: SimulatorSessions,
          serviceEndDate: TheorySessions,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        getAllCourse();
        handleClose();
        setcourseImage("");
        setcourseName("");
        setcourseDuration("");
        setDescription("");
        setPracticalSessions("");
        setTheorySessions("");
        setPracticalExam("");
        setTheoryExam("");
        setSimulatorSessions("");
        setprice("");
        setDemo("")
        setcoursePoint([]);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [AllDocumnets,setAllDocument]=useState([]);
  const getdocumentsData=async()=>{
      try {
          let res=await axios.get("https://jaidrivingschool.in/api/admin/getDOCUMENTS");
          if(res.status==200){
              setAllDocument(res.data.success)
          }
      } catch (error) {
          console.log(error);
      }
  }
  useEffect(() => {
    getAllCourse();
    getdocumentsData();
    getAllServiceType();
  }, []);
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallCourse([...filterTable]);
    } else {
      setSearch(e.target.value);
      setallCourse([...nochangedata]);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allCourse.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [show1, setshow1] = useState(false);
  const handleClose1 = () => setshow1(false);
  const handleShow1 = () => setshow1(true);
  const [deleteId, setdeleteId] = useState("");

  const [edit,setedit]=useState({})
  const [edshow,setedshow]=useState(false);
  const handleShowed=(edit)=>{
    setcourseName(edit?.serviceName);
    setcourseDuration(edit?.serviceType);
    setprice(edit?.servicePrice);
    setDescription(edit?.serviceDiscrioption);
    setSimulatorSessions(edit?.serviceStDate);
    setTheorySessions(edit?.serviceEndDate)
    setedshow(true);
  }
  const handCloseed=()=>setedshow(false)
 const updateCourse = async () => {
    try {
      const config = {
        url: "/admin/updateService",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          id:edit?._id,
          authId: admin?._id,
          serviceName: courseName,
          serviceType: courseDuration,
          servicePoint: coursePoint,
          servicePrice: price,
          serviceRequire:PracticalSessions,
          servicePicture: courseImage,
          serviceDiscrioption: Description,
          serviceStDate: SimulatorSessions,
          serviceEndDate: TheorySessions,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        getAllCourse();
        handCloseed();
        setcourseImage("");
        setcourseName("");
        setcourseDuration("");
        setDescription("");
        setPracticalSessions("");
        setTheorySessions("");
        setPracticalExam("");
        setTheoryExam("");
        setSimulatorSessions("");
        setprice("");
        setDemo("")
        setcoursePoint([]);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const addPointofCourse=async()=>{
    try {
      if(!aboutCourse) return alert("Please enter course point")
      const config={
          url: "/admin/addServicePoint",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data:{
          text: aboutCourse ,
          id:edit?._id,
          authId:admin?._id
        }
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully added")
        
        setedit(res.data.success)
        setAboutCourse("")
      }
    } catch (error) {
        alert(error.response.data.error);
      console.log(error);
    }
  }
  const deleteCoursePoint=async(id)=>{
    try {
      const config={
        url: "/admin/removeServicePoint",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data:{

        removeId: id ,
        id:edit?._id,
        authId:admin?._id
      }
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted")
      
      setedit(res.data.success)
    
    }
    } catch (error) {
        alert(error.response.data.error);
      console.log(error);
    }
  }
  
  const deleteCourses=async()=>{
    try {
      const congig={
        url: "/admin/deleteService/"+deleteId+"/"+admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        }
      }
      let res=await axios(congig);
      if(res.status==200){
        alert("Successfully deleted");
        handleClose1();
        getAllCourse();
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const makepopularService=async(id)=>{
    try {
      const congig={
        url: "/admin/makepopularService",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data:{
          authId:admin?._id,
          id:id
        }
      }
      let res=await axios(congig);
      if(res.status==200){
        alert("Successfully popular");
     
        getAllCourse();
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
            <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button > Service Details</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ad-b mt-4" >
                  <button  style={{float:"right",borderRadius:"10px"}} onClick={()=>{
                     setcourseName("");
                     setcourseDuration("");
                     setDescription("");
                     setPracticalSessions("");
                     setTheorySessions("");
                     setPracticalExam("");
                     setTheoryExam("");
                     setSimulatorSessions("");
                     setprice("");
                     setDemo("")
                    handleShow()}}>+Service</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Service Type</th>
                    {/* <th>Service requre</th> */}
                    {/* <th>Theory Session</th> */}

                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Amount</th>
                    <th>Documents Requireds</th>
                    <th>Action</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr> 
                </thead>
                <tbody>
                  {allCourse
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((ele, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <img
                              src={`https://jaidrivingschool.in/Service/${ele?.servicePicture}`}
                              alt=""
                              width={80}
                            />
                          </td>
                          <td>{ele?.serviceName}</td>
                          <td>
                            <p
                              style={{
                                height: "100px",
                                overflow: "hidden",
                                overflowY: "auto",
                              }}
                            >
                              {ele?.serviceDiscrioption ? (parse(ele?.serviceDiscrioption)):""}
                            </p>
                          </td>
                          <td>{ele?.serviceType}  </td>
                          {/* <td>{ele?.serviceRequire}  </td> */}
                          <td>{ele?.serviceStDate}  </td>
                          <td>{ele?.serviceEndDate} </td>
                          {/* <td>{ele?.TheoryExam} </td> */}
                          <td>&#8377;{ele?.servicePrice}</td>
                          <td>
                            <p
                              style={{
                                height: "100px",
                                width: "200px",
                                overflow: "hidden",
                                overflowY: "auto",
                              }}
                            >
                              {ele?.servicePoint?.map((item) => {
                                return (
                                  <p style={{ color: "red" }}>
                                    {"-->"}
                                    <span style={{ color: "green" }}>
                                      {item?.text}
                                    </span>
                                  </p>
                                );
                              })}
                            </p>
                          </td>
                            <td>{ele?.popularService==false ? (<button type="button" class="btn btn-info" onClick={()=>makepopularService(ele?._id)} style={{color:"white"}}>MakePopular</button>):(<span style={{color:"green"}}>Popular</span>)}</td>
                          <td>
                            <img src="./images/editing.png" alt="" width={30} onClick={()=>{
                              setedit(ele);
                              handleShowed(ele)
                            }} />
                          </td>
                          <td>
                            <Button
                              type="button"
                              variant="danger"
                              onClick={() => {
                                setdeleteId(ele?._id);
                                handleShow1();
                              }}
                            >
                              <span>
                                <RiDeleteBin6Line style={{ fontSize: "20" }} />
                              </span>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Service Image</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setcourseImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="vi_0"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Type</label>
                <select name="" id="" className="vi_0" onChange={(e)=>setcourseDuration(e.target.value)}>
                  <option value="">---Select Category----</option>
                  {AllServiceType?.map((item)=>{
                    return ( <option value={item?.ServiceCat}>{item?.ServiceCat}</option>)
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <label>Document Requireds</label>
                <select name="" id="" className="vi_0" onChange={(e)=>setAboutCourse(e.target.value)}>
                  <option value="">---Select Douments----</option>
                  {AllDocumnets?.map((item)=>{
                    return ( <option value={item?.documentT}>{item?.documentT}</option>)
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={addDataAbout}
                  class="btn btn-success"
                >
                  Add
                </button>{" "}
                {coursePoint?.length != 0 ? (
                  <button
                    type="button"
                    style={{margin:"5px"}}
                    onClick={() => setcoursePoint([])}
                    class="btn btn-danger"
                  >
                    Reset
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <ul>
                {coursePoint?.map((ele) => {
                  return <li>{ele?.text}</li>;
                })}
              </ul>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Service  Amount</label>
                <input
                  type="number"
                  min="0"
                  onChange={(e) => setprice(e.target.value)}
                  placeholder="2000"
                  className="vi_1"
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={handleChange2}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
             
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Service Start Date</label>
                  <input
                    type="date"
                    placeholder="Eg:-HOURS"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setSimulatorSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Service End Date</label>
                  <input
                    type="date"
                    placeholder="Eg:-HOURS"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheorySessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AdminService}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
                {/* edit course */}
                <Modal
          show={edshow}
          onHide={handCloseed}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Service Image</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setcourseImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Title</label>
                <input
                  type="text"
                  value={courseName}
                  placeholder={edit?.serviceName}
                  className="vi_0"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Category</label>
                <select name="" id="" className="vi_0" onChange={(e)=>setcourseDuration(e.target.value)}>
                  <option value={courseDuration}>{courseDuration}</option>
                  {AllServiceType?.map((item)=>{
                    return ( <option value={item?.ServiceCat}>{item?.ServiceCat}</option>)
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <label>Documents required</label>
                <select name="" id="" className="vi_0"  onChange={(e)=>setAboutCourse(e.target.value)}>
                  <option value="">---Select Douments----</option>
                  {AllDocumnets?.map((item)=>{
                    return ( <option value={item?.documentT}>{item?.documentT}</option>)
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={addPointofCourse}
                  class="btn btn-success"
                >
                  Add
                </button>{" "}

              </div>
              <ul>
                {edit?.servicePoint?.map((ele) => {
                  return <li>{ele?.text} {" "}<span style={{color:"red",cursor:"pointer"}} onClick={()=>deleteCoursePoint(ele?._id)}><RxCrossCircled/></span></li>;
                })}
              </ul>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Amount</label>
                <input
                  type="number"
                  min="0"
                  value={price}
                  onChange={(e) => setprice(e.target.value)}
                  placeholder={edit?.servicePrice}
                  className="vi_1"
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={handleChange2}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Service Required</label>
                  <input
                    type="text"
                    placeholder={`${edit?.serviceRequire}`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setPracticalSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Service Start Date</label>
                  <input
                    type="date"
                    placeholder={`${edit?.serviceStDate}`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setSimulatorSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Service End Date</label>
                  <input
                    type="date"
                    placeholder={`${edit?.serviceEndDate} `}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheorySessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Practical Exam</label>
                  <input
                    type="number"
                    placeholder={`${edit?.PracticalExam} MINUTES`}
                   
                    className="vi_0"
                    min="0"
                    onChange={(e) => setPracticalExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
             < div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Demo Session</label>
                  <input
                    type="number"
                    placeholder={`${edit?.Demo} MINUTES`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setDemo(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Theory Exam</label>
                  <input
                    type="number"
                    placeholder={`${edit?.TheoryExam} MINUTES`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheoryExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handCloseed}>
              Close
            </Button>
            <Button variant="primary" onClick={updateCourse}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete Model */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ??</Modal.Title>
          </Modal.Header>
       
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteCourses}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};
export default AdminCourse;
