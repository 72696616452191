// import "./App.css";
import "./Appp.css";
import "react-multi-carousel/lib/styles.css";
import "./Navbar.css";
import Navbar from "./Components/Navbar";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SignUpForm from "./Components/Signup";
import "./Components/Home.css";
import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";
import Courses from "./Components/Courses";
import Blog from "./Components/Blog";
import Blog1 from "./Components/Blog1";
import "./Blog1.css";
import Blog2 from "./Components/Blog2";
import "./Traffic.css";
import Contact from "./Components/Contact";
import Faq from "./Components/Faq";
import Rto from "./Components/Rto";
import Aboutus from "./Components/Aboutus";
import Home1 from "./Components/Home1";
import Coursesss from "./Components/Cousesss";
import "./coursesss.css";
import "./Home.css";
import Register from "./Components/Register";
import StaffLogin from "./Components/StaffLogin";
import StudentLogin from "./Components/StudentLogin";
import "./Footer.css";
import BookNow from "./Components/BookNow";
import "./BookNow.css";
import Choose from "./Components/Choose";
import "./Choose.css";
import Continue from "./Components/Continue";
import "./Continue.css";
import "./Instructors.css";
import LeaveUpdate from "./Components/LeaveUpdate";
import Sidebar from "./Components/sidebar/Sidebar";
import Profile from "./Components/Profile";
import Side from "./Components/sidebar/Side";
import Siide from "./Components/sidebar/Siide";
import TimeSlot from "./Components/TimeSlot";
import Me from "./Components/Me";
import Attendence from "./Components/Attendence";
import AddAttendence from "./Components/AddAttendence";
import FormFilling from "./Components/FormFilling";
import LogChoose from "./Components/LogChoose";
import LogInstructors from "./Components/LogInstructors";
import EnquireNow from "./Components/EnquireNow";
import OnlinePayment from "./Components/OnlinePayment";
import ContactEnquireNow from "./Components/ContactEnquiewNow";
import AdminLogin from "./Components/Admin/AdminLogin";
import Dashboard from "./Components/Admin/Dashboard";
import Admindashboard from "./Components/Admin/Admindashboard";
import AdminProfile from "./Components/Admin/AdminProfile";
import AdimnBankDetails from "./Components/Admin/AdminBankDetails";
// import IdCard from "./Components/Admin/IdCard";
import ChangePassword from "./Components/Admin/ChangePassword";
import SubAdmin from "./Components/Admin/SubAdmin";
import Adminhome from "./Components/Admin/Adminhome";
import AdminAboutUs from "./Components/Admin/AdminAboutUs";
import BlogViewMore from "./Components/BlogViewMore";
import PopUp from "./Components/PopUp";
import CourseViewMore from "./Components/CourseViewMore";
import Traffic1 from "./Components/Traffic1";
import GetItNow from "./Components/GetItNow";
import Me1 from "./Components/Me1";
import StartClasses from "./Components/StartClasses";
import Reviews from "./Components/Reviews";
// import Adminhome from './Components/Admin/Adminhome';
// import AdminAboutUs from './Components/Admin/AdminAboutUs';
import AdminTrainer from "./Components/Admin/AdminTrainer";
import AdminStaff from "./Components/Admin/AdminStaff";
import AdminFeatures from "./Components/Admin/AdminFeatures";
import AdminBlog from "./Components/Admin/AdminBlog";
import AdminStudents from "./Components/Admin/AdminStudents";
import AdminCustomer from "./Components/Admin/AdminCustomer";
import AdminCourse from "./Components/Admin/AdminCourse";
import AdminCount from "./Components/Admin/AdminCount";
import AdminCategory from "./Components/Admin/AdminCategory";
import AdminDiscount from "./Components/Admin/AdminDiscount";
import AdminGallery from "./Components/Admin/AdminGallery";
import Branches from "./Components/Admin/Admin/Braches";
import TimSlot from "./Components/TimSlot";
import Instructors from "./Components/Instructors";
import ClassOn from "./Components/ClassOn";
import Verification from "./Components/Verification";
import StudentReviews from "./Components/StudentReviews";
import StudentProfile from "./Components/StudentProfile";
import StudentCourse from "./Components/StudentCourse";
import StudentAttendence from "./Components/StudentAttendence";
import StudentCertificate from "./Components/StudentCertificate";
import StudentPayReceipt from "./Components/StudentPayReceipt";
import StudentServiceStatus from "./Components/StudentServiceStatus";
import StudentCertiPrint from "./Components/StudentCertiPrint";
import UpdateProfile from "./Components/UpdateProfile";
import CustomerWallet from "./Components/CustomerWallet";
import AdminService from "./Components/Admin/AdminService";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import AdminBookService from "./Components/Admin/AdminBookService";
import AdminTimeSlot from "./Components/Admin/AdminTimeSlote";
import AdminOpenning from "./Components/Admin/AdminOpenning";
import AdminEnqire from "./Components/Admin/Admin/AdminEnquire";
import AdminReview from "./Components/Admin/Admin/AdminReview";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TrainerWallet from "./Components/TrainerWallet";
import AdminLeave from "./Components/Admin/Admin/AdminLeave";
import AdminFAQ from "./Components/Admin/AdminFAQ";
import SingleBranch from "./Components/SingleBranch";
import VehicleType from "./Components/Admin/VehicleType";
import Additional from "./Components/Admin/Additional";

//vendor
import VendorDashboard from "./Components/Vendor/VendorDashboard";
import VendorAboutUs from "./Components/Vendor/VendorAboutus";
import VendorOpeningHours from "./Components/Vendor/VendorOpeningHours";
import VendorSlot from "./Components/Vendor/VendorSlot";
import VendorAddVechile from "./Components/Vendor/VendorAddVechile";
import VendorTrainner from "./Components/Vendor/VendorTrainner";
import VendorStaff from "./Components/Vendor/VendorStaff";
import VendorBlog from "./Components/Vendor/VendorBlog";
import VendorBookCourse from "./Components/Vendor/VendorBookCourse";
import VendorOtherBookcourses from "./Components/Vendor/VendorOtherBookcourses";
import VendorBookService from "./Components/Vendor/VendorBookService";
import VendorOtherBookService from "./Components/Vendor/VendorOtherBookService";
import VendorEnquire from "./Components/Vendor/VendorEnquire";
import VendorBranch from "./Components/Vendor/VendorBranch";
import VendorSubadmin from "./Components/Vendor/VendorSubadmin";
import VendorLogin from "./Components/Vendor/VendorLogin";
import VdashBoard from "./Components/Vendor/Dashboard";
import VendorAddTimeSlot from "./Components/Vendor/VendorAddTimeSlote";
import AdminSafetyRules from "./Components/Admin/AdminSafetyRules";
import AdminExpense from "./Components/Admin/AdminExpense";
import AdminRtolist from "./Components/Admin/AdminRtolist";
import AdminLLAplication from "./Components/Admin/AdminLLAplication";
import AdminDLAplication from "./Components/Admin/AdminDLApplication";
import Adminform5 from "./Components/Admin/Adminform5";
import { AdminForm5 } from "./Components/Admin/AdminnForm5";
import AdminForm15 from "./Components/Admin/AdminForm15";
import { AdminnForm15 } from "./Components/Admin/AdminnForm15";
import AdminForm14 from "./Components/Admin/AdminForm14";
import { AdminnForm14 } from "./Components/Admin/AdminnForm14";
import AdminDetails from "./Components/Admin/AdminDetails";
import AdminRtoCFPayment from "./Components/Admin/AdminRtoCFPayment";
import AdminRTOwebsitepayment from "./Components/Admin/AdminRTOwebsitepayment";
import IdCard from "./Components/IdCard";
import StaffIdCard from "./Components/StaffIDCard";
import Payrescietdate from "./Components/payrescietdate";
import Invoice from "./Components/Invoice";
import Footer1 from "./Components/Footer1";
import Header from "./Components/Header";
import "aos/dist/aos.css";
import AdminOtherServices from "./Components/Admin/AdminOtherServices";
import AdminCoursetopics from "./Components/Admin/AdminCoursetopics";
import AdminServicetopics from "./Components/Admin/AdminServicestopics";
import AdminOnedayCourse from "./Components/Admin/AdminOnedayCourse";
import AdminGenerateRescipt from "./Components/Admin/AdminGenerateRescipt";
import VendorExpanse from "./Components/Vendor/VendorExpanse";
import VendorAcountHistory from "./Components/Vendor/VendorAcountHistory";
import VendorForm5 from "./Components/Vendor/VendorForm5";
import { VenderView5 } from "./Components/Vendor/VendorView5";
import VendorForm14 from "./Components/Vendor/VendorForm14";
import { VendorView14 } from "./Components/Vendor/VendorView14";
import VendorForm15 from "./Components/Vendor/VendorForm15";
import { VendorVeiw15 } from "./Components/Vendor/VendorView15";
import VendorRtoCFPayment from "./Components/Vendor/VendorCFPayment";
import VendorRtoWebSite from "./Components/Vendor/VendorRtoWebSite";
import VendorRtoList from "./Components/Vendor/VendorRtoList";
import VendorOtherService from "./Components/Vendor/VendorOtherService";
import VendorDlApplication from "./Components/Vendor/VendorDlApplication";
import VendorLLApplication from "./Components/Vendor/VendorLLApplicatio";
import VendorOneDayCourse from "./Components/Vendor/VendorOneDayCourse";
import UserOneDayCourse from "./Components/UserOneDayCourse";
import AdminLogo from "./Components/Admin/AdminLogo";
import ServiceCategory from "./Components/Admin/ServiceCategory";
import VendorGenrateRecipt from "./Components/Vendor/VendorGenrateRecipt";
import AdminInvoice from "./Components/Admin/AdminPayInvoice";
import AdminTrainerProfile from "./Components/Admin/AdminTrainerProfile";
import AdminFeedBack from "./Components/Admin/AdminFeedBack";
import VendorTrainerProfile from "./Components/Vendor/VendorTrainerProfile";
import { AdminCertificate } from "./Components/Admin/AdminCertificate";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home1 />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/gallery"
            element={
              <>
                <Navbar />
                <Gallery />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/courses"
            element={
              <>
                <Navbar />
                <Courses />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/blog"
            element={
              <>
                <Navbar />
                <Blog />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/blog1"
            element={
              <>
                <Navbar />
                <Blog1 />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/postblog"
            element={
              <>
                <Navbar />
                <Blog2 />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/traffic"
            element={
              <>
                <Navbar />
                <Traffic1 />
                <Footer1 />
              </>
            }
          />

          <Route
            path="/SingleBranch/:id"
            element={
              <>
                <Navbar />
                <SingleBranch />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Navbar />
                <Contact />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <Navbar />
                <Faq />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/rto"
            element={
              <>
                <Navbar />
                <Rto />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Navbar />
                <Aboutus />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/coursess"
            element={
              <>
                <Navbar />
                <Coursesss />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <Navbar />
                <Register />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/stafflogin"
            element={
              <>
                <Navbar />
                <StaffLogin />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/studentlogin"
            element={
              <>
                <Navbar />
                <StudentLogin />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/popup"
            element={
              <>
                <Navbar />
                <PopUp />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/choose"
            element={
              <>
                <Navbar />
                <Choose />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/continue"
            element={
              <>
                <Navbar />
                <Continue />
                <Footer1 />
              </>
            }
          />

          <Route
            path="/leave"
            element={
              <>
                <Navbar />
                <LeaveUpdate />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/sidbar"
            element={
              <>
                <Navbar />
                <Sidebar />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/pro"
            element={
              <>
                <Navbar />
                <Profile />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/side"
            element={
              <>
                <Navbar />
                <Side />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/siide"
            element={
              <>
                <Navbar />
                <Siide />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/timeslot"
            element={
              <>
                <Navbar />
                <TimeSlot />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/Trainer-Wallet"
            element={
              <>
                <Navbar />
                <TrainerWallet />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/me1"
            element={
              <>
                <Navbar />
                <Me1 />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/me"
            element={
              <>
                <Navbar />
                <Me />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/attendence"
            element={
              <>
                <Navbar />
                <Attendence />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/addattendence"
            element={
              <>
                <Navbar />
                <AddAttendence />
                <Footer1 />{" "}
              </>
            }
          />
          <Route
            path="/formfilling"
            element={
              <>
                <Navbar />
                <FormFilling />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/logchoose"
            element={
              <>
                <Navbar />
                <LogChoose />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/loginstructors"
            element={
              <>
                <Navbar />
                <LogInstructors />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/enquirenow"
            element={
              <>
                <Navbar />
                <EnquireNow />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/aboutus"
            element={
              <>
                <Navbar />
                <Aboutus />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/onlinepayment"
            element={
              <>
                <Navbar />
                <OnlinePayment />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/opayment"
            element={
              <>
                <Navbar />
                <Home1 />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/contactenquirenow"
            element={
              <>
                <Navbar />
                <ContactEnquireNow />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/blogviewmore/:id"
            element={
              <>
                <Navbar />
                <BlogViewMore />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/courseviewmore"
            element={
              <>
                <Navbar />
                <CourseViewMore />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/getitnow"
            element={
              <>
                <Navbar />
                <GetItNow />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/startclasses"
            element={
              <>
                <Navbar />
                <StartClasses />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/reviews"
            element={
              <>
                <Navbar />
                <Reviews />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/booknow"
            element={
              <>
                <Navbar />
                <BookNow />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/timslot"
            element={
              <>
                <Navbar />
                <TimSlot />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/instructors"
            element={
              <>
                <Navbar />
                <Instructors />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/studentidcard"
            element={
              <>
                <Navbar />
                <IdCard />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/staffidcard"
            element={
              <>
                <Navbar />
                <StaffIdCard />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/classon"
            element={
              <>
                <Navbar />
                <ClassOn />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/verification"
            element={
              <>
                <Navbar />
                <Verification />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/sreviews"
            element={
              <>
                <Navbar />
                <StudentReviews />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/sprofile"
            element={
              <>
                <Navbar />
                <StudentProfile />
                <Footer1 />
              </>
            }
          />
           <Route
            path="/Customer-One-Day-Course"
            element={
              <>
                <Navbar />
                <UserOneDayCourse />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/updateprofile"
            element={
              <>
                <Navbar />
                <UpdateProfile />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/scourse"
            element={
              <>
                <Navbar />
                <StudentCourse />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/sattendence"
            element={
              <>
                <Navbar />
                <StudentAttendence />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/scertificate"
            element={
              <>
                <Navbar />
                <StudentCertificate />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/scertiprint"
            element={
              <>
                <Navbar />
                <StudentCertiPrint />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/invoice"
            element={
              <>
                <Navbar />
                <Invoice />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/spay"
            element={
              <>
                <Navbar />
                <Payrescietdate />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/sservice"
            element={
              <>
                <Navbar />
                <StudentServiceStatus />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/customer-wallet"
            element={
              <>
                <Navbar />
                <CustomerWallet />
                <Footer1 />
              </>
            }
          />
          <Route
            path="/privacy"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />

          <Route
            path="/admin"
            element={
              <>
                <AdminLogin />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                children={
                  <>
                    <Admindashboard />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin-gallery"
            element={
              <Dashboard
                children={
                  <>
                    <AdminGallery />
                  </>
                }
              />
            }
          />
           <Route
            path="/admin-service-category"
            element={
              <Dashboard
                children={
                  <>
                    <ServiceCategory />
                  </>
                }
              />
            }
          />
           <Route
            path="/admin-triner-profile"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTrainerProfile />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin-additional-charges"
            element={
              <Dashboard
                children={
                  <>
                    <Additional />
                  </>
                }
              />
            }
          />
           <Route
            path="/admin-home"
            element={
              <Dashboard
                children={
                  <>
                    <AdminLogo />
                  </>
                }
              />
            }
          />
          <Route
            path="/count"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCount />
                  </>
                }
              />
            }
          />
          <Route
            path="/branches"
            element={
              <Dashboard
                children={
                  <>
                    <Branches />
                  </>
                }
              />
            }
          />
           <Route
            path="Other_Application"
            element={<Dashboard children={<AdminOtherServices />} />}
          />
             <Route
            path="/Admin-feedback"
            element={<Dashboard children={<AdminFeedBack />} />}
          />
            <Route
            path="/course_topics"
            element={<Dashboard children={<AdminCoursetopics />} />}
          />
          <Route
            path="/service_topics"
            element={<Dashboard children={<AdminServicetopics />} />}
          />
          <Route
            path="/admin-invoice"
            element={<AdminInvoice />}
          />
            <Route
            path="/admin_one_day_course"
            element={<Dashboard children={<AdminOnedayCourse />} />}
          />
           <Route
            path="/generate_rescipt"
            element={
              <Dashboard
                children={
                  <>
                    <AdminGenerateRescipt />
                  </>
                }
              />
            }
          />
          <Route
            path="/category"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCategory />
                  </>
                }
              />
            }
          />
          <Route
            path="/AdminDiscount"
            element={
              <Dashboard
                children={
                  <>
                    <AdminDiscount />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin-safety-rules"
            element={
              <Dashboard
                children={
                  <>
                    <AdminSafetyRules />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminhome"
            element={
              <Dashboard
                children={
                  <>
                    <Adminhome />
                  </>
                }
              />
            }
          />
          <Route
            path="/admintrainer"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTrainer />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin-vehicle-type"
            element={
              <Dashboard
                children={
                  <>
                    <VehicleType />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminstaff"
            element={
              <Dashboard
                children={
                  <>
                    <AdminStaff />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminfeatures"
            element={
              <Dashboard
                children={
                  <>
                    <AdminFeatures />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminblog"
            element={
              <Dashboard
                children={
                  <>
                    <AdminBlog />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminstudent"
            element={
              <Dashboard
                children={
                  <>
                    <AdminStudents />
                  </>
                }
              />
            }
          />
          <Route
            path="/admincustomer"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCustomer />
                  </>
                }
              />
            }
          />
          <Route
            path="/Admin-Blog"
            element={
              <Dashboard
                children={
                  <>
                    <AdminBlog />
                  </>
                }
              />
            }
          />
          <Route
            path="/admincourses"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCourse />
                  </>
                }
              />
            }
          />
          <Route
            path="/subadmin"
            element={<Dashboard children={<SubAdmin />} />}
          />
          <Route
            path="/Rto_list"
            element={<Dashboard children={<AdminRtolist />} />}
          />
          <Route
            path="/LL_Application"
            element={<Dashboard children={<AdminLLAplication />} />}
          />
          <Route
            path="/DL_Application"
            element={<Dashboard children={<AdminDLAplication />} />}
          />
          <Route
            path="form5"
            element={<Dashboard children={<Adminform5 />} />}
          />
          <Route
            path="form15"
            element={<Dashboard children={<AdminForm15 />} />}
          />
          <Route
            path="form14"
            element={<Dashboard children={<AdminForm14 />} />}
          />
          <Route
            path="admin_form5"
            element={<Dashboard children={<AdminForm5 />} />}
          />
          <Route
            path="admin_form15"
            element={<Dashboard children={<AdminnForm15 />} />}
          />
          <Route
            path="admin_form14"
            element={<Dashboard children={<AdminnForm14 />} />}
          />
          <Route
            path="admindetails"
            element={<Dashboard children={<AdminDetails />} />}
          />
          <Route
            path="RTO_CF_Payment"
            element={<Dashboard children={<AdminRtoCFPayment />} />}
          />
          <Route
            path="RTO_Website_Payment"
            element={<Dashboard children={<AdminRTOwebsitepayment />} />}
          />
          <Route
            path="/adminaboutus"
            element={<Dashboard children={<AdminAboutUs />} />}
          />

          <Route
            path="/profile"
            element={<Dashboard children={<AdminProfile />} />}
          />

          <Route
            path="/bank-details"
            element={<Dashboard children={<AdimnBankDetails />} />}
          />
          <Route
            path="/Admin-service"
            element={<Dashboard children={<AdminService />} />}
          />
          <Route
            path="/Admin-Leaves"
            element={<Dashboard children={<AdminLeave />} />}
          />
          <Route
            path="/Admin-FAQ"
            element={<Dashboard children={<AdminFAQ />} />}
          />
          <Route
            path="/Admin-Enquires"
            element={<Dashboard children={<AdminEnqire />} />}
          />
          <Route
            path="/Admin-Time-Slots"
            element={<Dashboard children={<AdminTimeSlot />} />}
          />
          <Route
            path="/Admin-Opening-Hour"
            element={<Dashboard children={<AdminOpenning />} />}
          />
          <Route
            path="/Book-services"
            element={<Dashboard children={<AdminBookService />} />}
          />
          <Route
            path="/Admin-Reviews"
            element={<Dashboard children={<AdminReview />} />}
          />
          <Route path="/Admin-Certificate" element={<AdminCertificate/>}/>
          <Route
            path="/Admin-expanse"
            element={<Dashboard children={<AdminExpense />} />}
          />
          <Route
            path="/change-password"
            element={<Dashboard children={<ChangePassword />} />}
          />
          {/* Vendor Routes */}
          <Route path="/branch-login" element={<VendorLogin />} />

          <Route
            path="/vendor-opening-hours"
            element={<VendorDashboard children={<VendorOpeningHours />} />}
          />
          <Route
            path="/vendor-time-slot"
            element={<VendorDashboard children={<VendorSlot />} />}
          />
          <Route
            path="/vendor-add-vechile"
            element={<VendorDashboard children={<VendorAddVechile />} />}
          />
          <Route
            path="/vendor-trainner"
            element={<VendorDashboard children={<VendorTrainner />} />}
          />
          <Route
            path="/branch-account-history"
            element={<VendorDashboard children={<VendorAcountHistory />} />}
          />
           <Route
            path="/vendor-staff"
            element={<VendorDashboard children={<VendorStaff />} />}
          />
               <Route
            path="/Branch-Trainer-Profile"
            element={<VendorDashboard children={<VendorTrainerProfile />} />}
          />
          <Route
            path="/vendor-blog"
            element={<VendorDashboard children={<VendorBlog />} />}
          />
          <Route
            path="/vendor-book-course"
            element={<VendorDashboard children={<VendorBookCourse />} />}
          />
            <Route
            path="/vendor-expanse"
            element={<VendorDashboard children={<VendorExpanse />} />}
          />

          <Route
            path="/vendor-other-book-course"
            element={<VendorDashboard children={<VendorOtherBookcourses />} />}
          />
               <Route
            path="/branch-genrate-recipt"
            element={<VendorDashboard children={<VendorGenrateRecipt />} />}
          />
          <Route
            path="/vendor-book-service"
            element={<VendorDashboard children={<VendorBookService />} />}
          />
          <Route
            path="/vendor-other-book-service"
            element={<VendorDashboard children={<VendorOtherBookService />} />}
          />
          <Route
            path="/vendor-enquire"
            element={<VendorDashboard children={<VendorEnquire />} />}
          />
          <Route
            path="/vendor-branch"
            element={<VendorDashboard children={<VendorBranch />} />}
          />
           <Route
            path="/branch-form5"
            element={<VendorDashboard children={<VendorForm5 />} />}
          />
           <Route
            path="/branch-form14"
            element={<VendorDashboard children={<VendorForm14 />} />}
          />
            <Route
            path="/branch-form15"
            element={<VendorDashboard children={<VendorForm15 />} />}
          />
                <Route
            path="/branch-view-form14"
            element={<VendorDashboard children={<VendorView14 />} />}
          />
            <Route
            path="/branch-view-form15"
            element={<VendorDashboard children={<VendorVeiw15 />} />}
          />
          <Route
            path="/branch-Rto-CFPayment"
            element={<VendorDashboard children={<VendorRtoCFPayment />} />}
          />
           <Route
            path="/branch-Rto-Websit-Payment"
            element={<VendorDashboard children={<VendorRtoWebSite />} />}
          />
           <Route
            path="/branch-Rto-List"
            element={<VendorDashboard children={<VendorRtoList />} />}
          />
           <Route
            path="/branch-Other-Service"
            element={<VendorDashboard children={<VendorOtherService />} />}
          />
          <Route
            path="/branch-One-Day-Course"
            element={<VendorDashboard children={<VendorOneDayCourse />} />}
          />
           <Route
            path="/branch-DL-Application"
            element={<VendorDashboard children={<VendorDlApplication />} />}
          />
             <Route
            path="/branch-LL-Application"
            element={<VendorDashboard children={<VendorLLApplication />} />}
          />
              <Route
            path="/branch-view-form5"
            element={<VendorDashboard children={<VenderView5 />} />}
          />
          <Route
            path="/vendor-subadmin"
            element={<VendorDashboard children={<VendorSubadmin />} />}
          />
          <Route
            path="/Branch-dashboard"
            element={<VendorDashboard children={<VdashBoard />} />}
          />
          <Route
            path="/branch-time-slot"
            element={<VendorDashboard children={<VendorAddTimeSlot />} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
