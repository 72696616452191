import React, { useEffect, useState } from "react";
import Side from "./sidebar/Side";
import { useLocation } from "react-router-dom";
import axios from "axios";
import StarRatings from "react-star-ratings";
const StudentReviews = () => {
  const {state}=useLocation();
  console.log("state",state)
  const [customer,setcustomer]=useState("")
  const user=JSON.parse(sessionStorage.getItem("staff"));
  const token=sessionStorage.getItem("token")
  const [comment,setcomment]=useState("");
const [rating,setratting]=useState(0)
  const addRatting= async()=>{
    try {
      if(rating==0){
        return alert("Please give ratting")
      }
      const config = {
        url: "/user/AddRatting",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          trainerName:user?.name,trainerImage:user?.profile,customerName:state?.classP?.StudentName,customerImage:state?.oldData?.course?.customerId?.profile,remark:comment,ratting:rating,giveId:state?.classP?.customerId,givenBy:user?._id,classId:state?.classP?._id
        },
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Thanks for your response")
        window.location.assign("/attendence")
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Side />
      <h2 className="sdfgh_95 text-center mt-3">Student Reviews</h2>
      <div className="container mt-3">
        <div className="row me-0">
          <div
            className="col-md-8 sdfjk_25"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div>
              <img
                src={`https://jaidrivingschool.in/Customer/${state?.oldData?.course?.customerId?.profile}`}
                alt=""
                height={150}
                width={240}
              />
            </div>
            <div className="mt-4">
              <h6>
                Name &nbsp; &nbsp; &nbsp;: &nbsp; &nbsp; &nbsp;{state?.classP?.StudentName}
              </h6>
              <h6>
                Location&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;
                &nbsp; &nbsp; {state?.oldData?.course?.customerId?.city}
              </h6>
              <h6>
                Topic&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;: &nbsp; &nbsp; &nbsp; {state?.classP?.classTopic}
              </h6>
              <h6>
                Ratings&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:
                &nbsp; &nbsp; &nbsp;  <StarRatings
        rating={rating}
        starDimension="25px"
        changeRating={setratting}
        starSpacing="5px"
        starRatedColor="#fcca0b"
      />
              </h6>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container"style={{marginLeft:"140px"}} >
          <div className="row">
            <div
              className="col-md-6 mt-3 "
            > */}
      <textarea
        id="Stextarea"
        name=""
        onChange={(e)=>setcomment(e.target.value)}
        placeholder="Feedback"
        cols={57}
        rows={5}
      ></textarea>
      {/* </div>

          </div>
        </div> */}
      <div
        className="col-md-8 mt-3 mb-3"
        id="Sbutton"
        style={{ marginLeft: "11rem" }}
      >
        <button type="submit" className="btn btn-warning text-center" onClick={addRatting}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default StudentReviews;
