import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminAboutUs = () => {
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDescription1(data);
  };
  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setDescription2(data);
  };
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setDescription3(data);
  };
  const handleChange3 = (e, editor) => {
    const data = editor.getData();
    setDescriptionF(data);
  };
  const handleChange4 = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [allAbout, setAllAbout] = useState([]);
  const getAboutUs = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getAbout");
      if (res.status == 200) {
        setAllAbout(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  const [BackGround, setBackGround] = useState("");
  const [Description1, setDescription1] = useState("");
  const [Description2, setDescription2] = useState("");
  const [Description3, setDescription3] = useState("");
  const [DescriptionF, setDescriptionF] = useState("");
  const [Image, setImage] = useState("");
  const [Description, setDescription] = useState("");


  const addAbouts = async () => {
    try {
      const config = {
        url: "/admin/AddAbout",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          HomeDis1: Description1,
          HomeDis2: Description2,
          HomeDis3: Description3,
          Aboutdis: Description,
          footerDis: DescriptionF,
          image: Image,
          homeImage: BackGround,
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success)
        getAboutUs();
        handleClose();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [deleteData,setdeleteData]=useState({});
  const [show4, setshow4] = useState(false);

  const handleClose4 = () => setshow4(false);
  const handleshow4 = () => setshow4(true);

  const deleteDataAboutus=async()=>{
    try {
      const config = {
        url: "/admin/updateAboutus",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: deleteData
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully deleted");
        handleClose4();
        getAboutUs()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="add-gr">
        <div className="container">
        <div className="row">
                <div className="col-md-8">
                <h3>About Us </h3>
                </div>
                <div className="col-md-4">
                      <div className="ad-b mt-4 mb-4" style={{ float: "right" }}>
            <button style={{ borderRadius:"10px"}} onClick={handleShow}>+ Home Page</button>
            <button style={{ borderRadius:"10px"}} onClick={handleShow3} >+ About Page</button>
          </div>
                    </div>
            </div>
        </div>

        <div className="container">
          <h4 className="text-center">About Us Data For Home Page</h4>
          <div className="mo-gra mt-5 scrolll_bar">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Content</th>
                  <th colSpan={3}></th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {allAbout?.map((ele) => {
                return (
                  <tbody>
                    {ele?.homeImage ? (   <tr height={60}>
                      <th width={150}>BackGround Image</th>
                      <td colSpan={3} width={600}>
                        {ele?.homeImage ? (<img
                          src={`https://jaidrivingschool.in/About/${ele?.homeImage}`}
                          alt=""
                          width={100}
                          height={50}
                        />) : (<></>)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({homeImage:""})
                          handleshow4();
                        }} >
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                  
                    </tr>):(<></>)}
                 
                    {ele?.HomeDis1 ? ( <tr height={60}>
                      <th width={150}>Description1</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.HomeDis1}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({HomeDis1:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                    
                    {ele?.HomeDis2 ? ( <tr height={60}>
                      <th width={150}>Description2</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.HomeDis2}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={handleShow} />
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({HomeDis2:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                   
                    {ele?.HomeDis3 ? ( <tr height={60}>
                      <th width={150}>Description3</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.HomeDis3}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({HomeDis3:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                   
                    {ele?.footerDis ? (    <tr height={60}>
                      <th width={150}>Footer Description</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.footerDis}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={handleShow}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({footerDis:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                
                  </tbody>
                )
              })}

            </Table>
          </div>
        </div>
        <div className="container">
          <h4 className="text-center">About Us Data For About Us Page</h4>
          <div className="mo-gra mt-5 scrolll_bar">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Content</th>
                  <th colSpan={3}></th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {allAbout?.map((ele) => {
                return (
                  <tbody>
                    {ele?.image ? ( <tr height={60}>
                      <th width={150}> Image</th>
                      <td colSpan={3} width={600}>
                        {ele?.image ? (<img
                          src={`https://jaidrivingschool.in/About/${ele?.image}`}
                          alt=""
                          width={100}
                          height={50}
                        />) : (<></>)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={handleShow3}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({image:""})
                          handleshow4();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                   
                    {ele?.Aboutdis ? (   <tr height={60}>
                      <th width={150}>Description</th>
                      <td colSpan={3}>
                      {parse(`<div>${ele?.Aboutdis}</div>`)}

                      </td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30}  onClick={handleShow3}/>
                      </td>
                      <td>
                        <Button type="button" variant="danger" onClick={()=>{
                          setdeleteData({Aboutdis:""})
                          handleshow4();
                        }} >
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                      </td>
                    </tr>):(<></>)}
                 

                  </tbody>
                )
              })}

            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Home About us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label htmlFor="upload1">BackGround Image</label>
              <input type="file" name="file" id="upload1" className="vi_0" onChange={(e) => setBackGround(e.target.files[0])} />
            </div>
            <div className="do-sear mt-2">
              <label>Description1</label>
              <CKEditor
                editor={ClassicEditor}
                data={Description1}
                onChange={handleChange}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Description2</label>
              <CKEditor
                editor={ClassicEditor}
                data={Description2}
                onChange={handleChange1}
              />
            </div>
            <div className="do-sear mt-2">
              <label>Description3</label>
              <CKEditor
                editor={ClassicEditor}
                data={Description3}
                onChange={handleChange2}
              />

            </div>
            <div className="do-sear mt-2">
              <label>Footer Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={DescriptionF}
                onChange={handleChange3}
              />

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={addAbouts}>Submit</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title> About us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label htmlFor="upload2">BackGround Image</label>
              <input type="file" className="vi_0" name="file" id="upload2" onChange={(e) => setImage(e.target.files[0])} />
            </div>
            <div className="do-sear mt-2">
              <label>Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={Description}
                onChange={handleChange4}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
            <Button variant="primary" onClick={addAbouts}>Submit</Button>
          </Modal.Footer>
        </Modal>


        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title> Are you sure delete ?</Modal.Title>
          </Modal.Header>
         
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteDataAboutus}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminAboutUs;
