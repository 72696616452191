import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, InputGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { AiFillEye, AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";

const VendorForm14 = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // EnrollmentNumber,Name,garduianName,PermanentAddress,TemporaryAddress,OfficialAddress,DateofBirth,ClassofVehicle,DateofEnrollment,LLNDateExpire,DateofComplition,DateofPassing,aboutDL,Remarks
    const [EnrollmentNumber,setEnrollmentNumber]=useState("");
    const [Name,setName]=useState("");
    const [garduianName,setgarduianName]=useState("");
    const [PermanentAddress,setPermanentAddress]=useState("");
    const [TemporaryAddress,setTemporaryAddress]=useState("");
    const [OfficialAddress,setOfficialAddress]=useState("");
    const [DateofBirth,setDateofBirth]=useState("");
    const [ClassofVehicle,setClassofVehicle]=useState("");
    const [DateofEnrollment,setDateofEnrollment]=useState("");
    const [LLNDateExpire,setLLNDateExpire]=useState("");
    const [DateofComplition,setDateofComplition]=useState("");
    const [DateofPassing,setDateofPassing]=useState("");
    const [aboutDL,setaboutDL]=useState("");
    const [Remarks,setRemarks]=useState("");
    const [TraineePhoto,setTraineePhoto]=useState("");
    const admin=JSON.parse(sessionStorage.getItem("branch"));
    const token=sessionStorage.getItem("token");
    const [regiYear,setregiYear]=useState("");
    const [allData,setAlldata]=useState([]);
const [noschangedata,setnoschangedata]=useState([])
    const getForm15=async()=>{
        try {
            let ab =admin?.branchId ? admin?.branchId: admin?._id
            let res=await axios.get("https://jaidrivingschool.in/api/admin/getAllForm14ByBranchId/"+ab);
            if(res.status==200){
                setAlldata(res.data.success)
                setnoschangedata(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleFilter = (e) => {
      if (e.target.value != "") {
     
        const filterTable = noschangedata.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
        setAlldata([...filterTable]);
      } else {
     
        setAlldata([...noschangedata]);
      }
    };
    const createform14=async()=>{
        try {
            let ab =admin?.branchId ? admin?.branchId: admin?._id
            if(!TraineePhoto|!EnrollmentNumber|!Name|!garduianName|!PermanentAddress|!DateofBirth|!ClassofVehicle|!DateofEnrollment) return alert("Please fill the form")
          const config={
            url: "/admin/createform14",
            method: "post",
            baseURL: "https://jaidrivingschool.in/api",
          
            headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
             data: {authId:admin?._id,TraineePhoto:TraineePhoto,EnrollmentNumber,Name,garduianName,PermanentAddress,TemporaryAddress,OfficialAddress,DateofBirth,ClassofVehicle,DateofEnrollment,LLNDateExpire,DateofComplition,DateofPassing,aboutDL,Remarks,regiYear,branchId:ab,branchSId:admin?.branchName}
          }
          let res=await axios(config);
          if(res.status==200){
            alert("Successfully added");
            handleClose();
            getForm15()
          }
        } catch (error) {
          alert(error.response.data.error)
          console.log(error);
        }
      }

   

useEffect(()=>{
    getForm15()
},[])
    return (
        <>
            <div className="add-gr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ad-b mt-4">
                                <button>Form 14</button>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ad-b mt-4">
                                <button style={{ borderRadius: "10px", float: "right" }} onClick={handleShow}>+Add</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="mo-gra mt-5 scrolll_bar">
                        <div className="row">
                            <div className="col-md-6">
                            <InputGroup >
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ height: "45px" }}
                  onChange={handleFilter}
                />
              </InputGroup>
                            </div>
                        </div>
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>S.No</th>
                                    <th rowSpan={2}>Trainee Photo</th>
                                    <th rowSpan={2}>Enrollment Number </th>
                                    <th rowSpan={2}>Name</th>
                                    <th rowSpan={2}>Son/Wife/Daughter of</th>

                                    <th colSpan={3}>Address</th>
                                    <th rowSpan={2}>Date of Birth</th>
                                    <th rowSpan={2}>Class of Vehicle</th>
                                    <th rowSpan={2}>Date of Enrollment</th>
                                    <th rowSpan={2}>Learning Licence No. and Date of Expiry</th>
                                    <th rowSpan={2}>Date of Complition of the course</th>
                                    <th rowSpan={2}>Date of Passing of the test of competance to drive</th>
                                    <th rowSpan={2}>Driving Licence number and date...</th>
                                    <th rowSpan={2}>Remarks</th>
                                    <th rowSpan={2}>View Form</th>
                                </tr>
                                <tr>
                                    <th>Permanent Address</th>
                                    <th>Temporary Address</th>
                                    <th>Official Address (if Any)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allData?.map((item,i)=>{
                                    return (
                                         <tr>
                                    <td>{i+1}</td>
                                    <td><img src={`https://jaidrivingschool.in/RTO/${item?.TraineePhoto}`} alt="img" style={{ height: "75px", width: "75px" }} /></td>
                                    <td> {item?.EnrollmentNumber}</td>
                                    <td>{item?.Name}</td>
                                    <td>{item?.garduianName}</td>
                                    <td>{item?.PermanentAddress}</td>
                                    <td>{item?.TemporaryAddress}</td>
                                    <td>{item?.OfficialAddress}</td>
                                    <td>{moment(item?.DateofBirth).format("DD/MM/YYYY")}</td>
                                    <td>{item?.ClassofVehicle}</td>
                                    <td>{item?.DateofEnrollment} </td>
                                    <td>{item?.LLNDateExpire}</td>
                                    <td>{item?.DateofComplition}</td>
                                    <td>{item?.DateofPassing}</td>
                                    <td>
                                        {item?.aboutDL}
                                        {/* <Table bordered  >
                                            <tbody>
                                                <tr><th>DL Number</th>
                                                    <td>DL82781e27</td>
                                                </tr>
                                                <tr>
                                                    <th>Date of Issue</th>
                                                    <td>29/02/2020</td>
                                                </tr>
                                                <tr>
                                                    <th>licensing authority</th>
                                                    <td>RT Nagar</td>
                                                </tr>
                                            </tbody>
                                        </Table> */}
                                    </td>
                                    <td>{item?.Remarks}</td>
                                    <td><Link to="/branch-view-form14" state={{item:item}}>
                                        <AiFillEye  style={{fontSize:"25px"}}/>
                                    </Link>
                                    </td>
                                </tr>
                                    )
                                })}
                               

                            </tbody>
                        </Table>

                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Form 14</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label>Trainee Photo</label>
                        <input type="file" className="vi_0" accept="images/*" name="file" onChange={(e)=>setTraineePhoto(e.target.files[0])} placeholder="Please enter Branch Address" />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Enrollment Number</label>
                        <input type="text" className="vi_0" value={EnrollmentNumber} onChange={(e)=>setEnrollmentNumber(e.target.value)} placeholder="Please enter Enrollment Number" />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Enrollment Year</label>
                        <input type="text" className="vi_0" value={regiYear} onChange={(e)=>setregiYear(e.target.value)} placeholder="Please enter Enrollment Year" />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Name</label>
                        <input type="text" className="vi_0" placeholder="Please enter Name of trainee" value={Name} onChange={(e)=>setName(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Son/Wife/Daughter of</label>
                        <input type="text" className="vi_0" placeholder="Please enter Son/Wife/Daughter of" value={garduianName} onChange={(e)=>setgarduianName(e.target.value)}/>
                    </div>

                    <div className="do-sear mt-2">
                        <label style={{ textAlign: "center" }}>Address</label><br />
                        <div className="row " style={{ marginLeft: "-12px" }}>
                            <div className="col-md-12">
                                <label htmlFor="">Permanent Address</label>
                                <textarea rows={3} cols={10} className="vi_0" placeholder="Please enter Permanent Address" value={PermanentAddress} onChange={(e)=>setPermanentAddress(e.target.value)}/>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="">Temporary Address</label>
                                <textarea rows={3} cols={10} className="vi_0" placeholder="Please enter Temporary Address" value={TemporaryAddress} onChange={(e)=>setTemporaryAddress(e.target.value)} />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="">Official Address (if Any)</label>
                                <textarea rows={3} cols={10} className="vi_0" placeholder="Please enter Official Address" value={OfficialAddress} onChange={(e)=>setOfficialAddress(e.target.value)} />
                            </div>

                        </div>



                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Birth</label>
                        <input type="date" className="vi_0" placeholder="Please enter Class Of Vehicle " value={DateofBirth} onChange={(e)=>setDateofBirth(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Class of Vehicle</label>
                        <input type="text" className="vi_0" placeholder="Please enter Class of Vehicle" value={ClassofVehicle} onChange={(e)=>setClassofVehicle(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Enrollment</label>
                        <input type="date" className="vi_0" placeholder="Please enter Signature / Name of  Trainer"  value={DateofEnrollment} onChange={(e)=>setDateofEnrollment(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Learning Licence No. and Date of Expiry</label>
                        <input type="text" className="vi_0" placeholder="Please enter Learning Licence No. and Date of Expiry"  value={LLNDateExpire} onChange={(e)=>setLLNDateExpire(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Complition of the course</label>
                        <input type="date" className="vi_0" placeholder="Please enter Signature / Name of  Trainer"  value={DateofComplition} onChange={(e)=>setDateofComplition(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Passing of the test of competance to drive </label>
                        <input type="date" className="vi_0" placeholder="Please enter Signature / Name of  Trainer" value={DateofPassing} onChange={(e)=>setDateofPassing(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Driving Licence number and the Date of Issue and the licensing authority and which issued the licece </label>
                        <input type="text" className="vi_0" placeholder="Please enter Driving Licence number and the Date of Issue and the licensing authority and which issued the liceces" value={aboutDL} onChange={(e)=>setaboutDL(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Remarks </label>
                        <textarea rows={3} cols={10} type="text" className="vi_0" placeholder="Please enter Remarks " value={Remarks} onChange={(e)=>setRemarks(e.target.value)}/>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={createform14}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default VendorForm14
