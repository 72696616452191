import React from "react";

const Sidebrr = ({ sidebar }) => {
  return (
    <div className={sidebar ? "sidebar sidebar--open" : "sidebar"}>
      <li>
        <a href="/me1">Profile</a>{" "}
      </li>{" "}
      <li>
        <a href="/startclasses">Start Class</a>{" "}
      </li>{" "}

      <li>
        <a href="/pro">Student List</a>{" "}
      </li>{" "}
      <li>
        <a href="/timeslot">Time Slot</a>{" "}
      </li>{" "}
      <li>
        <a href="/attendence">Attendence</a>{" "}
      </li>{" "}
      <li>
        <a href="/staffidcard">ID Card</a>{" "}
      </li>{" "}
      <li>
        <a href="/leave">Leave Update</a>{" "}
      </li>{" "}
      <li>
        <a href="/Trainer-Wallet">Wallet</a>
      </li>
    </div>
  );
};

export default Sidebrr;
