import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowsToDot } from "react-icons/fa6";
import { BsArrowReturnRight } from "react-icons/bs";
const Footer1 = () => {
    const [AllOpening, setAllOpening] = useState([]);
    const getALLOpening = async () => {
        try {
            const res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getALLOpening"
            );
            if (res.status == 200) {
                setAllOpening(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [allAbout, setAllAbout] = useState([]);
    const getAboutUs = async () => {
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAbout"
            );
            if (res.status == 200) {
                setAllAbout(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [allBranch, setallBranch] = useState([]);
    const getAllBranch = async () => {
        try {
            let res = await axios.get(
                "https://jaidrivingschool.in/api/admin/getAllBranch"
            );
            if (res.status == 200) {
                setallBranch(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAboutUs();
        getAllBranch();
        getALLOpening();
    }, []);
    return (
        <>
            <div
                style={{
                    backgroundImage: 'url("../images/footer_bg.jpg")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: "300px",

                }}
            >
                <div className="container-fluid pt-2">
                    <div className="row">
                        {allAbout?.map((ele) => {
                            return (
                                <div className="col-md-3">
                                    <h4 style={{ color: "white", fontFamily: "cursive" }} className="underline">
                                        About Us
                                    </h4>
                                    <p style={{ textAlign: "justify", color: "white", marginTop: "20px" }}>
                                        {ele?.footerDis}
                                    </p>
                                </div>
                            );
                        })}
                        <div className="col-md-3 footer_list">
                            <h4 style={{ color: "white", fontFamily: "cursive" }} className="underline">
                                UseFull Links
                            </h4>
                            <ul>
                                <li>
                                    <FaArrowsToDot /> <a href="/"> Home</a>
                                </li>
                                <li>
                                    <FaArrowsToDot /> <a href="/about">About Us</a>
                                </li>
                                <li>
                                    {" "}
                                    <FaArrowsToDot /> <a href="/courses">Courses</a>
                                </li>
                                <li>
                                    {" "}
                                    <FaArrowsToDot /> <a href="/rto">Services</a>
                                </li>
                                <li>
                                    {" "}
                                    <FaArrowsToDot /> <a href="/gallery">Gallery</a>
                                </li>
                                <li>
                                    {" "}
                                    <FaArrowsToDot /> <a href="/blog">Blog</a>
                                </li>
                                <li>
                                    {" "}
                                    <FaArrowsToDot /> <a href="/traffic">Safety Rules</a>
                                </li>
                                <li>
                                    {" "}
                                    <FaArrowsToDot /> <a href="/faq"> FAQ</a>
                                </li>
                                <li>
                                    {" "}
                                    <FaArrowsToDot /> <a href="/privacy">Privacy And Policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 footer_list">
                            <h4 style={{ color: "white", fontFamily: "cursive" }} className="underline">
                                Our Branches
                            </h4>
                            <ul>
                                {allBranch?.map((item, i) => {
                                    if (i < 4) {
                                        return (
                                            <li>
                                                <BsArrowReturnRight /> {item?.area}
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                        <div className="col-md-3 footer_list">
                            <h4 style={{ color: "white", fontFamily: "cursive" }} >
                                Opening Hours <div className="underline"><span></span></div>
                            </h4>
                            <ul style={{ textAlign: "justify" }}>
                                {AllOpening?.map((item) => {
                                    return (
                                        <li style={{ color: "white" }}>
                                            {item?.day}---{item?.time}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer1;
