import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { BiUserCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import exportFromJSON from "export-from-json";
import useRazorpay from "react-razorpay";
import { Autocomplete, TextField } from "@mui/material";
import '../../PageLoder.css'; // Import your CSS file for styling

const VendorBookService = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [AllCustomer, setAllCustomer] = useState([]);
    //loder 
    const [loading, setLoading] = useState(false);

  const getCustomer = async () => {
    try {
      const config = {
        url: "/Customer/getAllCustomer",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
        // setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [additionalCharge, setadditionalcharge] = useState([]);

  const [AllAditional, setAllAditional] = useState([]);
  const getAllAditional = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllAdditional"
      );
      if (res.status == 200) {
        setAllAditional(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [allCourse, setallCourse] = useState([]);
  // const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllService"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        // setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");
  const [AllBooking, setAllBooking] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const [data, setdata] = useState([]);
  const getAllBooking = async () => {
    try {
      let ab = admin?.branchId ? admin?.branchId : admin?._id;
      const config = {
        url: "/admin/getAllServiceBookDeatailsByBranchId/" + ab,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
        setnochangedata(res.data.success);
        setdata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let [add, setadd] = useState(0);
  const [PayMethod, setpayMethod] = useState("");
  const [fileName, setfileName] = useState("BookingService");
  const exportType = "xls";
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
    } else {
      alert("Enter file name to export");
    }
  };

  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = nochangedata?.filter((ele) => {
      console.log(
        "amit check it",
        moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
      );
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setAllBooking(abc);
    setdata(abc);
    setfileName(
      `BookingService_${moment(fromd).format("DD/MM/YYYY")} to ${moment(
        tod
      ).format("DD/MM/YYYY")}`
    );
  };

  useEffect(() => {
    if (token) {
      getAllBooking();
    }
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllBooking.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleFilter = (e) => {
    if (e.target.value != "") {
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllBooking([...filterTable]);
    } else {
      setAllBooking([...nochangedata]);
    }
  };

  const [reason, setreason] = useState("");
  const [bookId, setBookId] = useState("");
  const [status, setstatus] = useState("");
  const makeCompleteAndReject = async () => {
    if (status == "Rejected") {
      if (!reason) return alert("Please enter reason");
    }
    try {
      const config = {
        url: "/admin/MakeCancelConfirmBooking",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          bookId: bookId,
          status: status,
          reason: reason,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        getAllBooking();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Razorpay = useRazorpay();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const [allBranch, setAllBranc] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const getAllcity = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllCity"
      );
      if (res.status == 200) {
        setAllCity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [user, setuser] = useState("");

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [branch, setbranch] = useState("");
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [photo, setphoto] = useState("");
  const [idproof, setidproof] = useState("");
  const [medicleProof, setmedicleProof] = useState("");

  useEffect(() => {
    getAllAditional();
    getAllCourse();
    getCustomer();
    getAllcity();
    if (user) {
      setname(user?.name);
      setmobile(user?.mobile);
      setemail(user?.email);
      setgender(user?.gender);
      setdob(user?.dob ? user?.dob : "");
      setstate(user?.state);
      setcity(user?.city);
      setaddress(user?.Address);
      setpincode(user?.pincode);
      setphoto(user?.profile);
      setidproof(user?.addhar);
      setmedicleProof(user?.medicalproof);
    }
  }, [user]);

  const top100Films = AllCustomer.map((item) => ({
    label: `${item?.customerId}-${item?.name}`,
    year: JSON.stringify(item),
  }));
  const top100Films2 = allCourse.map((item) => ({
    label: `${item?.serviceName}-${item?.serviceType}`,
    year: JSON.stringify(item),
  }));

  const [selectedValue2, setSelectedValue2] = useState(null);
  const handleAutocompleteChange2 = (event, newValue) => {
    setSelectedValue2(newValue);

    if (newValue) {
      setCourseData(JSON.parse(newValue.year));
    } else {
      setCourseData({});
    }
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [allDoc, setAllDoc] = useState([]);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    if (!name) return alert("Please fill the blank");
    if (!email) return alert("Please fill the blank");
    if (!mobile) return alert("Please fill the blank");
    if (!Object.keys(courseData).length) return alert("Please select services");
    if (!gender | !dob | !city | !address | !pincode)
      return alert("Please complete the form");
    if (!allDoc.length) return alert("please add documents");
    getAllAditional();
    setShow2(true);
  };

  const [courseData, setCourseData] = useState({});
  const [image, setimage] = useState("");
  const [doctype, setdoctype] = useState("");
  const addDocument = () => {
    if (!doctype) return alert("Please select document type");
    if (!image) return alert("Please select file");
    allDoc.push({
      doctype: doctype,
      image: image,
    });
    setimage("");
    setdoctype("");
    return alert("Successfully added");
  };
  const [payId, setpayid] = useState("");
  const uploadDocument = async (id) => {
    try {
      for (let i = 0; i < allDoc.length; i++) {
        const config = {
          url: "/admin/uploadDocument",
          method: "put",
          baseURL: "https://jaidrivingschool.in/api",
          headers: { "content-type": "multipart/form-data" },
          data: {
            id: id,
            doctype: allDoc[i].doctype,
            image: allDoc[i].image,
          },
        };
        await axios(config);
      }
      alert("Successfully booked");
      window.location.reload();
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const addAcountHistory = async (bookId) => {
    try {
      const config = {
        url: "/admin/addAccount",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          customeId: user?.customerId,
          bookingId: bookId,
          paymentId: payId,
          amount: courseData?.servicePrice + add,
          status: "Cr",
        },
      };
      await axios(config);
    } catch (error) {
      console.log(error);
    }
  };

  const checkHand = (data) => {
    let am = additionalCharge.filter((item) => item?._id == data?._id);
    if (am.length != 0) {
      let ab = additionalCharge.filter((item) => item?._id !== data?._id);
      setadditionalcharge(ab);
      setadd(ab.reduce((a, i) => a + Number(i.price), 0));
    } else {
      additionalCharge.push(data);
      setadd(additionalCharge.reduce((a, i) => a + Number(i.price), 0));
    }
  };

  const updateUser = async () => {
    try {
      setLoading(true);
      let pay = 0;
      let paymentStatus = "";
      if (PayMethod == "Online") {
        pay = courseData?.servicePrice + add;
        paymentStatus = "Full Payment";
      }
      let obj = {
        customerId: user?._id,
        ServiceId: courseData?._id,
        Servicename: courseData?.serviceName,
        customerName: name,
        number: mobile,
        courseStartDate: courseData?.serviceStDate,
        courseEndDate: courseData?.serviceEndDate,
        totalAmount: courseData?.servicePrice + add,
        payId: payId,
        paymentMethod: PayMethod,
        email: email,
        city: city,
        area: branch,
        passportcopy: photo,
        addhar: idproof,
        medicalproof: medicleProof,
        dob: dob,
        Address: address,
        gender: gender,
        area: admin?.branchName,
        state: state,
        pincode: pincode,
        rentalagreement: "",
        sslcmarkcard: "",
        voterid: "",
        paymentStatus: paymentStatus,
        payAmount: pay,
        branchId: admin?.branchId ? admin?.branchId : admin?._id,
        branchName: admin?.branchName,
        additional: additionalCharge,
      };

      const config = {
        url: "/admin/createservicebooking",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status == 200) {
        if (payId) {
          addAcountHistory(res.data.bookId);
        }
        uploadDocument(res.data.success?._id);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [selectedValue, setSelectedValue] = useState(null);
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);

    if (newValue) {
      setuser(JSON.parse(newValue.year));
    } else {
      setuser("");
    }
  };

  const posttransaction = async () => {
    try {
      if (!PayMethod) return alert("Please select payment method");
      if (PayMethod == "Cash") {
        return updateUser();
      }
      const config = {
        data: {
          key: "rzp_test_FAe0X6xLYXaXHe",
          amount: (courseData?.servicePrice + add) * 100,
          currency: "INR",
          name: "Jai Draving School",
          description: "Order Amount",
          image: "../images/logo.png.jpg",
          customerId: user?._id,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            name: user?.name,
            email: user?.email,
            contact: user?.mobile,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (payId) {
      updateUser();
    }
  }, [payId]);

  return (
    <div>
        {loading ? ( <div className={`page-loader ${loading ? "visible" : "hidden"}`}>
      <div className="loader"></div>
    </div>):(<></>)}
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="ad-b mt-4">
                  <button>Service Booking Details</button>
                </div>
              </div>
              <div className="col-md-9">
                <div className="ad-b mt-4">
                  <button
                    style={{ borderRadius: "10px", float: "right" }}
                    onClick={() => {
                      // setadd(0)
                      // setadditionalcharge([])
                      // settime("")

                      // setbranchId("");
                      // setuser("");
                      // setEditData({});
                      // seteditShow(false);
                      handleShow1();
                    }}
                  >
                    +Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px", marginTop: "26px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="">From</label>
                  <input
                    type="date"
                    class="form-control"
                    onChange={(e) => setfromd(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="">To</label>
                  <input
                    type="date"
                    class="form-control"
                    onChange={(e) => settod(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    onClick={searchDate}
                    style={{
                      borderRadius: "3px",
                      color: "white",
                      fontSize: "18px",
                      border: "1px solid",
                      backgroundColor: "#9d041b",
                      padding: "5px",
                      marginTop: "26px",
                      borderRadius: "10px",
                      fontWeight: 500,
                    }}
                  >
                    Search
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    onClick={ExportToExcel}
                    style={{
                      borderRadius: "3px",
                      color: "white",
                      fontSize: "18px",
                      border: "1px solid",
                      backgroundColor: "#9d041b",
                      padding: "5px",
                      marginTop: "26px",
                      borderRadius: "10px",
                      fontWeight: 500,
                      float: "right",
                      marginRight: "10px",
                    }}
                  >
                    Export To Excel
                  </button>
                </div>
              </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>BookId</th>
                    <th>Name</th>
                    <th>Date Of Birth</th>
                    <th>Number</th>
                    <th>Email</th>
                    {/* <th>Branch</th> */}
                    <th>Service</th>
                    <th>Additional</th>
                    <th>Documents</th>
                    <th>Total Price</th>
                    <th>Pay Amount </th>
                    <th>Pay Id</th>
                    <th>Pay method</th>
                    <th>Payment Status</th>
                    <th> Status</th>
                    <th>Booking Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {AllBooking?.slice(
                    pagesVisited,
                    pagesVisited + usersPerPage
                  )?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.bookid}</td>
                        <td>{item?.customerName}</td>
                        <td>{item?.customerId?.dob}</td>
                        <td>{item?.number}</td>
                        <td>{item?.email}</td>
                        {/* <td>
                        {item?.branchName}
                      </td> */}
                        <td>{item?.Servicename}</td>
                        <td>
                          <Table>
                            <thead>
                              <tr>
                                <th>Service</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.additional.map((ele, i) => {
                                return (
                                  <tr>
                                    {/* <td>{i + 1}</td> */}
                                    <td>{ele?.text}</td>
                                    <td>₹{ele?.price}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Table>
                            <thead>
                              <tr>
                                <th>DocType</th>
                                <th>Documents</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.documents.map((ele, i) => {
                                return (
                                  <tr>
                                    {/* <td>{i + 1}</td> */}
                                    <td>{ele?.doctype}</td>
                                    <td>
                                      <a
                                        href={`https://jaidrivingschool.in/Customer/${ele?.image}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={`https://jaidrivingschool.in/Customer/${ele?.image}`}
                                          style={{
                                            width: "70px",
                                            height: "70px",
                                          }}
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </td>
                        {/* <td>{item?.totalAmount}</td> */}
                        <td>{item?.totalAmount}</td>
                        <td>{item?.payAmount}</td>
                        <td>{item?.payId}</td>
                        <td>{item?.paymentMethod}</td>
                        <td>
                          {item?.paymentStatus == "Under Proccess" ? (
                            <span style={{ color: "blue" }}>
                              {item?.paymentStatus}
                            </span>
                          ) : (
                            <span style={{ color: "green" }}>
                              {item?.paymentStatus == "Assigned" ? (
                                <span style={{ color: "Red" }}>
                                  {item?.paymentStatus}
                                </span>
                              ) : (
                                <span>{item?.paymentStatus}</span>
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          {item?.status == "InProccess" ? (
                            <span style={{ color: "blue" }}>
                              {item?.status} {item?.reason}
                            </span>
                          ) : (
                            <span style={{ color: "green" }}>
                              {item?.status == "Rejected" ? (
                                <span style={{ color: "Red" }}>
                                  {item?.status} {item?.reason}
                                </span>
                              ) : (
                                <span>
                                  {item?.status} {item?.reason}
                                </span>
                              )}
                            </span>
                          )}
                        </td>
                        <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                        <td>
                          {item?.status == "InProccess" ||
                          item?.status == "Rejected" ? (
                            <span style={{ display: "flex", gap: "4px" }}>
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => {
                                  setBookId(item?._id);
                                  setstatus("Completed");
                                  handleShow();
                                }}
                              >
                                Complete
                              </button>{" "}
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={() => {
                                  setBookId(item?._id);
                                  setstatus("Rejected");
                                  handleShow();
                                }}
                              >
                                Reject
                              </button>
                            </span>
                          ) : (
                            <></>
                          )}{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <br />
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Booking Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Enter your remarks"
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                  onChange={(e) => setreason(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="success" onClick={makeCompleteAndReject}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Booking Service */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          // size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Fill the Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-12">
                  <label htmlFor="">Customer/Student</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    sx={{ width: 420 }}
                    value={selectedValue} // This sets the selected value
                    onChange={handleAutocompleteChange} // Handle change event
                    renderInput={(params) => (
                      <TextField {...params} label="Customer/Student" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-12">
                  <label htmlFor="">Service</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films2}
                    sx={{ width: 420 }}
                    value={selectedValue2} // This sets the selected value
                    onChange={handleAutocompleteChange2} // Handle change event
                    renderInput={(params) => (
                      <TextField {...params} label="Services" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class="col-md-6">
                  <label htmlFor="">Email ID</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    class="form-control"
                    placeholder="Email"
                    aria-label="Email"
                  />
                </div>
                <div class="col-md-6">
                  <label htmlFor="">Mobile</label>
                  <input
                    type="number"
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    class="form-control"
                    placeholder="Mobile"
                    aria-label="Mobile"
                  />
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class="col-md-6">
                  <label htmlFor="">Gender</label>
                  <select
                    class="form-select "
                    aria-label=".form-select-lg example"
                    onChange={(e) => setgender(e.target.value)}
                  >
                    <option>{gender ? gender : "Select gender"} </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div class="col-md-6">
                  <label htmlFor="">DOB</label>
                  <input
                    type="date"
                    value={dob}
                    onChange={(e) => setdob(e.target.value)}
                    class="form-control"
                    placeholder="Last name"
                    aria-label="Last name"
                  />
                </div>
              </div>
            </div>

            <div className="do-sear mt-2">
              <div class="row me-0">
                <div class=" col-md-12">
                  <label for="exampleFormControlTextarea1" class="form-label">
                    Address
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Please enter your address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="do-sear mt-2">
              <div class="row me-0">
                <div className="" style={{ display: "flex" }}>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label">
                      City
                    </label>
                    <select
                      id="inputState"
                      class="form-select formselect"
                      onChange={(e) => setcity(e.target.value)}
                    >
                      <option selected>
                        {user?.city ? city : "Choose..."}
                      </option>
                      {allCity?.map((item) => {
                        return <option>{item?.city}</option>;
                      })}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Zip
                    </label>
                    <input
                      type="text"
                      placeholder="Please enter zip"
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row me-0">
              <div class="col-md-7">
                <label for="formFile" class="form-label" htmlFor="upload3">
                  Documents
                </label>
              </div>
            </div>
            <div className="row me-0">
              <div class="col-md-5">
                <select
                  class="form-select ms-2 formselect"
                  onChange={(e) => setdoctype(e.target.value)}
                >
                  <option>Choose Doc Type</option>

                  {courseData?.servicePoint?.map((item) => {
                    return <option value={item?.text}>{item.text}</option>;
                  })}
                </select>
              </div>
              <div class="col-md-5">
                <input
                  class="form-control"
                  type="file"
                  id="upload3"
                  onChange={(e) => setimage(e.target.files[0])}
                />
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={addDocument}
                >
                  Add
                </button>
              </div>
              {allDoc?.map((item, i) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-between",margin:"10px" }}
                  >
                      <div>{i+1}.</div>
                    <div>{item?.doctype}</div>
                    <div>
                      <img
                        src={URL.createObjectURL(item?.image)}
                        alt="Selected"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleShow2()}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Additional</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Service </th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {AllAditional?.slice(
                  pagesVisited,
                  pagesVisited + usersPerPage
                )?.map((ele, i) => {
                  return (
                    <tr>
                      <td>
                        {" "}
                        <input
                          type="checkbox"
                          style={{
                            width: "20px",
                            height: "20px",
                            background: "red",
                          }}
                          onChange={() => checkHand(ele)}
                        />
                      </td>
                      <td>{ele?.text}</td>
                      <td>₹ {ele?.price}</td>
                    </tr>
                  );
                })}
                <td></td>
                <td></td>
                {/* <td style={{padding:"5px",fontWeight:"bold"}}>Total Price :-{total?.toFixed(2)}</td> */}
              </tbody>
            </Table>
            <h4>Payments Modes</h4>
            <br />
            <Form>
              {["radio"].map((type) => (
                <div
                  key={`inline-${type}`}
                  className="mb-6"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Form.Check
                    inline
                    label="Online"
                    name="group1"
                    type={type}
                    onClick={() => setpayMethod("Online")}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="Cash"
                    onClick={() => setpayMethod("Cash")}
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
              <br />
              <h5>
                Total Price:- ₹ {(courseData?.servicePrice + add)?.toFixed(2)}
              </h5>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={posttransaction}>
              Book Now
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default VendorBookService;
