import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment/moment";

const AdminCourse = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/course/getAllcourse"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [courseName, setcourseName] = useState("");
  const [courseDuration, setcourseDuration] = useState("");
  const [coursePoint, setcoursePoint] = useState([]);
  const [price, setprice] = useState("");
  const [courseImage, setcourseImage] = useState("");
  const [Description, setDescription] = useState("");
  const [PracticalSessions, setPracticalSessions] = useState("");
  const [TheorySessions, setTheorySessions] = useState("");
  const [PracticalExam, setPracticalExam] = useState("");
  const [TheoryExam, setTheoryExam] = useState("");
  const [SimulatorSessions, setSimulatorSessions] = useState("");
  const [aboutCourse, setAboutCourse] = useState("");

  const [allTopic, setalltopic] = useState([]);
  const [topic, settopic] = useState("");

  const AddTopic = () => {
    if (!topic) return alert("Please enter topic name");
    let am = allTopic?.filter((ele) => ele?.topicName == topic);
    if (am.length != 0) return alert(`${topic} is already exits`);

    allTopic.push({ topicName: topic, days: allTopic.length + 1 });
  };
  const [Demo, setDemo] = useState("");
  const AddCourses = async () => {
    try {
      if (!courseImage) return alert("Please select image");
      if (!courseName) return alert("Please enter tittle");
      if (!courseDuration) return alert("Please enter number of day of course");
      // if (coursePoint?.length == 0) return alert("Please add your course about one by one");
      if (allTopic?.length == 0)
        return alert("Please add your course topic one by one");
      if (!price) return alert("Please enter course amount");
      if (!Description) return alert("Please enter Description!");
      if (!PracticalSessions) return alert("Please enter practical session");
      if (!TheorySessions) return alert("Please enter theory session");
      // if(!courseImage) return alert("Please select image")
      // if(!courseImage) return alert("Please select image")

      const config = {
        url: "/course/addCourse",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          courseName: courseName,
          courseDuration: courseDuration,
          coursePoint: aboutCourse,
          price: price,
          Demo: Demo,
          courseImage: courseImage,
          Description: Description,
          PracticalSessions: PracticalSessions,
          TheorySessions: TheorySessions,
          PracticalExam: PracticalExam,
          TheoryExam: TheoryExam,
          SimulatorSessions: SimulatorSessions,
          courseTopic: allTopic,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        getAllCourse();
        handleClose();
        setcourseImage("");
        setcourseName("");
        setcourseDuration("");
        setDescription("");
        setPracticalSessions("");
        setTheorySessions("");
        setPracticalExam("");
        setAboutCourse("");
        setTheoryExam("");
        setSimulatorSessions("");
        setprice("");
        setDemo("");
        setcoursePoint([]);
        setalltopic([]);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const [AllTopic, setAllTopic] = useState([]);

  const getAllTopics = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getCORSETOPIC"
      );
      if (res.status == 200) {
        setAllTopic(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCourse();
    getAllTopics();
  }, []);
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallCourse([...filterTable]);
    } else {
      setSearch(e.target.value);
      setallCourse([...nochangedata]);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allCourse.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [show1, setshow1] = useState(false);
  const handleClose1 = () => setshow1(false);
  const handleShow1 = () => setshow1(true);
  const [deleteId, setdeleteId] = useState("");

  const [edit, setedit] = useState({});
  const [edshow, setedshow] = useState(false);
  const handleShowed = (edit) => {
    setcourseName(edit?.courseName);
    setcourseDuration(edit?.courseDuration);
    setAboutCourse(edit?.coursePoint);
    setprice(edit?.price);
    setDescription(edit?.Description);
    setPracticalSessions(edit?.PracticalSessions);
    setSimulatorSessions(edit?.SimulatorSessions);
    setTheorySessions(edit?.TheorySessions);
    setPracticalExam(edit?.PracticalExam);
    setDemo(edit?.Demo);
    setTheoryExam(edit?.TheoryExam);
    setedshow(true);
  };
  const handCloseed = () => setedshow(false);
  const updateCourse = async () => {
    try {
      const config = {
        url: "/course/updateCourse",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          id: edit?._id,
          authId: admin?._id,
          courseName: courseName,
          courseDuration: courseDuration,
          coursePoint: aboutCourse,
          price: price,
          Demo: Demo,
          courseImage: courseImage,
          Description: Description,
          PracticalSessions: PracticalSessions,
          TheorySessions: TheorySessions,
          PracticalExam: PracticalExam,
          TheoryExam: TheoryExam,
          SimulatorSessions: SimulatorSessions,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        getAllCourse();
        handCloseed();
        setcourseImage("");
        setcourseName("");
        setcourseDuration("");
        setDescription("");
        setPracticalSessions("");
        setTheorySessions("");
        setPracticalExam("");
        setTheoryExam("");
        setSimulatorSessions("");
        setAboutCourse("");
        setprice("");
        setDemo("");
        setcoursePoint([]);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const addTopicofCourse = async () => {
    try {
      if (!topic) return alert("Please enter course topic");
      const config = {
        url: "/course/addCourseTopic",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          topicName: topic,
          id: edit?._id,
          authId: admin?._id,
          days: edit?.courseTopic?.length + 1,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");

        setedit(res.data.success);
      
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteTopicCourse = async (id) => {
    try {
      const config = {
        url: "/course/deleteCourseTopic",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          deleteId: id,
          id: edit?._id,
          authId: admin?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");

        setedit(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCourses = async () => {
    try {
      const congig = {
        url: "/course/deleteCourse/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(congig);
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose1();
        getAllCourse();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // CKSEditor
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setAboutCourse(data);
  };
  // CKSEditor
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button> Courses Details</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button
                    style={{ borderRadius: "10px", float: "right" }}
                    onClick={() => {
                      setcourseImage("");
                      setcourseName("");
                      setcourseDuration("");
                      setDescription("");
                      setPracticalSessions("");
                      setTheorySessions("");
                      setPracticalExam("");
                      setTheoryExam("");
                      setSimulatorSessions("");
                      setAboutCourse("");
                      setprice("");
                      setDemo("");
                      setcoursePoint([]);
                      handleShow();
                    }}
                  >
                    + Add{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
              <div className="scrolll_bar">
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Course Image</th>
                      <th>Title</th>
                      {/* <th>Description</th> */}
                      <th>Number Of Days</th>
                      <th>Practical Sessions</th>
                      <th>Simulator Sessions</th>
                      <th>Theory Session</th>

                      <th>Practical Exam</th>
                      <th>Theory Exam</th>
                      <th>Amount</th>
                      <th>About Course</th>
                      <th>Topic in Course</th>
                      <th>Description</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCourse
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {moment(ele?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td>
                            <td>
                              <img
                                src={`https://jaidrivingschool.in/Course/${ele?.courseImage}`}
                                alt=""
                                width={80}
                              />
                            </td>
                            <td>{ele?.courseName}</td>

                            <td>{ele?.courseDuration} days</td>
                            <td>{ele?.PracticalSessions} HOURS </td>
                            <td>{ele?.SimulatorSessions} HOURS </td>
                            <td>{ele?.TheorySessions} HOURS </td>
                            <td>{ele?.PracticalExam} MINUTES</td>
                            <td>{ele?.TheoryExam} MINUTES</td>
                            <td>&#8377;{ele?.price}</td>
                            <td>
                              <p
                                style={{
                                  height: "100px",
                                  width: "200px",
                                  overflow: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {ele?.coursePoint
                                  ? parse(ele?.coursePoint)
                                  : ""}
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  height: "100px",
                                  width: "200px",
                                  overflow: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {ele?.courseTopic?.map((item) => {
                                  return (
                                    <p style={{ color: "red" }}>
                                      {item?.days}
                                      {"-->"}
                                      <span style={{ color: "green" }}>
                                        {item?.topicName}
                                      </span>
                                    </p>
                                  );
                                })}
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  height: "100px",
                                  width: "250px",
                                  overflow: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {ele?.Description
                                  ? parse(ele?.Description)
                                  : ""}
                              </p>
                            </td>
                            <td>
                              <img
                                src="./images/editing.png"
                                alt=""
                                width={30}
                                onClick={() => {
                                  setedit(ele);
                                  handleShowed(ele);
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setdeleteId(ele?._id);
                                  handleShow1();
                                }}
                              >
                                <span>
                                  <RiDeleteBin6Line
                                    style={{ fontSize: "20" }}
                                  />
                                </span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>

              <br />
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Course Image</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setcourseImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="vi_0"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>No. of days</label>
                <input
                  type="number"
                  placeholder="Days"
                  onChange={(e) => setcourseDuration(e.target.value)}
                  className="vi_0"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>About Course</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={aboutCourse}
                  onChange={handleChange}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <label>Topic in Course</label>
                <select
                  name=""
                  id=""
                  className="vi_1"
                  onChange={(e) => settopic(e.target.value)}
                >
                  <option value="">
                    ---Select Course Topic one by one-----
                  </option>
                  {AllTopic?.map((ele) => (
                    <option value={ele?.topic}>{ele?.topic}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={AddTopic}
                  class="btn btn-success"
                >
                  Add
                </button>{" "}
                {allTopic?.length != 0 ? (
                  <button
                    type="button"
                    onClick={() => setalltopic([])}
                    class="btn btn-danger"
                  >
                    Reset
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <ul>
                {allTopic?.map((ele) => {
                  return <li>{ele?.topicName}</li>;
                })}
              </ul>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Course Amount</label>
                <input
                  type="number"
                  min="0"
                  onChange={(e) => setprice(e.target.value)}
                  placeholder="2000"
                  className="vi_1"
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={handleChange2}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Practical Sessions</label>
                  <input
                    type="number"
                    placeholder="Eg:-HOURS"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setPracticalSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Simulator Sessions</label>
                  <input
                    type="number"
                    placeholder="Eg:-HOURS"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setSimulatorSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Theory Sessions</label>
                  <input
                    type="number"
                    placeholder="Eg:-HOURS"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheorySessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Practical Exam</label>
                  <input
                    type="number"
                    placeholder="Eg:-MINUTES"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setPracticalExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Demo Session</label>
                  <input
                    type="number"
                    placeholder="Eg:-MINUTES"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setDemo(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Theory Exam</label>
                  <input
                    type="number"
                    placeholder="Eg:-MINUTES"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheoryExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AddCourses}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* edit course */}
        <Modal
          show={edshow}
          onHide={handCloseed}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Course Image</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setcourseImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Title</label>
                <input
                  type="text"
                  value={courseName}
                  placeholder={edit?.courseName}
                  className="vi_0"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>No. of days</label>
                <input
                  type="number"
                  value={courseDuration}
                  placeholder={edit?.courseDuration}
                  onChange={(e) => setcourseDuration(e.target.value)}
                  className="vi_0"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>About Course</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={aboutCourse}
                  onChange={handleChange}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <label>Topic in Course</label>
                <select name="" id="" className="vi_1" onChange={(e)=>settopic(e.target.value)}>
                  <option value="">
                    ---Select Course Topic one by one-----
                  </option>
                  {AllTopic?.map((ele) => (
                    <option value={ele?.topic}>{ele?.topic}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={addTopicofCourse}
                  class="btn btn-success"
                >
                  Add
                </button>{" "}
              </div>
              <ul>
                {edit?.courseTopic?.map((ele) => {
                  return (
                    <li>
                      {ele?.days} {ele?.topicName}{" "}
                      <span
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => deleteTopicCourse(ele?._id)}
                      >
                        <RxCrossCircled />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Course Amount</label>
                <input
                  type="number"
                  min="0"
                  value={price}
                  onChange={(e) => setprice(e.target.value)}
                  placeholder={edit?.price}
                  className="vi_1"
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={handleChange2}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Practical Sessions</label>
                  <input
                    type="number"
                    value={PracticalSessions}
                    placeholder={`${edit?.PracticalSessions} HOURS`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setPracticalSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Simulator Sessions</label>
                  <input
                    type="number"
                    value={SimulatorSessions}
                    placeholder={`${edit?.SimulatorSessions} HOURS`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setSimulatorSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Theory Sessions</label>
                  <input
                    type="number"
                    value={TheorySessions}
                    placeholder={`${edit?.TheorySessions} HOURS`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheorySessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Practical Exam</label>
                  <input
                    type="number"
                    placeholder={`${edit?.PracticalExam} MINUTES`}
                    value={PracticalExam}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setPracticalExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Demo Session</label>
                  <input
                    type="number"
                    value={Demo}
                    placeholder={`${edit?.Demo} MINUTES`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setDemo(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Theory Exam</label>
                  <input
                    type="number"
                    value={TheoryExam}
                    placeholder={`${edit?.TheoryExam} MINUTES`}
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheoryExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handCloseed}>
              Close
            </Button>
            <Button variant="primary" onClick={updateCourse}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete Model */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ??</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteCourses}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};
export default AdminCourse;
