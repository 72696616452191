import React, { useEffect, useState } from "react";
import Side1 from "./sidebar1/Side1";
import axios from "axios";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import StarRatings from "react-star-ratings";
import '../PageLoder.css'; 
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const StudentCourse = () => {
  const [invoice, setInvoice] = useState("");
  const navigate = useNavigate();
  const handlenavigate = () => {

    navigate("/invoice", { state: invoice });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getBookDeatailsByCustomerId = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getBookDeatailsByCustomerId/" +
        user?._id
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allBranch, setAllBranc] = useState([]);
  const getAllBranch = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setAllBranc(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      getBookDeatailsByCustomerId();
      getAllBranch();
    }
  }, []);

  //loder 
  const [loading, setLoading] = useState(false);

  const [id, setid] = useState("");
  const MakeCancelConfirmBooking = async () => {
    try {
      const config = {
        url: "/admin/MakeCancelConfirmBookingCourses",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          bookId: id,
          status: "Cancel",
        },
      };
      let res = await axios(config)
      if (res.status == 200) {
        alert("Successfully cancel")
        window.location.reload(true)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allCourse.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallCourse([...filterTable]);
    } else {
      setSearch(e.target.value);
      setallCourse([...nochangedata]);
    }
  };
  const [showT, setshowT] = useState(false);
  const handleShowT = () => setshowT(true);
  const handleCloseT = () => setshowT(false);
  const [editd, seteditd] = useState({});
  const [branch, setbranch] = useState("");
  const [branchId, setbranchId] = useState("");
  const handleBr = (abc) => {
    if (abc !== "") {
      if (abc !== "Main Branch") {
        abc = JSON.parse(abc);
        setbranch(`${abc?.branchName},${abc?.area}`);
        setbranchId(abc?._id);
      } else {
        setbranch("Main Branch");
      }
    }
  };
  const makeTransferBranch = async () => {
    if (!branch) return alert("Please select branch");
    try {
      const config = {
        url: "/admin/MakeTransferBranchCourse",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          bookId: editd?._id,
          status: "Transfer",
          TransId: branchId, TransName: branch

        },
      };
      let res = await axios(config);
      if (res.status == 200) {
   
        alert("Successfully send notice transfer branch");
        getBookDeatailsByCustomerId()
        handleCloseT();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [showf,setshowf]=useState(false);
  const [video,setvideo]=useState("");
  const [images,setimages]=useState("");
  const [feedback,setfeedback]=useState("");
  const [ratting,setrating]=useState(0);
const courseFeedback=async ()=>{
  try {
    setLoading(true)
    const config={
      url: "/admin/courseFeedback",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data:{
        authId:user?._id,
      
        id:editd?._id,
      }
    }
    let res=await axios(config)
    if(res.status==200){
      alert("Thanks for give valuable time");
      setLoading(false);
      setshowf(false);
      getBookDeatailsByCustomerId()
    }
  } catch (error) {
    console.log(error);
  }
}
  const AddTestimon=async()=>{
    try {
      const config={
        url: "/admin/AddFeedback",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data:{
          authId:user?._id,
          userId:user?._id,
          text:feedback,
          courseId:editd?._id,
          image:images,
          video:video,
          courseName:editd?.courseTitle,
          ratting:ratting
        }
      }
      let res=await axios(config);
      if(res.status==200){
        courseFeedback()
      }
    } catch (error) {
      console.log(error);
    }
  }
    // CKSEditor
    const handleChange = (e, editor) => {
      const data = editor.getData();
      setfeedback(data);
    };
  return (
    <div>
      <Side1 />
      {loading ? ( <div className={`page-loader ${loading ? "visible" : "hidden"}`}>
      <div className="loader"></div>
    </div>):(<></>)}
      <div className="row">
        <div className="col-3">
          <input
            type="text"
            placeholder="Search...."
            className="vi_0"
            style={{ marginLeft: "-20px" }}
            onChange={(e) => handleFilter(e)}
          />
        </div>
      </div>
      {allCourse
        ?.slice(pagesVisited, pagesVisited + usersPerPage)
        ?.map((ele) => {
          return (
            <div
              className="row"
              style={{
                boxShadow: "0 8px 32px 0 rgba(19, 19, 20, 0.37)",
                border: "1px solid white",
                borderRadius: "15px",
                alignItems: "center",
              }}
            >
              <div className="col-md-3">
                <img
                  src={`https://jaidrivingschool.in/Course/${ele?.courseId?.courseImage}`}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                  height={200}
                />
              </div>
              <br />

              <div className="col-md-3">
                <h6>Course Name : &nbsp;{ele?.courseTitle}</h6>
                <h6>Branch Name : &nbsp;{ele?.branchName}</h6>
                <h6>
                  Total Price &nbsp; : &#8377;{Number(ele?.totalAmount)?.toFixed(2)}/-
                </h6>
                <h6>
                  No Of Days &nbsp; : {ele?.courseDuration} days
                </h6>
                <h6>
                  Start Date &nbsp;  : &nbsp;
                  {moment(ele?.courseStartDate).format("DD/MM/YYYY")}
                </h6>
                <h6>
                  End Date &nbsp; : &nbsp;{moment(ele?.courseEndDate).format("DD/MM/YYYY")}
                </h6>
                <h6>
                  Instructor Name &nbsp; : &nbsp;{ele?.trinerName}
                </h6>
                <h6>
                  Vehicle No &nbsp;:
                  &nbsp;{ele?.vechicleId?.vehicleNumber}
                </h6>
                <h6>
                  Payment Mode &nbsp;: &nbsp;{ele?.paymentMethod}
                </h6>
              </div>
              <div className="col-md-3">
                {ele?.TransName ? (<h6>
                  Tranfer Branch : &nbsp;{ele?.TransName}
                </h6>) : (<></>)}
                <h6>
                  Class Time : &nbsp;{ele?.courseTiming}
                </h6>
                <h6>
                  GST : &#8377;{Number(ele?.gst)?.toFixed(2)}/-
                </h6>
                <h6>
                  Discount
                  &nbsp;: &#8377;{ele?.discount}/-
                </h6>
                <h6>
                  Pay Amount  &nbsp;:  &nbsp;&#8377;{ele?.payAmount}/-
                </h6>
                <h6>
                  Pay Id &nbsp; : &nbsp;{ele?.payId}
                </h6>
                <h6>
                  Remaining Amount &nbsp; : &nbsp; &#8377;
                  {Number(ele?.totalAmount) - Number(ele?.payAmount)}/-
                </h6>
                <h6>
                  Payment Status &nbsp; :  &nbsp;{ele?.paymentStatus}
                </h6>
                <h6>
                  Booking Status&nbsp;: &nbsp;
                  {ele?.status == "Pending" ? (
                    <span style={{ color: "blue" }}>{ele?.status}</span>
                  ) : (
                    <span>
                      {ele?.status == "Cancel" ? (
                        <span style={{ color: "red" }}>{ele?.status}</span>
                      ) : (
                        <span style={{ color: "green" }}>{ele?.status}</span>
                      )}
                    </span>
                  )}
                </h6>
                <h6>
                  Instructor Status &nbsp; : &nbsp;
                  {ele?.trinerStatus == "Pending" ? (
                    <span style={{ color: "blue" }}>{ele?.trinerStatus}</span>
                  ) : (
                    <span>
                      {ele?.trinerStatus == "Assigned" ? (
                        <span style={{ color: "red" }}>
                          {ele?.trinerStatus}
                        </span>
                      ) : (
                        <span style={{ color: "green" }}>
                          {ele?.trinerStatus}
                        </span>
                      )}
                    </span>
                  )}
                </h6>
              </div>
              <div className="col-md-3" style={{ alignItems: "center", gap: "5px" }}>
                {ele?.status == "Pending" ? (
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => {
                      setid(ele?._id);
                      handleShow();
                    }}
                  >
                    Cancel Booking
                  </button>
                ) : (
                  <></>
                )}{" "}
                {ele?.status == "Completed" || ele?.status == "Cancel" ? (<></>) : (<button
                  type="button"
                  class="btn btn-info"
                  style={{ color: "white" }}
                  onClick={() => {
                    seteditd(ele)
                    handleShowT();
                  }}
                >
                  Transfer Branch
                </button>)}
                {ele?.status == "Completed"&&ele?.isFeedback == false ? (<button
                  type="button"
                  class="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    seteditd(ele)
                    setshowf(true);
                  }}
                >
                Feedback
                </button>) : (<></>)}
              </div>
            </div>
          );
        })}
      <br />
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />


      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure cancel course</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={MakeCancelConfirmBooking}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showT}
        onHide={handleCloseT}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Branch Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div class="col-md-12">
              <label for="inputState" class="form-label ms-2">
                Branch
              </label>
              <select class="form-select ms-2 formselect" onChange={(e) => handleBr(e.target.value)}>
                <option >Choose...</option>
                <option value="Main Branch">Main Branch</option>
                {allBranch?.map((item) => {
                  return (
                    <option value={JSON.stringify(item)}>{item.branchName},{item?.area}</option>
                  )
                })}

              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseT}>
            Cancel
          </Button>
          <Button variant="success" onClick={makeTransferBranch}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={showf}
        onHide={()=>setshowf(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div class="col-md-12">
              <label for="inputState" class="form-label ms-2" htmlFor="upload1">
                Images
              </label>
              <input type="file" name="file" accept="/image*" id="upload1" class="form-select ms-2 formselect" onChange={(e) =>setimages(e.target.files[0])}
              />
            </div>
            <div class="col-md-12">
              <label for="inputState"  class="form-label ms-2" htmlFor="upload2">
                Vieo
              </label>
              <input type="file" accept="/video*" name="file" id="upload2" class="form-select ms-2 formselect" onChange={(e) =>setvideo(e.target.files[0])}
              />
            </div>
            <div class="col-md-12">
              <label for="inputState"  class="form-label ms-2">
                Comment
              </label>
              <CKEditor
                  editor={ClassicEditor}
                  data={feedback}
                  onChange={handleChange}
                  onBlur={(event, editor) => {
                    // console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log("Focus.", editor);
                  }}
                />
            
            </div>
            <div className="col-md-12">
            <label for="inputState"  class="form-label ms-2">
                Ratting
              </label><br/>
            <StarRatings
        rating={ratting}
        starDimension="20px"
        changeRating={setrating}
        starSpacing="2px"
        starRatedColor="#fcca0b"
      />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>setshowf(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={AddTestimon}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StudentCourse;
