import React from "react";
// import AdminHeader from "./AdminHeader.";
import "../../Components/Admin/admin.css";
import VendorSidebar from "./VendorSidebar";
import VendorHeader from "./Vendorheader";

function VendorDashboard(props) {
  return (
    <div className="dash">
      <div className="row me-0">
        <div className="col-md-2 p-0">
          <div className="left-side">
            <VendorSidebar />
          </div>
        </div>

        <div className="col-md-10 p-0 right-h ">
          <VendorHeader />
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default VendorDashboard;
