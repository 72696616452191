import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { RiDeleteBin6Line } from 'react-icons/ri';
import axios from 'axios';
import { AddAPhoto } from '@mui/icons-material';


const AdminServicetopics = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const token = sessionStorage.getItem("token");
    const [AllDocumnets,setAllDocument]=useState([]);
    const getdocumentsData=async()=>{
        try {
            let res=await axios.get("https://jaidrivingschool.in/api/admin/getDOCUMENTS");
            if(res.status==200){
                setAllDocument(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        getdocumentsData()
    },[]);

    const [doc,setdoc]=useState("")

    const AddDocument=async()=>{
        if(!doc) return alert("Please enter documents name");
        let am=AllDocumnets.filter((ele)=>ele?.documentT?.toLowerCase()==doc?.toLowerCase())
        if(am.length!==0) return alert(`${doc} Already exits`)
        try {
            const config = {
                url: "/admin/addDOCUMENTS",
                method: "post",
                baseURL: "https://jaidrivingschool.in/api",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "content-type": "application/json",
                },
                data:{
                    authId:admin?._id,
                    documentT:doc
                }
              };
              let res=await axios(config);
              if(res.status===200){
                alert("Successfully Added")
                handleClose();
                getdocumentsData();
                setdoc("");
              }
        } catch (error) {
            console.log(error);
        }
    }
const [editData,setEditData]=useState({});

const updateDoc=async()=>{
    if(!doc) return alert("Please enter documents name");
    let am=AllDocumnets.filter((ele)=>ele?.documentT?.toLowerCase()==doc?.toLowerCase())
    if(am.length!==0) return alert(`${doc} Already exits`)
    try {
        const config = {
            url: "/admin/DOCUMENTSupdate",
            method: "put",
            baseURL: "https://jaidrivingschool.in/api",
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
            data:{
                authId:admin?._id,
                documentT:doc,
                id:editData?._id
            }
          };
          let res=await axios(config);
          if(res.status===200){
            alert("Successfully updated")
            handleClose1();
            getdocumentsData();
            setdoc("");
          }
    } catch (error) {
        console.log(error);
    }
}

const [deleteId,setdeleteId]=useState("");

const deleteDoc=async()=>{
    try {
        const config = {
            url: "/admin/deleteDOCUMENTS/"+deleteId+"/"+admin?._id,
            method: "delete",
            baseURL: "https://jaidrivingschool.in/api",
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          
          };
          let res=await axios(config);
          if(res.status===200){
            alert("Successfully Deleted")
            handleClose2();
            getdocumentsData();
        
          }
    } catch (error) {
        console.log(error);
    }
}

    return (
        <>
            <div className="add-gr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <span style={{ float: "right" }}>
                                <div className="ad-b mt-4">
                                    <button style={{ borderRadius: "10px", float: "right" }} onClick={() => {
                                        handleShow()
                                    }}>+ Add </button>
                                </div>
                            </span>
                        </div>
                    </div>
                    <di className="row">
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th>S No.</th>

                                    <th>Document</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AllDocumnets?.map((item,i)=>{
                                    return (<tr>
                                    <td>{i+1}.</td>
                                    <td>{item?.documentT}</td>

                                    <td>
                                        <img src="./images/editing.png" alt="" width={30} onClick={() =>{ 
                                            setEditData(item)
                                            handleShow1()}} />
                                    </td>
                                    <td>
                                        <Button
                                            type="button"
                                            variant="danger"
                                            onClick={() =>{
                                                setdeleteId(item?._id)
                                                handleShow2()}}

                                        >
                                            <span>
                                                <RiDeleteBin6Line style={{ fontSize: "20" }} />
                                            </span>
                                        </Button>
                                    </td>
                                </tr>)
                                })}
                                

                            </tbody>
                        </Table>
                    </di>
                </div>

            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Services Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <div className="row">
                        <div className="col-md-12">
                            <label>Documents Required</label>
                            <input
                                type="text"
                                value={doc}
                                placeholder="Enter document type name"
                                className="vi_1"
                                onChange={(e)=>setdoc(e.target.value)}
                            />
                        </div>


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={AddDocument} >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={show1}
                onHide={handleClose1}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div className="row">
                        <div className="col-md-12">
                            <label>Documents Required</label>
                            <input
                                type="text"
                                value={doc}
                                placeholder={editData?.documentT}
                                className="vi_1"
                                onChange={(e)=>setdoc(e.target.value)}
                            />
                        </div>


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateDoc} >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* delete Model */}
            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure delete ??</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleClose2()}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={deleteDoc} >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal >
        </>


    )
}

export default AdminServicetopics