import React, { useEffect } from "react";
import Side1 from "./sidebar1/Side1";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";

const StudentServiceStatus = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [AllServices, setServices] = useState([]);
  const [nochangedata, setnochangedata] = useState([])
  const getAllService = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getServiceBookDeatailsByCustomerId/" +
        user?._id
      );
      if (res.status == 200) {
        setServices(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      getAllService();
    }
  }, []);
  const [id, setid] = useState("");
  console.log("AllSeeeee", AllServices);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllServices.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setServices([...filterTable]);
    } else {
      setSearch(e.target.value);
      setServices([...nochangedata]);
    }
  };
  return (
    <div>
      <Side1 />

      <div className="container">
        <div className="row">
          <div className="col-3">
            <input type="text" placeholder="Search...." className="vi_0" style={{ marginLeft: "-20px" }} onChange={(e) => handleFilter(e)} />
          </div>
        </div>
        {AllServices?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item) => {
          return (
            <div
              className="row"
              style={{
                boxShadow: "0 8px 32px 0 rgba(19, 19, 20, 0.37)",
                border: "1px solid white",
                borderRadius: "15px",
                padding: "10px",
              }}
            >
              <div className="col-md-3">
                <img
                  src={`https://jaidrivingschool.in/Service/${item?.ServiceId?.servicePicture}`}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                  height={200}
                />
              </div>
              <br />
              <div className="col-md-3">
                <h4>{item?.Servicename}</h4>
                <h6 htmlFor="">BookId:- {item?.bookid} </h6>


                <h6 htmlFor="">Name:- {item?.customerName} </h6>

                <h6 htmlFor="">Email:- {item?.email} </h6>

                <h6 htmlFor="">Mobile No:- {item?.number} </h6>

                <h6 htmlFor="">
                  Book Date:- {moment(item?.createdAt).format("LLL")}{" "}
                </h6>

                <h6 htmlFor="">
                  Payment Method:- {item?.paymentMethod}{" "}
                </h6>

                {/* <h6 htmlFor="">BookId:- {item?.bookid} </h6> */}
              </div>
              <div className="col-md-3">
                <h4>Total Amount:- ₹ {item?.totalAmount}</h4>
                <h6 htmlFor="">Pay Amount:- {item?.payAmount} </h6>

                <h6 htmlFor="">Pay Id:- {item?.payId} </h6>

                <h6 htmlFor="">
                  Remaining Amount:- {item?.totalAmount - item?.payAmount}{" "}
                </h6>

                <h6 htmlFor="">
                  PayMent Status:- {item?.paymentStatus}{" "}
                </h6>

                {/* <h6 htmlFor="">Book Date:- {moment(item?.createdAt).format("LLL")} </h6><br/> */}
                <h6 htmlFor="">
                  Service Status:-{" "}
                  {item?.status == "Pending" ? (
                    <span style={{ color: "blue" }}>{item?.status}</span>
                  ) : (
                    <span>
                      {item?.status == "Cancel" ? (
                        <span style={{ color: "red" }}>{item?.status}</span>
                      ) : (
                        <span style={{ color: "green" }}>{item?.status}</span>
                      )}
                    </span>
                  )}{" "}
                </h6>

                {/* <h6 htmlFor="">BookId:- {item?.bookid} </h6> */}
              </div>
              <div className="col-md-3" style={{ alignItems: "center" }}>
                {item?.status == "Pending" ? (
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => {
                      setid(item?._id);
                      handleShow();
                    }}
                  >
                    Cancel Booking
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
        <br />
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
        {/* <div className="row">
          <div className="col-md-5 d-flex justify-content-evenly ">
            <h5>Status:</h5>
            <label htmlFor="">In process</label>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-5 d-flex justify-content-evenly ">
            <h5>Status:</h5>
            <label htmlFor="">Accepted</label>
            <Button
              variant="primary mb-3"
              onClick={handleShow1}
              style={{ color: "black", float: "right" }}
            >
              Preview
            </Button>
          </div>
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src="./images/bg-2.webp" alt="" style={{ width: "100%" }} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={handleClose1}
                style={{ color: "black" }}
              >
                Download
              </Button>
            </Modal.Footer>
          </Modal>
        </div> */}
        {/* <div className="row">
          <div className="col-md-5 d-flex justify-content-evenly">
            <h5>Status:</h5>
            <label htmlFor="">Rejected</label>
            <Button
              variant="primary mb-3"
              onClick={handleShow}
              style={{ color: "black", float: "right" }}
            >
              View Remark
            </Button>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Remark</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>Your request was rejected due to mis-match of document</h6>
              <img src="./images/adhaar.png" alt="" />
              <label htmlFor="">Please contact +91 9876543210</label>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={handleClose}
                style={{ color: "black" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure cancel course</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" >Ok</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StudentServiceStatus;
