import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

const Sidebar = ({opensidebar}) => {
  let trainer=JSON.parse(sessionStorage.getItem("staff"));
  return (
    <div className="tool-bar">
        <div className="buger" onClick={opensidebar}>
            <MenuIcon/>
        </div>
        <div className="tittle">
            {trainer?.name}
        </div>
    </div>
  );
};

export default Sidebar;
