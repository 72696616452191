import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";

const AdminForm15 = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const token = sessionStorage.getItem("token");

  const [AllBooking, setAllBooking] = useState([]);
  const [data, setdata] = useState([])
  const [nochangedata, setnochangedata] = useState([])
  const getAllBooking = async () => {
    try {
      const config = {
        url: "/admin/getAllBookingFortrainerP",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`, "content-type": "multipart/form-data" },
      }
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
        setnochangedata(res.data.success)
        setdata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getAllBooking()
  },[]);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllBooking.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  

  const [branchAddress,setbranchAddress]=useState("");
  const [branchNumber,setbranchNumber]=useState("");
  const [branchEmail,setbranchEmail]=useState("");
  const [Name,setName]=useState("");
  const [EnrollmentNumber,setEnrollmentNumber]=useState("");
  const [DateofEnrollment,setDateofEnrollment]=useState("");
  const [Attandance,setAttandance]=useState([]);

  const [classDate,setclassDate]=useState("");
  const [FromHrs,setFromHrs]=useState("");
  const [ToHrs,setToHrs]=useState("");
  const [TotalHrs,setTotalHrs]=useState("");
  const [Classofvehicle,setClassofvehicle]=useState("");
  const [Signatureofinsturctor,setSignatureofinsturctor]=useState("");
  const [SignatureofTrainer,setSignatureofTrainer]=useState("");

const AddClass=()=>{
    if(!classDate) return alert("Please select class date");
    if(!FromHrs) return alert("Please select FromHrs");
    if(!ToHrs) return alert("Please select ToHrs");
    if(!TotalHrs) return alert("Please enter TotalHrs");
    if(!Classofvehicle) return alert("Please enter class of vehicle");
    if(!Signatureofinsturctor) return alert("Please enter Signature of insturctor");
    if(!SignatureofTrainer) return alert("Please enter Signature of Trainer");
    Attandance.push({classDate,FromHrs,ToHrs,TotalHrs,Classofvehicle,Signatureofinsturctor,SignatureofTrainer});
    alert("Successfully added")
    setclassDate("");
    setFromHrs("");
    setToHrs("");
    setTotalHrs("");
    setClassofvehicle("");
    setSignatureofinsturctor("");
    setSignatureofTrainer("");
}


const [AllForm15,setAllForm15]=useState([]);
const [nochanForm15,setNochForm15]=useState([])
const getAllForm15=async()=>{
    try {
        let res=await axios.get("https://jaidrivingschool.in/api/admin/getAllForm15");
        if(res.status==200){
            setAllForm15(res.data.success);
            setNochForm15(res.data.success);
        }
    } catch (error) {
        console.log(error);
    }
}
const handleFilter = (e) => {
    if (e.target.value != "") {
 
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );

      const filterTable2 = nochanForm15.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setAllForm15([...filterTable2])
      setAllBooking([...filterTable]);
    } else {
        setAllForm15([...nochanForm15])
      setAllBooking([...nochangedata]);
    }
  };
const AddFrorm15=async()=>{
    try {
        const config = {
            url: "/admin/createform15data",
            method: "post",
            baseURL: "https://jaidrivingschool.in/api",
            headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },
            data:{
              authId:admin?._id ,branchAddress,Name,branchNumber,branchEmail,EnrollmentNumber,DateofEnrollment,Attandance
            }
          } 
          let res=await axios(config);
          if(res.status==200){
            alert("Successfully added");
            handleClose();
            getAllForm15();
          }
    } catch (error) {
        console.log(error);
    }
}

useEffect(()=>{
    getAllForm15();
},[])

    return (
        <>
            <div className="add-gr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ad-b mt-4">
                                <button>Form 15</button>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ad-b mt-4">
                                <button style={{ borderRadius: "10px", float: "right" }} onClick={handleShow}>+Add</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="mo-gra mt-5 scrolll_bar">
                    <div className="row">
                  <div className="col-3">
                    <input type="text" placeholder="Search...." className="vi_0" style={{ marginLeft: "-20px", marginTop: "26px", }} onChange={(e) => handleFilter(e)} />
                  </div>
                </div>
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    {/* <th rowSpan={2}>S.No</th> */}
                                    <th rowSpan={2}>Branch Address</th>
                                    <th rowSpan={2}>Name of the Trainee </th>
                                    <th rowSpan={2}>Enrollment Number</th>
                                    <th rowSpan={2}>Date of Enrollment</th>
                                   
                                    <th rowSpan={2}>Class Of Vehicle</th>
        
                                    <th rowSpan={2}> Name of Trainer</th>
                                    <th rowSpan={2}>View Form</th>
                                </tr>

                            </thead>
                            <tbody>
                            {AllForm15?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                                return (
                                    <tr>
                                    {/* <td>{i+1}</td> */}
                                    <td>{item?.branchAddress}</td>
                                    <td> {item?.Name}</td>
                                    <td>{item?.EnrollmentNumber}</td>
                                    <td>{item?.DateofEnrollment}</td>
                                    <td>{item?.vechicleType}</td>
                        
                                    <td> {item?.trinerName}</td>
                                    <td><Link to="/admin_form15" state={{item1:item}}>
                                        <AiFillEye style={{fontSize:"25px"}} />
                                    </Link>
                                    </td>
                                </tr>  
                                )
                            })}
                                {AllBooking?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                                    return (
                                     <tr>
                                    {/* <td>{i+1}</td> */}
                                    <td>{item?.branchName}-{item?.city}</td>
                                    <td> {item?.customerName}</td>
                                    <td>{item?.bookid}</td>
                                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                    <td>{item?.vechicleType}</td>
                        
                                    <td> {item?.trinerName}</td>
                                    <td><Link to="/admin_form15" state={{item:item}}>
                                        <AiFillEye style={{fontSize:"25px"}} />
                                    </Link>
                                    </td>
                                </tr>    
                                    )
                                })}
                               

                            </tbody>
                        </Table>
                        <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />

                    </div>
                </div>
            </div>
            
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Form 15</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="do-sear mt-2">
                        <label>Branch Address</label>
                        <input type="text" className="vi_0" placeholder="Please enter Branch Address" value={branchAddress} onChange={(e)=>setbranchAddress(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Branch Mobile</label>
                        <input type="text" className="vi_0" placeholder="Please enter Branch Mobile" value={branchNumber} onChange={(e)=>setbranchNumber(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Branch Email</label>
                        <input type="text" className="vi_0" placeholder="Please enter Branch Email" value={branchEmail} onChange={(e)=>setbranchEmail(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Name of the Trainee</label>
                        <input type="text" className="vi_0" placeholder="Please enter Name of the Trainee" value={Name} onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Enrollment Number</label>
                        <input type="text" className="vi_0" placeholder="Please enter Enrollment Number" value={EnrollmentNumber} onChange={(e)=>setEnrollmentNumber(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date of Enrollment</label>
                        <input type="text" className="vi_0" placeholder="Please enter Date of Enrollment" value={DateofEnrollment} onChange={(e)=>setDateofEnrollment(e.target.value)} />
                    </div><br/>
                    <label>Attendence Details</label>
                    <div style={{border:"2px solid red",borderRadius:"10px",padding:"10px"}}>
                    <div className="do-sear mt-2">
                        <label>Attendence Date</label>
                        <input type="date" className="vi_0" placeholder="Please enter Date" value={classDate} onChange={(e)=>setclassDate(e.target.value)}/>
                    </div>

                    <div className="do-sear mt-2">
                        <label style={{ textAlign: "center" }}>Hours Spent in Driving School</label><br />
                        <div className="row " style={{ marginLeft: "-12px" }}>
                            <div className="col-md-4">
                                <label htmlFor="">Start From</label>
                                <input type="time" className="vi_0" placeholder="Please enter Start time" value={FromHrs} onChange={(e)=>setFromHrs(e.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Till</label>
                                <input type="time" className="vi_0" placeholder="Please enter Till" value={ToHrs} onChange={(e)=>setToHrs(e.target.value)} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Total Hrs</label>
                                <input type="text" className="vi_0" placeholder="Total Hrs" value={TotalHrs} onChange={(e)=>setTotalHrs(e.target.value)}/>
                            </div>
                        </div>

                            

                    </div>
                    <div className="do-sear mt-2">
                        <label>Class Of Vehicle</label>
                        <input type="text" className="vi_0" placeholder="Please enter Class Of Vehicle " value={Classofvehicle} onChange={(e)=>setClassofvehicle(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Signature / Name of  Instructor</label>
                        <input type="text" className="vi_0" placeholder="Please enter Signature / Name of  Instructor" value={Signatureofinsturctor} onChange={(e)=>setSignatureofinsturctor(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Signature / Name of  Trainee</label>
                        <input type="text" className="vi_0" placeholder="Please enter Signature / Name of  Trainee" value={SignatureofTrainer} onChange={(e)=>setSignatureofTrainer(e.target.value)} />
                    </div>
                    <br/>
                    <button className="btn btn-danger" onClick={AddClass}>Add</button>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={AddFrorm15}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminForm15
