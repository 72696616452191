import React, { useEffect, useState } from "react";
import Side from "./sidebar/Side";
import axios from "axios";
import ReactPaginate from "react-paginate";

const LeaveUpdate = () => {
  const staff = JSON.parse(sessionStorage.getItem("staff"));

  if (!staff) {
    alert("Please login");
    window.location.assign("/");
  }

  const trainer = JSON.parse(sessionStorage.getItem("staff"));

  const [tableData, setTableData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [days, setdays] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [Reason, setreason] = useState("");

  useEffect(() => {
    if (trainer) {
      setname(trainer?.name);
      setemail(trainer?.email);
      setmobile(trainer?.mobile);
    }
  }, []);

  const getLeaveData = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getByUserIdLeave/" + trainer?._id);
      if (res.status == 200) {
        setTableData(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!name) return alert("Please enter name");
      if (!mobile) return alert("Please enter mobile number");
      if (!email) return alert("Please enter email id");
      if (!days) return alert("Please enter number of days");
      if (!fromDate) return alert("Select from date");
      if (!toDate) return alert("Select to date");
      if (!Reason) return alert("Please enter your reason")
      const config = {
        url: "/admin/addstaffleave",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          name: name,
          email: email,
          mobile: mobile,
          userType: "Trainer",
          Fromdate: fromDate,
          Todate: toDate,
          Reason: Reason,
          userId: trainer?._id,
          days: days,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully posted leave");
        getLeaveData()
        setfromDate("");
        setdays("");
        settoDate("");
        setreason("")
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (trainer) {
      getLeaveData();
    }
  }, [])
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(tableData.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleDelete = (index) => { };

  const handleEdit = async (event) => {
    event.preventDefault();
    try {
      const config = {
        url: "/admin/UpdateStaffleave",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          name: name,
          email: email,
          mobile: mobile,
          userType: "Trainer",
          Fromdate: fromDate,
          Todate: toDate,
          Reason: Reason,
          userId: trainer?._id,
          days: days,
          id: editData?._id
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated leave");
        getLeaveData()
        setfromDate("");
        setdays("");
        settoDate("");
        setreason("")
        setupdate(false)
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const [update, setupdate] = useState(false)
  const [editData, setEditData] = useState({});
  return (
    <div>
      <Side />
      <div className="container bg-35  ">
        <div className="row me-0">
          {/* <div className="main_employee"> */}
          <div>
            {staff?.profile ? (
              <img
                src={`https://jaidrivingschool.in/Staff/${staff?.profile}`}
                alt=""
                width={150}
                height={150}
                style={{
                  // border: "5px solid rgb(157, 4, 27)",
                  borderRadius: "100%",
                  width: "150px",
                  marginLeft: "30%",
                  padding: "2px",
                  marginTop: "10px",
                  display: "flex",
                  margin: "auto"
                }}
              />
            ) : (
              <img
                src="./images/person1.jpg"
                alt="Admin"
                className="rounded-circle"
                width="150"
                height="150px"
              />
            )}


            <div class="row">
              <h2
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Leave Update Form
              </h2>
            </div>
            {update ? (<form>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 ">
                    <label> Name:</label>
                    <br />
                    <input
                      // className="form-control"
                      type="text"
                      name="firstname"
                      id="finame"
                      placeholder={editData?.name}
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label>Mobile No:</label>
                    <br />
                    <input
                      // className="form-control"
                      type="number"
                      name="lastname"
                      id="finame"
                      min="1"
                      minLength="10"
                      maxLength="10"
                      placeholder={editData?.mobile}
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row me-0">
                  <div className="col-md-6 ">
                    <label htmlFor="instructorsid">Email:</label>
                    <br />
                    <input
                      // className="form-control"
                      type="email"
                      name="instructorsid"
                      id="finame"
                      placeholder={editData?.email}
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="phoneNumber">Number of day</label>
                    <br />
                    <input
                      // className="form-control"
                      type="number"
                      name="reason"
                      min={"1"}
                      id="finame"
                      placeholder={editData?.days}
                      value={days}
                      onChange={(e) => setdays(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <label htmlFor="">From</label>
                    <br />

                    <input
                      // className="form-control"
                      type="date"
                      name="from"
                      id="finame"
                      onChange={(e) => setfromDate(e.target.value)}
                    // onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="course">To</label>
                    <br />
                    <input
                      // className="form-control"
                      type="date"
                      name="to"
                      id="finame"
                      onChange={(e) => settoDate(e.target.value)}
                    // onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phoneNumber">Reason</label>
                    <br />
                    <textarea
                      // className="form-control"

                      name="reason"
                      id="finame"
                      cols={40}
                      rows={3}
                      placeholder={editData?.Reason}
                      value={Reason}
                      onChange={(e) => setreason(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn btn-warning mt-4"
                      type="button"
                      style={{ marginLeft: "40px", width: "7rem" }}
                      onClick={(e) => handleEdit(e)}
                    >
                      {"Update"}
                    </button></div>
                  <div className="col-md-6">
                    <button
                      className="btn btn-warning mt-4"
                      type="button"
                      // style={{ marginLeft: "40px", width: "7rem" }}
                      onClick={(e) => setupdate(false)}
                    >
                      {"Cancel"}
                    </button> </div>
                </div>

              </div>
            </form>) : (<form>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 ">
                    <label> Name:</label>
                    <br />
                    <input
                      // className="form-control"
                      type="text"
                      name="firstname"
                      id="finame"
                      placeholder="Please enter name"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label>Mobile No:</label>
                    <br />
                    <input
                      // className="form-control"
                      type="number"
                      name="lastname"
                      id="finame"
                      min="1"
                      minLength="10"
                      maxLength="10"
                      placeholder="Enter mobile number"
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row me-0">
                  <div className="col-md-6 ">
                    <label htmlFor="instructorsid">Email:</label>
                    <br />
                    <input
                      // className="form-control"
                      type="email"
                      name="instructorsid"
                      id="finame"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="phoneNumber">Number of day</label>
                    <br />
                    <input
                      // className="form-control"
                      type="number"
                      name="reason"
                      min={"1"}
                      id="finame"
                      placeholder="Eg:-1"
                      value={days}
                      onChange={(e) => setdays(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <label htmlFor="">From</label>
                    <br />

                    <input
                      // className="form-control"
                      type="date"
                      name="from"
                      id="finame"
                      onChange={(e) => setfromDate(e.target.value)}
                    // onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="course">To</label>
                    <br />
                    <input
                      // className="form-control"
                      type="date"
                      name="to"
                      id="finame"
                      onChange={(e) => settoDate(e.target.value)}
                    // onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phoneNumber">Reason</label>
                    <br />
                    <textarea
                      // className="form-control"

                      name="reason"
                      id="finame"
                      cols={40}
                      rows={3}
                      placeholder="Please enter your reason"
                      value={Reason}
                      onChange={(e) => setreason(e.target.value)}
                    />
                  </div>
                </div>

                <button
                  className="btn btn-warning mt-4"
                  type="button"
                  style={{ marginLeft: "40px", width: "7rem" }}
                  onClick={(e) => handleSubmit(e)}
                >
                  {editingIndex !== null ? "Update" : "Submit"}
                </button>
              </div>
            </form>)}

          </div>
          {/* </div> */}
        </div>
      </div>
      <div>
        <div className="row me-0">
          <div className="update" style={{ marginTop: "1rem" }}>
            <h1>Leave Update Table</h1>
            <div className="cs">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Days</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((data, index) => (
                    <tr key={index}>
                      <td>{data?.name}</td>
                      <td>{data?.mobile}</td>
                      <td>{data?.email}</td>
                      <td>{data?.days}</td>
                      <td>{data?.Fromdate}</td>
                      <td>{data?.Todate}</td>
                      <td>
                        <p style={{ overflow: "hidden", overflowY: "scroll", height: "100px" }}> {data?.Reason}</p>
                      </td>
                      <td>{data?.status == "Approved" ? (<span style={{ color: "green" }}>{data?.status}</span>) : (<span style={{ color: "red" }}>{data?.status}</span>)}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setEditData(data);
                            setupdate(true)
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveUpdate;
