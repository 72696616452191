import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Carousel from 'react-grid-carousel'
import { useNavigate } from 'react-router-dom';
import { contextType } from 'react-star-ratings';


export const Courses2 = () => {

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/course/getAllcourse"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCourse();
  }, [])
  useEffect(() => {
    getAllCourse();
  }, [])
  console.log("dsjdjs", allCourse)

  const history = useNavigate()
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <div>
      <Container className='mb-5'>
        <div className="text-center mb-3">
          <h1 className="fw-bold">OUR COURSES</h1>
          <p style={{ fontSize: '12px' }}>Check Out Multiple Courses</p>
        </div>

        <div>

          <Carousel cols={4} rows={1} gap={10} loop>
            {allCourse?.map((item) => <Carousel.Item  >

              <div className='text-center'>
                <div style={{ position: 'relative' }}>
                  <img src={`https://jaidrivingschool.in/Course/${item?.courseImage}`} alt="" style={{ width: '200px', height: '200px', borderRadius: '20px' }} />
                  <p style={{
                    padding: '0px 3px',
                    border: "1px solid #fccc0b",
                    background: '#fccc0b',
                    position: "absolute",
                    right: '39%',
                    bottom: "-8%",
                    borderRadius: '2px'

                  }}>{item?.courseDuration} Days</p>
                </div>
                <p className=" fs-6" style={{ fontWeight: "600" }}>{item?.courseName}</p>
                <a><button className="courses-btn" onClick={() => history("/courseviewmore", { state: item })}>MORE INFO</button></a>
              </div>

            </Carousel.Item>)}
            {/* 
            <Carousel.Item>
              <div className='text-center'>
                <div style={{ position: 'relative' }}>
                  <img src="./images/bg-18.jpg" alt="" style={{ width: '200px', height: '200px', borderRadius: '20px' }} />
                  <p style={{
                    padding: '0px 3px',
                    border: "1px solid #fccc0b",
                    background: '#fccc0b',
                    position: "absolute",
                    right: '39%',
                    bottom: "-8%",
                    borderRadius: '2px'

                  }}>20 Days</p>
                </div>
                <p className="fw-bold fs-5">Adult In Car Lessons</p>
                <a href="/courses"><button className="courses-btn">MORE INFO</button></a>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className='text-center'>
                <div style={{ position: 'relative' }}>
                  <img src="./images/bg-14.webp" alt="" style={{ width: '200px', height: '200px', borderRadius: '20px' }} />
                  <p style={{
                    padding: '0px 3px',
                    border: "1px solid #fccc0b",
                    background: '#fccc0b',
                    position: "absolute",
                    right: '39%',
                    bottom: "-8%",
                    borderRadius: '2px'

                  }}>30 Days</p>
                </div>
                <p className="fw-bold fs-5">Program for Seniors</p>
                <a href="/courses"><button className="courses-btn">MORE INFO</button></a>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className='text-center'>
                <div style={{ position: 'relative' }}>
                  <img src="./images/bg-47.jpg" alt="" style={{ width: '200px', height: '200px', borderRadius: '20px' }} />
                  <p style={{
                    padding: '0px 3px',
                    border: "1px solid #fccc0b",
                    background: '#fccc0b',
                    position: "absolute",
                    right: '39%',
                    bottom: "-8%",
                    borderRadius: '2px'

                  }}>40 Days</p>
                </div>

                <p className="fw-bold fs-5">Winter Driving</p>
                <a href="/courses"><button className="courses-btn">MORE INFO</button></a>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className='text-center'>
                <div style={{ position: 'relative' }}>
                  <img src="./images/56789.jpg" alt="" style={{ width: '200px', height: '200px', borderRadius: '20px' }} />
                  <p style={{
                    padding: '0px 3px',
                    border: "1px solid #fccc0b",
                    background: '#fccc0b',
                    position: "absolute",
                    right: '39%',
                    bottom: "-8%",
                    borderRadius: '2px'

                  }}>50 Days</p>
                </div>
                <p className="fw-bold fs-5">Defensive Driving</p>
                <a href="/courses"><button className="courses-btn">MORE INFO</button></a>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className='text-center'>
                <div style={{ position: 'relative' }}>
                  <img src="./images/456.jpg" alt="" style={{ width: '200px', height: '200px', borderRadius: '20px' }} />
                  <p style={{
                    padding: '0px 3px',
                    border: "1px solid #fccc0b",
                    background: '#fccc0b',
                    position: "absolute",
                    right: '39%',
                    bottom: "-8%",
                    borderRadius: '2px'

                  }}>60 Days</p>
                </div>
                <p className="fw-bold fs-5">Stick Shift Lessons</p>
                <a href="/courses"><button className="courses-btn">MORE INFO</button></a>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </Container>
    </div>
  )
}
