import React, { useState } from 'react'
import Sidebar1 from './Sidebar1'
import Sidebrr1 from './Sidebrr1.jsx'
import Siide1 from './Siide1'

const SideStudent = () => {
    const [sidebar1,setSidebar1]=useState(false)
    const togglesidebar1=()=>{
        setSidebar1(!sidebar1)
    }
  return (
    <div>
        <Sidebar1 opensidebar1={togglesidebar1}/>
        <Siide1  sidebar1={sidebar1} closesidebar1={togglesidebar1}/>
        <Sidebrr1 sidebar1={sidebar1}/>
    </div>
  )
}

export default SideStudent