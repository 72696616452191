import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  MdDashboardCustomize,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdOutlineReviews,
} from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { GrServices, GrUserAdmin } from "react-icons/gr";
import { FaFileWaveform, FaUsersLine } from "react-icons/fa6";

import { colors } from "@mui/material";
import { BsFillPersonBadgeFill } from "react-icons/bs";
import axios from "axios";
import { useEffect } from "react";

function SideBar() {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);
  const [acc9, setacc9] = useState(false);
  const [acc10, setacc10] = useState(false);
  const [acc11, setacc11] = useState(false);
  const [acc12, setacc12] = useState(false);
  const [acc13, setacc13] = useState(false);
  const [acc14, setacc14] = useState(false);
  const [acc15, setacc15] = useState(false);
  const [acc16, setacc16] = useState(false);
  const [acc17, setacc17] = useState(false);
  const [acc18, setacc18] = useState(false);
  const [acc19, setacc19] = useState(false);
  const [acc20, setacc20] = useState(false);
  const [acc21, setacc21] = useState(false);
  const [acc22, setacc22] = useState(false);
  const [acc23, setacc23] = useState(false);
  const [acc24, setacc24] = useState(false);
  const [acc25, setacc25] = useState(false);
  const [acc26, setacc26] = useState(false);
  const [acc27, setacc27] = useState(false);
  const [acc28, setacc28] = useState(false);
  const [acc29, setacc29] = useState(false);
  const [acc30, setacc30] = useState(false);
  const [acc31, setacc31] = useState(false);
  const [acc32, setacc32] = useState(false);
  const [acc33, setacc33] = useState(false);
  const [acc34, setacc34] = useState(false);
  const [acc35, setacc35] = useState(false);
  const [acc36, setacc36] = useState(false);
  const [acc37, setacc37] = useState(false);
  const [acc38, setacc38] = useState(false);
  const [acc39, setacc39] = useState(false);
  const [acc40, setacc40] = useState(false);
  const [acc41, setacc41] = useState(false);
  const [acc42, setacc42] = useState(false);
  const [gallery, setgallery] = useState(false);
  const [Branch, setBranch] = useState(false);
  const [service, setservice] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [bookS, setbookS] = useState(false);
  const [timeSlot, settimeSlot] = useState(false);
  const [opening, setopening] = useState(false);
  const [Enquires, setEnquires] = useState(false);
  const [ratting, setratting] = useState(false);
  const [Leaves, setLeave] = useState(false);
  const [FAQ, setFAQ] = useState(false);
  const [SafetyRule, setSafetyRule] = useState(false);
  const [vehicleT, setvehicleT] = useState(false);
  const [additional, setadditional] = useState(false);
  const [expanse, setexpanse] = useState(false);
  const [webManagement, setwebManagement] = useState(false);
  const [vehiclemanagement, setvehiclemanagement] = useState(false);
  const [courses, setCourses] = useState(false);
  const [services, setServices] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [Rto, setRto] = useState(false);
  const [RtoApp, setRtoApp] = useState(false);
  const [form, setForm] = useState(false);
const [logo,setlogo]=useState(false);
const [serviceCate,setserviceCate]=useState(false);
const [feedback,setfeedbeack]=useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const admin=JSON.parse(sessionStorage.getItem("admin"));
// console.log("admin",admin);
const [AllHomeLogo, setAllHomeLogo] = useState([]);
const getHomeLogo = async () => {
  try {
    let res = await axios.get("https://jaidrivingschool.in/api/admin/getHomeLogo");
    if (res.status == 200) {
      setAllHomeLogo(res.data.success);
    }
  } catch (error) {
    console.log(error);
  }
};
useEffect(() => {
  getHomeLogo();
}, []);

  return (
    <div className="si09">
      {AllHomeLogo?.map((ele)=>{
        return (<div className="lo-ad">
        <img
           src={`https://jaidrivingschool.in/HomeLogo/${ele?.image}`}
          alt="adminlogo"
          style={{ width: "200px", height: "87px", padding: "0px 11px" }}
        />
      </div>)
      })}
      
      <ul className="">
        <Link to="/dashboard">
          <li
            className={`a-ele ${acc ? "active1" : ""}`}
            onClick={() => {
              setacc(true);
              setacc1(false);
              settimeSlot(false);
              setopening(false);
              setEnquires(false);
              setratting(false);
              setadditional(false);
              setexpanse(false);
              setLeave(false);
              setacc2(false);
              setacc3(false);
              setFAQ(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setBranch(false);
              setservice(false);
              setbookS(false);
              setvehicleT(false);
              setSafetyRule(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <LuLayoutDashboard style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Dashboard</span>
          </li>
        </Link>
            {admin?.SubAdmin==true ? (  <Link to="/subadmin">
          <li
            className={`a-ele ${acc1 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(true);
              setacc2(false);
              settimeSlot(false);
              setopening(false);
              setacc3(false);
              setvehicleT(false);
              setLeave(false);
              setexpanse(false);
              setadditional(false);
              setbookS(false);
              setacc4(false);
              setFAQ(false);
              setratting(false);
              setacc5(false);
              setacc6(false);
              setservice(false);
              setEnquires(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setSafetyRule(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setgallery(false);
              setBranch(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i class="fa-solid fa-user" style={{ fontSize: "20px" }}></i>
            </span>
            <span className="ms-2">SubAdmin</span>
          </li>
        </Link>):(<></>)}
      {admin?.webMangement==true ? (  <Link to="#">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setwebManagement(!webManagement);
              setvehiclemanagement(false);
              setCourses(false);
              setServices(false);
              setEmployee(false);
              setRto(false);
              setForm(false);
              setacc(false);
              setacc1(true);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setlogo(false)
            }}
          >
            <span>
              <i class="fa-solid fa-globe" style={{ fontSize: "20px" }}></i>
            </span>{" "}
            <span>Web Management </span>{" "}
            {webManagement ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowLeft />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>):(<></>)}
      {admin?.webMangement==true ? (   <Link>
          {webManagement ? (
            <>
              <div className="webmanagement">
                <Link to="/adminhome">
                  <li className={`a-ele ${acc2 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(true);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setfeedbeack(false)
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                      setlogo(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa fa-bar-chart"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>{" "}
                    <span className="ms-1">Graph</span>
                  </li>
                </Link>
                <Link to="/admin-gallery">
                  <li className={`a-ele ${acc3 ? "active2" : "null"}`}
                   onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(true);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setfeedbeack(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                    setlogo(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-image"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>{" "}
                    <span className="ms-1">Gallery</span>
                  </li>
                </Link>
                <Link to="/admin-home">
                  <li className={`a-ele ${logo ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setlogo(true);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                      setfeedbeack(false);
                    }}
                  >
                    <span>
                      <i
                       class="fa-solid fa-home"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> Home</span>
                  </li>
                </Link>
                <Link to="/adminaboutus">
                  <li className={`a-ele ${acc4 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(true);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setlogo(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setfeedbeack(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-info ms-1"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> About Us</span>
                  </li>
                </Link>
                <Link to="/Admin-Opening-Hour">
                  <li className={`a-ele ${acc6 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(true);
                      setacc7(false);
                      setlogo(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setfeedbeack(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-clock"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-1"> Opening Hour</span>
                  </li>
                </Link>
                <Link to="/Admin-Time-Slots">
                  <li className={`a-ele ${acc7 ? "active2" : "null"}`}
                   onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setlogo(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(true);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setfeedbeack(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-check-to-slot"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Available Slot</span>
                  </li>
                </Link>
                <Link to="/count">
                  <li className={`a-ele ${acc8 ? "active2" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(true);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setlogo(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                    setfeedbeack(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa fa-calculator ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Counts</span>
                  </li>
                </Link>
                <Link to="/Admin-FAQ">
                  <li className={`a-ele ${acc9 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(true);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setlogo(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                      setfeedbeack(false);
                    }}
                  >
                    {" "}
                    <span>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>{" "}
                    <span className="ms-1">FAQ</span>{" "}
                  </li>
                </Link>
                <Link to="/admin-safety-rules">
                  <li className={`a-ele ${acc10 ? "active2" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(true);
                    setlogo(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setfeedbeack(false)
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    {" "}
                    <span>
                      <i
                        class="fa-solid fa-helmet-safety"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Safety Rules</span>
                  </li>
                </Link>
                <Link to="/Admin-feedback">
                  <li className={`a-ele ${feedback ? "active2" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setlogo(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                    setfeedbeack(true)
                  }}
                  >
                    <span>
                      <MdOutlineReviews style={{ fontSize: "20px" }} />
                    </span>
                    <span className="ms-2">Feedback</span>
                  </li>
                </Link>
                <Link to="/Admin-Reviews">
                  <li className={`a-ele ${acc11 ? "active2" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(true);
                    setacc12(false);
                    setlogo(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                    setfeedbeack(false)
                  }}
                  >
                    <span>
                      <MdOutlineReviews style={{ fontSize: "20px" }} />
                    </span>
                    <span className="ms-2">Reviews</span>
                  </li>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Link>):(<></>)}
     
            {admin?.Branch==true ? (  <Link to="/branches">
          <li
            className={`a-ele ${acc12 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setratting(false);
              setSafetyRule(false);
              setbookS(false);
              setacc8(false);
              setexpanse(false);
              setadditional(false);
              setacc9(false);
              setFAQ(false);
              setacc10(false);
              settimeSlot(false);
              setvehicleT(false);
              setopening(false);
              setservice(false);
              setlogo(false);
              setacc11(false);
              setEnquires(false);
              setLeave(false);
              setacc12(true);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
              // setBranch(true);
            }}
          >
            <span>
              <i
                class="fas fa-code-branch ms-1"
                style={{ fontSize: "20px" }}
              ></i>
            </span>
            <span className="ms-1">Our Branches</span>
          </li>
        </Link>):(<></>)}
        {admin?.Vehicle==true ? (   <Link to="#">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setvehiclemanagement(!vehiclemanagement);
              setCourses(false);
              setwebManagement(false);
              setServices(false);
              setEmployee(false);
              setRto(false);
              setForm(false);
              setacc(false);
              setacc1(true);
              setlogo(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
            }}
          >
            <span>
              {/* <FontAwesomeIcon icon={faCar} /> */}
              <i class="fa-solid fa-car" style={{ fontSize: "20px" }}></i>
            </span>{" "}
            <span>Vehicle Management </span>{" "}
            {vehiclemanagement ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowLeft />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>):(<></>)}
        {admin?.Vehicle==true ? (<Link to="">
          {vehiclemanagement ? (
            <>
              <div className="webmanagement">
                <Link to="/admin-vehicle-type">
                  <li className={`a-ele ${acc14 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(true);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setlogo(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-bicycle"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>{" "}
                    <span className="ms-1">Class of Vehicle</span>
                  </li>
                </Link>
                <Link to="/category">
                  <li className={`a-ele ${acc15 ? "active2" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(true);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa fa-plus ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> Add Vehicle</span>
                  </li>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Link>):(<></>)}
        
        {admin?.Discount==true ? (  <Link to="/AdminDiscount">
          <li
            className={`a-ele ${acc16 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setadditional(false);
              setacc4(false);
              setEnquires(false);
              setservice(false);
              setacc5(false);
              setbookS(false);
              setLeave(false);
              setratting(false);
              setFAQ(false);
              setacc6(false);
              setSafetyRule(false);
              setvehicleT(false);
              setexpanse(false);
              setacc7(true);
              setacc8(false);
              setacc9(false);
              settimeSlot(false);
              setopening(false);
              setBranch(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i
                class="fa fa-gift"
                aria-hidden="true"
                style={{ fontSize: "20px" }}
              ></i>
            </span>
            <span className="ms-2">Discount</span>
          </li>
        </Link>):(<></>)}
      
            {admin?.Courses==true ? ( <Link to="">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setCourses(!courses);
              setvehiclemanagement(false);
              setwebManagement(false);
              setServices(false);
              setEmployee(false);
              setRto(false);
              setForm(false);
              setacc(false);
              setacc1(true);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
            }}
          >
            <span>
              {/* <FontAwesomeIcon icon={faCar} /> */}
              <i class="fa-solid fa-book-open" style={{ fontSize: "20px" }}></i>
            </span>{" "}
            <span>Courses </span>{" "}
            {courses ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowLeft />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>):(<></>)}
        {admin?.Courses==true ? ( <Link to="">
          {courses ? (
            <>
              <div className="webmanagement">
                <Link to="/admincourses">
                  <li className={`a-ele ${acc17 ? "active2" : "null"}`}
                     onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(true);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa fa-plus ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> Add Courses</span>
                  </li>
                </Link>
                <Link to="/course_topics">
                  <li
                    className={`a-ele ${acc38 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(true);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa fa-plus ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> Add Course Topics</span>
                  </li>
                </Link>
                <Link to="/adminstudent">
                  <li className={`a-ele ${acc18 ? "active2" : "null"}`}
                   onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(true);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-check"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> Booked Course</span>
                  </li>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Link>):(<></>)}
        {admin?.ServicesA==true ? ( <Link to="">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setServices(!services);
              setvehiclemanagement(false);
              setwebManagement(false);
              setCourses(false);
              setEmployee(false);
              setRto(false);
              setForm(false);
              setacc(false);
              setacc1(true);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
            }}
          >
            <span>
              {/* <FontAwesomeIcon icon={faCar} /> */}
              <i class="fa-solid fa-gear" style={{ fontSize: "20px" }}></i>
            </span>{" "}
            <span>Services </span>{" "}
            {services ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowLeft />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>):(<></>)}
        {admin?.ServicesA==true ? (<Link>
          {services ? (
            <>
              <div className="webmanagement">
              <Link to="/admin-service-category">
                  <li
                    className={`a-ele ${serviceCate ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setserviceCate(true);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      {" "}
                      <i
                        class="fa fa-plus ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Add Services category</span>
                  </li>
                </Link>
                <Link to="/Admin-service">
                  <li
                    className={`a-ele ${acc19 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setserviceCate(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(true);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      {" "}
                      <i
                        class="fa fa-plus ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Add Services</span>
                  </li>
                </Link>
                <Link to="/service_topics">
                  <li
                    className={`a-ele ${acc39 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setserviceCate(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(true);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa fa-plus ms-1"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2"> Add Service Documents</span>
                  </li>
                </Link>
                <Link to="/Book-services">
                  <li className={`a-ele ${acc20 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setserviceCate(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(true);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-check"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Booked Services</span>
                  </li>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Link>):(<></>)}
        
          {admin?.Additional==true ? ( <Link to="/admin-additional-charges">
          <li
            className={`a-ele ${acc21 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setBranch(false);
              setservice(false);
              setexpanse(false);
              setacc3(false);
              setacc4(false);
              setbookS(false);
              setSafetyRule(false);
              setvehicleT(false);
              setratting(false);
              setEnquires(false);
              setadditional(true);
              setLeave(false);
              setacc5(false);
              setacc6(false);
              setFAQ(false);
              setacc7(false);
              settimeSlot(false);
              setopening(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(true);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i
                class="fa-solid fa-money-bill"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2"> Additional Charges</span>
          </li>
        </Link>):(<></>)}
        {admin?.Customer==true ? ( <Link to="/admincustomer">
          <li
            className={`a-ele ${acc22 ? "active1" : ""}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setadditional(false);
              setacc5(false);
              setvehicleT(false);
              setSafetyRule(false);
              setservice(false);
              setexpanse(false);
              setacc6(false);
              setFAQ(false);
              setacc7(false);
              setEnquires(false);
              setacc8(false);
              settimeSlot(false);
              setLeave(false);
              setopening(false);
              setbookS(false);
              setratting(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setBranch(false);
              setacc16(false);
              setacc17(false);
              setgallery(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(true);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <BsFillPersonBadgeFill style={{ fontSize: "18px" }} />
            </span>
            <span className="ms-2">Customer/Student List</span>
          </li>
        </Link>):(<></>)}
        {admin?.Employee==true ? (<Link to="">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setEmployee(!employee);
              setServices(false);
              setvehiclemanagement(false);
              setwebManagement(false);
              setCourses(false);
              setRto(false);
              setForm(false);
              setacc(false);
              setacc1(true);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
            }}
          >
            <span>
              {/* <FontAwesomeIcon icon={faCar} /> */}
              <i class="fas fa-users" style={{ fontSize: "18px" }}></i>
            </span>{" "}
            <span>Employee Management </span>{" "}
            {employee ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowLeft />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>):(<></>)}
        {admin?.Employee==true ? (  <Link to="">
          {employee ? (
            <>
              <div className="webmanagement">
                <Link to="/admintrainer">
                  <li className={`a-ele ${acc23 ? "active2" : "null"}`} 
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(true);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-user"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </span>
                    <span className="ms-2">Trainers</span>
                  </li>
                </Link>
                <Link to="/adminstaff">
                  <li className={`a-ele ${acc24 ? "active2" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(true);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <FaUsersLine style={{ fontSize: "20px" }} />
                    </span>
                    <span className="ms-2">Staff</span>
                  </li>
                </Link>
                {admin?.Leaves==true ? ( <Link to="/Admin-Leaves">
          <li
            className={`a-ele ${acc31 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setEnquires(false);
              setbookS(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setadditional(false);
              setvehicleT(false);
              setacc7(false);
              setBranch(false);
              setFAQ(false);
              setLeave(true);
              setratting(false);
              setexpanse(false);
              setSafetyRule(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              settimeSlot(false);
              setopening(false);
              setservice(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(true);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(false);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
            }}
          >
            <span>
              <MdDashboardCustomize style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Leaves</span>
          </li>
        </Link>):(<></>)}
              </div>
            </>
          ) : (
            ""
          )}
        </Link>):(<></>)}
        {admin?.RTO==true ? ( <Link to="">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setRto(!Rto);
              setEmployee(false);
              setServices(false);
              setvehiclemanagement(false);
              setwebManagement(false);
              setCourses(false);
              setForm(false);
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
            }}
          >
            <span>
              {/* <FontAwesomeIcon icon={faCar} /> */}
              <i class="fa-solid fa-taxi" style={{ fontSize: "18px" }}></i>
            </span>{" "}
            <span>RTO Management </span>{" "}
            {Rto ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowLeft />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>):(<></>)}
        {admin?.RTO==true ? ( <Link to="">
          {Rto ? (
            <>
              <div className="webmanagement">
                <Link to="/Rto_list">
                  <li className={`a-ele ${acc25 ? "active2" : "null"}`} 
                   onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(true);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-list-ul"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </span>
                    <span className="ms-2">RTO List</span>
                  </li>
                </Link>
                <Link to="">
                  <li
                    className={`a-ele ${acc1 ? "active-0" : "null"}`}
                    onClick={() => {
                      setRtoApp(!RtoApp);
                      setEmployee(false);
                      setServices(false);
                      setvehiclemanagement(false);
                      setwebManagement(false);
                      setForm(false);
                      setCourses(false);
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                    }}
                  >
                    <span>
                      {" "}
                      <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                    </span>
                    <span className="ms-2">RTO Application </span>{" "}
                    {RtoApp ? (
                      <>
                        {" "}
                        <span style={{ float: "right" }}>
                          <MdOutlineKeyboardArrowLeft />
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ float: "right" }}>
                          <MdOutlineKeyboardArrowDown />
                        </span>
                      </>
                    )}
                  </li>
                </Link>
                <Link to="">
                  {RtoApp ? (
                    <div className="webmanagement1">
                      <Link to="/LL_Application">
                        <li className={`a-ele ${acc26 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                            setacc22(false);
                            setacc23(false);
                            setacc24(false);
                            setacc25(false);
                            setacc26(true);
                            setacc27(false);
                            setacc28(false);
                            setacc29(false);
                            setacc30(false);
                            setacc31(false);
                            setacc32(false);
                            setacc33(false);
                            setacc34(false);
                            setacc35(false);
                            setacc36(false);
                            setacc37(false);
                            setacc38(false);
                            setacc39(false);
                            setacc40(false);
                            setacc41(false);
                            setacc42(false);
                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-id-card"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </span>
                          <span className="ms-2">L L Application</span>
                        </li>
                      </Link>
                      <Link to="/DL_Application">
                        <li className={`a-ele ${acc27 ? "active2" : "null"}`}
                         onClick={() => {
                          setacc(false);
                          setacc1(false);
                          setacc2(false);
                          setacc3(false);
                          setacc4(false);
                          setacc5(false);
                          setacc6(false);
                          setacc7(false);
                          setacc8(false);
                          setacc9(false);
                          setacc10(false);
                          setacc11(false);
                          setacc12(false);
                          setacc13(false);
                          setacc14(false);
                          setacc15(false);
                          setacc16(false);
                          setacc17(false);
                          setacc18(false);
                          setacc19(false);
                          setacc20(false);
                          setacc21(false);
                          setacc22(false);
                          setacc23(false);
                          setacc24(false);
                          setacc25(false);
                          setacc26(false);
                          setacc27(true);
                          setacc28(false);
                          setacc29(false);
                          setacc30(false);
                          setacc31(false);
                          setacc32(false);
                          setacc33(false);
                          setacc34(false);
                          setacc35(false);
                          setacc36(false);
                          setacc37(false);
                          setacc38(false);
                          setacc39(false);
                          setacc40(false);
                          setacc41(false);
                          setacc42(false);
                        }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-id-badge"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </span>
                          <span className="ms-2">D L Application</span>
                        </li>
                      </Link>
                      <Link to="/Other_Application">
                        <li
                          className={`a-ele ${acc40 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                            setacc22(false);
                            setacc23(false);
                            setacc24(false);
                            setacc25(false);
                            setacc26(false);
                            setacc27(false);
                            setacc28(false);
                            setacc29(false);
                            setacc30(false);
                            setacc31(false);
                            setacc32(false);
                            setacc33(false);
                            setacc34(false);
                            setacc35(false);
                            setacc36(false);
                            setacc37(false);
                            setacc38(false);
                            setacc39(false);
                            setacc40(true);
                            setacc41(false);
                            setacc42(false);
                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-id-badge"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </span>
                          <span className="ms-2">Other Applications</span>
                        </li>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </Link>
                <Link to="/RTO_Website_Payment">
                  <li className={`a-ele ${acc28 ? "active2" : "null"}`}
                   onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(true);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-money-bill"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </span>
                    <span className="ms-2">RTO Website Payment</span>
                  </li>
                </Link>
                <Link to="/RTO_CF_Payment">
                  <li className={`a-ele ${acc29 ? "active2" : "null"}`}
                   onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(true);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <i
                        class="fa-solid fa-money-check"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </span>
                    <span className="ms-2">RTO CF Payment</span>
                  </li>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Link>):(<></>)}
        {admin?.Blog==true ? ( <Link to="/adminblog">
          <li
            className={`a-ele ${acc30 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setEnquires(false);
              setbookS(false);
              setLeave(false);
              setacc5(true);
              setBranch(false);
              setexpanse(false);
              setSafetyRule(false);
              setadditional(false);
              settimeSlot(false);
              setopening(false);
              setratting(false);
              setacc6(false);
              setservice(false);
              setacc7(false);
              setFAQ(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setvehicleT(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(true);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i class="fa-solid fa-blog" style={{ fontSize: "20px" }}></i>
            </span>
            <span className="ms-2">Blog</span>
          </li>
        </Link>):(<></>)}
       
        {/* {admin?.Leaves==true ? ( <Link to="/Admin-Leaves">
          <li
            className={`a-ele ${Leaves ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setEnquires(false);
              setbookS(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setadditional(false);
              setvehicleT(false);
              setacc7(false);
              setBranch(false);
              setFAQ(false);
              setLeave(true);
              setratting(false);
              setexpanse(false);
              setSafetyRule(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              settimeSlot(false);
              setopening(false);
              setservice(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
            }}
          >
            <span>
              <MdDashboardCustomize style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Leaves</span>
          </li>
        </Link>):(<></>)} */}

        {admin?.Enquiry==true ? (  <Link to="/Admin-Enquires">
          <li
            className={`a-ele ${acc32 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setEnquires(true);
              setbookS(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setadditional(false);
              setacc6(false);
              setacc7(false);
              setexpanse(false);
              setBranch(false);
              setLeave(false);
              setratting(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              settimeSlot(false);
              setopening(false);
              setSafetyRule(false);
              setservice(false);
              setFAQ(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setvehicleT(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(true);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i
                class="fa fa-question-circle"
                aria-hidden="true"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2">Enquires</span>
          </li>
        </Link>):(<></>)}
       
      
        {admin?.expense==true ? ( <Link to="/Admin-expanse">
          <li
            className={`a-ele ${acc33 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setEnquires(false);
              setbookS(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setBranch(false);
              setLeave(false);
              setratting(false);
              setacc8(false);
              setadditional(false);
              setacc9(false);
              setvehicleT(false);
              setSafetyRule(false);
              setacc10(false);
              settimeSlot(false);
              setexpanse(true);
              setopening(false);
              setservice(false);
              setFAQ(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(true);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i
                class="fa-solid fa-money-bill"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2">Expense</span>
          </li>
        </Link>):(<></>)}
       
      

        {/* <Link to="/profile">
          <li className={`a-ele ${acc13 ? "active-0" : "null"}`} onClick={() => {
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setBranch(false)
            setacc4(false);
            setratting(false);
            setacc5(false);
            setacc6(false);
            setacc7(false);
            setacc8(false);
            setEnquires(false)
            setacc9(false);
            setacc10(false);
            setservice(false)
            setacc11(false);
            setFAQ(false);
            setacc12(false);
            setacc13(true);
            setacc14(false);
            setacc15(false);
            setLeave(false);
            setacc16(false)
            setgallery(false)
            settimeSlot(false);
            setopening(false);
            setacc17(false);
            setbookS(false)
          }}>Profile</li>
        </Link> */}
          {admin?.account==true ? (  <Link to="/bank-details">
          <li
            className={`a-ele ${acc34 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setvehicleT(false);
              setacc7(false);
              setacc8(false);
              setadditional(false);
              setexpanse(false);
              setbookS(false);
              setEnquires(false);
              setSafetyRule(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setratting(false);
              setFAQ(false);
              setacc12(false);
              setservice(false);
              setBranch(false);
              setacc13(false);
              settimeSlot(false);
              setopening(false);
              setacc14(true);
              setacc15(false);
              setLeave(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(true);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(false);
            }}
          >
            <span>
              <i
                class="fa fa-history"
                aria-hidden="true"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span className="ms-2"> Account History</span>
          </li>
        </Link>):(<></>)}
        {admin?.FormMangament==true ? (  <Link to="#">
          <li
            className={`a-ele ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setRtoApp(false);
              setEmployee(false);
              setServices(false);
              setvehiclemanagement(false);
              setwebManagement(false);
              setForm(!form);
              setacc(false);
              setacc1(true);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
            }}
          >
            <span>
              {/* <FontAwesomeIcon icon={faCar} /> */}
              <i class="fab fa-wpforms" style={{ fontSize: "18px" }}></i>
            </span>{" "}
            <span>Form Management </span>{" "}
            {form ? (
              <>
                {" "}
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowLeft />
                </span>
              </>
            ) : (
              <>
                <span style={{ float: "right" }}>
                  <MdOutlineKeyboardArrowDown />
                </span>
              </>
            )}
          </li>
        </Link>):(<></>)}
        {admin?.FormMangament==true ? ( <Link to="">
          {form ? (
            <>
              <div className="webmanagement">
                <Link to="/form5">
                  <li className={`a-ele ${acc35 ? "active2" : "null"}`}
                   onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(true);
                    setacc36(false);
                    setacc37(false);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <FaFileWaveform style={{ fontSize: "18px" }} />
                    </span>
                    <span className="ms-2">Form 5</span>
                  </li>
                </Link>
                <Link to="/form15">
                  <li className={`a-ele ${acc36 ? "active2" : "null"}`}
                    onClick={() => {
                      setacc(false);
                      setacc1(false);
                      setacc2(false);
                      setacc3(false);
                      setacc4(false);
                      setacc5(false);
                      setacc6(false);
                      setacc7(false);
                      setacc8(false);
                      setacc9(false);
                      setacc10(false);
                      setacc11(false);
                      setacc12(false);
                      setacc13(false);
                      setacc14(false);
                      setacc15(false);
                      setacc16(false);
                      setacc17(false);
                      setacc18(false);
                      setacc19(false);
                      setacc20(false);
                      setacc21(false);
                      setacc22(false);
                      setacc23(false);
                      setacc24(false);
                      setacc25(false);
                      setacc26(false);
                      setacc27(false);
                      setacc28(false);
                      setacc29(false);
                      setacc30(false);
                      setacc31(false);
                      setacc32(false);
                      setacc33(false);
                      setacc34(false);
                      setacc35(false);
                      setacc36(true);
                      setacc37(false);
                      setacc38(false);
                      setacc39(false);
                      setacc40(false);
                      setacc41(false);
                      setacc42(false);
                    }}
                  >
                    <span>
                      <FaFileWaveform style={{ fontSize: "18px" }} />
                    </span>
                    <span className="ms-2">Form 15</span>
                  </li>
                </Link>
                <Link to="/form14">
                  <li className={`a-ele ${acc37 ? "active2" : "null"}`}
                  onClick={() => {
                    setacc(false);
                    setacc1(false);
                    setacc2(false);
                    setacc3(false);
                    setacc4(false);
                    setacc5(false);
                    setacc6(false);
                    setacc7(false);
                    setacc8(false);
                    setacc9(false);
                    setacc10(false);
                    setacc11(false);
                    setacc12(false);
                    setacc13(false);
                    setacc14(false);
                    setacc15(false);
                    setacc16(false);
                    setacc17(false);
                    setacc18(false);
                    setacc19(false);
                    setacc20(false);
                    setacc21(false);
                    setacc22(false);
                    setacc23(false);
                    setacc24(false);
                    setacc25(false);
                    setacc26(false);
                    setacc27(false);
                    setacc28(false);
                    setacc29(false);
                    setacc30(false);
                    setacc31(false);
                    setacc32(false);
                    setacc33(false);
                    setacc34(false);
                    setacc35(false);
                    setacc36(false);
                    setacc37(true);
                    setacc38(false);
                    setacc39(false);
                    setacc40(false);
                    setacc41(false);
                    setacc42(false);
                  }}
                  >
                    <span>
                      <FaFileWaveform style={{ fontSize: "18px" }} />
                    </span>
                    <span className="ms-2">Form 14</span>
                  </li>
                </Link>
              </div>
            </>
          ) : (
            ""
          )}
        </Link>):(<></>)}
        <Link to="/admin_one_day_course">
          <li
            className={`a-ele ${acc42 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc41(false);
              setacc42(true);
            }}
          >
            <span>
              <LuLayoutDashboard style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">One Day Course </span>
          </li>
        </Link>
        <Link to="/generate_rescipt">
          <li
            className={`a-ele ${acc41 ? "active1" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              settimeSlot(false);
              setopening(false);
              setEnquires(false);
              setratting(false);
              setadditional(false);
              setexpanse(false);
              setLeave(false);
              setacc2(false);
              setacc3(false);
              setFAQ(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc12(false);
              setacc13(false);
              setacc14(false);
              setacc15(false);
              setacc16(false);
              setgallery(false);
              setacc17(false);
              setBranch(false);
              setservice(false);
              setbookS(false);
              setvehicleT(false);
              setSafetyRule(false);
              setacc41(true);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(false);
              setacc22(false);
              setacc23(false);
              setacc24(false);
              setacc25(false);
              setacc26(false);
              setacc27(false);
              setacc28(false);
              setacc29(false);
              setacc30(false);
              setacc31(false);
              setacc32(false);
              setacc33(false);
              setacc34(false);
              setacc35(false);
              setacc36(false);
              setacc37(false);
              setacc38(false);
              setacc39(false);
              setacc40(false);
              setacc42(false);
            }}
          >
            <span>
              <LuLayoutDashboard style={{ fontSize: "20px" }} />
            </span>
            <span className="ms-2">Generate Rescipt</span>
          </li>
        </Link>
        {/* <Link to="/id-card">
          {" "}
          <li className={`a-ele ${acc15 ? "active-0" : "null"}`} onClick={() => {
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(true);
            setacc6(false)
            setacc7(false);
            setacc8(false);
            setacc9(false);
            setacc10(false);
            setacc11(false);
            setacc12(false);
            setacc13(false);
            setacc14(false);
            setacc15(true);
            setacc16(false)

          }}>ID Card</li>
        </Link> */}
        {/* <Link to="/change-password">
          {" "}
          <li className={`a-ele ${acc16 ? "active-0" : "null"}`} onClick={() => {
            setacc(false);
            setacc1(false);
            setacc2(false);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setFAQ(false);
            setacc6(false);
            setacc7(false);
            setservice(false);
            setacc8(false);
            setratting(false);
            settimeSlot(false);
            setopening(false);
            setEnquires(false)
            setacc9(false);
            setacc10(false);
            setacc11(false);
            setBranch(false)
            setacc12(false);
            setLeave(false);
            setacc13(false);
            setbookS(false)
            setacc14(false);
            setacc15(false);
            setacc16(true);
            setgallery(false)
            setacc17(false);
          }}>Change Passwords</li>
        </Link> */}
      </ul>
    </div>
  );
}

export default SideBar;
