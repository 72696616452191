import React from "react";
import { Button } from "react-bootstrap";

function AdminProfile() {
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-3">
            {/* <button >Profile</button> */}
          </div>
          <div className="main-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img
                        src="./images/8906.jpg"
                        alt="Admin"
                        className="rounded-circle"
                        width="150px"
                        height="150px"
                      />
                      <div className="mt-3">
                        <h4></h4>
                        <p className="text-secondary mb-1">Rajesh</p>
                        <p className="text-muted font-size-sm">
                          Nagavara , Benguluru.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Full Name</label>
                          <input
                            type="text"
                            placeholder="Enter Full Name"
                            className="vi_0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Email</label>
                          <input
                            type="email"
                            placeholder="example@gmail.com"
                            className="vi_0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Mobile Number</label>
                          <input
                            type="number"
                            placeholder="+918978986676"
                            className="vi_0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Address</label>
                          <input
                            type="text"
                            placeholder="Enter Address"
                            className="vi_0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Pan Card Number</label>
                          <input
                            type="text"
                            placeholder="Enter Pan Card Number"
                            className="vi_0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Aadhar Number</label>
                          <input
                            type="email"
                            placeholder="Enter Aadhar Number"
                            className="vi_0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <Button>Save Changes</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminProfile;
