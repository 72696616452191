import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container">
        <div className="">
          <div>
            <h2
              style={{
                padding: " 25px",
                textAlign: "center",
              }}
            >
              PRIVACY POLICY
            </h2>
            <h6>1. Introduction</h6>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              Welcome to Jai Driving School (“us”, “we”, or “our”) operates
              https://jaidrivingschool.com/ (hereinafter referred to as
              “Service”). Our Privacy Policy governs your visit to
              https://jaidrivingschool.com/, and explains how we collect,
              safeguard and disclose information that results from your use of
              our Service. We use your data to provide and improve Service. By
              using Service, you agree to the collection and use of information
              in accordance with this policy. Unless otherwise defined in this
              Privacy Policy, the terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions. Our Terms and
              Conditions (“Terms”) govern all use of our Service and together
              with the Privacy Policy constitutes your agreement with us
              (“agreement”).
            </p>
            <h4>2. Definitions</h4>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              SERVICE means the https://jaidrivingschool.com/ website operated by
              parnets. PERSONAL DATA means data about a living individual who
              can be identified from those data (or from those and other
              information either in our possession or likely to come into our
              possession). USAGE DATA is data collected automatically either
              generated by the use of Service or from Service infrastructure
              itself (for example, the duration of a page visit). COOKIES are
              small files stored on your device (computer or mobile device).
              DATA CONTROLLER means a natural or legal person who (either alone
              or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal data are,
              or are to be, processed. For the purpose of this Privacy Policy,
              we are a Data Controller of your data. DATA PROCESSORS (OR SERVICE
              PROVIDERS) means any natural or legal person who processes the
              data on behalf of the Data Controller. We may use the services of
              various Service Providers in order to process your data more
              effectively. DATA SUBJECT is any living individual who is the
              subject of Personal Data. THE USER is the individual using our
              Service. The User corresponds to the Data Subject, who is the
              subject of Personal Data.
            </p>
            <h4>3. Information Collection and Use</h4>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>
            <h4>4. Types of Data Collected</h4>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              Personal Data While using our Service, we may ask you to provide
              us with certain personally identifiable information that can be
              used to contact or identify you (“Personal Data”). Personally
              identifiable information may include, but is not limited to: 0.1.
              Email address 0.2. First name and last name 0.3. Phone number 0.4.
              Address, Country, State, Province, ZIP/Postal code, City 0.5.
              Cookies and Usage Data We may use your Personal Data to contact
              you with newsletters, marketing or promotional materials and other
              information that may be of interest to you. You may opt out of
              receiving any, or all, of these communications from us by
              following the unsubscribe link. Usage Data We may also collect
              information that your browser sends whenever you visit our Service
              or when you access Service by or through any device (“Usage
              Data”). This Usage Data may include information such as your
              computer’s Internet Protocol address (e.g. IP address), browser
              type, browser version, the pages of our Service that you visit,
              the time and date of your visit, the time spent on those pages,
              unique device identifiers and other diagnostic data. When you
              access Service with a device, this Usage Data may include
              information such as the type of device you use, your device unique
              ID, the IP address of your device, your device operating system,
              the type of Internet browser you use, unique device identifiers
              and other diagnostic data. Location Data We may use and store
              information about your location if you give us permission to do so
              (“Location Data”). We use this data to provide features of our
              Service, to improve and customize our Service. You can enable or
              disable location services when you use our Service at any time by
              way of your device settings. Tracking Cookies Data We use cookies
              and similar tracking technologies to track the activity on our
              Service and we hold certain information. Cookies are files with a
              small amount of data which may include an anonymous unique
              identifier. Cookies are sent to your browser from a website and
              stored on your device. Other tracking technologies are also used
              such as beacons, tags and scripts to collect and track information
              and to improve and analyze our Service. You can instruct your
              browser to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be
              able to use some portions of our Service. Examples of Cookies we
              use: 0.1. Session Cookies: We use Session Cookies to operate our
              Service. 0.2. Preference Cookies: We use Preference Cookies to
              remember your preferences and various settings. 0.3. Security
              Cookies: We use Security Cookies for security purposes. 0.4.
              Advertising Cookies: Advertising Cookies are used to serve you
              with advertisements that may be relevant to you and your
              interests. Other Data While using our Service, we may also collect
              the following information: sex, age, date of birth, place of
              birth, passport details, citizenship, registration at place of
              residence and actual address, telephone number (work, mobile),
              details of documents on education, qualification, professional
              training, employment agreements, NDA agreements, information on
              bonuses and compensation, information on marital status, family
              members, social security (or other taxpayer identification)
              number, office location and other data.
            </p>
            <h4>5. Use of Data</h4>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              Jai Driving School uses the collected data for various purposes: 0.1. to
              provide and maintain our Service; 0.2. to notify you about changes
              to our Service; 0.3. to allow you to participate in interactive
              features of our Service when you choose to do so; 0.4. to provide
              customer support; 0.5. to gather analysis or valuable information
              so that we can improve our Service; 0.6. to monitor the usage of
              our Service; 0.7. to detect, prevent and address technical issues;
              0.8. to fulfil any other purpose for which you provide it; 0.9. to
              carry out our obligations and enforce our rights arising from any
              contracts entered into between you and us, including for billing
              and collection; 0.10. to provide you with notices about your
              account and/or subscription, including expiration and renewal
              notices, email-instructions, etc.; 0.11. to provide you with news,
              special offers and general information about other goods, services
              and events which we offer that are similar to those that you have
              already purchased or enquired about unless you have opted not to
              receive such information; 0.12. in any other way we may describe
              when you provide the information; 0.13. for any other purpose with
              your consent.
            </p>
            <h4>6.Security of Data</h4>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
            <h4>7. Payments</h4>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors). We will not store or collect your
              payment card details. That information is provided directly to our
              third-party payment processors whose use of your personal
              information is governed by their Privacy Policy. These payment
              processors adhere to the standards set by PCI-DSS as managed by
              the PCI Security Standards Council, which is a joint effort of
              brands like Visa, Mastercard, American Express and Discover.
              PCI-DSS requirements help ensure the secure handling of payment
              information.
            </p>
            <h4>8.Changes to This Privacy Policy</h4>
            <p
              style={{
                fontSize: "14px",
                textAlign: "justify",
                fontWeight: " 400",
                fontFamily: " 'Montserrat', sans-serif",
              }}
            >
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
