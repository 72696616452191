import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FiRefreshCcw } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Autocomplete, TextField } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";

const VendorOneDayCourse = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show1, setShow1] = useState(false);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);
    const [show2, setShow2] = useState(false);
    const handleShow2 = () => setShow2(true);
    const handleClose2 = () => setShow2(false);
  
    const admin = JSON.parse(sessionStorage.getItem("branch"));
    const token = sessionStorage.getItem("token");
    const [AllOneDay, setAllOneDay] = useState([]);
    const [nochangedata, setnochangedata] = useState([]);
  
    const getAllOnedayCourse = async () => {
        let ab =admin?.branchId ? admin?.branchId: admin?._id
      try {
        let res = await axios(
          "https://jaidrivingschool.in/api/admin/getAllOneDayCourseByBranchId/"+ab
        );
        if (res.status == 200) {
          setAllOneDay(res.data.success);
          setnochangedata(res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const [allBranch, setAllBranc] = useState([]);
  
    const getAllBranch = async () => {
      try {
        let res = await axios.get(
          "https://jaidrivingschool.in/api/admin/getAllBranch"
        );
        if (res.status == 200) {
          setAllBranc(res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const [AllCustomer, setAllCustomer] = useState([]);
    // const [nochangedata, setnochangedata] = useState([])
    const getCustomer = async () => {
      try {
        const config = {
          url: "/Customer/getAllCustomer",
          method: "get",
          baseURL: "https://jaidrivingschool.in/api",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
          },
        };
        let res = await axios(config);
        if (res.status == 200) {
          setAllCustomer(res.data.success);
          // setnochangedata(res.data.success)
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const [allCourse, setallCourse] = useState([]);
    // const [nochangedata, setnochangedata] = useState([]);
    const getAllCourse = async () => {
      try {
        let res = await axios.get(
          "https://jaidrivingschool.in/api/course/getAllcourse"
        );
        if (res.status == 200) {
          setallCourse(res.data.success);
          // setnochangedata(res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getAllOnedayCourse();
      getAllBranch();
      getCustomer();
      getAllCourse();
    }, []);
  
    const top100Films = AllCustomer.map((item) => ({
      label: `${item?.customerId}-${item?.name}`,
      year: JSON.stringify(item),
    }));
  
    const [selectedValue, setSelectedValue] = useState(null);
    const handleAutocompleteChange = (event, newValue) => {
      setSelectedValue(newValue);
  
      if (newValue) {
        setuserId(JSON.parse(newValue.year)?._id);
        setId(JSON.parse(newValue.year)?.customerId);
        setuserName(JSON.parse(newValue.year)?.name);
      } else {
        setuserId("");
        setId("");
        setuserName("");
      }
    };
  
    const top100Films2 = allCourse.map((item) => ({
      label: `${item?.courseName}-${item?.courseDuration} days`,
      year: JSON.stringify(item),
    }));
  
    const [selectedValue2, setSelectedValue2] = useState(null);
    const handleAutocompleteChange2 = (event, newValue) => {
      setSelectedValue2(newValue);
  
      if (newValue) {
        setCourseName(JSON.parse(newValue.year)?.courseName);
        // setId(JSON.parse(newValue.year)?.customerId);
        // setuserName(JSON.parse(newValue.year)?.name);
      } else {
        setCourseName("");
        // setId("");
        // setuserName("");
      }
    };
    const [CourseName, setCourseName] = useState("");
    const [overview, setoverview] = useState("");
    const [date, setdate] = useState("");
    const [branchId, setbranchId] = useState(admin?.branchId ? admin?.branchId: admin?._id);
    const [branchName, setbranchName] = useState(`${admin?.branchName},${admin?.area}`);
    const [time, settime] = useState("");
    const [userId, setuserId] = useState("");
    const [userName, setuserName] = useState("");
    const [reshuldDate, setreshuldDate] = useState("");
    const [reshudleTime, setreshudleTime] = useState("");
    const [Id, setId] = useState("");
    const AddOneDayCourse = async () => {
      try {
        const config = {
          url: "/admin/addOneDayCourse",
          method: "post",
          baseURL: "https://jaidrivingschool.in/api",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          data: {
            CourseName,
            overview,
            date,
            branchId,
            branchName,
            time,
            userId,
            userName,
            authId: admin?._id,
            Id,
          },
        };
        let res = await axios(config);
        if (res.status == 200) {
          alert("Successfully Added");
          handleClose();
          getAllOnedayCourse();
        }
      } catch (error) {
        console.log(error);
      }
    };
    const handleChange = (e, editor) => {
      const data = editor.getData();
      setoverview(data);
    };
    const [edit, setedit] = useState({});
    const [ShowEdit, setShowEdit] = useState(false);
    const updateOneDayCourse = async () => {
      try {
        const config = {
          url: "/admin/updateOneDayCourse",
          method: "put",
          baseURL: "https://jaidrivingschool.in/api",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          data: {
            CourseName,
            overview,
            date,
            branchId,
            branchName,
            time,
            userId,
            userName,
            authId: admin?._id,
            Id,
            id: edit?._id,
          },
        };
        let res = await axios(config);
        if (res.status == 200) {
          alert("Successfully updated");
          setShowEdit(false);
          getAllOnedayCourse();
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const [deleteid, setdeleteid] = useState("");
    const DeleteOncOurse = async () => {
      try {
        const config = {
          url: "/admin/deleteOneDayCourse/" + deleteid + "/" + admin?._id,
          method: "delete",
          baseURL: "https://jaidrivingschool.in/api",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        };
        let res = await axios(config);
        if (res.status == 200) {
          alert("Successfully deleted");
          handleClose2();
          getAllOnedayCourse();
        }
      } catch (error) {
        console.log(error);
      }
    };
    const RescheduldeOneCourese = async () => {
      try {
        const config = {
          url: "/admin/makeReshudleOneDayCourse",
          method: "put",
          baseURL: "https://jaidrivingschool.in/api",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          data: {
            id: edit?._id,
            authId: admin?._id,
            reshuldDate,
            reshudleTime,
          },
        };
        let res = await axios(config);
        if (res.status == 200) {
          alert("Successfully Rescheduled");
          handleClose1();
  
          getAllOnedayCourse();
          setreshudleTime("");
          setreshuldDate("");
        }
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    };
  
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(AllOneDay.length / usersPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };
  
    const [search, setSearch] = useState("");
    const handleFilter = (e) => {
      if (e.target.value != "") {
        setSearch(e.target.value);
        const filterTable = nochangedata.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
        setAllOneDay([...filterTable]);
      } else {
        setSearch(e.target.value);
        setAllOneDay([...nochangedata]);
      }
    };
    return (
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ad-b mt-4">
                  <button
                    style={{ borderRadius: "10px", float: "right" }}
                    onClick={handleShow}
                  >
                    +Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
               <div className="col-3">
                      <input
                        type="text"
                        placeholder="Search...."
                        className="vi_0"
                        style={{ margin: "10px" }}
                        onChange={(e) => handleFilter(e)}
                      />
                    </div>
  
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Student ID</th>
                    <th>Student Name</th>
                    <th> Date/Time</th>
                    {/* <th>Time</th> */}
                    <th>Branch Name</th>
                    <th>Course Name</th>
                    <th>Overview</th>
                    <th>Reschedule Date/Time</th>
                    <th>Reschedule</th>
                    <th>Student Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {AllOneDay?.slice(
                          pagesVisited,
                          pagesVisited + usersPerPage
                        )?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.Id}</td>
  
                        <td>{item?.userName}</td>
                        <td>
                          {item?.date},{item?.time}
                        </td>
                        <td>{item?.branchName}</td>
                        <td>{item?.CourseName}</td>
                        <td>{item?.overview ? parse(item?.overview) : ""}</td>
                        <td>
                          {item?.reshuldDate} {item?.reshudleTime}
                        </td>
                        <td>
                          <button
                            style={{
                              padding: "5px",
                              backgroundColor: "#895010",
                              width: "50px",
                              borderRadius: "10px",
                              border: "none",
                            }}
                            onClick={() => {
                              setedit(item);
                              setreshuldDate(item?.reshuldDate);
                              setreshudleTime(item?.reshudleTime);
                              handleShow1();
                            }}
                          >
                            <FiRefreshCcw
                              style={{
                                color: "white",
                                fontSize: "18px",
                                paddingBottom: "2px",
                              }}
                            />
                          </button>
                        </td>
                        <td>
                          {item?.studentStatus == "Pending" ? (
                            <span style={{ color: "blue" }}>
                              {item?.studentStatus}
                            </span>
                          ) : (
                            <span style={{ color: "green" }}>
                              {item?.studentStatus}
                            </span>
                          )}
                        </td>
                        <td style={{ display: "flex", gap: "3px" }}>
                          <Button
                            type="button"
                            variant="info"
                            onClick={() => {
                              setedit(item);
                              setId(item?.Id);
                              setdate(item?.date);
                              settime(item?.time);
                              setoverview(item?.overview);
                              setShowEdit(true);
                            }}
                          >
                            <span>
                              <BiEdit style={{ fontSize: "25" }} />
                            </span>
                          </Button>
                          <Button
                            type="button"
                            variant="danger"
                            onClick={() => {
                              setdeleteid(item?._id);
                              handleShow2();
                            }}
                          >
                            <span>
                              <RiDeleteBin6Line style={{ fontSize: "25" }} />
                            </span>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add One Day Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row me-0">
              <label htmlFor="">Branch Name</label>
              <select
                name=""
                id=""
                className="vi_0"
                onChange={(e) => {
                  if (e.target.value) {
                    let ab = JSON.parse(e.target.value);
                    setbranchId(ab?._id);
                    setbranchName(`${ab?.branchName},${ab?.area}`);
                  } else {
                    setbranchId("");
                    setbranchName("");
                  }
                }}
              >
                <option value={""}>{branchName}</option>
                {allBranch?.map((item) => {
                  return (
                    <option value={JSON.stringify(item)}>
                      {item?.branchName},{item?.area}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* <div className="row me-0 mt-2"> */}
            <div class="row">
              <div class="col-md-12">
                <label htmlFor="">Customer/Student</label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  sx={{ width: 440 }}
                  value={selectedValue} // This sets the selected value
                  onChange={handleAutocompleteChange} // Handle change event
                  renderInput={(params) => (
                    <TextField {...params} label="Customer/Student" />
                  )}
                />
              </div>
            </div>
            {/* </div> */}
            <div className="row me-0">
              <label htmlFor="">ID</label>
              <input
                type="text"
                value={Id}
                placeholder="fetch id"
                className="vi_0"
              />
            </div>
            <div className="so-sear">
              <div class="row me-0">
                <div class="col-md-12">
                  <label htmlFor="">Course</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films2}
                    sx={{ width: 440 }}
                    value={selectedValue2} // This sets the selected value
                    onChange={handleAutocompleteChange2} // Handle change event
                    renderInput={(params) => (
                      <TextField {...params} label="Courses" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row me-0">
              <label htmlFor=""> Course Date / Time</label>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setdate(e.target.value)}
                    placeholder="fetch id"
                    className="vi_0"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Eg:- 10:00 Am"
                    className="vi_0"
                    value={time}
                    onChange={(e) => settime(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row me-0">
              <label htmlFor="">Overview</label>
  
              <CKEditor
                editor={ClassicEditor}
                data={overview}
                onChange={handleChange}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AddOneDayCourse}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Re-Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row me-0">
              <label htmlFor="">Reschedule Course Date/Time</label>
              <div className="col-md-6">
                <input
                  type="date"
                  name=""
                  value={reshuldDate}
                  onChange={(e) => setreshuldDate(e.target.value)}
                  id=""
                  className="vi_0"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  name=""
                  placeholder="Eg:- 10:00 am"
                  value={reshudleTime}
                  onChange={(e) => setreshudleTime(e.target.value)}
                  id=""
                  className="vi_0"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={RescheduldeOneCourese}>
              Reschedule
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete??</Modal.Title>
          </Modal.Header>
  
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose2}>
              Cancel
            </Button>
            <Button variant="success" onClick={DeleteOncOurse}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Update One Day Course */}
        <Modal
          show={ShowEdit}
          onHide={() => setShowEdit(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update One Day Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row me-0">
              <label htmlFor="">Branch Name</label>
              <select
                name=""
                id=""
                className="vi_0"
                onChange={(e) => {
                  if (e.target.value) {
                    let ab = JSON.parse(e.target.value);
                    setbranchId(ab?._id);
                    setbranchName(`${ab?.branchName},${ab?.area}`);
                  } else {
                    setbranchId("");
                    setbranchName("");
                  }
                }}
              >
                <option value={""}>{edit?.branchName}</option>
                {allBranch?.map((item) => {
                  return (
                    <option value={JSON.stringify(item)}>
                      {item?.branchName},{item?.area}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* <div className="row me-0 mt-2"> */}
            <div class="row">
              <div class="col-md-12">
                <label htmlFor="">Customer/Student</label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  sx={{ width: 440 }}
                  value={selectedValue} // This sets the selected value
                  onChange={handleAutocompleteChange} // Handle change event
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${edit?.Id}-${edit?.userName}`}
                    />
                  )}
                />
              </div>
            </div>
            {/* </div> */}
            <div className="row me-0">
              <label htmlFor="">ID</label>
              <input
                type="text"
                value={Id}
                placeholder="fetch id"
                className="vi_0"
              />
            </div>
            <div className="so-sear">
              <div class="row me-0">
                <div class="col-md-12">
                  <label htmlFor="">Course</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films2}
                    sx={{ width: 440 }}
                    value={selectedValue2} // This sets the selected value
                    onChange={handleAutocompleteChange2} // Handle change event
                    renderInput={(params) => (
                      <TextField {...params} label={`${edit?.CourseName}`} />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row me-0">
              <label htmlFor=""> Course Date / Time</label>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setdate(e.target.value)}
                    placeholder="fetch id"
                    className="vi_0"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Eg:- 10:00 Am"
                    className="vi_0"
                    value={time}
                    onChange={(e) => settime(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row me-0">
              <label htmlFor="">Overview</label>
  
              <CKEditor
                editor={ClassicEditor}
                data={overview}
                onChange={handleChange}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEdit(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={updateOneDayCourse}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

export default VendorOneDayCourse;
