import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React from 'react'
import { Container } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

export const AdminCertificate = () => {
    const {state}=useLocation();
    const history=useNavigate();

    const createPDF = async () => {
        // Create a new jsPDF instance with landscape orientation and A4 size
        const pdf = new jsPDF("landscape", "pt", "a4");
    
        // Use html2canvas to capture the content of the "pdf" element
        const data = await html2canvas(document.getElementById("pdf"), {
            useCORS: true,
        });
    
        // Convert the captured content to a PNG image
        const img = data.toDataURL("image/png");
    
        // Get image properties
        const imgProperties = pdf.getImageProperties(img);
    
        // Calculate the width and height of the content based on the PDF dimensions
        const pdfWidth = pdf.internal.pageSize.getWidth() - 100;
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width - 100;
    
        // Calculate the center position for the image
        const xPosition = (pdf.internal.pageSize.getWidth() - pdfWidth) / 2;
        const yPosition = (pdf.internal.pageSize.getHeight() - pdfHeight) / 2;
    
        // Add the image to the PDF at the center position
        pdf.addImage(img, "PNG", xPosition, yPosition, pdfWidth, pdfHeight);
    
        // Save the PDF with a custom filename
        pdf.save(`${state?.customerName}_Certificate.pdf`);
    };
    
    return (
        <div>
            <Container className='my-3 text-center'    id="pdf"
                style={{
                    width: "800px",
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    border: "10px solid #9d041b",
                    padding: "15px"
                }}>
                <h5 className='text-uppercase'>Jai <span style={{ color: '#9d041b' }}> Driving</span> School</h5>
                <h1 className='text-uppercase' style={{ color: '#9d041b' }}>Certificate</h1>
                <p className='text-uppercase d-flex gap-2 align-items-center justify-content-center fs-3 fw-semibold'><span>-------</span> Of Compeltion <span>-------</span></p>
                <p className='text-uppercase fs-5'>This Certificate Proudaly </p>
                <p className='text-uppercase fs-5 mb-4'> Presented to </p>
                <div style={{ borderBottom: '1px dashed #9d041b', width: '500px', margin: 'auto' }}>
                    <h1 style={{ color: '#9d041b', marginBottom: '0px' }}>{state?.customerName}</h1>
                </div>
                <p className='fw-semibold mt-4 fs-6'>Has successfully completed the driving course.</p>
                <p className='mb-4 text-secondary'>Exceptional perfomance and outstandng achievements in driving school. Your hard work, dedication, and perseverance have been instrumental in your success, and we are honored to recognize your accomplishments</p>

                <div className='d-flex justify-content-between align-items-center'>
                    <div className='text-center'>
                        <p className='mb-0'>{state?.certificateDate}</p>
                        <p style={{ height: '15px' }}>------------------------</p>
                        <span className='fw-semibold'>DATE</span>
                    </div>
                    {state?.customerId?.passportcopy ? (    <img   src={`https://jaidrivingschool.in/Customer/${state?.customerId?.passportcopy}`} alt="" style={{ width: '140px', height: '120px' }} />):(<></>)}
                
                    <div className='text-center'>
                        <p className='mb-0'>{state?.customerName}</p>
                        <p style={{ height: '15px' }}>------------------------</p>
                        <span className='fw-semibold'>SIGNATURE</span>
                    </div>
                </div>
            </Container>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}}>
            <button type="button" class="btn btn-danger" onClick={()=>history(-1)}>Back</button>
<button type="button" class="btn btn-warning" style={{color:"white"}} onClick={createPDF}>Download</button>
            </div>
        </div>
    )
}
