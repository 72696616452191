import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import moment from "moment";
function VendorExpanse() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const admin=JSON.parse(sessionStorage.getItem("branch"));
  const token=sessionStorage.getItem("token");
  const [AllGraph,setAllgraph]=useState([]);
  const getAllgraph=async()=>{
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      const res=await axios.get("https://jaidrivingschool.in/api/admin/getExpanseByBranchId/"+ab);
      if(res.status==200){
        setAllgraph(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getAllgraph()
  },[])
  const [ExpanseList,setExpansList]=useState(false)
const [expanse,setexpanse]=useState("");
const [remark,setremark]=useState("");
const [amount,setamount]=useState("");
const [date,setdate]=useState("");
//expanse,remarks,amount,addedby,expanDate
const AddFAQ=async()=>{
  try {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
    if(!expanse) return alert("Please select expanse type")
    if(!remark) return alert("Please enter expans")
    if(!amount) return alert("Please enter amount")
    if(!date) return alert("Please select date")
    const config={
      url: "/admin/AddExpense",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"},
       data: {authId:admin?._id,expanse:expanse,remarks:remark,amount:amount,addedby:admin?.branchName,expanDate:date,branchId:ab}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully added");
      handleClose();
      setExpansList(false)
      getAllgraph();
      setexpanse("");
      setremark("");
      setdate("")
      setamount("")
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}

const [viewImge,setViewImage]=useState("")
const [edit,setedit]=useState({})
const editGraph=async()=>{
  try {
    const config={
      url: "/admin/updateExpense",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"},
       data: {authId:admin?._id,expanse:expanse,remarks:remark,amount:amount,expanDate:date,id:edit?._id}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Updated");
      handleClose1();
      getAllgraph();
      setexpanse("");
      setremark("");
      setdate("");
      setamount("")
    }
  } catch (error) {
    console.log(error);
  }
}
const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 10;
 const pagesVisited = pageNumber * usersPerPage;
 const pageCount = Math.ceil(AllGraph.length / usersPerPage);
 const changePage = ({ selected }) => {
   setPageNumber(selected);
 };

const [show4,setshow4]=useState(false);
const handleShow4=()=>setshow4(true);
const handleClose4=()=>setshow4(false);
const [deleteId,setdeleteId]=useState("");
const deleteGraph=async()=>{
  try {
    const config={
      url: "/admin/deleteExpense/"+deleteId+"/"+admin?._id,
      method: "delete",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
      //  data: {authId:admin?._id,titele:title,link:link,image:image,id:edit?._id}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
      handleClose4();
      getAllgraph();
    
    }
  } catch (error) {
    console.log(error);
  }
}

const [AllExpanseT,setAllExpanseT]=useState([])
const getAllExpanseType=async()=>{
  try {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
    let res=await axios.get("https://jaidrivingschool.in/api/admin/getExpanseTypeByBranchId/"+ab);
    if(res.status==200){
      setAllExpanseT(res.data.success)
    }
  } catch (error) {
    console.log(error);
  }
}
const [pageNumber1, setPageNumber1] = useState(0);
const usersPerPage1 = 10;
 const pagesVisited1 = pageNumber1 * usersPerPage1;
 const pageCount1 = Math.ceil(AllExpanseT.length / usersPerPage1);
 const changePage1 = ({ selected }) => {
   setPageNumber1(selected);
 };
useEffect(()=>{
getAllExpanseType();
},[])
const [expanseT,setexpanseT]=useState("");
const AddExpanseType=async()=>{
  if(!expanseT) return alert("Please enter expanse type")
  try {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
       const config={
      url: "/admin/AddExpenseType",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"},
       data: {authId:admin?._id,expanseT:expanseT,addedby:admin?.branchName,branchId:ab}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Added");
      handleClose3();
      setExpansList(true);
      getAllExpanseType();
      setexpanseT("");
    }
  } catch (error) {
    console.log(error);
  }
}
const [td,settd]=useState(false)
const DeleteExpanseType=async()=>{
  try {
    const config={
      url: "/admin/deleteExpenseType/"+deleteId+"/"+admin?._id,
      method: "delete",
      baseURL: "https://jaidrivingschool.in/api",
      headers: { Authorization: `Bearer ${token}` ,"content-type": "application/json"}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted")

      handleClose4()
      getAllExpanseType()
    }
  } catch (error) {
    console.log(error);
  }
}

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
          <div className="col-md-6">
              <div className="ad-b mt-4">
                <button>Expense Details</button>
               
              </div>
            </div>
            <div className="col-md-6">
             
              <div className="ad-b mt-2">
                <button style={{borderRadius:"10px",float:"right"}} onClick={handleShow}>+Expanse</button>
               
              </div>
              <div className="ad-b mt-2">
                <button style={{borderRadius:"10px",float:"right"}} onClick={handleShow3}>+Expanse Type</button>
               
              </div>
              <div className="ad-b mt-2">
                {ExpanseList ? (    <button style={{borderRadius:"10px",float:"right"}} onClick={()=>{
                  setExpansList(false)
                }}>Expense  List</button>):(    <button style={{borderRadius:"10px",float:"right"}} onClick={()=>{
                  setExpansList(true)
                }}>Expense Type List</button>)}
            
               
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {ExpanseList ? ( <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                
                  <th>Expense Type </th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {AllExpanseT?.slice(pagesVisited1, pagesVisited1 + usersPerPage1)?.map((item,i)=>{
                  return (<tr>
                    <td>{i+1}</td>
                    <td>{item?.expanseT}</td>
                    <td>
                    <Button type="button" variant="danger" onClick={()=>{
                      settd(true)
                                setdeleteId(item?._id);
                                handleShow4();
                              }}>
                                <span>
                                  <RiDeleteBin6Line style={{fontSize:"25"}} />
                                </span>
                              </Button>
                    </td>
                   
                  </tr>)
                })}
                
              </tbody>
            </Table>
            <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount1}
          onPageChange={changePage1}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
          </div>):( <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Expense Type </th>
                  <th>Remarks</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {AllGraph?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return (<tr>
                    <td>{i+1}</td>
                  
                    <td>{item?.expanse}</td>
                    <td>{item?.remarks}</td>
                    <td>{moment(item?.expanDate).format("DD/MM/YYYY")}</td>
                    <td>{Number(item?.amount)?.toFixed(2)}</td>
                    <td>
                      <img src="./images/editing.png" alt="" width={30} onClick={()=>{
                        setedit(item)
                        handleShow1()
                      }} />
                    </td>
                    <td>
                    <Button type="button" variant="danger" onClick={()=>{
                      settd(false)
                                setdeleteId(item?._id);
                                handleShow4();
                              }}>
                                <span>
                                  <RiDeleteBin6Line style={{fontSize:"25"}} />
                                </span>
                              </Button>
                    </td>
                   
                  </tr>)
                })}
                
              </tbody>
            </Table>
            <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
          </div>)}
         
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          <div className="do-sear mt-2">
            <label>Expense Type</label>
            <select className="vi_0"  onChange={(e)=>setexpanse(e.target.value)}>
              <option>Menu</option>
              {AllExpanseT?.map((item)=>{
                return (
                  <option value={item?.expanseT}>{item?.expanseT}</option>
                )
              })}
            </select>
            {/* <input type="text" onChange={(e)=>setremark(e.target.value)} className="vi_0" placeholder="Please enter question" /> */}
          </div>
          <div className="do-sear mt-2">
            <label>Remarks</label>
            <input  type="text" onChange={(e)=>setremark(e.target.value)} className="vi_0" placeholder="Enter Your remarks" />
          </div>
          <div className="do-sear mt-2">
            <label>Date</label>
            <input  type="date" onChange={(e)=>setdate(e.target.value)} className="vi_0" />
          </div>
          <div className="do-sear mt-2">
            <label>Amount</label>
            <input  type="number" min={1} onChange={(e)=>setamount(e.target.value)} className="vi_0" placeholder="Enter amount" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddFAQ}>Submit</Button>
        </Modal.Footer>
      </Modal>
     
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Expense Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
          <div className="do-sear mt-2">
            <label>Expense Type</label>
            <input type="text" onChange={(e)=>setexpanseT(e.target.value)} className="vi_0" placeholder="Please enter expanse type" />
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="primary" onClick={AddExpanseType}>Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* edit Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Updated Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
         <div className="do-sear mt-2">
           <label>Expense Type</label>
           <select className="vi_0"  onChange={(e)=>setexpanse(e.target.value)}>
            <option> {edit?.expanse ?(edit?.expanse):("Menu") }</option>
             {AllExpanseT?.map((item)=>{
               return (
                 <option value={item?.expanseT}>{item?.expanseT}</option>
               )
             })}
           </select>
           {/* <input type="text" onChange={(e)=>setremark(e.target.value)} className="vi_0" placeholder="Please enter question" /> */}
         </div>
         <div className="do-sear mt-2">
           <label>Remarks</label>
           <input  type="text" onChange={(e)=>setremark(e.target.value)} className="vi_0" placeholder={edit?.remarks} />
         </div>
         <div className="do-sear mt-2">
           <label>Date</label>
           <input  type="date" onChange={(e)=>setdate(e.target.value)} className="vi_0" />
         </div>
         <div className="do-sear mt-2">
           <label>Amount</label>
           <input  type="number" min={1} onChange={(e)=>setamount(e.target.value)} className="vi_0" placeholder={edit?.amount} />
         </div>
       </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editGraph}>Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* deleteMode */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>
      
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          {td ? (  <Button variant="success" onClick={DeleteExpanseType}>Ok</Button>):(  <Button variant="success" onClick={deleteGraph}>Ok</Button>)}
        
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default VendorExpanse;
