import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import axios from "axios";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
const AdminDiscount = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleClose3 = () => setShow3(false);
  const handleShow1 = () => setShow1(true);
  const handleShow3 = () => setShow3(true);
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  const [title, settitle] = useState("");
  const [Amount, setAmount] = useState(0);
  const [days, setdays] = useState(1);
  const [AllDiscount, setAllDiscount] = useState([]);
  const getAllDiscount = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllDiscount"
      );
      if (res.status == 200) {
        setAllDiscount(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // let addedTime=new Date(theDate.getTime() + days*24*60*60*1000);
  const [deleteId, setdeleteId] = useState("");
  const [show2, setshow2] = useState(false);
  const handelshow2 = (data) => {
    settitle(data?.tittle);
    setdays(data?.days);
    setAmount(data?.amount);
    setshow2(true)
  }
  const handleClose2 = () => setshow2(false);
  const addDiscount = async () => {
    try {
      let addedTime = new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
      if (!title) return alert("Please enter title");
      if (!Amount) return alert("Please enter amount");
      const config = {
        url: "/admin/addAndUpdateDiscount",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          authId: admin?._id,
          amount: Amount,
          tittle: title,
          dateAndtime: addedTime,
          days: days,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        handleClose();
        handleClose2();
        getAllDiscount()
        settitle("");
        setAmount(0);
        setdays(1)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDiscount = async () => {
    try {
      const config = {
        url: "/admin/deleteDiscount/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` }
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        getAllDiscount()
        handleClose3();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const MackeActiveDeactive = async (id, day, isActive) => {
    try {
      let addedTime = new Date(new Date().getTime() + day * 24 * 60 * 60 * 1000);
      const config = {
        url: "/admin/ActiveAndDectiveDiscount",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          authId: admin?._id,
          dateAndtime: addedTime,
          id: id,
          isActive: isActive
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        handleClose();
        handleClose2();
        getAllDiscount()
        settitle("");
        setAmount(0);
        setdays(1)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllDiscount();
  }, []);


  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            <button onClick={handleShow}>Add Discount</button>
          </div>
        </div>

        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Title</th>
                  <th>Amount</th>
                  {/* <th>Starting Date</th> */}
                  <th>Days</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllDiscount?.map((ele, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{ele?.tittle}</td>

                      <td>&#8377; {ele?.amount}</td>
                      {/* <td>01/01/2000</td> */}
                      <td>{ele?.days}</td>
                      <td>{ele?.isActive == true ? (<span style={{ color: "green" }}>Activated</span>) : (<span style={{ color: "red" }}>Deactivated</span>)}</td>
                      <td>
                        <img src="./images/editing.png" alt="" width={30} onClick={() => {
                          handelshow2(ele)
                        }} />{" "}
                        <Button type="button" variant="danger" onClick={() => {
                          setdeleteId(ele?._id);
                          handleShow3();
                        }}>
                          <span>
                            <RiDeleteBin6Line style={{ fontSize: "25" }} />
                          </span>
                        </Button>
                        {" "}
                        {ele?.isActive == true ? (<Button type="button" variant="danger" onClick={() => {

                          MackeActiveDeactive(ele?._id, ele?.days, false)
                        }}>
                          Deactivate
                        </Button>) : (<Button type="button" variant="success" onClick={() => {
                          MackeActiveDeactive(ele?._id, ele?.days, true)
                        }}>
                          Activate
                        </Button>)}

                      </td>

                      {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
                <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                    </tr>
                  )
                })}

              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Discount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Title</label>
              <input type="text" placeholder="Enter title..." className="vi_0" onChange={(e) => settitle(e.target.value)} />
            </div>
            <div className="do-sear mt-2">
              <label>Amount</label>
              <input
                type="number"
                min="0"
                step="1"
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter Amount"
                className="vi_0"
              />
            </div>
            <div className="do-sear mt-2">
              <input
                type="number"
                min="1"
                step="1"
                className="vi_0"
                onChange={(e) => setdays(e.target.value)}
                style={{ width: "100px" }}
                placeholder="1"
              />{" "}
              <label>Days</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={addDiscount}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete  Model */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ??</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose3}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteDiscount}>Ok</Button>
          </Modal.Footer>
        </Modal>
        {/* Edit  Model */}
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Discount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="do-sear mt-2">
              <label>Title</label>
              <input type="text" placeholder="Enter title..." value={title} className="vi_0" onChange={(e) => settitle(e.target.value)} />
            </div>
            <div className="do-sear mt-2">
              <label>Amount</label>
              <input
                type="number"
                min="0"
                value={Amount}
                step="1"
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter Amount"
                className="vi_0"
              />
            </div>
            <div className="do-sear mt-2">
              <input
                type="number"
                min="1"
                step="1"
                value={days}
                className="vi_0"
                onChange={(e) => setdays(e.target.value)}
                style={{ width: "100px" }}
                placeholder="1"
              />{" "}
              <label>Days</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={addDiscount}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AdminDiscount;
