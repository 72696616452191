import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
const AdminGallery = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const admin=JSON.parse(sessionStorage.getItem("admin"));
  const token=sessionStorage.getItem("token");
  const [AllGraph,setAllgraph]=useState([]);
  const getAllgraph=async()=>{
    try {
      const res=await axios.get("https://jaidrivingschool.in/api/admin/getGallery");
      if(res.status==200){
        setAllgraph(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getAllgraph()
  },[])
const [image,setimage]=useState("");
const [title,settitle]=useState("");
const [link,setlink]=useState("");

const AddGraph=async()=>{
  try {
    if(!image) return alert("Please select image");
    if(!title) return alert("Please enter title")
    const config={
      url: "/admin/addGallery",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
       data: {authId:admin?._id,imageType:title,link:link,image:image}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully added");
      handleClose();
      getAllgraph();
      setimage("");
      settitle("");
      setlink("")
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}

const [viewImge,setViewImage]=useState("")
const [edit,setedit]=useState({})
const editGraph=async()=>{
  try {
    const config={
      url: "/admin/updateGallery",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
       data: {authId:admin?._id,imageType:title,link:link,image:image,id:edit?._id}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully Updated");
      handleClose1();
      getAllgraph();
      setimage("");
      settitle("");
      setlink("")
    }
  } catch (error) {
    console.log(error);
  }
}
const [pageNumber, setPageNumber] = useState(0);
const usersPerPage = 5;
 const pagesVisited = pageNumber * usersPerPage;
 const pageCount = Math.ceil(AllGraph.length / usersPerPage);
 const changePage = ({ selected }) => {
   setPageNumber(selected);
 };

const [show4,setshow4]=useState(false);
const handleShow4=()=>setshow4(true);
const handleClose4=()=>setshow4(false);
const [deleteId,setdeleteId]=useState("");
const deleteGraph=async()=>{
  try {
    const config={
      url: "/admin/deleteGallery/"+deleteId+"/"+admin?._id,
      method: "delete",
      baseURL: "https://jaidrivingschool.in/api",
    
      headers: { Authorization: `Bearer ${token}` ,"content-type": "multipart/form-data"},
      //  data: {authId:admin?._id,titele:title,link:link,image:image,id:edit?._id}
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted");
      handleClose4();
      getAllgraph();
    
    }
  } catch (error) {
    console.log(error);
  }
}
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="ad-b mt-4">
                <button onClick={handleShow}>Add Gallery</button>
               
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  <th>Title</th>
                  
                  {/* <th>Url</th> */}
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {AllGraph?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return (<tr>
                    <td>{i+1}</td>
                    <td>
                      <img src={`https://jaidrivingschool.in/gallery/${item?.image}`} alt="" width={100} onClick={()=>{
                        setViewImage(`https://jaidrivingschool.in/gallery/${item?.image}`)
                        handleShow3()}}  style={{ cursor: "pointer" }}/>
                      
                    </td>
                    <td>{item?.imageType}</td>
                 
                   
                    <td>
                      <img src="./images/editing.png" alt="" width={30} onClick={()=>{
                        setedit(item)
                        handleShow1()
                      }} />
                    </td>
                    <td>
                    <Button type="button" variant="danger" onClick={()=>{
                                setdeleteId(item?._id);
                                handleShow4();
                              }}>
                                <span>
                                  <RiDeleteBin6Line style={{fontSize:"25"}} />
                                </span>
                              </Button>
                    </td>
                    {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
            <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                  </tr>)
                })}
                
              </tbody>
            </Table>
            <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Graph</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="upload1">Image</label>
            <input type="File" placeholder="Enter Userid" className="vi_0"  id="upload1" accept="image/*" onChange={(e)=>setimage(e.target.files[0])}/>
          </div>
          <div className="do-sear mt-2">
            <label>Title</label>
            <input type="text" onChange={(e)=>settitle(e.target.value)} className="vi_0" placeholder="Enter Your Text Here" />
          </div>
          {/* <div className="do-sear mt-2">
            <label>Url</label>
            <input type="text" onChange={(e)=>setlink(e.target.value)} className="vi_0" placeholder="Enter Your url" />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddGraph}>Submit</Button>
        </Modal.Footer>
      </Modal>
     
      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <h4>Text: Banner 1</h4> */}
            <img src={viewImge} alt="" width="100%"/>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          {/* <Button variant="primary">Submit</Button> */}
        </Modal.Footer>
      </Modal>
      {/* edit Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Updated Graph</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <label htmlFor="upload1">Image</label>
            <input type="File" placeholder="Enter Userid" className="vi_0"  id="upload1" accept="image/*" onChange={(e)=>setimage(e.target.files[0])}/>
          </div>
          <div className="do-sear mt-2">
            <label>Title</label>
            <input type="text" onChange={(e)=>settitle(e.target.value)} className="vi_0" placeholder={edit?.titele} />
          </div>
          {/* <div className="do-sear mt-2">
            <label>Url</label>
            <input type="text" onChange={(e)=>setlink(e.target.value)} className="vi_0" placeholder={edit?.link} />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editGraph}>Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* deleteMode */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>
      
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteGraph}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminGallery;
