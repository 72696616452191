import React from 'react'
import { useNavigate } from 'react-router-dom'

const GetItNow = () => {
    const navigate=useNavigate
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-3">
            <h6 className="content1" htmlFor="name">
              {" "}
              Name
            </h6>
            <input className=" forminput" type="text" name="name" id="name" />
          </div>
          <div className="col-md-6 mt-3">
            <h6 className="content1" htmlFor="name">
              {" "}
              Father Name:
            </h6>
            <input
              className=" forminput"
              type="text"
              name="fname"
              id="name"
            />
          </div>

          <div className="col-md-6 mt-3 ">
            <h6 className="content1" htmlFor="email">
              {" "}
              E-mail
            </h6>
            <input
              className=" forminput"
              type="text"
              name="email"
              id="name"
            />
          </div>
          <div className="col-md-6 mt-3 ">
            <h6 className="content1" htmlFor="mobile">
              {" "}
              Mobile No
            </h6>
            <input
              className=" forminput"
              type="text"
              name="mobile"
              id="name"
            />
          </div>

          <div className="col-md-4 mt-3 Genphy">
            <h6 htmlFor="student">Gender</h6>
            <select name="gender" id="select" >
              <option value=""></option>
              <option value="">Male</option>
              <option value="">Female</option>
            </select>
          </div>
          <div className="col-md-4 mt-3 ">
            <h6 htmlFor="student">Physical Disabled</h6>
            <select name="disabled" id="select" >
              <option value=""></option>
              <option value="">Yes</option>
              <option value="">No</option>
            </select>
          </div>
          <div className="col-md-4 mt-3">
            <h6 className="contentttt" htmlFor="dob" >
              {" "}
              DOB
            </h6>
            <input  type="date" name="dob" id="select" />
          </div>

          <div className="col-md-6 mt-3">
            <h6 className="content1" htmlFor="address">
              Address Line-1
            </h6>
            <input
              className=" forminput"
              type="text"
              name="address"
              id="name"
            />
          </div>
          <div className="col-md-6 mt-3">
            <h6 className="content1" htmlFor="address">
              {" "}
              Address Line-2
            </h6>
            <input
              className=" forminput"
              type="text"
              name="address"
              id="name"
            />
          </div>

          <div className="col-md-6 mt-3">
            <h6 className="content1" htmlFor="city">
              {" "}
              City/District
            </h6>
            <input className=" forminput" type="text" name="city" id="name" />
          </div>
          <div className="col-md-6 mt-3 formfill">
            <h6 className="content1" htmlFor="state">
              {" "}
              State
            </h6>
            <select name="disabled" id="name" >
              <option value=""></option>
              <option value="">karnataka</option>
              <option value="">Andhrapradesh</option>
              <option value="">Telangana</option>
              <option value="">MadhyaPradesh</option>

            </select>
          </div>

          <div className="col-md-6 mt-3 ">
            <h6 className="content1" htmlFor="pincode">
              {" "}
              Pincode
            </h6>
            <input
              className=" forminput"
              type="text"
              name="pincode"
              id="name"
            />
          </div>
          <div className="col-md-6  mt-3 formfill" >
            <h6 htmlFor="student" >Document Type</h6>
            <select name="type" id="name" >
              <option value=""></option>
              <option value="">Adhaar Card</option>
              <option value="">Voter ID</option>
              <option value="">Pan Card</option>
            </select>
          </div>

          <div className="col-md-6 mt-3 ">
            <h6 className="content1" htmlFor="photo">
              {" "}
              Photo
            </h6>
            <input
              className=" "
              type="file"
              name="photo"
              id="name"
            />
          </div>
          <div className="col-md-6 mt-3 ">
            <h6 className="content1" htmlFor="proof">
              {" "}
              ID Proof
            </h6>
            <input
              className=" "
              type="file"
              name="proof"
              id="name"
            />
          </div>

          <div className="col-md-6 mt-3 ">
            <h6 className="content1" htmlFor="medical">
              {" "}
              Address Proof
            </h6>
            <input
              className=""
              type="file"
              name="medical"
              id="name"
            />
          </div>

          <div className="col-md-6 mt-3 ">
            <h6 className="content1" htmlFor="medical">
              {" "}
              Other Documents
            </h6>
            <input
              className=""
              type="file"
              name="medical"
              id="name"
            />
          </div>


        </div>


      </div>


    </div>
  )
}

export default GetItNow