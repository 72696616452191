import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import moment from "moment/moment";
import useRazorpay from "react-razorpay";
import { Autocomplete, TextField } from "@mui/material";
import exportFromJSON from "export-from-json";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const VendorGenrateRecipt = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
const history=useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const Razorpay = useRazorpay();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");

  const [allBranch, setAllBranc] = useState([]);
  const [changeAdi, setchangeAdi] = useState(false);

  const getAllBranch = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setAllBranc(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllAditional, setAllAditional] = useState([]);
  const getAllAditional = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllAdditional"
      );
      if (res.status == 200) {
        setAllAditional(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllCustomer, setAllCustomer] = useState([]);
  // const [nochangedata, setnochangedata] = useState([])
  const getCustomer = async () => {
    try {
      const config = {
        url: "/Customer/getAllCustomer",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
        // setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllService, setAllService] = useState([]);
  // const [nochangedata, setnochangedata] = useState([]);
  const getAllServices = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllService"
      );
      if (res.status == 200) {
        setAllService(res.data.success);
        // setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allCourse, setallCourse] = useState([]);
  // const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/course/getAllcourse"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllPayRecipt, setAllPayRecipt] = useState([]);
  const [data, setdata] = useState([]);
  const [noschangedata, setnochangedata] = useState([]);

  const getAllPay = async () => {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
    // console.log(ab)
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getPayReciptByBranchId/"+ab,{
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
              },
        }
      );
      if (res.status == 200) {
        setAllPayRecipt(res.data.success);
        setdata(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllAditional();
    getAllBranch();
    getAllCourse();
    getAllServices();
    getCustomer();
    getAllPay();
  }, []);
//   console.log(AllPayRecipt);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllPayRecipt.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  let [add, setadd] = useState(0);
  const [additionalCharge, setadditionalcharge] = useState([]);
  const checkHand = (data) => {
    let am = additionalCharge.filter((item) => item?.text == data?.text);
    if (am.length != 0) {
      let ab = additionalCharge.filter((item) => item?.text !== data?.text);
      setadditionalcharge(ab);
      setadd(ab.reduce((a, i) => a + Number(i.price), 0));
    } else {
      additionalCharge.push(data);
      setadd(additionalCharge.reduce((a, i) => a + Number(i.price), 0));
    }
  };
  const top100Films = AllCustomer.map((item) => ({
    label: `${item?.customerId}-${item?.name}`,
    year: JSON.stringify(item),
  }));

  const [Name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [Id, setId] = useState("");
  const [branch, setbranch] = useState("");
  const [serveData, setserveData] = useState("");
  const [serveAmount, setserveAmount] = useState(0);
  const [Remarks, setRemarks] = useState("");
  const [branchId, setbranchId] = useState("");
  const [courseData, setcourseData] = useState("");
  const [courseAmount, setcourseAmount] = useState(0);
  let [totalAmount, settotalAmount] = useState(0);
  const [payId, setpayId] = useState("");
  const [payAmount, setpayAmount] = useState("");
  const [payMethod, setpayMethod] = useState("");
  const [status, setstatus] = useState("Under Proccess");

  const [selectedValue, setSelectedValue] = useState(null);
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);
    if (newValue) {
      setName(JSON.parse(newValue.year)?.name);
      setId(JSON.parse(newValue.year)?.customerId);
      setuserId(JSON.parse(newValue.year)?._id);
    } else {
      setName("");
      setId("");
      setuserId("");
    }
  };

  const handBranch = (ab) => {
    if (ab !== "") {
      ab = JSON.parse(ab);
      if (ab?.branchName == "Main Branch") {
        setbranch(ab?.branchName);
        setbranchId("");
      } else {
        setbranch(ab?.branchName);
        setbranchId(ab?._id);
      }
    } else {
      setbranch("");
      setbranchId("");
    }
  };
  useEffect(()=>{
    if(admin){
        setbranch(`${admin?.branchName},${admin?.area}`);
        setbranchId(admin?.branchId ? admin?.branchId: admin?._id);
    }
  },[]);
  useEffect(() => {
    
    settotalAmount(Number(courseAmount) + add + Number(serveAmount));
  }, [courseAmount, add, serveAmount]);

  const handleShow1 = () =>{
    if(!Name) return alert("Please select customer");
    if(!branch) return alert("Please select branch");
    if(!totalAmount) return alert("Please enter total Amount");
   
    setShow1(true);}

    const [paynow,setpaynow]=useState("");

  const addAcountHistory = async (bookId) => {
    try {
      const config = {
        url: "/admin/addAccount",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          customeId: Id,
          bookingId: bookId,
          paymentId: payId,
          amount: paynow? paynow:totalAmount,
          status: "Cr",
        },
      };
      await axios(config);
    } catch (error) {
      console.log(error);
    }
  };

  const AddPayrecipts = async () => {
    try {
      const config = {
        url: "/admin/addPayRecipt",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          Name: Name,
          userId: userId,
          Id: Id,
          branch: branch,
          serveData: serveData,
          serveAmount: serveAmount,
          Remarks: Remarks,
          branchId: branchId,
          courseData: courseData,
          courseAmount:courseAmount,
          totalAmount: totalAmount,
          payId: payId,
          payAmount: payAmount,
          payMethod: payMethod,
          status: status,
          additional:additionalCharge,
          addiCharge:add,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Added");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const posttransaction = async () => {
    try {
   
      if (!payMethod) return alert("Please select payment method");
      if (payMethod == "Cash") {
   if(paynow){
    return payNowController();
   }else{
     return AddPayrecipts();
   }
        
      }

      let amount = 0;
      if(paynow){
        amount = paynow * 100;
      }else{
        amount = totalAmount * 100;
      }
      
     
      const config = {
        data: {
          key: "rzp_test_FAe0X6xLYXaXHe",
          amount: Math.round(amount),
          currency: "INR",
          name: "Jai Driving School",
          description: "Order Amount",
          image: "../images/logo.png.jpg",
          customerId: admin?._id,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayId(response.razorpay_payment_id);
          },
          prefill: {
            name: admin?.name,
            email: admin?.email,
            contact: admin?.mobile,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };
  const [ShowPay, setShowPay] = useState(false);



  const [deleteId,setdeleteId]=useState("");
  const [showD,setShowD]=useState(false);

  const deletePayC=async()=>{
    try {
      const config = {
        url: "/admin/deletePayRecipt/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully deleted");
        setShowD(false);
        getAllPay();
      }
    } catch (error) {
      console.log(error);
    }
  }
const [Pdata,setPdata]=useState({});
  const payNowController=async()=>{
    try {
      let status1=Pdata?.status;
      let am=Pdata?.totalAmount+Pdata?.payAmount
      if(am==paynow){
        status1="Paid"
      }
      const config = {
        url: "/admin/MakePaymetPayrecipt",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data:{
          authId:admin?._id,
          id:Pdata?._id,payId:payId, status:status1,payMethod:payMethod,payAmount:paynow
        }
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully pay");
         window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  }

    useEffect(()=>{
    if(payId){
      if(!paynow){
         addAcountHistory()
      AddPayrecipts();
      }else{
        addAcountHistory()
        payNowController();
      }
    }
  },[payId])

 
  const [fileName, setfileName] = useState("Pay Reacipt");
  const exportType = "xls";
  const ExportToExcel = () => {
      exportFromJSON({ data, fileName, exportType });
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
  
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setAllPayRecipt(abc);
    setdata(abc);
    setfileName(
      `Pay Recipt ${moment(fromd).format("DD/MM/YYYY")} to ${moment(
        tod
      ).format("DD/MM/YYYY")}`
    );
  };

  const handleFilter = (e) => {
    if (e.target.value != "") {
      const filterTable = noschangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllPayRecipt([...filterTable]);
    } else {

      setAllPayRecipt([...noschangedata]);
    }
  };
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ad-b mt-4">
                <button
                  style={{ borderRadius: "10px", float: "right" }}
                  onClick={()=>{
                    setId("");
                    setName("");
                    setpaynow("");
                    handleShow()}}
                >
                  +Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <div className="scrolll_bar">
            <div className="row ">
            <div className="col-md-3 mt-4">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ height: "45px" }}
                  onChange={handleFilter}
                />
              </InputGroup>
            </div>
            <div className=" col-md-2 ">
              <label>From</label>
              <br />
              <input
                type="date"
                className="vi_0"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className=" col-md-2">
              <label>To</label>
              <br />
              <input
                type="date"
                className="vi_0"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2 mt-4 ">
              <button className="btn btn-success" onClick={searchDate}>
                Submit
              </button>
            </div>
            <div className="col-md-2 mt-4 ad-b">
              <button onClick={ExportToExcel}>Export Excel</button>
            </div>
          </div>
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    S.No
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Date
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    ID
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Name
                  </th>
              
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Branch Name
                  </th>
                  <th colSpan={3}>Rescipt Generation For</th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Total Amount
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Pay Amount
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    PayId
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Pay Method
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Payment Status
                  </th>
                  <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                    Action
                  </th>
                </tr>
                <tr>
                  <th>Courses</th>
                  <th>Additional Charges</th>
                  <th>Services</th>
                </tr>
              </thead>
              <tbody>
                {AllPayRecipt  ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return ( <tr>
                  <td>{i+1}.</td>
                  <td>{moment(item?.createdAt)?.format("DD/MM/YYYY")}</td>
                  <td>{item?.Name}</td>
                  <td>{item?.Id}</td>
                  <td>{item?.branch}</td>
                  <td>
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Course Name</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item?.courseData}</td>
                          <td>{item?.courseAmount==0 ? "":item?.courseAmount}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                  <td>
                    {" "}
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Additional Charges</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                   
                          <tr>
                        <td>  {item?.additional?.map((ele)=>{
                       return  ( 
                         <dive style={{display:"flex",gap:"2px"}}><div>{ele?.text}</div><div>{ele?.price}</div></dive> 

                    )
                      })}</td>
                          <td>{item?.addiCharge==0 ? "":item?.addiCharge}</td>
                          
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                  <td>
                    {" "}
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Services</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item?.serveData}</td>
                          <td>{item?.serveAmount==0?"":item?.serveAmount}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                  <td>{item?.totalAmount?.toFixed(2)}</td>
                  <td>{item?.payAmount?.toFixed(2)}</td>
                  <td>{item?.payId}</td>
                  <td>{item?.payMethod}</td>
                  <td>{item?.status =="Paid" ? (<span style={{color:"green"}}>{item?.status}</span>):(<span style={{color:"red"}}>{item?.status}</span>)}</td>
                   <td style={{display:"flex",gap:"5px"}}>
                   <button type="button" class="btn btn-success" onClick={()=>history("/admin-invoice",{state:item})}>View</button> 
                    {(item?.totalAmount-item?.payAmount)>0 ? (<div style={{display:"flex",gap:"2px"}}>
                    <button type="button" class="btn btn-success" onClick={()=>{
                      setPdata(item);
                      setId(item?.Id);
                      setName(item?.Name);
                      setpaynow(item?.totalAmount-item?.payAmount);
                      setShowPay(true)
                    }}>PayNow</button>
                        <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setdeleteId(item?._id);
                                  setShowD(true);
                                }}
                              >
                                <span>
                                  <RiDeleteBin6Line
                                    style={{ fontSize: "20" }}
                                  />
                                </span>
                              </Button></div>):(<></>)}
                              </td>
                </tr>)
                })}
               
              </tbody>
            </Table>
            </div>
            <br />
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear">
            <label htmlFor="">Name</label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 460 }}
              value={selectedValue} // This sets the selected value
              onChange={handleAutocompleteChange} // Handle change event
              renderInput={(params) => (
                <TextField {...params} label="Customer/Student" />
              )}
            />
          </div>
          <div className="do-sear">
            <label htmlFor="">Branch Name</label>
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => handBranch(e.target.value)}
            >
              <option value={JSON?.stringify({branchName:`${admin?.branchName},${admin?.area}`,_id:admin?.branchId ? admin?.branchId: admin?._id})}>{admin?.branchName},{admin?.area}</option>
              <option value={JSON.stringify({ branchName: "Main Branch" })}>
                Main Branch
              </option>
              {allBranch?.map((item) => {
                return (
                  <option value={JSON.stringify(item)}>
                    {item.branchName},{item?.area}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="do-sear">
            <label htmlFor="">Id</label>
            <input
              type="text"
              className="vi_0"
              value={Id}
              onChange={(e) => setId(e.target.value)}
              placeholder="Enter ID"
            />
          </div>
          <div className="do-sear">
            <label htmlFor="">Rescipt Genration For</label>
            <div>
              <label htmlFor="">Course</label>
              <select
                name=""
                id=""
                className="vi_0"
                onChange={(e) => {
                  if (e.target.value) {
                    setcourseData(
                      `${JSON.parse(e.target.value)?.courseName}-${
                        JSON.parse(e.target.value)?.courseDuration
                      } days`
                    );
                    setcourseAmount(JSON.parse(e.target.value)?.price);
                  } else {
                    setcourseData("");
                    setcourseAmount(0);
                  }
                }}
              >
                <option value="">----Select Course----</option>
                {allCourse?.map((item) => {
                  return (
                    <option
                      value={JSON.stringify(item)}
                    >{`${item?.courseName}-${item?.courseDuration} days`}</option>
                  );
                })}
              </select>
              <label htmlFor="">Course Amount</label>
              <input
                type="text"
                className="vi_0"
                value={courseAmount}
                onChange={(e) => setcourseAmount(e.target.value)}
                placeholder=" Course Amount"
              />
            </div>
            <div>
              <label htmlFor="">Additional Charges</label>
              <select
                name=""
                id=""
                className="vi_0"
                onClick={() => {
                  setchangeAdi(!changeAdi);
                }}
              >
                {changeAdi ? (
                  <></>
                ) : (
                  <option>----Select Additional Charges----</option>
                )}
              </select>
              {changeAdi ? (
                <div className="container-fluid xcvbnm_012">
                  <div style={{ margin: "0% 20px " }}>
                    <Table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Service </th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {AllAditional?.map((ele, i) => {
                          return (
                            <tr>
                              <td>
                                {" "}
                                <input
                                  type="checkbox"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    background: "red",
                                  }}
                                  onChange={() => checkHand(ele)}
                                  checked={
                                    additionalCharge?.filter(
                                      (item) => item?.text == ele?.text
                                    ).length
                                  }
                                />
                              </td>
                              <td>{ele?.text}</td>
                              <td>₹{ele?.price}</td>
                            </tr>
                          );
                        })}
                        <td></td>
                        <td></td>
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                ""
              )}
              <label htmlFor="">Additional Amount</label>
              <input
                type="number"
                className="vi_0"
                value={add}
                onChange={(e) => setadd(e.target.value)}
                placeholder=" Additional Charges Amount"
              />
            </div>
            <div>
              <label htmlFor="">Select Services</label>
              <select
                name=""
                id=""
                className="vi_0"
                onChange={(e) => {
                  if (e.target.value) {
                    setserveData(
                      `${JSON.parse(e.target.value)?.serviceName}-${
                        JSON.parse(e.target.value)?.serviceType
                      }`
                    );
                    setserveAmount(JSON.parse(e.target.value)?.servicePrice);
                  } else {
                    setserveData("");
                    setserveAmount(0);
                  }
                }}
              >
                <option value="">----Select Services----</option>
                {AllService?.map((item) => {
                  return (
                    <option
                      value={JSON.stringify(item)}
                    >{`${item?.serviceName}-${item?.serviceType}`}</option>
                  );
                })}
              </select>
              <label htmlFor="">Service Amount</label>
              <input
                type="text"
                className="vi_0"
                value={serveAmount}
                placeholder=" Service Amount"
                onChange={(e) => setserveAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="do-sear">
            <label htmlFor="">Total Amount</label>
            <input
              type="number"
              className="vi_0"
              placeholder="Enter Total Amount"
              value={totalAmount}
              onChange={(e) => settotalAmount(e.target.value)}
            />
          </div>
          <div className="do-sear">
            <label htmlFor="">Payment Status</label>
            <select
              name=""
              id=""
              className="vi_0"
              onChange={(e) => setstatus(e.target.value)}
            >
              <option value="">---Select---</option>
              <option value="Paid">Paid</option>
              <option value="UnPaid">UnPaid</option>
            </select>
          </div>
          <div className="do-sear">
            <label htmlFor="">Remarks</label>
            <textarea
              name=""
              id=""
              cols="40"
              className="vi_0"
              value={Remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Remarks....."
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleShow1}>
            Generate Rescipt
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Payments Modes</h4>
          <Form>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Pay Now"
                  name="group1"
                  type={type}
                  onClick={() =>{
                    setpayAmount(totalAmount);
                     setShowPay(true)}}
                  // id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="Pay Later"
                  onClick={() => AddPayrecipts("Cash")}
                  name="group1"
                  type={type}
                  // id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h5 style={{ float: "right" }} className="mb-3">
              Total Amount:- ₹ {totalAmount?.toFixed(2)}
            </h5>
          </div>
          <div>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            {/* <Button variant="primary" style={{ marginLeft: "10px" }}>
              Submit
            </Button> */}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={ShowPay} onHide={()=>setShowPay(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Payments Modes</h4>
          <Form>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Online"
                  name="group1"
                  type={type}
                  onClick={() => setpayMethod("Online")}
                  // id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="Cash"
                  onClick={() => setpayMethod("Cash")}
                  name="group1"
                  type={type}
                  // id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
          {paynow ? (<h5 style={{ float: "right" }} className="mb-3">
           
           Remaining Amount:- ₹ {paynow?.toFixed(2)}
         </h5>):(    <h5 style={{ float: "right" }} className="mb-3">
           
           Total Amount:- ₹ {totalAmount?.toFixed(2)}
         </h5>)}
        
          </div>
          <div>
            <Button variant="secondary" onClick={()=>setShowPay(false)}>
              Close
            </Button>
            <Button variant="primary" style={{ marginLeft: "10px" }} onClick={posttransaction}>
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Model */}
      <Modal
        show={showD}
        onHide={()=>setShowD(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={()=>setShowD(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={deletePayC}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorGenrateRecipt;
