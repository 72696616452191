import React, { useEffect, useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import Coursesss from "./Cousesss";
import "../index.css";
import ReactElasticCarousel from "react-elastic-carousel";
import { padding, width } from "@mui/system";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Rating from "react-rating";
import StarRatings from "react-star-ratings";
import { BsFillPersonPlusFill, BsFillPersonVcardFill } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { BiTime } from "react-icons/bi";
import { RiMapPinTimeLine } from "react-icons/ri";
import Packages from "./packages";
import { Courses2 } from "./Courses2";
import { Caranimation } from "./caranimation";
import AOS from 'aos';
import Reeviews from "./Reeviews";
import parse from "html-react-parser";
const Home1 = () => {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 4 },
  ];
  const [AllGraph, setAllgraph] = useState([]);
  const [expire, setexpire] = useState(0);
  const getAllgraph = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllGraph"
      );
      if (res.status == 200) {
        setexpire(res.data?.success[0]?.dateAndtime);
        setAllgraph(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allAbout, setAllAbout] = useState([]);
  const getAboutUs = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getAbout");
      if (res.status == 200) {
        setAllAbout(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllDiscount, setAllDiscount] = useState([]);
  const getAllDiscount = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getActiveDiscount"
      );
      if (res.status == 200) {
        setAllDiscount(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allcount, setallCount] = useState([]);
  const getallcount = async () => {
    try {
      let res = await axios("https://jaidrivingschool.in/api/admin/getcounthome");
      if (res.status == 200) {
        setallCount(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [allReviews, setallReviews] = useState([]);
  const getAllReviews = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getReview"
      );
      if (res.status == 200) {
        setallReviews(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllgraph();
    getAllReviews();
    getallcount()
    getAboutUs();
    getAllDiscount();
  }, []);
  let [hourse, setHours] = useState(0);
  let [minutes, setMinutes] = useState(0);
  let [second, setsecond] = useState(0);
  const calculateRemainingTime = () => {
    const now = new Date(); // Get the current date and time
    const timeDifference =
      new Date(AllDiscount[0]?.dateAndtime).getTime() - now.getTime(); // Calculate the difference in milliseconds

    if (timeDifference <= 0) {
      return "Expired";
    }

    setHours(Math.floor(timeDifference / (1000 * 60 * 60))); // Convert milliseconds to hours
  };
  const chengeMinutes = () => {
    setMinutes(60 - new Date().getMinutes());
    setsecond(60 - new Date().getSeconds());
  };

  if (AllDiscount.length !== 0) {
    setInterval(() => {
      console.log("check date", calculateRemainingTime());
    }, 2000);
    setInterval(() => {
      chengeMinutes();
    }, 1000);
  }

  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [mobile, setmobile] = useState("")
  const [age, setage] = useState("")
  const [expirense, setexpirense] = useState("")
  const [have4VeH, sethave4VeH] = useState("")

  const history = useNavigate()
  const check = (e) => {
    e.preventDefault()
    let obj = { name: name, mobile: mobile, age: age, expirense: expirense, have4VeH: have4VeH }
    console.log("object", obj);
    if (!name | !mobile | !age | !expirense | !have4VeH) return alert("Please fill the blank");
    return history("/courses", { state: obj })
  }
  const user = JSON.parse(sessionStorage.getItem("user"))
  const [name1, setname1] = useState("");
  const [mobile1, setmobile1] = useState("");
  const [email1, setemail1] = useState("");
  const [comment, setcomment] = useState("");

  const Addenquire = async () => {
    try {
      if (!name1) return alert("Please enter your name");
      // if(!mobile) return alert("Please enter mobile number");
      if (!email1) return alert("Please enter your email");
      if (!comment) return alert("Please enter your comment");
      const config = {
        url: "/admin/postEnquire",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: { name: name1, email: email1, EnquireType: `About Schhol and branch`, comments: comment }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Team will be connect as soon");
        window.location.assign("/");
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      // other AOS options
    });

    // Optionally, you can add a listener to reinitialize AOS when the screen size changes
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        AOS.init({
          disable: true, // Disable AOS on small screens
        });
      } else {
        AOS.init();
      }
    });

    return () => {
      // Cleanup AOS on component unmount
      AOS.refreshHard();
    };
  }, []);


  return (
    <div>
      <div >
        <Carousel fade className="mb-0">
          {AllGraph?.map((item) => {
            return (
              <Carousel.Item>
                {/* <a href={`${item?.link}`}> */}
                <img
                  className="d-block w-100 graph"
                  src={`https://jaidrivingschool.in/Graph/${item?.image}`}
                  alt="First slide"
                  height={450}
                />
                {/* </a> */}
                {/* <Carousel.Caption>
                  <h3> {item?.titele}</h3>
                </Carousel.Caption> */}
              </Carousel.Item>
            );
          })}
        </Carousel>

        <div className="counts_bg" style={{
          backgroundImage: "url('/images/pattern-blue.jpg ')",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          // height: '150px',
          // alignItems: 'center'
        }} >
          <Container>
            <div>
              <div className="row align-items-center justify-content-evenly counts" style={{ transform: 'skew(-22deg)' }}>

                <div className="col-lg-2 d-flex gap-2 " style={{ backgroundColor: '#cd880e', height: '100px', alignItems: 'center' }} data-aos="fade-up" data-aos-duration="2000">


                  <div className="center" data-aos="fade-up"  >
                    <span><BsFillPersonPlusFill className="fs-2 mt-2" /></span>
                    <p className="mb-0 fw-bold text-light fs-3">{allcount[0]?.count}</p>
                    <p className="mb-0 fw-bold text-light">{allcount[0]?.text}</p>
                  </div>


                </div>
                <div className="col-lg-2 d-flex gap-2 align-items-center" >

                  <div className="center" data-aos="fade-up" data-aos-duration="2500" >
                    <span><BiTime className="fs-2 mt-2" /></span>
                    <p className="mb-0 fw-bold text-light fs-3">{allcount[1]?.count}</p>
                    <p className="mb-0 fw-bold text-light">{allcount[1]?.text}</p>
                  </div>
                </div>
                <div className="col-lg-2 d-flex gap-2 align-items-center " style={{ backgroundColor: '#cd880e', height: '100px', alignItems: 'center' }} data-aos="fade-up" data-aos-duration="3000">

                  <div className="center"  >
                    <span><RiMapPinTimeLine className="fs-2 mt-2" /></span>
                    <p className="mb-0 fw-bold text-light fs-3">{allcount[2]?.count}</p>
                    <p className="mb-0 fw-bold text-light">{allcount[2]?.text}</p>
                  </div>
                </div>
                <div className="col-lg-2 d-flex gap-2 align-items-center" >

                  <div className="center" data-aos="fade-up" data-aos-duration="3500" >
                    <span><BsFillPersonVcardFill className="fs-2 mt-2" /></span>
                    <p className="mb-0 fw-bold text-light fs-3">{allcount[3]?.count}</p>
                    <p className="mb-0 fw-bold text-light">{allcount[3]?.text}</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>

 
          {/* uiry */}
        </div>

      </div>

     
      {allAbout?.map((ele) => {
        return (
          <>
            <div style={{
              backgroundImage: `url('https://jaidrivingschool.in/About/${ele?.homeImage}')`, backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "450px",
              backgroundAttachment: "fixed",
            }} className="mb-3 home_bg_about">
              <div className="container pt-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="best" data-aos="zoom-in-down" data-aos-duration="500" data-aos-delay="50">
                      <div className="text-center">
                        <h4 style={{ fontFamily: "cursive", fontSize: "20px" }}>ABOUT US</h4>

                      </div>
                      <span>
                        <p>{ele?.HomeDis1? parse(ele?.HomeDis1):""}</p>
                        <p>{ele?.HomeDis2 ? parse(ele?.HomeDis2):""}</p>
                        <p>{ele?.HomeDis3 ? parse(ele?.HomeDis3):""}</p>
                      </span>
                      <button className="btn btn-warning"><a href="/about" style={{ textDecoration: "none", color: "unset" }}>VIEW MORE</a></button>
                    </div>
                  </div>
                  {AllDiscount?.map((ele) => {
                    return (
                      <>
                        <div className="col-md-6" data-aos="fade-down" data-aos-durstion="500">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="cent" >
                                  <h1 style={{ color: "white" }}>
                                    SAVE &#8377;{ele?.amount}
                                  </h1>{" "}
                                  <span>
                                    <h1 style={{ color: "yellow" }}>BOOK NOW! </h1>{" "}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-12 mt-4">
                                <div className="offer">
                                  <div>
                                    <h1 style={{ fontSize: "25px", textTransform: "uppercase" }}>{ele?.tittle}</h1>
                                  </div>
                                  <div className="container mt-4">
                                    <div style={{ display: "flex", justifyContent: "space-evenly" }} >

                                      <div className="asdfgh_23">
                                        <p>{hourse} </p>
                                        <span>Hours</span>
                                      </div>




                                      <div className="asdfgh_23">
                                        <p>{minutes} </p>
                                        <span>Minutes</span>
                                      </div>


                                      <div className="asdfgh_23">
                                        <p>{second} </p>
                                        <span>Seconds</span>
                                      </div>
                                    </div>




                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div >
              </div>

            </div>
          </>
        );
      })}



      {/* <div style={{ marginTop: "-178px" }}>
        <canvas width="52" height="200"></canvas>
      </div> */}



      <Container fluid className="mb-5" style={{ background: '#e9debaf0', padding: '10px 0px' }}>
        <div className="text-center mb-3">
          <h1 className="fw-bold">WHY CHOOSE US?</h1>
          <p style={{ fontSize: '12px' }}>We understand that learning to drive isn’t cheap, many driving schools will take advantage of this <br /> and charge you a larger sum than necessary; not us.</p>
        </div>
        <Container>
          <div className="row justify-content-evenly mb-3">
            <div className="col-lg-3 text-center">
              <img src="./images/choose-1.png" alt="" className="choose-icon" style={{ width: '50px', height: '50px' }} />
              <p className="fw-bold mb-1">BEST SAFETY MEASURES</p>
              <p style={{ fontSize: '12px' }}>Our Instructors are Highly Trained in the latest Safety Techniques & Rules of the Road.</p>
            </div>
            <div className="col-lg-3 text-center">
              <img src="./images/choose-2.png" alt="" className="choose-icon" style={{ width: '50px', height: '50px' }} />
              <p className="fw-bold mb-1">EXPERIENCED INSTRUCTORS</p>
              <p style={{ fontSize: '12px' }}>Our Instructors are Highly Trained in the latest Safety Techniques & Rules of the Road.</p>
            </div>
            <div className="col-lg-3 text-center">
              <img src="./images/choose-3.png" alt="" className="choose-icon" style={{ width: '50px', height: '50px' }} />
              <p className="fw-bold mb-1">PERFECT TIMING</p>
              <p style={{ fontSize: '12px' }}>Our Instructors are Highly Trained in the latest Safety Techniques & Rules of the Road.</p>
            </div>
          </div>

          <div className="row justify-content-evenly">
            <div className="col-lg-3 text-center">
              <img src="./images/choose-4.png" alt="" className="choose-icon" style={{ width: '50px', height: '50px' }} />
              <p className="fw-bold mb-1">AFFORDABLE FEE</p>
              <p style={{ fontSize: '12px' }}>We know this process can be expensive. So we constantly monitor our competitions pricing.</p>
            </div>
            <div className="col-lg-3 text-center">
              <img src="./images/choose-5.png" alt="" className="choose-icon" style={{ width: '50px', height: '50px' }} />
              <p className="fw-bold mb-1">CLASS FORMATS</p>
              <p style={{ fontSize: '12px' }}>We offer In Classroom Drivers Education as well as Online Drivers Education</p>
            </div>
            <div className="col-lg-3 text-center">
              <img src="./images/choose-6.png" alt="" className="choose-icon" style={{ width: '50px', height: '50px' }} />
              <p className="fw-bold mb-1">PAYMENT PLANS</p>
              <p style={{ fontSize: '12px' }}>We allow you to decide when and how you want to pay. We accept all major forms of payments.</p>
            </div>
          </div>
        </Container>
      </Container>

      {/* <Container>
        <Carousel responsive={responsive} style={{backgroundImage:"url('')"}}>
          <div>Item 1</div>
          <div>Item 2</div>
          <div>Item 3</div>
          <div>Item 4</div>
        </Carousel>;

      </Container> */}


      {/* <div
        className="qwerty_0"
        style={{
          backgroundImage: "url('/images/pattern-blue.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "250px",
        }}
      >
        <div style={{ paddingTop: "45px" }}>
          <div className="container">
            <div className="row ">
              {allcount?.map((ele) => {
                return (
                  <div
                    className="col-md-3 text-center"
                    style={{
                      paddingRight: "20px",
                      borderRight: "1px dotted rgb(157, 4, 27)",
                      color: "rgb(157, 4, 27)",
                    }}
                  >
                    <h4>{ele?.count}</h4>
                    <p>{ele?.text}</p>
                    <span>
                      <img src="./images/mehrun.jpg" alt="" width={50} height={2} />
                    </span>
                  </div>
                )
              })}

              <div
                className="col-md-3 text-center"
                style={{
                  paddingRight: "20px",
                  borderRight: "1px dotted rgb(157, 4, 27)",
                  color:"rgb(157, 4, 27)",
                }}
              >
                <h4>7</h4>
                <p> Year_on_market</p>
                <span>
                  <img src="./images/mehrun.jpg" alt="" width={50} height={2} />
                </span>
              </div>
              <div
                className="col-md-3 text-center"
                style={{
                  paddingRight: "20px",
                  borderRight: "1px dotted rgb(157, 4, 27)",
                  color:"rgb(157, 4, 27)",
                }}
              >
                <h4>578</h4>
                <p>Training Hours</p>
                <span>
                  <img src="./images/mehrun.jpg" alt="" width={50} height={2} />
                </span>
              </div>
              <div className="col-md-3 text-center" style={{color:"rgb(157, 4, 27)",}}>
                <h4>32</h4>
                <p> Number_of_teachers</p>
                <span>
                  <img src="./images/mehrun.jpg" alt="" width={50} height={2} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>    */}
      {/* <div
        className="display1"
        style={{
          backgroundImage: "url('/images/pattern-blue.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "250px",
        }}
      >
        <div
          style={{
            marginTop: "-8px",
            paddingTop: "45px",
          }}
        >
          <div className="row text-center">
            <div className="col-md-3 ">
              <h1 style={{ color: "#9d041b" }}>10000+</h1>
              <h6 style={{ color: "#9d041b" }}>Graduates_receive_the_right</h6>
              <img src="/images/mehrun.jpg" alt="" width={80} height={2} />
            </div>
            <div className="col-md-3 ">
              <h1 style={{ color: "#9d041b" }}>7</h1>
              <h6 style={{ color: "#9d041b" }}>Year_on_market</h6>
              <img src="/images/mehrun.jpg" alt="" width={80} height={2} />
            </div>
            <div className="col-md-3">
              <h1 style={{ color: "#9d041b" }}>578</h1>
              <h6 style={{ color: "#9d041b" }}>Training_hours</h6>
              <img src="/images/mehrun.jpg" alt="" width={80} height={2} />
            </div>
            <div className="col-md-3 ">
              <h1 style={{ color: "#9d041b" }}>32</h1>
              <h6 style={{ color: "#9d041b" }}>Number_of_teachers</h6>
              <img src="/images/mehrun.jpg" alt="" width={80} height={2} />
            </div>
          </div>
        </div>
      </div> */}



      <div data-aos="fade-down" data-aos-duration="1000">
        <Courses2 />
      </div>

      <div>
        <Caranimation />
      </div>

      <div>
        <Packages />
      </div>
      <div>
        <Reeviews />
      </div>


      {/* <div>
        <div className="mt-4 map">
          <div className="dvkxnn">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.9951431576383!2d77.62578477381142!3d13.035980913466044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae10d44b95e597%3A0x4991aa1f4c90c4d1!2sJSpiders%20Hebbal!5e0!3m2!1sen!2sin!4v1683837019640!5m2!1sen!2sin"
              width="100%"
              height="600"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              style={{
                zindex: "1 ",
                opacity: "1",
                border: "none",
                background: "#000000ad",
              }}
            ></iframe>
          </div> */}
      {/* <img src="/images/map.jpg" alt="" style={{ width: "100%"}} height={700} /> */}
      {/* </div>
        <div className="bg_color" style={{ backgroundColor: "#dff9fb" }}> */}
      {/* <div className="map1">
            <div
              class="card"
              style={{
                width: "21rem",
                position: "absolute",
                top: "210rem",
                left: "400px",
              }}
            >
              <div class="card-body">
                <h5 class="card-title">Google</h5>
                <h6 class="card-subtitle mb-2 text-body-secondary">
                  This page can't load Google Maps correctly.
                </h6>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ textDecoration: "underLine" }} class="card-text">
                    Do you own this website?{" "}
                  </p>
                  <button className="btn btn-warning">OK</button>
                </div>
              </div>
            </div>
          </div> */}
      {/* <div className="loc">
            <div
              class="card"
              style={{ width: "40rem", backgroundColor: " rgb(252 202 11)" }}
            >
              <div class="card-body">
                <h5 class="card-title" style={{ color: "black" }}>
                  <div
                    className="bg-32"
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <h5>
                      <LocationOnIcon /> Matvey street, russia
                    </h5>
                    <h5>
                      {" "}
                      <PhoneIcon />+ 91 888 777 5544
                    </h5>
                  </div>
                </h5>
                <h6
                  class="card-subtitle mb-2 text-body-secondary "
                  style={{ display: "flex" }}
                >
                  <input type="text" placeholder="Enter your name" value={name1} onChange={(e) => setname1(e.target.value)} id="name" />
                  <input
                    type="email"
                    placeholder="Enter your E-mail"
                    onChange={(e) => setemail1(e.target.value)}
                    id="name"
                    className="name2"
                    style={{ marginLeft: "10px" }}
                  />
                </h6>
                <textarea
                  name=""
                  placeholder="Comments"
                  cols={57}
                  rows={5}
                  onChange={(e) => setcomment(e.target.value)}
                ></textarea>
              </div>
              <p

                className="btn btn-danger homebooknow"
                style={{
                  color: "white",
                  width: "200px",
                  marginLeft: "207px",
                  marginBottom: "10px",
                  backgroundColor: "#9d041b",
                }}
                onClick={() => Addenquire()}
              >
                Send Request
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div >
  );
};

export default Home1;
