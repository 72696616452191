import React, { useEffect } from "react";
import Side from "./sidebar/Side";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";
import { DistanceMatrixService } from "@react-google-maps/api";
const Verification = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [mobile,setmobile]=useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {state}=useLocation();
  console.log("State",state);
const [address,setaddress]=useState("");
const [dropLat,setdropLat]=useState("");
const [dropLng,setdropLng]=useState("");
let pickLat=state?.classP?.pickupLat

let pickLng=state?.classP?.pickupLng
const [dtime,setdtime]=useState("")
const [distance,setdistance]=useState("");
// console.log("dist",distance,pickLat,pickLng);
  var handlePlaceChanged = (place) => {
    if (place) {
      console.log("check it", place);
  
      console.log("Lat", place.geometry.location.lat());
      console.log("Long", place.geometry.location.lng());
      setdropLat(place.geometry.location.lat());
      setdropLng(place.geometry.location.lng());
  
      setaddress(place.name);
    
    }
  };

  const sendOtp=async()=>{
    if(!mobile) return alert("please enter mobile");
    // if(!student) return alert("Please select sudent");
    // if(!topic) return alert("please select topic");
    // if(!date) return alert("Please select date");
    if(!dtime) return alert("please select time");
    // if(!address) return alert("Please enter drop place");
    
    try {
      const config = {
        url: '/cLoginSendOtp',
        method: 'post',
        baseURL: 'https://jaidrivingschool.in/api/admin/',
        headers: {'content-type': 'application/json'},
        data: {
          mobile: mobile
        },
      };
      let res=await axios(config);
      if(res.status==200){
        if(show==true){
          alert("Successfully resend otp")
        }
        handleShow()
      }
    } catch (error) {
      console.log(error);
    }
  } 
   const user=JSON.parse(sessionStorage.getItem("staff"));
  const token=sessionStorage.getItem("token")
  const [comment,setcomment]=useState("")
  useEffect(()=>{
    if(address&&Number(state?.classP?.pickupAddress)<Number(address)){
      setdistance(Number(address)-Number(state?.classP?.pickupAddress))
    }
  },[address])
  const makeEndClass=async()=>{
    try {
      const config = {
        url: '/makeEndClass',
        method: 'put',
        baseURL: 'https://jaidrivingschool.in/api/admin/',
        headers: {'content-type': 'application/json'},
        data: {
         id:state?.classP?._id,
         endtime:dtime,dropAddress:address,dropLat:dropLat,dropLng:dropLng,totalKm:distance,remarks:comment
        },
      };
      let res=await axios(config);
      if(res.status==200){
        navigate("/Sreviews",{state:state})
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  // const AddAtandance=async()=>{
  //   try {
  //     const config = {
  //       url: "/admin/AddedAttance",
  //       method: "post",
  //       baseURL: "https://jaidrivingschool.in/api",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "content-type": "application/json",
  //       },
  //       data: {
  //         authId: user?._id,day:state?.classP?.day,
  //         remark:comment,attendenceTime:state?.classP?.picupTime,attendenceDate:dtime,customerId:state?.classP?.customerId,customerName:state?.classP?.StudentName,customerMobile:state?.classP?.mobile,trainerId:user?._id,trinerName:user?.name,trainerMobile:user?.mobile,bookId:state?.classP?._id,courseId:state?.classP?._id,courseTitle:state?.classP?.classTopic,status:"Prasent"
  //       },
  //     };
  //     let res=await axios(config);
  //     if(res.status==200){
       
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const [otp,setotp]=useState("");
  const verifyOtp=async()=>{
    try {
      if(!otp) return alert("Please enter otp")
      const config = {
        url: '/cLoginVerifyOtp',
        method: 'post',
        baseURL: 'https://jaidrivingschool.in/api/admin',
        headers: {'content-type': 'application/json'},
        data: {
          mobile: mobile,
          otp: otp,
        },
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully verify")
        makeEndClass()
     
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  // navigate("/Sreviews")
  useEffect(()=>{
    if(state?.classP){
      setmobile(state?.classP?.mobile)
    }
  },[])

  return (
    <div>
      <Side />
      <div className="container">
        <div
          style={{
            // border: "1px solid white",
            // boxShadow: "0 8px 32px rgba(19,19,20,0.37)",
            borderRadius: "10px",
            marginTop: "10px",
          }}
        >
          <div className=" mt-3 mb-3">
            <div className="row">
              <h4>Thank You For Attending The Class</h4>

              <div className="col-md-4">
                <label for="disabledSelect" class="form-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="disabledTextInput"
                  class="form-control"
                  value={mobile}
                  onChange={(e)=>setmobile(e.target.value)}
                  placeholder="Enter customer number"
                  style={{ width: "270px" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label for="disabledSelect" class="form-label">
                  Drop Time
                </label>
                <input
                  type="time"
                  id="disabledTextInput"
                  class="form-control"
                  onChange={(e)=>setdtime(e.target.value)}
                  placeholder="enter your no"
                  style={{ width: "270px" }}
                />
        
              </div>
           
            </div>
            <div className="row">
              <div className="col-md-6">
                <label for="disabledSelect" class="form-label">
                  End Reading
                </label>
                <input
                  type="number"
                  id="disabledTextInput"
                  class="form-control"
                
                  onChange={(e)=>setaddress(e.target.value)}
                  placeholder="Enter end reading"
                  style={{ width: "270px" }}
                />
          {/* {address ? ( <DistanceMatrixService
              options={{
                origins: [{ lng: Number(pickLng), lat: Number(pickLat) }],
                destinations: [{ lat: Number(dropLat), lng: Number(dropLng) }],
              
                travelMode: "DRIVING",
              }}
             
         
              callback={(response) => {
               
             
                setdistance(
                  parseInt(response.rows[0].elements[0].distance?.text)
                );
              }}
            />):(<></>)} */}
              </div>

            
            </div>
            <div className="row">
              <div className="col-md-6">
                <label for="disabledSelect" class="form-label">
                  Total km
                </label>
                <input
                  type="number"
                  min="1"
                  value={distance}
                  id="disabledTextInput"
                  class="form-control"
                  onChange={(e)=>setdistance(e.target.value)}
                  placeholder="Enter total distance"
                  style={{ width: "270px" }}
                />
        
              </div>
           
            </div>
            <div className="row">
              <div className="col-md-6">
                <label for="disabledSelect" class="form-label">
                  Remark
                </label>
                <input
                  type="text"
                
                  value={comment}
                  id="disabledTextInput"
                  class="form-control"
                  onChange={(e)=>setcomment(e.target.value)}
                  placeholder="Enter same remark"
                  style={{ width: "270px" }}
                />
        
              </div>
              <div>
                <Button
                  variant="primary mt-3 mb-3"
                  onClick={sendOtp}
                  style={{ color: "black" }}
                >
                  Complete Class
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleShow}>
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Enter The OTP</h6>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <input type="number" min="0" maxLength="6" className="vi_0" value={otp} onChange={(e)=>setotp(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="danger"
            onClick={() => sendOtp()}
            style={{ color: "black" }}
          >
            Resend
          </Button>
          <Button
            variant="success"
            onClick={() => verifyOtp()}
            style={{ color: "black" }}
          >
            Verify
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Enter The OTP</h6>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <input type="text" className="vi_0" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => navigate("/Sreviews")}
            style={{ color: "black" }}
          >
            submit
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default Verification;
