import React from "react";
// import Side1 from "./sidebar1/Side1";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const AdminInvoice = () => {


  const createPDF = async () => {

    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.getElementById("pdf"), {
      useCORS: true,
    });
    console.log("data", data);
    const img = data.toDataURL("image/png");
    console.log("img", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("imgProperties", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("pdfWidth", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("pdfHeight", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("PaymentRescipt.pdf");
  };
  const user = JSON.parse(sessionStorage.getItem("user"))
  const token = sessionStorage.getItem("token")
  const admin = JSON.parse(sessionStorage.getItem("admin"));


  const location = useLocation();
  console.log("fdfs", location?.state)

const navigate=useNavigate();
  return (
    <div>
    
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-12" >
          <button className="btn btn-warning"  onClick={()=>navigate(-1)}>
              Back{" "}
              <i
                class="fa-solid fa-back "
                style={{ marginLeft: "10px" }}
              ></i>
            </button>
            <button className="btn btn-warning" style={{ float: "right" }} onClick={createPDF}>
              Download Rescipt{" "}
              <i
                class="fa-solid fa-file-arrow-down "
                style={{ marginLeft: "10px" }}
              ></i>
            </button>
          </div>
        </div>
        <div
          style={{
            boxShadow: " 0px 8px 32px 0px rgba(19, 19, 20, 0.37)",
            background: "#f5f6fa",
            backdropFilter: "blur(4px)",
            border: "1px solid black",
           
            width: "100%",
            padding: "50px",
            borderRadius: "20px",
          }}
          id="pdf"
        >
          <div className="jdpoqwj_0" style={{ backgroundColor: "#dfe6e9" }}>
            <div className="container ">
              <div className="row">
                <div className="col-md-1 mt-3">
                  <img
                    src="./images/logo.png.jpg"
                    alt=""
                    style={{ width: "70px" }}
                  />
                </div>
                <div className="col-md-9 text-center ">
                  <p
                    className="fw-bold fs-4 pt-5"
                    style={{ fontFamily: "initial" }}
                  >
                    JAI MOTOR DRIVING SCHOOL <br />{" "}
                    <p className="fw-normal fs-6">
                      Site No. 185/2B, “Chennakeshava Commercial Complex”, Shop
                      No. 5 & 6, Thindlu Main Road, Opp to Srihari Service
                      Station, Virupakshapura, Vidyaranyapura Post, Bangalore
                      –560097. Ph: 080-49917114 | Mob: 9845091018|8618360152
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container ">
            <div className="row mt-3">
            
              <div className="col-md-12">
                <p
                  style={{
                    fontSize: "20px",
                    borderBottom: "3px solid #dfe6e9",
                    color: "#636e72",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <span style={{ float: "left" }}>
                    <span>Rescipt No:</span> <span>#JDSR{location?.state?._id?.slice(20,25)}</span>
                  </span>
                  <span>
                    RESCIPT{" "}
                  </span>

                  <span style={{ float: "right" }}>
                    {" "}
                    <span>Date:</span> <span>{moment(location?.state?.createdAt).format("DD/MM/YYYY")}</span>
                  </span>
                </p>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        <b>Received with thanks from </b>{" "}
                        <span>{location?.state?.Name}</span> <br />
                      </p>
                      <p>
                        <b>The Sum of Rupees :</b>{" "}
                        <span>&#8377;{location?.state?.totalAmount}/- Only</span>
                      </p>
                      <p>
                        <b>Payment Mode:</b>
                        <span>{location?.state?.payMethod}</span> </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <b>Course Name:</b> <span>{location?.state?.courseData}</span>
                      </p>
                      <p>
                        <b>Balance:</b>
                        <span>&#8377; {Number(location?.state?.totalAmount) - Number(location?.state?.payAmount)}/-</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <table className="table table-bordered border-dark">
             
                  <tbody>
                    <tr>
                      <th>Total Amount </th>
                      <td>&#8377;{location?.state?.totalAmount}/-</td>
                    </tr>
                    <tr>
                      <th>Amount Paid </th>
                      <td>&#8377;{location?.state?.payAmount}/-</td>
                    </tr>
                    <tr>
                      <th>Balance Due </th>
                      <td>&#8377;{Number(location?.state?.totalAmount) - Number(location?.state?.payAmount)}/-
                      </td>
                    </tr>
                  </tbody>
                </table>
              
              </div>
              <div className="col-md-4">
                <div className="text-center totalAmount">
                  <span
                    style={{
                      border: "1px solid black",
                      //   width: "",
                      padding: "10px 100px",
                      borderRadius: "15px",
                    }}
                  >
                    <span>&#8377; {location?.state?.totalAmount}/- </span>
                  </span>{" "}
                  <div style={{ marginTop: "10px" }}>
                    <label
                      class="form-check-label text-dark mt-1"
                      for="flexCheckChecked"
                    >
                      For JAI MOTOR DRIVING SCHOOL
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

       
          {/* <div className="container">
            <div className="row">
              <div className="col-md-1">1.</div>
              <div className="col-md-2">
                <p>
                  {" "}
                  <span className="fw-bold">
                    BONDED WITH HR SOFTY AND LATEX (78*72*8)
                  </span>
                  <br />
                  <span>10 years warranty on mattress excluding fabric</span>
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;17000</p>
              </div>
              <div className="col-md-2">
                <p style={{ float: "right" }}>1</p>
              </div>
              <div className="col-md-2">
                <p style={{ float: "right" }}>&#8377;17000</p>
              </div>
              <hr />
            </div>
          </div> */}

          {/* <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>SUBTOTAL</p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;17000</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>GST (18%) </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;3,203.28</p>
              </div>
              <hr />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>TOTAL </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;20,999.28</p>
              </div>
              <hr />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>
                  BALANCE DUE{" "}
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;20,999.28</p>
              </div>
              <hr />
            </div>
          </div> */}
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-12">
                <span style={{ float: "right" }}>Signature</span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <span style={{ fontSize: "15px", fontFamily: "initial" }}>
                  This is Computer Generated Rescipt
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInvoice;
