import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import parse from "html-react-parser";
import '../PageLoder.css'; // Import your CSS file for styling

const Rto = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);


  //loder 
  const [loading, setLoading] = useState(false);

  const [PayMethod, setpayMethod] = useState("");
  const handleShow = () => {
    if (!user) {
      alert("Please login");
      return window.location.assign("/studentlogin");
    }
    setShow(true);
  };
  const [allBranch, setAllBranc] = useState([]);

  const getAllBranch = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setAllBranc(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const Razorpay = useRazorpay();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const [allCity, setAllCity] = useState([]);
  const getAllcity = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllCity"
      );
      if (res.status == 200) {
        setAllCity(res.data.success);
        getAllBranch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [allService, setAllService] = useState([]);
  const getAllService = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllService"
      );
      if (res.status === 200) {
        setAllService(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AllAditional, setAllAditional] = useState([]);
  const getAllAditional = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllAdditional"
      );
      if (res.status == 200) {
        setAllAditional(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllAditional();
    getAllService();
    getAllcity();
    getAllBranch();
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allService.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [branch, setbranch] = useState("");
  const [address, setaddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [photo, setphoto] = useState("");
  const [idproof, setidproof] = useState("");
  const [medicleProof, setmedicleProof] = useState("");
  const [payId, setpayid] = useState("");
  useEffect(() => {
    if (user) {
      setname(user?.name);
      setmobile(user?.mobile);
      setemail(user?.email);
      setgender(user?.gender);
      setdob(user?.dob);
      setstate(user?.state);
      setcity(user?.city);
      setaddress(user?.Address);
      setpincode(user?.pincode);
      setphoto(user?.profile);
    }
  }, []);
  const [allDoc, setAllDoc] = useState([]);
  const handleshow1 = () => {
    if (!name) return alert("Please fill the blank");
    if (!email) return alert("Please fill the blank");
    if (!mobile) return alert("Please fill the blank");
    if (!gender | !dob | !city | !branch | !address | !pincode | !photo)
      return alert("Please complete the form");
    if(!allDoc.length) return alert("please add documents");
    handleClose();
    setShow1(true);
  };
  let [add, setadd] = useState(0);

  const [additionalCharge, setadditionalcharge] = useState([]);
  const checkHand = (data) => {
    let am = additionalCharge.filter((item) => item?._id == data?._id);
    if (am.length != 0) {
      let ab = additionalCharge.filter((item) => item?._id !== data?._id);
      setadditionalcharge(ab);
      setadd(ab.reduce((a, i) => a + Number(i.price), 0));
    } else {
      additionalCharge.push(data);
      setadd(additionalCharge.reduce((a, i) => a + Number(i.price), 0));
    }
  };

  const [image, setimage] = useState("");
  const [doctype, setdoctype] = useState("");
  const addDocument = () => {
    if (!doctype) return alert("Please select document type");
    if (!image) return alert("Please select file")
    allDoc.push({
      doctype: doctype,
      image: image,
    });
    setimage("");
    setdoctype("");
    return alert("Successfully added");
  };
  const uploadDocument = async (id) => {
    try {
      for (let i = 0; i < allDoc.length; i++) {
        const config = {
          url: "/admin/uploadDocument",
          method: "put",
          baseURL: "https://jaidrivingschool.in/api",
          headers: { "content-type": "multipart/form-data" },
          data: {
            id: id,
            doctype: allDoc[i].doctype,
            image: allDoc[i].image,
          },
        };
        await axios(config);
      }
      alert("Successfully booked");
      window.location.assign("/sservice");
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const [courseData, setCourseData] = useState({});

  const token = sessionStorage.getItem("token");
  const addAcountHistory = async (bookId) => {
    try {
      const config = {
        url: "/admin/addAccount",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },
        data: {
          authId: user?._id,
          customeId: user?.customerId, bookingId: bookId, paymentId: payId, amount: (courseData?.servicePrice + add), status: "Cr"
        }
      }
      await axios(config);
    } catch (error) {
      console.log(error);
    }
  }
  const updateUser = async () => {
    try {
      setLoading(true);
      let pay = 0;
      let paymentStatus = "";
      if (PayMethod == "Online") {
        pay = courseData?.servicePrice;
        paymentStatus = "Full Payment";
      }
      let obj = {
        customerId: user?._id,
        ServiceId: courseData?._id,
        Servicename: courseData?.serviceName,
        customerName: name,
        number: mobile,
        courseStartDate: courseData?.serviceStDate,
        courseEndDate: courseData?.serviceEndDate,
        totalAmount: courseData?.servicePrice + add,
        payId: payId,
        paymentMethod: PayMethod,
        email: email,
        city: city,
        area: branch,
        passportcopy: photo,
        addhar: idproof,
        medicalproof: medicleProof,
        dob: dob,
        Address: address,
        gender: gender,
        area: branch,
        state: state,
        pincode: pincode,
        rentalagreement: "",
        sslcmarkcard: "",
        voterid: "",
        paymentStatus: paymentStatus,
        payAmount: pay,
        branchId: branch?._id,
        branchName: branch?.branchName,
        additional: additionalCharge,
      };

      const config = {
        url: "/admin/createservicebooking",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status == 200) {
        if (payId) {
          addAcountHistory(res.data.bookId)
        }
        uploadDocument(res.data.success?._id);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const posttransaction = async () => {
    try {
      if (!PayMethod) return alert("Please select payment method");
      if (PayMethod == "Cash") {
        return updateUser();
      }
      const config = {
        data: {
          key: "rzp_test_FAe0X6xLYXaXHe",
          amount: (courseData?.servicePrice + add) * 100,
          currency: "INR",
          name: "Jai Draving School",
          description: "Order Amount",
          image: "../images/logo.png.jpg",
          customerId: user?._id,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayid(response.razorpay_payment_id);
          },
          prefill: {
            name: user?.name,
            email: user?.email,
            contact: user?.mobile,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (payId) {
      updateUser();
    }
  }, [payId]);
  return (
    <div >
      {loading ? ( <div className={`page-loader ${loading ? "visible" : "hidden"}`}>
      <div className="loader"></div>
    </div>):(<></>)}
      <div className="">
        <div
          class="module mid"
          style={{
            height: "130px",
            background: "black",
            overflow: " hidden",
            background: " #C33764",
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Services</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "white" }}>&raquo;</span>
            </li>
            <li>
              <a href="/rto">Services</a>{" "}
              <span style={{ color: "white" }}>&raquo;</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="container">
        {allService
          ?.slice(pagesVisited, pagesVisited + usersPerPage)
          ?.map((item) => {
            return (

              <div className=" rw1 row recomended">
                <div className="col-lg-4 col-md-2 pt-5 ">
                  <img
                    src={`https://jaidrivingschool.in/Service/${item?.servicePicture}`}
                    alt=""
                    height={300}
                    style={{ width: "100%" }}
                  />


                </div>
                <div className="col-lg-8 col-md-10 bg-7 mt-3 ">
                  <h3>{item?.serviceName} </h3>
                  <h5 style={{ textAlign: "justify" }}>
                    {item?.serviceDiscrioption
                      ? parse(item?.serviceDiscrioption)
                      : ""}
                  </h5>
                  <b>
                    <p className="mt-2">Doucument requireds</p>
                  </b>
                  <div className="row">
                    {item?.servicePoint?.map((ele) => {
                      return (
                        <div className="col-md-3 col-lg-4">
                          <p>&#10003;{ele?.text}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h3>&#8377;{item?.servicePrice?.toFixed(2)} </h3>
                    </div>
                    <div className="col-md-6 ">
                      <button
                        className="btn btn-warning cbmd_09"
                        onClick={() => {
                          setCourseData(item);
                          handleShow();
                        }}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                  {item?.popularService == true ? (<div>
                    <span className="recomnded">
                      Recomended
                    </span>
                  </div>) : (<></>)}
                </div>

              </div>

            );
          })}
      </div>
      <div >

        <div className="col-md-12" >
          <span style={{ textAlign: "center" }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </span>

        </div>

      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill the Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-12">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Full name"
                  aria-label="Full name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-6">
                <label htmlFor="">Email ID</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  class="form-control"
                  placeholder="Email"
                  aria-label="Email"
                />
              </div>
              <div class="col-md-6">
                <label htmlFor="">Mobile</label>
                <input
                  type="number"
                  value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                  class="form-control"
                  placeholder="Mobile"
                  aria-label="Mobile"
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-6">
                <label htmlFor="">Gender</label>
                <select
                  class="form-select "
                  aria-label=".form-select-lg example"
                  onChange={(e) => setgender(e.target.value)}
                >
                  <option>{gender ? gender : "Select gender"} </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div class="col-md-6">
                <label htmlFor="">DOB</label>
                <input
                  type="date"
                  value={dob}
                  onChange={(e) => setdob(e.target.value)}
                  class="form-control"
                  placeholder="Last name"
                  aria-label="Last name"
                />
              </div>
            </div>
          </div>


          {/* <div className="do-sear mt-2">
            <div class="row me-0">


              <div class="col-md-12">
                <label htmlFor="">User Type</label>
                <input
                  type="text"
                  // onChange={(e) => setdob(e.target.value)}
                  class="form-control"
                  placeholder="User Type"
                  aria-label="Last name"
                />
              </div>
            </div>
          </div> */}

          <div className="do-sear mt-2">
            <div class="row me-0">
              <div className="" style={{ display: "flex" }}>
                <div class="col-md-6">
                  <label for="inputState" class="form-label">
                    City
                  </label>
                  <select
                    id="inputState"
                    class="form-select formselect"
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option selected>{user?.city ? city : "Choose..."}</option>
                    {allCity?.map((item) => {
                      return <option>{item?.city}</option>;
                    })}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="inputState" class="form-label ms-2">
                    Branch
                  </label>
                  <select
                    class="form-select ms-2 formselect"
                    onChange={(e) => setbranch(JSON.parse(e.target.value))}
                  >
                    <option>Choose...</option>
                    <option
                      value={JSON.stringify({ branchName: "Main Branch" })}
                    >
                      Main Branch
                    </option>
                    {allBranch
                      ?.filter((ele) => ele?.city == city)
                      ?.map((item) => {
                        return (
                          <option value={JSON.stringify(item)}>
                            {item.branchName},{item?.area}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-6">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Address
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  placeholder="Please enter your address"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                ></textarea>
              </div>
              <div class="col-md-6">
                <label for="inputZip" class="form-label">
                  Zip
                </label>
                <input
                  type="text"
                  placeholder="Please enter zip"
                  value={pincode}
                  onChange={(e) => setpincode(e.target.value)}
                  class="form-control"
                  id="inputZip"
                />
              </div>
            </div>
          </div>
          <div className="row me-0">
            <div class="col-md-7">
              <label for="formFile" class="form-label" htmlFor="upload3">
                Documents
              </label>

            </div>
            {/* <div class="col-md-3">
            <button type="button" class="btn btn-danger" onClick={addDocument}>Add</button>
            </div> */}
          </div>
          <div className="row me-0">
            <div class="col-md-5">

              <select
                class="form-select ms-2 formselect"
                onChange={(e) => setdoctype(e.target.value)}
              >
                <option>Choose Doc Type</option>

                {courseData?.servicePoint?.map((item) => {
                  return <option value={item?.text}>{item.text}</option>;
                })}
              </select>
            </div>
            <div class="col-md-5">

              <input
                class="form-control"
                type="file"
                id="upload3"
                onChange={(e) => setimage(e.target.files[0])}
              />
            </div>
            <div class="col-md-2">
              <button type="button" class="btn btn-danger" onClick={addDocument}>Add</button>
            </div>
            {allDoc?.map((item, i) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-between",margin:"10px" }}
                  >
                      <div>{i+1}.</div>
                    <div>{item?.doctype}</div>
                    <div>
                      <img
                        src={URL.createObjectURL(item?.image)}
                        alt="Selected"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleshow1()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Additional</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Service </th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {AllAditional?.slice(
                pagesVisited,
                pagesVisited + usersPerPage
              )?.map((ele, i) => {
                return (
                  <tr>
                    <td>
                      {" "}
                      <input
                        type="checkbox"
                        style={{
                          width: "20px",
                          height: "20px",
                          background: "red",
                        }}
                        onChange={() => checkHand(ele)}
                      />
                    </td>
                    <td>{ele?.text}</td>
                    <td>₹ {ele?.price}</td>
                  </tr>
                );
              })}
              <td></td>
              <td></td>
              {/* <td style={{padding:"5px",fontWeight:"bold"}}>Total Price :-{total?.toFixed(2)}</td> */}
            </tbody>
          </Table>
          <h4>Payments Modes</h4>
          <br />
          <Form>
            {["radio"].map((type) => (
              <div
                key={`inline-${type}`}
                className="mb-6"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Form.Check
                  inline
                  label="Online"
                  name="group1"
                  type={type}
                  onClick={() => setpayMethod("Online")}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="Cash"
                  onClick={() => setpayMethod("Cash")}
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                />
              </div>
            ))}
            <br />
            <h5>
              Total Price:- ₹ {(courseData?.servicePrice + add)?.toFixed(2)}
            </h5>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={posttransaction}>
            Book Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Rto;
