import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FiRefreshCcw } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Side1 from "./sidebar1/Side1";
import axios from "axios";
import parse from "html-react-parser";
import ReactPaginate from "react-paginate";
const UserOneDayCourse = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const [AllOneDay, setAllOneDay] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const getAllOnedayCourse = async () => {
    try {
      let res = await axios(
        "https://jaidrivingschool.in/api/admin/getAllOneDayCourseByuserId/" +
          user?._id
      );
      if (res.status == 200) {
        setAllOneDay(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOnedayCourse();
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllOneDay.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllOneDay([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllOneDay([...nochangedata]);
    }
  };
  const token = sessionStorage.getItem("token");
  const RescheduldeOneCourese = async (id, studentStatus) => {
    try {
      const config = {
        url: "/admin/makeStudentSatusOneDayCourse",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          id: id,
          authId: user?._id,
          studentStatus: studentStatus,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(`Successfully ${studentStatus}`);

        getAllOnedayCourse();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      <Side1 />
      <div className="add-gr">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12">
                            <div className="ad-b mt-4">
                                <button
                                    style={{ borderRadius: "10px", float: "right" }}
                                    onClick={handleShow}
                                >
                                    +Add
                                </button>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-3">
              <input
                type="text"
                placeholder="Search...."
                className="vi_0"
                style={{ margin: "10px" }}
                onChange={(e) => handleFilter(e)}
              />
            </div>
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S No.</th>

                  <th>Branch Name</th>
                  <th>Course Name</th>
                  <th>Overview</th>
                  <th>Reschedule Date/Time</th>
                  <th>Reschedule</th>
                  <th> Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllOneDay?.slice(
                  pagesVisited,
                  pagesVisited + usersPerPage
                )?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>

                     
                      <td>{item?.branchName}</td>
                      <td>{item?.CourseName}</td>

                      <td>{item?.overview ? parse(item?.overview) : ""}</td>
                      <td>
                        {item?.date},{item?.time}
                      </td>
                      <td>
                        {item?.reshuldDate} {item?.reshudleTime}
                      </td>

                      <td>
                        {item?.studentStatus == "Pending" ? (
                          <span style={{ color: "blue" }}>
                            {item?.studentStatus}
                          </span>
                        ) : (
                          <span style={{ color: "green" }}>
                            {item?.studentStatus}
                          </span>
                        )}
                      </td>
                      <td>
                        {item?.studentStatus == "Confirmed" ? (
                          <></>
                        ) : (
                          <div style={{ display: "flex", gap: "5px" }}>
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={() =>
                                RescheduldeOneCourese(item?._id, "Confirmed")
                              }
                            >
                              Confirm
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={() =>
                                RescheduldeOneCourese(item?._id, "Rejected")
                              }
                            >
                              Reject
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add One Day Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear">
            <label htmlFor="">Branch Name</label>
            <select name="" id="" className="vi_0">
              <option value="">-----Select Branch-----</option>
            </select>
          </div>
          <div className="do-sear">
            <label htmlFor="">Name</label>
            <select name="" id="" className="vi_0">
              <option value="">----Select Name----</option>
            </select>
          </div>
          <div className="do-sear">
            <label htmlFor="">ID</label>
            <input type="text" placeholder="fetch id" className="vi_0" />
          </div>
          <div className="so-sear">
            <label htmlFor="">Course Name</label>
            <select name="" id="" className="vi_0">
              <option value="">----Select Course Name----</option>
            </select>
          </div>
          <div className="do-sear">
            <label htmlFor="">Full Day Course Date</label>
            <input type="date" name="" id="" className="vi_0" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Re-Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear">
            <label htmlFor="">Full Day Course Date</label>
            <input type="date" name="" id="" className="vi_0" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Reschedule</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="success">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserOneDayCourse;
