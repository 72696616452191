import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

export const VenderView5 = () => {

    const {state}=useLocation();

    let item=state?.item
const item1=state?.item1

    const createPDF = async () => {

        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.getElementById("pdf"), {
            useCORS: true,
        });
        console.log("data", data);
        const img = data.toDataURL("image/png");
        console.log("img", img);
        const imgProperties = pdf.getImageProperties(img);
        console.log("imgProperties", imgProperties);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        console.log("pdfWidth", pdfWidth);
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        console.log("pdfHeight", pdfHeight);
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("Form5.pdf");
    };

    const [branch,setbranch]=useState({});
    const getBranchData=async()=>{
        try {
            let res= await axios.get("https://jaidrivingschool.in/api/admin/getBranchById/"+item?.branchId);
            if(res.status==200){
                setbranch(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(()=>{
     
        if(item?.branchId){
            getBranchData();
        }
    },[]);
    return (
        <div className="add-gr"  >
            <Container>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-warning" style={{ float: "right" }} onClick={createPDF}>
                            Download Form 5{" "}
                            <i
                                class="fa-solid fa-file-arrow-down "
                                style={{ marginLeft: "10px" }}
                            ></i>
                        </button>
                    </div>
                </div>
         {item ? ( 
              <div className='form-5 mb-4' id='pdf' >
                    <div className='text-center'>
                        <h2 className='form-label'>FORM 5</h2>
                    
                    </div>

                    <br />

                    <div className='d-flex gap-2'>
                        <p className='text-start form-label'>NO : </p>
                        <p className='text-underline' style={{ width: '100px' }}>{(item?.bookid?.replace(/[^0-9]/g,""))}</p>
                    </div>

                    <br />

                    <div className='d-flex gap-2'>
                        <p className='form-label'>This is to certify that Shri / Smt, / Kumari : </p>
                        <p className='text-underline' >{item?.customerName}</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <p className='form-label'>Son / Wife / Daughter of : </p>
                        <p className='text-underline' style={{ width: '640px' }}>{item?.gaudianName}</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <p className='form-label'>Residing at : </p>
                        <p className='text-underline' style={{ width: '742px' }}>{item?.customerId?.Address}-{item?.city}</p>
                        {/* <p className='form-label'>.Was enrolled in this school</p> */}
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>His / Her Trainer name  : </p>
                        <p className='text-underline' style={{ width: '650px' }}>{item?.trinerName}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Number registered in form 14 : </p>
                        <p className='text-underline' style={{ width: '600px' }}>{(item?.bookid?.replace(/[^0-9]/g,""))}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Course of training in driving : </p>
                        <p className='text-underline' style={{ width: '615px' }}>{item?.courseTitle}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Syllabus prescribed for a period from : </p>
                        <p className='text-underline' style={{ width: '547px' }}>{item?.courseStartDate ? moment(item?.courseStartDate).format("DD/MM/YYYY"):""}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>to : </p>
                        <p className='text-underline' style={{ width: '695px' }}>{item?.courseEndDate ?  moment(item?.courseEndDate).format("DD/MM/YYYY"):""}</p>
                        <p className='form-label'>.satisfactorily</p>
                    </div>

                    <p className='text-center'>"I am satisfied with his/ her physical fitness and sense of responsibility"</p>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Place : </p>
                        <p className='text-underline' style={{ width: '300px' }}>{item?.branchName}-{item?.city}</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <p className='form-label'>Date : </p>
                        <p className='text-underline' style={{ width: '300px' }}></p>{item?.sighDate}
                    </div>

                    <div className='text-end d-flex justify-content-end'>
                        <div className='text-center'>
                            <p className='mb-0  '>Signature</p>
                            <p className='mb-0  '>Name and Designation</p>
                            <p>Name and Address of the Driving School <br /> with licence number and date of issue</p>
                        </div>
                    </div>

                    <hr />

                    <div className='text-center'>
                        <h2 className='text-uppercase'>jai motor driving school </h2>
                        <p style={{ fontSize: '14px', margin: '0px' }}>site No. 185/2B, "Chennkeshav Comercial complex", Shop No,5 & 6, Thindlu Main Road <br /> Oppt to Shirihari Service Station, Virupakshpura, Vidyranyapura Post, Banglore-560097.</p>
                        <p className='fw-bold' style={{ fontSize: '14px' }}> ph : 080-49917114 | Mob : 9845091018 | 8618360152 </p>
                    </div>

                </div>):(   <div className='form-5 mb-4' id='pdf' >
                    <div className='text-center'>
                        <h2 className='form-label'>FORM 5</h2>
                    
                    </div>

                    <br />

                    <div className='d-flex gap-2'>
                        <p className='text-start form-label'>NO : </p>
                        <p className='text-underline' style={{ width: '100px' }}>{(item1?.serNou)}</p>
                    </div>

                    <br />

                    <div className='d-flex gap-2'>
                        <p className='form-label'>This is to certify that Shri / Smt, / Kumari : </p>
                        <p className='text-underline' >{item1?.Name}</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <p className='form-label'>Son / Wife / Daughter of : </p>
                        <p className='text-underline' style={{ width: '640px' }}>{item1?.garduianName}</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <p className='form-label'>Residing at : </p>
                        <p className='text-underline' style={{ width: '742px' }}>{item1?.PermanentAddress}</p>
                        {/* <p className='form-label'>.Was enrolled in this school</p> */}
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>His / Her Trainer name  : </p>
                        <p className='text-underline' style={{ width: '650px' }}>{item1?.Trainername}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Number registered in form 14 : </p>
                        <p className='text-underline' style={{ width: '600px' }}>{(item1?.Numberregisteredinform14)}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Course of training in driving : </p>
                        <p className='text-underline' style={{ width: '615px' }}>{item1?.CourseName}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Syllabus prescribed for a period from : </p>
                        <p className='text-underline' style={{ width: '547px' }}>{item1?.CourseStartDate ? moment(item1?.CourseStartDate).format("DD/MM/YYYY"):""}</p>
                    </div>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>to : </p>
                        <p className='text-underline' style={{ width: '695px' }}>{item1?.CourseEndDate ?  moment(item1?.CourseEndDate).format("DD/MM/YYYY"):""}</p>
                        <p className='form-label'>.satisfactorilly</p>
                    </div>

                    <p className='text-center'>"I am satisfied with his/ her physical fitness and sense of responsibility"</p>

                    <div className='d-flex gap-2'>
                        <p className='form-label'>Place : </p>
                        <p className='text-underline' style={{ width: '300px' }}>{item1?.Place}</p>
                    </div>
                    <div className='d-flex gap-2'>
                        <p className='form-label'>Date : </p>
                        <p className='text-underline' style={{ width: '300px' }}>{item1?.DateA}</p>
                    </div>

                    <div className='text-end d-flex justify-content-end'>
                        <div className='text-center'>
                            <p className='mb-0  '>Signature</p>
                            <p className='mb-0  '>Name and Designation</p>
                            <p>Name and Address of the Driving School <br /> with licence number and date of issue</p>
                        </div>
                    </div>

                    <hr />

                    <div className='text-center'>
                        <h2 className='text-uppercase'>jai motor driving school </h2>
                        {branch?.Adress ? ( <p style={{ fontSize: '14px', margin: '0px' }}>{branch?.Adress} {branch?.area}-{branch?.pincode}.</p>):( <p style={{ fontSize: '14px', margin: '0px' }}>site No. 185/2B, "Chennkeshav Comercial complex", Shop No,5 & 6, Thindlu Main Road <br /> Oppt to Shirihari Service Station, Virupakshpura, Vidyranyapura Post, Banglore-560097.</p>)}
   {branch?.branchNumber ? ( <p className='fw-bold mb-0' style={{ fontSize: '14px' }}> ph : {branch?.branchNumber} | Email : {branch?.branchEmail} </p>):( <p className='fw-bold mb-0' style={{ fontSize: '14px' }}> ph : 080-49917114 | Mob : 9845091018 | 8618360152 </p>)}
   
                    </div>

                </div>)}
             

            </Container>
        </div>
    )
}
