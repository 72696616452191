import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import Table from "react-bootstrap/Table";
import { jsx } from "@emotion/react";
const CourseViewMore = () => {
  const { state } = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

const user=JSON.parse(sessionStorage.getItem("user"));

const [name,setname]=useState("")
const [mobile,setmobile]=useState("")
const [email,setemail]=useState("")
const [gender,setgender]=useState("")
const [dob,setdob]=useState("")
const [state1,setstate1]=useState("")
const [city,setcity]=useState("")
const [branch,setbranch]=useState("")

const [address,setaddress]=useState("")
const [pincode,setpincode]=useState("");
const [photo,setphoto]=useState("");
const [idproof,setidproof]=useState("");
const [medicleProof,setmedicleProof]=useState("");
  const [allBranch,setAllBranc]=useState([]);
  const getAllBranch=async()=>{
    try {
      let res=await axios.get("https://jaidrivingschool.in/api/admin/getAllBranch");
      if(res.status==200){
        setAllBranc(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [allCity,setAllCity]=useState([])
  const getAllcity=async()=>{
    try {
      let res=await axios.get("https://jaidrivingschool.in/api/admin/getAllCity");
      if(res.status==200){
        setAllCity(res.data.success)
        getAllBranch()
      }
    } catch (error) {
      console.log(error);
    }
  }
//   const [branch,setbranch]=useState({})
useEffect(()=>{
    getAllBranch()
    getAllcity()
  if(user){
    setname(user?.name);
    setmobile(user?.mobile);
    setemail(user?.email);
    setgender(user?.gender);
    setdob(user?.dob);
    setstate1(user?.state);
    setcity(user?.city);
    setaddress(user?.Address);
    setpincode(user?.pincode);
    setphoto(user?.profile);
    setidproof(user?.addhar);
    setmedicleProof(user?.medicalproof);
  }
},[]);
const [courseData,setCourseData]=useState(state)
const updateUser=async()=>{
  try {
    let obj={userId:user?._id, name:name, mobile:mobile, email:email ,city:city,area:branch,courseData:courseData,passportcopy:photo,addhar:idproof,medicalproof:medicleProof,dob:dob,Address:address,gender:gender,area:branch,state:state1,pincode:pincode}
    if(!name) return alert("Please fill the blank");
    if(!email) return alert("Please fill the blank");
    if(!mobile) return alert("Please fill the blank");
    if(!gender | !dob| !city | !branch| !address | !pincode | !photo | !idproof ) return alert("Please complete the form")
    const config={
      url: "/customer/updateUser",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: { "content-type": "multipart/form-data"},
      data:obj
    }
    let res=await axios(config);
    if(res.status==200){
      await sessionStorage.setItem("user",JSON.stringify(res.data.success))
      navigate("/timslot",{state:{data1:state,branch:branch}})
    }

  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}

//   const Addenquire = async () => {
//     try {
//       if (!name) return alert("Please enter your name");
//       if (!mobile) return alert("Please enter mobile number");
//       if (!email) return alert("Please enter your email");
//       if (!comment) return alert("Please enter your comment");
//       const config = {
//         url: "/admin/postEnquire",
//         method: "post",
//         baseURL: "https://jaidrivingschool.in/api",
//         headers: { "content-type": "application/json" },
//         data: {
//           name: name,
//           email: email,
//           mobile: mobile,
//           EnquireType: `About course Name:- ${state?.courseName}`,
//           comments: comment,
//         },
//       };
//       let res = await axios(config);
//       if (res.status == 200) {
//         alert("Team will be connect as soon");
//         window.location.assign("/");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
  const [AllAditional, setAllAditional] = useState([]);
  const getAllAditional = async () => {
    try {
      const res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllAdditional"
      );
      if (res.status == 200) {
        setAllAditional(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllAditional();
    if (user) {
      setname(user.name);
      setemail(user?.email);
      setmobile(user?.mobile);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <div
          style={{
            height: "130px",
            background: "black",
            overflow: " hidden",
            textAlign: "center",
            justifyContent: "center",
            background: " #C33764",
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
                         url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2 style={{ color: "white", marginTop: "3%" }}>
            {state?.courseName}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/" style={{ color: "black" }}>
                Home
              </a>{" "}
              <span style={{ color: "black" }}>&raquo;</span>
            </li>
            <li>
              <a href="/courses" style={{ color: "black" }}>
                Courses
              </a>{" "}
              <span style={{ color: "black" }}>&raquo;</span>
            </li>
            <li>
              <a style={{ color: "black" }}>CoursesDetails</a>{" "}
              <span style={{ color: "black" }}></span>
            </li>
          </ul>
        </div>
        <div className="col-md-4">
          <span>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-warning"
              style={{ float: "right", margin: "11px 44px 0px 0px" }}
            >
              Back
            </a>
          </span>
        </div>
      </div>

      <div className="container-fluid mt-3 mb-5">
        <h1>Course Topic</h1>
        <div className="row ">
          <div className="col-md-7">
            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  {/* <th>S.No</th> */}
                  <th>Day</th>
                  <th>Course Topic</th>
                </tr>
              </thead>
              <tbody>
                {state?.courseTopic?.map((ele, i) => {
                  return (
                    <tr>
                      {/* <td>{i + 1}</td> */}
                      <td>{ele?.days} day</td>
                      <td>{ele?.topicName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="col-md-4">
            <div className="col-md-12 bg-11">
              <h5>Course Content</h5>
              {/* <form
                action=""
                className="form1"
                style={{ textAlign: "center", alignItems: "center" }}
              >
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
                <br />
                <label htmlFor="">E-mail ID</label>
                <input
                  type="email"
                  placeholder="Enter your e-mail"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <br />
                <label htmlFor="">Mobile no</label>
                <input
                  type="number"
                  placeholder="Enter your number"
                  value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                />
                <br />
                <textarea
                  name=""
                  id=""
                  cols="40"
                  placeholder="Message....."
                  onChange={(e) => setcomment(e.target.value)}
                ></textarea>
                <p className="btn btn-warning mt-1" onClick={Addenquire}>
                  Send Request
                </p>
                <br />
              </form> */}
              <div className="row">
                <div className="col-md-6   ">
                  <div className="row  ">
                    <div className="col-md-6">
                      <img
                        src="/images/timer.png"
                        alt=""
                        width={50}
                        height={50}
                      />
                      <h6>
                        Theory Sessions:&nbsp;{state?.TheorySessions}hours
                      </h6>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <img
                        src="/images/timer.png"
                        alt=""
                        width={50}
                        height={50}
                      />
                      <h6>
                        Practical Sessions:&nbsp;{state?.PracticalSessions}hours
                      </h6>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <img
                        src="/images/timer.png"
                        alt=""
                        width={50}
                        height={50}
                      />
                      <h6>Demo Sessions:&nbsp;{state?.Demo}Minutes</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="row   ">
                    <div className="col-md-6">
                      <img
                        src="/images/timer.png"
                        alt=""
                        width={50}
                        height={50}
                      />
                      <h6>
                        Simulator Sessions:&nbsp;{state?.SimulatorSessions}hours
                      </h6>
                    </div>
                  </div>
                  <div className="row  ">
                    <div className="col-md-6">
                      <img
                        src="/images/timer.png"
                        alt=""
                        width={50}
                        height={50}
                      />
                      <h6>Theory Exam:&nbsp;{state?.TheoryExam}Minutes</h6>
                    </div>
                  </div>
                  <div className="row  ">
                    <div className="col-md-6">
                      <img
                        src="/images/timer.png"
                        alt=""
                        width={50}
                        height={50}
                      />
                      <h6>
                        Practical Exam:&nbsp;{state?.PracticalExam}Minutes
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <h1>More About Courses</h1>
          <div className="row">
            <div className="col-md-8">
              {state?.Description ? <h5>{parse(state?.Description)}</h5> : ""}
            </div>
            <div className="col-md-4">
                <h5>Additional</h5>
              <Table>
                <thead>
                  <tr>
                    {/* <th>S.No</th> */}
                    <th>Service </th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                {AllAditional?.map((ele, i) => {
                  return (
                    <tr>
                      {/* <td>{i + 1}</td> */}
                      <td>{ele?.text}</td>
                      <td>₹ {ele?.price}</td>
                    </tr>
                  );
                })}</tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10">
                <h4>   Total Price:- ₹ {state?.price?.toFixed(2)}</h4>
             
              <h6>
                So what are you waiting for?{" "}
                <a href="/register">Register now</a>
              </h6>
            </div>
            
          </div>
          <div className="row  " style={{float:"right"}}>
          <div className="col-md-12 " >
                <button type="submit" className="btn btn-warning mb-2" onClick={handleShow}>Book Now</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill the Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-10">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Full name"
                  aria-label="Full name"
                  value={name}
                  onChange={(e)=>setname(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-5">
                <label htmlFor="">Email ID</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e)=>setemail(e.target.value)}
                  class="form-control"
                  placeholder="Email"
                  aria-label="Email"
                />
              </div>
              <div class="col-md-5">
                <label htmlFor="">Mobile</label>
                <input
                  type="number" value={mobile}
                  onChange={(e)=>setmobile(e.target.value)}
                  class="form-control"
                  placeholder="Mobile"
                  aria-label="Mobile"
                />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class="col-md-5">
                <label htmlFor="">Gender</label>
                <select
                  class="form-select "
                  aria-label=".form-select-lg example"
                  onChange={(e)=>setgender(e.target.value)}
                >
                  <option >{gender ? (gender):("Select gender")} </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div class="col-md-5">
                <label htmlFor="">DOB</label>
                <input
                  type="date"
                  value={dob}
                  onChange={(e)=>setdob(e.target.value)}
                  class="form-control"
                  placeholder="Last name"
                  aria-label="Last name"
                />
              </div>
            </div>
          </div>

          <div className="do-sear mt-2">
            <div class="row me-0">
              <div className="" style={{ display: "flex" }}>
                <div class="col-md-5">
                  <label for="inputState" class="form-label">
                    City
                  </label>
                  <select id="inputState" class="form-select formselect" onChange={(e)=>setcity(e.target.value)}>
                    <option selected>{user?.city ? (city): ("Choose...")}</option>
                    {allCity?.map((item)=>{
                      return (
                        <option>{item?.city}</option>
                      )
                    })}
                  
                  </select>
                </div>
                <div class="col-md-5">
                  <label for="inputState" class="form-label ms-2">
                  Branch
                  </label>
                  <select  class="form-select ms-2 formselect" onChange={(e)=> setbranch(JSON.parse(e.target.value))}>
                    <option >Choose...</option>
                    <option value={JSON.stringify({branchName:"Main Branch"})}>Main Branch</option>
                    {allBranch?.filter((ele)=>ele?.city==city)?.map((item)=>{
                      return (
                         <option value={JSON.stringify(item)}>{item.branchName},{item?.area}</option>
                      )
                    })}
                   
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div class=" col-md-5">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Address
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  placeholder="Please enter your address"
                  value={address}
                  onChange={(e)=>setaddress(e.target.value)}
                ></textarea>
              </div>
              <div class="col-md-5">
                <label for="inputZip" class="form-label">
                  Zip
                </label>
                <input type="text" placeholder="Please enter zip" value={pincode} onChange={(e)=>setpincode(e.target.value)} class="form-control" id="inputZip" />
              </div>
            </div>
          </div>
          <div className="do-sear mt-2">
            <div class="row me-0">
              <div style={{ display: "flex" }}>
                <div class=" col-md-5">
                  <label for="formFile" class="form-label" htmlFor="upload1">
                   Passport Photo
                  </label>
                  <input
                    class="form-control formselect"
                    type="file"
                    id="upload1"
                    accept="image/*"
                    onChange={(e)=>setphoto(e.target.files[0])}
                  />
                </div>
                <div class=" col-md-5">
                  <label for="formFile" class="form-label" htmlFor="upload2">
                     Addhar
                  </label>
                  <input
                    class="form-control formselect"
                    type="file"
                    id="upload2"
                    onChange={(e)=>setidproof(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row me-0">
            <div class="col-md-5">
              <label for="formFile" class="form-label" htmlFor="upload3">
                Medical Certificate
              </label>
              <input class="form-control" type="file" id="upload3"  onChange={(e)=>setmedicleProof(e.target.files[0])}/>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() =>updateUser()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CourseViewMore;
