import React from 'react'
import { useNavigate } from 'react-router-dom'

const PopUp = () => {
    const navigate = useNavigate()
    return (
        <div className='main'>
            <div className="div-01">
                <div
                    class="module mid"
                    style={{
                        height: "130px",
                        background: "black",
                        overflow: " hidden",
                        background: " #C33764",
                        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
                        backgroundSize: "cover",
                    }}
                >
                    <h2>Courses Details</h2>
                    <ul
                        class=" container-fluid breadcrumbs bc3 pt-3 pb-3"
                        role="menubar"
                        aria-label="breadcrumbs"
                    >
                        <li>
                            <a href="/">Home</a>{" "}
                            <span style={{ color: "white" }}>&raquo;</span>
                        </li>
                        <li>
                             <a href="/courses">&raquo;Courses</a>{" "}
                            <span style={{ color: "white" }}></span></li>
                        <li>
                            <a href="/popup">&raquo;Courses Details</a>{" "}
                            <span style={{ color: "white" }}></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='container-fluid mt-4'>
                <div className='h-01'>
                    <h3>Learn to Drive with Confidence</h3><br />
                    <h6>An extended version of the learner driving course, the objective here is to provide more practical training through additional driving classes. At the end of 26 days, you'll be well-versed with the basic traffic rules and have a hands-on driving experience through a balanced mix of simulator sessions and on-road driving. Even more importantly, you'll be a lot more confident while taking the RTO driving exam to obtain a permanent driving license.</h6><br />

                    <br />
                </div>

            </div>

            <div className='container-fluid'>
                <div className='h-001'>
                    <div className='h-002'>
                        <div className='h-003'>
                            <img src="/images/popup.jpg" alt=""style={{width:"376px"}} />
                        </div>
                        <div className='h-004'>
                            <h4>Advanced Course</h4>
                            <h6>This driving course is for individuals who want to drive professionally as well as for executives who want to hire a driver and test their driving skills before doing so. The course tests a driver on 25 different parameters, including theory and practical. By the end of it, an individual will be able to drive cabs. If you are an executive who wants to hire a full-time driver, this test will help you assess a candidate's skills before you hire them. Enroll in Maruti Suzuki Driving School to know more about it.</h6>
                            <div className='h-005'>
                                <div className='h-006 '>
                                    <h3>15</h3>
                                    <h6>practical <br />sessions</h6>

                                </div>
                                <div className='h-006'>
                                    <h3>5</h3>
                                    <h6>simulator<br /> sessions</h6>
                                </div>
                                <div className='h-006'>
                                    <h3>4</h3>
                                    <h6>theory <br />sessions</h6>
                                </div>
                                
                            </div>
                            <div className='h-007 mb-3'>
                            <button type='submit' class="btn btn-warning" onClick={() => navigate("/courseviewmore")} style={{ height: "40px", marginLeft: "10px"}}>View More</button>

                                <button type='submit' class="btn btn-warning " onClick={() => navigate("/register")} id="cbtn2">I am Interested</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />



            <div className='container-fluid'>
                <div className='h-001'>
                    <div className='h-002'>
                        <div className='h-003'>
                            <img src="/images/popup.jpg" alt="" style={{width:"376px"}} />
                        </div>
                        <div className='h-004'>
                            <h4>Advanced Course</h4>
                            <h6>This driving course is for individuals who want to drive professionally as well as for executives who want to hire a driver and test their driving skills before doing so. The course tests a driver on 25 different parameters, including theory and practical. By the end of it, an individual will be able to drive cabs. If you are an executive who wants to hire a full-time driver, this test will help you assess a candidate's skills before you hire them. Enroll in Maruti Suzuki Driving School to know more about it.</h6>
                            <div className='h-005'>
                                <div className='h-006 '>
                                    <h3>15</h3>
                                    <h6>practical <br />sessions</h6>

                                </div>
                                <div className='h-006'>
                                    <h3>5</h3>
                                    <h6>simulator<br /> sessions</h6>
                                </div>
                                <div className='h-006'>
                                    <h3>4</h3>
                                    <h6>theory <br />sessions</h6>
                                </div>
                            </div>
                            <div className='h-007 mb-3'>
                            <button type='submit' class="btn btn-warning" onClick={() => navigate("/courseviewmore")} style={{ height: "40px", marginLeft: "10px" }}>View More</button>
                                <button type='submit' class="btn btn-warning " id="cbtn2" onClick={() => navigate("/register")}>I am Interested</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />
            <br />

            <div className='container-fluid'>
                <div className='h-001'>
                    <div className='h-002'>
                        <div className='h-003'>
                            <img src="/images/popup.jpg" alt="" style={{width:"376px"}} />
                        </div>
                        <div className='h-004'>
                            <h4>Advanced Course</h4>
                            <h6>This driving course is for individuals who want to drive professionally as well as for executives who want to hire a driver and test their driving skills before doing so. The course tests a driver on 25 different parameters, including theory and practical. By the end of it, an individual will be able to drive cabs. If you are an executive who wants to hire a full-time driver, this test will help you assess a candidate's skills before you hire them. Enroll in Maruti Suzuki Driving School to know more about it.</h6>
                            <div className='h-005'>
                                <div className='h-006 '>
                                    <h3>15</h3>
                                    <h6>practical <br />sessions</h6>

                                </div>
                                <div className='h-006'>
                                    <h3>5</h3>
                                    <h6>simulator<br /> sessions</h6>
                                </div>
                                <div className='h-006'>
                                    <h3>4</h3>
                                    <h6>theory <br />sessions</h6>
                                </div>
                            </div>
                            <div className='h-007 mb-3'>
                            <button type='submit' class="btn btn-warning" onClick={() => navigate("/courseviewmore")} style={{ height: "40px", marginLeft: "10px" }}>View More</button>
                                <button type='submit' class="btn btn-warning " id="cbtn2" onClick={() => navigate("/register")}>I am Interested</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />

            <div className='container-fluid'>
                <div className='h-001'>
                    <div className='h-002'>
                        <div className='h-003'>
                            <img src="/images/popup.jpg" alt="" style={{width:"376px"}} />
                        </div>
                        <div className='h-004'>
                            <h4>Advanced Course</h4>
                            <h6>This driving course is for individuals who want to drive professionally as well as for executives who want to hire a driver and test their driving skills before doing so. The course tests a driver on 25 different parameters, including theory and practical. By the end of it, an individual will be able to drive cabs. If you are an executive who wants to hire a full-time driver, this test will help you assess a candidate's skills before you hire them. Enroll in Maruti Suzuki Driving School to know more about it.</h6>
                            <div className='h-005'>
                                <div className='h-006 '>
                                    <h3>15</h3>
                                    <h6>practical <br />sessions</h6>

                                </div>
                                <div className='h-006'>
                                    <h3>5</h3>
                                    <h6>simulator<br /> sessions</h6>
                                </div>
                                <div className='h-006'>
                                    <h3>4</h3>
                                    <h6>theory <br />sessions</h6>
                                </div>
                            </div>
                            <div className='h-007 mb-3'>
                            <button type='submit' class="btn btn-warning" onClick={() => navigate("/courseviewmore")} style={{ height: "40px", marginLeft: "10px" }}>View More</button>
                                <button type='submit' class="btn btn-warning " id="cbtn2" onClick={() => navigate("/register")}>I am Interested</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />
            <br />


            <div className='container-fluid'>
                <div className='h-001'>
                    <div className='h-002'>
                        <div className='h-003'>
                            <img src="/images/popup.jpg" alt="" style={{width:"376px"}} />
                        </div>
                        <div className='h-004'>
                            <h4>Advanced Course</h4>
                            <h6>This driving course is for individuals who want to drive professionally as well as for executives who want to hire a driver and test their driving skills before doing so. The course tests a driver on 25 different parameters, including theory and practical. By the end of it, an individual will be able to drive cabs. If you are an executive who wants to hire a full-time driver, this test will help you assess a candidate's skills before you hire them. Enroll in Maruti Suzuki Driving School to know more about it.</h6>
                            <div className='h-005'>
                                <div className='h-006 '>
                                    <h3>15</h3>
                                    <h6>practical <br />sessions</h6>

                                </div>
                                <div className='h-006'>
                                    <h3>5</h3>
                                    <h6>simulator<br /> sessions</h6>
                                </div>
                                <div className='h-006'>
                                    <h3>4</h3>
                                    <h6>theory <br />sessions</h6>
                                </div>
                            </div>
                            <div className='h-007 mb-3'>
                            <button type='submit' class="btn btn-warning" onClick={() => navigate("/courseviewmore")} style={{ height: "40px", marginLeft: "10px" }}>View More</button>
                                <button type='submit' class="btn btn-warning " id="cbtn2">I am Interested</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopUp