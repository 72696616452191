import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BiEdit, BiUserCircle } from "react-icons/bi";
import axios from "axios";
import StarRatings from "react-star-ratings";
import parse from "html-react-parser";
import { AiFillEye } from "react-icons/ai";

import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";

const AdminFeedBack = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  const [AllCustomer, setAllCustomer] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getCustomer = async () => {
    try {
      const config = {
        url: "/admin/getFeedback",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [allBranch, setAllBranc] = useState([]);

  const getAllBranch = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranch"
      );
      if (res.status == 200) {
        setAllBranc(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [allCity, setAllCity] = useState([]);
  const getAllcity = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllCity"
      );
      if (res.status == 200) {
        setAllCity(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (token) {
      getCustomer();
      getAllcity();
      getAllBranch();
    }
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllCustomer.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllCustomer([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllCustomer([...nochangedata]);
    }
  };

  const makeBlockUnblockCustomer = async (id, block) => {
    try {
      const config = {
        url: "/Customer/makeBlockUnblockCustomer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          id: id,
          isBlocked: block,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert(res.data.success);
        getCustomer();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [fatheName, setfatheName] = useState("");
  const [user, setUsername] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [rno, setRno] = useState("");
  const [userType, setuserType] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [city, setcity] = useState("");
  const [School, setschool] = useState("");
  const [profile, setprofile] = useState("");
  const [branch, setbranch] = useState({});
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState("");
  const [pincode, setpincode] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [learnType, setlearnType] = useState("I Want to Learn Driving");



  const handleSubmit = async () => {
    try {
      if (!user) return alert("Please enter your name");
      if (!email) return alert("Please enter your email");
      if (!mobile) return alert("Please enter your mobile");

      if (!city) return alert("Please select city");

      if (Object.keys(branch).length == 0) return alert("Please select branch");
      let ab = "";
      if (branch?.branchName == "Main Branch") {
        ab = `Main Branch`;
      } else {
        ab = `${branch?.branchName},${branch?.area}`;
      }
      if (!userType) return alert("Please select user type");
      if (!password) return alert("Please enter your password");
      if (!cpassword) return alert("Please enter confirm password");
      if (password !== cpassword)
        return alert("Confirm password did not match");

      const config = {
        url: "/customer/register-customer",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          name: user,
          mobile: mobile,
          email: email,
          Password: password,
          fatheName: fatheName,
          profile: profile,
          city: city,
          area: ab,
          gender: gender,
          dob: dob,
          pincode: pincode,
          Address: address1,
          Address1: address2,
          userType: userType,
          learnType: learnType,
        },
      };

      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        handleClose()
        getCustomer()
        setprofile("")
        setpassword("")
        setcpassword("")
        setbranch({})
        setfatheName("")
        setUsername("")
        setemail("")
        setmobile("")
     
        setcity("")
        setdob("")
        setpincode("")
        setaddress1("")
        setaddress2("")
        setlearnType("")
       
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
 

  const [showEdit,setshowEdit]=useState(false);
const [editdata,seteditdata]=useState({});

  const handleEdit=(data)=>{
    setfatheName(data?.fatheName)
    setUsername(data?.name)
    setdob(data.dob)
    setcity(data?.city)
    setpincode(data?.pincode)
    setaddress1(data.Address)
    setaddress2(data?.Address1)
    setlearnType(data?.learnType)
    setReferFor(data?.ReferFor);
    setReferTo(data?.ReferTo);
    setshowEdit(true);
  }
const [ReferTo,setReferTo]=useState("");
const [ReferFor,setReferFor]=useState("");
  const closeEdit=()=>setshowEdit(false);

  const updateCustomer = async () => {
    try {
      let ab = "";
      if (Object.keys(branch).length !== 0) {
        if (branch?.branchName == "Main Branch") {
          ab = `Main Branch`;
        } else {
          ab = `${branch?.branchName},${branch?.area}`;
        }
      }
      if (password || cpassword) {
        if (!password) return alert("Please enter your password");
        if (!cpassword) return alert("Please enter confirm password");
        if (password !== cpassword)
          return alert("Confirm password did not match");
      }

      const config = {
        url: "/customer/updateUser",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          userId:editdata?._id,
          name: user,
          mobile: mobile,
          email: email,
          Password: password,
          fatheName: fatheName,
          profile: profile,
          city: city,
          area: ab,
          gender: gender,
          dob: dob,
          pincode: pincode,
          Address: address1,
          Address1: address2,
          userType: userType,
          learnType: learnType,
          ReferTo:ReferTo,
          ReferFor:ReferFor
        },
      };

      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Upadated");
        closeEdit()
        getCustomer()
        setprofile("")
        setpassword("")
        setcpassword("")
        setbranch({})
        setfatheName("")
        setUsername("")
        setemail("")
        setmobile("")
        setuserType("")
        setcity("")
        setdob("")
        setpincode("")
        setaddress1("")
        setaddress2("")
        setlearnType("")
        setReferTo("");
        setReferFor("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
    return (
      <div>
        <>
          <div className="add-gr">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="ad-b mt-4">
                    <h3>Customer/Student Feedback</h3>
                  </div>
                </div>
                {/* <div className="col-md-9">
                  <div className="ad-b mt-4">
                    <button
                      style={{ borderRadius: "10px", float: "right" }}
                      onClick={() => {
                    
                        setprofile("")
                        setpassword("")
                        setcpassword("")
                        setbranch({})
                        setfatheName("")
                        setUsername("")
                        setemail("")
                        setmobile("")
                        setuserType("")
                        setcity("")
                        setdob("")
                        setpincode("")
                        setaddress1("")
                        setaddress2("")
                        setReferTo("");
                        setReferFor("");
                        setlearnType("")
                        handleShow();
                      }}
                    >
                      +Add
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="container">
              <div className="mo-gra mt-5">
                <div className="row">
                  <div className="col-3">
                    <input
                      type="text"
                      placeholder="Search...."
                      className="vi_0"
                      style={{ marginLeft: "-20px" }}
                      onChange={(e) => handleFilter(e)}
                    />
                  </div>
                </div>
                <div className="scrolll_bar mb-4">
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Id</th>
                        {/* <th>Branch</th> */}
                        <th>User Profile</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Image</th>
                        <th>Video</th>
                        <th>Course Name</th>
                        <th>Comment</th>
                        <th>Rating</th>
                        {/* <th>Referel For Amount</th>
                        <th>Rating</th>
                        <th>view</th>
                        <th>Status</th>
                        <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {AllCustomer?.slice(
                        pagesVisited,
                        pagesVisited + usersPerPage
                      )?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item?.userId?.customerId}</td>
                            {/* <td>{item?.area}</td> */}
                            <td>
                              {item?.userId?.profile ? (
                                <img
                                  src={`https://jaidrivingschool.in/Customer/${item?.userId?.profile}`}
                                  alt=""
                                  width={60}
                                />
                              ) : (
                                <BiUserCircle
                                  style={{
                                    color: "rgb(252 202 11)",
                                    fontSize: "40px",
                                  }}
                                />
                              )}
                            </td>
                            <td>{item?.userId?.name}</td>

                            <td>{item?.userId?.mobile}</td>
                            <td>  {item?.image ? (<a href={`https://jaidrivingschool.in/review/${item?.image}`} target="_blank">
                                <img
                                  src={`https://jaidrivingschool.in/review/${item?.image}`}
                                  alt=""
                                  width={60}
                                /></a>):(<></>)}</td>
                            <td>  {item?.video ? (<a href={`https://jaidrivingschool.in/review/${item?.video}`} target="_blank">
                                <img
                                  src={`https://jaidrivingschool.in/review/${item?.video}`}
                                  alt=""
                                  width={60}
                                /></a>):(<></>)}</td>
                                <td>{item?.courseName}</td>
                            <td>{item?.text ?(parse(item?.text)):""}</td>
                            <td> <StarRatings
        rating={item?.ratting}
        starDimension="20px"
        // changeRating={setrating}
        starSpacing="2px"
        starRatedColor="#fcca0b"
      /></td>
                          
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="d-flex gap-2"
                style={{ justifyContent: "space-around" }}
              >
                <div
                  className="d-flex gap-2 "
                  style={{ alignItems: "baseline" }}
                >
                  <input
                    type="checkbox"
                    checked={learnType == "I Want to Learn Driving"}
                    value="I Want to Learn Driving"
                    onChange={(e) => setlearnType(e.target.value)}
                    style={{ width: "12px" }}
                  />
                  <label
                    className="mt-2 form-check-label"
                    for="flexCheckDefault"
                    style={{ fontSize: "14px" }}
                  >
                    I Want to Learn Driving
                  </label>
                </div>

                <div
                  className="d-flex gap-2 "
                  style={{ alignItems: "baseline" }}
                >
                  <input
                    type="checkbox"
                    checked={learnType == "I Want to Take Services"}
                    value="I Want to Take Services"
                    onChange={(e) => setlearnType(e.target.value)}
                    style={{ width: "12px" }}
                  />
                  <label
                    className="mt-2 form-check-label"
                    for="flexCheckDefault"
                    style={{ fontSize: "14px" }}
                  >
                    I Want to Take Services
                  </label>
                </div>
              </div>
              <div class="row me-0">
                <div className="do-sear mt-2">
                  <label htmlFor="upload1">Image</label>
                  <input
                    type="File"
                    id="upload1"
                    name="file"
                    accept="/image*"
                    onChange={(e) => setprofile(e.target.files[0])}
                    placeholder="Enter Userid"
                    className="vi_0"
                  />
                </div>
              </div>

              <div class="row me-0">
                <div className="do-sear mt-2">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="vi_0"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>

              <div class="row me-0">
                <div className="do-sear mt-2">
                  <label> Father/Spouse Name</label>
                  <input
                    type="text"
                    placeholder="Enter  Father/Spouse Name"
                    className="vi_0"
                    onChange={(e) => setfatheName(e.target.value)}
                  />
                </div>
              </div>

              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label htmlFor="">Email ID</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      class="form-control"
                      placeholder="Email"
                      aria-label="Email"
                    />
                  </div>
                  <div class="col-md-6">
                    <label htmlFor="">Mobile</label>
                    <input
                      type="number"
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                      class="form-control"
                      placeholder="Mobile"
                      aria-label="Mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label htmlFor="">Gender</label>
                    <select
                      class="form-select "
                      aria-label=".form-select-lg example"
                      onChange={(e) => setgender(e.target.value)}
                    >
                      <option>{"Select gender"} </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label htmlFor="">DOB</label>
                    <input
                      type="date"
                      value={dob}
                      onChange={(e) => setdob(e.target.value)}
                      class="form-control"
                      placeholder="Last name"
                      aria-label="Last name"
                    />
                  </div>
                </div>
              </div>

              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div className="" style={{ display: "flex" }}>
                    <div class="col-md-6">
                      <label for="inputState" class="form-label">
                        City
                      </label>
                      <select
                        id="inputState"
                        class="form-select formselect"
                        onChange={(e) => setcity(e.target.value)}
                      >
                        <option selected>{"Choose..."}</option>
                        {allCity?.map((item) => {
                          return <option>{item?.city}</option>;
                        })}
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="inputState" class="form-label ms-2">
                        Branch
                      </label>
                      <select
                        class="form-select ms-2 formselect"
                        onChange={(e) => setbranch(JSON.parse(e.target.value))}
                      >
                        <option>Choose...</option>
                        <option
                          value={JSON.stringify({ branchName: "Main Branch" })}
                        >
                          Main Branch
                        </option>
                        {allBranch
                          ?.filter((ele) => ele?.city == city)
                          ?.map((item) => {
                            return (
                              <option value={JSON.stringify(item)}>
                                {item.branchName},{item?.area}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class=" col-md-6">
                    <label for="exampleFormControlTextarea1" class="form-label">
                      Address
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Please enter your address"
                      value={address1}
                      onChange={(e) => setaddress1(e.target.value)}
                    ></textarea>
                  </div>
                  <div class=" col-md-6">
                    <label for="exampleFormControlTextarea1" class="form-label">
                      Address (Optional)
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Please enter your address"
                      value={address2}
                      onChange={(e) => setaddress2(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Pincode
                    </label>
                    <input
                      type="number"
                      placeholder="Please enter pincode"
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      User Type
                    </label>
                    <select
                      name=""
                      class="form-control"
                      id="inputZip"
                      onChange={(e) => setuserType(e.target.value)}
                    >
                      <option value="">----Select User Type-----</option>
                      <option value="Student">Student</option>
                      <option value="Customer">Customer</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      placeholder="Please enter password"
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="text"
                      placeholder="Please enter confirm password"
                      value={cpassword}
                      onChange={(e) => setcpassword(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>

          {/* update customer */}
          <Modal
            show={showEdit}
            onHide={closeEdit}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="d-flex gap-2"
                style={{ justifyContent: "space-around" }}
              >
                <div
                  className="d-flex gap-2 "
                  style={{ alignItems: "baseline" }}
                >
                  <input
                    type="checkbox"
                    checked={learnType == "I Want to Learn Driving"}
                    value="I Want to Learn Driving"
                    onChange={(e) => setlearnType(e.target.value)}
                    style={{ width: "12px" }}
                  />
                  <label
                    className="mt-2 form-check-label"
                    for="flexCheckDefault"
                    style={{ fontSize: "14px" }}
                  >
                    I Want to Learn Driving
                  </label>
                </div>

                <div
                  className="d-flex gap-2 "
                  style={{ alignItems: "baseline" }}
                >
                  <input
                    type="checkbox"
                    checked={learnType == "I Want to Take Services"}
                    value="I Want to Take Services"
                    onChange={(e) => setlearnType(e.target.value)}
                    style={{ width: "12px" }}
                  />
                  <label
                    className="mt-2 form-check-label"
                    for="flexCheckDefault"
                    style={{ fontSize: "14px" }}
                  >
                    I Want to Take Services
                  </label>
                </div>
              </div>
              <div class="row me-0">
                <div className="do-sear mt-2">
                  <label htmlFor="upload1">Image</label>
                  <input
                    type="File"
                    id="upload1"
                    name="file"
                    accept="/image*"
                    onChange={(e) => setprofile(e.target.files[0])}
                    placeholder="Enter Userid"
                    className="vi_0"
                  />
                </div>
              </div>

              <div class="row me-0">
                <div className="do-sear mt-2">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="vi_0"
                    value={user}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>

              <div class="row me-0">
                <div className="do-sear mt-2">
                  <label> Father/Spouse Name</label>
                  <input
                    type="text"
                    placeholder="Enter  Father/Spouse Name"
                    className="vi_0"
                    value={fatheName}
                    onChange={(e) => setfatheName(e.target.value)}
                  />
                </div>
              </div>

              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label htmlFor="">Email ID</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      class="form-control"
                      placeholder={editdata?.email}
                      aria-label="Email"
                    />
                  </div>
                  <div class="col-md-6">
                    <label htmlFor="">Mobile</label>
                    <input
                      type="number"
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                      class="form-control"
                      placeholder={editdata?.mobile}
                      aria-label="Mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label htmlFor="">Gender</label>
                    <select
                      class="form-select "
                      aria-label=".form-select-lg example"
                      onChange={(e) => setgender(e.target.value)}
                    >
                      <option>{editdata?.gender ? (editdata?.gender ):("Select gender")} </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label htmlFor="">DOB</label>
                    <input
                      type="date"
                      value={dob}
                      onChange={(e) => setdob(e.target.value)}
                      class="form-control"
                      placeholder="Last name"

                      aria-label="Last name"
                    />
                  </div>
                </div>
              </div>

              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div className="" style={{ display: "flex" }}>
                    <div class="col-md-6">
                      <label for="inputState" class="form-label">
                        City
                      </label>
                      <select
                        id="inputState"
                        class="form-select formselect"
                        onChange={(e) => setcity(e.target.value)}
                      >
                        <option selected>{editdata?.city? (editdata?.city):("Choose...")}</option>
                        {allCity?.map((item) => {
                          return <option>{item?.city}</option>;
                        })}
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="inputState" class="form-label ms-2">
                        Branch
                      </label>
                      <select
                        class="form-select ms-2 formselect"
                        onChange={(e) => setbranch(JSON.parse(e.target.value))}
                      >
                        <option>{editdata?.area? (editdata?.area):("Choose...")}</option>
                        <option
                          value={JSON.stringify({ branchName: "Main Branch" })}
                        >
                          Main Branch
                        </option>
                        {allBranch
                          ?.filter((ele) => ele?.city == city)
                          ?.map((item) => {
                            return (
                              <option value={JSON.stringify(item)}>
                                {item.branchName},{item?.area}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class=" col-md-6">
                    <label for="exampleFormControlTextarea1" class="form-label">
                      Address
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Please enter your address"
                      value={address1}
                      onChange={(e) => setaddress1(e.target.value)}
                    ></textarea>
                  </div>
                  <div class=" col-md-6">
                    <label for="exampleFormControlTextarea1" class="form-label">
                      Address (Optional)
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Please enter your address"
                      value={address2}
                      onChange={(e) => setaddress2(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Pincode
                    </label>
                    <input
                      type="number"
                      placeholder="Please enter pincode"
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      User Type
                    </label>
                    <select
                      name=""
                      class="form-control"
                      id="inputZip"
                      onChange={(e) => setuserType(e.target.value)}
                    >
                      <option value="">{editdata?.userType ?(editdata?.userType):("----Select User Type-----")}</option>
                      <option value="Student">Student</option>
                      <option value="Customer">Customer</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Refer To
                    </label>
                    <input
                      type="number"
                      placeholder="Please enter Refer To"
                      value={ReferTo}
                      onChange={(e) => setReferTo(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Refer For
                    </label>
                    <input
                      type="number"
                      placeholder="Please enter Refer To"
                      value={ReferFor}
                      onChange={(e) => setReferFor(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>
                </div>
              </div>
              <div className="do-sear mt-2">
                <div class="row me-0">
                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      placeholder="Please enter password"
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputZip" class="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="text"
                      placeholder="Please enter confirm password"
                      value={cpassword}
                      onChange={(e) => setcpassword(e.target.value)}
                      class="form-control"
                      id="inputZip"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeEdit}>
                Close
              </Button>
              <Button variant="primary" onClick={updateCustomer}>
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    );
};

export default AdminFeedBack;
