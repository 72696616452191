import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { colors } from "@mui/material";

const Blog2 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg">
        <img src="./images/personal-blog.jpg" alt="" style={{position:"relative"}} />
      </div>
      <div className="con">
        <div className="container">
          <form action="" className="postblog" style={{position:"absolute",top:"150px"}}>
            <h3 style={{ color: "white" }}>
              Post Your Blog{" "}
              <span
                className="cross"
                style={{ marginLeft: "150px", color: "white" }}
                onClick={() => navigate("/blog")}
              >
                <CloseIcon  id="cross"/>
              </span>
            </h3>{" "}
            <label htmlFor="">Post From</label>
            <select name="" id="pf" >
              <option value="">-----select-----</option>
              <option value="">Instructor</option>
              <option value="">Staff</option>
              <option value=""> Student</option>
              <option value="">Customer</option>
            </select>{" "}
            <br />
            <label htmlFor="">Category</label>
            <select name="" id="cat" >
              <option value="">-----select-----</option>
              <option value="">Learning Licence</option>
              <option value="">Permanent Licence</option>
              <option value="">RC Transfer</option>
              <option value="">Driving School</option>
              <option value="">General Blog</option>
            </select>
            <br />
            <label htmlFor="">Enter Blog Tittle</label>
            <span className="bg-21">
              <input type="text" placeholder="Enter Tittle...." />
            </span>
            <label htmlFor="">Select Image/Video</label>
            <span className="bg-22">
              <input type="file" />
            </span>
            <br />
            <label htmlFor="">Enter Your Name</label>
            <span>
              <input type="text" placeholder="Enter Your Name...." id="in" />
            </span>
            <label htmlFor="">Enter Mobile No. </label>
            <span className="bg-23">
              <input
                type="number"
                placeholder="Enter Mobile Number...."
               
              />
            </span>{" "}
            <br />
            <label htmlFor="">Enter Email Id </label>
            <span className="bg-24">
              <input
                type="email"
                placeholder="Enter Email id...."
                
              />
            </span>
            <textarea
              name=""
              id=""
              cols="46"
              placeholder="Enter Your Text Here......" style={{width:"100%",marginTop:"10px"}}
            ></textarea>
            <br />
            <a
              href=""
              className="btn btn-warning postblogbtn"
              style={{ marginLeft: "150px" }}
            >
              Submit
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Blog2;
