import React, { useEffect } from "react";
import Side from "./sidebar/Side";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";

const StartClasses = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
const [booked,setbooked]=useState({});
const [topic,settopic]=useState("");
const [day,setday]=useState("");
const [time,settime]=useState("");
const [mobile,setmobile]=useState("");
const [date,setdate]=useState("");
const [student,setstudent]=useState("")
const changeStudent=(data)=>{
  if(data){
    setbooked(JSON.parse(data))
    setmobile(JSON.parse(data)?.number)
    setstudent(JSON.parse(data)?.customerName)
  }
}
  let trainer=JSON.parse(sessionStorage.getItem("staff"));
let token=sessionStorage.getItem("token");
const [AllTrainerBook,setAlltrainer]=useState([]);
const [nochangedata,setnochangedata]=useState([])
  const getbookDetailsByTrainerId=async()=>{
    try {
      const config = {
        url: "/admin/getbookDetailsByTrainerId/"+trainer?._id+"/"+trainer?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`,"content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
      
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [AllClass,setAllClass]=useState([]);
  const getclassByTrainerId=async()=>{
    try {
      let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassByTrainerId/"+trainer?._id);
      if(res.status==200){
        setAllClass(res.data.success);
        setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    if(trainer){
      getbookDetailsByTrainerId()
      getclassByTrainerId()
    }
  },[]);
  console.log("booked data",booked);

  const sendOtp=async(m)=>{
  
    try {
      const config = {
        url: '/cLoginSendOtp',
        method: 'post',
        baseURL: 'https://jaidrivingschool.in/api/admin/',
        headers: {'content-type': 'application/json'},
        data: {
          mobile: mobile? mobile:m
        },
      };
      let res=await axios(config);
      if(res.status==200){
        if(show==true){
          alert("Successfully resend otp")
        }
        handleShow()
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const [otp,setotp]=useState("");
  const ClassStart=async()=>{
    if(!student) return alert("Please select sudent");
    if(!topic) return alert("please select topic");
    if(!date) return alert("Please select date");
    if(!time) return alert("please select time");
    if(!day) return alert("Please select days");
    if(!mobile) return alert("please enter mobile");
    try {
   const config={
    url: '/addclass',
    method: 'post',
    baseURL: 'https://jaidrivingschool.in/api/admin',
    headers: {'content-type': 'multipart/form-data'},
    data: {
      StudentName:student,studentId:booked?._id,classTopic:topic,classday:day,selectdate:date,selectTime:time,customerId:booked?.customerId,trainerId:trainer?._id,mobile:mobile
    },
      }
      let res=await axios(config);
      if(res.status==200){
      alert("Successfully Added");
      setshowSheule(false);
      getclassByTrainerId()
      }
    } catch (error) {
      console.log(error);
    }
  }
const [ClassD,setClassD]=useState({})
  const verifyOtp=async()=>{
    try {
      if(!otp) return alert("Please enter otp")
      const config = {
        url: '/cLoginVerifyOtp',
        method: 'post',
        baseURL: 'https://jaidrivingschool.in/api/admin',
        headers: {'content-type': 'application/json'},
        data: {
          mobile: mobile,
          otp: otp,
        },
      };
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully verify")
        navigate("/classon",{state:{course:booked,classD:ClassD}})
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
   const pagesVisited = pageNumber * usersPerPage;
   const pageCount = Math.ceil(AllClass?.length / usersPerPage);
   const changePage = ({ selected }) => {
     setPageNumber(selected);
   };
   const [search, setSearch] = useState("");
   const handleFilter = (e) => {
     if (e.target.value != "") {
       setSearch(e.target.value);
       const filterTable = nochangedata.filter((o) =>
         Object.keys(o).some((k) =>
           String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
         )
       );
       setAllClass([...filterTable]);
     } else {
       setSearch(e.target.value);
       setAllClass([...nochangedata]);
     }
   };
const [showShedule,setshowSheule]=useState(false);

  return (
    <div>
      <Side />
      <div className="container">
        <div className="row ">
         <div className="cs">
         <div className="mt-3">
            <h4>Schedule Student Class List</h4>
            <div className="ad-b mt-4">
                  <button
                    style={{ borderRadius: "10px", float: "right" }}
                    onClick={()=>{
                    setshowSheule(true) 
                    }}
                  >
                    Schedule Class
                  </button>
                </div>
            <div className="row">
            <div className="col-3">
            <input type="text" placeholder="Search...." className="vi_0" style={{marginLeft:"-20px"}} onChange={(e)=>handleFilter(e)}/>
          </div>
            </div>
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th> Student Name</th>
                  <th>Course Details</th>
                  <th>Day</th>
                  <th>Topic Name</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Total Km</th>
                  <th>Status</th>
                  <th>Student Status </th>
                 
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllClass?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                  return (
                     <tr>
                  <td>{i+1}</td>
                  <td>{item?.customerId?.name}</td>
                  <td>{item?.studentId?.courseTitle}</td>
                  <td>{item?.classday}</td>
                  <td>{item?.classTopic}</td>
                  <td>{item?.selectdate}</td>
                  <td>{item?.selectTime}</td>
                  <td>{item?.endtime}</td>
                  <td>{item?.totalKm}</td>
                  <td>
                     {item?.status=="Pending" ? (<span style={{color:"blue"}}>{item?.status}</span>):(<span style={{color:"green"}}>{item?.status=="Assigned" ? (<span style={{color:"Red"}}>{item?.status}</span>):(<span>{item?.status}</span>)}</span>)}
                    </td>

                    <td>
                     {item?.studentStatus=="Pending" ? (<span style={{color:"blue"}}>{item?.studentStatus}</span>):(<span style={{color:"green"}}>{item?.studentStatus=="Assigned" ? (<span style={{color:"Red"}}>{item?.studentStatus}</span>):(<span>{item?.studentStatus}</span>)}</span>)}
                    </td>
                    
                    <td >
                    {item?.status=="Pending" ? (<span style={{display:"flex",gap:"2px"}}><button type="button" class="btn btn-outline-success" onClick={()=>{
                      setbooked(item?.studentId)
                      setstudent(item?.studentId?.customerName);
                      setmobile(item?.studentId?.number)
                      setClassD(item);
                      sendOtp(item?.studentId?.number);
                    }}>Start Class</button>
<button type="button" class="btn btn-outline-danger">Cancel</button>
</span>):(<span style={{color:"green"}}>{item?.status=="Assigned" ? (<span style={{color:"Red"}}>{item?.status}</span>):(<span>{item?.status}</span>)}</span>)}
                    </td>
                </tr>
                  )
                })}
               
              
               
              </tbody>
            </table>
            
              <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
          </div>
         </div>
        </div>
      </div>
  
      <Modal show={show} onHide={handleShow}>
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Enter The OTP</h6>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <input type="number" min="0" maxLength="6" className="vi_0" value={otp} onChange={(e)=>setotp(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="danger"
            onClick={() => sendOtp(mobile)}
            style={{ color: "black" }}
          >
            Resend
          </Button>
          <Button
            variant="success"
            onClick={() => verifyOtp()}
            style={{ color: "black" }}
          >
            Verify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showShedule} onHide={()=>setshowSheule(false)} size="lg">
        <Modal.Header closeButton>
          {/* <Modal.Title>OTP Verification</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form className="mt-3">
          <h2 class="mb-4">Select The Details To Schedule  The Class</h2>
          <div className="row">
            <div class=" col-md-6 mb-3">
              <label for="" class="form-label">
                Select Student
              </label>
              <select
                class="form-select form-select-sm"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e)=>changeStudent(e.target.value)}
              >
                <option selected>StudentsList</option>
                {AllTrainerBook?.filter((ele)=>ele?.status=="Confirm"||ele?.status=="Completed")?.map((item)=>{
                  return (
                    <option value={JSON.stringify(item)}>{item?.customerName}</option>
                  )
                })}
              </select>
            </div>
            <div class=" col-md-6 mb-3">
              <label for="" class="form-label">
                Select Topic
              </label>
              <select
                class="form-select form-select-sm"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e)=>settopic(e.target.value)}
              >
                <option selected>{topic? topic: "--select--"}</option>
                {booked?.courseTopic?.filter((ele)=> day ? ele?.days==day:ele)?.map((item)=>{
                  return (
                     <option value={item?.topicName}>{item?.topicName}</option>
                  )
                })}
               
               
              </select>
            </div>
          </div>
          <div className="row">
            <div class="col-md-6 mb-3">
              <label for="disabledSelect" class="form-label">
                Select Date
              </label>
              <input
                type="date"
                id="disabledTextInput"
                class="form-control"
                style={{ width: "270px" }}
                onChange={(e)=>setdate(e.target.value)}
              />
            </div>
            <div class="col-md-6 mb-3">
              <label for="disabledSelect" class="form-label">
                Select Time
              </label>
              <input
                type="time"
                id="disabledTextInput"
                class="form-control"
                style={{ width: "270px" }}
                onChange={(e)=>settime(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div class="col-md-6 mb-3">
              <label for="disabledSelect" class="form-label">
                Select Day
              </label>
              <select
                class="form-select form-select-sm"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e)=>setday(e.target.value)}
              >
                <option selected>Select Day</option>
              
                {booked?.courseTopic?.filter((ele)=> topic ? ele?.topicName==topic:ele)?.map((item)=>{
                  return (
                     <option value={item?.days}>{item?.days} day</option>
                  )
                })}
             
              </select>
            </div>
            <div class="col-md-6 mb-3">
              <label for="disabledSelect" class="form-label">
                Mobile Number
              </label>
              <input
                type="text"
                id="disabledTextInput"
                value={mobile}
                class="form-control"
                onChange={(e)=>setmobile(e.target.value)}
                placeholder="enter student mobile number"
                style={{ width: "270px" }}
              />
            </div>
          </div>
        </form>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="primary mb-3"
          onClick={ClassStart}
          style={{ color: "black" }}
        >
          Start The Class
        </Button>
      </div>
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="danger"
            onClick={()=>setshowSheule(false)}
            style={{ color: "black" }}
          >
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
      <div></div>
    </div>
  );
};

export default StartClasses;
