import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap/cjs";

function Dashboard() {
  const [AllCustomer, setAllCustomer] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");
  const getCustomer = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      const config = {
        url: "/admin/staff/getAllStaffByBranchId/" + ab,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllBooking, setAllBooking] = useState([]);

  const getAllBooking = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      const config = {
        url: "/admin/getAllBookingByBranchId/" +ab + "/" + admin?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllTrainer, setAlltrainer] = useState([]);

  const getTrainer = async () => {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
    try {
      const config = {
        url: "/staff/getTrainerByBranchId/" + ab,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const [AllBooking,setAllBooking]=useState([]);
  const [AllServiceBook, setAllServiceBook] = useState([]);
  const getAllBookingService = async () => {
    try {
      let ab =admin?.branchId ? admin?.branchId: admin?._id
      const config = {
        url: "/admin/getAllServiceBookDeatailsByBranchId/" + ab,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllServiceBook(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllsubAdmin, setAllSubAdmin] = useState([]);
 
  const getAllSubadmin = async () => {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
    try {
      const config = {
        url: "/admin/getSubadminByBranchId/" + ab+"/"+admin?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllSubAdmin(res.data.success);
      
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if(admin){
          getCustomer();
    getAllBooking();
    getTrainer();
    getAllBookingService();
    getAllSubadmin()
    }

  }, []);
  return (
    <>
      <div className="add-gr">
        <div className="ad-dash mt-5">
          <div className="container">
            <div className="vina">
              <div className="vina-left">
                <div className="row">
                  <div className="col-md-8">
                    {/* <div className="row">
                    <div className="col-md-4">
                      <div className="lab">
                        <label>From</label><br />
                        <input type="text" placeholder="from " className="vi_0" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="lab">
                        <label>To</label><br />
                        <input type="text" placeholder="to " className="vi_0" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="lab">

                        <Button>Submit</Button>
                      </div>
                    </div>

                  </div> */}
                  </div>
                  <div className="col-md-4">
                    <div className="to-2"></div>
                  </div>
                </div>
              </div>
              <div className="np-p mt-4">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dask">
                      <div className="do-p">Sub-Admin</div>
                      <div className="no-p">
                        <p>{AllsubAdmin?.length}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dask">
                      <div className="do-p"> Staffs</div>
                      <div className="no-p">
                        <p>{AllCustomer?.length}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dask">
                      <div className="do-p">Instructors</div>
                      <div className="no-p">
                        <p>{AllTrainer?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-4">
                    <div className="dask">
                      <div className="do-p"> Total Sevice Booking</div>
                      <div className="no-p">
                        <p>{AllServiceBook?.length}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dask">
                      <div className="do-p"> Total Course Booking</div>
                      <div className="no-p">
                        <p>{AllBooking?.length}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                  <div className="dask">
                    <div className="do-p">No. of Staff</div>
                    <div className="no-p"><p>10</p></div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="dask">
                    <div className="do-p">No. of Students</div>
                    <div className="no-p"><p>20</p></div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
