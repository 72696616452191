import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Item } from "react-grid-carousel";
import moment from "moment";

const Adminform5 = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const token = sessionStorage.getItem("token");

  const [AllBooking, setAllBooking] = useState([]);
  const [data, setdata] = useState([])
  const [nochangedata, setnochangedata] = useState([])
  const getAllBooking = async () => {
    try {
      const config = {
        url: "/admin/getAllBooking/" + admin?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`, "content-type": "multipart/form-data" },
      }
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
        setnochangedata(res.data.success)
        setdata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getAllBooking()
  },[]);
  console.log(AllBooking);
  const [search, setSearch] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllBooking.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //EnrollmentNumber,Name,garduianName,PermanentAddress,Trainername,Numberregisteredinform14,CourseName,CourseStartDate,DateofEnrollment,Syllabusprescribed,CourseEndDate,Place,DateA,Remarks,regiYear

  const [EnrollmentNumber,setEnrollmentNumber]=useState("");
  const [Name,setName]=useState("");
  const [garduianName,setgarduianName]=useState("");
  const [PermanentAddress,setPermanentAddress]=useState("");
  const [Trainername,setTrainername]=useState("");
  const [Numberregisteredinform14,setNumberregisteredinform14]=useState("");
  const [CourseName,setCourseName]=useState("");
  const [CourseStartDate,setCourseStartDate]=useState("");
  const [DateofEnrollment,setDateofEnrollment]=useState("");
  const [Syllabusprescribed,setSyllabusprescribed]=useState("");
  const [CourseEndDate,setCourseEndDate]=useState("");
  const [Place,setPlace]=useState("");
  const [DateA,setDateA]=useState("");

  const [AllForm5,setAllForm5]=useState([]);
  const [noallForm5,setnoform5]=useState([]);
  const getallForm5=async()=>{
    let res=await axios("https://jaidrivingschool.in/api/admin/getAllForm5");
    if(res.status==200){
        setAllForm5(res.data.success)
        setnoform5(res.data.success)
    }
  }
  const handleFilter = (e) => {
    if (e.target.value != "") {
 
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      const filterTable2 = noallForm5.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setAllForm5([...filterTable2])
      setAllBooking([...filterTable]);
    } else {
      setAllForm5([...noallForm5])
      setAllBooking([...nochangedata]);
    }


  };
  const AddForm5=async()=>{
    if(!EnrollmentNumber|!Name|!garduianName|!PermanentAddress|!Trainername|!CourseName|!DateofEnrollment) return alert("Please fill the form")
    try {
        const config = {
            url: "/admin/createform5",
            method: "post",
            baseURL: "https://jaidrivingschool.in/api",
            headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },
            data:{authId:admin?._id,EnrollmentNumber,Name,garduianName,PermanentAddress,Trainername,Numberregisteredinform14,CourseName,CourseStartDate,DateofEnrollment,Syllabusprescribed,CourseEndDate,Place,DateA}
          }
          let res=await axios(config);
          if(res.status==200){
            alert("Successfully added");
            handleClose();
            getallForm5()
            setEnrollmentNumber("");
            setName("")
            setgarduianName("");
            setPermanentAddress("");
            setTrainername("");
            setNumberregisteredinform14("");
            setCourseEndDate("");
            setCourseStartDate("");
            setCourseName("");
            setDateofEnrollment("");
            setSyllabusprescribed("");
            setPlace("");
            setDateA(""); 
          }
    } catch (error) {
        console.log(error);
    }
  }
  useEffect(()=>{
    getallForm5()
  },[])
    return (
        <>
            <div className="add-gr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ad-b mt-4">
                                <button>Form 5</button>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ad-b mt-4">
                                <button style={{ borderRadius: "10px", float: "right" }} onClick={handleShow}>+Add</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
          
                    <div className="mo-gra mt-5 scrolll_bar">
                    <div className="row">
                  <div className="col-3">
                    <input type="text" placeholder="Search...." className="vi_0" style={{ marginLeft: "-20px", marginTop: "26px", }} onChange={(e) => handleFilter(e)} />
                  </div>
                </div>
                        <Table striped bordered hover style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    {/* <th>S.No</th> */}
                                    <th>Name </th>
                                    <th>Son/Wife/Daughter of</th>
                                    <th>Address</th>
                                    <th>Admission Date</th>
                                    <th>Registration Number</th>
                                    <th>Course Name</th>
                                    <th>Course Start Date</th>
                                    <th>Course End Date</th>
                                    <th>Place</th>
                                    <th>Date</th>
                                    <th>View Form</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AllForm5?.map((item)=>{
                                    return (
                                        <tr>
                                        {/* <td>{i+1}</td> */}
                                        <td>{item?.Name}</td>
                                        <td>{item?.garduianName}</td>
                                        <td><p style={{overFlow:"hidden auto",height:"100px",width:"200px"}}>{item?.PermanentAddress}</p></td>
                                        <td>{moment(item?.DateofEnrollment).format("DD/MM/YYYY")}</td>
                                        <td>{item?.EnrollmentNumber}</td>
                                        <td>{item?.CourseName}</td>
                                        <td>{moment(item?.CourseStartDate).format("DD/MM/YYYY")}</td>
                                        <td>{moment(item?.CourseEndDate).format("DD/MM/YYYY")}</td>
                                        <td>{item?.Place}</td>
                                        <td>{item?.DateA}</td>
                                        <td><Link to="/admin_form5" state={{item1:item}} >
                                            <AiFillEye style={{fontSize:"25px"}} />
                                        </Link>
                                        </td>
    
    
                                        {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
                <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                                    </tr> 
                                    )
                                })}
                                {AllBooking?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item,i)=>{
                                    return (
                                       <tr>
                                    {/* <td>{i+1}</td> */}
                                    <td>{item?.customerName}</td>
                                    <td>{item?.gaudianName}</td>
                                    <td><p style={{overFlow:"hidden auto",height:"100px",width:"200px"}}>{item?.customerId?.Address},{item?.customerId?.city}-{item?.customerId?.pincode}</p></td>
                                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                    <td>{item?.bookid}</td>
                                    <td>{item?.courseTitle}</td>
                                    <td>{moment(item?.courseStartDate).format("DD/MM/YYYY")}</td>
                                    <td>{moment(item?.courseEndDate).format("DD/MM/YYYY")}</td>
                                    <td>{item?.city}</td>
                                    <td>{item?.sighDate}</td>
                                    <td><Link to="/admin_form5" state={{item:item}} >
                                        <AiFillEye style={{fontSize:"25px"}} />
                                    </Link>
                                    </td>


                                    {/* <td><AiFillEdit style={{fontSize:"20px"}} onClick={handleShow1}/></td>
            <td><MdDeleteForever  style={{fontSize:"20px"}}/></td> */}
                                </tr>  
                                    )
                                })}
                               

                            </tbody>
                        </Table>

                        <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Form 5</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="do-sear mt-2">
                        <label>Name</label>
                        <input type="text" className="vi_0" placeholder="Please enter Name" value={Name} onChange={(e)=>setName(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Son/Wife/Daughter of</label>
                        <input type="text" className="vi_0" placeholder="Please enter Son/Wife/Daughter of" value={garduianName} onChange={(e)=>setgarduianName(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Address</label>
                        <textarea rows={3} cols={10} type="text" className="vi_0" placeholder="Please enter Address" value={PermanentAddress} onChange={(e)=>setPermanentAddress(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Admission Date</label>
                        <input type="date" className="vi_0" placeholder="Please enter Addmission Date" value={DateofEnrollment} onChange={(e)=>setDateofEnrollment(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Registration Number</label>
                        <input type="text" className="vi_0" placeholder="Please enter registration number" value={EnrollmentNumber} onChange={(e)=>setEnrollmentNumber(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Course Name</label>
                        <input type="text" className="vi_0" placeholder="Please enter Course Name" value={CourseName} onChange={(e)=>setCourseName(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Course Start Date</label>
                        <input type="date" className="vi_0" placeholder="Please enter Course Start Date" value={CourseStartDate} onChange={(e)=>setCourseStartDate(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Course End Date</label>
                        <input type="date" className="vi_0" placeholder="Please enter Course End Date" value={CourseEndDate} onChange={(e)=>setCourseEndDate(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Place</label>
                        <input type="text" className="vi_0" placeholder="Please enter Place" value={Place} onChange={(e)=>setPlace(e.target.value)} />
                    </div>
                    <div className="do-sear mt-2">
                        <label>Date</label>
                        <input type="date" className="vi_0" placeholder="Please enter Date" value={DateA} onChange={(e)=>setDateA(e.target.value)}/>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={AddForm5} >Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Adminform5
