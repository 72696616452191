import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { AiFillEdit } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import exportFromJSON from "export-from-json";

function VendorAcountHistory() {
  const [AllHistory, setAllHistory] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");
const [noschangedata,setnochangedata]=useState([]);
const [data,setdata]=useState([])
  const getAllHistory = async () => {
    try {
      const config = {
        url: "/admin/getAllAccountByBranchId/" + admin?.branchId ? admin?.branchId : admin?._id?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllHistory(res.data.success);
        setnochangedata(res.data.success);
        setdata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllHistory.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  useEffect(() => {
    if (admin) {
      getAllHistory();
    }
  }, []);
  const [fileName, setfileName] = useState("AcountHistory");
  const exportType = "xls";
  const ExportToExcel = () => {
    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
    } else {
      alert("Enter file name to export");
    }
  };
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
const searchDate=()=>{
  if(!fromd) return alert("Please select from date");
  if(!tod) return alert("Please select to date");

 let std=fromd?.split("-");
      let entd=tod?.split("-")
      let startD=parseInt(std?.join(""));
      let endD=parseInt(entd?.join(""))
      let abc=noschangedata?.filter((ele)=>{
        console.log("amit check it",moment(ele?.createdAt).format("YYYY-MM-DD").split("-"))
        let abd=moment(ele?.createdAt).format("YYYY-MM-DD").split("-")
        let amd=parseInt(abd?.join(""));
        return startD<=amd && endD>=amd
      })
      setAllHistory(abc);
      setdata(abc)
      setfileName(`AcountHistory_${moment(fromd).format("DD/MM/YYYY")} to ${moment(tod).format("DD/MM/YYYY")}`)
}
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4">
            <button>Account History</button>
          </div>
        </div>

        <div className="mo-gra mt-5">
          <div className="row">
            <div className="col-md-2">
              <label htmlFor="">From</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => setfromd(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="">To</label>
              <input
                type="date"
                class="form-control"
                onChange={(e) => settod(e.target.value)}
              />
            </div>
            <div className="col-md-2">
         
              <button onClick={searchDate}
                style={{
                  borderRadius: "3px",
                  color: "white",
                  fontSize: "18px",
                  border: "1px solid",
                  backgroundColor: "#9d041b",
                  padding: "5px",
                  marginTop: "26px",
                  borderRadius: "10px",
                  fontWeight: 500,
                }}
              >
                Search
              </button>
            </div>
            <div className="col-md-6">
         
         <button onClick={ExportToExcel}
           style={{
             borderRadius: "3px",
             color: "white",
             fontSize: "18px",
             border: "1px solid",
             backgroundColor: "#9d041b",
             padding: "5px",
             marginTop: "26px",
             borderRadius: "10px",
             fontWeight: 500,
             float:"right",
             marginRight:"10px"
           }}
         >
           Export To Excel
         </button>
       </div>
          </div>
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>BookId</th>
                <th>Date</th>
                <th>Customer/StudentId</th>
                <th>PayId</th>
                <th>Amount</th>
             
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {AllHistory?.slice(
                pagesVisited,
                pagesVisited + usersPerPage
              )?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item?.bookingId}</td>
                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{item?.customeId}</td>
                    <td>{item?.paymentId}</td>
                    <td>{item?.amount?.toFixed(2)}</td>
                    <td>
                      {item?.status == "Cr" ? (
                        <span style={{ color: "green" }}>Credit</span>
                      ) : (
                        <span style={{ color: "red" }}>Debit</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </>
  );
}

export default VendorAcountHistory;
