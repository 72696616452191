import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Side from "./sidebar/Side";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { display } from "@mui/system";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";

const ClassOn = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {state}=useLocation();
  console.log("state",state);
  let trainer=JSON.parse(sessionStorage.getItem("staff"));
let token=sessionStorage.getItem("token");
const [endtime,setendtime]=useState("");
const [address,setaddress]=useState("");
const [pickLat,setpickLat]=useState("");
const [pickLng,setpickLng]=useState("");

const endClass=async()=>{
  try {
    if(!endtime)return alert("Please select pickup time");
    if(!address) return alert("Please enter intial reading")
    const config = {
      url: '/makePickupCustomer',
      method: 'put',
      baseURL: 'https://jaidrivingschool.in/api/admin/',
      headers: {'content-type': 'application/json'},
      data: {
       id:state?.classD?._id,
       pickupAddress:address,picupTime:endtime,pickupLat:pickLat,pickupLng:pickLng
      },
    };
    let res=await axios(config);
    if(res.status==200){
      navigate("/verification",{state:{oldData:state,classP:res.data.success}})
    }
  } catch (error) {
    console.log(error);
  }
}
var handlePlaceChanged = (place) => {
  if (place) {
    console.log("check it", place);

    console.log("Lat", place.geometry.location.lat());
    console.log("Long", place.geometry.location.lng());
    setpickLat(place.geometry.location.lat());
    setpickLng(place.geometry.location.lng());

    setaddress(place.name);
  
  }
};
  return (
    <div>
      <Side />
      <div className="container" style={{}}>
        <div className="mt-3">
          <h4>Enter Pick Up Point</h4>
          <div
            className="dfghio_098"
            style={{
              border: "1px solid white",
              boxShadow: "0 8px 32px rgba(19,19,20,0.37)",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-evenly",
              padding: "20px",
            }}
          >
            <h6>Trainer:{trainer?.name}</h6>
            <h6>Course Name:{state?.course?.courseTitle}</h6>
            <h6>Title:{state?.classD?.classTopic}</h6>
            <h6>Student Name:{state?.classD?.StudentName
}</h6>
            {/* <h6>Class Duration:1 hour</h6> */}
          </div>

          <div
            style={{
              // border: "1px solid white",
              // boxShadow: "0 8px 32px rgba(19,19,20,0.37)",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <div className=" mt-3 mb-3">
              <label for="disabledSelect" class="form-label">
                Select Pickup Time
              </label>
              <input
                type="time"
                id="disabledTextInput"
                class="form-control"
                style={{ width: "270px" }}
                onChange={(e)=>setendtime(e.target.value)}
              />

              <label for="disabledSelect" class="form-label">
                Intial Reading
              </label>
             
              <input
                type="number"
                id="disabledTextInput"
                class="form-control"
                style={{ width: "270px" }}
                placeholder="Enter intial reading"
                onChange={(e)=>setaddress(e.target.value)}
              />

            </div>
          </div>

          <br />
          <Button
            variant="primary mb-3"
            onClick={() => endClass()}
            style={{ color: "black" }}
          >
            Pick Now
          </Button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Select Drop-off</label>
          <br />
          <select name="" id="name">
            <option value="">Driving School</option>
          </select>
          <br />
          <br />
          <label>Enter your mobile number for OTP verification</label>
          <input type="text" id="name" placeholder="Enter Mobile Number" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => navigate("/Sreviews")}
            style={{ color: "black" }}
          >
            submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/*<div>
             
            </div> */}
    </div>
  );
};

export default ClassOn;
