import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import './Login.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiFillEye } from "react-icons/ai";
import { Email } from "@mui/icons-material";


const StudentLogin = () => {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);


  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/customer/loginWithPwdCustomer",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: username, Password: password
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully login");
        sessionStorage.setItem("user", JSON.stringify(res.data.success));
        sessionStorage.setItem("token", res.data.token);
        window.location.assign("/sprofile")
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error)
    }
  };

  const SendOtp = async () => {
    if (!username) return alert("Please Enter Your Email Id ")
    try {
      const config = {
        url: "/customer/SendOtpEmailForgetpwd",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: username
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully send otp to your mail");
        handleShow2();

      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error)
    }
  };

  const [otp, setotp] = useState("");
  const [id, setid] = useState("")
  const VerifyOtp = async () => {
    if (!otp) return alert("Please Enter Your otp ")
    try {
      const config = {
        url: "/customer/verfiyOtpForEmail",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: username, otp: otp
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully verified otp");
        handleShow1();
        handleClose2();
        setid(res.data.success?._id);

      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error)
    }
  };

  const [password1, setpassword1] = useState("")
  const [conpassword, setconpassword] = useState("")

  const ChangePassword = async () => {
    if (!password1) return alert("Please Enter Your password ");
    if (!conpassword) { return alert('please enter confirm password') };
    if (!(password1 == conpassword)) return alert('confirm password does not match');
    try {
      const config = {
        url: "/customer/changePassword",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { "content-type": "application/json" },
        data: {
          userId: id, Password: password1
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Changed Password");
        handleShow1();
        handleClose2();
        window.location.assign("/studentlogin")


      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error)
    }
  };

  return (
    <>

      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <h2 style={{ textAlign: "center" }}>Student/Customer Login</h2>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username1"
            value={username}
            onChange={handleUsernameChange}
          // required
          />
          <br />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password1"
            value={password}
            onChange={handlePasswordChange}
          // required
          />{" "}
          <br />
          <span>
            <a
              onClick={handleShow}
              style={{
                color: "white",
                float: "right",
                textDecoration: "underline",
                marginTop: "-10px",
                cursor: 'pointer'
              }}
            >
              Forgot Password?
            </a>
          </span>
          <br />
          <button

            type="submit"
            className="btn btn-warning "
            style={{ marginLeft: "150px", marginTop: "10px" }}
          >
            Login
          </button>
          <br />
          <p style={{ marginTop: "20px", textAlign: "center" }}>
            Don't have an account!
            <a href="/register" style={{ color: "white", textDecoration: "underline", cursor: 'pointer', marginLeft: '10px' }}>
              Register now
            </a>
          </p>
        </form>
      </div>

      {/* Forgote Password */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="mb-3">
            <label >Email : </label>
            <br />
            <input
              // onChange={handleUsernameChange}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter Your Name"
              style={{ width: '300px', padding: '10px', borderRadius: '15px' }}
              type="email"
              required
            />{" "}
          </div>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={SendOtp}>
            Send otp
          </Button>
        </Modal.Footer>
      </Modal>


      {/* OTP MODAL */}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center gap-3">
          <div className="mb-3" >
            <label htmlFor="password">Enter Your OTP :</label>
            <br />
            <input
              // onChange={handlePasswordChange}
              value={otp}
              onChange={(e) => setotp(e.target.value)}
              placeholder="Enter Your OTP"
              style={{ width: '300px', padding: '10px', borderRadius: '15px' }}
              type="text"
              required
            />{" "}
          </div>
          <button onClick={VerifyOtp} style={{ backgroundColor: 'green', border: 'none', padding: '7px', borderRadius: '10px', color: 'white', height: '35px', marginTop: '10px' }}>Verify OTP</button>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button style={{backgroundColor:'green'}} onClick={handleClose2}>
            Resend OTP
          </Button> */}
          <Button variant="primary" onClick={SendOtp}>
            Resend OTP
          </Button>
        </Modal.Footer>
      </Modal>



      {/* New Password */}

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3" style={{ position: 'relative' }}>
            <label htmlFor="password">New Password :</label>
            <br />
            <input
              // onChange={handlePasswordChange}
              value={password1}
              onChange={(e) => setpassword1(e.target.value)}
              style={{ width: '300px', padding: '10px', borderRadius: '15px' }}
              type={passwordVisible ? "text" : "password"}
              required
            />{" "}
            <AiFillEye
              onClick={() => setPasswordVisible(!passwordVisible)}
              style={{
                position: 'absolute',
                bottom: '16%',
                right: '39%'
              }} />
          </div>

          <div className="mb-3" style={{ position: 'relative' }}>
            <label htmlFor="password">Confirm Password :</label>
            <br />
            <input
              value={conpassword}
              onChange={(e) => setconpassword(e.target.value)}
              style={{ width: '300px', padding: '10px', borderRadius: '15px' }}
              type={passwordVisible1 ? "text" : "password"}
              required
            />{" "}
            <AiFillEye
              onClick={() => setPasswordVisible1(!passwordVisible1)}
              style={{
                position: 'absolute',
                bottom: '16%',
                right: '39%'
              }} />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={ChangePassword}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>




    </>
  );
};

export default StudentLogin;
