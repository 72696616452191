import React from 'react'
import Side1 from "./sidebar1/Side1";

const StudentCertiPrint = () => {

  return (
    <div>
        <Side1 />
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <img src="./images/certificate.jpg" alt="" style={{width:"100%"}}/>
                    <button type='submit' className='btn btn-success mt-3 mb-3' style={{float:"right"}}>Print Certificate</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StudentCertiPrint