import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { AiFillEdit } from "react-icons/ai";
import { MdDeleteForever, MdDone } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Offcanvas } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { BsEye, BsFillEyeFill } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { RxCross2 } from "react-icons/rx";

function SubAdmin() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setpassword] = useState("");
  const [SubAdmin, setsubadmin] = useState(false);
  const [webMangement, setwebMangement] = useState(false);
  const [Branch, setBranch] = useState(false);
  const [Vehicle, setVehicle] = useState(false);
  const [Discount, setDiscount] = useState(false);
  const [Courses, setCourses] = useState(false);
  const [ServicesA, setServicesA] = useState(false);
  const [Additional, setAdditional] = useState(false);
  const [Customer, setCustomer] = useState(false);
  const [Employee, setEmployee] = useState(false);
  const [RTO, setRTO] = useState(false);

  const [FormMangament, setFormMangament] = useState(false);
  const [Leaves, setLeaves] = useState(false);
  const [Enquiry, setEnquiry] = useState(false);
  const [account, setaccount] = useState(false);
  const [expense, setexpense] = useState(false);
  const [Blog, setBlog] = useState(false);
  const token = sessionStorage.getItem("token");
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const [AllsubAdmin, setAllSubAdmin] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);

  const getAllSubadmin = async () => {
    try {
      const config = {
        url: "/admin/getallsubadmin/" + admin?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllSubAdmin(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const mobileNumberRegex = /^[0-9]{10}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const AddSubadminDetails = async () => {
    if(!name) return alert("Please enter name");
    if(!mobile) return alert("Please enter mobile number");
    if(!mobileNumberRegex.test(mobile)) return alert("Invalid mobile number")
    if(!email) return alert("Please enter email id");
    if(!emailRegex.test(email)) return alert("Invalid email id")
    if(!password) return alert("Please enter password");
    try {
      const config = {
        url: "/admin/signupSubadmin",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          name,
          email,
          password,
          mobile,
          SubAdmin,
          webMangement,
          Branch,
          Vehicle,
          Discount,
          Courses,
          ServicesA,
          Additional,
          Customer,
          Employee,
          RTO,
          Blog,
          Leaves,
          Enquiry,
          account,
          FormMangament,
          expense,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully Added");
        handleClose();
        getAllSubadmin();
        setname("");
        setemail("");
        setpassword("");
        setmobile("");
        setsubadmin(false);
        setwebMangement(false);
        setBranch(false);
        setAdditional(false);
        setBlog(false);
        setCourses(false);
        setServicesA(false);
        setDiscount(false);
        setEmployee(false);
        setEnquiry(false);
        setFormMangament(false);
        setRTO(false);
        setLeaves(false);
        setexpense(false);
        setaccount(false);
        setVehicle(false);
        setCustomer(false);
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  };

  // console.log("check==>", name, email, password, mobile,SubAdmin,webMangement,Branch, Vehicle,Discount,Courses,ServicesA,Additional,Customer,Employee,RTO,Blog,Leaves,Enquiry,account,FormMangament ,expense);
  useEffect(() => {
    if (token) {
      getAllSubadmin();
    }
  }, []);
  const [edit, setedit] = useState({});
  const handleShow1 = (data) => {
    setname(data?.name);
    setedit(data);
    setsubadmin(data?.SubAdmin);
    setwebMangement(data?.webMangement);
    setBranch(data?.Branch);
    setAdditional(data?.Additional);
    setBlog(data?.Blog);
    setCourses(data?.Courses);
    setServicesA(data?.ServicesA);
    setDiscount(data?.Discount);
    setEmployee(data?.Employee);
    setEnquiry(data?.Enquiry);
    setFormMangament(data?.FormMangament);
    setRTO(data?.RTO);
    setLeaves(data?.Leaves);
    setexpense(data?.expense);
    setaccount(data?.account);
    setVehicle(data?.Vehicle);
    setCustomer(data?.Customer);

    setShow1(true);
  };

  const updateSubAdmin = async () => {
    if(mobile){
      if(!mobileNumberRegex.test(mobile)) return alert("Invalid mobile number")
    }
    if(email){
      if(!emailRegex.test(email)) return alert("Invalid email id")
    }
    try {
      const config = {
        url: "/admin/updateSubAdmin",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: admin?._id,
          id: edit?._id,
          name,
          email,
          password,
          mobile,
          SubAdmin,
          webMangement,
          Branch,
          Vehicle,
          Discount,
          Courses,
          ServicesA,
          Additional,
          Customer,
          Employee,
          RTO,
          Blog,
          Leaves,
          Enquiry,
          account,
          FormMangament,
          expense,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        handleClose1();
        getAllSubadmin();
        setname("");
        setemail("");
        setpassword("");
        setmobile("");
        setsubadmin(false);
        setwebMangement(false);
        setBranch(false);
        setAdditional(false);
        setBlog(false);
        setCourses(false);
        setServicesA(false);
        setDiscount(false);
        setEmployee(false);
        setEnquiry(false);
        setFormMangament(false);
        setRTO(false);
        setLeaves(false);
        setexpense(false);
        setaccount(false);
        setVehicle(false);
        setCustomer(false);
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  };

  const [deleteId,setdeleteId]=useState("");
  const [showD,setShowD]=useState(false);

  const deleteSubAdmin=async()=>{
    try {
      const config={
        url: "/admin/deletesubadmin/"+deleteId+"/"+admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully Deleted");
        setShowD(false);
        getAllSubadmin();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllsubAdmin?.filter((ele) => ele?.role !== "admin" && ele?._id!=admin?._id).length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllSubAdmin([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAllSubAdmin([...nochangedata]);
    }
  };

  const [showdata, setshowdata] = useState({});
const [show4,setshow4]=useState(false)
  return (
    <>
      <div className="add-gr ms-5">
        <div className="container">
          <div className="ad-b mt-4">
            <button>ALL SUBADMIN LIST </button>
            <button onClick={()=>{
                 setname("");
                 setemail("");
                 setpassword("");
                 setmobile("");
                 setsubadmin(false);
                 setwebMangement(false);
                 setBranch(false);
                 setAdditional(false);
                 setBlog(false);
                 setCourses(false);
                 setServicesA(false);
                 setDiscount(false);
                 setEmployee(false);
                 setEnquiry(false);
                 setFormMangament(false);
                 setRTO(false);
                 setLeaves(false);
                 setexpense(false);
                 setaccount(false);
                 setVehicle(false);
                 setCustomer(false);
              handleShow()}} style={{ float: "right",borderRadius:"10px"}}>
             + Add 
            </button>
          </div>
        </div>

        <div className="container">
          <div className="mo-gra mt-5">
            <div className="row">
              <div className="col-3">
                <input
                  type="text"
                  placeholder="Search...."
                  onChange={handleFilter}
                  className="vi_0"
                  style={{ marginLeft: "-20px" }}
                />
              </div>
            </div>

            <Table striped bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  {/* <th>User id</th> */}
                  <th>Name</th>
                  <th>Contact No</th>
                  <th>Email Id</th>
                  <th>Access Modules</th>
                  <th>Date of Creation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {AllsubAdmin?.filter((ele) => ele?.role !== "admin" && ele?._id!=admin?._id)  ?.slice(pagesVisited, pagesVisited + usersPerPage)?.map(
                  (item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>
                          <BsFillEyeFill
                            style={{
                              color: "blue",
                              fontSize: "28px",
                              cursor: "pointer",
                            }}
                            onClick={()=>{
                              setshowdata(item);
                              setshow4(true);
                            }}
                          />

                        </td>

                        <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                        <td>
                          <AiFillEdit
                            style={{
                              fontSize: "27px",
                              color: "blue",
                              margin: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleShow1(item)}
                          />
                          <MdDeleteForever
                            style={{
                              fontSize: "27px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={()=>{
                              setdeleteId(item?._id);
                              setShowD(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
            <br/>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
          </div>
        </div>
      </div>

      {/* Add Model */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add SubAdmin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fwei">
            <div className="mb-2">
              <input
                type="text"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setname(e.target.value)}
                placeholder="Sub Admin Name"
              />
            </div>
            <div className="mb-2">
              <input
                type="number"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                placeholder="Phone Number"
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <input
                type="email"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Email Id"
              />
            </div>

            <div className="mb-2">
              <input
                type="password"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="dgier mt-4">
            <h4 style={{ color: "#fdd12d" }}>Access Modules</h4>
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={SubAdmin}
                  onChange={() => setsubadmin(!SubAdmin)}
                />
                <label>SubAdmin</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={webMangement}
                  onChange={() => setwebMangement(!webMangement)}
                />
                <label>Web Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Branch}
                  onChange={() => setBranch(!Branch)}
                />
                <label>Our Branch</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Vehicle}
                  onChange={() => setVehicle(!Vehicle)}
                />
                <label>Vehicle Mangament</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Discount}
                  onChange={() => setDiscount(!Discount)}
                />
                <label>Discount</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Courses}
                  onChange={() => setCourses(!Courses)}
                />
                <label>Courses</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={ServicesA}
                  onChange={() => setServicesA(!ServicesA)}
                />
                <label>Services</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Additional}
                  onChange={() => setAdditional(!Additional)}
                />
                <label>Additional Charges</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Customer}
                  onChange={() => setCustomer(!Customer)}
                />
                <label>Customer/Students</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Employee}
                  onChange={() => setEmployee(!Employee)}
                />
                <label>Employee Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={RTO}
                  onChange={() => setRTO(!RTO)}
                />
                <label>RTO Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Blog}
                  onChange={() => setBlog(!Blog)}
                />
                <label>Blogs</label>
              </div>

              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Leaves}
                  onChange={() => setLeaves(!Leaves)}
                />
                <label>Leaves</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Enquiry}
                  onChange={() => setEnquiry(!Enquiry)}
                />
                <label>Enquires</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={expense}
                  onChange={() => setexpense(!expense)}
                />
                <label>Expanse</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={() => setaccount(!account)}
                />
                <label>Account History</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={FormMangament}
                  onChange={() => setFormMangament(!FormMangament)}
                />
                <label>Form Management</label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddSubadminDetails}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit  Model */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit SubAdmin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fwei">
            <div className="mb-2">
              <input
                type="text"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                value={name}
                onChange={(e) => setname(e.target.value)}
                placeholder="Sub Admin Name"
              />
            </div>
            <div className="mb-2">
              <input
                type="number"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                value={mobile}
                placeholder={edit?.mobile}
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <input
                type="email"
                value={email}
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setemail(e.target.value)}
                placeholder={edit?.email}
              />
            </div>

            <div className="mb-2">
              <input
                type="password"
                style={{
                  padding: "10px",
                  width: "100%",
                  border: "1px solid #fdd12d",
                  height: "50px",
                  borderRadius: "20px",
                }}
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="dgier mt-4">
            <h4 style={{ color: "#fdd12d" }}>Access Modules</h4>
            <div className="grse" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={SubAdmin}
                  onChange={() => setsubadmin(!SubAdmin)}
                />
                <label>SubAdmin</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={webMangement}
                  onChange={() => setwebMangement(!webMangement)}
                />
                <label>Web Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Branch}
                  onChange={() => setBranch(!Branch)}
                />
                <label>Our Branch</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Vehicle}
                  onChange={() => setVehicle(!Vehicle)}
                />
                <label>Vehicle Mangament</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Discount}
                  onChange={() => setDiscount(!Discount)}
                />
                <label>Discount</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Courses}
                  onChange={() => setCourses(!Courses)}
                />
                <label>Courses</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={ServicesA}
                  onChange={() => setServicesA(!ServicesA)}
                />
                <label>Services</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Additional}
                  onChange={() => setAdditional(!Additional)}
                />
                <label>Additional Charges</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Customer}
                  onChange={() => setCustomer(!Customer)}
                />
                <label>Customer/Students</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Employee}
                  onChange={() => setEmployee(!Employee)}
                />
                <label>Employee Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={RTO}
                  onChange={() => setRTO(!RTO)}
                />
                <label>RTO Management</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Blog}
                  onChange={() => setBlog(!Blog)}
                />
                <label>Blogs</label>
              </div>

              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Leaves}
                  onChange={() => setLeaves(!Leaves)}
                />
                <label>Leaves</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={Enquiry}
                  onChange={() => setEnquiry(!Enquiry)}
                />
                <label>Enquires</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={expense}
                  onChange={() => setexpense(!expense)}
                />
                <label>Expanse</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={account}
                  onChange={() => setaccount(!account)}
                />
                <label>Account History</label>
              </div>
              <div className="mb-2 mx-2">
                <input
                  type="checkbox"
                  style={{
                    border: "1px solid #fdd12d",
                    borderRadius: "20px",
                  }}
                  checked={FormMangament}
                  onChange={() => setFormMangament(!FormMangament)}
                />
                <label>Form Management</label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={updateSubAdmin}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Delete  Model */}
       <Modal
        show={showD}
        onHide={()=>setShowD(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete ??</Modal.Title>
        </Modal.Header>
     
        <Modal.Footer>
          <Button variant="danger" onClick={()=>setShowD(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={deleteSubAdmin}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas show={show4} onHide={()=>setshow4(false)}>
        <Offcanvas.Header style={{backgroundColor:"#9d041b",color:"white"}} closeButton>
          <Offcanvas.Title>Sub Admin All Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="efqevs mb-2">
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Name: {showdata?.name}</h5>
            </div>
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Email: {showdata?.email}</h5>
            </div>
            <div className="mt-2 mb-2">
              <h5 style={{ color: "black" }}>Mobile : {showdata?.mobile}</h5>
            </div>
          </div>
          <div style={{ fontSize: "25px", fontWeight: "700", color: "black" }}>
            Access Modules
          </div>
          <ul style={{ listStyle: "none" }}>
            <li style={{ padding: "2px" }}>
            SubAdmin{" "}
              <span>
                {showdata?.SubAdmin == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Web Management{" "}
              <span>
                {showdata?.webMangement == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Our Branch{" "}
              <span>
                {showdata?.Branch == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Vehicle Mangament{" "}
              <span>
                {showdata?.Vehicle == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Discount{" "}
              <span>
                {showdata?.Discount == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Courses{" "}
              <span>
                {showdata?.Courses == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Services{" "}
              <span>
                {showdata?.ServicesA == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Additional Charges{" "}
              <span>
                {showdata?.Additional == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Customer/Students{" "}
              <span>
                {showdata?.Customer == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Employee Management{" "}
              <span>
                {showdata?.Employee == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            RTO Management{" "}
              <span>
                {showdata?.RTO == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Blogs{" "}
              <span>
                {showdata?.Blog == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Leaves{" "}
              <span>
                {showdata?.Leaves == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            {/* <li style={{ padding: "2px" }}>
              Vehicle Model{" "}
              <span>
                {showdata?.vehicalModel == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li> */}
            <li style={{ padding: "2px" }}>
            Enquires{" "}
              <span>
                {showdata?.Enquiry == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Expanse{" "}
              <span>
                {showdata?.expense == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Account History{" "}
              <span>
                {showdata?.account == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
            <li style={{ padding: "2px" }}>
            Form Management{" "}
              <span>
                {showdata?.FormMangament == true ? (
                  <MdDone
                    style={{
                      color: "green",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                ) : (
                  <RxCross2
                    style={{
                      color: "red",
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "25px",
                    }}
                  />
                )}
              </span>{" "}
            </li>
          
            {/* <li>GST{" "}<span>{showdata?.gst==true ? (<MdDone style={{color:"green",fontSize:"20px",fontWeight:"bold",marginLeft:"25px"}}/>):(<RxCross2 style={{color:"red",fontSize:"20px",fontWeight:"bold",marginLeft:"25px"}}/>)}</span> </li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SubAdmin;
