import React from "react";
import { useNavigate } from "react-router-dom";

const LogInstructors = () => {
  const navigate = useNavigate()
  return (
    <div>
      <div className="instructors">
        <h1 class="mt-3 mb-3" style={{ marginLeft: "550px" }}>
          Instructors
        </h1>
      </div>

      <div>
        <div class="row row-col-mds-1 row-col-mds-md-3 g-4">
          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor1.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h4 class="card-title">Dhanish patil</h4>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.{" "}
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>

          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor2.jpeg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Sophie Awary</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor1.jpg"
                class="card-img-top"
                alt="..."
                
              />
              <div class="card-body">
                <h5 class="card-title">Naveen Kumar</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-col-mds-1 row-col-mds-md-3 g-4">
          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor1.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h4 class="card-title">Dhanish patil</h4>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.{" "}
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>

          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor2.jpeg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Sophie Awary</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor1.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Naveen Kumar</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-col-mds-1 row-col-mds-md-3 g-4">
          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor1.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h4 class="card-title">Dhanish patil</h4>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.{" "}
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>

          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor2.jpeg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Sophie Awary</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
          <div class="col-md">
            <div class="card h-100">
              <img
                src="/images/Instructor1.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Naveen Kumar</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                </p>
                <h6>Designation: Trainer</h6>
                <h6>Location: Banglore</h6>
                <h6>Ratings: ⭐⭐⭐⭐⭐</h6>
                <button
                  type="submit"
                  class="btn btn-warning loginstructorsbtn"
                  style={{
                    width: "7rem",
                    // padding: "11px",
                    marginLeft: "130px",
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        onClick={() => navigate("/logchoose")}
        class="btn btn-warning loginstructorsbtn1"
        style={{ width: "7rem", float: "left", marginBottom: "20px", marginLeft: "50px" }}
      >
        previous
      </button>
      <button
        type="submit"
        onClick={() => navigate("/")}
        class="btn btn-warning loginstructorsbtn2"
        style={{
          float: "right",
          width: "8rem",
          // height: "50px",
          // marginTop: "60px",
          marginRight: "50px"
        }}
      >
        Save&Continue
      </button>
    </div>
  );
};

export default LogInstructors;
