import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

const Sidebar1 = ({opensidebar1}) => {
  let user=JSON.parse(sessionStorage.getItem("user"))
  return (
    <div className="tool-bar">
        <div className="buger" onClick={opensidebar1}>
            <MenuIcon/>
        </div>
        <div className="tittle">
            {user?.name}
        </div>
    </div>
  );
};

export default Sidebar1;
