import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";

export const AdminnForm15 = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const {state}=useLocation();
    const item=state?.item
    const [item1,setitem1]=useState(state?.item1)
    const [allData,setallData]=useState([]);

    const getAttenenceByCustomerId=async()=>{
        try {
            let res=await axios.get("https://jaidrivingschool.in/api/admin/getclassBycustomerId/"+item?.customerId?._id);
            if(res.status==200){
                setallData(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [branch,setbranch]=useState({});
    const getBranchData=async()=>{
        try {
            let res= await axios.get("https://jaidrivingschool.in/api/admin/getBranchById/"+item?.branchId);
            if(res.status==200){
                setbranch(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }
  

    useEffect(()=>{
        if(item){
            getAttenenceByCustomerId()
        }
        if(item?.branchId){
            getBranchData();
        }
    },[]);
    


function diffTime(time1,time2){
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);
    
    // Calculate the time difference in minutes
    const timeDifferenceMinutes = (hours2 - hours1) * 60 + (minutes2 - minutes1);
    
    // Calculate the hours and minutes components
    const hoursDifference = Math.floor(timeDifferenceMinutes / 60);
    const minutesDifference = timeDifferenceMinutes % 60;
    
    // Format the time difference as a string
    const formattedTimeDifference = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''}`;
return formattedTimeDifference
}

const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.getElementById("pdf"), {
        useCORS: true,
    });
    console.log("data", data);
    const img = data.toDataURL("image/png");
    console.log("img", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("imgProperties", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("pdfWidth", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("pdfHeight", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Form15.pdf");
};

const [classDate,setclassDate]=useState("");
const [FromHrs,setFromHrs]=useState("");
const [ToHrs,setToHrs]=useState("");
const [TotalHrs,setTotalHrs]=useState("");
const [Classofvehicle,setClassofvehicle]=useState("");
const [Signatureofinsturctor,setSignatureofinsturctor]=useState("");
const [SignatureofTrainer,setSignatureofTrainer]=useState("");
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const token = sessionStorage.getItem("token");
const AddClass=async()=>{
  if(!classDate) return alert("Please select class date");
  if(!FromHrs) return alert("Please select FromHrs");
  if(!ToHrs) return alert("Please select ToHrs");
  if(!TotalHrs) return alert("Please enter TotalHrs");
  if(!Classofvehicle) return alert("Please enter class of vehicle");
  if(!Signatureofinsturctor) return alert("Please enter Signature of insturctor");
  if(!SignatureofTrainer) return alert("Please enter Signature of Trainer");
  const config = {
    url: "/admin/addAttendanceFrom15",
    method: "put",
    baseURL: "https://jaidrivingschool.in/api",
    headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },
    data:{id:item1?._id,authId:admin?._id,classDate,FromHrs,ToHrs,TotalHrs,Classofvehicle,Signatureofinsturctor,SignatureofTrainer}
  }
 let res=await axios(config)
 if(res.status==200){
 alert("Successfully added")
 setitem1(res.data.success);
 handleClose();
  setclassDate("");
  setFromHrs("");
  setToHrs("");
  setTotalHrs("");
  setClassofvehicle("");
  setSignatureofinsturctor("");
  setSignatureofTrainer("");
 }

}

const registerUserAddAttandance=async()=>{
    try {
        if(!classDate) return alert("Please select class date");
        if(!FromHrs) return alert("Please select FromHrs");
        if(!ToHrs) return alert("Please select ToHrs");
        // if(!TotalHrs) return alert("Please enter TotalHrs");
        if(!Classofvehicle) return alert("Please enter class of topic");
        // if(!Signatureofinsturctor) return alert("Please enter Signature of insturctor");
        // if(!SignatureofTrainer) return alert("Please enter Signature of Trainer");
        const config = {
          url: "/admin/addclass",
          method: "post",
          baseURL: "https://jaidrivingschool.in/api",
          headers: { Authorization: `Bearer ${token}`, "content-type": "multipart/form-data" },
          data:{id:item1?._id,authId:admin?._id,StudentName:item?.customerName,picupTime:FromHrs,studentId:item?.customerId?._id,classTopic:Classofvehicle,classday:allData.length+1,selectdate:classDate,selectTime:FromHrs,customerId:item?.customerId?._id,trainerId:item?.trainerId?._id ,mobile:item?.mobile,status:"Complete",studentStatus:"Complete",endtime:ToHrs}
        }
        //item?.customerId?._id
       let res=await axios(config)
       if(res.status==200){
       alert("Successfully added")
         getAttenenceByCustomerId()
       handleClose1();
        setclassDate("");
        setFromHrs("");
        setToHrs("");
        setTotalHrs("");
        setClassofvehicle("");
        setSignatureofinsturctor("");
        setSignatureofTrainer("");}
    } catch (error) {
        console.log(error);
    }
}
    return (
        <div >
            <Container >
                <div className="row">
                    <div className="col-md-12">
                   
                        <button className="btn btn-warning" style={{ float: "right" }} onClick={createPDF}>
                            Download Form 15{" "}
                            <i
                                class="fa-solid fa-file-arrow-down "
                                style={{ marginLeft: "10px" }}
                            ></i>
                        </button>
                        {" "}
                        {item ? (  <button className="btn btn-warning" style={{ float: "right" ,margin:"0 10px 0 10px"}} onClick={handleShow1}>
                            +Add Attandance{" "}
                            
                        </button>):(  <button className="btn btn-warning" style={{ float: "right" ,margin:"0 10px 0 10px"}} onClick={handleShow}>
                            +Add Attandance{" "}
                            
                        </button>)}
                      
                    </div>
                </div>
                {item ? (  <div className='form-14 ' id='pdf'>

<div className='text-center'>
    <h2 className='form-label'>FORM 15</h2>
  
</div>

<div className='text-center'>
    <h2 className='text-uppercase'>jai motor driving school </h2>
    {branch?.Adress ? ( <p style={{ fontSize: '14px', margin: '0px' }}>{branch?.Adress} {branch?.area}-{branch?.pincode}.</p>):( <p style={{ fontSize: '14px', margin: '0px' }}>site No. 185/2B, "Chennkeshav Comercial complex", Shop No,5 & 6, Thindlu Main Road <br /> Oppt to Shirihari Service Station, Virupakshpura, Vidyranyapura Post, Banglore-560097.</p>)}
   {branch?.branchNumber ? ( <p className='fw-bold mb-0' style={{ fontSize: '14px' }}> ph : {branch?.branchNumber} | Email : {branch?.branchEmail} </p>):( <p className='fw-bold mb-0' style={{ fontSize: '14px' }}> ph : 080-49917114 | Mob : 9845091018 | 8618360152 </p>)}
   
    <p className='fw-bold'>Register Showing the enrollment of trainee(s) in the driving schoole stablishment</p>
</div>
<hr />

<div className='d-flex gap-2'>
    <p className='form-label'>Name of the Trainee : </p>
    <p className='text-underline' style={{ width: '300px' }}>{item?.customerName}</p>
</div>
<div className='d-flex gap-2'>
    <p className='form-label'>Enrollment Number : </p>
    <p className='text-underline' style={{ width: '300px' }}>{item?.customerId?.customerId}</p>
</div>
<div className='d-flex gap-2'>
    <p className='form-label'>Date of Enrollment : </p>
    <p className='text-underline' style={{ width: '309px' }}>{item?.customerId ? (moment(item?.customerId?.createdAt).format("DD/MM/YYYY")):""}</p>
</div>

<table className='table table-bordered'>
    <thead>
        <tr>
            <th rowSpan={2}>Sl No.</th>
            <th rowSpan={2}>Date</th>
            <th colspan="3" >Hours Spent In Driving School</th>
            <th rowSpan={2}>Class of vehicle</th>
            <th rowSpan={2}>Signature of Insturctor</th>
            <th rowSpan={2}>Signature of Trainee</th>

        </tr>

        <tr>
            <th>From Hrs</th>
            <th>To Hrs</th>
            <th>Total Hrs</th>
        </tr>
    </thead>
    <tbody>
        {allData?.filter((a)=>a.trainerId?._id==item?.trainerId?._id).map((ele,i)=>{
            return (   <tr>
            <td>{i+1}</td>
            <td>{moment(ele?.selectdate).format("DD/MM/YYYY")}</td>
            <td>{ele?.picupTime}</td>
            <td>{ele?.endtime}</td>
            <td>{diffTime(ele?.picupTime,ele?.endtime)} </td>
            <td>{item?.vechicleType}</td>
            <td>{ele?.trainerId?.name}</td>
            <td>{ele?.StudentName}</td>
           
        </tr>)
        })}
     
     
    </tbody>
</table>

<div className='d-flex gap-2'>
    <p className='form-label'>Signature of the Trainee : </p>
    <p className='text-underline' style={{ width: '300px' }}>{item?.customerName}</p>
</div>

</div>):(  <div className='form-14 ' id='pdf'>

<div className='text-center'>
    <h2 className='form-label'>FORM 15</h2>
  
</div>

<div className='text-center'>
    <h2 className='text-uppercase'>jai motor driving school </h2>
    {branch?.Adress ? ( <p style={{ fontSize: '14px', margin: '0px' }}>{branch?.Adress} {branch?.area}-{branch?.pincode}.</p>):( <p style={{ fontSize: '14px', margin: '0px' }}>{item1?.branchAddress}.</p>)}
   {branch?.branchNumber ? ( <p className='fw-bold mb-0' style={{ fontSize: '14px' }}> ph : {branch?.branchNumber} | Email : {branch?.branchEmail} </p>):( <p className='fw-bold mb-0' style={{ fontSize: '14px' }}> ph :{item1?.branchNumber} | Email : {item1?.branchEmail} </p>)}
   
    <p className='fw-bold'>Register Showing the enrollment of trainee(s) in the driving schoole stablishment</p>
</div>
<hr />

<div className='d-flex gap-2'>
    <p className='form-label'>Name of the Trainee : </p>
    <p className='text-underline' style={{ width: '300px' }}>{item1?.Name}</p>
</div>
<div className='d-flex gap-2'>
    <p className='form-label'>Enrollment Number : </p>
    <p className='text-underline' style={{ width: '300px' }}>{item1?.EnrollmentNumber}</p>
</div>
<div className='d-flex gap-2'>
    <p className='form-label'>Date of Enrollment : </p>
    <p className='text-underline' style={{ width: '309px' }}>{item1?.DateofEnrollment}</p>
</div>

<table className='table table-bordered'>
    <thead>
        <tr>
            <th rowSpan={2}>Sl No.</th>
            <th rowSpan={2}>Date</th>
            <th colspan="3" >Hours Spent In Driving School</th>
            <th rowSpan={2}>Class of vehicle</th>
            <th rowSpan={2}>Signature of Insturctor</th>
            <th rowSpan={2}>Signature of Trainee</th>

        </tr>

        <tr>
            <th>From Hrs</th>
            <th>To Hrs</th>
            <th>Total Hrs</th>
        </tr>
    </thead>
    <tbody>
        {item1?.Attandance?.map((ele,i)=>{
            return (   <tr>
            <td>{i+1}</td>
            <td>{ele?.classDate}</td>
            <td>{ele?.FromHrs}</td>
            <td>{ele?.ToHrs}</td>
            <td>{ele?.TotalHrs} </td>
            <td>{ele?.Classofvehicle}</td>
            <td>{ele?.Signatureofinsturctor}</td>
            <td>{ele?.SignatureofTrainer}</td>
        </tr>)
        })}
     
     
    </tbody>
</table>

<div className='d-flex gap-2'>
    <p className='form-label'>Signature of the Trainee : </p>
    <p className='text-underline' style={{ width: '300px' }}>{item1?.Name}</p>
</div>

</div>)}
              
            </Container>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Form 15</Modal.Title>
                </Modal.Header>
                <Modal.Body>
            
                    <label>Attendence Details</label>
                    <div style={{border:"2px solid red",borderRadius:"10px",padding:"10px"}}>
                    <div className="do-sear mt-2">
                        <label>Attendence Date</label>
                        <input type="date" className="vi_0" placeholder="Please enter Date" value={classDate} onChange={(e)=>setclassDate(e.target.value)}/>
                    </div>

                    <div className="do-sear mt-2">
                        <label style={{ textAlign: "center" }}>Hours Spent in Driving School</label><br />
                        <div className="row " style={{ marginLeft: "-12px" }}>
                            <div className="col-md-4">
                                <label htmlFor="">Start From</label>
                                <input type="time" className="vi_0" placeholder="Please enter Start time" value={FromHrs} onChange={(e)=>setFromHrs(e.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Till</label>
                                <input type="time" className="vi_0" placeholder="Please enter Till" value={ToHrs} onChange={(e)=>setToHrs(e.target.value)} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Total Hrs</label>
                                <input type="text" className="vi_0" placeholder="Total Hrs" value={TotalHrs} onChange={(e)=>setTotalHrs(e.target.value)}/>
                            </div>
                        </div>

                            

                    </div>
                    <div className="do-sear mt-2">
                        <label>Class Of Vehicle</label>
                        <input type="text" className="vi_0" placeholder="Please enter Class Of Vehicle " value={Classofvehicle} onChange={(e)=>setClassofvehicle(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Signature / Name of  Instructor</label>
                        <input type="text" className="vi_0" placeholder="Please enter Signature / Name of  Instructor" value={Signatureofinsturctor} onChange={(e)=>setSignatureofinsturctor(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Signature / Name of  Trainee</label>
                        <input type="text" className="vi_0" placeholder="Please enter Signature / Name of  trainee" value={SignatureofTrainer} onChange={(e)=>setSignatureofTrainer(e.target.value)} />
                    </div>
                 
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={AddClass}>Submit</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={show1}
                onHide={handleClose1}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Attendence Form 15</Modal.Title>
                </Modal.Header>
                <Modal.Body>
            
                    <label>Attendence Details</label>
                    <div style={{border:"2px solid red",borderRadius:"10px",padding:"10px"}}>
                    <div className="do-sear mt-2">
                        <label>Attendence Date</label>
                        <input type="date" className="vi_0" placeholder="Please enter Date" value={classDate} onChange={(e)=>setclassDate(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Class Topic</label>
                        <input type="text" className="vi_0" placeholder="Please enter Date" value={Classofvehicle} onChange={(e)=>setClassofvehicle(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label style={{ textAlign: "center" }}>Hours Spent in Driving School</label><br />
                        <div className="row " style={{ marginLeft: "-12px" }}>
                            <div className="col-md-4">
                                <label htmlFor="">Start From</label>
                                <input type="time" className="vi_0" placeholder="Please enter Start time" value={FromHrs} onChange={(e)=>setFromHrs(e.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Till</label>
                                <input type="time" className="vi_0" placeholder="Please enter Till" value={ToHrs} onChange={(e)=>setToHrs(e.target.value)} />
                            </div>
                            {/* <div className="col-md-4">
                                <label htmlFor="">Total Hrs</label>
                                <input type="text" className="vi_0" placeholder="Total Hrs" value={TotalHrs} onChange={(e)=>setTotalHrs(e.target.value)}/>
                            </div> */}
                        </div>

                            

                    </div>
                    {/* <div className="do-sear mt-2">
                        <label>Class Of Vehicle</label>
                        <input type="text" className="vi_0" placeholder="Please enter Class Of Vehicle " value={Classofvehicle} onChange={(e)=>setClassofvehicle(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Signature / Name of  Instructor</label>
                        <input type="text" className="vi_0" placeholder="Please enter Signature / Name of  Instructor" value={Signatureofinsturctor} onChange={(e)=>setSignatureofinsturctor(e.target.value)}/>
                    </div>
                    <div className="do-sear mt-2">
                        <label>Signature / Name of  Trainer</label>
                        <input type="text" className="vi_0" placeholder="Please enter Signature / Name of  Trainer" value={SignatureofTrainer} onChange={(e)=>setSignatureofTrainer(e.target.value)} />
                    </div> */}
                 
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={registerUserAddAttandance}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
