import React from "react";
import { useNavigate } from "react-router-dom";

const Continue = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <div style={{ position: "relative" }}>
        <img src="/images/choose.jpg" alt="" width={1275} height={400} /> */}
      <div className="form3">
          <h4 style={{ color: " #001A33", fontWeight: "bold",  }}>
            SELECT YOUR TIMINGS...
          </h4>
          <h6>From:</h6>

          <select name="" id="" className="timing">
            <option value="">-----select-----</option>
            <option value="">06:00 AM-8:00 AM</option>
            <option value="">08:00 AM-10:00 AM</option>
            <option value="">10:00 AM-12:00 AM</option>
            <option value="">----Lunch Break----</option>
            <option value="">02:00 PM-4:00 PM</option>
            <option value="">04:00 PM-6:00 PM</option>
            <option value="">06:00 PM-8:00 PM</option>
          </select>
          <br />
          <h6>To:</h6>

          <select name="" id="" className="timing">
            <option value="">-----select-----</option>
            <option value="">06:00 AM-8:00 AM</option>
            <option value="">08:00 AM-10:00 AM</option>
            <option value="">10:00 AM-12:00 AM</option>
            <option value="">----Lunch Break----</option>
            <option value="">02:00 PM-4:00 PM</option>
            <option value="">04:00 PM-6:00 PM</option>
            <option value="">06:00 PM-8:00 PM</option>
          </select>
          <br />
          <br />
          <br />
      </div>
      <button
        type="submit"
        onClick={() => navigate("/booknow")}
        class="btn btn-warning continuebtn"
        style={{ width: "7rem", float: "left", marginLeft:"50px", marginBottom:"20px" }}
      >
        previous
      </button>
      <button
        type="submit"
        onClick={() => navigate("/choose")}
        class="btn btn-warning continuebtn2"
        style={{
          float: "right",
          width: "8rem",
          // height: "50px",
          // marginTop: "60px",
          marginRight:"50px"
        }}
      >
        Save&Continue
      </button>

      {/* </div> */}
    </div>
  );
};

export default Continue;
