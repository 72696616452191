import React, { useEffect, useState } from "react";
import Side1 from "./sidebar1/Side1";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Button, Form, Modal, ModalBody } from "react-bootstrap";
// import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import useRazorpay from "react-razorpay";
const Payrescietdate = () => {
  const location = useLocation();
  console.log("fdfs", location?.state);

  const Razorpay = useRazorpay();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.error = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [allCourse, setallCourse] = useState([]);

  const [invoice, setInvoice] = useState("");
  const getBookDeatailsByCustomerId = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getPayReciptByUserId/" +
          user?._id +
          "/" +
          user?._id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [wallet,setwallet]=useState({})
const CreateWallet=async()=>{
  try {
    const config = {
      url: "/wallet/createWalletCustomer",
      method: "post",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data: {
        authId: user?._id,
        customerId: user?._id,
      },
    };
    let res=await axios(config)
    if(res.status==200){
      setwallet(res.data.success)
    }
  } catch (error) {
    alert(error.response.data.error)
    console.log(error);
  }
}

  useEffect(() => {
    if (user) {
      CreateWallet();
      getBookDeatailsByCustomerId();
    }
  }, []);

  const [ShowPay, setShowPay] = useState(false);
  const [payMethod, setpayMethod] = useState("Online");
  const [payId, setpayId] = useState("");
  const [Name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [Id, setId] = useState("");
  const [paynow, setpaynow] = useState(0);
  const [Pdata, setPdata] = useState({});
  const addAcountHistory = async (bookId) => {
    try {
      const config = {
        url: "/admin/addAccount",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          customeId: Id,
          bookingId: bookId,
          paymentId: payId,
          amount: paynow,
          status: "Cr",
        },
      };
      await axios(config);
    } catch (error) {
      console.log(error);
    }
  };

  const payNowController = async () => {
    try {
      let status1 = Pdata?.status;
      let am = Pdata?.totalAmount + Pdata?.payAmount;
      if (am == paynow) {
        status1 = "Paid";
      }
      const config = {
        url: "/admin/MakePaymetPayrecipt",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          id: Pdata?._id,
          payId: payId,
          status: status1,
          payMethod: payMethod,
          payAmount: paynow,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully pay");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddTransaction=async()=>{
    try {
      if(wallet?.totalAmount<paynow) return alert(`Your wallet is less please add amount`)
        const config = {
        url: "/wallet/addTransaction",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data: {
          authId: user?._id,
          id:wallet?._id,heading:`Pay for payment Due to Jai Driving School`,payId:payId,amount:paynow,status:"DR"
        },
      };
      let res=await axios(config);
    //   if(res.status==200){
    //  return  payNowController()
     
    //   }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  const posttransaction = async () => {
    try {
      if (!payMethod) return alert("Please select payment method");
      if (payMethod == "Wallet") {
      return    setpayId(`WalletJDSC00${wallet?.transaction?.length+1}`)
        
      }

      let amount = 0;
      if (paynow) {
        amount = paynow * 100;
      }

      const config = {
        data: {
          key: "rzp_test_FAe0X6xLYXaXHe",
          amount: Math.round(amount),
          currency: "INR",
          name: "Jai Driving School",
          description: "Order Amount",
          image: "../images/logo.png.jpg",
          customerId: user?._id,
          handler: function (response) {
            alert(response.razorpay_payment_id);
            setpayId(response.razorpay_payment_id);
          },
          prefill: {
            name: user?.name,
            email: user?.email,
            contact: user?.mobile,
          },
        },
      };
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Fail to load");
        return;
      }
      const paymentObject = new Razorpay(config.data);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (payId) {
      if(payMethod=="Wallet"){
        AddTransaction()
      }
      addAcountHistory();
      payNowController();
    }
  }, [payId]);
  // console.log(wallet,"wwwwwww");
  return (
    <>
      <Side1 />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="scrolll_bar">
              <table className="table table-bordered border-dark ">
                <thead>
                  <tr className="admin-table-head">
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      S.No
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Date
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      ID
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Name
                    </th>

                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Branch Name
                    </th>
                    <th colSpan={3}>Rescipt Generation For</th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Total Amount
                    </th>

                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Pay Amount
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Remaining Amount
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      PayId
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Pay Method
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Payment Status
                    </th>
                    <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                      Action
                    </th>
                  </tr>
                  <tr>
                    <th>Courses</th>
                    <th>Additional Charges</th>
                    <th>Services</th>
                  </tr>
                </thead>
                {allCourse?.map((item, i) => {
                  return (
                    <tbody>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{moment(item?.createdAt)?.format("DD/MM/YYYY")}</td>
                        <td>{item?.Name}</td>
                        <td>{item?.Id}</td>
                        <td>{item?.branch}</td>
                        <td>
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th>Course Name</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{item?.courseData}</td>
                                <td>
                                  {item?.courseAmount == 0
                                    ? ""
                                    : item?.courseAmount}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          {" "}
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th>Additional Charges</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {" "}
                                  {item?.additional?.map((ele) => {
                                    return (
                                      <dive
                                        style={{ display: "flex", gap: "2px" }}
                                      >
                                        <div>{ele?.text}</div>
                                        <div>{ele?.price}</div>
                                      </dive>
                                    );
                                  })}
                                </td>
                                <td>
                                  {item?.addiCharge == 0
                                    ? ""
                                    : item?.addiCharge}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          {" "}
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th>Services</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{item?.serveData}</td>
                                <td>
                                  {item?.serveAmount == 0
                                    ? ""
                                    : item?.serveAmount}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>&#8377;{item?.totalAmount?.toFixed(2)}/-</td>
                        <td>&#8377;{item?.payAmount?.toFixed(2)}/-</td>
                        <td>
                          &#8377;
                          {Number(item?.totalAmount) - Number(item?.payAmount)}
                          /-
                        </td>
                        <td>{item?.payId}</td>
                        <td>{item?.payMethod}</td>
                        <td>
                          {item?.status == "Paid" ? (
                            <span style={{ color: "green" }}>
                              {item?.status}
                            </span>
                          ) : (
                            <span style={{ color: "red" }}>{item?.status}</span>
                          )}
                        </td>
                        {/* <td><i
                            class="fa fa-eye"
                            aria-hidden="true"
                            onClick={handleShow}
                          ></i></td> */}
                        <td style={{display:"flex",gap:"2px",justifyContent:"center"}}>
                          <i
                            class="fa fa-eye"
                            aria-hidden="true"
                            style={{color:"green",fontSize:"20px"}}
                            onClick={() =>
                              navigate("/invoice", { state: item })
                            }
                          ></i>
                          {item?.totalAmount - item?.payAmount > 0 ? (
                            <div style={{ display: "flex", gap: "2px" }}>
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => {
                                  setPdata(item);
                                  setId(item?.Id);
                                  setName(item?.Name);
                                  setpaynow(
                                    item?.totalAmount - item?.payAmount
                                  );
                                  setShowPay(true);
                                }}
                              >
                                PayNow
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Details And Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              boxShadow: " 0px 8px 32px 0px rgba(19, 19, 20, 0.37)",
              background: "#f5f6fa",
              backdropFilter: "blur(4px)",
              border: "1px solid black",
              // height: "",
              width: "100%",
              padding: "50px",
              borderRadius: "20px",
            }}
            id="pdf"
          >
            <div className="jdpoqwj_0" style={{ backgroundColor: "#dfe6e9" }}>
              <div className="container ">
                <div className="row">
                  <div className="col-md-1 mt-3">
                    <img
                      src="./images/logo.png.jpg"
                      alt=""
                      style={{ width: "70px" }}
                    />
                  </div>
                  <div className="col-md-9 text-center ">
                    <p
                      className="fw-bold fs-4 pt-5"
                      style={{ fontFamily: "initial" }}
                    >
                      JAI MOTOR DRIVING SCHOOL <br />{" "}
                      <p className="fw-normal fs-6">
                        Site No. 185/2B, “Chennakeshava Commercial Complex”,
                        Shop No. 5 & 6, Thindlu Main Road, Opp to Srihari
                        Service Station, Virupakshapura, Vidyaranyapura Post,
                        Bangalore –560097. Ph: 080-49917114 | Mob:
                        9845091018|8618360152
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container ">
              <div className="row mt-3">
                {/* <div className="col-md-6">
                <p
                  style={{
                    fontSize: "20px",
                    borderBottom: "3px solid #dfe6e9",
                    color: "#636e72",
                    fontWeight: "bold",
                  }}
                >
                  BILL TO
                </p>
                <p>
                  Xpress Interiors <br />
                  29AVCPM955G1Z7 <br />
                  Ground Floor, No 10 24/1, Raviteja Nilay, 19th Cross Road,{" "}
                  <br />
                  Valagerehalli, Kengeri Satellite Town, Bengaluru, Bengaluru{" "}
                  <br />
                  Urban, Karnataka, 560060 <br />M +91 98802 74572{" "}
                </p>
              </div> */}
                <div className="col-md-12">
                  <p
                    style={{
                      fontSize: "20px",
                      borderBottom: "3px solid #dfe6e9",
                      color: "#636e72",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <span style={{ float: "left" }}>
                      <span>Rescipt No:</span>{" "}
                      <span>#{location?.state?.bookid}</span>
                    </span>
                    <span>RESCIPT </span>
                    <span style={{ float: "right" }}>
                      {" "}
                      <span>Date:</span>{" "}
                      <span>
                        {moment(location?.state?.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </span>
                  </p>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <b>Received with thanks from </b>{" "}
                          <span>{user?.name}</span> <br />
                        </p>
                        <p>
                          <b>The Sum of Rupees :</b>{" "}
                          <span>
                            &#8377;{location?.state?.totalAmount}/- Only
                          </span>
                        </p>
                        <p>
                          <b>Payment Mode:</b>
                          <span>{location?.state?.paymentMethod}</span>{" "}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <b>Course Name:</b>{" "}
                          <span>{location?.state?.courseTitle}</span>
                        </p>
                        <p>
                          <b>Balance:</b>
                          <span>
                            &#8377;{" "}
                            {Number(location?.state?.totalAmount) -
                              Number(location?.state?.payAmount)}
                            /-
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <table className="table table-bordered border-dark">
                    {/* <thead>
                    <tr className="admin-table-head">
                      <th className="fw-bold">SL No</th>
                      <th className="fw-bold">SERVICE TYPE</th>
                      <th className="fw-bold"> AMT PAYABLE</th>
                      <th className="fw-bold">AMOUNT PAIED</th>
                      <th className="fw-bold">BALANCE AMT </th>
                      <th className="fw-bold">TOTAL </th>
                    </tr>
                  </thead> */}

                    <tbody>
                      <tr>
                        <th>Total Amount </th>
                        <td>&#8377;{location?.state?.totalAmount}/-</td>
                      </tr>
                      <tr>
                        <th>Amount Paid </th>
                        <td>&#8377;{location?.state?.payAmount}/-</td>
                      </tr>
                      <tr>
                        <th>Balance Due </th>
                        <td>
                          &#8377;
                          {Number(location?.state?.totalAmount) -
                            Number(location?.state?.payAmount)}
                          /-
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          style={{ borderColor: "black" }}
                        />
                        <label
                          class="form-check-label text-dark"
                          for="flexCheckChecked"
                        >
                          Online
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          style={{ borderColor: "black" }}
                        />
                        <label
                          class="form-check-label text-dark"
                          for="flexCheckChecked"
                        >
                          Cash
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          style={{ borderColor: "black" }}
                        />
                        <label
                          class="form-check-label text-dark"
                          for="flexCheckChecked"
                        >
                          Wallet
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
                <div className="col-md-4">
                  <div className="text-center totalAmount">
                    <span
                      style={{
                        border: "1px solid black",
                        //   width: "",
                        padding: "10px 100px",
                        borderRadius: "15px",
                      }}
                    >
                      <span>&#8377; {location?.state?.totalAmount}/- </span>
                    </span>{" "}
                    <div style={{ marginTop: "10px" }}>
                      <label
                        class="form-check-label text-dark mt-1"
                        for="flexCheckChecked"
                      >
                        For JAI MOTOR DRIVING SCHOOL
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
            className="container mt-4"
            style={{
              border: "3px solid #dfe6e9",
              padding: "5px",
              height: "40px",
            }}
          >
            <div className="row">
              <div className="col-md-1">
                <p>S No.</p>
              </div>
              <div className="col-md-2">
                <p style={{ float: "right" }}>TOTAL AMOUNT </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}> AMOUNT PAID</p>
              </div>
              <div className="col-md-2 ">
                <p style={{ float: "right" }}>BALANCE DUE</p>
              </div>
              <div className="col-md-2">
                <p style={{ float: "right" }}>TOTAL</p>
              </div>
            </div>
          </div> */}
            {/* <div className="container">
            <div className="row">
              <div className="col-md-1">1.</div>
              <div className="col-md-2">
                <p>
                  {" "}
                  <span className="fw-bold">
                    BONDED WITH HR SOFTY AND LATEX (78*72*8)
                  </span>
                  <br />
                  <span>10 years warranty on mattress excluding fabric</span>
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;17000</p>
              </div>
              <div className="col-md-2">
                <p style={{ float: "right" }}>1</p>
              </div>
              <div className="col-md-2">
                <p style={{ float: "right" }}>&#8377;17000</p>
              </div>
              <hr />
            </div>
          </div> */}

            {/* <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>SUBTOTAL</p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;17000</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>GST (18%) </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;3,203.28</p>
              </div>
              <hr />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>TOTAL </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;20,999.28</p>
              </div>
              <hr />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p style={{ float: "right", fontWeight: "bold" }}>
                  BALANCE DUE{" "}
                </p>
              </div>
              <div className="col-md-4">
                <p style={{ float: "right" }}>&#8377;20,999.28</p>
              </div>
              <hr />
            </div>
          </div> */}
            <div className="container mt-5">
              <div className="row">
                <div className="col-md-12">
                  <span style={{ float: "right" }}>Signature</span>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <span style={{ fontSize: "15px", fontFamily: "initial" }}>
                    This is Computer Generated Rescipt
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleShow1}>
            {" "}
            Pay Now
          </Button>
        </Modal.Footer>
      </Modal>


      {/* pay Model */}
      <Modal show={ShowPay} onHide={() => setShowPay(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Payments Modes Online</h4>
          <Form>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Online"
                  name="group1"
                  type={type}
                  checked={payMethod == "Online"}
                  onClick={() => setpayMethod("Online")}
                  // id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label={`Wallet (₹${wallet?.totalAmount?.toFixed(2)})`}
                  onClick={() => setpayMethod("Wallet")}
                  name="group1"
                  type={type}
                  // id={`inline-${type}-2`}
                />
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h5 style={{ float: "right" }} className="mb-3">
              Remaining Amount:- ₹ {paynow?.toFixed(2)}
            </h5>
          </div>
          <div>
            <Button variant="secondary" onClick={() => setShowPay(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              style={{ marginLeft: "10px" }}
              onClick={posttransaction}
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Payrescietdate;
