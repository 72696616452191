import axios from "axios";
import React, { useState } from "react";


function VendorLogin() {
  const [email,setemail]=useState("");
  const [password,setpassword]=useState("");
  const loginAdmin=async ()=>{
    // e.preventDefault()
    try {
      const config={
        url:"/admin/getBranchLogin",
        method:"post",
        baseURL:"https://jaidrivingschool.in/api",
        headers:{"content-type":"application/json"},
        data:{branchEmail:email,password:password}
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully login");
        window.sessionStorage.setItem("branch",JSON.stringify(res.data.success))
        window.sessionStorage.setItem("token",res.data.token);
        window.location.assign("/Branch-dashboard")
      }
    } catch (error) {
      alert(error.response.data.error)
      console.log(error);
    }
  }
  return (
    <>
      <div className="add">
        <div className="container">
          <div className="fw_90">
            <div className="add_0">
              <div className="im09">
                <img src="../images/logo.png.jpg" alt="login" className="di_90" />
              </div>
              <div className="add-90">
                <label style={{margin:"10px",textAlign:"center",fontSize:"27px",fontWeight:"bold"}}>Branch Login</label>
                <form>
                  <div className="sd_00 mb-2">
                    <label>Email</label> <br />
                    <input
                      type="email"
                      placeholder="email@gmail.com"
                      className="name_0"
                      value={email}
                      onChange={(e)=>setemail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="sd_00 mb-2">
                    <label>Password</label>
                    <br />
                    <input
                      type="password"
                      placeholder="password"
                      className="name_0"
                      value={password}
                      onChange={(e)=>setpassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="sd_00 mt-2">
                   
                      {" "}
                      <button type="button" onClick={(e)=>loginAdmin(e)}>Login</button>
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorLogin;
