import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap/cjs";
import Table from "react-bootstrap/Table";

function Admindashboard() {
  const [AllCustomer, setAllCustomer] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");
  const getCustomer = async () => {
    try {
      const config = {
        url: "/Customer/getAllCustomer",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllCustomer(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllBooking, setAllBooking] = useState([]);

  const getAllBooking = async () => {
    try {
      const config = {
        url: "/admin/getAllBooking/" + admin?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllBooking(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [AllTrainer, setAlltrainer] = useState([]);

  const getTrainer = async () => {
    try {
      const config = {
        url: "/staff/getAllSudents",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const [AllBooking,setAllBooking]=useState([]);
  const [AllServiceBook, setAllServiceBook] = useState([]);
  const getAllBookingService = async () => {
    try {
      const config = {
        url: "/admin/getAllServiceBooking",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAllServiceBook(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allStaff, setallstaff] = useState([]);
  const getAllSatff = async () => {
    try {
      const config = {
        url: "/admin/staff/getAllStaffs",
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setallstaff(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [allBranch, setallbranch] = useState([]);

  const getAllBranches = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBranchByAdmin/" +
          admin?._id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        setallbranch(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCustomer();
    getAllBooking();
    getTrainer();
    getAllSatff();
    getAllBookingService();
    getAllBranches();
  }, []);
  const [add, setadd] = useState(true);
  const [add1, setadd1] = useState(true);
  const [add2, setadd2] = useState(true);
  return (
    <>
      {/* <div className="add-gr">
        <div className="ad-dash mt-5">
          <div className="container">
            <div className="vina">
              <div className="vina-left">
                <div className="row">
                  <div className="col-md-8">
                    
                  </div>
                </div>
              </div>
              <div className="np-p mt-4">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dask">
                      <div className="do-p">Students/Customers</div>
                      <div className="no-p">
                        <p>{AllCustomer?.length}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dask">
                      <div className="do-p"> Staffs</div>
                      <div className="no-p">
                        <p>{allStaff?.length}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dask">
                      <div className="do-p">Instructors</div>
                      <div className="no-p">
                        <p>{AllTrainer?.length}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dask">
                      <div className="do-p"> Total Sevice Booking</div>
                      <div className="no-p">
                        <p>{AllServiceBook?.length}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dask">
                      <div className="do-p"> Total Course Booking</div>
                      <div className="no-p">
                        <p>{AllBooking?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div> */}
      <div className=" add-gr ad-dash pt-3">
        <div className="container">
          <div className="dash-0 mb-3">
            <div className="row">
              {admin?.Customer==true ? ( <div className="col-md-4">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        <a href="/admincustomer">View All</a>{" "}
                      </div>{" "}
                      <br />
                      <p style={{ textAlign: "center" }}>
                        No. of Students/Customers Enrolled
                      </p>
                      <p
                        className="text-center"
                        style={{
                          borderTop: "1px solid darkgray",
                          paddingTop: "20px",
                        }}
                      >
                        {AllCustomer?.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
              {admin?.Employee==true ? (   <div className="col-md-4">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        <a href="/adminstaff">View All</a>{" "}
                      </div>{" "}
                      <br />
                      <p style={{ textAlign: "center" }}>No. of Staff</p>
                      {/* <br /> */}
                      <p
                        className="text-center"
                        style={{
                          borderTop: "1px solid darkgray",
                          paddingTop: "20px",
                        }}
                      >
                        {allStaff?.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
              {admin?.Employee==true ? (<div className="col-md-4">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        <a href="/admintrainer">View All</a>{" "}
                      </div>{" "}
                      <br />
                      <p style={{ textAlign: "center" }}>No. of Trainers</p>
                      {/* <br /> */}
                      <p
                        className="text-center"
                        style={{
                          borderTop: "1px solid darkgray",
                          paddingTop: "20px",
                        }}
                      >
                        {AllTrainer?.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
              {admin?.ServicesA==true ? ( <div className="col-md-4">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        <a href="/Book-services">View All</a>{" "}
                      </div>{" "}
                      <br />
                      <p style={{ textAlign: "center" }}>
                        No. of Booked Services
                      </p>
                      {/* <br /> */}
                      <p
                        className="text-center"
                        style={{
                          borderTop: "1px solid darkgray",
                          paddingTop: "20px",
                        }}
                      >
                        {AllServiceBook?.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
              {admin?.Courses==true ? (<div className="col-md-4">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        <a href="/adminstudent">View All</a>{" "}
                      </div>{" "}
                      <br />
                      <p style={{ textAlign: "center" }}>
                        No. of Booked Courses
                      </p>
                      {/* <br /> */}
                      <p
                        className="text-center"
                        style={{
                          borderTop: "1px solid darkgray",
                          paddingTop: "20px",
                        }}
                      >
                        {AllBooking?.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
              {admin?.Branch==true ? (   <div className="col-md-4">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        <a href="/branches">View All</a>{" "}
                      </div>{" "}
                      <br />
                      <p style={{ textAlign: "center" }}>No. of Branches</p>
                      {/* <br /> */}
                      <p
                        className="text-center"
                        style={{
                          borderTop: "1px solid darkgray",
                          paddingTop: "20px",
                        }}
                      >
                        {allBranch?.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
           
            </div>
          </div>
          <div className="dash-0 mb-3">
            <div className="row">
              {admin?.Courses==true ? (  <div className="col-md-6">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        {" "}
                        <a href="/adminstudent">View All</a>
                      </div>
                      <br />
                      <p style={{ textAlign: "center" }}>
                        Recently Booked Courses
                      </p>
                    </div>

                    <div>
                      <div className="admin-list res-0">
                        <Table striped bordered hover>
                          <thead>
                            <th>Sl.No</th>
                            <th>Booking ID</th>
                            <th>Student Name</th>
                            <th>Branch</th>
                            <th>Course</th>
                          </thead>
                          <tbody>
                            {AllBooking?.slice(0, 5)?.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{item?.bookid}</td>
                                  <td>{item?.customerName}</td>
                                  <td>{item?.branchName}</td>
                                  <td>{item?.courseTitle}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
            {admin?.ServicesA==true? ( <div className="col-md-6">
                <div className="ad-pa">
                  <div className="wraper-0">
                    <div className="wrapper-ad joi">
                      <div className="view-wrap">
                        <a href="/Book-services">
                        View All
                        </a>
                      </div>
                      <br />
                      <p style={{ textAlign: "center" }}>
                        Recently Booked Services
                      </p>
                    </div>

                    <div>
                      <div className="admin-list admin-list res-0">
                        <Table
                          striped
                          bordered
                          hover
                          style={{ textAlign: "center" }}
                        >
                          <thead>
                            <th>Sl.No</th>
                            <th>Booking ID</th>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Services</th>
                          </thead>
                          <tbody>
                            {AllServiceBook?.slice(0, 4)?.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{item?.bookid}</td>
                                  <td>{item?.customerName}</td>
                                  <td>{item?.customerId?.Address}</td>
                                  <td>{item?.Servicename}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>):(<></>)}
             
            </div>
          </div>
          <div className="container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4>Vehicle Validity</h4>
              <div>
                <a
                  href="/category"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  {" "}
                  View All
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                        Vehicle No.
                      </th>
                      <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                        Vehicle Type
                      </th>
                      <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                        RC Image
                      </th>
                      <th colSpan={2}>RC Validity</th>
                      <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                        Insurance Image
                      </th>
                      <th colSpan={2}>Insurance Validity</th>
                      <th rowSpan={2} style={{ paddingBottom: "30px" }}>
                        {" "}
                        Emission Image
                      </th>
                      <th colSpan={2}>Emission Validity</th>
                    </tr>
                    <tr>
                      <th>Starting Date</th>
                      <th>Ending Date</th>
                      <th>Starting Date</th>
                      <th>Ending Date</th>
                      <th>Starting Date</th>
                      <th>Ending Date</th>
                    </tr>
                  </thead>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admindashboard;
