import React, { useEffect, useState } from "react";
import Side from "./sidebar/Side";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { RiDeleteBinLine } from "react-icons/ri";

const TimeSlot = () => {
  let trainer = JSON.parse(sessionStorage.getItem("staff"));
  let token = sessionStorage.getItem("token");
  const [time, settime] = useState("")
  const [trainerData, setTrainerData] = useState({});
  const getTrainerById = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/staff/getStaffById/" + trainer?._id);
      if (res.status == 200) {
        setTrainerData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const AddTimeSlot = async () => {
    try {
      if (!time) return alert("Please select time")
      const config = {
        url: "/staff/addTimeSlotofTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },
        data: {
          authId: trainer?._id,
          id: trainer?._id,
          times: time
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [show4, setshow4] = useState(false);
  const handleShow4 = () => setshow4(true);
  const handleClose4 = () => setshow4(false);
  const [deleteId, setdeleteId] = useState("");
  const removeSlot = async (id) => {
    try {
      const config = {
        url: "/staff/removeTimeSlotofTrainer",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" },
        data: {
          authId: trainer?._id,
          id: trainer?._id,
          removeId: deleteId
        }
      }
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully deleted");
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [alltime, setAlltime] = useState([]);

  const gettimeSlot = async () => {
    try {
      let res = await axios.get("https://jaidrivingschool.in/api/admin/getslotTime");
      if (res.status == 200) {
        setAlltime(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (trainer) {
      getTrainerById();
    }
    gettimeSlot()
  }, [])
  return (
    <div>
      <Side />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h4>Add Avaliable Timings</h4>
            <div className="container">
              <div className="d-flex">
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ width: "250px" }}
                    // id="timeslot"
                    onChange={(e) => settime(e.target.value)}
                  >
                    <option>---Select Your Slot---</option>
                    {alltime?.map((item) => {
                      return (
                        <option value={item?.AvailableTime}>{item?.AvailableTime}</option>
                      )
                    })}

                  </Form.Select>
                </div>
                <div>
                  <button className="btn btn-warning" onClick={AddTimeSlot}>Add</button>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <h4>Avaliable Slot list</h4>
            {trainerData?.availableSlot?.map((item) => {
              return (
                <div className="container">
                  <div className="d-flex justify-content-between">
                    <div >
                      <p>{item?.times}</p>
                    </div>
                    <div>
                      <RiDeleteBinLine style={{ color: "red", fontSize: "25px", cursor: "pointer" }} onClick={() => {
                        setdeleteId(item?._id);
                        handleShow4()
                      }} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>



      </div>
      {/* deleteMode */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure delete??</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={removeSlot}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TimeSlot;
