import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
const Gallery = () => {
  const [AllGallery, setAllgallery] = useState([]);
  const getGallery = async () => {
    try {
      const res = await axios.get("https://jaidrivingschool.in/api/admin/getGallery");
      if (res.status == 200) {
        setAllgallery(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getGallery()
  }, [])
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 9;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(AllGallery.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "130px",
            background: "black",
            overflow: " hidden",
            background: " #C33764",
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Gallery</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "white" }}>&raquo;</span>
            </li>
            <li style={{ color: "white", fontSize: "18px" }}> Gallery</li>
          </ul>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          {AllGallery?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((ele) => {
            return (
              <div className="col-md-4 gallery" >
                <a href={`https://jaidrivingschool.in/gallery/${ele?.image}`} target="_blank">
                  <img src={`https://jaidrivingschool.in/gallery/${ele?.image}`} alt="" width={300} height={200} /></a>
                <h4 >{ele?.imageType}</h4>
              </div>
            )
          })}


        </div>


      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}

      />
    </div>
  );
};

export default Gallery;
