import { height } from '@mui/system'
import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import axios from 'axios';
import parse from "html-react-parser";

const Traffic1 = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [AllSafetyRule, setAllgraph] = useState([]);
    const getAllSafetyRules = async () => {
        try {
            const res = await axios.get("https://jaidrivingschool.in/api/admin/getSAFETYRULE");
            if (res.status == 200) {
                setAllgraph(res.data.success);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getAllSafetyRules()
    }, [])
    const [data1, setdata1] = useState({});

    return (
        <div>
            <div className="" >
                <div
                    class="module mid"
                    style={{
                        height: "130px",
                        background: "black",
                        overflow: " hidden",
                        background: " #C33764",
                        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
                        backgroundSize: "cover",
                    }}
                >
                    <h2>Safety Rules</h2>
                    <ul
                        class=" container breadcrumbs bc3 pt-3 pb-3"
                        role="menubar"
                        aria-label="breadcrumbs"
                    >
                        <li>
                            <a href="/">Home</a>{" "}
                            <span style={{ color: "white" }}>&raquo;</span>
                        </li>
                        <li style={{ color: "white", fontSize: "18px" }}> Safety Rules</li>
                    </ul>
                </div>
            </div>
            {/* <div className="container">
                <div className="row">
                    <div className="col">
                        <h4>Important Traffic Signs to Follow in India!</h4>
                        <p style={{ textAlign: "justify" }}>
                            Traffic signs are the salient speakers, preventing drivers and
                            pedestrians from fatal risks on the road. More than 400 road accidents
                            occur daily on Indian roads that cost lives and 3% of total annual
                            GDP.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Therefore, it is important to know 3 main categories of traffic signs
                            in India to ensure road safety.
                        </p>
                        <p>
                            Here we have listed details about traffic signs in a consolidated form
                            to make it easier to understand.
                        </p>
                    </div>

                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col ">
                        <h4>What Are the Traffic Signs in India?</h4>
                        <p style={{ textAlign: "justify" }}>
                            Understanding traffic signs is crucial for optimum safety on the road.
                            These traffic signs communicate the basic rules and regulations of
                            road safety in the form of extremely simple graphics that can be
                            easily understood within seconds. In fact, any person applying for a
                            driving license, for example, needs to be completely aware of all the
                            traffic signs to pass the theoretical exam of a driving test.
                            Discussed below are the various types of traffic signs or symbols for
                            Indian roads.
                        </p>

                    </div>

                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Traffic Signs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src="/images/trafficsign.jpeg" alt="" width={100} /><br />
                        Traffic signals and traffic lights are the most important set of safety rules that drivers and pedestrians must know and immediately recognize. These signals and symbols exist to prevent confusion and maintain order on the roads. Following traffic signals keeps you and others on the roads safe, and prevents traffic snarls, accidents and fatalities.

                        The meaning of the red, yellow and green traffic lights remains the same worldwide, but the types of traffic signals and regulations may differ country-wise. Read on to find out more about the traffic signal rules in India.  </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose} style={{color:"black"}}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div> */}
            <div className='container'>
                <h4>Different Types of Traffic Signs in India</h4>

            </div>
            {AllSafetyRule?.map((item) => {
                return (
                    <div className="container">

                        <div className="row">
                            <h4>{item?.category}</h4>
                            <div className="col-md-5">

                                <img src={`https://jaidrivingschool.in/SafetyRule/${item?.Image}`} alt="" style={{ width: "376px" }} />
                            </div>
                            <div className="col-md-7">
                                <p style={{ textAlign: "justify" }}>
                                    {item?.discription ? (parse((item?.discription).slice(0, 489))) : ""}
                                </p>
                                <Button variant="primary" onClick={() => {
                                    setdata1(item);
                                    handleShow()
                                }} style={{ color: "black", }}>
                                    View More
                                </Button>
                            </div>

                        </div>

                    </div>
                )
            })}
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{data1?.category}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={`https://jaidrivingschool.in/SafetyRule/${data1?.Image}`} alt="" width="100%" /><br />
                    {data1?.discription ? (parse(data1?.discription)) : ""}   </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} style={{ color: "black" }}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Traffic1