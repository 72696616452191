import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const VendorBlog = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const admin = JSON.parse(sessionStorage.getItem("branch"));
  const token = sessionStorage.getItem("token");

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    let ab =admin?.branchId ? admin?.branchId: admin?._id
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getBlogsByBranchId/"+ab,{
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
          }
        }
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [title, settitle] = useState("");
  const [blogCategory, setblogCategory] = useState("");
  const [coursePoint, setcoursePoint] = useState([]);
  const [price, setprice] = useState("");
  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");
  const [blog1, setblog1] = useState("");
  const [blog2, setblog2] = useState("");
  const [blog3, setblog3] = useState("");
  const [postBy, setpostBy] = useState(admin?.branchHeadrName);
  const [TheorySessions, setTheorySessions] = useState("");
  const [PracticalExam, setPracticalExam] = useState("");
  const [TheoryExam, setTheoryExam] = useState("");
  const [SimulatorSessions, setSimulatorSessions] = useState("");
  const [aboutCourse, setAboutCourse] = useState("");


  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setblog1(data);
  };
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setblog2(data);
  };
  const handleChange3 = (e, editor) => {
    const data = editor.getData();
    setblog3(data);
  };

  const AdminService = async () => {
    try {
      if (!image1) return alert("Please select image1");
      if (!image2) return alert("Please select image2");
      if (!title) return alert("Please enter tittle");
      if (!blogCategory) return alert("Please enter service type");

      if (!blog1) return alert("Please enter description 1!");
      if (!blog2) return alert("Please enter description 2!");
      if (!postBy) return alert("Please enter posted by name");

      const config = {
        url: "/admin/postBlog",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          title: title,
          category: blogCategory,
          image: image1,
          image1: image2,
          blog1: blog1,
          blog2: blog2,
          blog3: blog3,
          PostedBy: postBy,
          branchId:admin?._id,branchName:`${admin?.branchName} ${admin?.area}`,
          postedId: admin?._id,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        getAllCourse();
        handleClose();
        setimage1("");
        setimage2("");
        setpostBy("");
        settitle("");
        setblogCategory("");
        setblog1("");
        setblog2("");
        setblog3("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCourse();
  }, []);
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallCourse([...filterTable]);
    } else {
      setSearch(e.target.value);
      setallCourse([...nochangedata]);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allCourse.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [show1, setshow1] = useState(false);
  const handleClose1 = () => setshow1(false);
  const handleShow1 = () => setshow1(true);
  const [deleteId, setdeleteId] = useState("");

  const [edit, setedit] = useState({});
  const [edshow, setedshow] = useState(false);
  const handleShowed = (data) =>{
    setpostBy(data.PostedBy);
    settitle(data?.title);
  
    setblog1(data?.blog1);
    setblog2(data?.blog2);
    setblog3(data?.blog3);
    setedshow(true)};
  const handCloseed = () => setedshow(false);
  const updateCourse = async () => {
    try {
      const config = {
        url: "/admin/updateblog",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          id: edit?._id,
          authId: admin?._id,
          title: title,
          category: blogCategory,
          image: image1,
          image1: image2,
          blog1: blog1,
          blog2: blog2,
          blog3: blog3,
          PostedBy: postBy,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        getAllCourse();
        handCloseed();
        setimage1("");
        setimage2("");
        setpostBy("");
        settitle("");
        setblogCategory("");
        setblog1("");
        setblog2("");
        setblog3("");
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };

  const deleteCourses = async () => {
    try {
      const congig = {
        url: "/admin/deleteBlog/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(congig);
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose1();
        getAllCourse();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [category, setcategory] = useState("");
  const [catlist, setcatlist] = useState(false);

  const [allCategory, setallCategory] = useState([]);
  const [nochanCatData, setNochangeCat] = useState([]);
  const getAllCategory = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllCategory"
      );
      if (res.status == 200) {
        setallCategory(res.data.carList);
        setNochangeCat(res.data.carList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const addCategory = async () => {
  //   try {
  //     if (!category) return alert("Please enter category");
  //     const congig = {
  //       url: "/admin/addVehicleCategory",
  //       method: "post",
  //       baseURL: "https://jaidrivingschool.in/api",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "content-type": "application/json",
  //       },
  //       data: {
  //         authId: admin?._id,
  //         category: category,
  //       },
  //     };
  //     let res = await axios(congig);
  //     if (res.status == 200) {
  //       alert("Successfully Added");
  //       handleClose3();
  //       setcatlist(true);
  //       setcategory("");
  //       getAllCategory();
  //     }
  //   } catch (error) {
  //     alert(error.response.data.error);
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    getAllCategory();
  }, []);
  console.log("allcat", allCategory);

  const handleFilterCat = (e) => {
    if (e.target.value != "") {
      const filterTable = nochanCatData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallCategory([...filterTable]);
    } else {
      setallCategory([...nochanCatData]);
    }
  };

  const [pageNumber1, setPageNumber1] = useState(0);

  const pagesVisited1 = pageNumber1 * usersPerPage;
  const pageCount1 = Math.ceil(allCategory.length / usersPerPage);
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };

  const deleteCategory = async () => {
    try {
      const congig = {
        url: "/admin/deleteCategory/" + deleteId + "/" + admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      };
      let res = await axios(congig);
      if (res.status == 200) {
        alert("Successfully deleted");
        handleClose1();
        setcatlist(true);
        getAllCategory();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button> Blogs Details</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button
                    style={{ float: "right", borderRadius: "10px" }}
                    onClick={()=>{
                      settitle("");
                      setblogCategory("");
                      setblog1("");
                      setblog2("");
                      setblog3("");
                      setpostBy(admin?.branchHeadrName)
                      handleShow()}}
                  >
                    +Add Blog
                  </button>
                  {/* <button
                    style={{ float: "right", borderRadius: "10px" }}
                    onClick={handleShow3}
                  >
                    +Add Category
                  </button> */}
                  {catlist ? (
                    <button
                      style={{ float: "right", borderRadius: "10px" }}
                      onClick={() => setcatlist(false)}
                    >
                      Blog List
                    </button>
                  ) : (
                    <button
                      style={{ float: "right", borderRadius: "10px" }}
                      onClick={() => setcatlist(true)}
                    >
                      Category List
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {catlist ? (
              <div className="mo-gra mt-5">
                <div className="row">
                  <div className="col-3">
                    <input
                      type="text"
                      placeholder="Search...."
                      className="vi_0"
                      style={{ marginLeft: "-20px" }}
                      onChange={(e) => handleFilterCat(e)}
                    />
                  </div>
                </div>
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Category</th>
                      {/* <th>Edit</th> */}
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCategory
                      ?.slice(pagesVisited1, pagesVisited1 + usersPerPage)
                      ?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>

                            <td>{ele?.category}</td>

                            <td>
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setdeleteId(ele?._id);
                                  handleShow1();
                                }}
                              >
                                <span>
                                  <RiDeleteBin6Line
                                    style={{ fontSize: "20" }}
                                  />
                                </span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount1}
                  onPageChange={changePage1}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            ) : (
              <div className="mo-gra mt-5">
                <div className="row">
                  <div className="col-3">
                    <input
                      type="text"
                      placeholder="Search...."
                      className="vi_0"
                      style={{ marginLeft: "-20px" }}
                      onChange={(e) => handleFilter(e)}
                    />
                  </div>
                </div>
                <Table striped bordered hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Image 1</th>
                      <th>Image 2</th>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Description 1</th>
                      <th>Description 2</th>
                      <th>Description 3</th>
                      <th>Posted By</th>
                      <th>Status</th>
                      <th>Edit</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCourse
                      ?.slice(pagesVisited, pagesVisited + usersPerPage)
                      ?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              <img
                                src={`https://jaidrivingschool.in/Blog/${ele?.image}`}
                                alt=""
                                width={80}
                              />
                            </td>
                            <td>
                              {ele?.image1 ? (
                                <img
                                  src={`https://jaidrivingschool.in/Blog/${ele?.image1}`}
                                  alt=""
                                  width={80}
                                />
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              {" "}
                              <p
                                style={{
                                  height: "100px",
                                  overflow: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {ele?.title}
                              </p>{" "}
                            </td>
                            <td>{ele?.category} </td>
                            <td>
                              <p
                                style={{
                                  height: "100px",
                                  overflow: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {ele?.blog1 ? parse(ele?.blog1) : ""}
                              </p>
                            </td>

                            <td>
                              <p
                                style={{
                                  height: "100px",
                                  overflow: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {ele?.blog2 ? parse(ele?.blog2) : ""}
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  height: "100px",
                                  overflow: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {ele?.blog3 ? parse(ele?.blog3) : ""}
                              </p>
                            </td>
                            <td>{ele?.PostedBy}</td>
                            <td>
                              {ele?.status == "Pending" ? (
                                <span style={{ color: "blue" }}>
                                  {ele?.status}
                                </span>
                              ) : (
                                <span style={{ color: "green" }}>
                                  {ele?.status == "Hold" ? (
                                    <span style={{ color: "Red" }}>
                                      {ele?.status}
                                    </span>
                                  ) : (
                                    <span>{ele?.status}</span>
                                  )}
                                </span>
                              )}
                            </td>

                            <td>
                              <img
                                src="./images/editing.png"
                                alt=""
                                width={30}
                                onClick={() => {
                                  setedit(ele);
                                  handleShowed(ele);
                                }}
                              />
                            </td>
                            <td style={{ display: "flex", gap: "3px" }}>
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setdeleteId(ele?._id);
                                  handleShow1();
                                }}
                              >
                                <span>
                                  <RiDeleteBin6Line
                                    style={{ fontSize: "20" }}
                                  />
                                </span>
                              </Button>{" "}
                            
                            
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <br/>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            )}
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1"> Image 1</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setimage1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload2">Image 2</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload2"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setimage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="vi_0"
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Category</label>
                <select
                  className="vi_0"
                  onChange={(e) => setblogCategory(e.target.value)}
                >
                  <option>Select Category</option>
                  {allCategory?.map((item) => {
                    return (
                      <option value={item?.category}>{item?.category}</option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Description 1</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={blog1}
                  onChange={handleChange1}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description 2</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={blog2}
                  onChange={handleChange2}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description 3</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={blog3}
                  onChange={handleChange3}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Poated By Name</label>
                <input
                  type="text"
                  name=""
                  className="vi_0"
                  value={postBy}
                  placeholder="Enter posted by name"
                  onChange={(e) => setpostBy(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AdminService}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* edit course */}
        <Modal
          show={edshow}
          onHide={handCloseed}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1"> Image 1</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setimage1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload2">Image 2</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload2"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setimage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Title</label>
                <input
                  type="text"
                  value={title}
                  placeholder={edit?.title}
                  className="vi_0"
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Category</label>
                <select
                  className="vi_0"
                  onChange={(e) => setblogCategory(e.target.value)}
                >
                  <option>
                    {" "}
                    {edit?.category ? edit?.category : "Select Category"}
                  </option>
                  {allCategory?.map((item) => {
                    return (
                      <option value={item?.category}>{item?.category}</option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Description 1</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={blog1}
                  onChange={handleChange1}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description 2</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={blog2}
                  onChange={handleChange2}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description 3</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={blog3}
                  onChange={handleChange3}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Poated By Name</label>
                <input
                  type="text"
                  name=""
                  className="vi_0"
                  value={postBy}
                  placeholder={edit?.PostedBy}
                  onChange={(e) => setpostBy(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handCloseed}>
              Close
            </Button>
            <Button variant="primary" onClick={updateCourse}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete Model */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ??</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            {catlist ? (
              <Button variant="success" onClick={deleteCategory}>
                Ok
              </Button>
            ) : (
              <Button variant="success" onClick={deleteCourses}>
                Ok
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* category model */}
        {/* <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Category</label>
                <input
                  type="text"
                  placeholder="Enter category"
                  onChange={(e) => setcategory(e.target.value)}
                  className="vi_0"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
            <Button variant="primary" onClick={addCategory}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal> */}
      </>
    </div>
  );
};
export default VendorBlog;
