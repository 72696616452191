import React, { useEffect, useState } from "react";
import Side from "./sidebar/Side";
import axios from "axios";

const AddAttendence = () => {
 
  let trainer=JSON.parse(sessionStorage.getItem("staff"));
let token=sessionStorage.getItem("token");
const [AllTrainerBook,setAlltrainer]=useState([]);
const [nochangedata,setnochangedata]=useState([])
  const getbookDetailsByTrainerId=async()=>{
    try {
      const config = {
        url: "/admin/getbookDetailsByTrainerId/"+trainer?._id+"/"+trainer?._id,
        method: "get",
        baseURL: "https://jaidrivingschool.in/api",
        headers: { Authorization: `Bearer ${token}`,"content-type": "application/json" },
      };
      let res = await axios(config);
      if (res.status == 200) {
        setAlltrainer(res.data.success);
        setnochangedata(res.data.success)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    if(trainer){
      getbookDetailsByTrainerId()
    }
  },[]);
  const [booked,setbooked]=useState({});
  const [topic,settopic]=useState("");
  const [day,setday]=useState("");
  const [time,settime]=useState("");
  const [mobile,setmobile]=useState("");
  const [date,setdate]=useState("");
  const [student,setstudent]=useState("")
  const [endtime,setendtime]=useState("");
  const changeStudent=(data)=>{
    if(data){
      setbooked(JSON.parse(data))
      setmobile(JSON.parse(data)?.number)
      setstudent(JSON.parse(data)?.customerName)
    }
  }

  const ClassStart=async()=>{
    try {
   const config={
    url: '/addclass',
    method: 'post',
    baseURL: 'https://jaidrivingschool.in/api/admin',
    headers: {'content-type': 'multipart/form-data'},
    data: {
      StudentName:student,studentId:booked?._id,classTopic:topic,classday:day,selectdate:date,selectTime:time,customerId:booked?.customerId,trainerId:trainer?._id,mobile:mobile
    },
      }
      let res=await axios(config);
      if(res.status==200){
      
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <Side />
   
      <div className="container">
        <div className="main_employeeee">
          <form >
            <div class="row mt-2 add">
              <h2 style={{ color: "black",display:"flex",margin:"auto" }}>
                Attendence Details
              </h2>
              <div className="row">
              <div className="col-md-6">
                <label htmlFor="student">Select student</label>
                <br />
                <select
                  name="student"
                  id="student"
                  onChange={(e)=>changeStudent(e.target.value)}
                >
                  <option>Seclect Student</option>
                   {AllTrainerBook?.filter((ele)=>ele?.status=="Confirm")?.map((item)=>{
                  return (
                    <option value={JSON.stringify(item)}>{item?.customerName}</option>
                  )
                })}
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="student">Select Day</label> <br />
                <select
                  name="days"
                  id="day"
                  onChange={(e)=>setday(e.target.value)}
                >
                  <option>Select Days</option>
                 {booked?.courseTopic?.filter((ele)=> topic ? ele?.topicName==topic:ele)?.map((item)=>{
                  return (
                     <option value={item?.days}>{item?.days} day</option>
                  )
                })}
                </select>
              </div>
            
            </div>
              <div className="col-md-6  ">
                <label htmlFor="name"> Start Time</label><br />

                <input
                  //   className="form-control"
                  type="time"
                  name="starttime"
                  // id="stime"
                  className="timeinput "
             
                />
              </div>
              <div className="col-md-6 ">
                <label htmlFor="name">End Time</label> <br />
                <input
                  //   className="form-control"
                  type="time"
                  name="endtime"
                  className="timeinput "
                
             
                />
              </div>
            </div>
            <div className="col-md  ">
                <label htmlFor="remark">Remark</label><br />
                <textarea
                  name="remarks"
                  id="remarks"
                  col-mds="60"
                  rows="4"
               
                  style={{ border: "1px solid black" }}
                 
                >
                  {" "}
                </textarea>
              </div>

            <button
              className="btn btn-warning mt-1"
              type="submit"
              style={{ marginLeft: "40px", width: "7rem", marginBottom:"10px" }}
            >
               Submit
            </button>
          </form>
        </div>
      </div>
 
      
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default AddAttendence;
