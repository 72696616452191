import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Blog1 from "./Blog1";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";

const Blog = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [allCategory,setallCategory]=useState([]);
  const [nochanCatData,setNochangeCat]=useState([])
  const getAllCategory=async()=>{
    try {
        let res=await axios.get("https://jaidrivingschool.in/api/admin/getAllCategory");
        if(res.status==200){
            setallCategory(res.data.carList);
            setNochangeCat(res.data.carList);
        }
    } catch (error) {
        console.log(error);
    }
  }
  const [allBlog, setAllBlogs] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllBlogs = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getAllBlogsApproved"
      );
      if (res.status == 200) {
        setAllBlogs(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allBlog.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const navigate = useNavigate();

  const [cat,setcat]=useState("");

  useEffect(()=>{
    getAllBlogs()
    getAllCategory()
  },[])
  return (
    <div>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "130px",
            background: "black",
            overflow: " hidden",
            background: " #C33764",
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
             url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Blogs</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "white" }}>&raquo;</span>
            </li>
            <li style={{ color: "white", fontSize: "18px" }}>Blog</li>
          </ul>
        </div>
      </div>
      <div
        className="dhren"
        style={{ float: "right", marginTop: "20px", marginRight: "20px" }}
      >
        {/* <button
          className="btn btn-warning cbmd_09 "
          onClick={handleShow}
          style={{ marginLeft: "60px" }}
        >
          Post Blog
        </button> */}
        <div>
      <div className="blog1">
        <div className="container">
          <div className="row">
            <div className="col-md bg-17">
              <ul>
                {" "}
                <h3>CATEGORY</h3>
                {allCategory?.map((ele)=>{
                  return (
                    <li  style={{cursor:"pointer"}} onClick={()=>setcat(ele?.category)}>
                    <a style={{color:"red"}}>{ele?.category}</a> <a style={{color:"red",float:"right"}}>{allBlog?.filter((item)=>item?.category==ele?.category)?.length}</a>
                  </li>
                  )
                })}
              
               
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
      {allBlog ?.slice(pagesVisited, pagesVisited + usersPerPage)?.filter((item)=> cat ? item?.category==cat : item)?.map((ele)=>{
        return (
         <div className="container">
        <div className="rw2 row bg-18 ">
          <h2>{ele?.category}</h2>
          <div className="col-md-4 bg-19">
            <img src={`https://jaidrivingschool.in/Blog/${ele?.image}`} alt="" style={{ width: "100%" }} />
          </div>
          <div className="col-md-8 bg-20">
            <h3>{ele?.title}</h3>
            <p style={{ textAlign: "justify" }}>
             {ele?.blog1 ? (parse(ele?.blog1 )):""}
            </p>
            <a href={"/blogviewmore/"+ele?._id} className="btn btn-warning"> 
              View More <ArrowRightAltIcon />
            </a>
            <br /> <br />
            <small style={{ color: "gray" }}>
              {moment(ele?.updatedAt).format('lll')} Posted by {ele?.PostedBy}
            </small>
            <br />
            {/* <a href="">View Comments</a> */}
          </div>
        </div>
      </div> 
        )
      })}
         <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
    
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Post Your Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="do-sear mt-2">
            {/* <div class="row"> */}
            {/* <div class=" col-md-10"> */}
            <label htmlFor="">Post From</label>
            <select name="" className="vi_0">
              <option value="">-----select-----</option>
              <option value="">Instructor</option>
              <option value="">Staff</option>
              <option value=""> Student</option>
              <option value="">Customer</option>
            </select>{" "}
          </div>
          {/* </div> */}
          {/* </div> */}
          <div className="do-sear mt-2">
            {/* <div class="row"> */}
            {/* <div class="col-md-5"> */}
            <label htmlFor="">Category</label>
            <select name="" className="vi_0">
              <option value="">-----select-----</option>
              <option value="">Learning Licence</option>
              <option value="">Permanent Licence</option>
              <option value="">RC Transfer</option>
              <option value="">Driving School</option>
              <option value="">General Blog</option>
            </select>
          </div>

          <div className="do-sear mt-2">
            <label htmlFor="">Enter Blog Tittle</label>
            <span className="bg-21">
              <input
                type="text"
                placeholder="Enter Tittle...."
                className="vi_0"
              />
            </span>
          </div>

          <div className="do-sear mt-2">
            <label htmlFor="">Select Images/videos</label>
            <span className="bg-21">
              <input
                type="file"
                placeholder="Enter Tittle...."
                className="vi_0"
              />
            </span>
          </div>
          <div className="do-sear mt-2">
            {/* <div class="row"> */}
            {/* <div class=" col-md-5"> */}
            <label htmlFor="">Enter Your Name</label>
            <span>
              <input
                type="text"
                placeholder="Enter Your Name...."
                className="vi_0"
                id="in"
              />
            </span>
            {/* </div> */}
            {/* <div class="col-md-5"> */}
            <label htmlFor="">Enter Email Id </label>
            <span>
              <input
                type="email"
                placeholder="Enter Email id...."
                className="vi_0"
              />
            </span>
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="do-sear mt-2">
            <textarea
              name=""
              id=""
              cols="46"
              placeholder="Enter Your Text Here......"
              className="vi_0"
              style={{ width: "100%", marginTop: "10px" }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => navigate("/timslot")}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Blog;
