import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import {RxCrossCircled} from "react-icons/rx"
import axios from "axios";
import ReactPaginate from "react-paginate";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
const AdminReview = () => {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = sessionStorage.getItem("token");

  const [allCourse, setallCourse] = useState([]);
  const [nochangedata, setnochangedata] = useState([]);
  const getAllCourse = async () => {
    try {
      let res = await axios.get(
        "https://jaidrivingschool.in/api/admin/getReview"
      );
      if (res.status == 200) {
        setallCourse(res.data.success);
        setnochangedata(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [courseName, setcourseName] = useState("");
  const [courseDuration, setcourseDuration] = useState("");
  const [coursePoint, setcoursePoint] = useState([]);
  const [price, setprice] = useState("");
  const [courseImage, setcourseImage] = useState("");
  const [Description, setDescription] = useState("");
  const [PracticalSessions, setPracticalSessions] = useState("");
  const [TheorySessions, setTheorySessions] = useState("");
  const [PracticalExam, setPracticalExam] = useState("");
  const [TheoryExam, setTheoryExam] = useState("");
  const [SimulatorSessions, setSimulatorSessions] = useState("");
  const [aboutCourse, setAboutCourse] = useState("");
  const addDataAbout = () => {
    if (!aboutCourse) return alert("Please enter course point");
    coursePoint.push({ text: aboutCourse });
    setAboutCourse("");
  };

  const [Demo,setDemo]=useState("");
  const AdminService = async () => {
    try {
      if (!courseImage) return alert("Please select image");
      if (!courseName) return alert("Please enter name");
    //   if (!courseDuration) return alert("Please enter service type");
    //   if (coursePoint?.length == 0)
    //     return alert("Please add your documents require one by one");
     
      if (!Description) return alert("Please enter Description!");
      // if (!PracticalSessions) return alert("Please enter practical session");
      // if (!TheorySessions) return alert("Please enter theory session");
      // if(!courseImage) return alert("Please select image")
      // if(!courseImage) return alert("Please select image")
      if (!price) return alert("Please enter rating");
      if(price >5 || price<1) return alert ("Rating should be 1 to 5")
      const config = {
        url: "/admin/AddReview",
        method: "post",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          authId: admin?._id,
          writer: courseName,
        //   serviceType: courseDuration,
        //   servicePoint: coursePoint,
          ratting: price,
          // serviceRequire:PracticalSessions,
          logo: courseImage,
          text: Description,
        //   serviceStDate: SimulatorSessions,
        //   serviceEndDate: TheorySessions,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully added");
        getAllCourse();
        handleClose();
        setcourseImage("");
        setcourseName("");
        setcourseDuration("");
        setDescription("");
        setPracticalSessions("");
        setTheorySessions("");
        setPracticalExam("");
        setTheoryExam("");
        setSimulatorSessions("");
        setprice("");
        setDemo("")
        setcoursePoint([]);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCourse();
  }, []);
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setallCourse([...filterTable]);
    } else {
      setSearch(e.target.value);
      setallCourse([...nochangedata]);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(allCourse.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [show1, setshow1] = useState(false);
  const handleClose1 = () => setshow1(false);
  const handleShow1 = () => setshow1(true);
  const [deleteId, setdeleteId] = useState("");

  const [edit,setedit]=useState({})
  const [edshow,setedshow]=useState(false);
  const handleShowed=()=>setedshow(true);
  const handCloseed=()=>setedshow(false)
 const updateCourse = async () => {
    try {
      const config = {
        url: "/admin/updateReview",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
        data: {
          id:edit?._id,
          authId: admin?._id,
          logo: courseImage,
          text: Description,
          writer: courseName,
            ratting: price,
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        alert("Successfully updated");
        getAllCourse();
        handCloseed();
        setcourseImage("");
        setcourseName("");
        setcourseDuration("");
        setDescription("");
        setPracticalSessions("");
        setTheorySessions("");
        setPracticalExam("");
        setTheoryExam("");
        setSimulatorSessions("");
        setprice("");
        setDemo("")
        setcoursePoint([]);
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const addPointofCourse=async()=>{
    try {
      if(!aboutCourse) return alert("Please enter course point")
      const config={
          url: "/admin/addServicePoint",
        method: "put",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        data:{
          text: aboutCourse ,
          id:edit?._id,
          authId:admin?._id
        }
      }
      let res=await axios(config);
      if(res.status==200){
        alert("Successfully added")
        
        setedit(res.data.success)
        setAboutCourse("")
      }
    } catch (error) {
        alert(error.response.data.error);
      console.log(error);
    }
  }
  const deleteCoursePoint=async(id)=>{
    try {
      const config={
        url: "/admin/removeServicePoint",
      method: "put",
      baseURL: "https://jaidrivingschool.in/api",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      data:{

        removeId: id ,
        id:edit?._id,
        authId:admin?._id
      }
    }
    let res=await axios(config);
    if(res.status==200){
      alert("Successfully deleted")
      
      setedit(res.data.success)
    
    }
    } catch (error) {
        alert(error.response.data.error);
      console.log(error);
    }
  }
  
  const deleteCourses=async()=>{
    try {
      const congig={
        url: "/admin/deleteReview/"+deleteId+"/"+admin?._id,
        method: "delete",
        baseURL: "https://jaidrivingschool.in/api",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        }
      }
      let res=await axios(congig);
      if(res.status==200){
        alert("Successfully deleted");
        handleClose1();
        getAllCourse();
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <>
        <div className="add-gr">
          <div className="container">
            <div className="row">
            <div className="col-md-6">
                <div className="ad-b mt-4">
                  <button > Reviews Details</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ad-b mt-4" >
                  <button  style={{float:"right",borderRadius:"10px"}} onClick={handleShow}>+Add</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mo-gra mt-5">
              <div className="row">
                <div className="col-3">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="vi_0"
                    style={{ marginLeft: "-20px" }}
                    onChange={(e) => handleFilter(e)}
                  />
                </div>
              </div>
              <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Profile</th>
                    <th>Name</th>
                    <th>Description</th>
                    
                  
                    <th>Rating</th>
                    {/* <th>End Date</th>
                    <th>Amount</th>
                    <th>DocumentS Requireds</th> */}
                    {/* <th>view</th> */}
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr> 
                </thead>
                <tbody>
                  {allCourse
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((ele, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <img
                              src={`https://jaidrivingschool.in/review/${ele?.logo}`}
                              alt=""
                              width={80}
                            />
                          </td>
                          <td>{ele?.writer}</td>
                          <td>
                            <p
                              style={{
                                height: "100px",
                                overflow: "hidden",
                                overflowY: "auto",
                              }}
                            >
                              {ele?.text}
                            </p>
                          </td>
                          <td>{ele?.ratting}  </td>
                          {/* <td>{ele?.serviceRequire}  </td> */}
                         
                          {/* <td>{ele?.TheoryExam} </td> */}
                      
                      

                          <td>
                            <img src="./images/editing.png" alt="" width={30} onClick={()=>{
                              setedit(ele);
                              handleShowed()
                            }} />
                          </td>
                          <td>
                            <Button
                              type="button"
                              variant="danger"
                              onClick={() => {
                                setdeleteId(ele?._id);
                                handleShow1();
                              }}
                            >
                              <span>
                                <RiDeleteBin6Line style={{ fontSize: "20" }} />
                              </span>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Profile</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setcourseImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="vi_0"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="do-sear mt-2">
                <label>Service Type</label>
                <input
                  type="text"
                  placeholder="Service Type"
                  onChange={(e) => setcourseDuration(e.target.value)}
                  className="vi_0"
                />
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-md-8">
                <label>Document Requireds</label>
                <input
                  type="text"
                  value={aboutCourse}
                  placeholder="Enter Service document required one by one"
                  className="vi_1"
                  onChange={(e) => setAboutCourse(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={addDataAbout}
                  class="btn btn-success"
                >
                  Add
                </button>{" "}
                {coursePoint?.length != 0 ? (
                  <button
                    type="button"
                    style={{margin:"5px"}}
                    onClick={() => setcoursePoint([])}
                    class="btn btn-danger"
                  >
                    Reset
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <ul>
                {coursePoint?.map((ele) => {
                  return <li>{ele?.text}</li>;
                })}
              </ul>
            </div> */}
          
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  className="vi_0"
                  placeholder="Enter Text Here"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Ratting</label>
                <input
                  type="number"
                  min="1"
                  max="5"
                  onChange={(e) => setprice(e.target.value)}
                  placeholder="eg:- 1 to 5 "
                  className="vi_1"
                />
              </div>
            </div>
            {/* <div className="row">
             
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Service Start Date</label>
                  <input
                    type="date"
                    placeholder="Eg:-HOURS"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setSimulatorSessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Service End Date</label>
                  <input
                    type="date"
                    placeholder="Eg:-HOURS"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheorySessions(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div> */}
{/* 
            <div className="row">
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Practical Exam</label>
                  <input
                    type="number"
                    placeholder="Eg:-MINUTES"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setPracticalExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
             < div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Demo Session</label>
                  <input
                    type="number"
                    placeholder="Eg:-MINUTES"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setDemo(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="do-sear mt-2">
                  <label>Theory Exam</label>
                  <input
                    type="number"
                    placeholder="Eg:-MINUTES"
                    className="vi_0"
                    min="0"
                    onChange={(e) => setTheoryExam(e.target.value)}
                    width={20}
                  />
                </div>
              </div>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AdminService}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
                {/* edit course */}
                <Modal
          show={edshow}
          onHide={handCloseed}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label htmlFor="upload1">Logo</label>
                <input
                  type="file"
                  placeholder="Enter Location"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  className="vi_0"
                  onChange={(e) => setcourseImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Name</label>
                <input
                  type="text"
                  placeholder={edit?.writer}
                  className="vi_0"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </div>
            </div>
          
           
          
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  className="vi_0"
                  placeholder={edit?.text}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Ratting</label>
                <input
                  type="number"
                  min="1"
                  max="5"
                  onChange={(e) => setprice(e.target.value)}
                  placeholder={edit?.ratting}
                  className="vi_1"
                />
              </div>
            </div>

       
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handCloseed}>
              Close
            </Button>
            <Button variant="primary" onClick={updateCourse}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* delete Model */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure delete ??</Modal.Title>
          </Modal.Header>
       
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="success" onClick={deleteCourses}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};
export default AdminReview;
